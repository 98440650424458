import { Button, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import Loading from "../../components/Loading";
import { isEmail } from "validator";
export const REACT_APP_CP_AUTH_CLIENTID =
  process.env.REACT_APP_CP_AUTH_CLIENTID;
function CommandPostSignup() {
  const [cpUserinfo, setcpUserinfo] = useState({
    userid: "",
    authid: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [isFailure, setisFailure] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [validatemsg, SetValidatemsg] = useState("");
  const [isvaliddata, setisvaliddata] = useState(false);

  const handleChange = (event) => {
    SetValidatemsg("");
    const path = event.target.name.split(".");
    const finalProp = path.pop();
    const newuser = { ...cpUserinfo };
    let pointer = newuser;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] = event.target.value;
    setcpUserinfo(newuser);
    handleFieldValidation(event);
    setisvaliddata(true);
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "email":
        localerror = !isEmail(value) ? "Invalid Email" : "";
        break;
      case "password":
        localerror = value.length < 0 ? "Invalid Password" : "";
        break;
      case "phone":
        localerror =
          value.length < 10 ? "Phone must be at least 10 characters" : "";
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
  };
  const handleFetchFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      email:
        cpUserinfo.email === ""
          ? (iserror = "Email  required")
          : errors.email,
      password:
        cpUserinfo.password === ""
          ? (iserror = "Password required")
          : errors.password,
    });

    return iserror.length === 0;
  };
  const handleSumbitFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      firstname:
        cpUserinfo.firstname === ""
          ? (iserror = "First name required")
          : errors.firstname,
      lastname:
        cpUserinfo.lastname === ""
          ? (iserror = "Last name required")
          : errors.lastname,
      email:
        cpUserinfo.email === ""
          ? (iserror = "Email required")
          : errors.email,
    });

    return iserror.length === 0;
  };
  const handleFetch = async (e) => {
    e.preventDefault();
    if (validateFetchForm()) {
      await getcpuserinfo(cpUserinfo);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateSubmitForm()) {
      await signuppuserinfo(cpUserinfo);
    }
  };
  //error handling functions
  const validateFetchForm = () => {
    try {
      let valid = false;
      valid = handleFetchFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  //error handling functions
  const validateSubmitForm = () => {
    try {
      let valid = false;
      valid = handleSumbitFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  function gotoLogin() {
    window.location.href = "/";
  }
  const handleSucessSweetAlert = () => {
    setisSuccess(false);
    gotoLogin();
  };
  const handleFailureSweetAlert = () => {
    setisFailure(false);
  };

  useEffect(() => { }, []);

  function getcpuserinfo(cp_Userinfo) {
    setLoading(true);
    // console.log(JSON.stringify(values));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(cp_Userinfo);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("/getcpuserbyemail", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (result.toUpperCase().includes("[ERROR]:")) {
          // console.log(result);
          var message = result.replace("[error]:", "");
          var errmsg = JSON.parse(message);
          //console.log(JSON.stringify(errmsg));
          seterrorMessage(errmsg);
          setisFailure(true);
        } else {
          var msg = JSON.parse(result);
          //console.log(msg[0].authId_sub[0].sub);
          var localfirstname = "";
          var locallastname = "";
          var localemail = "";
          var localphone = "";
          var localauthid = "";
          var localclientid = "";
          var authId_sub = [];
          for (var myKey in msg[0]) {
            //console.log("key:"+myKey+", value:"+msg[0][myKey]);
            if (myKey === "firstName") localfirstname = msg[0][myKey];
            if (myKey === "lastName") locallastname = msg[0][myKey];
            if (myKey === "userEmail") localemail = msg[0][myKey];

            if (myKey === "authId_sub") {
              msg[0].authId_sub.forEach((myauthId_sub) => {
                //console.log("key:"+myauthKey+", value:"+msg[0][myKey][0][myauthKey]);
                //REACT_APP_CP_AUTH_CLIENTID
                if (myauthId_sub.aud === REACT_APP_CP_AUTH_CLIENTID) {
                  localauthid = myauthId_sub.sub;
                  authId_sub.push(myauthId_sub);
                }
              });
            }
          }
          const newuser = { ...cpUserinfo };
          let pointer = newuser;

          pointer["firstname"] = localfirstname;
          pointer["lastname"] = locallastname;
          pointer["email"] = localemail;
          pointer["phone"] = localphone;
          pointer["userid"] = localauthid;
          pointer["authid"] = localauthid;
          pointer["authId_sub"] = authId_sub;
          setcpUserinfo(newuser);

          console.log("userdata:", cpUserinfo);
          // setisSuccess(true);
        }
      })
      .catch((error) => console.log("error", error));
    setLoading(false);
  }
  function signuppuserinfo(cp_Userinfo) {
    setLoading(true);
    // console.log(JSON.stringify(values));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let cpuserpayload = {
      authid: cp_Userinfo.authid,
      email: cp_Userinfo.email,
      firstname: cp_Userinfo.firstname,
      lastname: cp_Userinfo.lastname,
      phone: cp_Userinfo.phone,
      authId_sub: cp_Userinfo.authId_sub,
    };
    var raw = JSON.stringify(cpuserpayload);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    console.log("userdata:", cpuserpayload);

    fetch("/createcpuserbyemail", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (result.toUpperCase().includes("[ERROR]:")) {
          console.log(result);
          var message = result.replace("[error]:", "");
          var errmsg = JSON.parse(message);
          console.log(JSON.stringify(errmsg));
          seterrorMessage(errmsg);
          setisFailure(true);
        } else {
          setisSuccess(true);
        }
      })
      .catch((error) => console.log("error", error));
    setisSuccess(true);
    setLoading(false);
  }
  function handleCancel() {
    window.location.href = "/Signup/0/0";
  }

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="px-3">
      <h3 align="center">Command Post</h3>
      <Form className="">
        <div>
          <div className="row mb-3">
            <label className="col-sm-12 col-md-2">
              Email<span style={{ color: "red" }}>&nbsp;*</span>
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                placeholder="command post email"
                name="email"
                className="form-control"
                onChange={handleChange}
                autoComplete="new-cpEmail"
              />
              {errors.email.length > 0 && (
                <small className="form-check-label text-danger" htmlFor="email">
                  {errors.email}
                </small>
              )}
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-12 col-md-2" y>
              {" "}
              Password<span style={{ color: "red" }}>&nbsp;*</span>
            </label>
            <div className="col-sm-10">
              <input
                type="password"
                placeholder="command post password"
                name="password"
                className="form-control"
                onChange={handleChange}
              />
              {errors.password.length > 0 && (
                <small
                  className="form-check-label text-danger"
                  htmlFor="password"
                >
                  {errors.password}
                </small>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-center gap-2">
            <Button
              variant="primary"
              id="procoreToken"
              onClick={handleFetch}
              type="submit"
              className="btn btn-success"
            >
              Fetch Profile
            </Button>
            <Button className="btn btn-success " onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
        <div>
          <hr></hr>
          <div className="row mt-3 mb-3">
            <label className="col-sm-12 col-md-2">
              First Name <span style={{ color: "red" }}>*</span>
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                placeholder="First Name"
                name="firstname"
                disabled="true"
                id="firstname"
                className="form-control"
                value={cpUserinfo.firstname}
                onChange={handleChange}
              />
              {errors.firstname.length > 0 && (
                <small
                  className="form-check-label text-danger"
                  htmlFor="firstname"
                >
                  {errors.firstname}
                </small>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-12 col-md-2">
              Last Name <span style={{ color: "red" }}>*</span>
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                placeholder="Last Name"
                name="lastname"
                disabled="true"
                id="lastname"
                className="form-control"
                value={cpUserinfo.lastname}
                onChange={handleChange}
              />
              {errors.lastname.length > 0 && (
                <small
                  className="form-check-label text-danger"
                  htmlFor="lastname"
                >
                  {errors.lastname}
                </small>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-12 col-md-2">
              Email <span style={{ color: "red" }}>*</span>
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                disabled="true"
                placeholder="Email"
                name="email_display"
                className="form-control"
                value={cpUserinfo.email}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-12 col-md-2">Phone </label>
            <div className="col-sm-10">
              <input
                type="number"
                placeholder="Phone"
                disabled="true"
                name="phone"
                id="phone"
                className="form-control"
                value={cpUserinfo.phone}
                onChange={handleChange}
              />
              {errors.phone.length > 0 && (
                <small className="form-check-label text-danger" htmlFor="phone">
                  {errors.phone}
                </small>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-center gap-2">
            <Button
              variant="primary"
              id="procoreToken"
              onClick={handleSubmit}
              className="btn btn-success"
            >
              Save
            </Button>
            <span> </span>
            <Button variant="secondary" onClick={() => gotoLogin()}>
              Cancel
            </Button>
          </div>
        </div>
        <div>
          {isSuccess && (
            <SweetAlert
              success
              style={{ color: "black" }}
              title="user added in contact sync successfully.."
              onConfirm={handleSucessSweetAlert}
            ></SweetAlert>
          )}
        </div>

        <div>
          {isFailure && (
            <SweetAlert
              danger
              style={{ color: "black" }}
              title=" user failed to add in contact sync."
              onConfirm={handleFailureSweetAlert}
            >
              {errorMessage}
            </SweetAlert>
          )}
        </div>
      </Form>
    </div>
  );
}

export default CommandPostSignup;
