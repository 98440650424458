import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";

function Signupwithlob() {
  function gotosignup() {
    window.location.href = "/Signup/0/0";
  }
  function gotolinkedin() {
    window.location.href = "/LinkedInProfile/0";
  }

  return (
    <div style={{ height: 100 }}>
      <Link className="linkk" to={`/`}>
        Home
      </Link>
      <div style={{ height: 100 }}>
        <h1 align="center">Contact Sync Signup</h1>
      </div>
      <div align="center">
        <div style={{ height: 100 }}>
          <span> </span>
          <a
            className="imgLink"
            href="#"
            id="authorizeUri"
            onClick={() => gotolinkedin()}
          >
            <img
              alt=""
              style={{ marginheight: 100 }}
              src="/images/linkedin_connect.png"
            />
          </a>
          <br></br>
        </div>
        <div style={{ height: 100 }}>
          <span> </span>
          <Button
            variant="primary"
            id="refreshToken"
            className="btn btn-success"
            onClick={() => gotosignup()}
          >
            User Signup
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Signupwithlob;
