export const getcommandpostaccountlistbyuserid_wrapper = async (
  email,
  Ctechapp,
  loggedinby
) => {
  try {
    const data =
      await Ctechapp.functions.getcommandpostaccountlistbyuserid_wrapper(
        email,
        loggedinby
      );
    if (data !== undefined) {
      // console.log("status: success");
      return JSON.stringify(data);
    }
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

export const getcommandpostcontactlistbyaccountid_wrapper = async (
  accountid,
  Ctechapp,
  loggedinby
) => {
  try {
    const data =
      await Ctechapp.functions.getcommandpostcontactlistbyaccountid_wrapper(
        accountid,
        loggedinby
      );
    if (data !== undefined) {
      // console.log("status: success");
      return JSON.stringify(data);
    }
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

export const getcommandpostcompanylistbyaccountid_wrapper = async (
  accountid,
  Ctechapp,
  loggedinby
) => {
  try {
    const data =
      await Ctechapp.functions.getcommandpostcompanylistbyaccountid_wrapper(
        accountid,
        loggedinby
      );
    if (data !== undefined) {
      // console.log("status: success");
      return JSON.stringify(data);
    }
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

export const getCommandPostImportUserListByCompanyID = async (
  accountid,
  Ctechapp,
  loggedinby
) => {
  try {
    const data =
      await Ctechapp.functions.getCommandPostImportUserListByCompanyID(
        accountid,
        loggedinby
      );

    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

export const getAppNameDedupById = async (
  sourceAppname,
  sourceData,
  contactMasterRecords,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.getAppNameDedupById(
      sourceAppname,
      sourceData,
      contactMasterRecords,
      loggedin_by
    );

    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

export const getCommandPostDedupByIDs = async (arg, Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.getCommandPostDedupByIDs(
      arg,
      loggedin_by
    );

    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
//getImportDedupFormatByRecords
export const getImportDedupFormatByRecords = async (
  datalist,
  structurelist,
  Ctechapp
) => {
  try {
    const data = await Ctechapp.functions.getImportDedupFormatByRecords(
      datalist,
      structurelist
    );

    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
