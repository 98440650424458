//services for getProcorecontactlistbycompanyid
export const getProcoreuserlistbycompanyid = async (
  procoreCompanyID,
  procoreAccessTokenHostName,
  apiclientid,
  apiclientsecret,
  apirefreshtoken,
  apiapptoken,
  apiredirecturi,
  procoreRestAPIHostName,
  Ctechapp,
  loggedinby
) => {
  try {
    const userdata = {
      procoreCompanyID: procoreCompanyID,
      procoreAccessTokenHostName: procoreAccessTokenHostName,
      procoreClientID: apiclientid,
      procoreClientSecret: apiclientsecret,
      procoreRefreshToken: apirefreshtoken,
      procoreAppToken: apiapptoken,
      procoreRedirectUri: apiredirecturi,
      procoreRestAPIHostName: procoreRestAPIHostName,
    };
    const data = await Ctechapp.functions.getProcoreuserlistbycompanyid(
      JSON.stringify(userdata),
      loggedinby
    );
    if (data !== undefined) {
      // console.log("status: success");
      return data;
    }
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

export const getProcoreuserbyid = async (
  procoreApiInfo,
  Ctechapp,
  loggedinby
) => {
  try {
    const data = await Ctechapp.functions.getProcoreuserbyid(
      JSON.stringify(procoreApiInfo),
      loggedinby
    );
    if (data !== undefined) {
      // console.log("status: success");
      return data;
    }
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

export const getProcoreImportUserListByCompanyID = async (
  accountid,
  Ctechapp,
  loggedinby
) => {
  try {
    const data =
      await Ctechapp.functions.getProcoreImportUserListByCompanyID(
        accountid,
        loggedinby
      );

    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

export const getProcoreDedupByIDs = async (arg, procoreApptokenInfo, Ctechapp) => {
  try {
    const data = await Ctechapp.functions.getProcoreDedupByIDs(
      arg,
      procoreApptokenInfo,
      Ctechapp.id
    );
    return data;
  } catch (error) {
    console.log("getProcoreDedupByIDs error : ", error);
    throw Error(error);
  }
};
