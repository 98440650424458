import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Loading from "../../components/Loading";
import { getapiusersettingsbyappname_userid_companyid } from "../../services/api_user_settings_services";
import { getProcoreprojectdetailsbycompanyid } from "../../services/procore_project_services";
import { insert_procoreprojects_events_history } from "../../services/procore_projects_events_history_services";
import { useRealmApp } from "../../providers/RealmApp";
import { useAuth0 } from "@auth0/auth0-react";
//import { BSON } from "realm-web";
const _appname = "Procore";
function EditProject() {
  // const [appname, setAppname] = useState("--Select--");
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  const [appenvironment, setAppEnvironment] = useState();
  const [restapihostname, SetRestApiHostname] = useState();
  const [apiclientid, SetApiClientid] = useState();
  const [apiclientsecret, SetApiClientSecret] = useState();
  const [apirefreshtoken, setApiRefreshtoken] = useState();
  const [apiapptoken, setApiApptoken] = useState();
  const [apiaccesstokenhostname, SetApiAccesstokenHostname] = useState();
  // const [apigranttype, SetApiGrantType] = useState();
  // const [apiappcompanyid, SetApiAppCompanyid] = useState();
  // const [apienvironment, SetApiEnvironment] = useState();
  const [apiredirecturi, SetApiRedirectUri] = useState();

  const [loading, setLoading] = useState(false);
  // const [isvalid, setIsvalid] = useState(true);
  const { projectid, companyid } = useParams();

  const [name, Setname] = useState();
  //  const [display_name, Setdisplay_name] = useState();
  const [project_number, Setproject_number] = useState();
  const [description, Setdescription] = useState();
  const [total_value, Setotal_value] = useState();
  const [start_date, Setstart_date] = useState();
  const [completion_date, Setcompletion_date] = useState();
  const [actual_start_date, Setactual_start_date] = useState();
  const [projected_finish_date, Setprojected_finish_date] = useState();
  const [active, Setactive] = useState();
  const ctechapp = useRealmApp();
  const [loggedinuserdata, setLoggedinUserdata] = useState();

  useEffect(() => {
    if (companyid !== 0) {
      if (ctechapp.currentUser != undefined) {
        setLoggedinUserdata(ctechapp.currentUser);
        getDataappinfo(ctechapp.currentUser);
      }
    }
  }, []);
  function getDataappinfo(user_data) {
    setLoading(false);
    getapiusersettingsbyappname_userid_companyid(
      _appname,
      user.sub,
      companyid,
      ctechapp.currentUser,
      user.sub
    ).then((response) => {
      if (response !== undefined || response !== null) {
        const result = JSON.parse(response);
        const res = result[0];
        SetRestApiHostname(res.restapihostname);
        SetApiClientid(res.apiclientid);
        SetApiClientSecret(res.apiclientsecret);
        setApiRefreshtoken(res.apirefreshtoken);
        setApiApptoken(res.apiapptoken);
        SetApiAccesstokenHostname(res.apiaccesstokenhostname);
        //  SetApiGrantType(res.apigranttype);
        //  SetApiAppCompanyid(res.apiappcompanyid);
        //  SetApiEnvironment(res.apienvironment);
        SetApiRedirectUri(res.apiredirecturi);

        getProjectDetailsbyCompanyid(
          companyid,
          projectid,
          res.apiaccesstokenhostname,
          res.restapihostname,
          res.apiclientid,
          res.apiclientsecret,
          res.apirefreshtoken,
          res.apiapptoken,
          user_data
        );
      }
    });
  }

  async function getProjectDetailsbyCompanyid(
    Company_id,
    Project_Id,
    p_apiaccesstokenhostname,
    p_restapihostname,
    p_apiclientid,
    p_apiclientsecret,
    p_apirefreshtoken,
    p_apiapptoken,
    p_apiredirecturi,
    user_data
  ) {
    setLoading(true);

    getProcoreprojectdetailsbycompanyid(
      Project_Id,
      Company_id,
      p_apiaccesstokenhostname,
      p_apiclientid,
      p_apiclientsecret,
      p_apirefreshtoken,
      p_apiapptoken,
      p_apiredirecturi,
      p_restapihostname,
      user_data,
      user.sub
    ).then((data) => {
      if (data !== undefined) {
        Setname(data.name);
        // Setdisplay_name(JSON.parse(data.message).display_name);
        Setproject_number(data.project_number);
        Setdescription(data.description);
        Setotal_value(data.total_value);
        Setstart_date(data.start_date);
        Setcompletion_date(data.completion_date);
        Setactual_start_date(data.actual_start_date);
        Setprojected_finish_date(data.projected_finish_date);
        Setactive(data.active);
      }
    });
    setLoading(false);
  }

  // function ChangeAppEnvironment(e) {
  // //  setIsvalid(true);
  //   setAppEnvironment(e.target.value);
  // }

  function submithandler() {
    try {
      insert_procoreprojects_events_history(
        _appname,
        appenvironment,
        restapihostname,
        companyid,
        apiclientid,
        apiclientsecret,
        apirefreshtoken,
        apiapptoken,
        apiaccesstokenhostname,
        apiredirecturi,
        companyid,
        projectid,
        name,
        description,
        ctechapp.currentUser,
        user.sub
      ).then((response) => {
        if (response !== undefined) {
          alert("Project Modification created successfully in Events Table");
          window.location.href = "/procoreprojects/" + companyid;
        }
      });
    } catch (exception) {
      alert(exception);
      setLoading(false);
    }
  }
  function gotoprocoreprojects() {
    window.location.href = "/procoreprojects/" + companyid;
  }

  return (
    <div>
      <h3 align="center">Project Info</h3>
      <Form>
        {loading && <Loading />}

        <Form.Group className="mb-3">
          <Form.Label>Project Name</Form.Label>
          <Form.Control
            id="disabledTextInput"
            value={name}
            onChange={(e) => {
              Setname(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Description</Form.Label>
          <Form.Control
            id="disabledTextInput"
            value={description}
            onChange={(e) => {
              Setdescription(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Project #</Form.Label>
          <Form.Control
            id="disabledTextInput"
            disabled
            value={project_number}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Total Value</Form.Label>
          <Form.Control id="disabledTextInput" disabled value={total_value} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Start Date</Form.Label>
          <Form.Control id="disabledTextInput" disabled value={start_date} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Completed Date</Form.Label>
          <Form.Control
            id="disabledTextInput"
            disabled
            value={completion_date}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Actual Start Date</Form.Label>
          <Form.Control
            id="disabledTextInput"
            disabled
            value={actual_start_date}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Projected Completion Date</Form.Label>
          <Form.Control
            id="disabledTextInput"
            disabled
            value={projected_finish_date}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Active</Form.Label>
          <Form.Control id="disabledTextInput" disabled value={active} />
        </Form.Group>

        <div className="text-center mt-2">
          <Button onClick={submithandler}>Submit</Button>
          <Button
            variant="secondary"
            style={{ margin: 20 }}
            onClick={() => gotoprocoreprojects()}
          >
            close
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default EditProject;
