import { useEffect, useState } from "react";
import ContactCard from "../../components/ContactCard";
import { useRealmApp } from "../../providers/RealmApp";
import { getUserListByUserID } from "../../services/users_services";
import { useAuth0 } from "@auth0/auth0-react";
import { appConstants } from "../../constants/app_constants";
import { Link, useLocation } from "react-router-dom";
function ContactList() {
  const { appname, accountid } = useLocation().state || "";

  const [Contacts, setcontacts] = useState([]);
  const [vaildmsg, setvaildmsg] = useState(false);
  const ctechapp = useRealmApp();
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  useEffect(() => {
    if (isAuthenticated) {
      getData();
    }

    async function getData() {
      getUserListByUserID(ctechapp.currentUser, user.sub).then((response) => {
        if (response !== undefined) {
          let user_list = JSON.parse(response);
          var _user_list = [];
          console.log("user_list", user_list);
          if (
            appname &&
            accountid &&
            appname.toLowerCase() !== "contactmaster"
          ) {
            // console.log(appname, accountid);
            user_list.map((row) => {
              if (row.email === "ctechaotestuser100@gmail.com") {
                console.log(row);
              }
              let result = row.contactAffiliationsArray.filter(
                (val) =>
                  val?.sourceSystem?.appname === appname &&
                  val?.sourceSystem?.companyid === accountid &&
                  val?.sourceSystem?.appuserid
              );
              // console.log("result", result);
              //if (result.length > 0 && row.contactOwner === user.sub) {
              if (result.length > 0) {
                _user_list.push(row);
              }
            });
          } else {
            _user_list = user_list;
          }
          if (user_list.length === 0) {
            setvaildmsg(true);
          }

          setcontacts(_user_list);
        }
      });
    }
  }, []);

  return (
    <div className="mt-3">
      <ContactCard contact={Contacts} />
      {vaildmsg && (
        <div className="form-check-label  text-center" htmlFor="validation">
          <h3 className="text-danger ">
            {appConstants.import.handlefieldvalidation.nodata}
          </h3>
        </div>
      )}
    </div>
  );
}

export default ContactList;
