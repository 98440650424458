export const getPendingUpdateTask = async (Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.getPendingUpdateTask(loggedin_by);
    if (data !== undefined) {
      //console.log("data of getapplistbyuserid :" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

//getPendingCreationTaskByID
export const getPendingUpdateTaskByID = async (id, Ctechapp, loggedin_by) => {
  try {
    console.log("getPendingCreationTaskByID Services", id, loggedin_by);
    const data = await Ctechapp.functions.getPendingUpdateTaskByID(
      id,
      loggedin_by
    );
    if (data !== undefined) {
      //console.log("data of getapplistbyuserid :" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

//updatePendingUpdateTaskByBatchStatus
export const updatePendingUpdateTaskByBatchStatus = async (
  payload,
  status,
  Ctechapp,
  loggedin_by
) => {
  try {
    console.log("updatePendingUpdateTaskByBatchStatus Services", loggedin_by);
    const data = await Ctechapp.functions.updatePendingUpdateTaskByBatchStatus(
      JSON.stringify(payload),
      status,
      loggedin_by
    );
    if (data !== undefined) {
      //console.log("data of getapplistbyuserid :" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
