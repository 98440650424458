//services for getProcoreprojectlistbycompanyid
export const getProcoreprojectlistbycompanyid = async (
  procoreUserID,
  procoreCompanyID,
  procoreAccessTokenHostName,
  apiclientid,
  apiclientsecret,
  apirefreshtoken,
  apiapptoken,
  apiredirecturi,
  procoreRestAPIHostName,
  Ctechapp,
  loggedinby
) => {
  try {
    const userdata = {
      procoreUserID: procoreUserID,
      procoreCompanyID: procoreCompanyID,
      procoreAccessTokenHostName: procoreAccessTokenHostName,
      procoreClientID: apiclientid,
      procoreClientSecret: apiclientsecret,
      procoreRefreshToken: apirefreshtoken,
      procoreAppToken: apiapptoken,
      procoreRedirectUri: apiredirecturi,
      procoreRestAPIHostName: procoreRestAPIHostName,
    };
    const data = await Ctechapp.functions.getProcoreprojectlistbycompanyid(
      JSON.stringify(userdata),
      loggedinby
    );
    if (data !== undefined) {
      console.log("status: success");
      return data;
    }
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

//services for getProcoreprojectdetailsbycompanyid
export const getProcoreprojectdetailsbycompanyid = async (
  procoreProjectID,
  procoreCompanyID,
  procoreAccessTokenHostName,
  apiclientid,
  apiclientsecret,
  apirefreshtoken,
  apiapptoken,
  apiredirecturi,
  procoreRestAPIHostName,
  Ctechapp,
  loggedinby
) => {
  try {
    const userdata = {
      procoreProjectId: procoreProjectID,
      procoreCompanyID: procoreCompanyID,
      procoreAccessTokenHostName: procoreAccessTokenHostName,
      procoreClientID: apiclientid,
      procoreClientSecret: apiclientsecret,
      procoreRefreshToken: apirefreshtoken,
      procoreAppToken: apiapptoken,
      procoreRedirectUri: apiredirecturi,
      procoreRestAPIHostName: procoreRestAPIHostName,
    };
    const data = await Ctechapp.functions.getProcoreprojectdetailsbycompanyid(
      JSON.stringify(userdata),
      loggedinby
    );
    if (data !== undefined) {
      //  console.log("status: success");
      return data;
    }
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

//services for getProcoreProjectListbyUserID
export const getProcoreProjectListbyUserID = async (
  procoreUserID,
  procoreCompanyID,
  procoreAccessTokenHostName,
  apiclientid,
  apiclientsecret,
  apirefreshtoken,
  apiapptoken,
  apiredirecturi,
  procoreRestAPIHostName,
  Ctechapp,
  loggedinby
) => {
  try {
    const userdata = {
      procoreUserID: procoreUserID,
      procoreCompanyID: procoreCompanyID,
      procoreAccessTokenHostName: procoreAccessTokenHostName,
      procoreClientID: apiclientid,
      procoreClientSecret: apiclientsecret,
      procoreRefreshToken: apirefreshtoken,
      procoreAppToken: apiapptoken,
      procoreRedirectUri: apiredirecturi,
      procoreRestAPIHostName: procoreRestAPIHostName,
    };
    console.log("userdata ", userdata);
    const data = await Ctechapp.functions.getProcoreProjectListbyUserID(
      JSON.stringify(userdata),
      loggedinby
    );
    console.log("data ", data);
    if (data !== undefined) {
      console.log("status: success");
      return data;
    }
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
