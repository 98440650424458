import React from "react";

const MongoContext = React.createContext({
  app: null,
  client: null,
  user: null,
  authClient: null,
  setApp: () => {},
  setClient: () => {},
  setUser: () => {},
  setoauthClient: () => {},
});

export default MongoContext;
