import { useState, useMemo } from "react";
import { useRealmApp } from "../providers/RealmApp";

import { useAuth0 } from "@auth0/auth0-react";
import GenericListTable from "./gridTable/genericLlistTable";
import { useHistory } from "react-router";
import Moment from "moment";
import { appConstants } from "../constants/app_constants";
import PageSubHeader from "../components/pagesubheader"
function PendingVerificationCard({ contact }) {
  console.log("contact", contact);
  const [loading, setLoading] = useState(false);
  const ctechapp = useRealmApp();
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  //state variables
  const history = useHistory();
  //table functions
  const data = useMemo(() => [...contact], [contact]);
  const appColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "includes",
      },

      {
        Header: "First name ",
        accessor: "firstname",
        sortable: true,
        width: "20%",
        Filter: DefaultColumnFilter,
        filter: "value",
        Cell: ({ value, row }) => (
          <>
            <span>{row.original.firstname.value}</span>
          </>
        ),
      },
      {
        Header: "Last name ",
        accessor: "lastname",
        sortable: true,
        width: "20%",
        Filter: DefaultColumnFilter,
        Cell: ({ value, row }) => (
          <>
            <span>{row.original.lastname.value}</span>
          </>
        ),
      },

      {
        Header: "Email",
        accessor: "email",
        sortable: true,
        width: "30%",
        Filter: DefaultColumnFilter,
        filter: "value",
        Cell: ({ value, row }) => (
          <>
            <span>{row.original.email.value}</span>
          </>
        ),
      },
      {
        Header: "Phone",
        accessor: "phone",
        sortable: true,
        width: "30%",
        Filter: DefaultColumnFilter,
        filter: "value",
        Cell: ({ value, row }) => (
          <>
            <span>{row.original.phone.value}</span>
          </>
        ),
      },
      {
        Header: "App name",
        accessor: "sourceSystem",
        sortable: true,
        width: "30%",
        Filter: DefaultColumnFilter,
        filter: "value",
        Cell: ({ value, row }) => (
          <>
            {row.original.contactAffiliationsArray.map((item) => (
              <div className="mb-5px">
                <span>{item.sourceSystem.appname}</span>
              </div>
            ))}
          </>
        ),
      },
      {
        Header: "Company name",
        accessor: "contactAffiliationsArray",
        sortable: true,
        width: "30%",
        Filter: DefaultColumnFilter,
        filter: "value",
        Cell: ({ value, row }) => (
          <>
            {row.original.contactAffiliationsArray.map((item) => (
              <div className="mb-5px">
                <span>{item.companyName.value}</span>
              </div>
            ))}
          </>
        ),
      },

      {
        Header: "Active",
        accessor: "isactive",
        sortable: true,
        width: "10%",
        Filter: DummyColumnFilter,
        filter: "value",
        //filter: 'include',
        Cell: ({ value }) => {
          return (
            <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
              <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
              {value === "1" ? "Yes" : "Yes"}
            </span>
          );
        },
      },
      {
        Header: "Action",
        accessor: "",
        sortable: false,
        width: "10%",
        Filter: DummyColumnFilter,
        filter: "value",
        Cell: ({ row }) => {
          const onEditItemClick = () => {
            handleEditClickApps(row);
          };

          return (
            <div className="d-flex gap-1">
              <button
                className="btn btn-primary btn-sm"
                onClick={onEditItemClick}
              >
                View
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  const hiddenappColumns = ["_id"];
  const handleEditClickApps = (row) => {
    AdminVerification(row.original._id);
  };

  // This is a custom filter UI for selecting
  // a unique option from a list
  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        className="form-select"
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }
  // Define a default UI for filtering
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;

    return (
      <input
        className="form-control"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
      />
    );
  }
  function DummyColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;

    return (
      <input
        className="form-control"
        disabled
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
      />
    );
  }
  function AdminVerification(id) {
    window.location.href = "/adminverificationdetail/" + id;
  }
 
  return (
    <div>
      <PageSubHeader title={"Pending verification"}/>
      <GenericListTable
        gcolumns={appColumns}
        gdata={data.length > 0 ? JSON.stringify(data) : ""}
        ghiddencolumns={hiddenappColumns}
        gplaceholder={"Search pending verifications.."}
      />
    </div>
  );
}

export default PendingVerificationCard;
