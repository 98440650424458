import Moment from "moment";
import { useEffect, useState } from "react";
import { getContactDetailByID } from "../../services/contactDetailsServices";
import { useAuth0 } from "@auth0/auth0-react";
import { } from "../../services/users_services";
import { useRealmApp } from "../../providers/RealmApp";

import { getCollectionListsByFormName } from "../../services/collection_lists_services";
import { getPendingCreationTaskByID } from "../../services/pending_create_task_services";
import { getPendingUpdateTaskByID } from "../../services/pending_update_task_services";
function ViewContact({ value }) {
  const ctechapp = useRealmApp();
  const { id, title } = value;
  const [contact, setContact] = useState();
  const [salutationOptions, setSalutationOption] = useState([]);
  const [genderOptions, setGenderOption] = useState([]);
  const [phoneOptions, setPhoneOption] = useState([]);
  const [addressOptions, setAddressOption] = useState([]);
  const [userRoleOptions, setUserRoleOption] = useState([]);
  const [userphoto, setuserphoto] = useState("../assets/img/usericon.png");
  const { isLoading, error, isAuthenticated, user } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      switch (title) {
        case "viewcontact":
          GetContactDetailByID(id);
          break;
        case "pendingcreation":
          GetPendingCreationContactByID(id);
          break;
        case "pendingupdate":
          GetPendingUpdateContactByID(id);
          break;
        default:
          break;
      }
      // GetContactDetailByID();
      GetSalutationOptions();
      GetGenderOptions();
      GetPhoneOptions();
      GetAddressOptions();
      GetUserRoleOptions();
      if (id === undefined) {
        setuserphoto(user.picture);
      }
    }
  }, [user.sub]);
  async function GetPendingCreationContactByID(id) {
    await getPendingCreationTaskByID(id, ctechapp.currentUser, user.sub).then(
      (response) => {
        if (response !== undefined) {
          let user_info = JSON.parse(response);
          var _contact = user_info[0];
          setContact(_contact);
        }
      }
    );
  }
  async function GetPendingUpdateContactByID(id) {
    await getPendingUpdateTaskByID(id, ctechapp.currentUser, user.sub).then(
      (response) => {
        if (response !== undefined) {
          let user_info = JSON.parse(response);
          var _contact = user_info[0];
          setContact(_contact);
        }
      }
    );
  }
  function VerifiedStatus(row) {
    if (
      row?.verified_status !== undefined &&
      row?.verified_status?.status === "verified"
    ) {
      return (
        <i class="fa fa-check-circle ms-1" style={{ color: " #2fb634" }}></i>
      );
    } else {
      return <span></span>;
    }
  }
  async function GetContactDetailByID(id) {
    let useridvalue = id;
    let usertype = id === undefined ? "1" : "2";

    await getContactDetailByID(
      useridvalue,
      usertype,
      ctechapp.currentUser,
      user.sub
    ).then((response) => {
      if (response !== undefined) {
        let user_info = JSON.parse(response);
        var _contact = user_info[0];
        console.log("_contact", _contact);
        setContact(_contact);
      }
    });
  }

  async function GetSalutationOptions() {
    await getCollectionListsByFormName("Salutation", ctechapp.currentUser).then(
      (response) => {
        if (response !== undefined) {
          let salutation_info = JSON.parse(response);

          let options = salutation_info.map(function (salutation) {
            return { value: salutation.listValue, label: salutation.listName };
          });

          setSalutationOption(options);
        }
      }
    );
  }
  async function GetGenderOptions() {
    await getCollectionListsByFormName("Gender", ctechapp.currentUser).then(
      (response) => {
        if (response !== undefined) {
          let gender_info = JSON.parse(response);

          let options = gender_info.map(function (gender) {
            return { value: gender.listValue, label: gender.listName };
          });

          setGenderOption(options);
        }
      }
    );
  }

  async function GetPhoneOptions() {
    await getCollectionListsByFormName("Phone", ctechapp.currentUser).then(
      (response) => {
        if (response !== undefined) {
          let phone_info = JSON.parse(response);

          let options = phone_info.map(function (phone) {
            return { value: phone.listValue, label: phone.listName };
          });

          setPhoneOption(options);
        }
      }
    );
  }

  async function GetAddressOptions() {
    await getCollectionListsByFormName("Address", ctechapp.currentUser).then(
      (response) => {
        if (response !== undefined) {
          let address_info = JSON.parse(response);
          let options = address_info.map(function (address) {
            return { value: address.listValue, label: address.listName };
          });
          setAddressOption(options);
        }
      }
    );
  }
  async function GetUserRoleOptions() {
    await getCollectionListsByFormName(
      "ContactRole",
      ctechapp.currentUser
    ).then((response) => {
      if (response !== undefined) {
        let userrole_info = JSON.parse(response);

        let options = userrole_info.map(function (userrole) {
          return {
            value: userrole.listValue,
            label: userrole.listName,
          };
        });

        setUserRoleOption(options);
      }
    });
  }

  return (
    <div>
      <div className="p-1 d-flex ">
        <img
          className="rounded-circle justify-content-start"
          width="150px"
          height="90px"
          src={userphoto}
          alt=""
        />
        <span className="mt-4 justify-content-start">
          {" "}
          <span className="fw-bold h4 mb-1">
            {contact?.firstname} {contact?.middlename} {contact?.lastname}
          </span>
          <br />
          {title === "viewcontact" && (
            <span>
              {contact?.activity_history[0]?.activity_on.trim() && (
                <span>
                  {" "}
                  <span>created on </span>
                  {Moment(
                    contact?.activity_history[0]?.activity_on.trim()
                  ).format("DD/MM/YYYY") || ""}
                </span>
              )}
            </span>
          )}
        </span>
      </div>
      <hr />
      <div className="row p-2 fw-bold h4">Personal information</div>
      {/* {contact?.firstname && (
        <div className="row p-1">
          <div className="col-sm-12 col-md-3 col-lg-3 "> First name</div>
          <div className="col-sm-12 col-md-9 col-lg-9">
            {contact?.firstname}
          </div>
        </div>
      )}
      {contact?.middlename && (
        <div className="row p-1">
          <div className="col-sm-12 col-md-3 col-lg-3 ">
            {" "}
            Middle name
          </div>
          <div className="col-sm-12 col-md-9 col-lg-9">
            {contact?.middlename}
          </div>
        </div>
      )}

      {contact?.lastname && (
        <div className="row p-1">
          <div className="col-sm-12 col-md-3 col-lg-3 "> Last name</div>
          <div className="col-sm-12 col-md-9 col-lg-9">{contact?.lastname}</div>
        </div>
      )} */}
      {contact?.salutation && (
        <div className="row p-1">
          <div className="col-sm-12 col-md-3 col-lg-3 "> Salutation</div>
          <div className="col-sm-12 col-md-9 col-lg-9">
            {salutationOptions.map((option) =>
              option.value === contact?.salutation ? (
                <span>{option.label}</span>
              ) : (
                ""
              )
            )}
            {contact?.verified_status !== undefined && (
              <VerifiedStatus
                verified_status={contact?.verified_status?.salutation}
              />
            )}
          </div>
        </div>
      )}
      {contact?.email && (
        <div className="row p-1">
          <div className="col-sm-12 col-md-3 col-lg-3 "> Main email</div>
          <div className="col-sm-12 col-md-9 col-lg-9">
            {contact?.email}{" "}
            {contact?.verified_status !== undefined && (
              <VerifiedStatus
                verified_status={contact?.verified_status?.email}
              />
            )}
          </div>
        </div>
      )}
      {contact?.phone && (
        <div className="row p-1">
          <div className="col-sm-12 col-md-3 col-lg-3 "> Main phone</div>
          <div className="col-sm-12 col-md-9 col-lg-9">
            {"+" + contact?.phone.replace("+", "")}{" "}
            {contact?.verified_status !== undefined && (
              <VerifiedStatus
                verified_status={contact?.verified_status?.phone}
              />
            )}
          </div>
        </div>
      )}
      {contact?.nickName && (
        <div className="row p-1">
          <div className="col-sm-12 col-md-3 col-lg-3 "> Nick name</div>
          <div className="col-sm-12 col-md-9 col-lg-9">
            {contact?.nickName}{" "}
            {contact?.verified_status !== undefined && (
              <VerifiedStatus
                verified_status={contact?.verified_status?.nickName}
              />
            )}
          </div>
        </div>
      )}
      {contact?.dateOfBirth?.trim() && (
        <div className="row p-1">
          <div className="col-sm-12 col-md-3 col-lg-3 ">Date of birth</div>
          <div className="col-sm-12 col-md-9 col-lg-9">
            {(contact?.dateOfBirth &&
              Moment(contact?.dateOfBirth).format("DD-MM-YYYY")) ||
              ""}
            {contact?.verified_status !== undefined && (
              <VerifiedStatus
                verified_status={contact?.verified_status?.dateOfBirth}
              />
            )}
          </div>
        </div>
      )}
      {contact?.gender && (
        <div className="row p-1">
          <div className="col-sm-12 col-md-3 col-lg-3 ">Gender</div>
          <div className="col-sm-12 col-md-9 col-lg-9">
            {genderOptions.map((option) =>
              option.value === contact?.gender ? (
                <span>{option.label}</span>
              ) : (
                ""
              )
            )}
            {contact?.verified_status !== undefined && (
              <VerifiedStatus
                verified_status={contact?.verified_status?.gender}
              />
            )}
          </div>
        </div>
      )}
      {contact?.description && (
        <div className="row p-1">
          <div className="col-sm-12 col-md-3 col-lg-3 "> Description</div>
          <div className="col-sm-12 col-md-9 col-lg-9">
            {contact?.description}
            {contact?.verified_status !== undefined && (
              <VerifiedStatus
                verified_status={contact?.verified_status?.description}
              />
            )}
          </div>
        </div>
      )}
      {contact?.emailArray?.length > 0 && (
        <div className="row p-1 ">
          <div className="col-lg-3 ">Other email</div>
          <div className="col-lg-9">
            {contact?.emailArray?.map((row, index) => {
              return (
                <div>
                  {row.email && (
                    <div className="row mb-2">
                      <div className="col-sm-12 col-md-10 col-lg-10">
                        {" "}
                        <span>
                          {row?.email}
                          {contact?.verified_status !== undefined &&
                            contact?.emailArray[index].email && (
                              <VerifiedStatus
                                verified_status={
                                  contact?.verified_status?.emailArray[index]
                                    .email
                                }
                              />
                            )}
                          <br />
                        </span>{" "}
                      </div>
                      <div className="col-sm-12 col-md-2 col-lg-2">
                        <span>
                          {row?.label?.length > 0
                            ? " (" + row?.label + ")"
                            : ""}
                        </span>
                        <span className="ms-2">
                          {row?.isMain === 1 ? (
                            <i
                              class="fa-solid fa-star"
                              style={{
                                color: "#ffd43b",
                              }}
                            ></i>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {/* Phone Array */}
      {contact?.phoneArray?.length > 0 && (
        <div className="row p-1 ">
          <div className="col-lg-3 ">Other phone</div>
          <div className="col-lg-9">
            {contact?.phoneArray?.map((row, index) => {
              return (
                <div>
                  {row?.fullPhone !== "" && (
                    <div className="row">
                      <div className="col-sm-12 col-md-10 col-lg-10 mb-2">
                        <span className="">
                          {(row?.fullPhone && "+" + row?.fullPhone.replace("+", "")) || ""}
                          {(row?.extphone && ", extn " + row?.extphone) || ""}

                          {contact?.verified_status !== undefined &&
                            contact?.phoneArray[index]?.fullPhone && (
                              <VerifiedStatus
                                verified_status={
                                  contact?.verified_status?.phoneArray[index]
                                    ?.fullPhone
                                }
                              />
                            )}
                          <br />
                        </span>
                      </div>
                      <div className="col-sm-12 col-md-2 col-lg-2">
                        <span>
                          {" "}
                          {row?.type &&
                            phoneOptions.map((option) =>
                              option.value === row?.type ? (
                                <span>{"(" + option?.label + ")"}</span>
                              ) : (
                                ""
                              )
                            )}
                        </span>
                        <span className="ms-2">
                          {row?.isMain === 1 ? (
                            <i
                              class="fa-solid fa-star"
                              style={{
                                color: "#ffd43b",
                              }}
                            ></i>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </div>
                  )}{" "}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {/*Address Array  */}
      {contact?.addressArray?.length > 0 && (
        <div className="row p-1 ">
          <div className="col-lg-3 "> Address</div>
          <div className="col-lg-9">
            {contact?.addressArray?.map((row, index) => {
              return (
                <div className="row mb-2">
                  <div className="col-sm-12 col-md-10 col-lg-10">
                    {" "}
                    <div>
                      <div>
                        {(row?.addressLine1 && row?.addressLine1 + ",") || ""}
                        {contact?.verified_status !== undefined && (
                          <VerifiedStatus
                            verified_status={
                              contact?.verified_status?.addressArray[index]
                                ?.addressLine1
                            }
                          />
                        )}
                      </div>
                      <div>
                        {(row?.addressLine2 && row?.addressLine2 + ",") || ""}
                        {contact?.verified_status !== undefined && (
                          <VerifiedStatus
                            verified_status={
                              contact?.verified_status?.addressArray[index]
                                ?.addressLine2
                            }
                          />
                        )}
                      </div>
                      <div>
                        {(row?.city && row?.city + ",") || ""}{" "}
                        {contact?.verified_status !== undefined && (
                          <VerifiedStatus
                            verified_status={
                              contact?.verified_status?.addressArray[index]
                                ?.city
                            }
                          />
                        )}
                      </div>
                      <div>
                        {(row?.state && row?.state + ",") || ""}{" "}
                        {contact?.verified_status !== undefined && (
                          <VerifiedStatus
                            verified_status={
                              contact?.verified_status?.addressArray[index]
                                .state
                            }
                          />
                        )}
                      </div>
                      <div>
                        {(row?.country && row?.country + ",") || ""}{" "}
                        {contact?.verified_status !== undefined && (
                          <VerifiedStatus
                            verified_status={
                              contact?.verified_status?.addressArray[index]
                                .country
                            }
                          />
                        )}
                      </div>
                      <div>
                        {(row?.postalCode && row?.postalCode + ",") || ""}{" "}
                        {row?.postalCode &&
                          contact?.verified_status !== undefined && (
                            <VerifiedStatus
                              verified_status={
                                contact?.verified_status?.addressArray[index]
                                  .postalCode
                              }
                            />
                          )}
                      </div>
                    </div>{" "}
                  </div>
                  <div className="col-sm-12 col-md-2 col-lg-2">
                    <span>
                      {" "}
                      {row?.addressLabel &&
                        addressOptions.map((option) =>
                          option.value === row?.addressLabel ? (
                            <span>{"(" + option.label + ")"}</span>
                          ) : (
                            ""
                          )
                        )}
                    </span>
                    <span className="ms-2">
                      {row?.isMain === 1 ? (
                        <i
                          class="fa-solid fa-star"
                          style={{
                            color: "#ffd43b",
                          }}
                        ></i>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {/* <hr /> */}
      {contact?.contactAffiliationsArray.length > 0 && (
        <div>
          {contact?.contactAffiliationsArray.map((row, index) => {
            let filterIndex =
              contact?.verified_status?.contactAffiliationsArray?.findIndex(
                (val) =>
                  val?.sourceSystem?.appname === row?.sourceSystem?.appname &&
                  val?.sourceSystem?.appuserid ===
                  row?.sourceSystem?.appuserid &&
                  val?.sourceSystem?.companyid === row?.sourceSystem?.companyid
              );

            return (
              <div>
                {row?.companyName && (
                  <span className="">
                    <span className="p-1 mb-5 fw-bold h4">
                      {row?.companyName}{" "}
                    </span>
                    <span>({row.sourceSystem.appname})</span>
                  </span>
                )}
                {row?.description && (
                  <div className="row p-1">
                    <div className="col-sm-12 col-md-3 col-lg-3 ">
                      {" "}
                      Description
                    </div>
                    <div className="col-sm-12 col-md-9 col-lg-9">
                      {row?.description}
                      {contact?.verified_status !== undefined && (
                        <VerifiedStatus
                          verified_status={
                            contact?.verified_status?.contactAffiliationsArray[
                              filterIndex
                            ]?.description
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
                {row?.contactRole && (
                  <div className="row p-1">
                    <div className="col-sm-12 col-md-3 col-lg-3 ">
                      {" "}
                      Contact role
                    </div>
                    <div className="col-sm-12 col-md-9 col-lg-9">
                      {row?.contactRole}
                      {contact?.verified_status !== undefined && (
                        <VerifiedStatus
                          verified_status={
                            contact?.verified_status?.contactAffiliationsArray[
                              filterIndex
                            ]?.contactRole
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
                {row?.title && (
                  <div className="row p-1">
                    <div className="col-sm-12 col-md-3 col-lg-3 "> Title</div>
                    <div className="col-sm-12 col-md-9 col-lg-9">
                      {row?.title}
                      {contact?.verified_status !== undefined && (
                        <VerifiedStatus
                          verified_status={
                            contact?.verified_status?.contactAffiliationsArray[
                              filterIndex
                            ]?.title
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
                {row?.claimedUrl && (
                  <div className="row p-1">
                    <div className="col-sm-12 col-md-3 col-lg-3 ">
                      {" "}
                      Claimed url
                    </div>
                    <div className="col-sm-12 col-md-9 col-lg-9">
                      {row?.claimedUrl}
                      {contact?.verified_status !== undefined && (
                        <VerifiedStatus
                          verified_status={
                            contact?.verified_status?.contactAffiliationsArray[
                              filterIndex
                            ]?.claimedUrl
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
                {row?.department && (
                  <div className="row p-1">
                    <div className="col-sm-12 col-md-3 col-lg-3 ">
                      {" "}
                      Department
                    </div>
                    <div className="col-sm-12 col-md-9 col-lg-9">
                      {row?.department}
                      {contact?.verified_status !== undefined && (
                        <VerifiedStatus
                          verified_status={
                            contact?.verified_status?.contactAffiliationsArray[
                              filterIndex
                            ]?.department
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
                {row?.reportTo && (
                  <div className="row p-1">
                    <div className="col-sm-12 col-md-3 col-lg-3 ">
                      {" "}
                      Report to
                    </div>
                    <div className="col-sm-12 col-md-9 col-lg-9">
                      {row?.reportTo}
                      {contact?.verified_status !== undefined && (
                        <VerifiedStatus
                          verified_status={
                            contact?.verified_status?.contactAffiliationsArray[
                              filterIndex
                            ]?.reportTo
                          }
                        />
                      )}
                    </div>
                  </div>
                )}

                {row?.employeeID && (
                  <div className="row p-1">
                    <div className="col-sm-12 col-md-3 col-lg-3 ">
                      {" "}
                      Employee ID
                    </div>
                    <div className="col-sm-12 col-md-9 col-lg-9">
                      {row?.employeeID}
                      {contact?.verified_status !== undefined && (
                        <VerifiedStatus
                          verified_status={
                            contact?.verified_status?.contactAffiliationsArray[
                              filterIndex
                            ]?.employeeID
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
                {row?.employeeType && (
                  <div className="row p-1">
                    <div className="col-sm-12 col-md-3 col-lg-3 ">
                      {" "}
                      Employee type
                    </div>
                    <div className="col-sm-12 col-md-9 col-lg-9">
                      {row?.employeeType}
                      {contact?.verified_status !== undefined && (
                        <VerifiedStatus
                          verified_status={
                            contact?.verified_status?.contactAffiliationsArray[
                              filterIndex
                            ]?.employeeType
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
                {row?.employeeHireDate && (
                  <div className="row p-1">
                    <div className="col-sm-12 col-md-3 col-lg-3 ">
                      {" "}
                      Employee hire date
                    </div>
                    <div className="col-sm-12 col-md-9 col-lg-9">
                      {Moment(row?.employeeHireDate.trim()).format(
                        "DD/MM/YYYY"
                      ) || ""}

                      {contact?.verified_status !== undefined && (
                        <VerifiedStatus
                          verified_status={
                            contact?.verified_status?.contactAffiliationsArray[
                              filterIndex
                            ]?.employeeHireDate
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
                {row?.emailArray?.length > 0 && (
                  <div className="row p-1 ">
                    <div className="col-lg-3 ">Email</div>
                    <div className="col-lg-9">
                      {row?.emailArray?.map((row, rowindex) => {
                        return (
                          <div>
                            {row.email && (
                              <div className="row mb-2">
                                <div className="col-sm-12 col-md-10 col-lg-10">
                                  {" "}
                                  <span>
                                    {row?.email}
                                    {row?.email &&
                                      contact?.verified_status !==
                                      undefined && (
                                        <VerifiedStatus
                                          verified_status={
                                            contact?.verified_status
                                              ?.contactAffiliationsArray[
                                              filterIndex
                                            ]?.emailArray[rowindex].email
                                          }
                                        />
                                      )}
                                    <br />
                                  </span>{" "}
                                </div>
                                <div className="col-sm-12 col-md-2 col-lg-2">
                                  <span>
                                    {row?.label?.length > 0
                                      ? " (" + row?.label + ")"
                                      : ""}
                                  </span>
                                  <span className="ms-2">
                                    {row?.isMain === 1 ? (
                                      <i
                                        class="fa-solid fa-star"
                                        style={{
                                          color: "#ffd43b",
                                        }}
                                      ></i>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {/* Phone Array */}
                {row?.phoneArray?.length > 0 && (
                  <div className="row p-1 ">
                    <div className="col-lg-3 ">Phone </div>
                    <div className="col-lg-9">
                      {row?.phoneArray?.map((row, rowindex) => {
                        return (
                          <div>
                            {row?.fullPhone !== "" && (
                              <div className="row">
                                <div className="col-sm-12 col-md-10 col-lg-10 mb-2">
                                  <span className="">
                                    {(row?.fullPhone && "+" + row?.fullPhone.replace("+", "")) ||
                                      ""}
                                    {(row?.extphone &&
                                      ", extn " + row?.extphone) ||
                                      ""}
                                    {row?.fullPhone &&
                                      contact?.verified_status !==
                                      undefined && (
                                        <VerifiedStatus
                                          verified_status={
                                            contact?.verified_status
                                              ?.contactAffiliationsArray[
                                              filterIndex
                                            ]?.phoneArray[rowindex]?.fullPhone
                                          }
                                        />
                                      )}
                                    <br />
                                  </span>
                                </div>
                                <div className="col-sm-12 col-md-2 col-lg-2">
                                  <span>
                                    {" "}
                                    {row?.type &&
                                      phoneOptions.map((option) =>
                                        option.value === row?.type ? (
                                          <span>
                                            {"(" + option?.label + ")"}
                                          </span>
                                        ) : (
                                          ""
                                        )
                                      )}
                                  </span>
                                  <span className="ms-2">
                                    {row?.isMain === 1 ? (
                                      <i
                                        class="fa-solid fa-star"
                                        style={{
                                          color: "#ffd43b",
                                        }}
                                      ></i>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </div>
                              </div>
                            )}{" "}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {/*Address Array  */}
                {row?.addressArray?.length > 0 && (
                  <div className="row p-1 ">
                    <div className="col-lg-3 "> Address</div>
                    <div className="col-lg-9">
                      {row?.addressArray?.map((row, rowindex) => {
                        return (
                          <div className="row mb-2">
                            <div className="col-sm-12 col-md-10 col-lg-10">
                              {" "}
                              <div>
                                <div>
                                  {(row?.addressLine1 &&
                                    row?.addressLine1 + ",") ||
                                    ""}
                                  {row?.addressLine1 &&
                                    contact?.verified_status !== undefined && (
                                      <VerifiedStatus
                                        verified_status={
                                          contact?.verified_status
                                            ?.contactAffiliationsArray[
                                            filterIndex
                                          ]?.addressArray[rowindex]
                                            ?.addressLine1
                                        }
                                      />
                                    )}
                                </div>
                                <div>
                                  {(row?.addressLine2 &&
                                    row?.addressLine2 + ",") ||
                                    ""}
                                  {row?.addressLine2 &&
                                    contact?.verified_status !== undefined && (
                                      <VerifiedStatus
                                        verified_status={
                                          contact?.verified_status
                                            ?.contactAffiliationsArray[
                                            filterIndex
                                          ]?.addressArray[rowindex]
                                            ?.addressLine2
                                        }
                                      />
                                    )}
                                </div>
                                <div>
                                  {(row?.city && row?.city + ",") || ""}
                                  {row?.city &&
                                    contact?.verified_status !== undefined && (
                                      <VerifiedStatus
                                        verified_status={
                                          contact?.verified_status
                                            ?.contactAffiliationsArray[
                                            filterIndex
                                          ]?.addressArray[rowindex]?.city
                                        }
                                      />
                                    )}
                                </div>
                                <div>
                                  {(row?.state && row?.state + ",") || ""}
                                  {row?.state &&
                                    contact?.verified_status !== undefined && (
                                      <VerifiedStatus
                                        verified_status={
                                          contact?.verified_status
                                            ?.contactAffiliationsArray[
                                            filterIndex
                                          ]?.addressArray[rowindex]?.state
                                        }
                                      />
                                    )}
                                </div>
                                <div>
                                  {(row?.country && row?.country + ",") || ""}
                                  {row?.country &&
                                    contact?.verified_status !== undefined && (
                                      <VerifiedStatus
                                        verified_status={
                                          contact?.verified_status
                                            ?.contactAffiliationsArray[
                                            filterIndex
                                          ]?.addressArray[rowindex]?.country
                                        }
                                      />
                                    )}
                                </div>
                                <div>
                                  {(row?.postalCode && row?.postalCode + ",") ||
                                    ""}
                                  {row?.postalCode &&
                                    contact?.verified_status !== undefined && (
                                      <VerifiedStatus
                                        verified_status={
                                          contact?.verified_status
                                            ?.contactAffiliationsArray[
                                            filterIndex
                                          ]?.addressArray[rowindex]?.postalCode
                                        }
                                      />
                                    )}
                                </div>
                              </div>{" "}
                            </div>
                            <div className="col-sm-12 col-md-2 col-lg-2">
                              <span>
                                {" "}
                                {row?.addressLabel &&
                                  addressOptions.map((option) =>
                                    option.value === row?.addressLabel ? (
                                      <span>{"(" + option.label + ")"}</span>
                                    ) : (
                                      ""
                                    )
                                  )}
                              </span>
                              <span className="ms-2">
                                {row?.isMain === 1 ? (
                                  <i
                                    class="fa-solid fa-star"
                                    style={{
                                      color: "#ffd43b",
                                    }}
                                  ></i>
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <hr />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
export default ViewContact;
