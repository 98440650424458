import React from "react";
import Modal from 'react-modal';
const customModalStyles = {
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // 50% transparent black overlay
  },
  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    border: "none", // No border
    padding: "20px", // Adjust the padding as needed
    borderRadius: "4px",
    width: "200px", // Set a specific width for the dialog
    height: "150px", // Set a specific height for the dialog
    outline: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};

const LoadingDialog = ({ message, isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customModalStyles}
      ariaHideApp={false}
    >
      <span className="mb-1 fw-bold">Contact Sync</span>
      <span className="">{message ? message : "Loading..."}</span>
      <div className="spinner-border text-primary" role="status">

        <span className="sr-only"></span>

      </div>
    </Modal>
  );
};

export default LoadingDialog;
