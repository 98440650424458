import { useEffect, useState, useMemo } from "react";
import { Button } from "react-bootstrap";
import GenericListTable from "../../components/gridTable/genericLlistTable";

import { resendSignupURLGenerate } from "../../services/contactVerificationTask_services";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { useRealmApp } from "../../providers/RealmApp";
import { getImportedStatus } from "../../services/import_contact_task_services";
import { useAuth0 } from "@auth0/auth0-react";
import customstyles from "../../components/customstyles/react-select-styles";
import { getappcompanylistbyappname_userid } from "../../services/applications_services";
import { appConstants } from "../../constants/app_constants";
import Moment from "moment";
import { getuserbyauthproviderid } from "../../services/users_services";
import PageSubHeader from "../../components/pagesubheader";
function QuickbooksImportStatus() {
  const [importContactstatus, setimportcontactstatus] = useState([]);
  const _appname = "QuickBooks";

  const ctechapp = useRealmApp();
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  var [companylist, setCompanyList] = useState([]);
  const [selectedcompany, setSelectedCompany] = useState("DEFAULT");
  const [isTokenLinkSend, setisTokenLinkSend] = useState(false);
  const [isInvaildToken, setisInvaildToken] = useState(false);
  const [vaildmsg, setvaildmsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userinfo, setUserinfo] = useState({
    role: "",
  });
  const [contactstatus, setcontactstatus] = useState({
    company_ID: "",
    fromdate: "",
    todate: "",
    total_days: "",
  });
  const [errors, setErrors] = useState({
    company_ID: "",
    fromdate: "",
    todate: "",
    total_days: "",
  });
  const data = useMemo(() => [...importContactstatus], [importContactstatus]);
  const appColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "includes",
      },

      {
        Header: "First name ",
        accessor: "firstname",
        sortable: true,
        width: "20%",
        Filter: DefaultColumnFilter,
        filter: "value",
        Cell: ({ value, row }) => {
          return <span>{row.original.contact_info.firstname}</span>;
        },
      },
      {
        Header: "Last name ",
        accessor: "lastname",
        sortable: true,
        width: "20%",
        Filter: DefaultColumnFilter,
        Cell: ({ value, row }) => {
          return <span>{row.original.contact_info.lastname}</span>;
        },
      },

      {
        Header: "Email",
        accessor: "email",
        sortable: true,
        width: "30%",
        Filter: DefaultColumnFilter,
        filter: "value",
        Cell: ({ value, row }) => {
          return <span>{row.original.contact_info.email}</span>;
        },
      },
      {
        Header: "Phone",
        accessor: "phone",
        sortable: true,
        width: "30%",
        Filter: DefaultColumnFilter,
        filter: "value",
        Cell: ({ value, row }) => {
          return <span>{row.original.contact_info.phone}</span>;
        },
      },
      {
        Header: "Imported date",
        accessor: "created_date",
        sortable: true,
        width: "30%",
        Filter: DefaultColumnFilter,
        filter: "value",
        Cell: ({ value }) => Moment(value).format(appConstants.DATEFORMAT),
      },
      {
        Header: "Status",
        accessor: "status",
        sortable: true,
        width: "30%",
        Filter: DefaultColumnFilter,
        filter: "value",
        Cell: ({ value, row }) => {
          return <span>{row.original.status}</span>;
        },
      },
      {
        Header: "Contact verification",
        accessor: "verification",
        sortable: true,
        width: "30%",
        Filter: DefaultColumnFilter,
        filter: "value",
        Cell: ({ value, row }) => {
          return <span>{row.original.verification}</span>;
        },
      },
      {
        Header: "Admin verification",
        accessor: "adminverification",
        sortable: true,
        width: "30%",
        Filter: DefaultColumnFilter,
        filter: "value",
        Cell: ({ value, row }) => {
          return <span>{row.original?.adminverification}</span>;
        },
      },
      {
        Header: "Action",
        Filter: "",
        filter: "",
        Cell: ({ value, row }) => {
          const onReSendLinkItemClick = () => {
            handleSendLinkClickApps(row.original._id);
          };

          return (
            <div className="d-flex gap-1">
              <button
                hidden={row.original.verification !== "Pending"}
                className="btn btn-primary btn-sm"
                onClick={onReSendLinkItemClick}
              >
                Re-send
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  const hiddenappColumns = ["_id"];

  const handleSendLinkClickApps = (_id) => {
    ResendSignupURL(_id);
  };
  async function ResendSignupURL(_id) {
    await resendSignupURLGenerate(_id, ctechapp.currentUser, user.sub).then(
      (response) => {
        if (response !== undefined) {
          let url_info = JSON.parse(response);
          //  console.log("url_info", url_info);
          if (url_info !== "Invaild Token") {
            setisTokenLinkSend(true);
          } else {
            setisInvaildToken(true);
          }
        }
      }
    );
  }

  const handleSucessSweetAlert = () => {
    setisInvaildToken(false);
    setisTokenLinkSend(false);
  };
  useEffect(() => {
    if (isAuthenticated) {
      getUserInfoByID();
      getCompanyListData();
    }
  }, [userinfo.role]);

  useEffect(() => {
    if (isAuthenticated && companylist.length > 0) {
      handleAutoSubmit();
    }
  }, [companylist]);

  useEffect(() => {
    if (contactstatus.fromdate && contactstatus.todate !== "") {
      findtotaldays();
    } else {
      contactstatus.total_days = "1";
    }
  }, [contactstatus.fromdate, contactstatus.todate]);
  const findtotaldays = () => {
    errors.total_days = "";
    var date1, date2;
    //  console.log( "from_date", leaverequest.from_date,"to_date", leaverequest.to_date);
    date1 = new Date(contactstatus.fromdate);
    date2 = new Date(contactstatus.todate);
    //calculate time difference
    var time_difference = date2.getTime() - date1.getTime();
    var totaldays = 1 + time_difference / (1000 * 60 * 60 * 24);
    if (Number.isNaN(totaldays)) {
      totaldays = 0;
    }
    var total_days = String(totaldays);
    setcontactstatus({ ...contactstatus, ["total_days"]: total_days });
  };
  //error handling functions
  const handleSubmit = async (e) => {
    let _contactstatus = { ...contactstatus };
    e.preventDefault();
    if (validateForm()) {
      getData(_contactstatus);
    }
  };
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      // console.log(valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      company_ID:
        contactstatus.company_ID === ""
          ? (iserror = appConstants.import.handlefieldvalidation.companyid)
          : errors.company_ID,
      fromdate:
        contactstatus.fromdate === ""
          ? (iserror = appConstants.import.handlefieldvalidation.fromdate)
          : errors.fromdate,
      todate:
        contactstatus.todate === ""
          ? (iserror = appConstants.import.handlefieldvalidation.todate)
          : errors.todate,

      total_days:
        contactstatus.total_days <= 0
          ? (iserror = appConstants.import.handlefieldvalidation.totaldays)
          : errors.total_days,
    });
    // console.log(iserror);
    return iserror.length === 0;
  };

  async function getUserInfoByID() {
    try {
      setLoading(true);
      getuserbyauthproviderid(user.sub, ctechapp.currentUser).then(
        (response) => {
          let user_info = JSON.parse(response);
          if (response !== undefined || user_info.length !== 0) {
            setUserinfo(user_info[0]);
          }
        }
      );
    } catch (exception) {
      setLoading(false);
      console.error(exception);
    }
  }

  async function getData(contactstatus) {
    getImportedStatus(
      _appname,
      contactstatus,
      "apiinfo.companyid",
      ctechapp.currentUser,
      user.sub
    ).then((response) => {
      // console.log("response", response);
      if (response !== undefined) {
        let user_list = JSON.parse(response);
        if (user_list.length > 0) {
          //  console.log("user_list", user_list);
          setimportcontactstatus(user_list);
          setvaildmsg(false);
        } else {
          setimportcontactstatus([]);
          setvaildmsg(true);
        }
      }
    });
  }

  async function getCompanyListData() {
    getappcompanylistbyappname_userid(
      _appname,
      ctechapp.currentUser,
      user.sub
    ).then(async (response) => {
      if (response !== undefined) {
        let company_info = JSON.parse(response);

        let options = await company_info.map(function (company) {
          return {
            value: company.appaccountid,
            label: company.appuserinfo[0].appdisplayname,
          };
        });

        //  console.log("options ", options);

        setCompanyList(options);
      }
    });
  }

  const handleAutoSubmit = () => {
    let _contactstatus = { ...contactstatus };
    _contactstatus.company_ID = companylist[0].value;
    var startdate = Moment().subtract(50, "days").startOf("day");
    _contactstatus.fromdate = Moment(startdate).format().split("T")[0];
    _contactstatus.todate = Moment().format().split("T")[0];
    setcontactstatus(_contactstatus);
    getData(_contactstatus);
  };
  // const handleCompanyChange = (event) => {
  //   setSelectedCompany(event.target.value);
  // };
  const handleDropDownChange = (selectedOption) => {
    const { name, value } = selectedOption.target;
    setcontactstatus({ ...contactstatus, [name]: value });
    setErrors({ ...errors, [name]: "" });
    // console.log(`Option selected:`, selectedOption);
  };
  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setcontactstatus({ ...contactstatus, [name]: value });
    handleFieldValidation(event);
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
  };
  //console.log("contactstatus", contactstatus);

  // Define a default UI for filtering
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;

    return (
      <input
        className="form-control"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
      />
    );
  }

  return (
    <div className="mt-3">
      <PageSubHeader title={"Quickbooks import status"} />

      <hr></hr>

      <div className="d-flex ustify-content-start align-items-center row mb-3">
        <div className=" col-sm-12 col-lg-2">
          <label className="">
            Account display name<label className="text-danger">*</label> :
          </label>
        </div>
        <div className="col-sm-12 col-lg-3">
          <Select
            width={"10%"}
            name="company_ID"
            id="company_ID"
            styles={customstyles}
            className="form-control"
            options={companylist}
            value={companylist?.filter(
              (record) => record.value === contactstatus?.company_ID
            )}
            onChange={(val) => {
              handleDropDownChange({
                target: {
                  name: "company_ID",
                  value: val.value,
                },
              });
            }}
          ></Select>
          {errors.company_ID.length > 0 && (
            <small
              className="form-check-label text-danger"
              htmlFor="company_ID"
            >
              {errors.company_ID}
            </small>
          )}
        </div>
        <div className="col-sm-12 col-lg-1 col-xs-2">
          <label> From date :</label>{" "}
        </div>
        <div className="col-sm-12 col-lg-2">
          <input
            type="date"
            className="form-control"
            id="fromdate"
            value={contactstatus?.fromdate}
            max={Moment().format(contactstatus?.todate, "YYYY-MM-DD")}
            onChange={handleChange}
            name="fromdate"
          />
          {errors.fromdate.length > 0 && (
            <small className="form-check-label text-danger" htmlFor="fromdate">
              {errors.fromdate}
            </small>
          )}
        </div>
        <div className="col-sm-12 col-lg-1 col-xs-2">
          <label>To date :</label>{" "}
        </div>
        <div className="col-sm-12 col-lg-2">
          <input
            type="date"
            className="form-control"
            id="todate"
            value={contactstatus?.todate}
            min={Moment().format(contactstatus?.fromdate, "YYYY-MM-DD")}
            name="todate"
            onChange={handleChange}
          />
          {errors.todate.length > 0 && (
            <small className="form-check-label text-danger" htmlFor="todate">
              {errors.todate}
            </small>
          )}
        </div>
        <div className="col-sm-12 col-lg-1">
          <Button className="btn btn-success" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </div>
      {errors.total_days.length > 0 && (
        <small className="form-check-label text-danger" htmlFor="total_days">
          {errors.total_days}
        </small>
      )}
      <div>
        <GenericListTable
          gcolumns={appColumns}
          gdata={data.length > 0 ? JSON.stringify(data) : ""}
          ghiddencolumns={hiddenappColumns}
          gplaceholder={"Search quickbooks import contact status..."}
        />
        {vaildmsg && (
          <div className="form-check-label  text-center" htmlFor="validation">
            <h3 className="text-danger ">
              {appConstants.import.handlefieldvalidation.nodata}
            </h3>
          </div>
        )}
      </div>
      <div>
        {isTokenLinkSend && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title={appConstants.import.alertmsg.newlink}
            onConfirm={handleSucessSweetAlert}
          ></SweetAlert>
        )}
        {isInvaildToken && (
          <SweetAlert
            danger
            style={{ color: "black" }}
            title={appConstants.import.alertmsg.errorlink}
            onConfirm={handleSucessSweetAlert}
          ></SweetAlert>
        )}
      </div>
    </div>
  );
}

export default QuickbooksImportStatus;
