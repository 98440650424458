const customstyles = {
  control: (customstyles) => ({
    // backgroundColor: 'lightgray',
    color: "black",
    //  border: '1px solid black',
    //  borderRadius: '2px',
    height: "24px",
    display: "flex",
  }),
  indicatorSeparator: (customstyles) => ({
    backgroundColor: "transparent",
  }),
  input: (customstyles) => ({
    //color: 'black',
    fontWeight: "600",
    gridArea: "1/1/2/3",
    flex: "1 1 auto",
    //display: 'inline-grid',
    margin: "0px",
    gridTemplateColumns: "0 min-content",
    boxSizing: "content-box",
    paddingTop: "0px",
    paddingBottom: "0px",
    visibility: "visible",
  }),
  singleValue: (customstyles) => ({
    color: "black",
    gridArea: "1/1/2/3",
    marginLeft: "2px",
    marginRight: "2px",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    boxSizing: "border-box",
  }),
  placeholder: (customstyles) => ({
    //color: 'rgba('lightgray', .5)',
    fontWeight: "2px",
    gridArea: "1/1/2/3",
  }),
  menu: (customstyles) => ({
    backgroundColor: "#ced4da",
    position: "absolute",
    top: "100%",
    borderRadius: "2px",
    margin: "8px 0",
    zIndex: "1",
    boxSizing: "border-box",
    width: "100%",
  }),
  option: (customstyles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      backgroundColor: isFocused ? "#99a6b2" : "lighgray",
      color: "black",
      cursor: isDisabled ? "not-allowed" : "default",
      padding: "8px 12px",
    };
  },
};
export default customstyles;
