// import Table from "react-bootstrap/Table";
// import { useEffect, useState } from "react";
// import customstyles from "../../components/customstyles/react-select-styles";
// import Loading from "../../components/Loading";

// import { Button, Form } from "react-bootstrap";

// import { useRealmApp } from "../../providers/RealmApp";
// import {
//   getapisettingsbyappname_createdby,
//   getapisettingbyappname_user,
// } from "../../services/api_settings_services";
// import { getapiusersettingsbyappname_userid_companyid } from "../../services/api_user_settings_services";
// import {
//   getapiusersettingsbyappname_email_companyid,
//   insertapiusersettings,
// } from "../../services/api_user_settings_services";
// import {
//   getusersbyemail,
//   getuser,
//   insertuser,
//   getuserbyauthproviderid,
// } from "../../services/users_services";
// import { getProcoreuserlistbycompanyid } from "../../services/procore_contact_services";
// import {
//   getEmailnotificationusersbyemail,
//   insertemail_notification_user,
// } from "../../services/email_notification_users_services";
// import { useAuth0 } from "@auth0/auth0-react";
// const _appname = "Procore";

function ProcoreContacts() {
  // const [selectedcompany, setSelectedCompany] = useState("DEFAULT");
  // const [password, setpassword] = useState("test123=0");
  // var [companylist, setCompanyList] = useState([]);
  // const [userlist, setUserList] = useState([]);
  // var [procoreuserlist, setProcoreuserlist] = useState([]);
  // var [apiuserlist, setApiuserlist] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [order, SetOrder] = useState("ASC");
  // const [result, SetResult] = useState("0");
  // const ctechapp = useRealmApp();
  // const [errordisplay, SetErrorDisplay] = useState("");
  // let [userackid, SetUserAckId] = useState("0");
  // var sortJsonArray = require("sort-json-array");
  // const [userinfo, setUserinfo] = useState({
  //   role: "",
  // });
  // var array = [];
  // const sorting = (col) => {
  //   if (order === "ASC") {
  //     const sorted = userlist.sort((a, b) =>
  //       a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
  //     );
  //     setUserList(sorted);
  //     SetOrder("DSC");
  //   }
  //   if (order === "DSC") {
  //     const sorted = userlist.sort((a, b) =>
  //       a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
  //     );
  //     setUserList(sorted);
  //     SetOrder("ASC");
  //   }
  // };
  // const { isLoading, error, isAuthenticated, user } = useAuth0();
  // async function getUserInfoByID() {
  //   try {
  //     setLoading(true);
  //     getuserbyauthproviderid(user.sub, ctechapp.currentUser).then(
  //       (response) => {
  //         let user_info = JSON.parse(response);
  //         if (response !== undefined || user_info.length !== 0) {
  //           setUserinfo(user_info[0]);
  //         }
  //       }
  //     );
  //   } catch (exception) {
  //     setLoading(false);
  //     console.log(exception);
  //   }
  // }
  // useEffect(() => {
  //   if (isAuthenticated) {
  //     getUserInfoByID();
  //     getCompanyListData(ctechapp.currentUser);
  //   }
  // }, []);
  // useEffect(() => {}, [errordisplay]);
  // useEffect(() => {
  //   if (isAuthenticated) {
  //     getDatauserinfobyCompanyid(ctechapp.currentUser);
  //   }
  // }, [selectedcompany]);
  // async function getCompanyListData() {
  //   if (userinfo.role === "2") {
  //     getapisettingsbyappname_createdby(
  //       _appname,
  //       user.sub,
  //       ctechapp.currentUser,
  //       user.sub
  //     ).then((response) => {
  //       if (response !== undefined) {
  //         let company_info = JSON.parse(response);
  //         setCompanyList(company_info);
  //       }
  //     });
  //   } else {
  //     getapisettingbyappname_user(
  //       _appname,
  //       user.sub,
  //       ctechapp.currentUser
  //     ).then((response) => {
  //       if (response !== undefined) {
  //         let company_info = JSON.parse(response);
  //         //  setCompanyList(company_info[0]);
  //         const companyuserList = [];
  //         //apisettingslist.forEach((apps) => {
  //         for (const apicompany of company_info) {
  //           if (apicompany.users.length !== 0) {
  //             const {
  //               users: [{ firstname, lastname }],
  //             } = apicompany;
  //             apicompany["displaynamevalue"] =
  //               " (" + firstname + " " + lastname + ")";
  //           } else {
  //             apicompany["displaynamevalue"] = "";
  //           }
  //           companyuserList.push(apicompany);
  //         }
  //         setCompanyList(companyuserList);
  //         //  setList(await rests.find())
  //         setLoading(false);
  //       }
  //     });
  //   }
  // }
  // function getDatauserinfobyCompanyid() {
  //   try {
  //     if (selectedcompany !== "DEFAULT") {
  //       getapiusersettingsbyappname_userid_companyid(
  //         _appname,
  //         user.sub,
  //         selectedcompany,
  //         ctechapp.currentUser,
  //         user.sub
  //       ).then((response) => {
  //         if (response !== undefined || response !== "null") {
  //           let company_info = JSON.parse(response);
  //           let res = company_info[0];
  //           setUserList([]);
  //           getUserListData(
  //             res.apiaccesstokenhostname,
  //             res.restapihostname,
  //             selectedcompany,
  //             res.apiclientid,
  //             res.apiclientsecret,
  //             res.apirefreshtoken,
  //             res.apiapptoken,
  //             res.apiredirecturi
  //           );
  //         }
  //       });
  //     }
  //   } catch (Exception) {
  //     console.log("getDatauserinfobyCompanyid error : ", Exception);
  //   }
  // }
  // async function getUserListData(
  //   procoreAccessTokenHostName,
  //   procoreRestAPIHostName,
  //   procoreCompanyID,
  //   procoreClientID,
  //   procoreClientSecret,
  //   procoreRefreshToken,
  //   procoreAppToken,
  //   procoreRedirectUri
  // ) {
  //   getProcoreuserlistbycompanyid(
  //     procoreCompanyID,
  //     procoreAccessTokenHostName,
  //     procoreClientID,
  //     procoreClientSecret,
  //     procoreRefreshToken,
  //     procoreAppToken,
  //     procoreRedirectUri,
  //     procoreRestAPIHostName,
  //     ctechapp.currentUser,
  //     user.sub
  //   ).then((response) => {
  //     if (response !== undefined || response.length !== 0) {
  //       setProcoreuserlist(response);
  //       getapiDatauserinfo(procoreCompanyID, response);
  //       setLoading(false);
  //     }
  //   });
  // }
  // function getapiDatauserinfo(companyid, _procoreuserslist) {
  //   getuser(ctechapp.currentUser, user.sub).then((response) => {
  //     if (response !== undefined) {
  //       let user_list = JSON.parse(response);
  //       setApiuserlist(user_list);
  //       GetBothUserList(_procoreuserslist, user_list);
  //     }
  //   });
  // }
  // async function GetBothUserList(_procoreuserslist, _apiuserlist) {
  //   //  setUserList([]);
  //   var _usersList = [];
  //   //_apiuserlist.forEach((apiuserlist) => {
  //   for (const apiuser of _apiuserlist) {
  //     let userid = apiuser.userid;
  //     let id = userid;
  //     let firstname = apiuser.firstname;
  //     let lastname = apiuser.lastname;
  //     let email = apiuser.email;
  //     let phone = apiuser.phone;
  //     let source = "Contact Master";
  //     var _user = {};
  //     _user["id"] = id;
  //     _user["firstname"] = firstname;
  //     _user["lastname"] = lastname;
  //     _user["email"] = email;
  //     _user["phone"] = phone;
  //     _user["source"] = source;
  //     _usersList.push(_user);
  //   }
  //   for (const apiusersettings of _procoreuserslist) {
  //     let id = apiusersettings.id;
  //     let firstname = apiusersettings.first_name;
  //     let lastname = apiusersettings.last_name;
  //     let email = apiusersettings.email_address;
  //     let phone = apiusersettings.business_phone;
  //     let source = apiusersettings.source;
  //     var _user = {};
  //     _user["id"] = id;
  //     _user["firstname"] = firstname;
  //     _user["lastname"] = lastname;
  //     _user["email"] = email;
  //     _user["phone"] = phone;
  //     _user["source"] = source;
  //     _usersList.push(_user);
  //   }
  //   let result = _procoreuserslist.includes("errors");
  //   if (result === true) {
  //     var _displaymsg = _procoreuserslist.replace(/"/g, "");
  //     _displaymsg = _displaymsg.replace("{errors:", "");
  //     _displaymsg = _displaymsg.replace("}", "");
  //     SetErrorDisplay(_displaymsg);
  //   }
  //   try {
  //     setUserList(sortJsonArray(_usersList, "email"));
  //   } catch (error) {}
  // }
  // async function EmailUserSend() {
  //   // array.forEach((arruser) => {
  //   for (const arruser of array) {
  //     let firstname = arruser.firstname;
  //     let lastname = arruser.lastname;
  //     let email = arruser.email;
  //     let phone = arruser.phone;
  //     let approveuserid = user.sub;
  //     let ackid = arruser.id;
  //     let ackdate = new Date();
  //     var user_ack_id_value = "0";
  //     console.log("email : " + email);
  //     getEmailnotificationusersbyemail(
  //       email,
  //       ctechapp.currentUser,
  //       user.sub
  //     ).then((response) => {
  //       let user_info = JSON.parse(response);
  //       if (user_info.length !== 0) {
  //         console.log("Record Exists , Email : " + email);
  //       } else {
  //         insertemail_notification_user(
  //           firstname,
  //           lastname,
  //           email,
  //           phone,
  //           approveuserid,
  //           ackid,
  //           ackdate,
  //           selectedcompany,
  //           ctechapp.currentUser,
  //           user.sub
  //         ).then((result) => {
  //           if (result !== undefined) {
  //             user_ack_id_value = result.insertedId;
  //             SetUserAckId(result.insertedId);
  //             console.log("Record Inserted ID : " + result.insertedId);
  //             console.log("email_notification_users Created Successfully.");
  //             var fullname = firstname + " " + lastname;
  //             var query =
  //               "fullname=" +
  //               fullname +
  //               "&email=" +
  //               email +
  //               "&userackid=" +
  //               result.insertedId;
  //             fetch("/ApproveEmail?" + query)
  //               .then((res) => {
  //                 if (res.ok) {
  //                   {
  //                     ResultUpdate("1");
  //                   }
  //                   return res.json();
  //                 }
  //               })
  //               .then((data) => {
  //                 //setProcoreuserlist(JSON.parse(data.message));
  //                 {
  //                   ResultUpdate("1");
  //                 }
  //                 setLoading(false);
  //               })
  //               .catch((err) => {
  //                 console.log("EmailUserSend : " + err);
  //                 setLoading(false);
  //               });
  //           }
  //           alert("Email notification sent successfully.");
  //         });
  //       }
  //     });
  //   }
  // }
  // const handleCompanyChange = (event) => {
  //   setLoading(true);
  //   setSelectedCompany(event.target.value);
  // };
  // const ResultUpdate = (res2) => {
  //   SetResult(res2);
  // };
  // const handlechangecheckbox = (
  //   idvalue,
  //   firstname,
  //   lastname,
  //   email,
  //   phone,
  //   source,
  //   checkedstatus
  // ) => {
  //   // console.log("idvalue : " + idvalue + ", status : " + checkedstatus);
  //   //("input:checked").each(function () {
  //   if (checkedstatus === true) {
  //     var _user = {};
  //     _user["id"] = idvalue;
  //     _user["firstname"] = firstname;
  //     _user["lastname"] = lastname;
  //     _user["email"] = email;
  //     _user["phone"] = phone;
  //     _user["source"] = source;
  //     _user["checkedstatus"] = checkedstatus;
  //     array.push(_user);
  //   } else {
  //     const filteredPersons = array.filter((person) => person.id !== idvalue);
  //     array = filteredPersons;
  //   }
  //   //});
  //   // console.log(array.length);
  //   // console.log(array);
  //   //var listArray = [];
  // };
  // async function approveclick() {
  //   //console.log("arr : "+array.length);
  //   //console.log(array);
  //   // array.forEach((arruser) => {
  //   for (const arruser of array) {
  //     // console.log("apiusersettings.id : " + apiusersettings.id);
  //     let id = arruser.id;
  //     let firstname = arruser.firstname;
  //     let lastname = arruser.lastname;
  //     let email = arruser.email;
  //     let phone = arruser.phone;
  //     let source = arruser.source;
  //     let approveuserid = user.sub;
  //     let ackid = arruser.id;
  //     let ackdate = new Date();
  //     let finduser = [];
  //     await getusersbyemail(arruser.email, ctechapp.currentUser, user.sub).then(
  //       (response) => {
  //         finduser = JSON.parse(response);
  //       }
  //     );
  //     if (finduser.length !== 0) {
  //       const res = finduser[0];
  //       await api_user_settings_insert(email, ackid, res.userid);
  //       ResultUpdate("1");
  //       console.log("Updated email , " + arruser.email + " : " + res.userid);
  //     } else {
  //       ctechapp
  //         .CreateUserWithoutlogout(arruser.email, password)
  //         .then((newuserid) => {
  //           insertuser(
  //             newuserid,
  //             firstname,
  //             lastname,
  //             email,
  //             phone,
  //             ctechapp.currentUser,
  //             user.sub
  //           )
  //             .then((response) => {
  //               if (response != undefined) {
  //                 api_user_settings_insert(email, ackid, newuserid).then(
  //                   (apiuserinsertstatus) => {
  //                     ResultUpdate("1");
  //                     console.log("User Created Successfully.");
  //                   }
  //                 );
  //               }
  //             })
  //             .catch((err) => {
  //               console.log("error : " + err);
  //               setLoading(false);
  //             });
  //         });
  //     }
  //   }
  //   alert("Users has been approved successfully");
  // }
  // async function api_user_settings_insert(email, procoreuserid, realmuserid) {
  //   let procoreappanmestatic = "Procore";
  //   getapiusersettingsbyappname_userid_companyid(
  //     _appname,
  //     user.sub,
  //     selectedcompany,
  //     ctechapp.currentUser,
  //     user.sub
  //   ).then((response) => {
  //     if (response !== undefined || response !== null) {
  //       const result = JSON.parse(response);
  //       const res = result[0];
  //       getapiusersettingsbyappname_email_companyid(
  //         procoreappanmestatic,
  //         email,
  //         selectedcompany,
  //         ctechapp.currentUser,
  //         user.sub
  //       ).then((response1) => {
  //         const response2 = JSON.parse(response1);
  //         if (
  //           response2 === null ||
  //           response2.length === 0 ||
  //           response2 === undefined
  //         ) {
  //           insertapiusersettings(
  //             realmuserid,
  //             email,
  //             procoreappanmestatic,
  //             res.restapihostname,
  //             res.apigranttype,
  //             selectedcompany,
  //             res.apiclientid,
  //             res.apiclientsecret,
  //             "",
  //             res.apiaccesstokenhostname,
  //             res.apiredirecturi,
  //             res.appenvironment,
  //             procoreuserid,
  //             user.sub,
  //             ctechapp.currentUser
  //           ).then((response) => {
  //             if (response !== undefined) {
  //               console.log("Procore User ID : ", procoreuserid);
  //             }
  //           });
  //         }
  //       });
  //     }
  //   });
  // }
  // return companylist.length > 0 ? (
  //   <div>
  //     {loading && <Loading />}
  //     <h3 align="center">Procore Contacts</h3>
  //     <div className=" row mb-3">
  //       <label className="col-2 text-end">Account Display Name:</label>
  //       <div className="col-sm-3">
  //         <select
  //           value={selectedcompany}
  //           defaultValue={"DEFAULT"}
  //           styles={customstyles}
  //           className="form-select form-control"
  //           onChange={handleCompanyChange}
  //         >
  //           <option value="DEFAULT" disabled>
  //             Select company
  //           </option>
  //           {companylist.map((companylist) => (
  //             <option value={companylist.apiappcompanyid}>
  //               {companylist.appdisplayname}
  //               {companylist.displaynamevalue}
  //             </option>
  //           ))}
  //         </select>
  //       </div>
  //     </div>
  //     <label for="error1">{errordisplay}</label>
  //     <Table striped bordered hover>
  //       <thead className="tablehead">
  //         <tr>
  //           <th></th>
  //           <th onClick={() => sorting("firstname")}>First Name</th>
  //           <th onClick={() => sorting("lastname")}>Last Name</th>
  //           <th onClick={() => sorting("email")}>Email</th>
  //           <th>Phone</th>
  //           <th>Source</th>
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {userlist.map((row) => (
  //           <tr>
  //             <td>
  //               <input
  //                 type="checkbox"
  //                 onClick={(e) =>
  //                   handlechangecheckbox(
  //                     row.id,
  //                     row.firstname,
  //                     row.lastname,
  //                     row.email,
  //                     row.phone,
  //                     row.source,
  //                     e.target.checked
  //                   )
  //                 }
  //                 name="selectoption"
  //                 value={row.id}
  //               />
  //             </td>
  //             <td width="5">{row.firstname}</td>
  //             <td> {row.lastname}</td>
  //             <td> {row.email}</td>
  //             <td>{row.phone}</td>
  //             <td>{row.source}</td>
  //           </tr>
  //         ))}
  //       </tbody>
  //     </Table>
  //     <Button
  //       id="approve"
  //       value="Approve"
  //       onClick={() => approveclick()}
  //       className="btn btn-sm"
  //     >
  //       Approve
  //     </Button>
  //     <Button
  //       style={{ margin: 20 }}
  //       value="Send Notification"
  //       id="Send Notification"
  //       onClick={() => EmailUserSend()}
  //       className="btn btn-sm"
  //     >
  //       Send Notification
  //     </Button>
  //   </div>
  // ) : (
  //   <div>Procore contact doesn't have access.</div>
  // );
}

export default ProcoreContacts;
