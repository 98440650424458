//services for getLinkedinuserhistoryByID

export const getLinkedinuserhistoryByID = async (id, Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.getLinkedinuserhistoryByID(
      id,
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

//services for insertemail_notification_user
export const insertlinkedinuserhistory = async (
  clientid,
  clientsecret,
  redirecturi,
  firstname,
  lastname,
  email,
  phone,
  Ctechapp,
  loggedinby
) => {
  try {
    const userdata = {
      clientid: clientid,
      clientsecret: clientsecret,
      redirecturi: redirecturi,
      firstname: firstname,
      lastname: lastname,
      email: email,
      phone: phone,
    };
    const data = await Ctechapp.functions.insertemail_notification_user(
      JSON.stringify(userdata),
      loggedinby
    );
    return data;
  } catch (error) {
    console.log("insertlinkedinuserhistory error : ", error);
    throw Error(error);
  }
};

//services for deletelinkedinuserhistorybyid
export const deletelinkedinuserhistorybyid = async (
  _id,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.deletelinkedinuserhistorybyid(
      _id,
      loggedin_by
    );
    if (data !== undefined) {
      //  console.log("data of deletelinkedinuserhistorybyid: " + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
