import { Button, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useRealmApp } from "../../providers/RealmApp";
import {
  getContactDetailSettingsByID,
  updateGeneralSettingByID,
  getContactDetailAffiliationLookupByID,
  updateContactDetailSettingsByID,
  getGeneralSettingByAuthID,
} from "../../services/contactDetailsServices";
import { useHistory } from "react-router";
import {
  getAdminUserListByUserID,
  getuserbyauthproviderid,
} from "../../services/users_services";
import ContactAdminCard from "../../components/ContactAdminCard";
import { useAuth0 } from "@auth0/auth0-react";
import SweetAlert from "react-bootstrap-sweetalert";
import { isEmail } from "validator";
import Select from "react-select";
import customstyles from "../../components/customstyles/react-select-styles";
import Loading from "../../components/Loading";
import FileBase from "react-file-base64";
import { v4 as uuidv4 } from "uuid";
import PageSubHeader from "../../components/pagesubheader";
import { appConstants } from "../../constants/app_constants";
function GeneralSettings() {
  const history = useHistory();
  const ctechapp = useRealmApp();
  const initialContactSettngs = {
    companyName: "",
    companyLogo: "",
    autoApprovalVerification: false,

  };
  const initialContactSettngsArray = {
    _id: "",
    settings: [initialContactSettngs],
  };
  const [contactSettingsArray, setcontactSettingsArray] = useState(
    initialContactSettngsArray
  );
  const [contactSettings, setcontactSettings] = useState(initialContactSettngs);

  const [errors, setErrors] = useState({
    companyLogo: "",
  });
  const [loading, setLoading] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [isFailure, setisFailure] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [validatemsg, SetValidatemsg] = useState("");
  const [isvaliddata, setisvaliddata] = useState(false);
  const [userInfoLoggedin, setUserInfoLoggedin] = useState({
    _id: "",
    userid: "",
    authId: "",
    role: "",
    authId_sub: [],
  });
  const [affArray, setaffArray] = useState([]);
  const [companyList, setcompanyList] = useState([]);
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  const [adminContacts, setAdmincontacts] = useState([]);

  //validation functions

  const handleSubmit = async (e) => {
    await saveSettings();
  };

  const handleToRemoveAppLogo = () => {
    var _contactSettings = { ...contactSettings };
    _contactSettings.companyLogo = "";
    setcontactSettings(_contactSettings);
  };
  //error handling functions

  const handleSucessSweetAlert = () => {
    SetValidatemsg("");
    setisSuccess(false);
    window.location.reload();
  };
  const handleFailureSweetAlert = () => {
    setisFailure(false);
    SetValidatemsg("");
  };

  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      companyLogo:
        contactSettings.companyLogo === ""
          ? (iserror = "Company logo required")
          : errors.companyLogo,
    });

    return iserror.length === 0;
  };

  useEffect(() => {
    getUserInfoByID();
  }, []);

  async function getUserInfoByID() {
    try {
      setLoading(true);
      await getuserbyauthproviderid(user.sub, ctechapp.currentUser).then(
        (response) => {
          let user_info = JSON.parse(response);
          console.log("user_info", user_info);
          if (response !== undefined || user_info.length !== 0) {
            setUserInfoLoggedin(user_info[0]);
            GetGeneralSettingByAuthID(user_info[0].authID);
          }
        }
      );
      setLoading(false);
    } catch (exception) {
      setLoading(false);
      console.error(exception);
    }
  }
  //getGeneralSettingByAuthID
  async function GetGeneralSettingByAuthID(authid) {
    try {
      setLoading(true);
      await getGeneralSettingByAuthID(ctechapp.currentUser, user.sub).then(
        (response) => {
          console.log("user_responseinfo", response);
          if (response !== null) setcontactSettings(response);
        }
      );
      setLoading(false);
    } catch (exception) {
      setLoading(false);
      console.error(exception);
    }
  }
  // async function GetContactDetailSettingsByID(currentuser_id) {
  //   let useridvalue = currentuser_id;

  //   console.log("useridvalue: ", useridvalue);

  //   await getContactDetailSettingsByID(
  //     useridvalue,
  //     ctechapp.currentUser,
  //     user.sub
  //   ).then((response) => {
  //     if (response !== undefined) {
  //       let user_info = JSON.parse(response);
  //       var _contactsettingsList = user_info[0];
  //       console.log("_contact", _contactsettingsList);
  //       //settings info
  //       if (
  //         _contactsettingsList?.settings !== undefined &&
  //         _contactsettingsList?.settings.length > 0
  //       ) {
  //         var _contactsettings = _contactsettingsList.settings[0];
  //         contactSettings.state = "add";
  //         contactSettings.primaryContactUserID = currentuser_id;
  //         setcontactSettings(_contactsettings);
  //       } else {
  //         contactSettings._id = uuidv4();
  //         contactSettings._p_contactID = _contactsettingsList._id;
  //         contactSettings.state = "add";
  //         contactSettings.primaryContactUserID = currentuser_id;
  //         setcontactSettings(contactSettings);
  //         _contactsettingsList.settings = [];
  //       }
  //       setcontactSettingsArray(_contactsettingsList);
  //     }
  //   });
  // }
  // async function GetContactDetailAffiliationLookupByID(currentuser_id) {
  //   let useridvalue = currentuser_id;

  //   console.log("useridvalue: ", useridvalue);

  //   await getContactDetailAffiliationLookupByID(
  //     useridvalue,
  //     ctechapp.currentUser,
  //     user.sub
  //   ).then((response) => {
  //     if (response !== undefined) {
  //       var result = JSON.stringify(response);
  //       if (result.toUpperCase().includes("ERROR")) {
  //         var errmsg = JSON.parse(result);
  //         seterrorMessage(errmsg.error);
  //         setisFailure(true);
  //         return;
  //       }
  //       let affarray = JSON.parse(response);
  //       if (affarray.length > 0) {
  //         var _affarray = affarray[0].contactAffiliationsArray;
  //         console.log("_affarray", _affarray);
  //         //settings info
  //         let options = _affarray.map(function (rowdata) {
  //           return { value: rowdata.companyId, label: rowdata.companyName };
  //         });
  //         setcompanyList(options);
  //         setaffArray(_affarray);
  //       }
  //     }
  //   });
  // }
  // async function getAdminUserData() {
  //   getAdminUserListByUserID(ctechapp.currentUser, user.sub).then(
  //     (response) => {
  //       if (response !== undefined) {
  //         let user_list = JSON.parse(response);
  //         setAdmincontacts(user_list);
  //       }
  //     }
  //   );
  // }
  async function saveSettings() {
    try {
      setLoading(true);

      updateGeneralSettingByID(
        contactSettings,
        ctechapp.currentUser,
        user.sub
      ).then((response) => {
        if (response !== undefined) {
          SetValidatemsg(
            appConstants.contact.successfullmsg.generalsetting_success
          );
          setisSuccess(true);
        } else {
          seterrorMessage(
            appConstants.contact.successfullmsg.generalsetting_fail
          );
          setisFailure(true);
          return;
        }
      });
      setLoading(false);
    } catch (error) {
      seterrorMessage(error);
      setisFailure(true);
      return;
    }
  }

  function gotoHome() {
    history.push({
      pathname: "/dashboard",
    });
  }
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file.size > 5242880) {
      setErrors({
        ...errors,
        ["companyLogo"]: "Image size should be less than 5mb",
      });
      return;
    }
    setisvaliddata(true);
    const base64 = await convertToBase64(file);
    setcontactSettings({ ...contactSettings, ["companyLogo"]: base64 });
    setErrors({ ...errors, ["companyLogo"]: "" });
  };

  const handleChange_AutoApprovalVerification = (e) => {
    const { name, checked } = e.target;
    var _contactSettings = { ...contactSettings };
    console.log("handleChange_AutoApprovalVerification , checked", checked);
    if (checked) {
      _contactSettings.autoApprovalVerification = true;
      setcontactSettings(_contactSettings);

    } else {
      _contactSettings.autoApprovalVerification = false;
      setcontactSettings(_contactSettings);
    }
  };
  return (
    <div className="px-3">
      {loading && <Loading />}
      <PageSubHeader title={"General settings"} />
      {userInfoLoggedin.role !== "1" && (
        <Form>
          <div>
            <div id="generalsection">
              <div
                className="row p-2 mb-3"
                style={{
                  "background-color": "#f2f2f2",
                }}
              >
                <label className="fw-bolder">General section</label>
              </div>

              <div className="row mb-3">
                <div className="col-sm-12 col-md-2 col-lg-2">
                  <label>Company name</label>
                </div>
                <div className="col-sm-12 col-md-5 col-lg-5">
                  <span>{contactSettings.companyName}</span>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-12 col-md-2 col-lg-2">Auto approval verification</div>
                <div className="col-sm-12 col-md-10 col-lg-10">
                  <input
                    type="checkbox"
                    className="form-check-input  ml-2"
                    name="autoApprovalVerification"
                    id="autoApprovalVerification"
                    checked={
                      contactSettings.autoApprovalVerification === true
                        ? true
                        : false
                    }
                    onChange={(evnt) => {
                      handleChange_AutoApprovalVerification(evnt);
                    }}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-12 col-md-2 col-lg-2">
                  <label>Company logo</label>
                  <div>
                    {" "}
                    <small>(Logo size: 200px * 80px)</small>
                  </div>
                </div>
                <div className="col-sm-12 col-md-5 col-lg-5">
                  <Form.Group>
                    <div className="d-flex gap-3 justify-content-start align-items-start">
                      <Button
                        style={{ padding: "1px 6px" }}
                        onClick={() => handleToRemoveAppLogo()}
                      >
                        Clear
                      </Button>
                      <input
                        type="file"
                        multiple={false}
                        name="companyLogo"
                        onChange={(e) => handleFileUpload(e)}
                        accept=".jpeg, .png, .jpg"
                      ></input>

                      <img
                        src={
                          contactSettings.companyLogo === "" ||
                          contactSettings.companyLogo === undefined
                            ? "../assets/img/noimg1.png"
                            : contactSettings.companyLogo
                        }
                        className="rounded mx-auto d-block mw-100"
                        width="200"
                        height="80"
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>
              {/* <div className="mt-2 " className="d-none">
                <div id="additionalsection ">
                  <div
                    className="row p-2 mb-3"
                    style={{
                      "background-color": "#f2f2f2",
                    }}
                  >
                    <label className="fw-bolder">Additional section</label>
                  </div>
                </div>
                <ContactAdminCard contact={adminContacts} />
              </div> */}
              <div className="d-flex  justify-content-end gap-2 mb-3 mt-3">
                <Button variant="secondary" onClick={() => gotoHome()}>
                  Cancel
                </Button>
                {userInfoLoggedin?.role === "2" && (
                  <Button
                    variant="primary"
                    id="procoreToken"
                    onClick={handleSubmit}
                    className="btn btn-success btn-sm"
                  >
                    Save
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Form>
      )}

      <div>
        {isSuccess && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title={validatemsg}
            onConfirm={handleSucessSweetAlert}
          ></SweetAlert>
        )}
      </div>

      <div>
        {isFailure && (
          <SweetAlert
            danger
            style={{ color: "black" }}
            title={validatemsg}
            onConfirm={handleFailureSweetAlert}
          >
            {errorMessage}
          </SweetAlert>
        )}
      </div>
    </div>
  );
}

export default GeneralSettings;
