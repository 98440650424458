import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
//import * as yup from "yup";
import { useEffect, useState } from "react";
import * as Realm from "realm-web";
import { getapisettingsbyappname } from "../../services/api_settings_services";
//import { useHistory, useParams } from "react-router";
//import Loading from "../components/Loading";
//import { BSON } from "realm-web";

//const OAuthClient = require("intuit-oauth");

const _appname = "LinkedIn";
function LinkedInConnect() {
  // const [restapihostname, SetRestApiHostname] = useState();
  const [apiclientid, SetApiClientid] = useState();
  const [apiclientsecret, SetApiClientSecret] = useState();
  //  const [apirefreshtoken, setApiRefreshtoken] = useState();
  // const [apiaccesstokenhostname, SetApiAccesstokenHostname] = useState();
  //  const [apigranttype, SetApiGrantType] = useState();
  // const [apiappcompanyid, SetApiAppCompanyid] = useState();
  const [apienvironment, SetApiEnvironment] = useState();
  const [apiredirecturi, SetApiRedirectUri] = useState();
  // const [apiappname, SetApiAppname] = useState();

  useEffect(() => {
    getQBConnectInfo();
  }, []);
  async function getQBConnectInfo() {
    // Get the API key from the local environment
    const apiKey = process.env.EACT_APP_APIKEY;
    if (!apiKey) {
      throw new Error("Could  not find a Realm Server API Key.");
    }
    // Create an api key credential
    const credentials = Realm.Credentials.apiKey(apiKey);
    const localapp = new Realm.App(process.env.REACT_APP_REALM_APP_ID);
    localapp.logIn(credentials).then(() => {
      getapisettingsbyappname(
        _appname,
        localapp.currentUser,
        localapp.currentUser.id
      ).then((response) => {
        let api_info = JSON.parse(response);
        if (api_info !== undefined || api_info.length !== 0) {
          const res = api_info[0];
          //  SetApiAppname(res.appname);
          //  SetRestApiHostname(res.restapihostname);
          SetApiClientid(res.apiclientid);
          SetApiClientSecret(res.apiclientsecret);
          //   setApiRefreshtoken(res.apirefreshtoken);
          //  SetApiAccesstokenHostname(res.apiaccesstokenhostname);
          ///   SetApiGrantType(res.apigranttype);
          //   SetApiAppCompanyid(res.apiappcompanyid);
          SetApiRedirectUri(res.apiredirecturi);
        }
      });
    });
    if (localapp.currentUser !== null && localapp.currentUser !== undefined) {
      await localapp.removeUser(localapp.currentUser);
    }
  }

  function authorizeUri() {
    var oauthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${apiclientid}&scope=r_liteprofile&state=123456&redirect_uri=${apiredirecturi}`;
    var width = 450,
      height = 730,
      left = window.screen.width / 2 - width / 2,
      top = window.screen.height / 2 - height / 2;

    window.open(
      oauthUrl,
      "Linkedin",
      "menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=" +
        width +
        ", height=" +
        height +
        ", top=" +
        top +
        ", left=" +
        left
    );
  }

  function gotoprofile() {
    window.location.href = "/contactprofile";
  }

  return (
    <div>
      <h1>
        <img
          width="300"
          alt=""
          height="200"
          src="/images/Linkedin-Logo.png"
          id="headerLogo"
        />
      </h1>
      <Form>
        <div>
          <Form.Group>
            <Form.Label>
              {" "}
              ClientID<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="enter the clientId"
              name="clientId"
              value={apiclientid}
              onChange={(e) => {
                SetApiClientid(e.target.value);
              }}
            />
          </Form.Group>
        </div>
        <div>
          <Form.Group>
            <Form.Label>
              ClientSecret<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="enter the clientSecret"
              name="clientSecret"
              value={apiclientsecret}
              onChange={(e) => {
                SetApiClientSecret(e.target.value);
              }}
            />
          </Form.Group>
        </div>
        <div>
          <Form.Group className="mb-3">
            <Form.Label>Environment</Form.Label>
            <Form.Control
              id="environment"
              as="select"
              value={apienvironment}
              onChange={(e) => {
                SetApiEnvironment(e.target.value);
              }}
            >
              <option value="sandbox" selected="selected">
                Sandbox
              </option>
              <option value="production">Production</option>
            </Form.Control>
          </Form.Group>
        </div>
        <div>
          <Form.Group>
            <Form.Label>
              Redirect URI<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="enter the redirectUri"
              name="redirectUri"
              value={apiredirecturi}
              onChange={(e) => {
                SetApiRedirectUri(e.target.value);
              }}
            />
          </Form.Group>
        </div>
        <div>
          <Button
            variant="primary"
            style={{ margin: 10 }}
            onClick={() => gotoprofile()}
          >
            Save
          </Button>
          <Button
            variant="primary"
            id="procoreToken"
            onClick={() => authorizeUri()}
            className="btn btn-success"
          >
            LinkedIn Connect
          </Button>
          <span> </span>
          <Button
            variant="primary"
            id="refreshToken"
            className="btn btn-success"
          >
            Refresh Token
          </Button>
          <Button
            variant="secondary"
            style={{ margin: 20 }}
            onClick={() => gotoprofile()}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default LinkedInConnect;
