import { useState, useMemo, useEffect } from "react";
import { useRealmApp } from "../providers/RealmApp";
import { deleteusersByID } from "../services/users_services";
import SweetAlert from "react-bootstrap-sweetalert";
import { useAuth0 } from "@auth0/auth0-react";
import GenericListTable from "../components/gridTable/genericLlistTable";
import { Button, Table, Modal } from "react-bootstrap";

import Moment from "moment";
import ViewContact from "../pages/contact/ViewContact";
import EditContact from "../pages/contact/EditContact";
import { appConstants } from "../constants/app_constants";
import { reCreateAffiliationContactVerification } from "../services/contactVerificationTask_services";
import PageSubHeader from "../components/pagesubheader";
function ContactCard({ contact }) {
    const [isDeleteUser, setIsDeleteUser] = useState(false);

    const [isView, setIsView] = useState(false);
    const [viewData, setViewData] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [editData, setEditData] = useState("");
    const [isDeleted, setIsDeleted] = useState(false);
    const [isSendVerification, setIsSendVerification] = useState(false);
    const [isShowTheAff, setIsShowTheAff] = useState(false);
    const [userAffList, setuserAffList] = useState();
    const [rowToDelete, setRowToDelete] = useState();
    const [selectedAffUser, setSelectedAffUser] = useState([]);
    const [contactid, setcontactid] = useState();
    const ctechapp = useRealmApp();
    const { isLoading, error, isAuthenticated, user } = useAuth0();
    //state variables

    //table functions
    const data = useMemo(() => [...contact], [contact]);
    const appColumns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "_id",
                sortable: true,
                width: "20%",
            },

          {
              Header: "First name ",
              accessor: "firstname",
              sortable: true,
              width: "20%",
              Cell: ({ value, row }) => {
                  return <span>{row.original?.firstname}</span>;
              },
          },
          {
              Header: "Last name",
              accessor: "lastname",
              sortable: true,
              width: "30%",
              Cell: ({ value, row }) => {
                  return <span>{row.original?.lastname}</span>;
              },
          },
          {
              Header: "Email",
              accessor: "email",
              sortable: true,
              width: "30%",
              Cell: ({ value, row }) => {
                  return <span>{row.original?.email}</span>;
              },
          },
          {
              Header: "Phone",
              accessor: "phone",
              sortable: true,
              width: "30%",
              Cell: ({ value, row }) => {
                  return <span>{row.original?.phone}</span>;
              },
          },
          {
              Header: "Created on",
              accessor: "activity_on",
              sortable: true,
              width: "10%",
              Filter: "",
              filter: "value",
              Cell: ({ value, row }) =>
                  row.original?.activity_history !== undefined &&
                      row.original?.activity_history?.length > 0
                      ? Moment(row.original?.activity_history).format(
                          appConstants.DATEFORMAT
                      )
                      : "",
          },
          {
              Header: "Active",
              accessor: "isactive",
              sortable: true,
              width: "10%",
              Cell: ({ value, row }) => {
                  return (
                      <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                          <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                          {row.original?.isactive === "1" ? "Yes" : "No"}
                      </span>
                  );
              },
          },
          {
              Header: "Action",
              Cell: ({ row }) => {
                  const onEditItemClick = () => {
                      handleEditClickApps(row);
                  };
                  const onViewItemClick = () => {
                      handleViewClickApps(row);
                  };
                  const onDeleteItemClick = () => {
                      handleDeleteClickApps(row);
                  };
                  const onSendVerificationClick = () => {
                      handleSendVerificationRecordPopup(row);
                  };
                  return (
                      <div className="d-flex gap-2">
                          <button
                              className="btn btn-primary btn-sm"
                              onClick={onViewItemClick}
                          >
                              View
                          </button>
                          <button
                              className="btn btn-primary btn-sm"
                              onClick={onEditItemClick}
                          >
                              Edit
                          </button>
                          <button
                              className="btn btn-primary btn-sm"
                              onClick={onDeleteItemClick}
                          >
                              Delete
                          </button>
                          <button
                              className="btn btn-primary btn-sm"
                              onClick={onSendVerificationClick}
                          >
                              Send verification
                          </button>
                      </div>
                  );
              },
          },
      ],
      []
  );

    const hiddenappColumns = ["_id"];

    const SendVerification_CheckboxSelect = (e, data, index) => {
        const { name, checked } = e.target;
        if (checked) {
            // if cheked and selectall checkbox add all fileds to selectedList
            if (name === "SelectSource") {
                setSelectedAffUser([...selectedAffUser, data]);
                console.log("data", data);
            } else {
                setSelectedAffUser(userAffList?.original?.contactAffiliationsArray);
            }
        } else {
            if (name === "SelectSource") {
                let tempuser = selectedAffUser.filter((item) => item.id !== data.id);
                setSelectedAffUser(tempuser);
            } else {
                setSelectedAffUser([]);
            }
        }
    };
    const handleSendVerificationRecordPopup = (row) => {
        var _affUserList = [];
        // console.log("row.original._p_contactID", row.original._p_contactID);
        setcontactid(row.original._p_contactID);
        setuserAffList(row);
        if (row.original.contactAffiliationsArray.length > 0) {
            row.original.contactAffiliationsArray.map((rowdata) => {
                _affUserList.push(rowdata);
                setSelectedAffUser([...selectedAffUser, rowdata]);
            });
            setSelectedAffUser(_affUserList);
            setIsShowTheAff(true);
        } else {
            handleSendVerificationEmailSubmit(row.original._p_contactID);
        }
    };
    const handleSendVerificationEmailSubmit = (id) => {
        SendContactVerificationEmail(id);
        setIsShowTheAff(false);
    };
    const handleEditClickApps = (row) => {
        // setIsEdit(true);
        // let obj = {
        //   id: row.original._p_contactID,
        //   title: "editcontact",
        // };
        // setEditData(obj);
        EditContact(row.original._p_contactID);
    };
    const handleViewClickApps = (row) => {
        setIsView(true);
        let obj = {
            id: row.original._p_contactID,
            title: "viewcontact",
        };
        setViewData(obj);
    };
    const handleDeleteClickApps = (row) => {
        setIsDeleteUser(true);
        setRowToDelete(row.original._p_contactID);
    };
    const handleConfirmDelete = (row) => {
        setIsDeleteUser(false);
        deleteUser(rowToDelete);
    };
    const handleConfirmDeleted = (row) => {
        setIsDeleted(false);
        window.location.reload(false);
    };
    const handleConfirmSendVerification = (row) => {
        setIsSendVerification(false);
    };
    const handleCancelDelete = (row) => {
        setIsDeleteUser(false);
    };

    async function deleteUser(id) {
        try {
            deleteusersByID(id, ctechapp.currentUser, user.sub).then((response) => {
                if (response !== undefined) {
                    setIsDeleted(true);
                }
            });
        } catch (error) {
            console.log(error);
            // setLoading(false);
        }
    }
    async function SendContactVerificationEmail(id) {
        try {
            var userdata = {
                id: id,
                aff: selectedAffUser,
                signupurl: window.location.origin,
            };
            console.log("userdata", userdata);
            reCreateAffiliationContactVerification(
                userdata,
                ctechapp.currentUser,
                user.sub
            ).then((response) => {
                if (response !== undefined) {
                    setIsSendVerification(true);
                }
            });
        } catch (error) {
            console.log(error);
            // setLoading(false);
        }
    }

    function EditContact(id) {
        window.location.href = "/editcontact/" + id;
    }

    return (
        <div>
            <PageSubHeader title={"Contacts list"} />
            <hr></hr>
            <GenericListTable
                gcolumns={appColumns}
                gdata={data.length > 0 ? JSON.stringify(data) : ""}
                ghiddencolumns={hiddenappColumns}
                gplaceholder={"Search contacts.."}
            />
            {isDeleteUser && (
                <SweetAlert
                    warning
                    style={{ color: "black" }}
                    title=""
                    confirmBtnText="Delete"
                    cancelBtnText="No"
                    showCancel={true}
                    onConfirm={handleConfirmDelete}
                    onCancel={handleCancelDelete}
                >
                    <div>{appConstants.contact.confirmationmsg.delete}</div>
                </SweetAlert>
            )}
            {isDeleted && (
                <SweetAlert
                    success
                    style={{ color: "black" }}
                    title=""
                    confirmBtnText="Ok"
                    onConfirm={handleConfirmDeleted}
                >
                    <div>{appConstants.contact.successfullmsg.delete}</div>
                </SweetAlert>
            )}
            {isSendVerification && (
                <SweetAlert
                    success
                    style={{ color: "black" }}
                    title=""
                    confirmBtnText="Ok"
                    onConfirm={handleConfirmSendVerification}
                >
                    <div> {appConstants.contact.successfullmsg.sendverification}</div>
                </SweetAlert>
            )}
            {isView && (
                <Modal
                    scrollable={true}
                    show={isView}
                    onHide={() => setIsView(false)}
                    size="xl"
                    centered
                >
                    <Modal.Header className="fw-bold h4">
                        View Contact
                        <a
                            style={{ "text-decoration": "none" }}
                            onClick={() => setIsView(false)}
                            className="col d-flex justify-content-end text-dark"
                        >
                            <i class="fa-solid fa-xmark"></i>
                        </a>
                    </Modal.Header>
                    <Modal.Body>
                        <ViewContact value={viewData} />{" "}
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="row p-1">
                            <div className=" d-flex justify-content-end mt-3 col-sm-12 col-md-12 col-lg-12">
                                <button
                                    className=" btn btn-primary me-2 "
                                    onClick={() => setIsView(false)}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            )}
            {/* {isEdit && (
        <Modal
          scrollable={true}
          show={isEdit}
          onHide={() => setIsEdit(false)}
          size="xl"
          centered
        >
          <Modal.Header className="fw-bold h4">
            Edit Contact
            <a
              style={{ "text-decoration": "none" }}
              onClick={() => setIsEdit(false)}
              className="col d-flex justify-content-end text-dark"
            >
              <i class="fa-solid fa-xmark"></i>
            </a>
          </Modal.Header>
          <Modal.Body>
            <EditContact value={editData} />{" "}
          </Modal.Body>
        </Modal>
      )} */}

          {isShowTheAff && (
              <SweetAlert
                  style={{
                      color: "black",
                      margin: "auto",
                      width: "60%",
                      padding: "20px",
                  }}
                  showConfirm={false}
              >
                  <div>
                      <div className="row">
                          <label className="col-3 h4 d-flex">Send verification</label>
                          <a
                              style={{ "text-decoration": "none" }}
                              onClick={() => setIsShowTheAff(false)}
                              className="col d-flex justify-content-end"
                          >
                              <i class="fa-solid fa-xmark"></i>
                          </a>
                          <hr></hr>
                      </div>
                      <div className="row p-1 mt-3">
                          <Table striped bordered hover responsive>
                              <thead>
                                  <tr>
                                      <th>
                                          <input
                                              type="checkbox"
                                              className="form-check-input"
                                              name="allSelectSource"
                                              id="allSelectSource"
                                              // allSelect selected when both length equal
                                              // slecteduser === allUser
                                              checked={
                                                  selectedAffUser?.length ===
                                                  userAffList?.original?.contactAffiliationsArray
                                                      ?.length && selectedAffUser?.length !== 0
                                              }
                                              onChange={(e) =>
                                                  SendVerification_CheckboxSelect(
                                                      e,
                                                      userAffList?.original?.contactAffiliationsArray
                                                  )
                                              }
                                          />
                                      </th>
                                      <th>App name</th>
                                      <th>Company name</th>
                                      {/* <th>Imported Type</th> */}
                                      {/* <th>Account ID</th> */}
                                  </tr>
                              </thead>
                              <tbody>
                                  {userAffList?.original?.contactAffiliationsArray?.map(
                                      (row, index) => (
                                          <tr key={index}>
                                              <td>
                                                  <input
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      name="SelectSource"
                                                      id="SelectSource"
                                                      defaultChecked={true}
                                                      checked={selectedAffUser.some(
                                                          (item) => item?.id === row.id
                                                      )}
                                                      onChange={(e) =>
                                                          SendVerification_CheckboxSelect(e, row, index)
                                                      }
                                                  />{" "}
                                              </td>
                                              <td> {row.sourceSystem.appname}</td>
                                              <td> {row.companyName}</td>
                                              {/* <td>
                          {" "}
                          {row.sourceSystem.contact_category === "1"
                            ? "Approve w/o Verification"
                            : "Review and Send verification"}
                        </td> */}
                              {/* <td> {row.sourceSystem.companyid}</td> */}
                          </tr>
                      )
                  )}
                              </tbody>
                          </Table>
                      </div>
                      <div className="d-flex justify-content-end gap-2">
                          <Button
                              className="btn btn-success me-1"
                              onClick={() => setIsShowTheAff(false)}
                          >
                              Close
                          </Button>
                          <Button
                              className="btn btn-success me-1"
                              onClick={() => handleSendVerificationEmailSubmit(contactid)}
                          >
                              Submit
                          </Button>
                      </div>
                  </div>
              </SweetAlert>
          )}
      </div>
  );
}

export default ContactCard;
