import { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React from "react";
import * as Realm from "realm-web";
import { useRealmApp } from "../../../providers/RealmApp";
import { useLocation } from "react-router-dom";
import { ChargebeeUserOnboarding } from "../../../services/users_services";
import LoadingDialog from "../../../components/LoadingDialog";
import { useAuth0 } from "@auth0/auth0-react";
import SweetAlert from "react-bootstrap-sweetalert";
function AppSetupExisting() {
    const ctechapp = useRealmApp();
    const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
    const [loadDialogOpen, setloadDialogOpen] = useState(false);
    const queryparams = new URLSearchParams(window.location.search);
    const [charegbee_response_id, setCharegbee_response_id] = useState(queryparams.get("id"));
    const [charegbee_response_state, setCharegbee_response_state] = useState(queryparams.get("state"));
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [apptitle, setapptitle] = useState("Contact Sync");

    const location = useLocation();
    const redirectUrl = window.location.origin
    const redirectManagerURLmap = new Map()
        .set(redirectUrl + '/connection/commandpost/manage', "Command Post")
        .set(redirectUrl + '/connection/quickbooks/manage', "Quickbooks")
        .set(redirectUrl + '/connection/procore/manage', "Procore")
        .set(redirectUrl + '/connection/master/manage', "Masters");

    const redirectURLmap = new Map()
        .set(redirectUrl + '/connection/commandpost/setup', "Command Post")
        .set(redirectUrl + '/connection/quickbooks/setup', "Quickbooks")
        .set(redirectUrl + '/connection/procore/setup', "Procore")
        .set(redirectUrl + '/connection/master/setup', "Masters");

    async function RedirectPage(e) {
        e.preventDefault();
        setloadDialogOpen(true);
        try {
            var response = await ChargebeeUserOnboarding(
                charegbee_response_id,
                window.location.href,
                location.pathname || "",
                ctechapp.currentUser,
                ctechapp.currentUser.id
            )
            if (response !== undefined) {
                console.log("response", JSON.stringify(response));
                if (response.statusCode === 200) {
                    setIsSuccess(true);
                }
                else {
                    setIsError(true)
                    setErrorMessage(response.message);
                }

            }
            setloadDialogOpen(false);
        } catch (err) {
            console.log("err : " + err);
            setloadDialogOpen(false);
        }
    }

    async function PopupClose(e) {
        e.preventDefault();
        try {
            window.close();
            if (window.opener && !window.opener.closed) {
                window.opener.location.reload();
            }
        } catch (err) {
            console.log("PopupClose : " + err);
        }
    }

    async function handleSweetAlertSuccess() {
        setIsSuccess(false);
        if (!isAuthenticated) {
            const options = {
                appState: { type: "appconnection", returnTo: window.location.href },
                redirectUri: window.location.origin,
            };
            await loginWithRedirect(options);
        }
        else {
            console.log("user.email ", user.email);
            window.location.href = window.location.origin + "/dashboard"
        }
    }
    function handleSweetAlertError() {
        setIsError(false);
        logout({
            returnTo: window.location.origin,
        });
    }

    return (

        <div className="mb-2">
            {!isSuccess && (
                <div>
                    <h3 className="m-2" align="center">
                        Welcome to contact sync onboarding
                    </h3>
                    <div className="d-flex justify-content-center align-self-start  welcomehintwindow">

                        {/* <ui>
                            <li>Sample hint1</li>
                            <li>Sample hint2</li>
                            <li>Sample hint3</li>
                            <li>Sample hint4</li>
                        </ui> */}


                    </div>
                    <div className="d-flex-inline align-items-center">
                        <div class="d-flex justify-content-center mt-4">
                            <div>
                                <button
                                    className="btn btn-primary"
                                    onClick={(e) => {
                                        RedirectPage(e);
                                    }}
                                >
                                    Continue
                                </button></div></div>
                    </div>

                </div>)}
            {isSuccess && (
                <div>
                    <h3 className="m-2" align="center">
                        Thanks for purchasing an application.
                    </h3>

                    <div className="d-flex-inline align-items-center">
                        <div class="d-flex justify-content-center mt-4">
                            <div>
                                <button
                                    className="btn btn-primary"
                                    onClick={(e) => {
                                        PopupClose(e);
                                    }}
                                >
                                    close
                                </button></div></div>
                    </div>

                </div>)}
            <LoadingDialog
                isOpen={loadDialogOpen}
                onClose={() => setloadDialogOpen(false)}
            />
            {/* {isSuccess && (
                <SweetAlert
                    success
                    style={{ color: "black" }}
                    title="App has been onboarded successfully."
                    onConfirm={handleSweetAlertSuccess}
                ></SweetAlert>

            )} */}
            {isError && (
                <SweetAlert
                    danger
                    style={{ color: "black" }}
                    title={errorMessage}
                    onConfirm={handleSweetAlertError}
                ></SweetAlert>
            )}
        </div>

    );
}
export default AppSetupExisting;