import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoginButton from "./login-button";
import SignupButton from "./signup-button";
import { useRealmApp } from "../providers/RealmApp";
import LogoutButton from "./logout-button";
const AuthNav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showmenu, setshowmenu] = useState(true);
  const [advancedMenuEnable, setAdvancedMenuEnable] = useState(false);
  // window.location.pathname.includes("contactverification") ||
  // window.location.pathname === "/contactverification" ||
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const ctechapp = useRealmApp();

  const logoutWithRedirect = () => {
    ctechapp.logOutAllUsers();
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };
  function gotoChangePassword() {
    window.location.href = "/changepassword";
  }
  function gotoContactProfile() {
    window.location.href = "/contactprofile";
  }
  function gotoGeneralSettings() {
    window.location.href = "/generalsettings";
  }
  useEffect(() => {
    try {
      if (
        window.location.pathname.includes("setup") ||
        window.location.pathname.includes("contactverification") ||
        window.location.pathname === "/contactverification" ||
        window.location.pathname.includes("verificationmsg") ||
        window.location.pathname.includes("/manage")

      ) {
        setshowmenu(false);
        setAdvancedMenuEnable(false);
      }
      const _advancedMode = localStorage.getItem("menuadvancedmode");
      console.log("_advancedMode", _advancedMode);
      if (_advancedMode) {
        if (_advancedMode === "1")
          setAdvancedMenuEnable(true);
        else
          setAdvancedMenuEnable(false);
      }
      else {
        setAdvancedMenuEnable(false);
      }
    }
    catch (ex) {
      console.log("ex", ex);
    }

  }, [window.location.pathname, showmenu, advancedMenuEnable]);



  const handleChange_Advancedmode = (e) => {
    const { name, checked } = e.target;

    console.log("handleChange_Advancedmode , checked", checked);
    if (checked) {
      localStorage.setItem('menuadvancedmode', 1);
      setAdvancedMenuEnable(true);

    } else {
      localStorage.setItem('menuadvancedmode', 0);
      setAdvancedMenuEnable(false);
    }
    window.location.href = "/";
  };
  return (

    <Nav className="ms-auto">
      {showmenu && isAuthenticated && ctechapp.currentUser !== null && (
        <NavDropdown
          title={user && <h6 className="d-inline-block">{user.name}</h6>}
          id="collasible-nav-dropdown"
        >
          <NavDropdown.Item>
            <FontAwesomeIcon icon="password" className="mr-3" />
            <RouterNavLink
              to="#"
              id="qscontactprofileBtn"
              onClick={() => gotoContactProfile()}
            >
              PROFILE
            </RouterNavLink>
          </NavDropdown.Item>
          {

            <NavDropdown.Item>
              <FontAwesomeIcon icon="gear" className="mr-3" />
              <RouterNavLink
                to="#"
                id="qscontactprofileBtn"
                onClick={() => gotoGeneralSettings()}
              >
                GENERAL SETTINGS
              </RouterNavLink>
            </NavDropdown.Item>

          }
          <NavDropdown.Item>
            <FontAwesomeIcon icon="password" className="mr-3" />
            <RouterNavLink
              to="#"
              id="qschangepasswordBtn"
              onClick={() => gotoChangePassword()}
            >
              CHANGE PASSWORD
            </RouterNavLink>
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item>
            {/* <RouterNavLink
              to="#"
              id="advancemodetoggle"
            > */}
            <RouterNavLink
              to="#"
              id="qschangepasswordBtn"
              onClick={(e) => handleChange_Advancedmode(e)}
            >
              <div className="form-check form-switch ms-auto mb-0">
                <span>ADVANCED</span>{" "}
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="advancedmode"
                  id="advancedmode"

                  // onclick={(e) => {
                  //   handleChange_Advancedmode(e)
                  // }}
                  checked={advancedMenuEnable}
                />
              </div>
            </RouterNavLink>


            {/* </RouterNavLink> */}
          </NavDropdown.Item>
          <NavDropdown.Item>
            <FontAwesomeIcon icon="power-off" />
            <RouterNavLink
              to="#"
              id="qsLogoutBtn"
              onClick={() => logoutWithRedirect()}
            >
              &nbsp; LOGOUT
            </RouterNavLink>
          </NavDropdown.Item>
        </NavDropdown>
      )}
      {showmenu && !isAuthenticated && (
        <>
          <LoginButton />
          {!window.location.pathname
            .toString()
            .toUpperCase()
            .includes("SIGNUP") &&
            !window.location.pathname
              .toString()
              .toUpperCase()
              .includes("LINKEDINPROFILE") && <SignupButton />}
        </>
      )}
    </Nav>

  );
};

export default AuthNav;
