import { useEffect, useState, useMemo } from "react";
import { useRealmApp } from "../../providers/RealmApp";
import { Button, Form, Modal } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../components/panel/panel";
import {
  getapplistbyfilter,
  getProcorevendorlistbycompanyid,
} from "../../services/applications_services";
import Select from "react-select";
import customstyles from "../../components/customstyles/react-select-styles";
import {
  getPendingCreationTask,
  updatePendingCreateTaskByBatchStatus,
} from "../../services/pending_create_task_services";
import {
  getCompanyListFromQuickBooks,
  GetQuickBooksContactRole,
} from "../../services/quickbooks_contact_services";
import { getcommandpostcompanylistbyaccountid_wrapper } from "../../services/commandpost_contact_services";
import { useAuth0 } from "@auth0/auth0-react";
import SweetAlert from "react-bootstrap-sweetalert";
import { getuserbyauthproviderid } from "../../services/users_services";
import GenericListTable from "../../components/gridTable/genericLlistTable";
import LoadingDialog from "../../components/LoadingDialog";
import { useHistory } from "react-router";
import Moment from "moment";
import { appConstants } from "../../constants/app_constants";
import CreatableSelect from "react-select/creatable";
import PageSubHeader from "../../components/pagesubheader";
import { getentraidcompanylistbycompanyid } from "../../services/entraid_contact_services";
import ViewContact from "./ViewContact";
function PendingCreationList() {
  const { appname, accountid } = useLocation()?.state || "";
  const [list, setList] = useState([]);
  const [tabPendingUsers, setPendingUsers] = useState(true);
  const [tabApprovalUsers, setApprovalUsers] = useState(false);
  const [userlist, setUserList] = useState([]);
  const [tabSendverification, setSendverification] = useState(false);
  const [userlist_step2, setUserList_Step2] = useState([]);
  const [selectedSourceUser, setSelectedSourceUser] = useState([]);
  const [isView, setIsView] = useState(false);
  const [viewData, setViewData] = useState("");
  const [errormsg, setErrormsg] = useState("");
  const [vaildmsg, setvaildmsg] = useState(false);
  const [userStepbutton2, setuserStepbutton2] = useState(false);
  const [userlist_step3, setUserList_Step3] = useState([]);

  const [appAffList, setAppAffList] = useState([]);
  const [isApproveModal, setIsApproveModal] = useState(false);
  const [rowApproveInfo, setRowApproveInfo] = useState();

  const [vendorList, setVendorList] = useState([]);
  const [rowToDelete, setRowToDelete] = useState();
  const ctechapp = useRealmApp();

  const [isRejectedMsg, setisRejectedMsg] = useState(false);
  const [isApprovedMsg, setisApprovedMsg] = useState(false);

  const [isRejected, setisRejected] = useState(false);
  const [isRejectedConfirm, setisRejectedConfirm] = useState(false);
  const [rowRejectInfo, setRowRejectInfo] = useState([]);

  const [vendorListSearchLookup, setVendorListSearchLookup] = useState([]);
  const [quickBooksCompanyList, setQuickBooksCompanyList] = useState([]);
  const [contactRoleList, setContactRoleList] = useState([]);

  const [changeVendorRow, setchangeVendorRow] = useState("");
  const [isVendorChange, setIsVendorChange] = useState(false);
  const [validationMsg, setvalidationMsg] = useState("");

  const [page, Stepage] = useState(0);
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  const FormTitles = ["pendingusers", "approvalusers"];
  const [loadDialogOpen, setloadDialogOpen] = useState(false);

  const showTab = (tab) => {
    console.log("tab ", tab);
    switch (tab) {
      case "pendingusers":
        setPendingUsers(true);
        setApprovalUsers(false);

        return;
      case "approvalusers":
        setPendingUsers(false);
        setApprovalUsers(true);

        return;
      default:
        break;
    }
  };
  const [userinfo, setUserinfo] = useState({
    firstname: "",
    lastname: "",
    email: "",
    role: "",
  });
  //state variables
  const history = useHistory();
  //table functions
  const data = useMemo(() => [...list], [list]);
  const appColumns = useMemo(
    () => [
      {
        Header: ({ data }) => (
          <input
            type="checkbox"
            className="form-check-input"
            name="allSelectSource"
            id="allSelectSource"
            // allSelect selected when both length equal
            // slecteduser === allUser

            // checked={
            //   selectedSourceUser.length !== 0 &&
            //   selectedSourceUser.every((user) =>
            //     data.some((row) => row._id === user._id)
            //   )
            // }
            onChange={(e) => handleChange_AppSelect(e, data)}
          />
        ),
        accessor: "selectsourceoption",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
        Cell: ({ row }) => {
          return (
            <div className="d-flex gap-4">
              <input
                type="checkbox"
                onChange={(e) =>
                  handleChange_AppSelect(e, row?.original, row.index)
                }
                checked={row?.original?.SelectSource}
                name="SelectSource"
                id="SelectSource"
              />
            </div>
          );
        },
      },
      {
        Header: "ID",
        accessor: "_id",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },

      {
        Header: "First name ",
        accessor: "firstname",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
        // Filter: DefaultColumnFilter,
        // filter: 'value',
      },
      {
        Header: "Last name ",
        accessor: "lastname",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
        // Filter: DefaultColumnFilter,
        // filter: 'value',
      },

      {
        Header: "Email ",
        accessor: "email",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
        // Filter: DefaultColumnFilter,
        // filter: 'value',
      },

      {
        Header: "Phone",
        accessor: "phone",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
      },
      {
        Header: "App name",
        accessor: "",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
        Cell: ({ row }) => (
          <span>
            {row?.original?.contactAffiliationsArray[0]?.sourceSystem?.appname}
          </span>
        ),
      },

      {
        Header: "Contact company",
        accessor: "",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
        Cell: ({ row }) => (
          <span>
            <span>
              {row?.original?.contactAffiliationsArray[0]?.companyName}
              {row?.original?.contactAffiliationsArray[0]?.contactRole && (
                <span>
                  <span className="fw-bolder "> / </span>
                  {row?.original?.contactAffiliationsArray[0]?.contactRole}
                </span>
              )}
            </span>
            {row?.original?.SelectSource &&
              row?.original?.contactAffiliationsArray[0]?.sourceSystem.appname.toLowerCase() ===
                "quickbooks" &&
              !row?.original?.contactAffiliationsArray[0]?.contactRole && (
                <span className="text-danger d-flex justify-content-start">
                  {appConstants.import.quickbooks.role}
                </span>
              )}
          </span>
        ),
      },
      {
        Header: "Source app",
        accessor: "sourceUpdate",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
      },
      {
        Header: "Active",
        accessor: "isactive",
        sortable: true,
        width: "10%",
        Filter: "",
        filter: "value",
        Cell: ({ value }) => {
          return (
            <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
              <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
              {value === "1" ? "Yes" : "No"}
            </span>
          );
        },
      },
      {
        Header: "Action",
        Filter: "",
        filter: "",

        Cell: ({ row }) => {
          const onViewItemClick = () => {
            handleViewClick(row?.original._id);
          };
          const onApproveItemClick = () => {
            handleApproveClick(row?.original);
          };
          const onRejectItemClick = () => {
            handleRejectClick(row?.original);
          };

          return (
            <div className="d-flex gap-4">
              <Button className="btn btn-sm " onClick={onViewItemClick}>
                {/* <i className="fas fa-2x  fa-pencil"></i> */}
                View
              </Button>
              <Button className="btn btn-sm " onClick={onRejectItemClick}>
                {/* <i className="fas fa-2x  fa-pencil"></i> */}
                Reject
              </Button>
              {row?.original.isCompanyButton && (
                <Button className="btn btn-sm " onClick={onApproveItemClick}>
                  {/* <i className="fas fa-2x  fa-pencil"></i> */}
                  Company
                </Button>
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  const hiddenappColumns = ["_id"];

  const handleChange_AppSelect = (e, data, index) => {
    const { name, checked } = e.target;
    var _selectUserList = selectedSourceUser;
    if (name === "SelectSource") {
      if (checked) {
        const objIndex = _selectUserList.findIndex(
          (obj) => obj._id === data._id
        );
        if (objIndex === -1) {
          console.log("objIndex !== -1");
          data.SelectSource = true;
          _selectUserList.push(data);
        } else {
          _selectUserList[objIndex].SelectSource = true;
        }
      } else {
        const objIndex = _selectUserList.findIndex(
          (obj) => obj._id === data._id
        );
        if (objIndex !== -1) {
          _selectUserList[objIndex].SelectSource = false;
        }
      }
    } else if (name === "allSelectSource") {
      if (checked) {
        data.map((obj, index) => {
          const objIndex = _selectUserList.findIndex(
            (val) => val._id === obj._id
          );
          if (objIndex !== -1) {
            _selectUserList[objIndex].SelectSource = true;
          } else {
            obj.SelectSource = true;
            _selectUserList.push(obj);
          }
        });
      } else {
        data.map((obj, index) => {
          const objIndex = _selectUserList.findIndex(
            (val) => val._id === obj._id
          );
          if (objIndex !== -1) {
            _selectUserList[objIndex].SelectSource = false;
          }
        });
      }
    }

    let _selectUser = _selectUserList.filter(
      (record) => record.SelectSource === true
    );

    setSelectedSourceUser(_selectUser);
    //console.log("_selectUser",_selectUser);
    setvalidationMsg("");

    let quickbooksuserfilter = _selectUser.filter(
      (_user) =>
        _user?.contactAffiliationsArray[0]?.sourceSystem?.appname?.toLowerCase() ===
        "quickbooks"
    );
    const hasDuplicate = (arrayObj, colName) => {
      var hash = Object.create(null);
      return arrayObj.some((arr) => {
        return (
          arr[colName] && (hash[arr[colName]] || !(hash[arr[colName]] = true))
        );
      });
    };
    const isDuplicate = hasDuplicate(quickbooksuserfilter, "caId");
    console.log(isDuplicate);

    _selectUser?.map((_user) => {
      const qb_validate = _user?.contactAffiliationsArray?.filter(
        (affinfo) =>
          affinfo?.contactRole === "" &&
          affinfo?.sourceSystem?.appname?.toLowerCase() === "quickbooks"
      );

      if (qb_validate.length > 0) {
        setvalidationMsg(appConstants.import.quickbooks.role);
      }
      if (isDuplicate) {
        setvalidationMsg(appConstants.import.quickbooks.multipleuser);
      }
    });
  };
  const data_approval = useMemo(() => [...userlist_step2], [userlist_step2]);
  const appColumns_approval = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },

      {
        Header: "First name ",
        accessor: "firstname",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
        // Filter: DefaultColumnFilter,
        // filter: 'value',
      },
      {
        Header: "Last name ",
        accessor: "lastname",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
        // Filter: DefaultColumnFilter,
        // filter: 'value',
      },

      {
        Header: "Email ",
        accessor: "email",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
        // Filter: DefaultColumnFilter,
        // filter: 'value',
      },

      {
        Header: "Phone",
        accessor: "phone",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
      },
      {
        Header: "App name",
        accessor: "",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
        Cell: ({ row }) => {
          let _appName = [];
          console.log("row?.original", row?.original);
          row?.original?.contactAffiliationsArray.map((affinfo) => {
            _appName.push(affinfo.sourceSystem?.appname);
          });

          return <span>{_appName.toString()}</span>;
        },
      },
      {
        Header: "Contact company",
        accessor: "",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
        Cell: ({ row }) => {
          var _companyName = [];
          row?.original?.contactAffiliationsArray?.map((affinfo) => {
            _companyName.push(affinfo?.companyName);
          });
          var unique = _companyName?.filter(
            (value, index, array) => array.indexOf(value) === index
          );
          return <span>{unique?.toString()}</span>;
        },
      },
      {
        Header: "Source app",
        accessor: "sourceUpdate",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
      },
      {
        Header: "Active",
        accessor: "isactive",
        sortable: true,
        width: "10%",
        Filter: "",
        filter: "value",
        Cell: ({ value }) => {
          return (
            <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
              <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
              {value === "1" ? "Yes" : "No"}
            </span>
          );
        },
      },
    ],
    []
  );

  const hiddenappColumns_approval = ["_id"];

  useEffect(() => {
    try {
      setloadDialogOpen(true);
      getUserInfoByID();
      getPendingusersData();
      getAccountCompanyList();
      GetQuickBooksContactRoleList();
      //  setloadDialogOpen(false);
    } catch (error) {
      setloadDialogOpen(false);
      console.log("Error in useeffect", error);
    }
    if (isAuthenticated) {
    }
  }, []);

  async function getPendingusersData() {
    getPendingCreationTask(ctechapp.currentUser, user.sub).then((response) => {
      if (response !== undefined) {
        let Pending_creation_list = JSON.parse(response);
        var _Pending_creation_list = [];
        //   console.log(Pending_creation_list);
        if (appname && accountid) {
          Pending_creation_list.map((row) => {
            let result = row.contactAffiliationsArray.filter(
              (val) =>
                val.sourceSystem.appname.toLowerCase() ===
                  appname.toLowerCase() &&
                val.sourceSystem.companyid === accountid
            );
            // console.log("result", result);
            if (result.length > 0 && row.contactOwner === user.sub) {
              _Pending_creation_list.push(row);
            }
          });
        } else {
          _Pending_creation_list = Pending_creation_list;
        }
        if (_Pending_creation_list.length === 0) {
          setvaildmsg(true);
        }
        _Pending_creation_list.map((row) => {
          if (
            row?.contactAffiliationsArray[0].sourceSystem.appname.toLowerCase() ===
              "quickbooks" &&
            row?.contactAffiliationsArray[0].contactRole === ""
          ) {
            row.isCompanyButton = true;
          } else {
            row.isCompanyButton = false;
          }
        });
        setList(_Pending_creation_list);
        setloadDialogOpen(false);
      }
    });
  }
  const UpdatePendingUpdateTaskByID = (payload, statusupdaterecord, status) => {
    try {
      setloadDialogOpen(true);
      updatePendingCreateTaskByBatchStatus(
        payload,
        statusupdaterecord,
        status,
        ctechapp.currentUser,
        user.sub
      ).then((response) => {
        setuserStepbutton2(true);
        if (status === "rejected") {
          //toast("Contact rejected");
          setisRejectedMsg(true);
          setloadDialogOpen(false);
        } else {
          //toast("Contact approved");
          setisApprovedMsg(true);
          setloadDialogOpen(false);
        }
      });
    } catch (error) {
      console.log("Error in UpdatePendingUpdateTaskByID; ", error);
      setloadDialogOpen(false);
    }
  };
  async function getUserInfoByID() {
    try {
      getuserbyauthproviderid(user.sub, ctechapp.currentUser).then(
        (response) => {
          let user_info = JSON.parse(response);
          if (response !== undefined || user_info.length !== 0) {
            setUserinfo(user_info[0]);
          }
        }
      );
    } catch (exception) {
      console.log(exception);
    }
  }

  async function getAccountCompanyList() {
    var tempAppList = [];
    const lobList = ["Procore", "CommandPost", "QuickBooks", "EntraID"];

    // var aff_list = [];
    // await contactinfo.contactAffiliationsArray?.map((aff1) => {
    //   const index = lobList.indexOf(aff1?.sourceSystem?.appname);
    //   if (index > -1) {
    //     lobList.splice(index, 1);
    //   }
    // });

    var query = {
      "appuserinfo.userid": user.sub,
      appname: { $in: lobList },
      isactive: "1",
    };
    getapplistbyfilter(ctechapp.currentUser, query, user.sub).then(
      (response) => {
        if (response !== undefined) {
          var app_list = JSON.parse(response);

          // contactinfo.contactAffiliationsArray.map((aff) => {
          //   var objindex = app_list?.findIndex(
          //     (appinfo) =>
          //       aff?.sourceSystem?.appname?.toLowerCase() ===
          //         appinfo?.appname?.toLowerCase() &&
          //       aff?.sourceSystem?.companyid?.toLowerCase() ===
          //         appinfo?.appaccountid?.toLowerCase()
          //   );

          //   if (objindex !== -1) {
          //     app_list.splice(objindex, 1);
          //   }
          // });

          app_list.map((app_row) => {
            app_row.SelectSource = true;
            app_row.guid = uuid();
            app_row.companyid = app_row.appaccountid;
            app_row.selectvalue = "";
            app_row.vendorid = "";
            app_row.vendorname = "";
            app_row.vendordisplayname = "";
            app_row.contactrole = "";
            tempAppList.push(app_row);
            return app_row;
          });
          GetVendorList(app_list);
          setAppAffList(app_list);
        }
      }
    );
  }

  const GetVendorListByCompanyID = (companyid, appname) => {
    const vendorList_sorted = vendorList.sort((a, b) =>
      a["vendorname"].toLowerCase() > b["vendorname"].toLowerCase() ? 1 : -1
    );

    const _resultvendors = vendorList_sorted.filter(
      (vendorinfo) =>
        vendorinfo.companyid === companyid &&
        vendorinfo?.appname?.toLowerCase() === appname?.toLowerCase()
    );

    var uniqueVendors = _resultvendors.filter(
      (arr, index, self) =>
        index === self.findIndex((t) => t.label === arr.label)
    );

    console.log("_vendorbycompany", uniqueVendors);
    return uniqueVendors;
  };

  async function GetVendorList(applist) {
    try {
      var _vendorLookup = [];
      var templist = [];
      await applist.map(async (row) => {
        if (row.appname.toLowerCase() === "procore") {
          const token_input = {
            procoreClientID: row.appclientid,
            procoreClientSecret: row.appclientsecret,
            procoreRedirectUri: row.appredirecturi,
            procoreAppToken: row.appuserinfo[0].apptoken,
            procoreRefreshToken: row.appuserinfo[0].apprefreshtoken,
            procoreRestAPIHostName: row.appresthostname,
            procoreCompanyID: row.appaccountid,
            loggedin_by: user.sub,
            procoreAccessTokenHostName: row.appaccesstokenhostname,
          };

          var _procoreList = await getProcorevendorlistbycompanyid(
            token_input,
            ctechapp.currentUser,
            user.sub
          );

          if (_procoreList !== undefined) {
            //  var templist = vendorList;
            _procoreList = JSON.parse(_procoreList);
            if (_procoreList.length > 0) {
              await _procoreList.map(async (procore) => {
                templist.push({
                  vendorid: procore.id,
                  vendorname: procore.name,
                  vendordisplayname: procore.name,
                  companyid: procore.company_ID,
                  appname: row.appname,
                  guid: row?.guid,
                });
              });
            }
          }
        } else if (row?.appname.toLowerCase() === "entraid") {
          const EID_token_input = {
            entraidClientID: row.appclientid || "",
            entraidClientSecret: row.appclientsecret || "",
            entraidRedirectUri: row.appredirecturi || "",
            entraidAppToken: row.appuserinfo[0].apptoken || "",
            entraidRefreshToken: row.appuserinfo[0].apprefreshtoken || "",
            expires_in_seconds: row.appuserinfo[0].expires_in_seconds || 0,
            token_createdon: row.appuserinfo[0].token_createdon || "",
            entraidRestAPIHostName: row.appresthostname || "",
            entraidAccessTokenHostName: row.appaccesstokenhostname || "",
            entraidTenantID: row.tenantid || "",
            entraidTenantName: row.tenantname || "",
            entraidCompanyID: row.appaccountid || "",
            loggedin_by: user.sub,
          };

          var _entraidList = await getentraidcompanylistbycompanyid(
            EID_token_input,
            ctechapp.currentUser,
            user.sub
          );

          if (_entraidList !== undefined && _entraidList.statusCode === 200) {
            //  var templist = vendorList;
            //_entraidList = JSON.parse(_entraidList.data);
            _entraidList = _entraidList.data;
            if (_entraidList.length > 0) {
              await _entraidList.map(async (entraid) => {
                let obj = {
                  vendorid: entraid.id,
                  vendorname: entraid.companyName,
                  vendordisplayname: entraid.companyName,
                  companyid: EID_token_input.entraidTenantID || "",
                  appname: "EntraID",
                  guid: row?.guid,
                };
                console.log("obj ", JSON.stringify(obj));
                templist.push(obj);
              });
            }
          }
        } else if (row.appname.toLowerCase() === "quickbooks") {
          var _quickbooksList = await getCompanyListFromQuickBooks(
            row.appaccountid,
            row.appaccesstokenhostname,
            row.appclientid,
            row.appclientsecret,
            row.appuserinfo[0].apprefreshtoken,
            row.appuserinfo[0].apptoken,
            row.appredirecturi,
            row.appresthostname,
            ctechapp.currentUser,
            user.sub
          );

          if (_quickbooksList !== undefined && _quickbooksList.length > 0) {
            console.log("qblist", JSON.stringify(_quickbooksList));
            //  var templist = vendorList;
            await _quickbooksList.map(async (qb) => {
              templist.push({
                vendorid: qb.vendorid,
                vendorname: qb.vendorname,
                companyid: qb.companyid,
                appname: qb.appname,
                vendordisplayname: qb.vendordisplayname,
                contactrole: qb.contactrole,
                guid: row?.guid,
              });
            });
          }
        } else if (row.appname.toLowerCase() === "commandpost") {
          var _commandpostList =
            await getcommandpostcompanylistbyaccountid_wrapper(
              row.appaccountid,
              ctechapp.currentUser,
              user.sub
            );

          if (_commandpostList !== undefined) {
            //   var templist = vendorList;
            _commandpostList = JSON.parse(_commandpostList);
            if (_commandpostList?.length > 0) {
              await _commandpostList?.map(async (commandpost) => {
                templist.push({
                  vendorid: commandpost._id,
                  vendorname: commandpost.companyName,
                  vendordisplayname: commandpost.companyName,
                  companyid: row.appaccountid,
                  appname: row.appname,
                  guid: row?.guid,
                });
              });
            }
          }
        }
        try {
          var _vendorLookup1 = templist;
          console.log("templist2", JSON.stringify(templist));

          if (templist.length > 0) {
            templist.map((vendor1) => {
              let _name1 = vendor1.vendorname;
              var result = [];
              result = _vendorLookup1.filter(
                (_namevalue) => _namevalue?.keyvalue !== _name1
              );

              if (_vendorLookup.length === 0 || result.length > 0) {
                _vendorLookup.push({
                  label: _name1.concat(" (" + vendor1.appname + ")"),
                  value: _name1.concat(" (" + vendor1.appname + ")"),
                  vendorid: vendor1.vendorid,
                  vendorname: vendor1.vendorname,
                  vendordisplayname: vendor1.vendordisplayname,
                  companyid: vendor1.companyid,
                  appname: vendor1.appname,
                  contactrole:
                    vendor1.contactrole === undefined
                      ? ""
                      : vendor1.contactrole,
                });
              }
            });
            setVendorList(_vendorLookup);
            console.log("_vendorLookup", _vendorLookup);

            const key = "label";

            const arrayUniqueByKey = [
              ...new Map(
                _vendorLookup.map((item) => [item[key], item])
              ).values(),
            ];
            setVendorListSearchLookup(arrayUniqueByKey);
          }
        } catch (exception) {
          console.log("getProcorevendorlistbycompanyid Error", exception);
        }
      });
    } catch (error) {
      console.log("getProcorevendorlistbycompanyid Error", error);
    }
  }

  //generate guid
  function uuid() {
    var uuidValue = "",
      k,
      randomValue;
    for (k = 0; k < 32; k++) {
      randomValue = (Math.random() * 16) | 0;

      if (k == 8 || k == 12 || k == 16 || k == 20) {
        uuidValue += "";
      }
      uuidValue += (
        k == 12 ? 4 : k == 16 ? (randomValue & 3) | 8 : randomValue
      ).toString(16);
    }
    return uuidValue;
  }

  const handleDropDownChangeAddCompany = (vendorinfo) => {
    setErrormsg("");
    const { value, name, rowindex } = vendorinfo;
    var _rowApproveInfo = { ...rowApproveInfo };
    if (value?.vendorname !== undefined) {
      _rowApproveInfo.contactAffiliationsArray[0].companyId = value.vendorid;
      _rowApproveInfo.contactAffiliationsArray[0].companyName =
        value.vendorname;
      _rowApproveInfo.contactAffiliationsArray[0].companyDBA = value.vendorname;
    } else {
      console.log("value", value);
      _rowApproveInfo.contactAffiliationsArray[0].companyId = "";
      _rowApproveInfo.contactAffiliationsArray[0].companyName = value.value;
      _rowApproveInfo.contactAffiliationsArray[0].companyDBA = value.value;
    }
    setRowApproveInfo(_rowApproveInfo);
  };

  const handleDropDownChangeContactToleAddCompany = (contactrole) => {
    var _rowApproveInfo = { ...rowApproveInfo };
    _rowApproveInfo.contactAffiliationsArray[0].sourceSystem.contactrole =
      contactrole;
    _rowApproveInfo.contactAffiliationsArray[0].contactRole = contactrole;
    setRowApproveInfo(_rowApproveInfo);
  };

  async function submitConfirm(values) {
    var _list = list;
    var _rowApproveInfo = rowApproveInfo;
    var _listresultindex = list.findIndex(
      (list_row) => list_row?._id.toString() === _rowApproveInfo._id.toString()
    );
    if (_listresultindex !== -1) {
      _list[_listresultindex] = _rowApproveInfo;
    }
    setList(_list);
    setIsApproveModal(false);
  }

  async function GetQuickBooksContactRoleList() {
    await GetQuickBooksContactRole(ctechapp.currentUser, user.sub).then(
      (response) => {
        if (response !== undefined) {
          let contact_role = JSON.parse(response);
          const contact_role_sorted = contact_role.sort((a, b) =>
            a["label"].toLowerCase() > b["label"].toLowerCase() ? 1 : -1
          );
          setContactRoleList(contact_role_sorted);
        }
      }
    );
  }

  const GetVendorQuickbooksListByCompanyID = (
    companyid,
    appname,
    contactRole,
    index
  ) => {
    const vendorList_sorted = vendorList.sort((a, b) =>
      a["vendorname"].toLowerCase() > b["vendorname"].toLowerCase() ? 1 : -1
    );

    var _resultvendors = vendorList_sorted.filter(
      (vendorinfo) =>
        vendorinfo.companyid === companyid &&
        vendorinfo?.appname?.toLowerCase() === appname.toLowerCase() &&
        vendorinfo?.contactrole?.toLowerCase() === contactRole?.toLowerCase()
    );

    var uniqueVendors = _resultvendors.filter(
      (arr, index, self) =>
        index === self.findIndex((t) => t.label === arr.label)
    );

    console.log("uniqueVendors", uniqueVendors);
    console.log("_vendorbycompany", _resultvendors);
    setQuickBooksCompanyList(uniqueVendors);
  };

  async function approvePendingListUpdate() {
    var status = "approved";
    UpdatePendingUpdateTaskByID(userlist_step2, selectedSourceUser, status);
  }
  async function rejectPendingListUpdate() {
    var status = "rejected";
    console.log(userlist_step2);
    UpdatePendingUpdateTaskByID(userlist_step2, selectedSourceUser, status);
  }

  const handleViewClick = (id) => {
    // history.push({
    //   pathname: "/detailcontact",
    //   state: { contact_id: id, listpagename: "pendingcreate" },
    // });
    setIsView(true);
    let obj = {
      id: id,
      title: "pendingcreation",
    };
    setViewData(obj);
  };
  const handleApproveClick = (data) => {
    console.log("data", JSON.stringify(data._id));
    setRowApproveInfo(data);
    setIsApproveModal(true);
  };

  const handleRejectClick = (data) => {
    setRowRejectInfo([data]);
    console.log("RowRejectInfo", data);
    setisRejected(true);
  };

  const handleRejectSweetAlert = () => {
    var _data = rowRejectInfo;
    console.log("_data", _data);
    var status = "rejected";
    UpdatePendingUpdateTaskByID(_data, _data, status);
    setisRejected(false);
    // setisRejectedConfirm(true);
  };

  // async function rejectcontactsettings(amlsettingsid, userInfo) {
  //   rejectContactSettingsByID(amlsettingsid, userInfo).then((response) => {
  //     if (response !== undefined) {
  //       setisRejected(false);
  //       setisRejectedConfirm(true);
  //     }
  //   });
  // }
  const handleConfirmRejectSweetAlert = () => {
    setisRejectedConfirm(false);
    handleRejectCancel();
  };
  const handleRejectCancel = () => {
    setisRejected(false);
    setisRejectedConfirm(false);
    return true;
  };

  // This a custom filter UI for selecting
  // a unique option from a list
  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    const handleConfirmVendor = (row) => {
      handleDropDownChangeAddCompany(changeVendorRow);
      setIsVendorChange(false);
    };

    const handleCancelVendor = (row) => {
      setIsVendorChange(false);
    };

    return (
      <select
        className="form-select"
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }
  // Define a default UI for filtering
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;

    return (
      <input
        className="form-control"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
      />
    );
  }

  const handleConfirmVendor = (row) => {
    handleDropDownChangeAddCompany(changeVendorRow);
    setIsVendorChange(false);
  };

  const handleCancelVendor = (row) => {
    setIsVendorChange(false);
  };
  const handleRedirect = () => {
    history.push({
      pathname: "/dashboard",
    });
  };
  return (
    <div className="tab-content p-0">
      <PageSubHeader title={"Pending creation"} />
      {/* <hr></hr> */}
      <div
        className={"tab-pane fade " + (tabPendingUsers ? "show active " : "")}
      >
        <div className=" stepWrapper justify-content-around">
          <div className={"stepBlock  " + (tabPendingUsers ? "selected " : "")}>
            <div className="circleWrapper">
              <div className="circle d-flex align-item-center justify-content-center">
                1
              </div>
            </div>
            <span className="d-flex justify-content-center">
              Select contact
            </span>
          </div>

          <div className={"stepBlock " + (tabApprovalUsers ? "active " : "")}>
            <div className="circleWrapper d-flex align-item-center justify-content-center ">
              <div className="circle d-flex align-item-center justify-content-center">
                2
              </div>
            </div>
            <span className="d-flex justify-content-center">
              Review and Approve
            </span>
          </div>
        </div>
        <div className="col-xl-12">
          <Panel>
            <PanelHeader noButton={true}>
              {/* <div className="d-flex align-items-center">
                <div>
                  <ul className="profile-header-tab nav nav-tabs">
                    <li className="nav-item me-2" key="pendingusers">
                      <div
                        className={
                          "nav-link text-dark " +
                          (tabPendingUsers ? "active" : "")
                        }
                      >
                        Select contact
                      </div>
                    </li>
                    <li className="nav-item me-2" key="pendingusers">
                      <div
                        className={
                          "nav-link text-dark " +
                          (tabApprovalUsers ? "active" : "")
                        }
                      >
                        Review and approve
                      </div>
                    </li>
                  </ul>
                </div>
              </div> */}
            </PanelHeader>
            <GenericListTable
              gcolumns={appColumns}
              gdata={data.length > 0 ? JSON.stringify(data) : ""}
              ghiddencolumns={hiddenappColumns}
              gplaceholder={"Search pending creation lists.."}
              gsearchDisabled={true}
            />
            {vaildmsg && (
              <div
                className="form-check-label  text-center"
                htmlFor="validation"
              >
                <h3 className="text-danger ">
                  {appConstants.import.handlefieldvalidation.nodata}
                </h3>
              </div>
            )}
          </Panel>
        </div>
      </div>

      <div
        className={"tab-pane fade " + (tabApprovalUsers ? "show active " : "")}
      >
        <div className="stepWrapper  justify-content-around">
          <div className={"stepBlock " + (tabPendingUsers ? "selected " : "")}>
            <div className="circleWrapper">
              <div className="circle d-flex align-item-center justify-content-center">
                1
              </div>
            </div>
            <span className="me-5 d-flex justify-content-center">
              Select contact
            </span>
          </div>
          <div className={"stepBlock " + (tabApprovalUsers ? "selected " : "")}>
            <div className="circleWrapper d-flex align-item-center justify-content-center">
              <div className="circle d-flex align-item-center justify-content-center">
                2
              </div>
            </div>
            <span className="me-5">Review and Approve</span>
          </div>
        </div>
        <div className="col-xl-12">
          <Panel>
            <PanelHeader noButton={true}>
              {/* <div className="d-flex align-items-center">
                <div>
                  <ul className="profile-header-tab nav nav-tabs">
                    <li className="nav-item me-2" key="pendingusers">
                      <div
                        className={
                          "nav-link text-dark " +
                          (tabPendingUsers ? "active" : "")
                        }
                      >
                        Select contact
                      </div>
                    </li>
                    <li className="nav-item me-2" key="pendingusers">
                      <div
                        className={
                          "nav-link text-dark " +
                          (tabApprovalUsers ? "active" : "")
                        }
                      >
                        Review and approve
                      </div>
                    </li>
                  </ul>
                </div>
              </div> */}
            </PanelHeader>

            <PanelBody className="p-0 m-2">
              <GenericListTable
                gcolumns={appColumns_approval}
                gdata={
                  data_approval.length > 0 ? JSON.stringify(data_approval) : ""
                }
                ghiddencolumns={hiddenappColumns_approval}
                gplaceholder={"Search pending creation lists.."}
                gsearchDisabled={true}
              />
            </PanelBody>
          </Panel>
        </div>
      </div>
      <div className="footer  m-3  d-flex justify-content-between">
        {tabApprovalUsers && (
          <button
            className="btn btn-sm me-2"
            disabled={page == 0}
            onClick={(e) => {
              console.log("page - 1", page - 1);
              Stepage((currPage) => currPage - 1);
              if (page) showTab(FormTitles[page - 1]);

              setUserList_Step2([]);
            }}
          >
            {"<< Prev"}
          </button>
        )}
        {tabPendingUsers && (
          <button
            className="btn btn-sm"
            disabled={
              validationMsg.length !== 0 ||
              selectedSourceUser.length === 0 ||
              page == 1
            }
            onClick={async (e) => {
              console.log("page + 1", page + 1);
              console.log("FormTitles[page] ", FormTitles[page + 1]);
              Stepage((currPage) => currPage + 1);
              showTab(FormTitles[page + 1]);

              var Pending_Lists = [];
              setUserList_Step2([]);

              // Extract unique caIds
              const unique_users = [
                ...new Set(selectedSourceUser.map((pending) => pending.caId)),
              ];

              console.log("unique_users", JSON.stringify(unique_users));

              unique_users?.map((caID) => {
                let _caID = caID;
                const _userAffList = selectedSourceUser.filter(
                  (userinfo) => userinfo.caId === _caID
                );
                let _userAffListTemp;
                if (_userAffList.length > 0) {
                  var _pendingUser = {};
                  _userAffList.map((singleuser, index) => {
                    const _singleuser = { ...singleuser };
                    if (index === 0) {
                      _pendingUser = _singleuser;
                      _pendingUser.contactAffiliationsArray = [
                        _singleuser?.contactAffiliationsArray[0],
                      ];
                    } else {
                      _pendingUser.contactAffiliationsArray.push(
                        _singleuser?.contactAffiliationsArray[0]
                      );
                    }
                  });
                  Pending_Lists.push(_pendingUser);
                }
              });

              console.log("Pending_Lists", JSON.stringify(Pending_Lists));
              setUserList_Step2(Pending_Lists);

              // setUserList_Step2(selectedSourceUser);
            }}
          >
            {"Next >>"}
          </button>
        )}
        {tabApprovalUsers && (
          <div className="">
            <button
              className="btn btn-sm me-2"
              disabled={userlist_step2.length === 0 || userStepbutton2}
              onClick={() => approvePendingListUpdate()}
            >
              {" Approve "}
            </button>
            <button
              className="btn btn-sm me-2 "
              disabled={userlist_step2.length === 0 || userStepbutton2}
              onClick={() => rejectPendingListUpdate()}
            >
              {" Reject "}
            </button>
          </div>
        )}
      </div>
      <div className="d-flex align-items-left">
        {validationMsg !== "" && (
          <span className="text-danger d-flex justify-content-start">
            {validationMsg}
          </span>
        )}
      </div>
      {isApproveModal && (
        <Modal
          show={isApproveModal}
          onHide={() => {
            setIsApproveModal(false);
          }}
          size="xl"
          centered
        >
          <Modal.Body>
            <div className="d-flex justify-content-center align-items-center">
              <img
                src="/images/cm_logo.png"
                className="headerLogo"
                alt="Logo"
              />
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <label className="h5">Select company information</label>
            </div>
            <hr></hr>

            <div>
              {rowApproveInfo?.contactAffiliationsArray[0]?.sourceSystem?.appname.toLowerCase() !==
                "quickbooks" && (
                <div>
                  <hr></hr>
                  <div className="row mt-3 d-flex justify-content-center align-items-center">
                    <div className="col-sm-12 col-md-2 col-lg-1"> </div>
                    <div className="col-sm-12 col-md-2 col-lg-3 fw-bold text-start">
                      <label>Connect apps</label>
                    </div>
                    <div className="col-sm-12 col-md-2 col-lg-4 fw-bold  text-start">
                      <label>Account display name</label>
                    </div>

                    <div className="col-sm-12 col-md-2 col-lg-4 fw-bold  text-start">
                      <label>Contact company</label>
                    </div>
                  </div>
                  <hr></hr>
                  {appAffList?.map((row, index) => (
                    <div key={index}>
                      {row.appname.toLowerCase() !== "quickbooks" &&
                        row.appname.toLowerCase() ==
                          rowApproveInfo?.contactAffiliationsArray[0].sourceSystem?.appname.toLowerCase() && (
                          <div className="row pb-1 d-flex justify-content-center align-items-center">
                            <div className="col-sm-12 col-md-2 col-lg-1">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                name="SelectSource"
                                id="SelectSource"
                                checked={true}
                                onChange={(e) =>
                                  handleChange_AppSelect(e, row, index)
                                }
                              />
                            </div>
                            <div className="col-sm-12 col-md-2 col-lg-3">
                              <span className="d-flex">{row.appname}</span>
                            </div>
                            <div className="col-sm-12 col-md-2 col-lg-4">
                              <span className="d-flex">
                                {row.appuserinfo[0]?.appdisplayname}
                              </span>
                            </div>

                            <div className="col-sm-12 col-md-2 col-lg-4">
                              <CreatableSelect
                                name={"vendor_" + index}
                                id={"vendor_" + index}
                                options={GetVendorListByCompanyID(
                                  row.appaccountid,
                                  row.appname,
                                  index
                                )}
                                isClearable={false}
                                isSearchable={true}
                                // styles={customexpandstyles}
                                placeholder={
                                  <div className="d-flex">Select...</div>
                                }
                                value={vendorList.filter(
                                  (vendorinfo) =>
                                    vendorinfo.vendorname ===
                                    rowApproveInfo.contactAffiliationsArray[0]
                                      .companyName
                                )}
                                onChange={(val) => {
                                  var changeinfo = {
                                    value: val,
                                    name: "vendor",
                                    rowindex: index,
                                  };
                                  if (row.vendorname === "") {
                                    setchangeVendorRow(changeinfo);
                                    handleDropDownChangeAddCompany(changeinfo);
                                  } else {
                                    setIsVendorChange(!isVendorChange);
                                    setchangeVendorRow(changeinfo);
                                  }
                                }}
                              ></CreatableSelect>
                            </div>
                          </div>
                        )}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div>
              {rowApproveInfo?.contactAffiliationsArray[0]?.sourceSystem?.appname.toLowerCase() ===
                "quickbooks" && (
                <div>
                  <hr></hr>
                  <div className="row mt-3 d-flex justify-content-center align-items-center">
                    <div className="col-sm-12 col-md-2 col-lg-1">
                      {/* {" "}
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="allSelectSource"
                      id="allSelectSource"
                      // allSelect selected when both length equal

                      defaultChecked={true}
                      onChange={(e) => handleChange_AppSelect(e, appAffList)}
                    /> */}
                    </div>
                    <div className="col-sm-12 col-md-2 col-lg-2 fw-bold text-start">
                      <label>Connect apps</label>
                    </div>
                    <div className="col-sm-12 col-md-2 col-lg-3 fw-bold  text-start">
                      <label>Account display name</label>
                    </div>
                    <div className="col-sm-12 col-md-2 col-lg-3 fw-bold  text-start">
                      <label>Contact role</label>
                    </div>
                    <div className="col-sm-12 col-md-2 col-lg-3 fw-bold  text-start">
                      <label>Contact company</label>
                    </div>
                  </div>
                  <hr></hr>
                  {appAffList?.map((row, index) => (
                    <div key={index}>
                      {row.appname.toLowerCase() === "quickbooks" && (
                        <div className="row pb-1 d-flex justify-content-center align-items-center">
                          <div className="col-sm-12 col-md-2 col-lg-1">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name="SelectSource"
                              id="SelectSource"
                              checked={row?.SelectSource}
                              onChange={(e) =>
                                handleChange_AppSelect(e, row, index)
                              }
                            />
                          </div>
                          <div className="col-sm-12 col-md-2 col-lg-2">
                            <span className="d-flex">{row.appname}</span>
                          </div>
                          <div className="col-sm-12 col-md-2 col-lg-3">
                            <span className="d-flex">
                              {row.appuserinfo[0]?.appdisplayname}
                            </span>
                          </div>
                          <div className="col-sm-12 col-md-2 col-lg-3">
                            {row?.appname?.toLowerCase() === "quickbooks" && (
                              <Select
                                name={"contactrole_" + index}
                                id={"contactrole_" + index}
                                options={contactRoleList}
                                isClearable={false}
                                isSearchable={true}
                                // styles={customexpandstyles}
                                placeholder={
                                  <div className="d-flex">Select...</div>
                                }
                                value={contactRoleList.filter(
                                  (contactrole) =>
                                    contactrole.value ===
                                    rowApproveInfo?.contactAffiliationsArray[0]
                                      ?.sourceSystem?.contactrole
                                )}
                                onChange={(val) => {
                                  handleDropDownChangeContactToleAddCompany(
                                    val?.value
                                  );

                                  var _rowApproveInfo = { ...rowApproveInfo };
                                  _rowApproveInfo.contactAffiliationsArray[0].sourceSystem.contactrole =
                                    val?.value;
                                  setRowApproveInfo(_rowApproveInfo);
                                  var _appAffList = [...appAffList];
                                  _appAffList[index].contactrole = val?.value;
                                  _appAffList[index].selectvalue = "";
                                  _appAffList[index].vendorid = "";
                                  _appAffList[index].vendorname = "";
                                  setAppAffList(_appAffList);
                                  GetVendorQuickbooksListByCompanyID(
                                    row.appaccountid,
                                    row.appname,
                                    val?.value,
                                    index
                                  );

                                  var tempselectedSourceUser = [
                                    ...selectedSourceUser,
                                  ];

                                  const objIndex1 =
                                    tempselectedSourceUser.findIndex(
                                      (obj) => obj._id === _rowApproveInfo._id
                                    );
                                  if (objIndex1 !== -1) {
                                    tempselectedSourceUser[
                                      objIndex1
                                    ].contactAffiliationsArray[0].contactRole =
                                      val?.value;
                                  }
                                  setSelectedSourceUser(tempselectedSourceUser);

                                  let validate = false;

                                  tempselectedSourceUser?.map((_user) => {
                                    const qb_validate =
                                      _user?.contactAffiliationsArray?.filter(
                                        (affinfo) =>
                                          affinfo?.contactRole === "" &&
                                          affinfo?.sourceSystem?.appname?.toLowerCase() ===
                                            "quickbooks"
                                      );

                                    if (qb_validate.length > 0) {
                                      validate = true;
                                    }
                                  });
                                  if (validate) {
                                    if (
                                      validationMsg !==
                                      appConstants.import.quickbooks
                                        .multipleuser
                                    ) {
                                      setvalidationMsg(
                                        appConstants.import.quickbooks.role
                                      );
                                    }
                                  } else {
                                    if (
                                      validationMsg !==
                                      appConstants.import.quickbooks
                                        .multipleuser
                                    ) {
                                      setvalidationMsg("");
                                    }
                                  }
                                }}
                              ></Select>
                            )}
                          </div>
                          <div className="col-sm-12 col-md-2 col-lg-3">
                            <CreatableSelect
                              name={"vendor_" + index}
                              id={"vendor_" + index}
                              options={quickBooksCompanyList}
                              isClearable={false}
                              isSearchable={true}
                              // styles={customexpandstyles}
                              placeholder={
                                <div className="d-flex">Select...</div>
                              }
                              value={vendorList.filter(
                                (vendorinfo) =>
                                  vendorinfo.vendorname ===
                                  rowApproveInfo?.contactAffiliationsArray[0]
                                    ?.companyName
                              )}
                              onChange={(val) => {
                                var changeinfo = {
                                  value: val,
                                  name: "vendor",
                                  rowindex: index,
                                };
                                if (row.vendorname === "") {
                                  setchangeVendorRow(changeinfo);
                                  handleDropDownChangeAddCompany(changeinfo);
                                } else {
                                  setIsVendorChange(!isVendorChange);
                                  setchangeVendorRow(changeinfo);
                                }
                              }}
                            ></CreatableSelect>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {errormsg.length > 0 && (
              <div className="text-danger">{errormsg}</div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-end gap-2">
              <Button
                className="btn btn-primary "
                onClick={() => {
                  setIsApproveModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                className="btn btn-primary me-2"
                //  disabled={!isVaildData}
                onClick={submitConfirm}
              >
                Confirm
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      {isRejected && (
        <SweetAlert
          style={{ color: "black" }}
          warning
          title=""
          confirmBtnText="Yes"
          confirmBtnBsStyle="danger"
          cancelBtnText="No"
          cancelBtnBsStyle="info"
          showCancel
          focusCancelBtn
          onConfirm={handleRejectSweetAlert}
          onCancel={handleRejectCancel}
        >
          <h5>
            {appConstants.contact.confirmationmsg.rejectwish +
              " " +
              rowRejectInfo[0]?.firstname +
              " " +
              rowRejectInfo[0]?.lastname}
          </h5>
        </SweetAlert>
      )}
      {isRejectedConfirm && (
        <SweetAlert
          success
          style={{ color: "black" }}
          title={appConstants.contact.successfullmsg.reject}
          onConfirm={handleConfirmRejectSweetAlert}
        ></SweetAlert>
      )}

      {isApprovedMsg && (
        <SweetAlert
          success
          style={{ color: "black" }}
          title={appConstants.contact.successfullmsg.approve}
          onConfirm={(e) => {
            setisApprovedMsg(false);
            window.location.reload();
          }}
        ></SweetAlert>
      )}

      {isRejectedMsg && (
        <SweetAlert
          success
          style={{ color: "black" }}
          title={appConstants.contact.successfullmsg.reject}
          onConfirm={(e) => {
            setisRejectedMsg(false);
            window.location.reload();
          }}
        ></SweetAlert>
      )}

      {isVendorChange && (
        <SweetAlert
          warning
          style={{ color: "black" }}
          title=""
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel={true}
          onConfirm={handleConfirmVendor}
          onCancel={handleCancelVendor}
        >
          <div>{appConstants.contact.confirmationmsg.company}</div>
        </SweetAlert>
      )}
      {/* {isVendor && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title=""
            confirmBtnText="Ok"
            onConfirm={handleConfirmVendor}
          >
            <div>Company was successfully changed.</div>
          </SweetAlert>
        )} */}
      <LoadingDialog
        isOpen={loadDialogOpen}
        onClose={() => setloadDialogOpen(false)}
      />
      {isView && (
        <Modal
          scrollable={true}
          show={isView}
          onHide={() => setIsView(false)}
          size="xl"
          centered
        >
          <Modal.Header className="fw-bold h4">
            View Contact
            <a
              style={{ "text-decoration": "none" }}
              onClick={() => setIsView(false)}
              className="col d-flex justify-content-end text-dark"
            >
              <i class="fa-solid fa-xmark"></i>
            </a>
          </Modal.Header>
          <Modal.Body>
            <ViewContact value={viewData} />{" "}
          </Modal.Body>
          <Modal.Footer>
            <div className="row p-1">
              <div className=" d-flex justify-content-end mt-3 col-sm-12 col-md-12 col-lg-12">
                <button
                  className=" btn btn-primary me-2 "
                  onClick={() => setIsView(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default PendingCreationList;
