import { Button, ButtonGroup, Table } from "react-bootstrap";
//import VerifyUserActions from "../../components/customstyles/VerifyUserActions";
import Select from "react-select";
import { useEffect, useState } from "react";
import * as Realm from "realm-web";
import { appConstants } from "../../constants/app_constants";
import { useParams } from "react-router";
import customstyles from "../../components/customstyles/react-select-styles";
import { useAuth0 } from "@auth0/auth0-react";
import { getuserbyauthproviderid } from "../../services/users_services";
import { getappinfobyappname_companyid } from "../../services/applications_services";

import { useRealmApp } from "../../providers/RealmApp";
import { isEmail } from "validator";
import {
  getAllCountryCodes,
  getCollectionListsByFormName,
} from "../../services/collection_lists_services";
import { getuserbyid } from "../../services/users_services";
import SweetAlert from "react-bootstrap-sweetalert";
import PhoneInput from "react-phone-input-2";
import {
  getContactVerificationTaskByID,
  getcontactverifciationdetailByToken,
  updateContactVerificationTaskByID,
  updateContactVerificationTaskByID_AutoSave,
  resendSignupURL,
} from "../../services/contactVerificationTask_services";
import Spinner from "react-bootstrap/Spinner";
import LoadingDialog from "../../components/LoadingDialog";

function Contactverification() {
  const [userrole, setUserrole] = useState("1");
  const [inputverifyclassName, setinputverifyclassName] = useState(
    "form-control border-0"
  );
  const [inputnotacceptclassName, setinputnotacceptclassName] = useState(
    "form-control border-1"
  );
  const [inputverifydisabled, setinputverifydisabled] = useState(true);
  const [inputverifyenabled, setinputverifyenabled] = useState(false);
  const [crossbuttonicon, setcrossbuttonicon] = useState("fa  fa-times");
  const [tickbuttonicon, settickbuttonicon] = useState("fa  fa-check");
  const [loading, setLoading] = useState(false);
  const [validateMsg, setvalidateMsg] = useState("");

  const [salutationOptions, setSalutationOption] = useState([]);
  const [genderOptions, setGenderOption] = useState([]);
  const [phoneOptions, setPhoneOption] = useState([]);
  const [addressOptions, setAddressOption] = useState([]);
  const [userRoleOptions, setUserRoleOption] = useState([]);
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  const [isSuccess, setisSuccess] = useState(false);
  const [isTokenVaild, setisTokenVaild] = useState();
  const [isTokenLinkSend, setisTokenLinkSend] = useState(false);
  const [isInvaildToken, setisInvaildToken] = useState(false);
  const [isSignUp, setisSignUp] = useState(false);
  const [isSignIn, setisSignIn] = useState(false);
  const [isLoadSignUp, setisLoadSignUp] = useState(false);
  const [isLoadSignIn, setisLoadSignIn] = useState(false);
  const [isUserInfo, setisUserInfo] = useState([]);

  const initialpersonalPhoneState = {
    id: "",
    type: { name: "", value: "", status: "", state: "", newvalue: "" },
    phone: { name: "", value: "", status: "", state: "", newvalue: "" },
    ext: { name: "", value: "", status: "", state: "", newvalue: "" },
    extphone: { name: "", value: "", status: "", state: "", newvalue: "" },
    country: { name: "", value: "", status: "", state: "", newvalue: "" },
    isMain: { name: "", value: "", status: "", state: "", newvalue: "" },
  };
  const [personalPhone, setPersonalPhone] = useState(initialpersonalPhoneState);
  const [personalPhoneErrors, setPersonalPhoneErrors] = useState(
    initialpersonalPhoneState
  );
  const initialpersonalAddressState = {
    id: "",
    addressLabel: { name: "", value: "", status: "", state: "", newvalue: "" },
    addressLine1: { name: "", value: "", status: "", state: "", newvalue: "" },
    addressLine2: { name: "", value: "", status: "", state: "", newvalue: "" },
    city: { name: "", value: "", status: "", state: "", newvalue: "" },
    country: { name: "", value: "", status: "", state: "", newvalue: "" },
    state: { name: "", value: "", status: "", state: "", newvalue: "" },
    postalCode: { name: "", value: "", status: "", state: "", newvalue: "" },
    isMain: { name: "", value: "", status: "", state: "", newvalue: "" },
  };

  const [personalAddress, setPersonalAddress] = useState(
    initialpersonalAddressState
  );
  const [personalAddressErrors, setPersonalAddressErrors] = useState(
    initialpersonalAddressState
  );

  const initialpersonalEmailState = {
    id: "",
    label: { name: "", value: "", status: "", state: "", newvalue: "" },
    email: { name: "", value: "", status: "", state: "", newvalue: "" },
    isMain: { name: "", value: "", status: "", state: "", newvalue: "" },
  };
  const [PhoneArray, setPhoneArray] = useState(initialpersonalPhoneState);
  const [AddressArray, setAddressArray] = useState(initialpersonalAddressState);
  const [EmailArray, setEmailArray] = useState(initialpersonalEmailState);
  const [personalEmail, setPersonalEmail] = useState(initialpersonalEmailState);
  const [personalEmailErrors, setPersonalEmailErrors] = useState(
    initialpersonalEmailState
  );
  const initialbusinessPhoneState = [
    {
      id: "",
      type: { name: "", value: "", status: "", state: "", newvalue: "" },
      phone: { name: "", value: "", status: "", state: "", newvalue: "" },
      ext: { name: "", value: "", status: "", state: "", newvalue: "" },
      extphone: { name: "", value: "", status: "", state: "", newvalue: "" },
      country: { name: "", value: "", status: "", state: "", newvalue: "" },
      isMain: { name: "", value: "", status: "", state: "", newvalue: "" },
    },
  ];
  const initialbusinessPhoneErrorState = {
    id: "",
    type: { name: "", value: "", status: "", state: "", newvalue: "" },
    phone: { name: "", value: "", status: "", state: "", newvalue: "" },
    ext: { name: "", value: "", status: "", state: "", newvalue: "" },
    extphone: { name: "", value: "", status: "", state: "", newvalue: "" },
    country: { name: "", value: "", status: "", state: "", newvalue: "" },
    isMain: { name: "", value: "", status: "", state: "", newvalue: "" },
  };
  const [businessPhone, setBusinessPhone] = useState(initialbusinessPhoneState);
  const [businessPhoneErrors, setBusinessPhoneErrors] = useState(
    initialbusinessPhoneErrorState
  );
  const initialbusinessEmailState = [
    {
      id: "",
      label: { name: "", value: "", status: "", state: "", newvalue: "" },
      email: { name: "", value: "", status: "", state: "", newvalue: "" },
      isMain: { name: "", value: "", status: "", state: "", newvalue: "" },
      bus_index: { name: "", value: "", status: "", state: "", newvalue: "" },
      bus_phone_index: {
        name: "",
        value: "",
        status: "",
        state: "",
        newvalue: "",
      },
    },
  ];
  const initialbusinessEmailErrorState = {
    id: "",
    label: { name: "", value: "", status: "", state: "", newvalue: "" },
    email: { name: "", value: "", status: "", state: "", newvalue: "" },
    isMain: { name: "", value: "", status: "", state: "", newvalue: "" },
  };
  const [businessEmail, setBusinessEmail] = useState(initialbusinessEmailState);
  const [businessEmailErrors, setBusinessEmailErrors] = useState(
    initialbusinessEmailErrorState
  );

  const initialbusinessAddressState = [
    {
      id: "",
      addressLabel: {
        name: "",
        value: "",
        status: "",
        state: "",
        newvalue: "",
      },
      addressLine1: {
        name: "",
        value: "",
        status: "",
        state: "",
        newvalue: "",
      },
      addressLine2: {
        name: "",
        value: "",
        status: "",
        state: "",
        newvalue: "",
      },
      city: { name: "", value: "", status: "", state: "", newvalue: "" },
      country: { name: "", value: "", status: "", state: "", newvalue: "" },
      state: { name: "", value: "", status: "", state: "", newvalue: "" },
      postalCode: { name: "", value: "", status: "", state: "", newvalue: "" },
      isMain: { name: "", value: "", status: "", state: "", newvalue: "" },
    },
  ];
  const initialbusinessAddressErrorState = {
    id: "",
    addressLabel: { name: "", value: "", status: "", state: "", newvalue: "" },
    addressLine1: { name: "", value: "", status: "", state: "", newvalue: "" },
    addressLine2: { name: "", value: "", status: "", state: "", newvalue: "" },
    city: { name: "", value: "", status: "", state: "", newvalue: "" },
    country: { name: "", value: "", status: "", state: "", newvalue: "" },
    state: { name: "", value: "", status: "", state: "", newvalue: "" },
    postalCode: { name: "", value: "", status: "", state: "", newvalue: "" },
    isMain: { name: "", value: "", status: "", state: "", newvalue: "" },
  };

  const [businessAddress, setBusinessAddress] = useState(
    initialbusinessAddressState
  );
  const [businessAddressErrors, setBusinessAddressErrors] = useState(
    initialbusinessAddressErrorState
  );

  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    email: "",
  });
  const initialContactState = {
    _id: "",
    userid: "",
    firstname: { name: "", value: "", status: "", state: "", newvalue: "" },
    salutation: { name: "", value: "", status: "", state: "", newvalue: "" },
    lastname: { name: "", value: "", status: "", state: "", newvalue: "" },
    middlename: { name: "", value: "", status: "", state: "", newvalue: "" },
    phone: { name: "", value: "", status: "", state: "", newvalue: "" },
    email: { name: "", value: "", status: "", state: "", newvalue: "" },
    gender: { name: "", value: "", status: "", state: "", newvalue: "" },
    dateOfBirth: { name: "", value: "", status: "", state: "", newvalue: "" },
    nickName: { name: "", value: "", status: "", state: "", newvalue: "" },
    contactOwner: { name: "", value: "", status: "", state: "", newvalue: "" },
    description: { name: "", value: "", status: "", state: "", newvalue: "" },
    contactRole: { name: "", value: "", status: "", state: "", newvalue: "" },
    role: { name: "", value: "", status: "", state: "", newvalue: "" },
    PhoneArray: [],
    AddressArray: [],
    EmailArray: [],
    status: "",
    contactAffiliationsArray: [],
  };
  const [contactErrors, setContactErrors] = useState(initialContactState);

  const [contact, setContact] = useState(initialContactState);
  const initialbusinessContact = {
    id: uuid(),
    companyName: { name: "", value: "", status: "", state: "", newvalue: "" },
    contactOwner: { name: "", value: "", status: "", state: "", newvalue: "" },
    description: { name: "", value: "", status: "", state: "", newvalue: "" },
    role: { name: "", value: "", status: "", state: "", newvalue: "" },
    contactRole: { name: "", value: "", status: "", state: "", newvalue: "" },
    sourceSystemId: {
      name: "",
      value: "",
      status: "",
      state: "",
      newvalue: "",
    },
    contactRecordType: {
      name: "",
      value: "",
      status: "",
      state: "",
      newvalue: "",
    },
    department: { name: "", value: "", status: "", state: "", newvalue: "" },
    reportTo: { name: "", value: "", status: "", state: "", newvalue: "" },
    claimedUrl: { name: "", value: "", status: "", state: "", newvalue: "" },
    phoneArray: [],
    addressArray: [],
    emailArray: [],
  };

  const [businessContact, setBusinessContact] = useState(
    initialbusinessContact
  );
  const [businessContactErrors, setBusinessContactErrors] = useState(
    initialbusinessContact
  );

  const [isvaliddata, setisvaliddata] = useState(false);
  const [userInfo, setUserInfo] = useState(false);
  const [applogo, setAppLogo] = useState("/images/cm_logo.png");

  const { token } = useParams();
  // Get the API key from the local environment
  const apiKey = process.env.REACT_APP_APIKEY;
  if (!apiKey) {
    throw new Error("Could  not find a Realm Server API Key.");
  }
  const credentials = Realm.Credentials.apiKey(apiKey);
  const ctechapp = new Realm.App(process.env.REACT_APP_REALM_APP_ID);
  const [ids, setIds] = useState("");
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    getcontactverifciationIDByToken();

    async function getcontactverifciationIDByToken() {
      ctechapp.logIn(credentials).then(() => {
        setLoading(true);
        getcontactverifciationdetailByToken(
          token,
          ctechapp.currentUser,
          ctechapp.currentUser.id
        ).then((response) => {
          let token_info = JSON.parse(response);

          if (token_info !== undefined) {
            console.log("token_info ", token_info);
            if (token_info.istokenvalid === "true") {
              setIds(token_info.contactverificationid);
              setisTokenVaild(token_info.istokenvalid);
              getDataContactTaskDetailsByID(token_info.contactverificationid);
            } else {
              setisTokenVaild(token_info.istokenvalid);
              console.log("token expired");
            }
          }
          setLoading(false);
        });
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {}, [ctechapp.currentUser]);

  async function getDataContactTaskDetailsByID(idvalue) {
    ctechapp.logIn(credentials).then(() => {
      setLoading(true);
      if (
        ctechapp.currentUser !== null &&
        ctechapp.currentUser !== undefined &&
        userRoleOptions.length === 0
      ) {
        GetSalutationOptions();
        GetGenderOptions();
        GetPhoneOptions();
        GetAddressOptions();
        GetUserRoleOptions();
      }
      getContactVerificationTaskByID(
        idvalue,
        ctechapp.currentUser,
        ctechapp.currentUser.id
      ).then((response) => {
        if (response.length > 0) {
          let user_info = JSON.parse(response);

          if (user_info !== undefined || user_info.length !== 0) {
            const res = user_info[0];
            setContact(res);

            setPhoneArray(res.phoneArray);
            setAddressArray(res.addressArray);
            setEmailArray(res.emailArray);
            GetUserByID(res._p_contactID);
            console.log("res ", res._p_contactID);
            GetAppLogoByAppnam_CompanyID(
              res?.contactAffiliationsArray[0]?.sourceSystem?.appname,
              res?.contactAffiliationsArray[0]?.sourceSystem?.companyid
            );
          }
        }
        setLoading(false);
      });
    });
  }

  async function GetUserByID(id) {
    await getuserbyid(id, ctechapp.currentUser).then((response) => {
      if (response !== undefined) {
        let user_info = JSON.parse(response);
        console.log("user_info", user_info);
        setisUserInfo(user_info[0]);

        if (user_info[0].userid !== "" && user_info[0].userid !== undefined) {
          // if (!isAuthenticated) {
          //   setisSignIn(true);
          // }
        } else {
          setisSignUp(true);
        }
      }
    });
  }

  async function GetAppLogoByAppnam_CompanyID(appname, companyID) {
    try {
      await getappinfobyappname_companyid(
        appname,
        companyID,
        ctechapp.currentUser,
        ""
      ).then((response) => {
        if (response !== undefined) {
          let _appinfo = JSON.parse(response);
          _appinfo = _appinfo[0];
          if (
            _appinfo?.applogo !== undefined &&
            _appinfo?.applogo !== null &&
            _appinfo.applogo.length > 0
          ) {
            setAppLogo(_appinfo.applogo);
          }
        }
      });
    } catch (exception) {
      console.error("GetAppLogoByAppnam_CompanyID", exception);
    }
  }

  async function GetSalutationOptions() {
    await getCollectionListsByFormName("Salutation", ctechapp.currentUser).then(
      (response) => {
        if (response !== undefined) {
          let salutation_info = JSON.parse(response);

          let options = salutation_info.map(function (salutation) {
            return { value: salutation.listValue, label: salutation.listName };
          });

          setSalutationOption(options);
        }
      }
    );
  }
  async function GetGenderOptions() {
    await getCollectionListsByFormName("Gender", ctechapp.currentUser).then(
      (response) => {
        if (response !== undefined) {
          let gender_info = JSON.parse(response);

          let options = gender_info.map(function (gender) {
            return { value: gender.listValue, label: gender.listName };
          });

          setGenderOption(options);
        }
      }
    );
  }

  async function GetPhoneOptions() {
    await getCollectionListsByFormName("Phone", ctechapp.currentUser).then(
      (response) => {
        if (response !== undefined) {
          let phone_info = JSON.parse(response);

          let options = phone_info.map(function (phone) {
            return { value: phone.listValue, label: phone.listName };
          });

          setPhoneOption(options);
        }
      }
    );
  }

  async function GetAddressOptions() {
    await getCollectionListsByFormName("Address", ctechapp.currentUser).then(
      (response) => {
        if (response !== undefined) {
          let address_info = JSON.parse(response);
          let options = address_info.map(function (address) {
            return { value: address.listValue, label: address.listName };
          });
          setAddressOption(options);
        }
      }
    );
  }
  async function GetUserRoleOptions() {
    await getCollectionListsByFormName(
      "ContactRole",
      ctechapp.currentUser
    ).then((response) => {
      if (response !== undefined) {
        let userrole_info = JSON.parse(response);

        let options = userrole_info.map(function (userrole) {
          return {
            value: userrole.listValue,
            label: userrole.listName,
          };
        });

        setUserRoleOption(options);
      }
    });
  }

  const handleSendLink = () => {
    ResendSignupURL();
  };

  async function ResendSignupURL() {
    await resendSignupURL(
      token,
      ctechapp.currentUser,
      ctechapp.currentUser.id
    ).then((response) => {
      if (response !== undefined) {
        let url_info = JSON.parse(response);
        console.log("url_info", url_info);
        if (url_info !== "Invaild Token") {
          setvalidateMsg(
            appConstants.contact.successfullmsg.contactverification_newlink
          );
          setisTokenLinkSend(true);
        } else {
          setvalidateMsg(
            appConstants.contact.confirmationmsg
              .contactverification_incorrectsignupurl
          );
          setisInvaildToken(true);
        }
      }
    });
  }

  //save functions
  const handleDropDownChange = (
    selectedOption,
    itemupdate,
    afflist_index,
    row_index
  ) => {
    const { name, value } = selectedOption.target;
    if (itemupdate === "contact") {
      console.log(name, value);
      setContact({
        ...contact,
        [name]: {
          ...contact[name],
          value: value,
          newvalue: value,
        },
      });
      //  setContactErrors({ ...contactErrors, [name]: "" });
    }
    if (itemupdate === "personalphone") {
      setPersonalPhone({ ...personalPhone, [name]: value });

      setPersonalPhoneErrors({ ...personalPhoneErrors, [name]: "" });
    }
    if (itemupdate === "personaladdress") {
      setPersonalAddress({ ...personalAddress, [name]: value });
      setPersonalAddressErrors({ ...personalAddressErrors, [name]: "" });
    }
    if (itemupdate === "personalemail") {
      setPersonalEmail({ ...personalEmail, [name]: value });
    }
    if (itemupdate === "businessemail") {
      setBusinessEmail({ ...businessEmail, [name]: value });
    }
  };

  function handlecontactPhoneChange(event, type) {
    // console.log(target);
    const { name, value } = event.target;
    const path = name.split(".");
    const newpersonalcontact = { ...contact };
    let pointer = newpersonalcontact;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
      pointer.newvalue = value;
      //  console.log("el ", el);
    });

    setContact(newpersonalcontact);
    // handlePersonalFieldValidation(event);
    setisvaliddata(true);
  }

  function handleBusinessChange(e, itemId, property, index, islist) {
    const value = islist === true ? e.value : e.target.value;
    //copying data to temp variable so that we do not directly mutate original state

    var _contact = { ...contact };
    var temparrayprops = _contact.contactAffiliationsArray[index];
    temparrayprops[property]["newvalue"] = value;
    console.log("temparrayprops ", temparrayprops);

    _contact.contactAffiliationsArray[index] = temparrayprops;

    setContact(_contact);
  }

  const handlePersonalFieldValidation = (event) => {
    // event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "firstname":
        localerror =
          value.length < 3 ? "first name must be at least 3 characters" : "";
        break;
      case "lastname":
        localerror =
          value.length < 3 ? "last name must be at least 3 characters" : "";
        break;
      case "email":
        localerror = !isEmail(value) ? "Invalid Email" : "";
        break;
      default:
        break;
    }
    setContactErrors({ ...contactErrors, [name]: localerror });
    setisvaliddata(true);
  };

  const handleBusinessFieldValidation = (event) => {
    //event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "companyName":
        localerror =
          value.length < 3 ? "Company name must be at least 3 characters" : "";
        break;

      default:
        break;
    }
    setBusinessContactErrors({ ...contactErrors, [name]: localerror });
    setisvaliddata(true);
  };
  async function submitHandler(values) {
    console.log("contactwhilesubmit", contact);

    updateContactVerificationTaskByID(
      contact,
      ctechapp.currentUser,
      ctechapp.currentUser.id
    ).then((res) => {
      //alert("contact details updated successfully");
      setisSuccess(true);
    });
  }

  const handleSignUp = () => {
    setisLoadSignUp(false);

    gotoSignUp();
  };
  function gotoSignUp(id) {
    window.location.href = "/signupbyinvitation/" + token + "/1";
  }

  const handleSignIn = () => {
    setisLoadSignIn(false);
    const url = (window.location.href = "/contactverification/" + token);
    localStorage.setItem(contact.email?.newvalue, url);
    loginWithRedirect();
  };
  function gotoSignIn(id) {
    window.location.href = "/signinbyinvitation/" + token + "/1";
  }
  const handleNoSweetAlert = () => {
    setisLoadSignIn(false);
    setisLoadSignUp(false);
  };
  const handleSucessSweetAlert = () => {
    setisInvaildToken(false);
    setisTokenLinkSend(false);

    // if (ids !== undefined) {
    //   gotoContactList();
    // }
  };
  function gotoContactList() {
    window.location.href = "/";
  }

  const handleSucessSweetAlert1 = () => {
    setisSuccess(false);

    gotoverificationmsg();
  };
  function gotoverificationmsg() {
    window.location.href =
      "/verificationmsg/" +
      contact?.activity_history[0]?.activity_by_name +
      "/0";
  }
  const validBusinessForm = () => {
    try {
      let valid = false;
      valid = handleFieldBusinessListRequiredValidation();
      Object.values(personalEmailErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldBusinessListRequiredValidation = () => {
    let iserror = "";

    setBusinessContactErrors({
      ...businessContactErrors,
      companyName:
        businessContact.companyName === ""
          ? (iserror = "Company name required")
          : businessContact.companyName,
    });

    return iserror.length === 0;
  };

  const validPersonalForm = () => {
    try {
      let valid = false;
      valid = handleFieldPersonalListRequiredValidation();
      Object.values(personalEmailErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldPersonalListRequiredValidation = () => {
    let iserror = "";

    setContactErrors({
      ...contactErrors,
      firstname:
        contact.firstname === ""
          ? (iserror = "First name required")
          : contactErrors.firstname,
      lastname:
        contact.lastname === ""
          ? (iserror = "Last name required")
          : contactErrors.lastname,

      email:
        contact.email === ""
          ? (iserror = "Email required")
          : contactErrors.email,
    });

    return iserror.length === 0;
  };

  const handleCancel = () => {
    if (ids !== undefined) window.location.href = "/";
  };

  // handle change for personal phone
  const handleChangePersonalPhone = (
    event,
    itemid,
    index,
    pname,
    property,
    islist
  ) => {
    let name = "";
    let value = "";
    //event.preventDefault();
    if (islist === "true") {
      value = event.value;
      name = pname;
    } else {
      value = property === "fullPhone" ? event : event.target.value;
      name = property === "fullPhone" ? pname : event.target.name;
    }

    if (property === "isMain") {
      value = event.target.checked === true ? 1 : 0;
    }
    if (islist !== "true" && property != "fullPhone")
      handlePersonalEmailFieldValidation(event);

    name = name.replaceAll(/[_]/g, ".");
    const path = name.split(".");

    if (path.length === 3) {
      var el = path[0];
      var el1 = path[1];
      if (index > -1) {
        //copying data to temp variable so that we do not directly mutate original state
        const temparrayprops = [...PhoneArray];
        //findIndex to find location of item we need to update
        let sindex = temparrayprops.findIndex((item) => item.id == itemid);
        // -1 check to see if we found that object in social_media
        if (sindex != -1) {
          temparrayprops[sindex][el1] = {
            ...temparrayprops[sindex][el1], //keeping existing values in object
            newvalue: value, //here property can be "link"
          };
        }
        console.log("temparrayprops", temparrayprops);
        setPhoneArray(temparrayprops);
      }
    }
  };

  // handle change for personal address
  const handleChangePersonalAddress = (
    event,
    itemid,
    index,
    property,
    islist
  ) => {
    // event.preventDefault();
    let { name } = event.target;

    let value =
      islist === true
        ? event.value
        : property === "phone"
        ? event
        : event.target.value;
    if (property === "isMain") {
      value = event.target.checked === true ? 1 : 0;
    }

    if (property !== "addresslabel") handlePersonalEmailFieldValidation(event);
    name = name.replaceAll(/[_]/g, ".");
    const path = name.split(".");

    if (path.length === 3) {
      var el = path[0];
      var el1 = path[1];
      if (index > -1) {
        //copying data to temp variable so that we do not directly mutate original state
        const temparrayprops = [...AddressArray];
        //findIndex to find location of item we need to update
        let sindex = temparrayprops.findIndex((item) => item.id == itemid);
        // -1 check to see if we found that object in social_media
        if (sindex != -1) {
          temparrayprops[sindex][el1] = {
            ...temparrayprops[sindex][el1], //keeping existing values in object
            newvalue: value, //here property can be "link"
          };
        }
        setAddressArray(temparrayprops);
      }
    }
  };
  // handle change for personal email
  const handleChangePersonalEmail = (event, itemid, index, property) => {
    // event.preventDefault();
    let { name, value } = event.target;
    if (property === "isMain") {
      value = event.target.checked === true ? 1 : 0;
    }
    handlePersonalEmailFieldValidation(event);
    name = name.replaceAll(/[_]/g, ".");
    const path = name.split(".");

    if (path.length === 3) {
      var el = path[0];
      var el1 = path[1];
      if (index > -1) {
        //copying data to temp variable so that we do not directly mutate original state
        const temparrayprops = [...EmailArray];
        //findIndex to find location of item we need to update
        let sindex = temparrayprops.findIndex((item) => item.id == itemid);
        // -1 check to see if we found that object in social_media
        if (sindex != -1) {
          temparrayprops[sindex][el1] = {
            ...temparrayprops[sindex][el1], //keeping existing values in object
            newvalue: value, //here property can be "link"
          };
        }
        setEmailArray(temparrayprops);
      }
    }
  };

  const handlePersonalEmailFieldValidation = (event) => {
    //event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "email":
        localerror = !isEmail(value) ? "Invalid Email" : "";
        break;
      default:
        break;
    }
    setPersonalEmailErrors({ ...personalEmailErrors, [name]: localerror });
    setisvaliddata(true);
  };
  //business info
  // handle change for business phone
  function handleChangeBusinessPhone(
    e,
    afflist_index,
    _phoneList_index,
    property,
    islist
  ) {
    let value =
      islist === true ? e.value : property === "fullPhone" ? e : e.target.value;
    if (property === "isMain") {
      value = e.target.checked === true ? 1 : 0;
    }

    var _contact = { ...contact };
    var temparrayprops =
      _contact.contactAffiliationsArray[afflist_index].phoneArray[
        _phoneList_index
      ];
    temparrayprops[property]["newvalue"] = value;
    console.log("temparrayprops ", temparrayprops);

    _contact.contactAffiliationsArray[afflist_index].phoneArray[
      _phoneList_index
    ] = temparrayprops;

    setContact(_contact);
  }
  //button action for Business phone
  //button action for Business address
  const SelectBusinessPhoneRow = (index, id) => {
    const rowsInput = [...contact.contactAffiliationsArray[index].phoneArray];
    setBusinessPhone((prevbusinenessphone) => {
      //  let localbusinessEmail=prevbusinenessemail[index];
      rowsInput.forEach((element) => {
        if (element.id === id) prevbusinenessphone[index] = element;
      });
      return prevbusinenessphone;
    });
    setBusinessPhoneErrors(initialbusinessPhoneErrorState);
  };

  const deleteBusinessPhoneRow = (index, rowindex) => {
    const rows = [...contact.contactAffiliationsArray[index].phoneArray];
    rows.splice(rowindex, 1);
    setContact((prevcontact) => {
      prevcontact.contactAffiliationsArray[index].phoneArray = rows;
      //    console.log("prevcontact",JSON.stringify(prevcontact));
      setBusinessPhone(initialbusinessPhoneState);
      return prevcontact;
    });
  };

  //button action for Business address
  const SelectBusinessAddressRow = (index, id) => {
    const rowsInput = [...contact.contactAffiliationsArray[index].addressArray];
    setBusinessAddress((prevbusinenessemail) => {
      //  let localbusinessEmail=prevbusinenessemail[index];
      rowsInput.forEach((element) => {
        if (element.id === id) prevbusinenessemail[index] = element;
      });
      return prevbusinenessemail;
    });
    setBusinessAddressErrors(initialbusinessAddressErrorState);
  };

  const deleteBusinessAddressRow = (index, rowindex) => {
    const rows = [...contact.contactAffiliationsArray[index].addressArray];
    rows.splice(rowindex, 1);
    setContact((prevcontact) => {
      prevcontact.contactAffiliationsArray[index].addressArray = rows;
      //    console.log("prevcontact",JSON.stringify(prevcontact));
      setBusinessAddress(initialbusinessAddressState);
      return prevcontact;
    });
  };

  //button action for Business email

  const SelectBusinessEmailRow = (index, id) => {
    const rowsInput = [...contact.contactAffiliationsArray[index].emailArray];
    setBusinessEmail((prevbusinenessemail) => {
      //  let localbusinessEmail=prevbusinenessemail[index];
      rowsInput.forEach((element) => {
        if (element.id === id) prevbusinenessemail[index] = element;
      });
      return prevbusinenessemail;
    });
    setBusinessEmailErrors(initialbusinessEmailErrorState);
  };

  const deleteBusinessEmailRow = (index, rowindex) => {
    const rows = [...contact.contactAffiliationsArray[index].emailArray];
    rows.splice(rowindex, 1);
    setContact((prevcontact) => {
      prevcontact.contactAffiliationsArray[index].emailArray = rows;
      //    console.log("prevcontact",JSON.stringify(prevcontact));
      setBusinessEmail(initialbusinessEmailState);
      return prevcontact;
    });
  };

  //generate guid
  function uuid() {
    var uuidValue = "",
      k,
      randomValue;
    for (k = 0; k < 32; k++) {
      randomValue = (Math.random() * 16) | 0;

      if (k == 8 || k == 12 || k == 16 || k == 20) {
        uuidValue += "";
      }
      uuidValue += (
        k == 12 ? 4 : k == 16 ? (randomValue & 3) | 8 : randomValue
      ).toString(16);
    }
    return uuidValue;
  }

  const addPersonalEmail = async (e) => {
    e.preventDefault();
    if (validEmailForm()) {
      //console.log("personalEmail : ", personalEmail);
      if (EmailArray !== null) {
        const objIndex = EmailArray.findIndex(
          (obj) => obj.id === personalEmail.id
        );
        console.log("objIndex ", objIndex);
        if (objIndex != -1) {
          const rowsInput = [...EmailArray];
          rowsInput[objIndex] = personalEmail;

          setEmailArray(rowsInput);
          setPersonalEmail(initialpersonalEmailState);
        } else {
          setEmailArray([...EmailArray, personalEmail]);
          setPersonalEmail(initialpersonalEmailState);
        }
      } else {
        let emailArray = [];
        emailArray.push(personalEmail);
        setEmailArray(emailArray);
        setPersonalEmail(initialpersonalEmailState);
      }
    }
  };
  const validEmailForm = () => {
    try {
      let valid = false;
      valid = handleFieldPersonalEmailListRequiredValidation();
      Object.values(personalEmailErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldPersonalEmailListRequiredValidation = () => {
    let iserror = "";

    setPersonalEmailErrors({
      ...personalEmailErrors,
      label:
        personalEmail.label === ""
          ? (iserror = "Label required")
          : personalEmailErrors.label,
      email:
        personalEmail.email === ""
          ? (iserror = "Email  required")
          : personalEmailErrors.email,
    });

    return iserror.length === 0;
  };

  //add personal address

  const addPersonalAddress = async (e) => {
    e.preventDefault();
    if (validAddressForm()) {
      if (AddressArray !== null) {
        const objIndex = AddressArray.findIndex(
          (obj) => obj.id === personalAddress.id
        );
        if (objIndex !== -1) {
          const rowsInput = [...AddressArray];
          rowsInput[objIndex] = personalAddress;

          setAddressArray(rowsInput);
          setPersonalAddress(initialpersonalAddressState);
        } else {
          setAddressArray([...AddressArray, personalAddress]);
          setPersonalAddress(initialpersonalAddressState);
        }
      } else {
        let addressArray = [];
        addressArray.push(personalAddress);
        setAddressArray(addressArray);
        setPersonalAddress(initialpersonalAddressState);
      }
    }
  };
  const validAddressForm = () => {
    try {
      let valid = false;
      valid = handleFieldPersonalAddressListRequiredValidation();
      Object.values(personalAddressErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldPersonalAddressListRequiredValidation = () => {
    let iserror = "";

    setPersonalAddressErrors({
      ...personalAddressErrors,
      addressLabel:
        personalAddress.addressLabel === ""
          ? (iserror = "Label required")
          : personalAddressErrors.addressLabel,
      addressLine1:
        personalAddress.addressLine1 === ""
          ? (iserror = "Address required")
          : personalAddressErrors.addressLine1,
    });

    return iserror.length === 0;
  };

  //add personal phone

  const addPersonalPhone = async (e) => {
    e.preventDefault();
    if (validPhoneForm()) {
      if (PhoneArray !== null) {
        const objIndex = PhoneArray.findIndex(
          (obj) => obj.id === personalPhone.id
        );
        if (objIndex !== -1) {
          const rowsInput = [...PhoneArray];
          rowsInput[objIndex] = personalPhone;

          setPhoneArray(rowsInput);
          setPersonalPhone(initialpersonalPhoneState);
        } else {
          setPhoneArray([...PhoneArray, personalPhone]);
          setPersonalPhone(initialpersonalPhoneState);
        }
      } else {
        let phoneArray = [];
        phoneArray.push(personalPhone);
        setPhoneArray(phoneArray);
        setPersonalPhone(initialpersonalPhoneState);
      }
    }
  };
  const validPhoneForm = () => {
    try {
      let valid = false;
      valid = handleFieldPersonalPhoneListRequiredValidation();
      Object.values(personalPhoneErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldPersonalPhoneListRequiredValidation = () => {
    let iserror = "";

    setPersonalPhoneErrors({
      ...personalPhoneErrors,
      type:
        personalPhone.type === ""
          ? (iserror = "Type required")
          : personalPhoneErrors.type,
      // country:
      //   personalPhone.country === ""
      //     ? (iserror = "Country code required")
      //     : personalPhoneErrors.country,
      phone:
        personalPhone.phone === ""
          ? (iserror = "Phone required")
          : personalPhoneErrors.phone,
    });

    return iserror.length === 0;
  };

  function handleChangeBusinessEmail(
    e,
    afflist_index,
    _emailList_index,
    property,
    islist
  ) {
    let value =
      islist === true ? e.value : property === "phone" ? e : e.target.value;
    if (property === "isMain") {
      value = e.target.checked === true ? 1 : 0;
    }

    var _contact = { ...contact };
    var temparrayprops =
      _contact.contactAffiliationsArray[afflist_index].emailArray[
        _emailList_index
      ];
    temparrayprops[property]["newvalue"] = value;
    console.log("temparrayprops ", temparrayprops);

    _contact.contactAffiliationsArray[afflist_index].emailArray[
      _emailList_index
    ] = temparrayprops;

    setContact(_contact);
  }
  // add business email
  const addBusinessEmail = (e, index) => {
    e.preventDefault();
    if (validBusinessEmailForm(index)) {
      const _businessEmail = businessEmail[index];
      //copying data to temp variable so that we do not directly mutate original state
      let temparrayprops = [
        ...contact.contactAffiliationsArray[index].emailArray,
      ];
      //findIndex to find location of item we need to update
      let record_index = temparrayprops.findIndex(
        (item) => item.id == _businessEmail.id
      );
      // -1 check to see if we found that object in social_media
      if (record_index != -1) {
        temparrayprops[record_index] = _businessEmail;
        // ...temparrayprops[index], //keeping existing values in object
        // _businessEmail: value, //here property can be "link"
      } else {
        _businessEmail.id = uuid();
        temparrayprops.push(_businessEmail);
      }
      setContact((prevcontact) => {
        prevcontact.contactAffiliationsArray[index].emailArray = temparrayprops;
        //    console.log("prevcontact",JSON.stringify(prevcontact));
        setBusinessEmail(initialbusinessEmailState);
        return prevcontact;
      });
    }
  };

  const validBusinessEmailForm = (index) => {
    try {
      let valid = false;
      return true;
      valid = handleFieldBusinessEmailListRequiredValidation(index);
      Object.values(personalEmailErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldBusinessEmailListRequiredValidation = (index) => {
    let iserror = "";
    return true;
    setBusinessEmailErrors({
      ...businessEmailErrors,
      label:
        businessEmail[index].label === ""
          ? (iserror = "Label required")
          : businessEmailErrors?.label,
      email:
        businessEmail[index].email === ""
          ? (iserror = "Email  required")
          : businessEmailErrors?.email,
    });

    return iserror.length === 0;
  };

  // handle change for business Address

  function handleChangeBusinessAddress(
    e,
    afflist_index,
    _addressList_index,
    property,
    islist
  ) {
    let value = islist === true ? e.value : e.target.value;
    if (property === "isMain") {
      value = e.target.checked === true ? 1 : 0;
    }

    var _contact = { ...contact };
    var temparrayprops =
      _contact.contactAffiliationsArray[afflist_index].addressArray[
        _addressList_index
      ];
    // var property1 = property + ".value"
    // var property2 = property + ".newvalue"
    // temparrayprops[property1] = e.target.value;
    temparrayprops[property]["newvalue"] = value;
    console.log("temparrayprops ", temparrayprops);

    _contact.contactAffiliationsArray[afflist_index].addressArray[
      _addressList_index
    ] = temparrayprops;

    setContact(_contact);
  }
  //add business address
  const addBusinessAddress = (e, index) => {
    e.preventDefault();
    if (validBusinessAddressForm(index)) {
      const _businessAddress = businessAddress[index];
      //copying data to temp variable so that we do not directly mutate original state
      let temparrayprops = [
        ...contact.contactAffiliationsArray[index].addressArray,
      ];
      //findIndex to find location of item we need to update
      let record_index = temparrayprops.findIndex(
        (item) => item.id == _businessAddress.id
      );
      // -1 check to see if we found that object in social_media
      if (record_index != -1) {
        temparrayprops[record_index] = _businessAddress;
        // ...temparrayprops[index], //keeping existing values in object
        // _businessEmail: value, //here property can be "link"
      } else {
        _businessAddress.id = uuid();
        temparrayprops.push(_businessAddress);
      }
      setContact((prevcontact) => {
        prevcontact.contactAffiliationsArray[index].addressArray =
          temparrayprops;
        //    console.log("prevcontact",JSON.stringify(prevcontact));
        setBusinessAddress(initialbusinessAddressState);
        return prevcontact;
      });
    }
  };

  const validBusinessAddressForm = (index) => {
    try {
      let valid = true;
      return valid;
      valid = handleFieldBusinessAddressListRequiredValidation();
      Object.values(personalAddressErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldBusinessAddressListRequiredValidation = () => {
    let iserror = "";

    setBusinessAddressErrors({
      ...businessAddressErrors,
      addressLabel:
        businessAddress.addressLabel === ""
          ? (iserror = "Label required")
          : businessAddressErrors.addressLabel,
      addressLine1:
        businessAddress.addressLine1 === ""
          ? (iserror = "Address required")
          : businessAddressErrors.addressLine1,
    });

    return iserror.length === 0;
  };

  //add business phone
  const addBusinessPhone = (e, index) => {
    e.preventDefault();
    if (validBusinessPhoneForm(index)) {
      const _businessPhone = businessPhone[index];
      //copying data to temp variable so that we do not directly mutate original state
      let temparrayprops = [
        ...contact.contactAffiliationsArray[index].phoneArray,
      ];
      //findIndex to find location of item we need to update
      let record_index = temparrayprops.findIndex(
        (item) => item.id == _businessPhone.id
      );
      // -1 check to see if we found that object in social_media
      if (record_index != -1) {
        temparrayprops[record_index] = _businessPhone;
        // ...temparrayprops[index], //keeping existing values in object
        // _businessEmail: value, //here property can be "link"
      } else {
        _businessPhone.id = uuid();
        temparrayprops.push(_businessPhone);
      }
      setContact((prevcontact) => {
        prevcontact.contactAffiliationsArray[index].phoneArray = temparrayprops;
        //    console.log("prevcontact",JSON.stringify(prevcontact));
        setBusinessAddress(initialbusinessPhoneState);
        return prevcontact;
      });
    }
  };

  const validBusinessPhoneForm = (index) => {
    try {
      let valid = true;
      return valid;
      valid = handleFieldBusinessPhoneListRequiredValidation();
      Object.values(businessPhoneErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldBusinessPhoneListRequiredValidation = () => {
    let iserror = "";

    setBusinessPhoneErrors({
      ...businessPhoneErrors,
      type:
        businessPhone.type === ""
          ? (iserror = "Type required")
          : businessPhoneErrors.type,
      country:
        businessPhone.country === ""
          ? (iserror = "Country code required")
          : businessPhoneErrors.country,
      phone:
        businessPhone.phone === ""
          ? (iserror = "Phone required")
          : businessPhoneErrors.phone,
    });

    return iserror.length === 0;
  };
  const handlePersonalChange = (event) => {
    console.log("event", event);
    var name = event.target.name;
    var value = event.target.value;
    const path = name.split(".");
    const newpersonalcontact = { ...contact };
    let pointer = newpersonalcontact;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
      pointer.newvalue = value;
      //  console.log("el ", el);
    });

    setContact(newpersonalcontact);
    setisvaliddata(true);
  };
  const handlePersonalChange_autosave = (controlname, statusvalue) => {
    //console.log("event", event);
    var name = controlname.name;
    var value = statusvalue;
    var index = controlname.index;
    name = name.replaceAll(/[_]/g, ".");
    const path = name.split(".");
    if (path.length === 1) {
      const newpersonalcontact = { ...contact };
      let pointer = newpersonalcontact;
      var el = path[0];
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
      if (pointer !== undefined) pointer.status = value;
      setContact(newpersonalcontact);
      AutoSave(newpersonalcontact);
    } else if (path.length === 3) {
      var el = path[0];
      var el1 = path[1];
      if (index > -1) {
        //copying data to temp variable so that we do not directly mutate original state
        const temparrayprops = [...contact[el]];
        //findIndex to find location of item we need to update
        let sindex = temparrayprops.findIndex(
          (item) => item.id == controlname.itemid
        );
        // -1 check to see if we found that object in social_media
        if (sindex != -1) {
          temparrayprops[sindex][el1] = {
            ...temparrayprops[sindex][el1], //keeping existing values in object
            status: value, //here property can be "link"
          };
        }

        const newpersonalcontactarr = { ...contact, [el]: temparrayprops };
        setContact(newpersonalcontactarr);
        AutoSave(newpersonalcontactarr);
      }
    } else if (path.length === 5) {
      var el = path[0];
      var el1 = path[1];
      var el2 = path[2];
      if (index > -1) {
        //copying data to temp variable so that we do not directly mutate original state
        const temparrayprops = [...contact.contactAffiliationsArray];
        //findIndex to find location of item we need to update
        let sindex = -1;
        if (el1 === "emailArray") {
          sindex = temparrayprops[index].emailArray.findIndex(
            (item) => item.id == controlname.subitemid
          );
        }
        if (el1 === "addressArray") {
          sindex = temparrayprops[index].addressArray.findIndex(
            (item) => item.id == controlname.subitemid
          );
        }
        if (el1 === "phoneArray") {
          sindex = temparrayprops[index].phoneArray.findIndex(
            (item) => item.id == controlname.subitemid
          );
        }
        // -1 check to see if we found that object in social_media
        if (sindex != -1) {
          temparrayprops[index][el1][sindex][el2] = {
            ...temparrayprops[index][el1][sindex][el2], //keeping existing values in object
            status: value,
          };
        }
        const newpersonalcontactarr = { ...contact, [el]: temparrayprops };
        setContact(newpersonalcontactarr);
        AutoSave(newpersonalcontactarr);
      }
    }
  };

  const AutoSave = (contact_info) => {
    console.log("autosave contact ", contact_info);
    updateContactVerificationTaskByID_AutoSave(
      contact_info,
      ctechapp.currentUser,
      ctechapp.currentUser.id
    );
  };

  const handletickbutton = (
    e,
    controlname,
    tickbuttonname,
    crossbuttonname,
    tickverifyiconname,
    crossverifyiconname,
    tickaccepticonname,
    crossaccepticonname
  ) => {
    const divacceptcontrolname = "divaccept_" + controlname.name;
    const divverifycontrolname = "divverify_" + controlname.name;
    const inputcontrolname = controlname.name;
    const crosscontrol = document.querySelectorAll(`#${inputcontrolname}`);
    if (crosscontrol.length > 0) {
      crosscontrol[0].className = inputverifyclassName;
      crosscontrol[0].disabled = !inputverifyenabled;
      crosscontrol[0].isDisabled = !inputverifyenabled;
    }
    //save state

    handlePersonalChange_autosave(controlname, "verified");
  };
  const handlecrossbutton = (
    e,
    controlname,
    tickbuttonname,
    crossbuttonname,
    tickverifyiconname,
    crossverifyiconname,
    tickaccepticonname,
    crossaccepticonname
  ) => {
    if (
      controlname?.controltype === "isMain" ||
      controlname?.controltype === "dropdown" ||
      controlname?.controltype === "fullPhone"
    ) {
      if (controlname.status === "verified") {
        var value = "notverified";
        handlePersonalChange_autosave(controlname, value);
      } else {
        if (isSignUp) {
          setisLoadSignUp(true);
          return;
        } else if (isSignIn) {
          setisLoadSignIn(true);
          return;
        }
        var diveditcontrolname = "div_edit_" + controlname.name;
        var divviewcontrolname = "div_view_" + controlname.name;
        const diveditcontrol = document.querySelectorAll(
          `#${diveditcontrolname}`
        );
        const divviewcontrol = document.querySelectorAll(
          `#${divviewcontrolname}`
        );
        if (diveditcontrol != undefined) {
          diveditcontrol[0].className =
            diveditcontrol[0].className === "col-9" ? "col-9 d-none" : "col-9";
        }

        if (divviewcontrol != undefined) {
          divviewcontrol[0].className =
            divviewcontrol[0].className === "col-9" ? "col-9 d-none" : "col-9";
        }

        return;
      }
    }
    //iccon change fa  fa-times fa  fa-undo
    const crossiconcontrol = document.querySelectorAll(
      `#${crossverifyiconname}`
    );

    if (controlname.status === "verified") {
      var value = "notverified";
      console.log("verified action to notverifed action");
      //console.log("handlePersonalChange_autosave ", value);
      //setContact(newpersonalcontact);
      handlePersonalChange_autosave(controlname, value);
    } else {
      if (crossiconcontrol[0].className === "fa  fa-times") {
        if (isSignUp) {
          setisLoadSignUp(true);
        } else if (isSignIn) {
          setisLoadSignIn(true);
        } else {
          const inputcontrolname = controlname.name;

          const crosscontrol = document.querySelectorAll(
            `#${inputcontrolname}`
          );
          if (crosscontrol.length > 0) {
            crosscontrol[0].className = inputnotacceptclassName;
            crosscontrol[0].disabled = inputverifyenabled;
            crosscontrol[0].isDisabled = inputverifyenabled;
          }
        }
      }
    }
  };
  function VerifyUserActions(controlname) {
    const divbuttonname = "divverify_" + controlname.name;
    const tickbuttonname = "tick_" + controlname.name;
    const crossbuttonname = "cross_" + controlname.name;
    const tickverifyiconname = "tick_verifyicon_" + controlname.name;
    const crossverifyiconname = "cross_verifyicon_" + controlname.name;
    const tickaccepticonname = "tick_accepticon_" + controlname.name;
    const crossaccepticonname = "cross_accepticon_" + controlname.name;
    if (controlname.status === "verified") {
      return (
        <div
          name={divbuttonname}
          id={divbuttonname}
          className="d-flex gap-4 justify-content-end d-none"
        >
          <button
            onClick={(e) => {
              handletickbutton(
                e,
                controlname,
                tickbuttonname,
                crossbuttonname,
                tickverifyiconname,
                crossverifyiconname,
                tickaccepticonname,
                crossaccepticonname
              );
            }}
            name={tickbuttonname}
            id={tickbuttonname}
            className="cmbtn cmbtn-circle cmbtn-outline-success"
          >
            <i id={tickverifyiconname} className={tickbuttonicon}></i>
          </button>
          <button
            name={crossbuttonname}
            id={crossbuttonname}
            onClick={(e) => {
              handlecrossbutton(
                e,
                controlname,
                tickbuttonname,
                crossbuttonname,
                tickverifyiconname,
                crossverifyiconname,
                tickaccepticonname,
                crossaccepticonname
              );
            }}
            className="cmbtn cmbtn-circle cmbtn-outline-danger"
          >
            <i id={crossverifyiconname} className="fa  fa-times"></i>
          </button>
        </div>
      );
    } else {
      return (
        <div
          name={divbuttonname}
          id={divbuttonname}
          className="d-flex gap-4 justify-content-end"
        >
          <button
            onClick={(e) => {
              handletickbutton(
                e,
                controlname,
                tickbuttonname,
                crossbuttonname,
                tickverifyiconname,
                crossverifyiconname,
                tickaccepticonname,
                crossaccepticonname
              );
            }}
            name={tickbuttonname}
            id={tickbuttonname}
            className="cmbtn cmbtn-circle cmbtn-outline-success"
          >
            <i id={tickverifyiconname} className={tickbuttonicon}></i>
          </button>
          <button
            name={crossbuttonname}
            id={crossbuttonname}
            onClick={(e) => {
              handlecrossbutton(
                e,
                controlname,
                tickbuttonname,
                crossbuttonname,
                tickverifyiconname,
                crossverifyiconname,
                tickaccepticonname,
                crossaccepticonname
              );
            }}
            className="cmbtn cmbtn-circle cmbtn-outline-danger"
          >
            <i id={crossverifyiconname} className={crossbuttonicon}></i>
          </button>
        </div>
      );
    }
  }

  function AcceptUserActions(controlname) {
    const divbuttonname = "divaccept_" + controlname.name;
    const tickbuttonname = "tick_" + controlname.name;
    const crossbuttonname = "cross_" + controlname.name;
    const tickverifyiconname = "tick_verifyicon_" + controlname.name;
    const crossverifyiconname = "cross_verifyicon_" + controlname.name;
    const tickaccepticonname = "tick_accepticon_" + controlname.name;
    const crossaccepticonname = "cross_accepticon_" + controlname.name;

    if (controlname.status === "verified") {
      if (
        controlname?.controltype === "dropdown" ||
        controlname?.controltype === "isMain" ||
        controlname?.controltype === "fullPhone"
      ) {
        var diveditcontrolname = "div_edit_" + controlname.name;
        var divviewcontrolname = "div_view_" + controlname.name;
        const diveditcontrol = document.querySelectorAll(
          `#${diveditcontrolname}`
        );
        const divviewcontrol = document.querySelectorAll(
          `#${divviewcontrolname}`
        );
        if (diveditcontrol != undefined && diveditcontrol.length > 0) {
          diveditcontrol[0].className = "col-9 d-none";
        }

        if (divviewcontrol != undefined && divviewcontrol.length > 0) {
          divviewcontrol[0].className = "col-9";
        }
      }
      return (
        <div
          name={divbuttonname}
          id={divbuttonname}
          className="d-flex gap-4 justify-content-end"
        >
          <div class="dropdown w-10">
            <button
              class="cmbtn cmbtn-circle cmbtn-success"
              type="button"
              id="dropdownMenuButton1"
              // data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={(e) => {
                handlecrossbutton(
                  e,
                  controlname,
                  tickbuttonname,
                  crossbuttonname,
                  tickverifyiconname,
                  crossverifyiconname,
                  tickaccepticonname,
                  crossaccepticonname
                );
              }}
            >
              <i id={tickaccepticonname} className={tickbuttonicon}></i>
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div
          name={divbuttonname}
          id={divbuttonname}
          className="d-flex gap-4 justify-content-end d-none"
        >
          <div class="dropdown w-10">
            <button
              class="cmbtn cmbtn-circle cmbtn-success dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i id={tickaccepticonname} className={tickbuttonicon}></i>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <a class="dropdown-item" href="#">
                  <button
                    name={crossbuttonname}
                    id={crossbuttonname}
                    onClick={(e) => {
                      handlecrossbutton(
                        e,
                        controlname,
                        tickbuttonname,
                        crossbuttonname,
                        tickverifyiconname,
                        crossverifyiconname,
                        tickaccepticonname,
                        crossaccepticonname
                      );
                    }}
                    className="cmbtn cmbtn-circle cmbtn-outline-danger"
                  >
                    <i id={crossaccepticonname} className={crossbuttonicon}></i>
                  </button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      );
    }
  }

  return (
    <div>
      {isTokenVaild !== "false" && (
        <div className="container p-1">
          <div className="row">
            <h3 align="center">Contact verification</h3>
            {/* {loading && (
              <div>
                <Spinner animation="border" variant="info" />
              </div>
            )} */}
            <LoadingDialog
              isOpen={loading}
              // onClose={() => set(false)}
            />
          </div>

          {contact?.status !== "verification_completed" && (
            <div className="row">
              {/* <div className="mt-3"></div> */}
              <div className="accordion" id="accordionPanelscontactPer">
                <div className="accordion-item p-0">
                  <div
                    className="accordion-header p-2 fw-bold"
                    style={{
                      "background-color": "#f2f2f2",
                    }}
                  >
                    {" "}
                    Personal information
                  </div>
                  <div>
                    <div className="accordion-body">
                      <div className="row ">
                        {contact.firstname?.value && (
                          <div className="col-sm-12 col-md-6 col-lg-6 ">
                            <div className="form-group">
                              <label className="form-label ">
                                First name
                                <label className="text-danger">*</label>
                              </label>
                              <div className="row">
                                <div className="col-9">
                                  <input
                                    type="text"
                                    name="firstname"
                                    id="firstname"
                                    disabled={inputverifydisabled}
                                    className={inputverifyclassName}
                                    value={contact.firstname?.newvalue}
                                    onChange={(e) => handlePersonalChange(e)}
                                  />
                                </div>
                                <div className="col-3 p-0">
                                  <VerifyUserActions
                                    name="firstname"
                                    status={contact.firstname?.status}
                                  />
                                  <AcceptUserActions
                                    name="firstname"
                                    status={contact.firstname?.status}
                                  />
                                </div>
                                {contactErrors.firstname?.value.length > 0 && (
                                  <div className="text-danger">
                                    {contactErrors.firstname?.newvalue}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {contact.middlename?.value && (
                          <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group">
                              <label className="form-label">Middle name</label>

                              <div className="row">
                                <div className="col-9">
                                  <input
                                    type="text"
                                    name="middlename"
                                    id="middlename"
                                    disabled={inputverifydisabled}
                                    className={inputverifyclassName}
                                    value={contact.middlename?.newvalue}
                                    onChange={(e) => handlePersonalChange(e)}
                                  />
                                </div>
                                <div className="col-3 p-0">
                                  <VerifyUserActions
                                    name="middlename"
                                    status={contact.middlename?.status}
                                  />
                                  <AcceptUserActions
                                    name="middlename"
                                    status={contact.middlename?.status}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="row ">
                        {contact.lastname?.value && (
                          <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group">
                              <label className="form-label">
                                Last name
                                <label className="text-danger">*</label>
                              </label>
                              <div className="row">
                                <div className="col-9">
                                  <input
                                    type="text"
                                    name="lastname"
                                    id="lastname"
                                    disabled={inputverifydisabled}
                                    className={inputverifyclassName}
                                    value={contact.lastname?.newvalue}
                                    onChange={(e) => handlePersonalChange(e)}
                                  />
                                </div>
                                <div className="col-3 p-0">
                                  <VerifyUserActions
                                    name="lastname"
                                    status={contact.lastname?.status}
                                  />
                                  <AcceptUserActions
                                    name="lastname"
                                    status={contact.lastname?.status}
                                  />
                                </div>

                                {contactErrors.lastname?.newvalue.length >
                                  0 && (
                                  <div className="text-danger">
                                    {contactErrors.lastname?.newvalue}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="row ">
                        {contact.email?.value && (
                          <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group">
                              <label className="form-label">
                                Main email{" "}
                                <label className="text-danger ">*</label>
                              </label>
                              <div className="row">
                                <div className="col-9">
                                  <input
                                    type="text"
                                    name="email"
                                    id="email"
                                    disabled={inputverifydisabled}
                                    className={inputverifyclassName}
                                    value={contact.email?.newvalue}
                                    onChange={handlePersonalChange}
                                  />
                                </div>
                                <div className="col-3 p-0">
                                  <VerifyUserActions
                                    name="email"
                                    status={contact.email?.status}
                                  />
                                  <AcceptUserActions
                                    name="email"
                                    status={contact.email?.status}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {contact.phone?.value && (
                          <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group">
                              <label className="form-label">Main phone</label>
                              <div className="row">
                                <div className="col-9" id="div_view_phone">
                                  <PhoneInput
                                    className=""
                                    inputStyle={{ width: "200px", border: 0 }}
                                    country={"us"}
                                    value={contact.phone?.newvalue}
                                    name="phone"
                                    id="phone"
                                    onChange={(val) => {
                                      handlecontactPhoneChange(
                                        {
                                          target: {
                                            name: "phone",
                                            value: val,
                                          },
                                        },
                                        "phone"
                                      );
                                    }}
                                    disabled={true}
                                  />
                                </div>
                                <div
                                  className="col-9 d-none"
                                  id="div_edit_phone"
                                >
                                  <PhoneInput
                                    className=""
                                    inputStyle={{ width: "200px" }}
                                    country={"us"}
                                    value={contact.phone?.newvalue}
                                    name="phone"
                                    id="phone"
                                    onChange={(val) => {
                                      handlecontactPhoneChange(
                                        {
                                          target: {
                                            name: "phone",
                                            value: val,
                                          },
                                        },
                                        "phone"
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-3 p-0">
                                  <VerifyUserActions
                                    name="phone"
                                    status={contact.phone?.status}
                                    controltype="fullPhone"
                                  />
                                  <AcceptUserActions
                                    name="phone"
                                    status={contact.phone?.status}
                                    controltype="fullPhone"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* personal email info */}
                    {EmailArray.length > 0 && (
                      <div className="accordion-body">
                        {EmailArray.length > 0 &&
                          EmailArray.map((row, index) => (
                            <div>
                              <div>
                                <label className="form-label form-label">
                                  Other email
                                </label>
                                <div className="row">
                                  <div className="col-9">
                                    <input
                                      type="text"
                                      name={"emailArray_email_" + index}
                                      id={"emailArray_email_" + index}
                                      disabled={inputverifydisabled}
                                      className={inputverifyclassName}
                                      value={row.email?.newvalue}
                                      onChange={(e) =>
                                        handleChangePersonalEmail(
                                          e,
                                          row.id,
                                          index
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="col-3 p-0">
                                    <VerifyUserActions
                                      name={"emailArray_email_" + index}
                                      status={row?.email?.status}
                                      index={index}
                                      itemid={row.id}
                                    />
                                    <AcceptUserActions
                                      name={"emailArray_email_" + index}
                                      status={row?.email?.status}
                                      index={index}
                                      itemid={row.id}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}

                    {/* personal address info */}
                    {AddressArray.length > 0 && (
                      <div className="accordion-body">
                        <div className="row mt-3">
                          {AddressArray.length > 0 &&
                            AddressArray.map((row, index) => (
                              <div>
                                {row.addressLine1?.value && (
                                  <div className="row mb-3">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Address line 1
                                      </label>
                                      <div className="row ">
                                        <div className="col-9">
                                          <input
                                            type="text"
                                            name={
                                              "addressArray_addressLine1_" +
                                              index
                                            }
                                            id={
                                              "addressArray_addressLine1_" +
                                              index
                                            }
                                            disabled={inputverifydisabled}
                                            className={inputverifyclassName}
                                            value={row.addressLine1?.newvalue}
                                            onChange={(e) => {
                                              handleChangePersonalAddress(
                                                e,
                                                row.id,
                                                index
                                              );
                                            }}
                                          />
                                        </div>
                                        <div className="col-3 p-0">
                                          <VerifyUserActions
                                            name={
                                              "addressArray_addressLine1_" +
                                              index
                                            }
                                            index={index}
                                            itemid={row.id}
                                            status={row?.addressLine1?.status}
                                          />
                                          <AcceptUserActions
                                            name={
                                              "addressArray_addressLine1_" +
                                              index
                                            }
                                            index={index}
                                            itemid={row.id}
                                            status={row?.addressLine1?.status}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {row.addressLine2?.value && (
                                  <div className="row mb-3">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Address line 2
                                      </label>
                                      <div className="row ">
                                        <div className="col-9">
                                          <input
                                            type="text"
                                            name={
                                              "addressArray_addressLine2_" +
                                              index
                                            }
                                            id={
                                              "addressArray_addressLine2_" +
                                              index
                                            }
                                            disabled={inputverifydisabled}
                                            className={inputverifyclassName}
                                            value={row.addressLine2?.newvalue}
                                            onChange={(e) => {
                                              handleChangePersonalAddress(
                                                e,
                                                row.id,
                                                index
                                              );
                                            }}
                                          />
                                        </div>
                                        <div className="col-3 p-0">
                                          <VerifyUserActions
                                            name={
                                              "addressArray_addressLine2_" +
                                              index
                                            }
                                            index={index}
                                            itemid={row.id}
                                            status={row?.addressLine2?.status}
                                          />
                                          <AcceptUserActions
                                            name={
                                              "addressArray_addressLine2_" +
                                              index
                                            }
                                            index={index}
                                            itemid={row.id}
                                            status={row?.addressLine2?.status}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {row.city?.value && (
                                  <div className="row mb-3">
                                    <div className="form-group">
                                      <label className="form-label">City</label>
                                      <div className="row ">
                                        <div className="col-9">
                                          <input
                                            type="text"
                                            name={"addressArray_city_" + index}
                                            id={"addressArray_city_" + index}
                                            disabled={inputverifydisabled}
                                            className={inputverifyclassName}
                                            value={row.city?.newvalue}
                                            onChange={(e) => {
                                              handleChangePersonalAddress(
                                                e,
                                                row.id,
                                                index
                                              );
                                            }}
                                          />
                                        </div>
                                        <div className="col-3 p-0">
                                          <VerifyUserActions
                                            name={"addressArray_city_" + index}
                                            index={index}
                                            itemid={row.id}
                                            status={row?.city?.status}
                                          />
                                          <AcceptUserActions
                                            name={"addressArray_city_" + index}
                                            index={index}
                                            itemid={row.id}
                                            status={row?.city?.status}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {row.country?.value && (
                                  <div className="row mb-3">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Country
                                      </label>
                                      <div className="row ">
                                        <div className="col-9">
                                          <input
                                            type="text"
                                            name={
                                              "addressArray_country_" + index
                                            }
                                            id={"addressArray_country_" + index}
                                            disabled={inputverifydisabled}
                                            className={inputverifyclassName}
                                            value={row.country?.newvalue}
                                            onChange={(e) => {
                                              handleChangePersonalAddress(
                                                e,
                                                row.id,
                                                index
                                              );
                                            }}
                                          />
                                        </div>
                                        <div className="col-3 p-0">
                                          <VerifyUserActions
                                            name={
                                              "addressArray_country_" + index
                                            }
                                            index={index}
                                            itemid={row.id}
                                            status={row?.country?.status}
                                          />
                                          <AcceptUserActions
                                            name={
                                              "addressArray_country_" + index
                                            }
                                            index={index}
                                            itemid={row.id}
                                            status={row?.country?.status}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {row.state?.value && (
                                  <div className="row mb-3">
                                    <div className="form-group">
                                      <label className="form-label">
                                        State
                                      </label>
                                      <div className="row ">
                                        <div className="col-9">
                                          <input
                                            type="text"
                                            name={"addressArray_state_" + index}
                                            id={"addressArray_state_" + index}
                                            disabled={inputverifydisabled}
                                            className={inputverifyclassName}
                                            value={row.state?.newvalue}
                                            onChange={(e) => {
                                              handleChangePersonalAddress(
                                                e,
                                                row.id,
                                                index
                                              );
                                            }}
                                          />
                                        </div>
                                        <div className="col-3 p-0">
                                          <VerifyUserActions
                                            name={"addressArray_state_" + index}
                                            index={index}
                                            itemid={row.id}
                                            status={row?.state?.status}
                                          />
                                          <AcceptUserActions
                                            name={"addressArray_state_" + index}
                                            index={index}
                                            itemid={row.id}
                                            status={row?.state?.status}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {row.postalCode?.value && (
                                  <div className="row mb-3">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Postal code
                                      </label>
                                      <div className="row ">
                                        <div className="col-9">
                                          <input
                                            type="number"
                                            name={
                                              "addressArray_postalCode_" + index
                                            }
                                            id={
                                              "addressArray_postalCode_" + index
                                            }
                                            disabled={inputverifydisabled}
                                            className={inputverifyclassName}
                                            value={row.postalCode?.newvalue}
                                            onChange={(e) => {
                                              handleChangePersonalAddress(
                                                e,
                                                row.id,
                                                index
                                              );
                                            }}
                                          />
                                        </div>
                                        <div className="col-3 p-0">
                                          <VerifyUserActions
                                            name={
                                              "addressArray_postalCode_" + index
                                            }
                                            index={index}
                                            itemid={row.id}
                                            status={row?.postalCode?.status}
                                          />
                                          <AcceptUserActions
                                            name={
                                              "addressArray_postalCode_" + index
                                            }
                                            index={index}
                                            itemid={row.id}
                                            status={row?.postalCode?.status}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {/* <hr></hr> */}
                              </div>
                            ))}
                        </div>
                      </div>
                    )}

                    {/* personal phone info */}
                    {PhoneArray.length > 0 && (
                      <div className="accordion-body">
                        {PhoneArray.length > 0 &&
                          PhoneArray.map((row, index) => (
                            <div className="row mb-3">
                              <div className="form-group">
                                <label className="form-label">
                                  Other Phone{" "}
                                </label>
                                <div className="row ">
                                  <div
                                    className="col-9"
                                    id={
                                      "div_view_phoneArray_fullPhone_" + index
                                    }
                                  >
                                    <PhoneInput
                                      inputStyle={{
                                        width: "200px",
                                        border: 0,
                                      }}
                                      country={"us"}
                                      value={row.fullPhone?.newvalue}
                                      name={"phoneArray_fullPhone_" + index}
                                      id={"phoneArray_fullPhone_" + index}
                                      disabled={true}
                                      onChange={(e) => {
                                        handleChangePersonalPhone(
                                          e,
                                          row.id,
                                          index,
                                          "phoneArray_fullPhone_" + index,
                                          "fullPhone",
                                          "false"
                                        );
                                      }}
                                    />
                                  </div>
                                  <div
                                    className="col-9 d-none"
                                    id={
                                      "div_edit_phoneArray_fullPhone_" + index
                                    }
                                  >
                                    <PhoneInput
                                      inputStyle={{ width: "200px" }}
                                      country={"us"}
                                      value={row.fullPhone?.newvalue}
                                      name={"phoneArray_fullPhone_" + index}
                                      id={"phoneArray_fullPhone_" + index}
                                      onChange={(e) => {
                                        handleChangePersonalPhone(
                                          e,
                                          row.id,
                                          index,
                                          "phoneArray_fullPhone_" + index,
                                          "fullPhone",
                                          "false"
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="col-3 p-0">
                                    <VerifyUserActions
                                      name={"phoneArray_fullPhone_" + index}
                                      index={index}
                                      itemid={row.id}
                                      status={row?.fullPhone?.status}
                                      controltype="fullPhone"
                                    />
                                    <AcceptUserActions
                                      name={"phoneArray_fullPhone_" + index}
                                      index={index}
                                      itemid={row.id}
                                      status={row?.fullPhone?.status}
                                      controltype="fullPhone"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* business info     */}
              {contact.contactAffiliationsArray.length > 0 && (
                <div className="accordion" id="accordionPanelscontactAff">
                  <div className="accordion-item p-1">
                    <div
                      className="accordion-header p-2 fw-bold"
                      style={{
                        "background-color": "#f2f2f2",
                      }}
                    >
                      {" "}
                      Company information
                    </div>
                    {contact.contactAffiliationsArray.map(
                      (affiliation, index) => {
                        return (
                          <div className="accordion-body">
                            <div className="row">
                              <div className="row p-1">
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                  <div className="form-group">
                                    <label className="form-label fw-bold ">
                                      Company name
                                    </label>
                                    <div className="row">
                                      <div className="col-9">
                                        <input
                                          type="text"
                                          name={
                                            "contactAffiliationsArray_companyName_" +
                                            index
                                          }
                                          id={
                                            "contactAffiliationsArray_companyName_" +
                                            index
                                          }
                                          disabled={inputverifydisabled}
                                          className={
                                            inputverifyclassName + " fw-bold"
                                          }
                                          value={
                                            affiliation?.companyName?.newvalue +
                                            " (" +
                                            affiliation.sourceSystem.appname +
                                            ")"
                                          }
                                          onChange={(evnt) =>
                                            handleBusinessChange(
                                              evnt,
                                              affiliation.id,
                                              "companyName",
                                              index
                                            )
                                          }
                                        />
                                      </div>

                                      {/* <div className="col-3 p-0">
                                          <VerifyUserActions
                                            name={
                                              "contactAffiliationsArray_companyName_" +
                                              index
                                            }
                                            status={
                                              affiliation?.companyName?.status
                                            }
                                            index={index}
                                            itemid={affiliation.id}
                                          />
                                          <AcceptUserActions
                                            name={
                                              "contactAffiliationsArray_companyName_" +
                                              index
                                            }
                                            status={
                                              affiliation?.companyName?.status
                                            }
                                            index={index}
                                            itemid={affiliation.id}
                                          />
                                        </div>
                                        {businessContactErrors.companyName
                                          ?.newvalue.length > 0 && (
                                            <div className="text-danger">
                                              {
                                                businessContactErrors.companyName
                                                  ?.newvalue
                                              }
                                            </div>
                                          )} */}
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Description
                                      </label>
                                      <div className="row">
                                        <div className="col-9">
                                          <input
                                            type="text"
                                            name={
                                              "contactAffiliationsArray_description_" +
                                              index
                                            }
                                            id={
                                              "contactAffiliationsArray_description_" +
                                              index
                                            }
                                            disabled={inputverifydisabled}
                                            className={inputverifyclassName}
                                            value={
                                              affiliation?.description?.newvalue
                                            }
                                            onChange={(evnt) =>
                                              handleBusinessChange(
                                                evnt,
                                                affiliation.id,
                                                "description",
                                                index
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="col-3 p-0">
                                          <VerifyUserActions
                                            name={
                                              "contactAffiliationsArray_description_" +
                                              index
                                            }
                                            status={
                                              affiliation?.description?.status
                                            }
                                            index={index}
                                            itemid={affiliation.id}
                                          />
                                          <AcceptUserActions
                                            name={
                                              "contactAffiliationsArray_description_" +
                                              index
                                            }
                                            status={
                                              affiliation?.description?.status
                                            }
                                            index={index}
                                            itemid={affiliation.id}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                                {/* <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                      <label className="form-label ">
                                        Contact Owner
                                      </label>
                                      <div className="row">
                                        <div className="col-9">
                                          <input
                                            type="text"
                                            name={
                                              "contactAffiliationsArray_contactOwner_" +
                                              index
                                            }
                                            id={
                                              "contactAffiliationsArray_contactOwner_" +
                                              index
                                            }
                                            disabled={inputverifydisabled}
                                            className={inputverifyclassName}
                                            value={
                                              affiliation?.contactOwner
                                                ?.newvalue
                                            }
                                            onChange={(evnt) =>
                                              handleBusinessChange(
                                                evnt,
                                                affiliation.id,
                                                "contactOwner",
                                                index
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="col-3 p-0">
                                          <VerifyUserActions
                                            name={
                                              "contactAffiliationsArray_contactOwner_" +
                                              index
                                            }
                                            status={
                                              affiliation?.contactOwner?.status
                                            }
                                            index={index}
                                            itemid={affiliation.id}
                                          />
                                          <AcceptUserActions
                                            name={
                                              "contactAffiliationsArray_contactOwner_" +
                                              index
                                            }
                                            status={
                                              affiliation?.contactOwner?.status
                                            }
                                            index={index}
                                            itemid={affiliation.id}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                              </div>

                              {/* <div className="row p-1">
                                  <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Contact type{" "}
                                      </label>
                                      <div className="row">
                                        <div className="col-9">
                                          <input
                                            type="text"
                                            name={
                                              "contactAffiliationsArray_contactRole_" +
                                              index
                                            }
                                            id={
                                              "contactAffiliationsArray_contactRole_" +
                                              index
                                            }
                                            disabled={inputverifydisabled}
                                            className={inputverifyclassName}
                                            value={
                                              affiliation?.contactRole?.newvalue
                                            }
                                            onChange={(evnt) =>
                                              handleBusinessChange(
                                                evnt,
                                                affiliation.id,
                                                "contactRole",
                                                index
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="col-3 p-0">
                                          <VerifyUserActions
                                            name={
                                              "contactAffiliationsArray_contactRole_" +
                                              index
                                            }
                                            status={
                                              affiliation?.contactRole?.status
                                            }
                                            index={index}
                                            itemid={affiliation.id}
                                          />
                                          <AcceptUserActions
                                            name={
                                              "contactAffiliationsArray_contactRole_" +
                                              index
                                            }
                                            status={
                                              affiliation?.contactRole?.status
                                            }
                                            index={index}
                                            itemid={affiliation.id}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Contact record type
                                      </label>
                                      <div className="row">
                                        <div className="col-9">
                                          <input
                                            type="text"
                                            name={
                                              "contactAffiliationsArray_contactRecordType_" +
                                              index
                                            }
                                            id={
                                              "contactAffiliationsArray_contactRecordType_" +
                                              index
                                            }
                                            disabled={inputverifydisabled}
                                            className={inputverifyclassName}
                                            value={
                                              affiliation?.contactRecordType
                                                ?.newvalue
                                            }
                                            onChange={(evnt) =>
                                              handleBusinessChange(
                                                evnt,
                                                affiliation.id,
                                                "contactRecordType",
                                                index
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="col-3 p-0">
                                          <VerifyUserActions
                                            name={
                                              "contactAffiliationsArray_contactRecordType_" +
                                              index
                                            }
                                            status={
                                              affiliation?.contactRecordType
                                                ?.status
                                            }
                                            index={index}
                                            itemid={affiliation.id}
                                          />
                                          <AcceptUserActions
                                            name={
                                              "contactAffiliationsArray_contactRecordType_" +
                                              index
                                            }
                                            status={
                                              affiliation?.contactRecordType
                                                ?.status
                                            }
                                            index={index}
                                            itemid={affiliation.id}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                              {/* <div className="row p-1">
                                  <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Department{" "}
                                      </label>
                                      <div className="row">
                                        <div className="col-9">
                                          <input
                                            type="text"
                                            name={
                                              "contactAffiliationsArray_department_" +
                                              index
                                            }
                                            id={
                                              "contactAffiliationsArray_department_" +
                                              index
                                            }
                                            disabled={inputverifydisabled}
                                            className={inputverifyclassName}
                                            value={
                                              affiliation?.department?.newvalue
                                            }
                                            onChange={(evnt) =>
                                              handleBusinessChange(
                                                evnt,
                                                affiliation.id,
                                                "department",
                                                index
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="col-3 p-0">
                                          <VerifyUserActions
                                            name={
                                              "contactAffiliationsArray_department_" +
                                              index
                                            }
                                            status={
                                              affiliation?.department?.status
                                            }
                                            index={index}
                                            itemid={affiliation.id}
                                          />
                                          <AcceptUserActions
                                            name={
                                              "contactAffiliationsArray_department_" +
                                              index
                                            }
                                            status={
                                              affiliation?.department?.status
                                            }
                                            index={index}
                                            itemid={affiliation.id}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Report to
                                      </label>
                                      <div className="row">
                                        <div className="col-9">
                                          <input
                                            type="text"
                                            name={
                                              "contactAffiliationsArray_reportTo_" +
                                              index
                                            }
                                            id={
                                              "contactAffiliationsArray_reportTo_" +
                                              index
                                            }
                                            disabled={inputverifydisabled}
                                            className={inputverifyclassName}
                                            value={
                                              affiliation?.reportTo?.newvalue
                                            }
                                            onChange={(evnt) =>
                                              handleBusinessChange(
                                                evnt,
                                                affiliation.id,
                                                "reportTo",
                                                index
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="col-3 p-0">
                                          <VerifyUserActions
                                            name={
                                              "contactAffiliationsArray_reportTo_" +
                                              index
                                            }
                                            status={
                                              affiliation?.reportTo?.status
                                            }
                                            index={index}
                                            itemid={affiliation.id}
                                          />
                                          <AcceptUserActions
                                            name={
                                              "contactAffiliationsArray_reportTo_" +
                                              index
                                            }
                                            status={
                                              affiliation?.reportTo?.status
                                            }
                                            index={index}
                                            itemid={affiliation.id}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}

                              {/* <div className="row p-1">
                                  <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Claimed URL
                                      </label>
                                      <div className="row">
                                        <div className="col-9">
                                          <input
                                            type="text"
                                            name={
                                              "contactAffiliationsArray_claimedUrl_" +
                                              index
                                            }
                                            id={
                                              "contactAffiliationsArray_claimedUrl_" +
                                              index
                                            }
                                            disabled={inputverifydisabled}
                                            className={inputverifyclassName}
                                            value={
                                              affiliation?.claimedUrl?.newvalue
                                            }
                                            onChange={(evnt) =>
                                              handleBusinessChange(
                                                evnt,
                                                affiliation.id,
                                                "claimedUrl",
                                                index
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="col-3 p-0">
                                          <VerifyUserActions
                                            name={
                                              "contactAffiliationsArray_claimedUrl_" +
                                              index
                                            }
                                            status={
                                              affiliation?.claimedUrl?.status
                                            }
                                            index={index}
                                            itemid={affiliation.id}
                                          />
                                          <AcceptUserActions
                                            name={
                                              "contactAffiliationsArray_claimedUrl_" +
                                              index
                                            }
                                            status={
                                              affiliation?.claimedUrl?.status
                                            }
                                            index={index}
                                            itemid={affiliation.id}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                            </div>

                            {/* business email info */}
                            {affiliation.emailArray &&
                              affiliation.emailArray.length > 0 && (
                                <div className="row p-1 mt-3">
                                  {affiliation.emailArray?.map(
                                    (row, rowindex) => (
                                      <div>
                                        <div className="row mb-2">
                                          <div className="form-group">
                                            <label className="form-label">
                                              Email
                                            </label>
                                            <div className="row">
                                              <div className="col-9">
                                                <input
                                                  type="text"
                                                  name={
                                                    "contactAffiliationsArray_emailArray_email_" +
                                                    index +
                                                    "_" +
                                                    rowindex
                                                  }
                                                  id={
                                                    "contactAffiliationsArray_emailArray_email_" +
                                                    index +
                                                    "_" +
                                                    rowindex
                                                  }
                                                  disabled={inputverifydisabled}
                                                  className={
                                                    inputverifyclassName
                                                  }
                                                  value={row?.email?.newvalue}
                                                  onChange={(evnt) =>
                                                    handleChangeBusinessEmail(
                                                      evnt,
                                                      index,
                                                      rowindex,
                                                      "email"
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className="col-3 p-0">
                                                <VerifyUserActions
                                                  name={
                                                    "contactAffiliationsArray_emailArray_email_" +
                                                    index +
                                                    "_" +
                                                    rowindex
                                                  }
                                                  status={row?.email?.status}
                                                  index={index}
                                                  itemid={affiliation.id}
                                                  subitemid={row.id}
                                                />
                                                <AcceptUserActions
                                                  name={
                                                    "contactAffiliationsArray_emailArray_email_" +
                                                    index +
                                                    "_" +
                                                    rowindex
                                                  }
                                                  status={row?.email?.status}
                                                  index={index}
                                                  itemid={affiliation.id}
                                                  subitemid={row.id}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* <div className="row mb-2">
                                              <div className="form-group">
                                                <label className="form-label form-label">
                                                  Label
                                                </label>
                                                <div className="row">
                                                  <div className="col-9">
                                                    <input
                                                      type="text"
                                                      name={
                                                        "contactAffiliationsArray_emailArray_label_" +
                                                        index +
                                                        "_" +
                                                        rowindex
                                                      }
                                                      id={
                                                        "contactAffiliationsArray_emailArray_label_" +
                                                        index +
                                                        "_" +
                                                        rowindex
                                                      }
                                                      disabled={
                                                        inputverifydisabled
                                                      }
                                                      className={
                                                        inputverifyclassName
                                                      }
                                                      value={
                                                        row?.label?.newvalue
                                                      }
                                                      onChange={(evnt) => {
                                                        handleChangeBusinessEmail(
                                                          evnt,
                                                          index,
                                                          rowindex,
                                                          "label"
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="col-3 p-0">
                                                    <VerifyUserActions
                                                      name={
                                                        "contactAffiliationsArray_emailArray_label_" +
                                                        index +
                                                        "_" +
                                                        rowindex
                                                      }
                                                      status={
                                                        row?.label?.status
                                                      }
                                                      index={index}
                                                      itemid={affiliation.id}
                                                      subitemid={row.id}
                                                    />
                                                    <AcceptUserActions
                                                      name={
                                                        "contactAffiliationsArray_emailArray_label_" +
                                                        index +
                                                        "_" +
                                                        rowindex
                                                      }
                                                      status={
                                                        row?.label?.status
                                                      }
                                                      index={index}
                                                      itemid={affiliation.id}
                                                      subitemid={row.id}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div> */}
                                        {/* <div className="row mb-2">
                                          <div className="form-group">
                                            <label className="form-label form-label ms-1">
                                              Ismain?
                                            </label>
                                            <div className="row">
                                              <div
                                                className="col-9"
                                                id={
                                                  "div_view_contactAffiliationsArray_emailArray_isMain_" +
                                                  index +
                                                  "_" +
                                                  rowindex
                                                }
                                              >
                                                <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                                  <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                                  {row?.isMain?.newvalue ===
                                                    1
                                                    ? "Yes"
                                                    : "No"}{" "}
                                                </span>
                                              </div>
                                              <div
                                                className="col-9 d-none"
                                                id={
                                                  "div_edit_contactAffiliationsArray_emailArray_isMain_" +
                                                  index +
                                                  "_" +
                                                  rowindex
                                                }
                                              >
                                                <input
                                                  type="checkbox"
                                                  id="isMain"
                                                  name="isMain"
                                                  className="form-check-input ms-3"
                                                  defaultChecked={false}
                                                  onChange={(evnt) =>
                                                    handleChangeBusinessEmail(
                                                      evnt,
                                                      index,
                                                      rowindex,
                                                      "isMain"
                                                    )
                                                  }
                                                  checked={
                                                    row.isMain?.newvalue ===
                                                      1
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                <label className="form-label form-label ms-1">
                                                  Yes/No
                                                </label>
                                              </div>
                                              <div className="col-3 p-0">
                                                <VerifyUserActions
                                                  name={
                                                    "contactAffiliationsArray_emailArray_isMain_" +
                                                    index +
                                                    "_" +
                                                    rowindex
                                                  }
                                                  status={
                                                    row?.isMain?.status
                                                  }
                                                  index={index}
                                                  itemid={affiliation.id}
                                                  subitemid={row.id}
                                                  controltype={"isMain"}
                                                />
                                                <AcceptUserActions
                                                  name={
                                                    "contactAffiliationsArray_emailArray_isMain_" +
                                                    index +
                                                    "_" +
                                                    rowindex
                                                  }
                                                  status={
                                                    row?.isMain?.status
                                                  }
                                                  index={index}
                                                  itemid={affiliation.id}
                                                  subitemid={row.id}
                                                  controltype={"isMain"}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div> */}
                                      </div>
                                    )
                                  )}
                                </div>
                              )}

                            {/* business address info */}
                            {affiliation.addressArray &&
                              affiliation.addressArray.length > 0 && (
                                <div className="row p-1 mt-3">
                                  {affiliation.addressArray?.map(
                                    (row, rowindex) => (
                                      <diV>
                                        {/* <div className="row mb-3">
                                              <div className="form-group">
                                                <label className="form-label">
                                                  Address label
                                                </label>
                                                <div className="row">
                                                  <div
                                                    className="col-9"
                                                    id={
                                                      "div_view_contactAffiliationsArray_addressArray_addressLabel_" +
                                                      index +
                                                      "_" +
                                                      rowindex
                                                    }
                                                  >
                                                    <Select
                                                      type="text"
                                                      name={
                                                        "contactAffiliationsArray_addressArray_addressLabel_" +
                                                        index +
                                                        "_" +
                                                        rowindex
                                                      }
                                                      id={
                                                        "contactAffiliationsArray_addressArray_addressLabel_" +
                                                        index +
                                                        "_" +
                                                        rowindex
                                                      }
                                                      isDisabled={true}
                                                      className={
                                                        inputverifyclassName
                                                      }
                                                      styles={customstyles}
                                                      options={addressOptions}
                                                      value={addressOptions.filter(
                                                        (client) =>
                                                          client.value ===
                                                          row?.addressLabel
                                                            ?.newvalue
                                                      )}
                                                      onChange={(val) => {
                                                        handleChangeBusinessAddress(
                                                          {
                                                            target: {
                                                              name: "addressLabel",
                                                              value: val.value,
                                                              newvalue:
                                                                val.newvalue,
                                                            },
                                                          },
                                                          index,
                                                          rowindex,
                                                          "addressLabel"
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                  <div
                                                    className="col-9 d-none"
                                                    id={
                                                      "div_edit_contactAffiliationsArray_addressArray_addressLabel_" +
                                                      index +
                                                      "_" +
                                                      rowindex
                                                    }
                                                  >
                                                    <Select
                                                      type="text"
                                                      name={
                                                        "contactAffiliationsArray_addressArray_addressLabel_" +
                                                        index +
                                                        "_" +
                                                        rowindex
                                                      }
                                                      id={
                                                        "contactAffiliationsArray_addressArray_addressLabel_" +
                                                        index +
                                                        "_" +
                                                        rowindex
                                                      }
                                                      className={
                                                        inputverifyclassName
                                                      }
                                                      styles={customstyles}
                                                      options={addressOptions}
                                                      value={addressOptions.filter(
                                                        (client) =>
                                                          client.value ===
                                                          row?.addressLabel
                                                            ?.newvalue
                                                      )}
                                                      onChange={(val) => {
                                                        handleChangeBusinessAddress(
                                                          {
                                                            target: {
                                                              name: "addressLabel",
                                                              value: val.value,
                                                              newvalue:
                                                                val.newvalue,
                                                            },
                                                          },
                                                          index,
                                                          rowindex,
                                                          "addressLabel"
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="col-3 p-0">
                                                    <VerifyUserActions
                                                      name={
                                                        "contactAffiliationsArray_addressArray_addressLabel_" +
                                                        index +
                                                        "_" +
                                                        rowindex
                                                      }
                                                      status={
                                                        row?.addressLabel
                                                          ?.status
                                                      }
                                                      index={index}
                                                      itemid={affiliation.id}
                                                      subitemid={row.id}
                                                      controltype="dropdown"
                                                    />
                                                    <AcceptUserActions
                                                      name={
                                                        "contactAffiliationsArray_addressArray_addressLabel_" +
                                                        index +
                                                        "_" +
                                                        rowindex
                                                      }
                                                      status={
                                                        row?.addressLabel
                                                          ?.status
                                                      }
                                                      index={index}
                                                      itemid={affiliation.id}
                                                      subitemid={row.id}
                                                      controltype="dropdown"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div> */}
                                        {row?.addressLine1?.value && (
                                          <div className="row mb-3">
                                            <div className="form-group">
                                              <label className="form-label">
                                                Address line 1
                                              </label>
                                              <div className="row">
                                                <div className="col-9">
                                                  <input
                                                    type="text"
                                                    name={
                                                      "contactAffiliationsArray_addressArray_addressLine1_" +
                                                      index +
                                                      "_" +
                                                      rowindex
                                                    }
                                                    id={
                                                      "contactAffiliationsArray_addressArray_addressLine1_" +
                                                      index +
                                                      "_" +
                                                      rowindex
                                                    }
                                                    disabled={
                                                      inputverifydisabled
                                                    }
                                                    className={
                                                      inputverifyclassName
                                                    }
                                                    value={
                                                      row?.addressLine1
                                                        ?.newvalue
                                                    }
                                                    onChange={(evnt) =>
                                                      handleChangeBusinessAddress(
                                                        evnt,
                                                        index,
                                                        rowindex,
                                                        "addressLine1"
                                                      )
                                                    }
                                                  />
                                                </div>
                                                <div className="col-3 p-0">
                                                  <VerifyUserActions
                                                    name={
                                                      "contactAffiliationsArray_addressArray_addressLine1_" +
                                                      index +
                                                      "_" +
                                                      rowindex
                                                    }
                                                    status={
                                                      row?.addressLine1?.status
                                                    }
                                                    index={index}
                                                    itemid={affiliation.id}
                                                    subitemid={row.id}
                                                  />
                                                  <AcceptUserActions
                                                    name={
                                                      "contactAffiliationsArray_addressArray_addressLine1_" +
                                                      index +
                                                      "_" +
                                                      rowindex
                                                    }
                                                    status={
                                                      row?.addressLine1?.status
                                                    }
                                                    index={index}
                                                    itemid={affiliation.id}
                                                    subitemid={row.id}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        {row?.addressLine2?.value && (
                                          <div className="row mb-3">
                                            <div className="form-group">
                                              <label className="form-label">
                                                Address line 2
                                              </label>
                                              <div className="row">
                                                <div className="col-9">
                                                  <input
                                                    type="text"
                                                    name={
                                                      "contactAffiliationsArray_addressArray_addressLine2_" +
                                                      index +
                                                      "_" +
                                                      rowindex
                                                    }
                                                    id={
                                                      "contactAffiliationsArray_addressArray_addressLine2_" +
                                                      index +
                                                      "_" +
                                                      rowindex
                                                    }
                                                    disabled={
                                                      inputverifydisabled
                                                    }
                                                    className={
                                                      inputverifyclassName
                                                    }
                                                    value={
                                                      row?.addressLine2
                                                        ?.newvalue
                                                    }
                                                    onChange={(evnt) =>
                                                      handleChangeBusinessAddress(
                                                        evnt,
                                                        index,
                                                        rowindex,
                                                        "addressLine2"
                                                      )
                                                    }
                                                  />
                                                </div>
                                                <div className="col-3 p-0">
                                                  <VerifyUserActions
                                                    name={
                                                      "contactAffiliationsArray_addressArray_addressLine2_" +
                                                      index +
                                                      "_" +
                                                      rowindex
                                                    }
                                                    status={
                                                      row?.addressLine2?.status
                                                    }
                                                    index={index}
                                                    itemid={affiliation.id}
                                                    subitemid={row.id}
                                                  />
                                                  <AcceptUserActions
                                                    name={
                                                      "contactAffiliationsArray_addressArray_addressLine2_" +
                                                      index +
                                                      "_" +
                                                      rowindex
                                                    }
                                                    status={
                                                      row?.addressLine2?.status
                                                    }
                                                    index={index}
                                                    itemid={affiliation.id}
                                                    subitemid={row.id}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        {row?.city?.value && (
                                          <div className="row mb-3">
                                            <div className="form-group">
                                              <label className="form-label">
                                                City
                                              </label>
                                              <div className="row">
                                                <div className="col-9">
                                                  <input
                                                    type="text"
                                                    name={
                                                      "contactAffiliationsArray_addressArray_city_" +
                                                      index +
                                                      "_" +
                                                      rowindex
                                                    }
                                                    id={
                                                      "contactAffiliationsArray_addressArray_city_" +
                                                      index +
                                                      "_" +
                                                      rowindex
                                                    }
                                                    disabled={
                                                      inputverifydisabled
                                                    }
                                                    className={
                                                      inputverifyclassName
                                                    }
                                                    value={row?.city?.newvalue}
                                                    onChange={(evnt) =>
                                                      handleChangeBusinessAddress(
                                                        evnt,
                                                        index,
                                                        rowindex,
                                                        "city"
                                                      )
                                                    }
                                                  />
                                                </div>
                                                <div className="col-3 p-0">
                                                  <VerifyUserActions
                                                    name={
                                                      "contactAffiliationsArray_addressArray_city_" +
                                                      index +
                                                      "_" +
                                                      rowindex
                                                    }
                                                    status={row?.city?.status}
                                                    index={index}
                                                    itemid={affiliation.id}
                                                    subitemid={row.id}
                                                  />
                                                  <AcceptUserActions
                                                    name={
                                                      "contactAffiliationsArray_addressArray_city_" +
                                                      index +
                                                      "_" +
                                                      rowindex
                                                    }
                                                    status={row?.city?.status}
                                                    index={index}
                                                    itemid={affiliation.id}
                                                    subitemid={row.id}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        {/* <div className="row mb-3">
                                          <div className="form-group">
                                            <label className="form-label ms-1">
                                              Ismain?
                                            </label>
                                            <div className="row">
                                              <div
                                                className="col-9"
                                                id={
                                                  "div_view_contactAffiliationsArray_addressArray_isMain_" +
                                                  index +
                                                  "_" +
                                                  rowindex
                                                }
                                              >
                                                <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                                  <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                                  {row.isMain?.newvalue ===
                                                    1
                                                    ? "Yes"
                                                    : "No"}{" "}
                                                </span>
                                              </div>
                                              <div
                                                className="col-9 d-none"
                                                id={
                                                  "div_edit_contactAffiliationsArray_addressArray_isMain_" +
                                                  index +
                                                  "_" +
                                                  rowindex
                                                }
                                              >
                                                <input
                                                  type="checkbox"
                                                  id="isMain"
                                                  name="isMain"
                                                  className="form-check-input ms-3"
                                                  defaultChecked={false}
                                                  onChange={(evnt) =>
                                                    handleChangeBusinessAddress(
                                                      evnt,
                                                      index,
                                                      rowindex,
                                                      "isMain"
                                                    )
                                                  }
                                                  checked={
                                                    row.isMain?.newvalue ===
                                                      1
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                <label className="form-label form-label ms-1">
                                                  Yes/No
                                                </label>
                                              </div>
                                              <div className="col-3 p-0">
                                                <VerifyUserActions
                                                  name={
                                                    "contactAffiliationsArray_addressArray_isMain_" +
                                                    index +
                                                    "_" +
                                                    rowindex
                                                  }
                                                  status={
                                                    row?.isMain?.status
                                                  }
                                                  index={index}
                                                  itemid={affiliation.id}
                                                  subitemid={row.id}
                                                  controltype={"isMain"}
                                                />
                                                <AcceptUserActions
                                                  name={
                                                    "contactAffiliationsArray_addressArray_isMain_" +
                                                    index +
                                                    "_" +
                                                    rowindex
                                                  }
                                                  status={
                                                    row?.isMain?.status
                                                  }
                                                  index={index}
                                                  itemid={affiliation.id}
                                                  subitemid={row.id}
                                                  controltype={"isMain"}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div> */}
                                        {row?.country?.value && (
                                          <div className="row mb-3">
                                            <div className="form-group">
                                              <label className="form-label">
                                                Country
                                              </label>
                                              <div className="row">
                                                <div className="col-9">
                                                  <input
                                                    type="text"
                                                    name={
                                                      "contactAffiliationsArray_addressArray_country_" +
                                                      index +
                                                      "_" +
                                                      rowindex
                                                    }
                                                    id={
                                                      "contactAffiliationsArray_addressArray_country_" +
                                                      index +
                                                      "_" +
                                                      rowindex
                                                    }
                                                    disabled={
                                                      inputverifydisabled
                                                    }
                                                    className={
                                                      inputverifyclassName
                                                    }
                                                    value={
                                                      row?.country?.newvalue
                                                    }
                                                    onChange={(evnt) =>
                                                      handleChangeBusinessAddress(
                                                        evnt,
                                                        index,
                                                        rowindex,
                                                        "country"
                                                      )
                                                    }
                                                  />
                                                </div>
                                                <div className="col-3 p-0">
                                                  <VerifyUserActions
                                                    name={
                                                      "contactAffiliationsArray_addressArray_country_" +
                                                      index +
                                                      "_" +
                                                      rowindex
                                                    }
                                                    status={
                                                      row?.country?.status
                                                    }
                                                    index={index}
                                                    itemid={affiliation.id}
                                                    subitemid={row.id}
                                                  />
                                                  <AcceptUserActions
                                                    name={
                                                      "contactAffiliationsArray_addressArray_country_" +
                                                      index +
                                                      "_" +
                                                      rowindex
                                                    }
                                                    status={
                                                      row?.country?.status
                                                    }
                                                    index={index}
                                                    itemid={affiliation.id}
                                                    subitemid={row.id}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        {row?.state?.value && (
                                          <div className="row mb-3">
                                            <div className="form-group">
                                              <label className="form-label">
                                                State
                                              </label>
                                              <div className="row">
                                                <div className="col-9">
                                                  <input
                                                    type="text"
                                                    name={
                                                      "contactAffiliationsArray_addressArray_state_" +
                                                      index +
                                                      "_" +
                                                      rowindex
                                                    }
                                                    id={
                                                      "contactAffiliationsArray_addressArray_state_" +
                                                      index +
                                                      "_" +
                                                      rowindex
                                                    }
                                                    disabled={
                                                      inputverifydisabled
                                                    }
                                                    className={
                                                      inputverifyclassName
                                                    }
                                                    value={row?.state?.newvalue}
                                                    onChange={(evnt) =>
                                                      handleChangeBusinessAddress(
                                                        evnt,
                                                        index,
                                                        rowindex,
                                                        "state"
                                                      )
                                                    }
                                                  />
                                                </div>
                                                <div className="col-3 p-0">
                                                  <VerifyUserActions
                                                    name={
                                                      "contactAffiliationsArray_addressArray_state_" +
                                                      index +
                                                      "_" +
                                                      rowindex
                                                    }
                                                    status={row?.state?.status}
                                                    index={index}
                                                    itemid={affiliation.id}
                                                    subitemid={row.id}
                                                  />
                                                  <AcceptUserActions
                                                    name={
                                                      "contactAffiliationsArray_addressArray_state_" +
                                                      index +
                                                      "_" +
                                                      rowindex
                                                    }
                                                    status={row?.state?.status}
                                                    index={index}
                                                    itemid={affiliation.id}
                                                    subitemid={row.id}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        {row.postalCode?.value && (
                                          <div className="row mb-3">
                                            <div className="form-group">
                                              <label className="form-label">
                                                Postal code
                                              </label>
                                              <div className="row">
                                                <div className="col-9">
                                                  <input
                                                    type="number"
                                                    name={
                                                      "contactAffiliationsArray_addressArray_postalCode_" +
                                                      index +
                                                      "_" +
                                                      rowindex
                                                    }
                                                    id={
                                                      "contactAffiliationsArray_addressArray_postalCode_" +
                                                      index +
                                                      "_" +
                                                      rowindex
                                                    }
                                                    disabled={
                                                      inputverifydisabled
                                                    }
                                                    className={
                                                      inputverifyclassName
                                                    }
                                                    value={
                                                      row.postalCode?.newvalue
                                                    }
                                                    onChange={(evnt) =>
                                                      handleChangeBusinessAddress(
                                                        evnt,
                                                        index,
                                                        rowindex,
                                                        "postalCode"
                                                      )
                                                    }
                                                  />
                                                </div>
                                                <div className="col-3 p-0">
                                                  <VerifyUserActions
                                                    name={
                                                      "contactAffiliationsArray_addressArray_postalCode_" +
                                                      index +
                                                      "_" +
                                                      rowindex
                                                    }
                                                    status={
                                                      row?.postalCode?.status
                                                    }
                                                    index={index}
                                                    itemid={affiliation.id}
                                                    subitemid={row.id}
                                                  />
                                                  <AcceptUserActions
                                                    name={
                                                      "contactAffiliationsArray_addressArray_postalCode_" +
                                                      index +
                                                      "_" +
                                                      rowindex
                                                    }
                                                    status={
                                                      row?.postalCode?.status
                                                    }
                                                    index={index}
                                                    itemid={affiliation.id}
                                                    subitemid={row.id}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </diV>
                                    )
                                  )}
                                </div>
                              )}

                            {/* business phone info */}
                            {affiliation.phoneArray &&
                              affiliation.phoneArray.length > 0 && (
                                <div className="row p-1 mt-">
                                  {affiliation.phoneArray?.map(
                                    (row, rowindex) => (
                                      <div>
                                        {/* <div className="row mb-3">
                                              <div className="form-group">
                                                <label className="form-label">
                                                  Phone type
                                                </label>
                                                <div className="row">
                                                  <div
                                                    className="col-9"
                                                    id={
                                                      "div_view_contactAffiliationsArray_phoneArray_type_" +
                                                      index +
                                                      "_" +
                                                      rowindex
                                                    }
                                                  >
                                                    <Select
                                                      type="text"
                                                      name={
                                                        "contactAffiliationsArray_phoneArray_type_" +
                                                        index +
                                                        "_" +
                                                        rowindex
                                                      }
                                                      id={
                                                        "contactAffiliationsArray_phoneArray_type_" +
                                                        index +
                                                        "_" +
                                                        rowindex
                                                      }
                                                      isDisabled={true}
                                                      className={
                                                        inputverifyclassName
                                                      }
                                                      styles={customstyles}
                                                      value={phoneOptions.filter(
                                                        (client) =>
                                                          client.value ===
                                                          row?.type?.newvalue
                                                      )}
                                                      onChange={(val) => {
                                                        handleChangeBusinessPhone(
                                                          {
                                                            target: {
                                                              name: "type",
                                                              value: val.value,
                                                            },
                                                          },
                                                          index,
                                                          rowindex,
                                                          "type"
                                                        );
                                                      }}
                                                      options={phoneOptions}
                                                    />
                                                  </div>
                                                  <div
                                                    className="col-9 d-none"
                                                    id={
                                                      "div_edit_contactAffiliationsArray_phoneArray_type_" +
                                                      index +
                                                      "_" +
                                                      rowindex
                                                    }
                                                  >
                                                    <Select
                                                      type="text"
                                                      name={
                                                        "contactAffiliationsArray_phoneArray_type_" +
                                                        index +
                                                        "_" +
                                                        rowindex
                                                      }
                                                      id={
                                                        "contactAffiliationsArray_phoneArray_type_" +
                                                        index +
                                                        "_" +
                                                        rowindex
                                                      }
                                                      className={
                                                        inputverifyclassName
                                                      }
                                                      styles={customstyles}
                                                      value={phoneOptions.filter(
                                                        (client) =>
                                                          client.value ===
                                                          row?.type?.newvalue
                                                      )}
                                                      onChange={(val) => {
                                                        handleChangeBusinessPhone(
                                                          {
                                                            target: {
                                                              name: "type",
                                                              value: val.value,
                                                            },
                                                          },
                                                          index,
                                                          rowindex,
                                                          "type"
                                                        );
                                                      }}
                                                      options={phoneOptions}
                                                    />
                                                  </div>
                                                  <div className="col-3 p-0">
                                                    <VerifyUserActions
                                                      name={
                                                        "contactAffiliationsArray_phoneArray_type_" +
                                                        index +
                                                        "_" +
                                                        rowindex
                                                      }
                                                      status={row?.type?.status}
                                                      index={index}
                                                      itemid={affiliation.id}
                                                      subitemid={row.id}
                                                      controltype="dropdown"
                                                    />
                                                    <AcceptUserActions
                                                      name={
                                                        "contactAffiliationsArray_phoneArray_type_" +
                                                        index +
                                                        "_" +
                                                        rowindex
                                                      }
                                                      status={row?.type?.status}
                                                      index={index}
                                                      itemid={affiliation.id}
                                                      subitemid={row.id}
                                                      controltype="dropdown"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div> */}
                                        <div className="row mb-3">
                                          <div className="form-group">
                                            <label className="form-label">
                                              Phone number{" "}
                                            </label>
                                            <div className="row">
                                              <div
                                                className="col-9"
                                                id={
                                                  "div_view_contactAffiliationsArray_phoneArray_fullPhone_" +
                                                  index +
                                                  "_" +
                                                  rowindex
                                                }
                                              >
                                                <PhoneInput
                                                  inputStyle={{
                                                    width: "200px",
                                                    border: 0,
                                                  }}
                                                  country={"us"}
                                                  value={
                                                    row?.fullPhone?.newvalue
                                                  }
                                                  name={
                                                    "contactAffiliationsArray_phoneArray_fullPhone_" +
                                                    index +
                                                    "_" +
                                                    rowindex
                                                  }
                                                  id={
                                                    "contactAffiliationsArray_phoneArray_fullPhone_" +
                                                    index +
                                                    "_" +
                                                    rowindex
                                                  }
                                                  onChange={(evnt) =>
                                                    handleChangeBusinessPhone(
                                                      evnt,
                                                      index,
                                                      rowindex,
                                                      "fullPhone"
                                                    )
                                                  }
                                                  disabled={true}
                                                />
                                              </div>
                                              <div
                                                className="col-9 d-none"
                                                id={
                                                  "div_edit_contactAffiliationsArray_phoneArray_fullPhone_" +
                                                  index +
                                                  "_" +
                                                  rowindex
                                                }
                                              >
                                                <PhoneInput
                                                  inputStyle={{
                                                    width: "200px",
                                                  }}
                                                  country={"us"}
                                                  value={
                                                    row?.fullPhone?.newvalue
                                                  }
                                                  name={
                                                    "contactAffiliationsArray_phoneArray_fullPhone_" +
                                                    index +
                                                    "_" +
                                                    rowindex
                                                  }
                                                  id={
                                                    "contactAffiliationsArray_phoneArray_fullPhone_" +
                                                    index +
                                                    "_" +
                                                    rowindex
                                                  }
                                                  onChange={(evnt) =>
                                                    handleChangeBusinessPhone(
                                                      evnt,
                                                      index,
                                                      rowindex,
                                                      "fullPhone"
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className="col-3 p-0">
                                                <VerifyUserActions
                                                  name={
                                                    "contactAffiliationsArray_phoneArray_fullPhone_" +
                                                    index +
                                                    "_" +
                                                    rowindex
                                                  }
                                                  status={
                                                    row?.fullPhone?.status
                                                  }
                                                  index={index}
                                                  itemid={affiliation.id}
                                                  subitemid={row.id}
                                                  controltype="fullPhone"
                                                />
                                                <AcceptUserActions
                                                  name={
                                                    "contactAffiliationsArray_phoneArray_fullPhone_" +
                                                    index +
                                                    "_" +
                                                    rowindex
                                                  }
                                                  status={
                                                    row?.fullPhone?.status
                                                  }
                                                  index={index}
                                                  itemid={affiliation.id}
                                                  subitemid={row.id}
                                                  controltype="fullPhone"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* <div className="row mb-3">
                                              <div className="form-group">
                                                <label className="form-label">
                                                  Extn
                                                </label>
                                                <div className="row">
                                                  <div className="col-9">
                                                    <input
                                                      type="text"
                                                      name={
                                                        "contactAffiliationsArray_phoneArray_ext_" +
                                                        index +
                                                        "_" +
                                                        rowindex
                                                      }
                                                      id={
                                                        "contactAffiliationsArray_phoneArray_ext_" +
                                                        index +
                                                        "_" +
                                                        rowindex
                                                      }
                                                      disabled={
                                                        inputverifydisabled
                                                      }
                                                      className={
                                                        inputverifyclassName
                                                      }
                                                      value={row?.ext?.newvalue}
                                                      onChange={(evnt) =>
                                                        handleChangeBusinessPhone(
                                                          evnt,
                                                          index,
                                                          rowindex,
                                                          "ext"
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="col-3 p-0">
                                                    <VerifyUserActions
                                                      name={
                                                        "contactAffiliationsArray_phoneArray_ext_" +
                                                        index +
                                                        "_" +
                                                        rowindex
                                                      }
                                                      status={row?.ext?.status}
                                                      index={index}
                                                      itemid={affiliation.id}
                                                      subitemid={row.id}
                                                    />
                                                    <AcceptUserActions
                                                      name={
                                                        "contactAffiliationsArray_phoneArray_ext_" +
                                                        index +
                                                        "_" +
                                                        rowindex
                                                      }
                                                      status={row?.ext?.status}
                                                      index={index}
                                                      itemid={affiliation.id}
                                                      subitemid={row.id}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div> */}
                                        {/* <div className="row mb-3">
                                          <div className="form-group">
                                            <label className="form-label ms-1">
                                              Ismain?
                                            </label>
                                            <div className="row">
                                              <div
                                                className="col-9"
                                                id={
                                                  "div_view_contactAffiliationsArray_phoneArray_isMain_" +
                                                  index +
                                                  "_" +
                                                  rowindex
                                                }
                                              >
                                                <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                                  <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                                  {row.isMain?.newvalue === 1
                                                    ? "Yes"
                                                    : "No"}{" "}
                                                </span>
                                              </div>
                                              <div
                                                className="col-9 d-none"
                                                id={
                                                  "div_edit_contactAffiliationsArray_phoneArray_isMain_" +
                                                  index +
                                                  "_" +
                                                  rowindex
                                                }
                                              >
                                                <input
                                                  type="checkbox"
                                                  id="isMain"
                                                  name="isMain"
                                                  className="form-check-input ms-3"
                                                  defaultChecked={false}
                                                  onChange={(evnt) =>
                                                    handleChangeBusinessPhone(
                                                      evnt,
                                                      index,
                                                      rowindex,
                                                      "isMain"
                                                    )
                                                  }
                                                  checked={
                                                    row.isMain?.newvalue === 1
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                <label className="form-label form-label ms-1">
                                                  Yes/No
                                                </label>
                                              </div>
                                              <div className="col-3 p-0">
                                                <VerifyUserActions
                                                  name={
                                                    "contactAffiliationsArray_phoneArray_isMain_" +
                                                    index +
                                                    "_" +
                                                    rowindex
                                                  }
                                                  status={row?.isMain?.status}
                                                  index={index}
                                                  itemid={affiliation.id}
                                                  subitemid={row.id}
                                                  controltype={"isMain"}
                                                />
                                                <AcceptUserActions
                                                  name={
                                                    "contactAffiliationsArray_phoneArray_isMain_" +
                                                    index +
                                                    "_" +
                                                    rowindex
                                                  }
                                                  status={row?.isMain?.status}
                                                  index={index}
                                                  itemid={affiliation.id}
                                                  subitemid={row.id}
                                                  controltype={"isMain"}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div> */}
                                      </div>
                                    )
                                  )}
                                </div>
                              )}
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              )}
              <div className="d-flex justify-content-between mt-2 gap-2">
                <Button className=" btn btn-success " onClick={handleCancel}>
                  Close
                </Button>
                <Button className="btn btn-success" onClick={submitHandler}>
                  Submit
                </Button>
              </div>
            </div>
          )}
          {contact.status === "verification_completed" && (
            <div>
              <div className="text-danger text-center">
                {
                  appConstants.contact.confirmationmsg
                    .contactverification_alreadyverified
                }
              </div>
              <div className="text-center mt-2">
                <Button className="btn btn-success" onClick={handleCancel}>
                  Close
                </Button>
              </div>
            </div>
          )}

          <div>
            {isSuccess && (
              <SweetAlert
                success
                style={{ color: "black" }}
                title=""
                onConfirm={handleSucessSweetAlert1}
              >
                <div>
                  {
                    appConstants.contact.confirmationmsg
                      .contactverification_thanks
                  }
                  {contact?.activity_history[0]?.activity_by_name}{" "}
                </div>
              </SweetAlert>
            )}
            {isLoadSignIn && (
              <SweetAlert
                style={{ color: "black" }}
                title="Sign in"
                confirmBtnText="Continue"
                cancelBtnText="Cancel"
                showCancel
                onConfirm={handleSignIn}
                onCancel={handleNoSweetAlert}
              >
                <div>
                  <img src={applogo} className="px-5 mt-5" alt="Logo" />
                  <h2>Manage Profile</h2>
                  <h5>
                    {" "}
                    {
                      appConstants.contact.confirmationmsg
                        .contactverification_profile
                    }
                  </h5>
                </div>
              </SweetAlert>
            )}
            {isLoadSignUp && (
              <SweetAlert
                style={{ color: "black" }}
                title="Sign Up"
                cancelBtnText="Cancel"
                confirmBtnText="Continue"
                showCancel
                onConfirm={handleSignUp}
                onCancel={handleNoSweetAlert}
              >
                <div>
                  <img src={applogo} className="px-5 mt-5" alt="Logo" />
                  <h2>
                    <b>Manage Profile</b>
                  </h2>
                  <h5>
                    {
                      appConstants.contact.confirmationmsg
                        .contactverification_profile
                    }
                  </h5>
                </div>
              </SweetAlert>
            )}
          </div>
        </div>
      )}{" "}
      {validateMsg !== "" && (
        <div className="text-center fw-bold ">{validateMsg}</div>
      )}
      {isTokenVaild === "false" && validateMsg === "" && (
        <div>
          <div className="text-center text-danger">
            {
              appConstants.contact.confirmationmsg
                .contactverification_linkexpired
            }
          </div>
          <div className="text-center text-success ">
            {appConstants.contact.confirmationmsg.contactverification_linksend}
          </div>
          <div className="text-center mt-3">
            {" "}
            <Button className="btn btn-success" onClick={handleSendLink}>
              Send Link
            </Button>{" "}
            <Button className="btn btn-success" onClick={handleCancel}>
              Close
            </Button>{" "}
          </div>
          <div>
            {/* {isTokenLinkSend && (
              <SweetAlert
                success
                style={{ color: "black" }}
                title="New link has been sent successfully..."
                onConfirm={handleSucessSweetAlert}
              ></SweetAlert>
            )}
            {isInvaildToken && (
              <SweetAlert
                danger
                style={{ color: "black" }}
                title="We are unable to generate new link. due to incorrect signup URL"
                onConfirm={handleSucessSweetAlert}
              ></SweetAlert>
            )} */}
          </div>
        </div>
      )}
    </div>
  );
}
export default Contactverification;
