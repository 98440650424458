import { useParams } from "react-router";
import * as Realm from "realm-web";
import { appConstants } from "../../constants/app_constants";
export default function VerificationMsg() {
  const { requestcontact, affname } = useParams();
  console.log("test");
  var message =
    appConstants.contact.confirmationmsg.verificationmsg + requestcontact;
  console.log(message);
  return <div className="text-center bg-green">{message}</div>;
}
