import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ButtonGroup, Button } from "react-bootstrap";
import * as Realm from "realm-web";
import { useRealmApp } from "../../providers/RealmApp";
import { AppSettings } from "../../config/app-settings.js";
import { sendResetEmailByID } from "../../services/users_services";
import { isEmail } from "validator";
import ClipLoader from "react-spinners/ClipLoader";
import SweetAlert from "react-bootstrap-sweetalert";
import ApplicationHeadername from "../../components/ApplicationHeadername";

function ForgotPassword() {
  //context variables

  const context = useContext(AppSettings);
  const copyrightcontent = "Sample Company  All Right Reserved 2022";
  //state variables
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    phone: "",
    otp: "",
  });
  const [errorvalues, setErrorValues] = React.useState({
    email: "",
    password: "",
    remoteerror: "",
    phone: "",
    otp: "",
  });
  const [isvaliddata, setisvaliddata] = useState(false);

  const [mfaflow, setmfaflow] = useState("1");
  let [color, setColor] = useState("#ffffff");
  let [mfaflow1loading, setmfaflow1Loading] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [isSuccess1, setisSuccess1] = useState(false);

  useEffect(() => {
    if (context != null) {
      context.handleSetAppSidebarNone(true);
      context.handleSetAppHeaderNone(true);
      context.handleSetAppContentClass("p-0");
    }
  }, [context]);

  //save functions
  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    if (errorvalues.remoteerror !== "")
      setErrorValues({ ...errorvalues, remoteerror: "" });
    if (errorvalues.otp !== "") setErrorValues({ ...errorvalues, otp: "" });
    setValues({ ...values, [name]: value });
    handleFieldRequiredValidation(values);
    setisvaliddata(true);
    // console.log(roles);
  };

  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errorvalues).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      // console.log(valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldRequiredValidation = () => {
    let isemailerror = "";
    setErrorValues({
      ...errorvalues,
      email: !isEmail(values.email)
        ? (isemailerror = "email invalid")
        : values.email === ""
          ? "email required"
          : "",
    });
    // console.log(iserror);
    return isemailerror.length === 0;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    enableloading();
    if (validateForm()) {
      handleforgotpassword();
    } else {
      disableloading();
    }
  };

  const enableloading = async () => {
    setmfaflow1Loading(true);
  };
  const disableloading = async () => {
    setmfaflow1Loading(false);
  };
  const handleforgotpassword = async () => {
    try {
      // Get the API key from the local environment
      const apiKey = process.env.REACT_APP_APIKEY;
      if (!apiKey) {
        throw new Error("Could  not find a Realm Server API Key.");
      }
      // Create an api key credential
      const credentials = Realm.Credentials.apiKey(apiKey);
      const localapp = new Realm.App(process.env.REACT_APP_REALM_APP_ID);
      localapp.logIn(credentials).then(() => {
        sendResetEmailByID(
          values.email,
          String(window.location.host),
          localapp.currentUser,
          localapp.currentUser.id
        ).then((response) => {
          if (response !== undefined) {
            if (response === true) {
              disableloading();
              //let result = JSON.parse(response);
              if (
                localapp.currentUser !== null &&
                localapp.currentUser !== undefined
              ) {
                localapp.removeUser(localapp.currentUser);
              }
              setisSuccess(true);
            } else {
              disableloading();
              setisSuccess1(true);
              if (
                localapp.currentUser !== null &&
                localapp.currentUser !== undefined
              ) {
                localapp.removeUser(localapp.currentUser);
              }
            }
          }
        });
      });
      if (localapp.currentUser !== null && localapp.currentUser !== undefined) {
        await localapp.removeUser(localapp.currentUser);
      }
      disableloading();
    } catch (err) {
      console.log("err : " + err);
      setmfaflow("1");
      handleAuthenticationError(err);
      disableloading();
    }
  };

  function handleAuthenticationError(err) {
    const { status, message } = parseAuthenticationError(err);
    const errorType = message || status;
    switch (errorType) {
      case "invalid username":
        setErrorValues({
          ...errorvalues,
          remoteerror: "invalid email address",
        });
        break;
      default:
        setErrorValues({
          ...errorvalues,
          remoteerror: "invalid email address",
        });
        break;
    }
  }

  function parseAuthenticationError(err) {
    const parts = err.message.split(":");
    const reason = parts[parts.length - 1].trimStart();
    if (!reason) return { status: "", message: "" };
    const reasonRegex = /(?<message>.+)\s\(status (?<status>[0-9][0-9][0-9])/;
    const match = reason.match(reasonRegex);
    const { status, message } = match?.groups ?? {};
    return { status, message };
  }
  //message functions
  const handleSweetAlert = () => {
    setisSuccess(false);
    setisSuccess1(false);
  };
  function gotologin() {
    window.location.href = "/";
  }

  return (
    <div className="divcontainer">
      <ApplicationHeadername />
      <p className="txtalign p-2">
        <h5>Forgot Password</h5>
      </p>
      <div className="rowctr">
        <div>
          <input
            type="text"
            className="form-control "
            placeholder="Enter Email Address"
            id="email"
            name="email"
            value={values.email}
            onChange={(e) => {
              handleChange(e);
            }}
          />

          <div>
            {errorvalues.email.length > 0 && (
              <label
                className="form-check-label text-danger"
                htmlFor="emailAddress"
              >
                {/* {errorvalues.email} */}
              </label>
            )}
          </div>
          <div className="mt-2">
            <a href="#" onClick={() => gotologin()} className="columnctr">
              Back to Login
            </a>
          </div>
          <div className="text-center mt-2">
            <div style={{ flexDirection: "column" }}>
              <Button
                onClick={handleSubmit}
                className="btn btn-success "
                disabled={!isvaliddata}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>

        <br className="bg-gray-600 opacity-2" />
        {errorvalues.remoteerror && (
          <h4 className="form-check-label text-red" htmlFor="remoteerror">
            {errorvalues.remoteerror}
          </h4>
        )}
      </div>
      <ClipLoader
        color={color}
        loading={mfaflow1loading}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      {/* <br className="bg-gray-600 opacity-2" />
        <br className="bg-gray-600 opacity-2" />
        <hr className="bg-gray-600 opacity-2" /> */}
      {/* <div className="text-gray-600 text-center text-gray-500-darker mb-0">
          &copy; {copyrightcontent}
        </div> */}

      <div>
        {isSuccess && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title="Password reset link sent successfully.."
            onConfirm={handleSweetAlert}
          ></SweetAlert>
        )}
        {isSuccess1 && (
          <SweetAlert
            danger
            style={{ color: "black" }}
            title="Invaild email address"
            onConfirm={handleSweetAlert}
          ></SweetAlert>
        )}
      </div>
    </div>
  );
}

export default ForgotPassword;
