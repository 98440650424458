//services for getContactDetails
export const getContactDetails = async (Ctechapp) => {
  try {
    const data = await Ctechapp.functions.getContactDetails();
    if (data !== undefined) {
      // console.log("getContactDetails", JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

//services for getContactDetailByID
export const getContactDetailByID = async (
  _id,
  usertype,
  Ctechapp,
  loggedin_by
) => {
  // console.log("getContactDetailByID", _id, Ctechapp);
  try {
    const data = await Ctechapp.functions.getContactDetailByID(
      _id,
      usertype,
      loggedin_by
    );
    if (data !== undefined) {
      //    console.log("getContactDetailByID", JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error;
  }
};
//services for getContactDetailSettingsByID
export const getContactDetailSettingsByID = async (
  _id,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.getContactDetailSettingsByID(
      _id,
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error;
  }
};
//services for getContactDetailSettingsByID
export const getContactDetailAffiliationLookupByID = async (
  _id,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.getContactDetailAffiliationLookupByID(
      _id,
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error;
  }
};
//services for insertContactDetail
export const insertContactDetail = async (
  _p_contactID,
  _p_userID,
  salutation,
  nickName,
  gender,
  dateOfBirth,
  contactOwner,
  description,
  contactRole,
  isActive,
  Ctechapp,
  loggedinby
) => {
  try {
    const userdata = {
      salutation: salutation,
      nickName: nickName,
      gender: gender,
      dateOfBirth: dateOfBirth,
      contactOwner: contactOwner,
      description: description,
      contactRole: contactRole,
      isActive: isActive,
    };
    const data = await Ctechapp.functions.insertContactDetail(
      JSON.stringify(userdata),
      loggedinby
    );
    console.log("status: success");
    console.log("insertContactDetail", data);

    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

//services for updateContactDetailByID
export const updateContactDetailByID = async (
  useridvalue,
  userid,
  authId,
  firstname,
  lastname,
  middlename,
  email,
  phone,
  gender,
  salutation,
  nickName,
  dateOfBirth,
  contactOwner,
  description,
  contactRole,
  userRole,
  PhoneArray,
  EmailArray,
  AddressArray,
  contactAffiliationsArray,
  inactive_contactAffiliationsArray,
  ctechapp,
  loggedinby
) => {
  try {
    console.log("ctechapp", ctechapp);

    const userdetails = {
      _id: useridvalue,
      userid: userid,
      authId: authId,
      firstname: firstname,
      lastname: lastname,
      middlename: middlename,
      email: email,
      phone: phone,
      role: userRole,
    };

    const userdata = {
      _id: useridvalue,
      userdetails: userdetails,
      gender: gender,
      salutation: salutation,
      nickName: nickName,
      dateOfBirth: dateOfBirth,
      contactOwner: contactOwner,
      description: description,
      contactRole: contactRole,
      phoneArray: PhoneArray,
      emailArray: EmailArray,
      addressArray: AddressArray,
      contactAffiliationsArray: contactAffiliationsArray,
      inactive_contactAffiliationsArray: inactive_contactAffiliationsArray,
    };
    const data = await ctechapp.functions.updateContactDetailByID(
      JSON.stringify(userdata),
      loggedinby
    );
    console.log("status: success");
    console.log("updateContactDetailByID", data);
    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
//services for updateContactDetailByID
export const updateContactDetailSettingsByID = async (
  useridvalue,
  settingsArray,
  ctechapp,
  loggedinby
) => {
  try {
    console.log("ctechapp", ctechapp);

    const data = await ctechapp.functions.updateContactDetailSettingsByID(
      JSON.stringify(settingsArray),
      loggedinby
    );
    console.log("status: success");
    console.log("updateContactDetailSettingsByID", data);
    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
//services for deleteContactDetailByID
export const deleteContactDetailByID = async (_id, Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.deleteContactDetailByID(
      _id,
      loggedin_by
    );
    if (data !== undefined) {
      console.log("deleteContactDetailByID", JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
//getGeneralSettingByAuthID
export const getGeneralSettingByAuthID = async (Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.getGeneralSettingByAuthID(
      loggedin_by
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

//updateGeneralSettingByID
export const updateGeneralSettingByID = async (arg, Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.updateGeneralSettingByID(
      arg,
      loggedin_by
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
