import React, { useContext, useState, useEffect } from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import * as Realm from "realm-web";
import { AppSettings } from "../../config/app-settings.js";
import { getUserEmailByToken } from "../../services/users_services";
import ClipLoader from "react-spinners/ClipLoader";
import SweetAlert from "react-bootstrap-sweetalert";
import { useParams } from "react-router";
import ApplicationHeadername from "../../components/ApplicationHeadername";
import { useHistory } from "react-router-dom";

export const REALM_APP_ID = process.env.REACT_APP_REALM_APP_ID;

function ResetPassword() {
  //context variables

  const context = useContext(AppSettings);
  let navigate = useHistory();
  //state variables
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    confirmpassword: "",
    token: "",
  });
  const [error, setError] = React.useState({
    password: "",
    confirmpassword: "",
    remoteerror: "",
  });
  const [isvaliddata, setisvaliddata] = useState(false);
  const [resetflow, setresetflow] = useState("1");
  let [color, setColor] = useState("#ffffff");
  let [resetflow1loading, setresetflow1Loading] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [isSuccess1, setisSuccess1] = useState(false);
  const { token } = useParams();

  useEffect(() => {
    if (context != null) {
      context.handleSetAppSidebarNone(true);
      context.handleSetAppHeaderNone(true);
      context.handleSetAppContentClass("p-0");
    }
  }, [context]);

  //save functions
  // const handleChange = (event) => {
  //   event.preventDefault();
  //   const { name, value } = event.target;
  //   if (error.remoteerror !== "")
  //     setError({ ...error, remoteerror: "" });
  //   if (error.otp !== "") setError({ ...error, otp: "" });
  //   setValues({ ...values, [name]: value });
  //   setisvaliddata(true);
  //   // console.log(roles);
  // };

  const handleChange = (event) => {
    const path = event.target.name.split(".");
    const finalProp = path.pop();
    const newpassword = { ...values };
    let pointer = newpassword;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setValues(newpassword);
    handleFieldValidation(event);
    setisvaliddata(true);
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "password":
        localerror =
          value.length < 8 ? "password must be at least 8 characters" : "";
        break;
      case "confirmpassword":
        localerror =
          value != values.password
            ? "Password & Confirm Password are mismatched"
            : "";
        break;
      default:
        break;
    }
    setError({ ...error, [name]: localerror });
    setisvaliddata(true);
  };

  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      // if (values.password !== values.confirmpassword) {
      //   setError({
      //     ...error,
      //     remoteerror: "password and confirm password are mismatched",
      //   });
      //   valid = false;
      // }
      Object.values(error).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      // console.log(valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldRequiredValidation = () => {
    let ispassowrderror = "";
    setError({
      ...error,
      password:
        values.password === ""
          ? (ispassowrderror = "password required")
          : error.password,
      confirmpassword:
        values.confirmpassword === ""
          ? (ispassowrderror = "Confirm Password required")
          : values.confirmpassword !== values.password
            ? (ispassowrderror = "Password & confirm password are mismatched")
            : error.confirmpassword,
    });
    // console.log(iserror);
    return ispassowrderror.length === 0;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    enableloading();
    if (validateForm()) {
      handleresetpassword();
    } else {
      disableloading();
    }
  };

  const enableloading = async () => {
    setresetflow1Loading(true);
  };
  const disableloading = async () => {
    setresetflow1Loading(false);
  };
  const handleresetpassword = async () => {
    try {
      // Get the API key from the local environment
      const apiKey = process.env.REACT_APP_APIKEY;
      if (!apiKey) {
        throw new Error("Could  not find a Realm Server API Key.");
      }
      // Create an api key credential
      const credentials = Realm.Credentials.apiKey(apiKey);
      //   try {
      //     const serviceUser = await app.logIn(credentials);

      //     setUser(serviceUser);
      //   } catch (err) {}

      const localapp = new Realm.App(REALM_APP_ID);
      localapp.logIn(credentials).then(() => {
        getUserEmailByToken(token, localapp.currentUser).then((response) => {
          if (response !== undefined) {
            // console.log(JSON.stringify(response) );
            let result = JSON.parse(response);
            //console.log(result[0].email);
            if (result.length > 0) {
              localapp.emailPasswordAuth
                .callResetPasswordFunction(result[0].email, values.password)
                .then(() => {
                  //console.log(result[0].email);
                  setisSuccess(true);
                });
              if (
                localapp.currentUser !== null &&
                localapp.currentUser !== undefined
              ) {
                localapp.removeUser(localapp.currentUser);
              }
            } else {
              setisSuccess1(true);
              if (
                localapp.currentUser !== null &&
                localapp.currentUser !== undefined
              ) {
                localapp.removeUser(localapp.currentUser);
              }
            }
            disableloading();

            if (
              localapp.currentUser !== null &&
              localapp.currentUser !== undefined
            ) {
              localapp.removeUser(localapp.currentUser);
            }
          }
        });
      });
      if (localapp.currentUser !== null && localapp.currentUser !== undefined) {
        await localapp.removeUser(localapp.currentUser);
      }

      disableloading();
    } catch (err) {
      // console.log("err : " + err);
      setresetflow("1");
      handleAuthenticationError(err);
      disableloading();
    }
  };

  function handleAuthenticationError(err) {
    const { status, message } = parseAuthenticationError(err);
    const errorType = message || status;
    switch (errorType) {
      default:
        setError({ ...error, remoteerror: err });
        break;
    }
  }

  function parseAuthenticationError(err) {
    const parts = err.message.split(":");
    const reason = parts[parts.length - 1].trimStart();
    if (!reason) return { status: "", message: "" };
    const reasonRegex = /(?<message>.+)\s\(status (?<status>[0-9][0-9][0-9])/;
    const match = reason.match(reasonRegex);
    const { status, message } = match?.groups ?? {};
    return { status, message };
  }
  //message functions
  function handleSweetAlert() {
    window.location.href = "/";
  }

  function handleSweetAlert1() {
    window.location.href = "/forgotpassword";
  }

  return (
    <div className="divcontainer">
      <ApplicationHeadername />
      <p className="txtalign p-2">
        <h5>Reset Password</h5>
      </p>

      <div>
        <div className="rowctr">
          <input
            type="password"
            className="form-control"
            placeholder=" New Password"
            id="password"
            name="password"
            value={values.password}
            onChange={(e) => {
              handleChange(e);
            }}
          />

          {error.password.length > 0 && (
            <small className="text-danger" htmlFor="password">
              {error.password}
            </small>
          )}
        </div>
        <div className="rowctr">
          <input
            type="password"
            className="form-control "
            placeholder="Confirm Password"
            id="confirmpassword"
            name="confirmpassword"
            value={values.confirmpassword}
            onChange={(e) => {
              handleChange(e);
            }}
          />

          {error.confirmpassword.length > 0 && (
            <small className="text-danger" htmlFor="confirmpassword">
              {error.confirmpassword}
            </small>
          )}
        </div>

        <div className="text-center mt-2">
          <Button
            onClick={handleSubmit}
            className="btn btn-success "
            disabled={!isvaliddata}
          >
            Submit
          </Button>
        </div>
      </div>
      <ClipLoader
        color={color}
        loading={resetflow1loading}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      {/* <br className="bg-gray-600 opacity-2" />
        <br className="bg-gray-600 opacity-2" />
        <hr className="bg-gray-600 opacity-2" /> */}
      {/* <div className="text-gray-600 text-center text-gray-500-darker mb-0">
          &copy; {copyrightcontent}
        </div> */}
      <div>
        {isSuccess && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title="Password reset successfully.."
            onConfirm={handleSweetAlert}
          ></SweetAlert>
        )}
        {isSuccess1 && (
          <SweetAlert
            danger
            style={{ color: "black" }}
            title="Invalid URL"
            onConfirm={handleSweetAlert1}
          ></SweetAlert>
        )}
      </div>
    </div>
  );
}
export default ResetPassword;
