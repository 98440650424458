// //services for getapiusersettingsbyappname_companyid
// export const getapiusersettingsbyappname_companyid = async (
//   appname,
//   apiappcompanyid,
//   Ctechapp,
//   loggedin_by
// ) => {
//   try {
//     const data = await Ctechapp.functions.getapiusersettingsbyappname_companyid(
//       appname,
//       apiappcompanyid,
//       loggedin_by
//     );
//     if (data !== undefined) {
//       // console.log(
//       //   "data of getapiusersettingsbyappname_companyid :" + JSON.stringify(data)
//       // );
//       return JSON.stringify(data);
//     }
//   } catch (error) {
//     return error.response.data.message;
//   }
// };

// //services for getapiusersettingsbyappname_userid
// export const getapiusersettingsapplistbyuserid = async (
//   Ctechapp,
//   loggedin_by
// ) => {
//   try {
//     const data = await Ctechapp.functions.getapiusersettingsapplistbyuserid(
//       loggedin_by
//     );
//     if (data !== undefined) {
//       // console.log(
//       //   "data of getapiusersettingsapplistbyuserid :" + JSON.stringify(data)
//       // );
//       return JSON.stringify(data);
//     }
//   } catch (error) {
//     return error.response.data.message;
//   }
// };

// //services for getapiusersettingsbyappname_userid
// export const getapiusersettingsbyappname_userid = async (
//   appname,
//   Ctechapp,
//   loggedin_by
// ) => {
//   try {
//     const data = await Ctechapp.functions.getapiusersettingsbyappname_userid(
//       appname,
//       loggedin_by
//     );
//     if (data !== undefined) {
//       // console.log(
//       //   "data of getapiusersettingsbyappname_userid :" + JSON.stringify(data)
//       // );
//       return JSON.stringify(data);
//     }
//   } catch (error) {
//     return error.response.data.message;
//   }
// };

// //services for getapisettingsbyid
// export const getapisettingsbyid = async (id, Ctechapp, loggedin_by) => {
//   try {
//     const data = await Ctechapp.functions.getapisettingsbyid(id, loggedin_by);
//     if (data !== undefined) {
//       //  console.log("data of getapisettingsbyid :" + JSON.stringify(data));
//       return JSON.stringify(data);
//     }
//   } catch (error) {
//     return error.response.data.message;
//   }
// };

// //services for getapiusersettingsbyappname_userid_companyid
export const getapiusersettingsbyappname_userid_companyid = async (
  appname,
  userid,
  apiappcompanyid,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data =
      await Ctechapp.functions.getapiusersettingsbyappname_userid_companyid(
        appname,
        userid,
        apiappcompanyid,
        loggedin_by
      );
    if (data !== undefined) {
      // console.log(
      //   "data of getapiusersettingsbyappname_userid_companyid :" +
      //     JSON.stringify(data)
      // );
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

// //services for getapiusersettingsbyappname_email_companyid
// export const getapiusersettingsbyappname_email_companyid = async (
//   appname,
//   email,
//   apiappcompanyid,
//   Ctechapp,
//   loggedin_by
// ) => {
//   try {
//     const data =
//       await Ctechapp.functions.getapiusersettingsbyappname_email_companyid(
//         appname,
//         email,
//         apiappcompanyid,
//         loggedin_by
//       );
//     if (data !== undefined) {
//       // console.log(
//       //   "data of getapiusersettingsbyappname_email_companyid :" +
//       //     JSON.stringify(data)
//       // );
//       return JSON.stringify(data);
//     }
//   } catch (error) {
//     return error.response.data.message;
//   }
// };

// //services for insertapiusersettings
// export const insertapiusersettings = async (
//   userid,
//   email,
//   appname,
//   restapihostname,
//   apigranttype,
//   apiappcompanyid,
//   apiclientid,
//   apiclientsecret,
//   apirefreshtoken,
//   apiaccesstokenhostname,
//   apiredirecturi,
//   apienvironment,
//   ackid,
//   loggedinby,
//   Ctechapp
// ) => {
//   try {
//     const userdata = {
//       userid: userid,
//       email: email,
//       appname: appname,
//       restapihostname: restapihostname,
//       apigranttype: apigranttype,
//       apiappcompanyid: apiappcompanyid,
//       apiclientid: apiclientid,
//       apiclientsecret: apiclientsecret,
//       apirefreshtoken: apirefreshtoken,
//       apiaccesstokenhostname: apiaccesstokenhostname,
//       apiredirecturi: apiredirecturi,
//       apienvironment: apienvironment,
//       ackid: ackid,
//     };
//     const data = await Ctechapp.functions.insertapiusersettings(
//       JSON.stringify(userdata),
//       loggedinby
//     );
//     // console.log("status: success");
//     // console.log("data", data);
//     return data;
//   } catch (error) {
//     console.log("error : ", error);
//     throw Error(error);
//   }
// };

// export const updateapiusersettingapirefreshtokenbyid = async (
//   _id,
//   apirefreshtoken,
//   apiapptoken,
//   Ctechapp,
//   loggedinby
// ) => {
//   try {
//     const apiusersetting = {
//       _id: _id,
//       apirefreshtoken: apirefreshtoken,
//       apiapptoken: apiapptoken,
//     };

//     const data =
//       await Ctechapp.functions.updateapiusersettingapirefreshtokenbyid(
//         JSON.stringify(apiusersetting),
//         loggedinby
//       );
//     //  console.log("status: success");
//     return { status: "success", data };
//   } catch (error) {
//     return error.response.data.message;
//   }
// };

// //services for updateapiusersettingsbyid
// export const updateapiusersettingsbyid = async (
//   _id,
//   userid,
//   email,
//   appname,
//   restapihostname,
//   apigranttype,
//   apiappcompanyid,
//   apiclientid,
//   apiclientsecret,
//   apirefreshtoken,
//   apiaccesstokenhostname,
//   apiredirecturi,
//   apienvironment,
//   Ctechapp,
//   loggedinby
// ) => {
//   try {
//     const userdata = {
//       _id: _id,
//       userid: userid,
//       email: email,
//       appname: appname,
//       restapihostname: restapihostname,
//       apigranttype: apigranttype,
//       apiappcompanyid: apiappcompanyid,
//       apiclientid: apiclientid,
//       apiclientsecret: apiclientsecret,
//       apirefreshtoken: apirefreshtoken,
//       apiaccesstokenhostname: apiaccesstokenhostname,
//       apiredirecturi: apiredirecturi,
//       apienvironment: apienvironment,
//     };
//     const data = await Ctechapp.functions.updateapiusersettingsbyid(
//       JSON.stringify(userdata),
//       loggedinby
//     );
//     //  console.log("status: success");
//     // console.log("data", data);
//     return data;
//   } catch (error) {
//     console.log("error : ", error);
//     throw Error(error);
//   }
// };

// //services for deleteApiUserSettingsByCompanyIDandUserID
// export const deleteApiUserSettingsByCompanyIDandUserID = async (
//   companyid,
//   userid,
//   Ctechapp,
//   loggedin_by
// ) => {
//   try {
//     const data =
//       await Ctechapp.functions.deleteApiUserSettingsByCompanyIDandUserID(
//         companyid,
//         userid,
//         loggedin_by
//       );
//     if (data !== undefined) {
//       // console.log(
//       //   "data of deleteApiUserSettingsByCompanyIDandUserID: " +
//       //     JSON.stringify(data)
//       // );
//       return JSON.stringify(data);
//     }
//   } catch (error) {
//     return error.response.data.message;
//   }
// };
