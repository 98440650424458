import { Panel, PanelHeader, PanelBody } from "../../components/panel/panel";
import React, { useState, useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import customstyles from "../../components/customstyles/react-select-styles";
import Table from "react-bootstrap/Table";
import LoadingDialog from "../../components/LoadingDialog";
import {
  GetImportUserListFromQuickBooks,
  getQuickbooksImportUserListByCompanyID,
  getQuickBooksDedupByIDs,
} from "../../services/quickbooks_contact_services";
import {
  getappcompanylistbyappname_userid,
  getappinfobyappname_userid_companyid,
} from "../../services/applications_services";
import {
  getimportcontactsbyappname,
  getuserbyauthproviderid,
} from "../../services/users_services";

import SweetAlert from "react-bootstrap-sweetalert";

import { appConstants } from "../../constants/app_constants";

import Moment from "moment";
import GenericListTable from "../../components/gridTable/genericLlistTable";
import { createImportContactTask } from "../../services/import_contact_task_services";
import { useRealmApp } from "../../providers/RealmApp";
import { useAuth0 } from "@auth0/auth0-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageSubHeader from "../../components/pagesubheader";
import { produce } from "immer";
const _appname = "QuickBooks";

function QuickbooksImportWizard() {
  const history = useHistory();
  const { v4: uuidv4 } = require("uuid");
  const { accountid } = useLocation().state || "";
  const [tabQuickbooksusers, Stequickbooksusers] = useState(true);
  const [tabApproveWithoutverification, setApproveWithoutverification] =
    useState(false);
  const [tabSendverification, setSendverification] = useState(false);
  const [userStepbutton2, setuserStepbutton2] = useState(false);
  const [userStepbutton3, setuserStepbutton3] = useState(false);
  const [page, Stepage] = useState(0);
  const [dedupData, setDedupData] = useState([]);
  const [dedupDataIndex, setDedupDataIndex] = useState();
  const [isDedup, setIsDedup] = useState(false);
  const [isDedupRes, setIsDedupeRes] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [duplicateMsg, setDuplicateMsg] = useState("");

  const [headerInputAction, setHeaderInputAction] = useState("0");
  const [quickbooksApptokenInfo, setQuickBooksAppTokenInfo] = useState({});
  const [step3Page, setStep3Page] = useState(false);
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  const [dropDownEnable, setDropDownEnable] = useState(true);
  const [dropDownEnableRow, setDropDownEnableRow] = useState(true);
  const [survivingData, setSurvivingData] = useState();
  const [errorMsg, setErrorMsg] = useState("");
  const [userapisettingsinfo, setUserapisettingsinfo] = useState([]);
  const [selectedMatchingSourceUser, setSelectedMatchingSourceUser] = useState(
    []
  );
  const [isApprovedMsg, setisApprovedMsg] = useState(false);
  const [isApproveWithNotificationMsg, setisApproveWithNotificationMsg] =
    useState(false);
  const selectActionOptions = appConstants.selectActionOptions;

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    username: "",
    nationality: "",
    other: "",
  });

  const FormTitles = [
    "quickbooksusers",
    "approvewithoutverification",
    "sendverification",
  ];
  const [selectedcompany, setSelectedCompany] = useState("DEFAULT");
  const [password, setpassword] = useState("test123=0");
  const [structureTable, setStructureTable] = useState({
    personal_email_count: 0,
    personal_phone_count: 0,
    personal_address_count: 0,
    aff_count: 0,
    business_email_count: 0,
    business_phone_count: 0,
    business_address_count: 0,
  });
  var [companylist, setCompanyList] = useState([]);
  const [userlist, setUserList] = useState([]);
  const [userlist_step2, setUserList_Step2] = useState([]);
  const [userlist_step3, setUserList_Step3] = useState([]);
  var [quickbooksuserlist, setQuickbooksuserlist] = useState([]);
  var [apiuserlist, setApiuserlist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [order, SetOrder] = useState("ASC");
  const [result, SetResult] = useState("0");
  const ctechapp = useRealmApp();
  const [errordisplay, SetErrorDisplay] = useState("");
  let [userackid, SetUserAckId] = useState("0");
  var sortJsonArray = require("sort-json-array");

  const [userinfo, setUserinfo] = useState({
    role: "",
  });

  const [isMessage, setisMessage] = useState("");
  const [msgTitle] = useState("Contact Sync");
  const [msgInfo, setmsgInfo] = useState("");
  const [totalRecord, settotalRecord] = useState(0);
  const [loadDialogOpen, setloadDialogOpen] = useState(false);

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = userlist.sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setUserList(sorted);
      SetOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = userlist.sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setUserList(sorted);
      SetOrder("ASC");
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getUserInfoByID();
      getCompanyListData(ctechapp.currentUser);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      getDatauserinfobyCompanyid(ctechapp.currentUser);
      // GetQuickbooksImportUserListByCompanyID();
    }
  }, [selectedcompany]);

  async function getUserInfoByID() {
    try {
      getuserbyauthproviderid(user.sub, ctechapp.currentUser).then(
        (response) => {
          let user_info = JSON.parse(response);
          if (response !== undefined || user_info.length !== 0) {
            setUserinfo(user_info[0]);
          }
        }
      );
    } catch (exception) {
      console.log(exception);
    }
  }

  async function GetQuickBooksDedupByIDs(arg) {
    setloadDialogOpen(true);
    try {
      getQuickBooksDedupByIDs(
        arg,
        quickbooksApptokenInfo,
        ctechapp.currentUser
      ).then((response) => {
        if (response && response.statusCode === 200) {
          console.log("getQuickBooksDedupByIDs response.data", response.data);
          let data = response.data;
          var p_emailarr_s = 0,
            p_phonearr_s = 0,
            p_addressarr_s = 0,
            b_emailarr_s = 0,
            b_phonearr_s = 0,
            b_addressarr_s = 0,
            aff_s = 0;
          data?.map((row) => {
            if (row?.emailArray?.length > 0) {
              p_emailarr_s = p_emailarr_s + row.emailArray.length;
            }
            if (row?.addressArray?.length > 0) {
              p_addressarr_s = p_addressarr_s + row.addressArray.length;
            }
            if (row?.phoneArray?.length > 0) {
              p_phonearr_s = p_phonearr_s + row.phoneArray.length;
            }
            if (row?.contactAffiliationsArray?.length > 0) {
              aff_s = aff_s + row.contactAffiliationsArray.length;
              row.contactAffiliationsArray?.map((val) => {
                if (val.emailArray.length > 0) {
                  b_emailarr_s = b_emailarr_s + val.emailArray.length;
                }
                if (val.phoneArray.length > 0) {
                  b_phonearr_s = b_phonearr_s + val.phoneArray.length;
                }
                if (val.addressArray.length > 0) {
                  b_addressarr_s = b_addressarr_s + val.addressArray.length;
                }
              });
            }
          });
          let _structureTable = { ...structureTable };
          _structureTable.personal_address_count = p_addressarr_s;
          _structureTable.personal_email_count = p_emailarr_s;
          _structureTable.personal_phone_count = p_phonearr_s;
          _structureTable.business_address_count = b_addressarr_s;
          _structureTable.business_email_count = b_emailarr_s;
          _structureTable.business_phone_count = b_phonearr_s;
          _structureTable.aff_count = aff_s;
          //   console.log("_structureTable", _structureTable);
          setStructureTable(_structureTable);
          //  setDedupData(response.data);
          GetTableStructure(response.data, _structureTable);
          // setloadDialogOpen(false);
          setIsDedup(true);
        } else {
          setloadDialogOpen(false);
          console.error(response.error);
        }
      });
    } catch (exception) {
      console.log(exception);
    }
  }

  function GetTableStructure(dataList, structureArralyList) {
    //getImportDedupFormatByRecords
    // try {
    //   getImportDedupFormatByRecords(
    //     dataList,
    //     structureArralyList,
    //     ctechapp.currentUser
    //   ).then((response) => {
    //     console.log("response", response);
    //     setDedupData(response);
    //     setloadDialogOpen(false);
    //   });
    // } catch (err) {
    //   console.error("GetTableStructure internal function error", err);
    // }

    /*
exports();
*/

    try {
      // console.log("dataList1", dataList);
      // console.log("structureArralyList1", structureArralyList);
      let arrRecords = [];
      let PersonalEmailArr = [];
      let PersonalAddressArr = [];
      let PersonalPhoneArr = [];
      let AffArr = [];
      let BusinessEmailArr = [];
      let BusinessAddressArr = [];
      let BusinessPhoneArr = [];

      let colNames = [
        "_id",
        "firstname",
        "lastname",
        "email",
        "phone",
        "nickName",
        "salutation",
      ];
      for (let i = 0; i < structureArralyList.personal_email_count; i++) {
        // const columnName = "emailObj" + parseInt(i).toString();
        // colNames.push(columnName);
        PersonalEmailArr.push({ active: 0 });
      }
      for (let i = 0; i < structureArralyList.personal_phone_count; i++) {
        //const columnName = "phoneObj" + parseInt(i).toString();
        //colNames.push(columnName);
        PersonalPhoneArr.push({ active: 0 });
      }
      for (let i = 0; i < structureArralyList.personal_address_count; i++) {
        //const columnName = "addressObj" + parseInt(i).toString();
        //colNames.push(columnName);
        PersonalAddressArr.push({ active: 0 });
      }
      for (let i = 0; i < structureArralyList.aff_count; i++) {
        // const columnName = "affArr" + parseInt(i).toString();
        // colNames.push(columnName);
        AffArr.push({ active: 0 });
      }
      for (let i = 0; i < structureArralyList.business_email_count; i++) {
        // const columnName = "bussEmailObj" + parseInt(i).toString();
        // colNames.push(columnName);
        BusinessEmailArr.push({ active: 0 });
      }
      for (let i = 0; i < structureArralyList.business_phone_count; i++) {
        // const columnName = "bussPhoneObj" + parseInt(i).toString();
        // colNames.push(columnName);
        BusinessPhoneArr.push({ active: 0 });
      }
      for (let i = 0; i < structureArralyList.business_address_count; i++) {
        // const columnName = "bussAddressObj" + parseInt(i).toString();
        // colNames.push(columnName);
        BusinessAddressArr.push({ active: 0 });
      }

      // schema creation
      for (let col = 0; col < dataList.length; col++) {
        // schema creation personal
        let recordObj = {};

        for (let row = 0; row < colNames.length; row++) {
          const displayColumn = colNames[row];
          if (displayColumn.toLowerCase().includes("obj")) {
            recordObj[displayColumn] = {};
          } else if (displayColumn.toLowerCase().includes("arr")) {
            recordObj[displayColumn] = [];
          } else {
            recordObj[displayColumn] = "";
          }
        }
        let recordAffObj = { active: 0 };
        recordObj.contactAffiliationsArray = [];
        for (let col = 0; col < AffArr.length; col++) {
          // schema creation affiliation

          if (BusinessEmailArr && BusinessEmailArr.length > 0) {
            // recordAffObj.emailArray = BusinessEmailArr;
            recordAffObj = produce(recordAffObj, (draft) => {
              draft.emailArray = BusinessEmailArr;
            });
          }
          if (BusinessPhoneArr && BusinessPhoneArr.length > 0) {
            //  recordAffObj.phoneArray = BusinessPhoneArr;
            recordAffObj = produce(recordAffObj, (draft) => {
              draft.phoneArray = BusinessPhoneArr;
            });
          }
          if (BusinessAddressArr && BusinessAddressArr.length > 0) {
            // recordAffObj.addressArray = BusinessAddressArr;
            recordAffObj = produce(recordAffObj, (draft) => {
              draft.addressArray = BusinessAddressArr;
            });
          }

          // recordAffObj = produce(recordAffObj, (draft) => {
          //   draft.companyName = "";
          //   draft.id = "";
          //   draft.companyid = "";
          // });

          recordObj.contactAffiliationsArray.push(recordAffObj);
        }

        recordObj.emailArray = PersonalEmailArr;
        recordObj.phoneArray = PersonalPhoneArr;
        recordObj.addressArray = PersonalAddressArr;
        arrRecords.push(recordObj);
      }
      // console.log("arrRecords", arrRecords);
      const finalResult = [];
      //Schema Data  updation
      dataList &&
        dataList?.map((rowData, index) => {
          const rowobj = { ...rowData };

          let recordData = { ...arrRecords[index] };
          recordData._id = rowobj._id || "";
          recordData._p_contactID = rowobj._p_contactID || "";
          recordData.firstname = rowobj.firstname || "";
          recordData.lastname = rowobj.lastname || "";
          recordData.nickName = rowobj.nickName || "";
          recordData.email = rowobj.email || "";
          recordData.phone = rowobj.phone || "";
          recordData.sourceSystem = rowobj.sourceSystem || "";

          rowobj.emailArray &&
            rowobj.emailArray.forEach((emailRow, emailindex) => {
              // arrRecords[index]["emailObj" + emailindex] = emailRow || {};

              recordData = produce(recordData, (draft) => {
                draft.emailArray[emailindex] = { ...emailRow };
              });
              //recordData.emailArray[emailindex] = { ...emailRow };
            });

          rowobj.phoneArray &&
            rowobj.phoneArray.forEach((phoneRow, phoneindex) => {
              //  arrRecords[index]["phoneObj" + phoneindex] = phoneRow || {};
              //   recordData.phoneArray[phoneindex] = { ...phoneRow };
              recordData = produce(recordData, (draft) => {
                draft.phoneArray[phoneindex] = { ...phoneRow };
              });
            });

          rowobj.addressArray &&
            rowobj.addressArray.forEach((addressRow, addressindex) => {
              // arrRecords[index]["addressObj" + addressindex] = addressRow || {};
              //recordData.addressArray[addressindex] = { ...addressRow };
              recordData = produce(recordData, (draft) => {
                draft.addressArray[addressindex] = { ...addressRow };
              });
            });

          rowobj.contactAffiliationsArray &&
            rowobj.contactAffiliationsArray.forEach((affRow, affindex) => {
              let _affObjInput = { ...affRow };
              //    console.log("_affObjInput", _affObjInput);
              let _affObjRecord_temp =
                recordData.contactAffiliationsArray[affindex];
              let { active, ..._affObjRecord } = _affObjRecord_temp;
              _affObjInput.emailArray &&
                _affObjInput.emailArray.forEach((emailRow, emailindex) => {
                  _affObjRecord = produce(_affObjRecord, (draft) => {
                    draft.emailArray[emailindex] = { ...emailRow };
                  });
                });

              _affObjInput.phoneArray &&
                _affObjInput.phoneArray.forEach((phoneRow, phoneindex) => {
                  _affObjRecord = produce(_affObjRecord, (draft) => {
                    draft.phoneArray[phoneindex] = { ...phoneRow };
                  });
                });

              _affObjInput.addressArray &&
                _affObjInput.addressArray.forEach(
                  (addressRow, addressindex) => {
                    _affObjRecord = produce(_affObjRecord, (draft) => {
                      draft.addressArray[addressindex] = { ...addressRow };
                    });
                  }
                );
              // console.log("_affObjRecord.companyName", _affObjRecord);
              // _affObjRecord.companyName = _affObjInput.companyName || "";
              if (
                _affObjInput?.companyName &&
                _affObjInput?.companyName !== ""
              ) {
                _affObjRecord = produce(_affObjRecord, (draft) => {
                  draft.companyName = _affObjInput?.companyName || "";
                  draft.id = _affObjInput?.id || "";
                  draft.companyId = _affObjInput?.companyId || "";
                  draft.sourceSystem = _affObjInput?.sourceSystem || "";
                  draft.contactOwner = _affObjInput?.contactOwner || "";
                  draft.description = _affObjInput?.description || "";
                  draft.reportTo = _affObjInput?.reportTo || "";
                  draft.contactRole = _affObjInput?.contactRole || "";
                  draft.reportTo = _affObjInput?.reportTo || "";
                  draft.title = _affObjInput?.title || "";
                  draft.claimedUrl = _affObjInput?.claimedUrl || "";
                  draft.department = _affObjInput?.department || "";
                });
              }
              recordData = produce(recordData, (draft) => {
                draft.contactAffiliationsArray[affindex] = _affObjRecord;
              });
            });

          finalResult.push(recordData);
        });

      // console.log("finalResult", finalResult);
      setSurvivingData(finalResult[0]);
      setDedupData(finalResult);
      setloadDialogOpen(false);
    } catch (exception) {
      console.log(exception);
      return exception;
    }
  }

  const ResultUpdate = (res2) => {
    SetResult(res2);
  };

  const selectcontact_data = useMemo(() => [...userlist], [userlist]);
  const selectcontact_appColumns = useMemo(
    () => [
      {
        Header: () => (
          <input
            type="checkbox"
            className="form-check-input"
            name="allSelectSource"
            id="allSelectSource"
            // allSelect selected when both length equal
            // slecteduser === allUser
            checked={
              userlist.filter((row) => row.ischecked === true).length ===
                userlist.length && userlist.length > 0
                ? true
                : false
            }
            onChange={(e) => handleChange_SoureceSelect(e, userlist)}
          />
        ),
        accessor: "ischecked",
        sortable: false,
        width: "20%",

        Cell: ({ value, row }) => {
          return (
            <div>
              {!row.original.hasOwnProperty("matchingsource") ? (
                <td>
                  <input
                    type="checkbox"
                    checked={row.original.ischecked}
                    onChange={(e) =>
                      handleChange_SoureceSelect(e, row.index, row.original)
                    }
                    name="selectsourceoption"
                    className="form-check-input"
                    value={row.original.ischecked}
                  />
                </td>
              ) : (
                <td></td>
              )}
            </div>
          );
        },
      },
      {
        Header: "Source",
        accessor: "source",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },

      {
        Header: "First name ",
        accessor: "firstname",
        sortable: true,
        width: "20%",
      },
      {
        Header: "Last name",
        accessor: "lastname",
        sortable: true,
        width: "20%",
      },
      {
        Header: "Email",
        accessor: "email",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },
      {
        Header: "Phone ",
        accessor: "phone",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },
      {
        Header: "Contact role ",
        accessor: "contactrole",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },
      {
        Header: "CM Record ",
        accessor: "cmlinkid",
        width: "20%",
        Filter: "",
        filter: "",
        Cell: ({ row, value }) => {
          return <div>{JSON.stringify(value)}</div>;
        },
      },
      {
        Header: "CP Record ",
        accessor: "cplinkid",
        width: "20%",
        Filter: "",
        filter: "",
        Cell: ({ row, value }) => {
          return <div>{JSON.stringify(value)}</div>;
        },
      },
      {
        Header: "Matched record ",
        accessor: "matchrecord",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
        Cell: ({ row, value }) => {
          return (
            <div>
              {row.original.cmlinkid.length > 0 ||
              row.original.cplinkid.length > 0 ? (
                <Button
                  className=" ms-2  position-relative"
                  onClick={(e) =>
                    //  handleAction(e, row?.original, row?.index, "dedup_call")
                    handleAction(e, row?.original, row?.index, "dedupbyid")
                  }
                  variant="secondary"
                >
                  Merge
                  {row.original.cplinkid.length > 0 && (
                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-info">
                      {row?.original.cplinkid.length}
                      <span class="visually-hidden">Merge records</span>
                    </span>
                  )}
                </Button>
              ) : (
                <div></div>
              )}
            </div>
          );
        },
      },
    ],
    [userlist]
  );
  const hiddenselectcontactappColumns = ["cplinkid", "cmlinkid"];

  const approvewithoutverification_data = useMemo(
    () => [...userlist_step2],
    [userlist_step2]
  );
  const approvewithoutverification_appColumns = useMemo(
    () => [
      {
        Header: "First name ",
        accessor: "firstname",
        sortable: true,
        width: "20%",
      },
      {
        Header: "Last name",
        accessor: "lastname",
        sortable: true,
        width: "20%",
      },
      {
        Header: "Email",
        accessor: "email",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },
      {
        Header: "Phone ",
        accessor: "phone",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },
      {
        Header: "Contact role ",
        accessor: "contactrole",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },

      {
        Header: () => (
          <div className="row">
            <label className="col-3 mt-3">Action</label>

            <div className="col-9 ">
              <Select
                width={"10%"}
                name="reacord_header_action"
                styles={customstyles}
                className="form-control"
                options={selectActionOptions}
                value={selectActionOptions.filter(
                  (record) => record.value === headerInputAction
                )}
                onChange={(val) => {
                  handleHeaderInputChange({
                    target: {
                      name: "reacord_header_action",
                      value: val.value,
                    },
                  });
                }}
              ></Select>
            </div>
          </div>
        ),
        accessor: "matchingsource",
        sortable: false,
        width: "20%",
        Filter: "",
        filter: "",
        Cell: ({ row, value }) => {
          return (
            <div>
              {!row.original.hasOwnProperty("matchingsource") ? (
                <td>
                  {" "}
                  <div className="col-10 px-3">
                    <Select
                      name="reacord_action"
                      styles={customstyles}
                      className="form-control"
                      options={selectActionOptions}
                      value={selectActionOptions.filter(
                        (record) =>
                          record.value === row.original.selectdropdownsource
                      )}
                      onChange={(val) => {
                        handleInputChange(
                          {
                            target: {
                              name: "reacord_action",
                              value: val.value,
                              data: row.original,
                            },
                          },
                          row.index
                        );
                      }}
                    ></Select>
                  </div>
                </td>
              ) : (
                <td></td>
              )}
            </div>
          );
        },
      },
    ],
    [userlist_step2]
  );
  const hiddenapprovewithoutverificationappColumns = [];

  const approvewithverification_data = useMemo(
    () => [...userlist_step3],
    [userlist_step3]
  );
  const approvewithverification_appColumns = useMemo(
    () => [
      {
        Header: "First name ",
        accessor: "firstname",
        sortable: true,
        width: "20%",
      },
      {
        Header: "Last name",
        accessor: "lastname",
        sortable: true,
        width: "20%",
      },
      {
        Header: "Email",
        accessor: "email",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },
      {
        Header: "Phone ",
        accessor: "phone",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },
    ],
    []
  );
  const hiddenapprovewithverificationappColumns = [];

  const handleAction = (e, data, index, type) => {
    switch (type) {
      case "dedup_call":
        data.sourceindex = index;
        setDedupData(data);
        //  setIsDedup(true);
        break;
      case "dedup_ok":
        let _userlist = [...userlist];
        _userlist[index].firstname = data.firstname;
        _userlist[index].lastname = data.lastname;
        _userlist[index].email = data.email;
        _userlist[index].phone = data.phone;
        setUserList(_userlist);
        //setIsDedup(false);
        break;
      case "dedupbyid":
        // GetAppNameDedupById(data.source, data);
        console.log("dedupbyid data", data);
        GetQuickBooksDedupByIDs(data);

        setDedupDataIndex(index);
        break;
      default:
        break;
    }
  };
  const handleDedupChange = (e) => {
    const { name, value, checked } = e.target;
    let finalresult = [...dedupData];
    let _dedupData = { ...finalresult[0] };
    if (checked) {
      if (_dedupData[name] === value) {
        e.target.checked = false;
        let errorname = "";
        switch (name) {
          case "firstname":
            errorname = "First name";
            break;
          case "lastname":
            errorname = "Last name";
            break;
          case "email":
            errorname = " Main email";
            break;
          case "phone":
            errorname = "Main phone";
            break;
          case "nickName":
            errorname = "Nick name";
            break;
          default:
            break;
        }

        setDuplicateMsg(errorname + " already exists");
        setIsDuplicate(true);
      } else {
        _dedupData[name] = value;
      }
    } else {
      _dedupData[name] = survivingData[name];
    }
    finalresult[0] = _dedupData;
    setDedupData(finalresult);
  };
  const handleChange_SoureceSelect = (e, index, data) => {
    let _userlist = [...userlist];
    const { name, checked } = e.target;

    if (checked) {
      // if cheked and selectall checkbox add all fileds to selectedList
      if (name === "allSelectSource") {
        _userlist.map((user) => {
          user.ischecked = true;
        });

        setUserList(_userlist);
      } else {
        // if cheked and specific checkbox add specific field to selectedList

        _userlist[index].ischecked = true;
        setUserList(_userlist);
      }
    } else {
      // if uncheked and selectall checkbox add remove all fileds from selectedList
      if (name === "allSelectSource") {
        _userlist.map((user) => {
          user.ischecked = false;
        });

        setUserList(_userlist);
      } else {
        // if uncheked and specific checkbox remove specific field from selectedList

        _userlist[index].ischecked = false;
        setUserList(_userlist);
      }
    }
  };

  const handleChange_MatchingSoureceSelect = (e, data) => {
    const { name, checked } = e.target;
    if (checked) {
      // if cheked and selectall checkbox add all fileds to selectedList
      if (name === "allSelectMatchingSource") {
        setSelectedMatchingSourceUser(userlist);
      } else {
        // if cheked and specific checkbox add specific field to selectedList
        setSelectedMatchingSourceUser([...selectedMatchingSourceUser, data]);
        var _userlist = [...userlist];
        let index = _userlist.findIndex((_user) => _user.id === data.linkid);

        if (index !== -1) {
          _userlist[index].contactid = data.id;
          setUserList(_userlist);
        }
      }
    } else {
      // if uncheked and selectall checkbox add remove all fileds from selectedList
      if (name === "allSelectMatchingSource") {
        setSelectedMatchingSourceUser([]);
      } else {
        // if uncheked and specific checkbox remove specific field from selectedList
        let tempuser = selectedMatchingSourceUser.filter(
          (item) => item.id !== data.id
        );

        setSelectedMatchingSourceUser(tempuser);
      }
    }
  };

  function getDatauserinfobyCompanyid() {
    try {
      if (selectedcompany !== "DEFAULT") {
        setloadDialogOpen(true);
        getappinfobyappname_userid_companyid(
          _appname,
          selectedcompany,
          ctechapp.currentUser,
          user.sub
        ).then((response) => {
          if (response !== undefined || response !== "null") {
            let company_info = JSON.parse(response);
            let res = company_info[0];
            setUserList([]);

            let _quickbooksAppInfo = {
              quickbooksClientID: res.appclientid,
              quickbooksClientSecret: res.appclientsecret,
              quickbooksRedirectUri: res.appredirecturi,
              quickbooksAppToken: res.appuserinfo[0].apptoken,
              quickbooksRefreshToken: res.appuserinfo[0].apprefreshtoken,
              quickbooksRestAPIHostName: res.appresthostname,
              quickbooksAccessTokenHostName: res.appaccesstokenhostname || "",
              quickbooksCompanyID: selectedcompany,
              loggedin_by: res.appuserinfo[0].userid,
            };
            setQuickBooksAppTokenInfo(_quickbooksAppInfo);

            getUserListData(
              res.appaccesstokenhostname,
              res.appresthostname,
              selectedcompany,
              res.appclientid,
              res.appclientsecret,
              res.appuserinfo[0].apprefreshtoken,
              res.appuserinfo[0].apptoken,
              res.appredirecturi
            );
          }
        });
      }
    } catch (Exception) {
      setloadDialogOpen(false);
      console.log("getDatauserinfobyCompanyid error : ", Exception);
    }
  }
  async function getUserListData(
    quickbooksAccessTokenHostName,
    quickbooksRestAPIHostName,
    quickbooksCompanyID,
    quickbooksClientID,
    quickbooksClientSecret,
    quickbooksRefreshToken,
    quickbooksAppToken,
    quickbooksRedirectUri
  ) {
    GetImportUserListFromQuickBooks(
      quickbooksCompanyID,
      quickbooksAccessTokenHostName,
      quickbooksClientID,
      quickbooksClientSecret,
      quickbooksRefreshToken,
      quickbooksAppToken,
      quickbooksRedirectUri,
      quickbooksRestAPIHostName,
      ctechapp.currentUser,
      user.sub
    ).then((response) => {
      if (response !== undefined || response.length !== 0) {
        const result = response;
        //console.log(JSON.stringify("result:"+ result));
        result.forEach((row) => {
          // console.log(JSON.stringify(row));
          if (
            row.primaryPhone != undefined &&
            row.primaryPhone.freeFormNumber
          ) {
            //console.log(row.primaryPhone.freeFormNumber)

            row.primaryPhone.freeFormNumber =
              row.primaryPhone.freeFormNumber.replace("-", "");
            row.primaryPhone.freeFormNumber =
              row.primaryPhone.freeFormNumber.replace("(", "");
            row.primaryPhone.freeFormNumber =
              row.primaryPhone.freeFormNumber.replace(")", "");
            row.primaryPhone.freeFormNumber =
              row.primaryPhone.freeFormNumber.replace(" ", "");
            row.primaryPhone.freeFormNumber =
              row.primaryPhone.freeFormNumber.replace("-", "");
            row.primaryPhone.freeFormNumber =
              row.primaryPhone.freeFormNumber.replace("+", "");
            row.primaryPhone.freeFormNumber =
              row.primaryPhone.freeFormNumber.replace(" ", "");
            row.primaryPhone.freeFormNumber =
              row.primaryPhone.freeFormNumber.replace(" ", "");
            row.primaryPhone.freeFormNumber =
              row.primaryPhone.freeFormNumber.replace(" ", "");
          }
        });
        // setQuickBooksuserlist(result);
        getapiDatauserinfo(result);
      }
    });
  }
  async function GetQuickbooksImportUserListByCompanyID() {
    setloadDialogOpen(true);
    getQuickbooksImportUserListByCompanyID(
      selectedcompany,
      ctechapp.currentUser,
      user.sub
    ).then((response) => {
      if (response.statusCode === 200) {
        setUserList(response.data);
        setloadDialogOpen(false);
      } else {
        setUserList([]);
        setloadDialogOpen(false);
      }
    });
  }

  // async function getUserListData() {
  //   console.log(user.sub);
  //   getQuickbooksuserlistbycompanyid(
  //     selectedcompany,
  //     ctechapp.currentUser,
  //     user.sub
  //   ).then((response) => {
  //     if (response !== undefined || response.length !== 0) {
  //       setQuickbooksuserlist(JSON.parse(response));

  //       getapiDatauserinfo(selectedcompany, JSON.parse(response));
  //     }
  //   });
  // }

  async function getCompanyListData() {
    getappcompanylistbyappname_userid(
      "QuickBooks",
      ctechapp.currentUser,
      user.sub
    ).then((response) => {
      try {
        if (response !== undefined) {
          let company_info = JSON.parse(response);

          var options = [];
          company_info?.map(function (company) {
            if (company?.appuserinfo.length > 0) {
              options.push({
                value: company.appaccountid,
                label: company.appuserinfo[0].appdisplayname,
              });
            }
          });

          setCompanyList(options);
          if (accountid) {
            handleCompanyChange({
              target: {
                name: "company_ID",
                value: accountid,
              },
            });
          } else {
            if (options.length > 0)
              handleCompanyChange({
                target: {
                  name: "company_ID",
                  value: options[0].value,
                },
              });
          }
        }
      } catch (exception) {}
    });
  }

  function getapiDatauserinfo(_quickbooksuserslist) {
    getimportcontactsbyappname(_appname, ctechapp.currentUser, user.sub).then(
      (response) => {
        if (response !== undefined) {
          let user_list = JSON.parse(response);
          setApiuserlist(user_list);
          GetBothUserList(_quickbooksuserslist, user_list);
        }
      }
    );
  }

  const formatphone = (phone) => {
    phone = phone?.replace("+", "");
    phone = phone?.replace("(", "");
    phone = phone?.replace("(", "");
    phone = phone?.replace("-", "");
    phone = phone?.replace("-", "");
    return phone;
  };
  async function GetBothUserList(_quickbooksuserslist, _contactusersList) {
    try {
      console.log("_quickbooksuserslist ", _quickbooksuserslist);
      //  setUserList([]);
      var _quickbooksusersList = [];
      let sortorder = 1;
      if (
        _quickbooksuserslist === null ||
        _quickbooksuserslist === "" ||
        _quickbooksuserslist === undefined
      ) {
        setisMessage(true);
        setmsgInfo(appConstants.import.handlefieldvalidation.nodata);
        return;
      }
      _quickbooksuserslist = _quickbooksuserslist.filter(
        (record) =>
          record.givenName !== undefined &&
          record.givenName !== null &&
          record.givenName !== "" &&
          record.familyName !== undefined &&
          record.familyName !== null &&
          record.familyName !== "" &&
          record?.primaryEmailAddr?.address !== undefined &&
          record?.primaryEmailAddr?.address !== null &&
          record?.primaryEmailAddr?.address !== ""
      );
      let localtotalRecord = 0;
      for (const quickbooksuserinfo of _quickbooksuserslist) {
        try {
          let firstname = "";
          let lastname = "";
          let contactrole = "";
          let id = quickbooksuserinfo.id;
          if (quickbooksuserinfo?.sourceType?.toLowerCase() === "employee") {
            firstname = quickbooksuserinfo.givenName;
            lastname = quickbooksuserinfo.familyName;
            contactrole = quickbooksuserinfo.sourceType;
          } else if (
            quickbooksuserinfo?.sourceType?.toLowerCase() === "vendor"
          ) {
            firstname = quickbooksuserinfo.givenName;
            lastname = quickbooksuserinfo.familyName;
            if (quickbooksuserinfo.vendor1099 === false) {
              contactrole = quickbooksuserinfo.sourceType;
            } else {
              contactrole = "Individual Contractor";
            }
          } else if (
            quickbooksuserinfo?.sourceType?.toLowerCase() === "customer"
          ) {
            firstname = quickbooksuserinfo.givenName;
            lastname = quickbooksuserinfo.familyName;
            contactrole = quickbooksuserinfo.sourceType;
          }
          let email = quickbooksuserinfo.primaryEmailAddr?.address;
          let phone = quickbooksuserinfo.primaryPhone?.freeFormNumber;
          let source = quickbooksuserinfo.source;

          var _user = {};
          _user["id"] = id;
          _user["firstname"] = firstname;
          _user["lastname"] = lastname;
          _user["email"] = email;
          _user["phone"] = phone;
          _user["contactrole"] = contactrole;
          _user["source"] = source;
          _user["is_active"] = 1;
          _user["companyid"] = selectedcompany;
          _user["contactid"] = "";
          _user["sortorder"] = sortorder;
          _user["sortby"] = contactrole;
          _user["linkid"] = id;
          _user["cmlinkid"] = [];
          _user["cplinkid"] = [];
          //check whether user exists in contactmaster already.

          const filteredbysourceuserid = [];
          try {
            for (const contactinfo of _contactusersList) {
              console.log("contactinfo", contactinfo);
              if (
                contactinfo !== undefined &&
                contactinfo?.sourceSystem !== undefined
              ) {
                contactinfo?.sourceSystem &&
                  contactinfo?.sourceSystem.map((sourcerecord) => {
                    if (
                      _user?.id === sourcerecord?.appuserid &&
                      _user?.companyid === sourcerecord?.companyid
                    )
                      filteredbysourceuserid.push(contactinfo);
                  });
              }
            }
          } catch (error) {
            console.log(_user?.email);
            console.log("Error in _contactusersList.filter", error);
          }

          if (filteredbysourceuserid.length === 0) {
            _quickbooksusersList.push(_user);
            localtotalRecord = localtotalRecord + 1;
            settotalRecord(localtotalRecord);
            //check for confidence factor and push related records in the user array

            //filterby emailand firstname and lastname
            const filteredbyemailandfirstname = _contactusersList.filter(
              (contactinfo) =>
                contactinfo?.email !== undefined &&
                contactinfo?.email !== null &&
                contactinfo?.email !== "" &&
                _user.email?.toLowerCase() ===
                  contactinfo?.email?.toLowerCase() &&
                _user.firstname?.toLowerCase() ===
                  contactinfo?.firstname?.toLowerCase() &&
                _user.lastname?.toLowerCase() ===
                  contactinfo?.lastname?.toLowerCase()
            );
            if (filteredbyemailandfirstname.length > 0) {
              var _localuser = {};
              _localuser["id"] = filteredbyemailandfirstname[0]._id;
              /*  _localuser["firstname"] = filteredbyemailandfirstname[0].firstname;
               _localuser["lastname"] = filteredbyemailandfirstname[0].lastname;
               _localuser["email"] = filteredbyemailandfirstname[0].email;
               _localuser["phone"] = filteredbyemailandfirstname[0].phone;
               _localuser["is_active"] = 1;
               _localuser["matchingsource"] = "Contact Master";
               _localuser["sortorder"] = sortorder;
               _localuser["sortby"] = contactrole;
               _localuser["linkid"] = id;
               console.log("_localuser1 ", _localuser);
               // 👇️ check if array contains object
               const isFound = _quickbooksusersList.some((element) => {
                 if (element.id === _localuser.id) {
                   return true;
                 }
                 return false;
               }); */
              if (!_user.cmlinkid.includes(_localuser.id)) {
                _user["cmlinkid"].push(_localuser.id);
              }
            }

            //filterby phoneand firstnameand lastname
            // const filteredbyphoneandfirstname = [];
            // _contactusersList.filter((contactinfo) => {
            //   if (
            //     contactinfo?.phone !== undefined &&
            //     contactinfo?.phone !== null &&
            //     contactinfo?.phone !== "" &&
            //     _user?.phone !== undefined &&
            //     _user?.phone !== null &&
            //     _user?.phone !== "" &&
            //      _user.phone === contactinfo?.phone &&
            //     _user.firstname?.toLowerCase() ===
            //     contactinfo?.firstname?.toLowerCase() &&
            //     _user.lastname?.toLowerCase() ===
            //     contactinfo?.lastname?.toLowerCase()
            //   ) {
            //     var contactinfophone = formatphone(phone);
            //     var userinfophone = formatphone(_user.phone);
            //     if (contactinfophone === userinfophone)
            //       filteredbyphoneandfirstname.push(contactinfo);
            //   }
            // });
            // if (filteredbyphoneandfirstname.length > 0) {
            //   var _localuser = {};
            //   _localuser["id"] = filteredbyphoneandfirstname[0]._id;
            //   _localuser["firstname"] = filteredbyphoneandfirstname[0].firstname;
            //   _localuser["lastname"] = filteredbyphoneandfirstname[0].lastname;
            //   _localuser["email"] = filteredbyphoneandfirstname[0].email;
            //   _localuser["phone"] = filteredbyphoneandfirstname[0].phone;
            //   _localuser["is_active"] = 1;
            //   _localuser["matchingsource"] = "Contact Master";
            //   _localuser["sortorder"] = sortorder;
            //   _localuser["sortby"] = contactrole;
            //   _localuser["linkid"] = id;
            //   console.log("_localuser1 ", _localuser);
            //   // 👇️ check if array contains object
            //   const isFound = _quickbooksusersList.some((element) => {
            //     if (element.id === _localuser.id) {
            //       return true;
            //     }
            //     return false;
            //   });
            //   // if (!isFound) {
            //   //   _quickbooksusersList.push(_localuser);
            //   // }
            // }

            //filterby email and firstname
            const filteredbyemailandfirstnameonly = _contactusersList.filter(
              (contactinfo) =>
                contactinfo?.email !== undefined &&
                contactinfo?.email !== null &&
                contactinfo?.email !== "" &&
                _user.email?.toLowerCase() ===
                  contactinfo?.email?.toLowerCase() &&
                _user.firstname?.toLowerCase() ===
                  contactinfo?.firstname?.toLowerCase()
            );
            if (filteredbyemailandfirstnameonly.length > 0) {
              var _localuser = {};
              _localuser["id"] = filteredbyemailandfirstnameonly[0]._id;
              // _localuser["firstname"] =
              //   filteredbyemailandfirstnameonly[0].firstname;
              // _localuser["lastname"] = filteredbyemailandfirstnameonly[0].lastname;
              // _localuser["email"] = filteredbyemailandfirstnameonly[0].email;
              // _localuser["phone"] = filteredbyemailandfirstnameonly[0].phone;
              // _localuser["is_active"] = 1;
              // _localuser["matchingsource"] = "Contact Master";
              // _localuser["sortorder"] = sortorder;
              // _localuser["sortby"] = email;
              // _localuser["linkid"] = id;
              // console.log("_localuser1 ", _localuser);
              // // 👇️ check if array contains object
              // const isFound = _quickbooksusersList.some((element) => {
              //   if (element.id === _localuser.id) {
              //     return true;
              //   }
              //   return false;
              // });
              if (!_user.cmlinkid.includes(_localuser.id)) {
                _user["cmlinkid"].push(_localuser.id);
              }
            }

            //filterby emailand lastname
            const filteredbyemailandlastnameonly = _contactusersList.filter(
              (contactinfo) =>
                contactinfo?.email !== undefined &&
                contactinfo?.email !== null &&
                contactinfo?.email !== "" &&
                _user.email?.toLowerCase() ===
                  contactinfo?.email?.toLowerCase() &&
                _user.lastname?.toLowerCase() ===
                  contactinfo?.lastname?.toLowerCase()
            );
            if (filteredbyemailandlastnameonly.length > 0) {
              var _localuser = {};
              _localuser["id"] = filteredbyemailandlastnameonly[0]._id;
              // _localuser["firstname"] = filteredbyemailandlastnameonly[0].firstname;
              // _localuser["lastname"] = filteredbyemailandlastnameonly[0].lastname;
              // _localuser["email"] = filteredbyemailandlastnameonly[0].email;
              // _localuser["phone"] = filteredbyemailandlastnameonly[0].phone;
              // _localuser["is_active"] = 1;
              // _localuser["matchingsource"] = "Contact Master";
              // _localuser["sortorder"] = sortorder;
              // _localuser["sortby"] = email;
              // _localuser["linkid"] = id;
              // console.log("_localuser1 ", _localuser);
              // // 👇️ check if array contains object
              // const isFound = _quickbooksusersList.some((element) => {
              //   if (element.id === _localuser.id) {
              //     return true;
              //   }
              //   return false;
              // });
              if (!_user.cmlinkid.includes(_localuser.id)) {
                _user["cmlinkid"].push(_localuser.id);
              }
            }

            sortorder = sortorder + 1;
          }
        } catch (error) {
          console.log("Data:", JSON.stringify(quickbooksuserinfo));
          console.log("Error in quickbooksuserinfo forloop:", error);
        }
      }
      console.log("_contactusersList ", _contactusersList);

      try {
        // _quickbooksusersList.map((user) => {
        //   user.ischecked = true;
        // });

        //getting cp duplicate record
        var duplicaterecords = [];
        _quickbooksusersList.map((user) => {
          user.ischecked = false;
          user.isduplicate = false;
          if (duplicaterecords.includes(user.id)) {
            user.isduplicate = true;
          }
          _quickbooksuserslist.map((record) => {
            if (
              record.firstName === user.firstname &&
              record.lastName === user.lastname &&
              record.email === user.email &&
              record._id !== user.id
            ) {
              const _record = {
                id: record._id,
                contactrole: record.contactrole,
              };
              user.cplinkid.push(_record);
              duplicaterecords.push(record._id);
            }
          });
        });
        _quickbooksusersList = _quickbooksusersList.filter(
          (record) => record.isduplicate === false
        );
        console.log("_usersList", _quickbooksusersList);
        setUserList(sortJsonArray(_quickbooksusersList, "sortby"));
        //setUserList(_quickbooksusersList);
        console.log("_usersList", _quickbooksusersList);
        settotalRecord(localtotalRecord);
        setloadDialogOpen(false);
        if (localtotalRecord === 0) {
          setisMessage(true);
          setmsgInfo(
            appConstants.import.handlefieldvalidation.noeligiblerecord
          );
        }
      } catch (error) {
        setloadDialogOpen(false);
      }
    } catch (err) {
      setloadDialogOpen(false);
      setisMessage(true);
      setmsgInfo(JSON.stringify(err));
    }
  }
  const showTab = (tab) => {
    switch (tab) {
      case "quickbooksusers":
        Stequickbooksusers(true);
        setApproveWithoutverification(false);
        setSendverification(false);
        return;
      case "approvewithoutverification":
        Stequickbooksusers(false);
        setApproveWithoutverification(true);
        setSendverification(false);
        return;
      case "sendverification":
        Stequickbooksusers(false);
        setApproveWithoutverification(false);
        setSendverification(true);
        return;
      default:
        return;
    }
  };

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
  };

  const handleInputChange = (evnt, index) => {
    const { name, value, data } = evnt.target;
    let rowsInput = [...userlist_step2];
    rowsInput[index]["selectdropdownsource"] = value;
    setUserList_Step2(rowsInput);

    let with_verification = rowsInput.filter(
      (val) => val.selectdropdownsource === "2"
    );
    if (with_verification.length > 0) {
      setuserStepbutton3(false);
    } else {
      setuserStepbutton3(true);
    }
  };

  const handleHeaderInputChange = (evnt) => {
    const { name, value } = evnt.target;

    const rowsInput = [...userlist_step2];
    rowsInput.map((row, index) => {
      row["selectdropdownsource"] = value;
    });
    setHeaderInputAction(value);
    setUserList_Step2(rowsInput);

    let with_verification = rowsInput.filter(
      (val) => val.selectdropdownsource === "2"
    );
    if (with_verification.length > 0) {
      setuserStepbutton3(false);
    } else {
      setuserStepbutton3(true);
    }
  };
  const nextpage = () => {
    let _userlist = [...userlist];
    let _userlist_step2 = [...userlist_step2];

    Stepage((currPage) => currPage + 1);
    showTab(FormTitles[page + 1]);
    switch (page + 1) {
      case 1:
        const filtered1 = _userlist.filter((usr) => usr.ischecked === true);
        setUserList_Step2(filtered1);
        let filtered_2 = _userlist_step2.filter(
          (usr) => usr.selectdropdownsource === "2"
        );
        if (filtered_2.length > 0) {
          setuserStepbutton3(false);
        } else {
          setuserStepbutton3(true);
        }
        break;
      case 2:
        const filtered2 = _userlist_step2.filter(
          (usr) => usr.selectdropdownsource === "2"
        );

        setUserList_Step3(filtered2);
        break;
      default:
        break;
    }
  };
  const prevpage = () => {
    setuserStepbutton3(false);
    let _userlist = [...userlist];

    Stepage((currPage) => currPage - 1);
    showTab(FormTitles[page - 1]);
    switch (page - 1) {
      case 1:
        const filtered1 = _userlist.filter((usr) => usr.ischecked === true);
        setUserList_Step2(filtered1);
        break;
      default:
        break;
    }
  };
  const setVaildData = () => {
    let msg = [...userlist];

    let filterresult = msg.filter(
      (user) =>
        (user.ischecked === false && user.selectdropdownsource === undefined) ||
        user.selectdropdownsource === "0"
    );

    if (filterresult.length > 0) {
      setErrorMsg(appConstants.import.handlefieldrequiredvalidation.action);
      return false;
    } else {
      return true;
    }
  };

  async function approvewithoutverificationclick() {
    setloadDialogOpen(true);
    let _userlist_step2 = [...userlist_step2];
    let f_userlist_step2 = _userlist_step2.filter(
      (val) => val.selectdropdownsource === "1"
    );
    var pagemaxlimit = 25,
      totalcount = f_userlist_step2.length;
    var batchid = "",
      batch_coll_info = "",
      contact_task_list = "";
    let appapiInfo = {
      appname: "QuickBooks",
      companyid: selectedcompany,
      LoggedinBy: user.sub,
      signupurl: window.location.origin,
    };
    let _userinfo = {
      _id: userinfo._id,
      userid: userinfo.userid,
      firstName: userinfo.firstname,
      lastName: userinfo.lastname,
      email: userinfo.email,
    };
    if (totalcount >= pagemaxlimit) {
      for (let i = 0; i <= totalcount; i += pagemaxlimit) {
        let filter_indexuserlist = f_userlist_step2.slice(i, i + pagemaxlimit);
        batchid = Moment(new Date()).format("YYYYMMDDHHmmss");
        batchid = batchid + "_" + uuidv4().replace(/-/g, "");
        batch_coll_info = {
          status: "Pending",
          batchid: batchid,
          batchdate: new Date().toString(),
          sourceSystem: appapiInfo,
          userinfo: _userinfo,
        };
        contact_task_list = filter_indexuserlist?.map((row) => {
          return {
            apiinfo: appapiInfo,
            userinfo: _userinfo,
            contact_info: row,
            created_date: new Date(),
            status: "Pending",
            exception: "",
            verification: "",
            _p_contactID: "",
            _p_contactdetailID: "",
            batchid: batchid,
          };
        });

        await createImportContactTask(
          batch_coll_info,
          contact_task_list,
          ctechapp.currentUser
        );
      }
      setisApprovedMsg(true);
      setloadDialogOpen(false);
    } else {
      batchid = Moment(new Date()).format("YYYYMMDDHHmmss");
      batchid = batchid + "_" + uuidv4().replace(/-/g, "");
      batch_coll_info = {
        status: "Pending",
        batchid: batchid,
        batchdate: new Date().toString(),
        sourceSystem: appapiInfo,
        userinfo: _userinfo,
      };
      contact_task_list = f_userlist_step2.map((row) => {
        return {
          apiinfo: appapiInfo,
          userinfo: _userinfo,
          contact_info: row,
          created_date: new Date(),
          status: "Pending",
          exception: "",
          verification: "",
          _p_contactID: "",
          _p_contactdetailID: "",
          batchid: batchid,
        };
      });

      await createImportContactTask(
        batch_coll_info,
        contact_task_list,
        ctechapp.currentUser
      )
        .then((response) => {
          if (response != undefined) {
            setisApprovedMsg(true);
            setloadDialogOpen(false);
          }
        })
        .catch((err) => {
          console.log("error : " + err);
          setloadDialogOpen(false);
          setisMessage(true);
          setmsgInfo(err);
        });
    }
    let filter_userlist = [];
    _userlist_step2.map((row, index) => {
      if (row.selectdropdownsource !== "1") {
        filter_userlist.push(row);
      }
    });
    setUserList_Step2(filter_userlist);
  }

  async function approvewithverificationclick() {
    setloadDialogOpen(true);
    let _userlist_step3 = [...userlist_step2];
    let f_userlist_step3 = _userlist_step3.filter(
      (val) => val.selectdropdownsource === "2"
    );
    var pagemaxlimit = 25,
      totalcount = f_userlist_step3.length;
    var batchid = "",
      batch_coll_info = "",
      contact_task_list = "";
    let appapiInfo = {
      appname: "QuickBooks",
      companyid: selectedcompany,
      LoggedinBy: user.sub,
      signupurl: window.location.origin,
    };
    let _userinfo = {
      _id: userinfo._id,
      userid: userinfo.userid,
      firstName: userinfo.firstname,
      lastName: userinfo.lastname,
      email: userinfo.email,
    };
    if (totalcount >= pagemaxlimit) {
      for (let i = 0; i <= totalcount; i += pagemaxlimit) {
        let filter_indexuserlist = f_userlist_step3.slice(i, i + pagemaxlimit);
        batchid = Moment(new Date()).format("YYYYMMDDHHmmss");
        batchid = batchid + "_" + uuidv4().replace(/-/g, "");
        batch_coll_info = {
          status: "Pending",
          batchid: batchid,
          batchdate: new Date().toString(),
          sourceSystem: appapiInfo,
          userinfo: _userinfo,
        };
        contact_task_list = filter_indexuserlist?.map((row) => {
          return {
            apiinfo: appapiInfo,
            userinfo: _userinfo,
            contact_info: row,
            created_date: new Date(),
            status: "Pending",
            exception: "",
            verification: "",
            _p_contactID: "",
            _p_contactdetailID: "",
            batchid: batchid,
          };
        });

        await createImportContactTask(
          batch_coll_info,
          contact_task_list,
          ctechapp.currentUser
        );
      }
      setisApprovedMsg(true);
      setloadDialogOpen(false);
    } else {
      batchid = Moment(new Date()).format("YYYYMMDDHHmmss");
      batchid = batchid + "_" + uuidv4().replace(/-/g, "");
      batch_coll_info = {
        status: "Pending",
        batchid: batchid,
        batchdate: new Date().toString(),
        sourceSystem: appapiInfo,
        userinfo: _userinfo,
      };
      contact_task_list = f_userlist_step3.map((row) => {
        return {
          apiinfo: appapiInfo,
          userinfo: _userinfo,
          contact_info: row,
          created_date: new Date(),
          status: "Pending",
          exception: "",
          verification: "",
          _p_contactID: "",
          _p_contactdetailID: "",
          batchid: batchid,
        };
      });

      createImportContactTask(
        batch_coll_info,
        contact_task_list,
        ctechapp.currentUser
      )
        .then((response) => {
          if (response != undefined) {
            setisApproveWithNotificationMsg(true);
            setloadDialogOpen(false);
          }
        })
        .catch((err) => {
          console.log("error : " + err);
          setloadDialogOpen(false);
          setisMessage(true);
          setmsgInfo(err);
        });
    }
    let filter_userlist = [];
    _userlist_step3.map((row, index) => {
      if (row.selectdropdownsource === "1") {
        filter_userlist.push(row);
      }
    });
    setUserList_Step2(filter_userlist);
  }
  const handleMessage = () => {
    setisMessage(false);
    setmsgInfo("");
    setloadDialogOpen(false);
  };
  const handleRedirect = () => {
    history.push({
      pathname: "/dashboard",
    });
  };
  const handleClose = (e, type) => {
    switch (type) {
      case "dedup":
        setDedupData([]);
        setIsDedup(false);
        break;

      default:
        break;
    }
  };
  const handleDedupPersonalEmailArrayChange = (e, rowdata) => {
    const { name, checked } = e.target;
    let finalresult = [...dedupData];
    var _dedubData = { ...finalresult[0] };

    if (checked) {
      let filterduplicate = _dedubData[name].findIndex(
        (val) => val.email === rowdata.email
      );
      if (filterduplicate !== -1) {
        e.target.checked = false;
        setDuplicateMsg("Email info already exists");
        setIsDuplicate(true);
        return;
      }
      let filterindex = _dedubData[name].findIndex((val) => val.active === 0);

      if (filterindex !== -1) {
        _dedubData = produce(_dedubData, (draft) => {
          draft[name][filterindex] = { ...rowdata };
        });
        //  _dedubData[name][filterindex] = rowdata;
      } else {
        _dedubData = produce(_dedubData, (draft) => {
          draft[name].push(rowdata);
        });
        //  _dedubData[name].push(rowdata);
      }
    } else {
      let filterindex = _dedubData[name].findIndex(
        (val) => val.id === rowdata.id
      );
      if (filterindex !== -1) {
        _dedubData = produce(_dedubData, (draft) => {
          draft[name][filterindex] = { active: 0 };
        });
        // _dedubData[name][filterindex] = { active: 0 };
      }
    }
    finalresult[0] = _dedubData;
    // console.log("finalresult", finalresult);
    setDedupData(finalresult);
  };

  const handleDedupPersonalPhoneArrayChange = (e, rowdata) => {
    const { name, checked } = e.target;
    let finalresult = [...dedupData];
    var _dedubData = { ...finalresult[0] };

    if (checked) {
      let filterduplicate = _dedubData[name].findIndex(
        (val) => val.fullPhone === rowdata.fullPhone
      );
      if (filterduplicate !== -1) {
        e.target.checked = false;
        setDuplicateMsg("Phone info already exists");
        setIsDuplicate(true);
        return;
      }
      let filterindex = _dedubData[name].findIndex((val) => val.active === 0);

      if (filterindex !== -1) {
        _dedubData = produce(_dedubData, (draft) => {
          draft[name][filterindex] = { ...rowdata };
        });
        //  _dedubData[name][filterindex] = rowdata;
      } else {
        _dedubData = produce(_dedubData, (draft) => {
          draft[name].push(rowdata);
        });
        //  _dedubData[name].push(rowdata);
      }
    } else {
      let filterindex = _dedubData[name].findIndex(
        (val) => val.id === rowdata.id
      );
      if (filterindex !== -1) {
        _dedubData = produce(_dedubData, (draft) => {
          draft[name][filterindex] = { active: 0 };
        });
        // _dedubData[name][filterindex] = { active: 0 };
      }
    }
    finalresult[0] = _dedubData;
    // console.log("finalresult", finalresult);
    setDedupData(finalresult);
  };
  const handleDedupPersonalAddressArrayChange = (e, rowdata) => {
    const { name, checked } = e.target;
    let finalresult = [...dedupData];
    var _dedubData = { ...finalresult[0] };

    if (checked) {
      let filterduplicate = _dedubData[name].findIndex(
        (val) =>
          val.addressLine1 === rowdata.addressLine1 &&
          val.addressLine2 === rowdata.addressLine2 &&
          val.city === rowdata.city
      );
      if (filterduplicate !== -1) {
        e.target.checked = false;
        setDuplicateMsg("Address info already exists");
        setIsDuplicate(true);
        return;
      }
      let filterindex = _dedubData[name].findIndex((val) => val.active === 0);

      if (filterindex !== -1) {
        _dedubData = produce(_dedubData, (draft) => {
          draft[name][filterindex] = { ...rowdata };
        });
        //  _dedubData[name][filterindex] = rowdata;
      } else {
        _dedubData = produce(_dedubData, (draft) => {
          draft[name].push(rowdata);
        });
        //  _dedubData[name].push(rowdata);
      }
    } else {
      let filterindex = _dedubData[name].findIndex(
        (val) => val.id === rowdata.id
      );
      if (filterindex !== -1) {
        _dedubData = produce(_dedubData, (draft) => {
          draft[name][filterindex] = { active: 0 };
        });
        // _dedubData[name][filterindex] = { active: 0 };
      }
    }
    finalresult[0] = _dedubData;
    // console.log("finalresult", finalresult);
    setDedupData(finalresult);
  };

  const handleDedupArrayChange = (e, rowdata) => {
    const { name, checked } = e.target;
    let finalresult = [...dedupData];
    var _dedupData = { ...finalresult[0] };

    if (checked) {
      let filterduplicate = _dedupData[name].findIndex(
        (val) => val.id === rowdata.id
      );
      if (filterduplicate !== -1) {
        e.target.checked = false;
        setDuplicateMsg("Affiliation info already exists");
        setIsDuplicate(true);
        return;
      }
      let filterindex = _dedupData[name].findIndex((val) => val.active === 0);

      if (filterindex !== -1) {
        _dedupData = produce(_dedupData, (draft) => {
          draft[name][filterindex] = { ...rowdata };
        });
        //  _dedupData[name][filterindex] = rowdata;
      } else {
        _dedupData = produce(_dedupData, (draft) => {
          draft[name].push(rowdata);
        });
        //  _dedupData[name].push(rowdata);
      }
    } else {
      let filterindex = _dedupData[name].findIndex(
        (val) => val.id === rowdata.id
      );
      if (filterindex !== -1) {
        _dedupData = produce(_dedupData, (draft) => {
          draft[name][filterindex] = { active: 0 };
        });
        // _dedupData[name][filterindex] = { active: 0 };
      }
    }
    finalresult[0] = _dedupData;
    // console.log("finalresult", finalresult);
    setDedupData(finalresult);
  };

  const submitDedupHandler = (type) => {
    var _dedupData = { ...dedupData[0] };
    let _sourceSystem = [];
    _dedupData.contactAffiliationsArray?.map((val, valindex) => {
      if (val.sourceSystem) {
        _sourceSystem.push(val.sourceSystem);
      }
    });
    //console.log("_sourceSystem", _sourceSystem);
    _dedupData = produce(_dedupData, (draft) => {
      draft.sourceSystem = _sourceSystem;
      draft.selectdropdownsource = type === "w/o" ? "1" : "2";
    });
    const _aff = _dedupData?.contactAffiliationsArray;
    if (_aff && _aff.length > 0) {
      let _aff_temp = [];
      for (let i = 0; i < _aff.length; i++) {
        if (_aff[i]?.active === undefined) {
          let aff_temp = _aff[i];
          let _aff_emailArr = aff_temp.emailArray;
          if (_aff_emailArr && _aff_emailArr.length > 0) {
            let _emailArr_temp = [];
            for (let i = 0; i < _aff_emailArr.length; i++) {
              if (_aff_emailArr[i]?.active === undefined) {
                _emailArr_temp.push(_aff_emailArr[i]);
              }
            }

            aff_temp = produce(aff_temp, (draft) => {
              draft.emailArray = _emailArr_temp;
            });
          }

          let _aff_phoneArr = aff_temp.phoneArray;
          if (_aff_phoneArr && _aff_phoneArr.length > 0) {
            let _phoneArr_temp = [];
            for (let i = 0; i < _aff_phoneArr.length; i++) {
              if (_aff_phoneArr[i]?.active === undefined) {
                _phoneArr_temp.push(_aff_phoneArr[i]);
              }
            }

            aff_temp = produce(aff_temp, (draft) => {
              draft.phoneArray = _phoneArr_temp;
            });
          }
          let _aff_addressArr = aff_temp.addressArray;
          if (_aff_addressArr && _aff_addressArr.length > 0) {
            let _addressArr_temp = [];
            for (let i = 0; i < _aff_addressArr.length; i++) {
              if (_aff_addressArr[i]?.active === undefined) {
                _addressArr_temp.push(_aff_addressArr[i]);
              }
            }
            aff_temp = produce(aff_temp, (draft) => {
              draft.addressArray = _addressArr_temp;
            });
          }

          _aff_temp.push(aff_temp);
        }
      }

      _dedupData = produce(_dedupData, (draft) => {
        draft.contactAffiliationsArray = _aff_temp;
      });
    }

    _dedupData?.contactAffiliationsArray?.map((val, valindex) => {
      if (val.active === 0) {
        _dedupData = produce(_dedupData, (draft) => {
          draft.contactAffiliationsArray.splice(valindex, 1);
        });
      }
    });
    const _emailArray = _dedupData?.emailArray;
    if (_emailArray && _emailArray.length > 0) {
      let _emailArr_temp = [];
      for (let i = 0; i < _emailArray.length; i++) {
        if (_emailArray[i]?.active === undefined) {
          _emailArr_temp.push(_emailArray[i]);
        }
      }

      _dedupData = produce(_dedupData, (draft) => {
        draft.emailArray = _emailArr_temp;
      });
    }

    const _phoneArray = _dedupData?.phoneArray;
    if (_phoneArray && _phoneArray.length > 0) {
      let _phoneArr_temp = [];
      for (let i = 0; i < _phoneArray.length; i++) {
        if (_phoneArray[i]?.active === undefined) {
          _phoneArr_temp.push(_phoneArray[i]);
        }
      }

      _dedupData = produce(_dedupData, (draft) => {
        draft.phoneArray = _phoneArr_temp;
      });
    }
    const _addressArray = _dedupData?.addressArray;
    if (_addressArray && _addressArray.length > 0) {
      let _addressArr_temp = [];
      for (let i = 0; i < _addressArray.length; i++) {
        if (_addressArray[i]?.active === undefined) {
          _addressArr_temp.push(_addressArray[i]);
        }
      }
      _dedupData = produce(_dedupData, (draft) => {
        draft.addressArray = _addressArr_temp;
      });
    }

    let appapiInfo = {
      appname: "QuickBooks",
      companyid: selectedcompany,
      LoggedinBy: user.sub,
      signupurl: window.location.origin,
    };
    let _userinfo = {
      _id: userinfo._id,
      userid: userinfo.userid,
      firstName: userinfo.firstname,
      lastName: userinfo.lastname,
      email: userinfo.email,
    };
    let batchid = Moment(new Date()).format("YYYYMMDDHHmmss");
    batchid = batchid + "_" + uuidv4().replace(/-/g, "");
    let batch_coll_info = {
      status: "Pending",
      batchid: batchid,
      batchdate: new Date().toString(),
      sourceSystem: appapiInfo,
      userinfo: _userinfo,
    };
    let contact_task_list = {
      apiinfo: appapiInfo,
      userinfo: _userinfo,
      contact_info: _dedupData,
      created_date: new Date(),
      importtype: "dedup",
      status: "Pending",
      exception: "",
      verification: "",
      _p_contactID: _dedupData?._p_contactID || "",
      _p_contactdetailID: _dedupData?._id?.toString() || "",
      batchid: batchid,
    };
    console.log("_dedupData", _dedupData);
    createImportContactTask(
      batch_coll_info,
      [contact_task_list],
      ctechapp.currentUser
    ).then((response) => {
      if (response !== undefined) {
        setIsDedupeRes(true);

        let _userlist = [...userlist];
        _userlist.splice(dedupDataIndex, 1);
        setUserList(_userlist);
      }
    });
  };

  return (
    <div className="tab-content div-0 p-0 m-0">
      <PageSubHeader title={"Quickbooks import wizard"} />

      <div className="d-flex justify-content-start align-items-center  row ">
        {companylist.length > 1 && (
          <div className="d-flex">
            <div className="col-sm-12 col-md-2 col-lg-2">
              <label className="">Account display name:</label>
            </div>
            <div className="col-sm-12 col-md-2 col-lg-3">
              <Select
                name="company_ID"
                id="company_ID"
                styles={customstyles}
                isDisabled={
                  tabApproveWithoutverification || tabSendverification
                }
                value={companylist.filter(
                  (val) => val.value === selectedcompany
                )}
                className="form-control"
                options={companylist}
                onChange={(val) => {
                  handleCompanyChange({
                    target: {
                      name: "company_ID",
                      value: val.value,
                    },
                  });
                }}
              ></Select>
            </div>
          </div>
        )}
      </div>
      <div
        className={
          "tab-pane fade " + (tabQuickbooksusers ? "show active " : "")
        }
      >
        <div>
          <div className=" stepWrapper justify-content-around">
            <div
              className={
                "stepBlock  " + (tabQuickbooksusers ? "selected " : "")
              }
            >
              <div className="circleWrapper">
                <div className="circle d-flex align-item-center justify-content-center">
                  1
                </div>
              </div>
              <span className="d-flex justify-content-center">
                Select contact
              </span>
            </div>

            <div
              className={
                "stepBlock " + (tabApproveWithoutverification ? "active " : "")
              }
            >
              <div className="circleWrapper d-flex align-item-center justify-content-center ">
                <div className="circle d-flex align-item-center justify-content-center">
                  2
                </div>
              </div>
              <span className="d-flex justify-content-center">
                Approve w/o verification or send verification
              </span>
            </div>
          </div>

          <label for="error1">{errordisplay}</label>
          <GenericListTable
            gcolumns={selectcontact_appColumns}
            gdata={
              selectcontact_data.length > 0
                ? JSON.stringify(selectcontact_data)
                : ""
            }
            ghiddencolumns={hiddenselectcontactappColumns}
            gplaceholder={"Search import contacts.."}
            gsearchDisabled={false}
            filterDisabled={false}
          />
        </div>
      </div>
      <div
        className={
          "tab-pane fade " +
          (tabApproveWithoutverification ? "show active " : "")
        }
      >
        <div className="col-xl-12">
          <div className="stepWrapper  justify-content-around">
            <div
              className={
                "stepBlock " +
                (tabApproveWithoutverification ? "selected " : "")
              }
            >
              <div className="circleWrapper">
                <div className="circle d-flex align-item-center justify-content-center">
                  1
                </div>
              </div>
              <span className="me-5 d-flex justify-content-center">
                Select contact
              </span>
            </div>
            <div
              className={
                "stepBlock " +
                (tabApproveWithoutverification ? "selected " : "")
              }
            >
              <div className="circleWrapper d-flex align-item-center justify-content-center">
                <div className="circle d-flex align-item-center justify-content-center">
                  2
                </div>
              </div>
              <span className="me-5">
                Approve w/o verification or send verification
              </span>
            </div>
          </div>

          <label for="error1">{errordisplay}</label>
          <GenericListTable
            gcolumns={approvewithoutverification_appColumns}
            gdata={
              approvewithoutverification_data.length > 0
                ? JSON.stringify(approvewithoutverification_data)
                : ""
            }
            ghiddencolumns={hiddenapprovewithoutverificationappColumns}
            gplaceholder={"Search import contacts.."}
            gsearchDisabled={false}
            filterDisabled={false}
          />
        </div>
      </div>
      <hr></hr>
      <div className="footer m-3  d-flex justify-content-between">
        <div>
          {tabApproveWithoutverification && (
            <div>
              <button
                className="btn btn-sm me-2"
                disabled={page == 0}
                onClick={(e) => {
                  prevpage();
                }}
              >
                {"<< Goto select contact"}
              </button>
            </div>
          )}
          {tabQuickbooksusers && (
            <div>
              <button
                className="btn btn-sm"
                hidden={userStepbutton3}
                disabled={
                  page === FormTitles.length - 1
                    ? true
                    : false ||
                      userlist.filter((val) => val.ischecked === true).length >
                        0
                    ? false
                    : true
                }
                onClick={(e) => {
                  nextpage();
                }}
              >
                {"Goto review contact >>"}
              </button>
            </div>
          )}
        </div>
        <div>
          {tabApproveWithoutverification && (
            <div>
              <button
                className="btn btn-sm me-2 "
                disabled={
                  userlist_step2.filter(
                    (val) => val.selectdropdownsource === "1"
                  ).length > 0
                    ? false
                    : true
                }
                onClick={(e) => approvewithoutverificationclick(e)}
              >
                {"Approve without verification"}
              </button>

              <button
                className="btn btn-sm me-2"
                disabled={
                  userlist_step2.filter(
                    (val) => val.selectdropdownsource === "2"
                  ).length > 0
                    ? false
                    : true
                }
                onClick={(e) => approvewithverificationclick(e)}
              >
                {"Send verification"}
              </button>
            </div>
          )}
        </div>
      </div>
      {errorMsg !== "" && (
        <span className="text-danger d-flex justify-content-end">
          {errorMsg}
        </span>
      )}
      <LoadingDialog
        isOpen={loadDialogOpen}
        onClose={() => setloadDialogOpen(false)}
      />
      {isApprovedMsg && (
        <SweetAlert
          success
          style={{ color: "black" }}
          title={appConstants.import.quickbooks.create}
          onConfirm={(e) => {
            setisApprovedMsg(false);
            if (
              userlist_step2.filter((val) => val.selectdropdownsource === "2")
                .length === 0
            ) {
              handleRedirect();
            }
          }}
        ></SweetAlert>
      )}
      {isDedup && (
        <Modal
          scrollable={true}
          show={isDedup}
          onHide={(e) => {
            handleClose(e, "dedup");
          }}
          size="xl"
          centered
        >
          <Modal.Header className="fw-bold h4">
            Matched record
            <a
              style={{ "text-decoration": "none" }}
              onClick={(e) => {
                handleClose(e, "dedup");
              }}
              className="col d-flex justify-content-end text-dark"
            >
              <i class="fa-solid fa-xmark"></i>
            </a>
          </Modal.Header>
          <Modal.Body>
            <div class="container">
              <div className="row">
                <div className="col-3 border fw-bold">
                  <div className="row border"># Field name</div>
                  <div className="border-bottom">First name</div>
                  <div className="border-bottom">Last name</div>
                  <div className="border-bottom">Suffix</div>
                  <div className="border-bottom">Nick name</div>
                  <div className="border-bottom">Main email</div>
                  <div className="border-bottom">Main phone</div>
                  <div className="">
                    {[...Array(structureTable.personal_email_count)]?.map(
                      (elementInArray, index) => {
                        return (
                          <div className="border-bottom">
                            Email {Number(index) + 1}{" "}
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div className="">
                    {[...Array(structureTable.personal_phone_count)]?.map(
                      (elementInArray, index) => {
                        return (
                          <div className="border-bottom">
                            Phone {Number(index) + 1}{" "}
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div className="">
                    {[...Array(structureTable.personal_address_count)]?.map(
                      (elementInArray, index) => {
                        return (
                          <div className="border-bottom addressHeight">
                            Address {Number(index) + 1}{" "}
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div className="">
                    {[...Array(structureTable.aff_count)]?.map(
                      (elementInArray, affindex) => {
                        return (
                          <div className="">
                            <div className="border-bottom">
                              Company {Number(affindex) + 1} name{" "}
                            </div>
                            <div className="">
                              {[
                                ...Array(structureTable.business_email_count),
                              ]?.map((elementInArray, index) => {
                                return (
                                  <div className="border-bottom">
                                    {" "}
                                    Company {Number(affindex) + 1} email{" "}
                                    {Number(index) + 1}{" "}
                                  </div>
                                );
                              })}
                            </div>
                            <div className="">
                              {[
                                ...Array(structureTable.business_phone_count),
                              ]?.map((elementInArray, index) => {
                                return (
                                  <div className="border-bottom">
                                    Company {Number(affindex) + 1} phone{" "}
                                    {Number(index) + 1}{" "}
                                  </div>
                                );
                              })}
                            </div>
                            <div className="">
                              {[
                                ...Array(structureTable.business_address_count),
                              ]?.map((elementInArray, index) => {
                                return (
                                  <div className="border-bottom addressHeight">
                                    Company {Number(affindex) + 1} address{" "}
                                    {Number(index) + 1}{" "}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>

                {dedupData.length > 0 &&
                  dedupData?.map((row, colindex) => {
                    return (
                      <div className="col-3 border">
                        <div className="row border fw-bold">
                          {colindex === 0
                            ? "Surviving data(CM)"
                            : "Record " + colindex}
                        </div>
                        <div className="border-bottom">
                          {colindex !== 0 && row.firstname && (
                            <input
                              type="checkbox"
                              className="form-check-input mt-1 me-1"
                              name="firstname"
                              value={row.firstname}
                              //  checked={row.firstname}
                              onChange={(e) => {
                                handleDedupChange(e);
                              }}
                            />
                          )}
                          {row.firstname || <br />}
                        </div>
                        <div className="border-bottom">
                          {colindex !== 0 && row.lastname && (
                            <input
                              type="checkbox"
                              className="form-check-input mt-1 me-1"
                              name="lastname"
                              value={row.lastname}
                              //  checked={row.lastname}
                              onChange={(e) => {
                                handleDedupChange(e);
                              }}
                            />
                          )}
                          {row.lastname || <br />}
                        </div>
                        <div className="text-nowrap border-bottom ">
                          {colindex !== 0 && row.suffix && (
                            <input
                              type="checkbox"
                              className="form-check-input mt-1 me-1"
                              name="suffix"
                              value={row.suffix}
                              //  checked={row.email}
                              onChange={(e) => {
                                handleDedupChange(e);
                              }}
                            />
                          )}
                          {row.suffix || <br />}
                        </div>
                        <div className="text-nowrap border-bottom ">
                          {colindex !== 0 && row.nickName && (
                            <input
                              type="checkbox"
                              className="form-check-input mt-1 me-1"
                              name="nickName"
                              value={row.nickName}
                              //  checked={row.email}
                              onChange={(e) => {
                                handleDedupChange(e);
                              }}
                            />
                          )}
                          {row.nickName || <br />}
                        </div>
                        <div className="text-nowrap border-bottom ">
                          {colindex !== 0 && row.email && (
                            <input
                              type="checkbox"
                              className="form-check-input mt-1 me-1"
                              name="email"
                              value={row.email}
                              //  checked={row.email}
                              onChange={(e) => {
                                handleDedupChange(e);
                              }}
                            />
                          )}
                          {row.email || <br />}
                        </div>
                        <div className="text-nowrap border-bottom">
                          {colindex !== 0 && row.phone && (
                            <input
                              type="checkbox"
                              className="form-check-input mt-1 me-1"
                              name="phone"
                              value={row.phone}
                              // checked={row.phone}
                              onChange={(e) => {
                                handleDedupChange(e);
                              }}
                            />
                          )}
                          {row.phone || <br />}
                        </div>
                        <div className="text-nowrap ">
                          {row.emailArray &&
                            row.emailArray.length > 0 &&
                            row.emailArray?.map((row, rowindex) => {
                              return (
                                <div className="border-bottom">
                                  {colindex !== 0 && row.email && (
                                    <input
                                      type="checkbox"
                                      className="form-check-input mt-1 me-1"
                                      name="emailArray"
                                      value={row}
                                      // checked={row.email}
                                      onChange={(e) =>
                                        handleDedupPersonalEmailArrayChange(
                                          e,
                                          row
                                        )
                                      }
                                    />
                                  )}
                                  {row.email || <br />}
                                </div>
                              );
                            })}
                        </div>
                        <div className="text-nowrap ">
                          {row.phoneArray &&
                            row.phoneArray.length > 0 &&
                            row.phoneArray?.map((row, rowindex) => {
                              return (
                                <div className="border-bottom">
                                  {colindex !== 0 && row.fullPhone && (
                                    <input
                                      type="checkbox"
                                      className="form-check-input mt-1 me-1"
                                      name="phoneArray"
                                      value={row}
                                      //  checked={row.fullPhone}
                                      onChange={(e) => {
                                        handleDedupPersonalPhoneArrayChange(
                                          e,
                                          row
                                        );
                                      }}
                                    />
                                  )}
                                  {row.fullPhone || <br />}
                                </div>
                              );
                            })}
                        </div>
                        <div className="text-nowrap ">
                          {row.addressArray &&
                            row.addressArray.length > 0 &&
                            row.addressArray?.map((row, rowindex) => {
                              return (
                                <div className="text-wrap border-bottom  addressHeight">
                                  {colindex !== 0 &&
                                    row.addressLine1 &&
                                    row.addressLine1 && (
                                      <input
                                        type="checkbox"
                                        className="form-check-input mt-1 me-1"
                                        name="addressArray"
                                        value={row.addressLine1}
                                        //  checked={row.addressLine1}
                                        onChange={(e) => {
                                          handleDedupPersonalAddressArrayChange(
                                            e,
                                            row
                                          );
                                        }}
                                      />
                                    )}
                                  {row.addressLine1 && row.addressLine1 + ","}
                                  {row.addressLine2 && row.addressLine2 + ","}
                                  {row.city && row.city + ","}
                                  {row.state && row.state + ","}{" "}
                                  {row.country && row.country + ","}
                                  {(row.postalCode && row.postalCode + ",") || (
                                    <br />
                                  )}
                                </div>
                              );
                            })}
                        </div>
                        <div className="text-nowrap ">
                          {row.contactAffiliationsArray &&
                            row.contactAffiliationsArray.length > 0 &&
                            row.contactAffiliationsArray?.map(
                              (rowaff, rowindex) => {
                                return (
                                  <div>
                                    <div className="text-nowrap border-bottom">
                                      {colindex !== 0 && rowaff.companyName && (
                                        <input
                                          type="checkbox"
                                          className="form-check-input mt-1 me-1"
                                          name="contactAffiliationsArray"
                                          value={rowaff.companyName}
                                          // checked={row.phone}
                                          onChange={(e) => {
                                            let _row = { ...rowaff };
                                            console.log("_row", _row);
                                            let sourceSystem = {
                                              appuserid: row?._id
                                                ? row?._id || ""
                                                : _row.sourceSystem.appuserid ||
                                                  "",
                                              appname: "QuickBooks",
                                              clientid: "",
                                              companyid: selectedcompany,
                                              contact_category:
                                                _row.contactrole,
                                              imported_on: new Date(),
                                              contactrole: "",
                                              status: "Created",
                                            };
                                            _row.sourceSystem = sourceSystem;
                                            delete _row.active;
                                            handleDedupArrayChange(e, _row);
                                          }}
                                        />
                                      )}
                                      {rowaff.companyName || <br />}
                                    </div>
                                    <div className="text-nowrap ">
                                      {rowaff.emailArray &&
                                        rowaff.emailArray.length > 0 &&
                                        rowaff.emailArray?.map(
                                          (row, rowindex) => {
                                            return (
                                              <div className="border-bottom">
                                                {/* {colindex !== 0 && row.email && (
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input mt-1 me-1"
                                                  name="emailArray"
                                                  value={row}
                                                  // checked={row.email}
                                                  onChange={(e) =>
                                                    handleDedupPersonalArrayChange(
                                                      e,
                                                      row
                                                    )
                                                  }
                                                />
                                              )} */}
                                                {row.email || <br />}
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                    <div className="text-nowrap ">
                                      {rowaff.phoneArray &&
                                        rowaff.phoneArray.length > 0 &&
                                        rowaff.phoneArray?.map(
                                          (row, rowindex) => {
                                            return (
                                              <div className="border-bottom">
                                                {/* {colindex !== 0 &&
                                                row.fullPhone && (
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input mt-1 me-1"
                                                    name="phoneArray"
                                                    value={row}
                                                    //  checked={row.fullPhone}
                                                    onChange={(e) => {
                                                      handleDedupPersonalArrayChange(
                                                        e,
                                                        row
                                                      );
                                                    }}
                                                  />
                                                )} */}
                                                {row.fullPhone || <br />}
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                    <div className="text-nowrap ">
                                      {rowaff.addressArray &&
                                        rowaff.addressArray.length > 0 &&
                                        rowaff.addressArray?.map(
                                          (row, rowindex) => {
                                            return (
                                              <div className="text-wrap border-bottom  addressHeight">
                                                {/* {colindex !== 0 &&
                                                  row.addressLine1 &&
                                                  row.addressLine1 && (
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input mt-1 me-1"
                                                      name="addressArray"
                                                      value={row.addressLine1}
                                                      //  checked={row.addressLine1}
                                                      onChange={(e) => {
                                                        handleDedupPersonalArrayChange(
                                                          e,
                                                          row
                                                        );
                                                      }}
                                                    />
                                                  )} */}
                                                {row.addressLine1 &&
                                                  row.addressLine1 + ","}
                                                {row.addressLine2 &&
                                                  row.addressLine2 + ","}
                                                {row.city && row.city + ","}
                                                {row.state &&
                                                  row.state + ","}{" "}
                                                {row.country &&
                                                  row.country + ","}
                                                {(row.postalCode &&
                                                  row.postalCode + ",") || (
                                                  <br />
                                                )}
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {" "}
            <div className="row div-1">
              {" "}
              <div className=" d-flex justify-content-end mt-3 col-sm-12 col-md-12 col-lg-12">
                <button
                  className=" btn btn-primary me-2 "
                  onClick={(e) => {
                    handleClose(e, "dedup");
                  }}
                >
                  Close
                </button>
                <button
                  className=" btn btn-primary me-2 "
                  onClick={() => submitDedupHandler("w/o")}
                >
                  Approve w/o verification
                </button>
                <button
                  className=" btn btn-primary me-2 "
                  onClick={() => submitDedupHandler("with")}
                >
                  Send verification
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {isApproveWithNotificationMsg && (
        <SweetAlert
          success
          style={{ color: "black" }}
          title={appConstants.import.quickbooks.create}
          onConfirm={(e) => {
            setisApproveWithNotificationMsg(false);

            if (
              userlist_step2.filter((val) => val.selectdropdownsource === "1")
                .length === 0
            ) {
              handleRedirect();
            }
          }}
        ></SweetAlert>
      )}
      <div className="div-1">
        {isMessage && (
          <SweetAlert
            info
            style={{ color: "black" }}
            title={msgTitle}
            onConfirm={handleMessage}
          >
            {msgInfo}
          </SweetAlert>
        )}
        {isDuplicate && (
          <SweetAlert
            info
            style={{ color: "black" }}
            title={msgTitle}
            onConfirm={() => setIsDuplicate(false)}
          >
            {duplicateMsg}
          </SweetAlert>
        )}
        {isDedupRes && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title={msgTitle}
            onConfirm={() => {
              setIsDedupeRes(false);
              setIsDedup(false);
            }}
          >
            {appConstants.import.quickbooks.dedup}
          </SweetAlert>
        )}
      </div>
    </div>
  );
}

export default QuickbooksImportWizard;
