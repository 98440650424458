//services for getProcorecontactlistbycompanyid
export const getImportEntraIDUserListByCompanyID = async (
  arg,
  Ctechapp,
  loggedinby
) => {
  try {
    const data = await Ctechapp.functions.getImportEntraIDUserListByCompanyID(
      arg,
      loggedinby
    );
    if (data !== undefined) {
      // console.log("status: success");
      return data;
    }
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

//services for getentraidcompanylistbycompanyid
export const getentraidcompanylistbycompanyid = async (
  arg,
  Ctechapp,
  loggedinby
) => {
  try {
    const data = await Ctechapp.functions.getentraidcompanylistbycompanyid(
      arg,
      loggedinby
    );
    if (data !== undefined) {
      // console.log("status: success");
      return data;
    }
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};



export const getEntraIDDedupByIDs = async (
  arg,
  entraApptokenInfo,
  Ctechapp
) => {
  try {
    const data = await Ctechapp.functions.getEntraIDDedupByIDs(
      arg,
      entraApptokenInfo,
      Ctechapp.id
    );
    return data;
  } catch (error) {
    console.log("getEntraIDDedupByIDs error : ", error);
    throw Error(error);
  }
};
