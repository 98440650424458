import { useEffect, useState, useMemo } from "react";
import { useRealmApp } from "../../providers/RealmApp";
import { Button, Modal } from "react-bootstrap";
import {
  getapplistbyuserid,
  deleteAppByuserID,
} from "../../services/applications_services";
import { useAuth0 } from "@auth0/auth0-react";
import { isEmail } from "validator";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  getuserbyauthproviderid,
  getChargebeeSubscriptionIDByEmail,
  get_mongodb_values,
} from "../../services/users_services";
import GenericLlistTableActive from "../../components/gridTable/genericLlistTableActive";
import { createImportContactTask } from "../../services/import_contact_task_services";
import { useHistory } from "react-router";
import Moment from "moment";
import { appConstants } from "../../constants/app_constants";
import LoadingDialog from "../../components/LoadingDialog";
function AppDashboard() {
  const history = useHistory();
  const [list, setList] = useState([]);
  const [isDeleteApp, setIsDeleteApp] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [rowToDelete, setRowToDelete] = useState();
  const [disablePagination, setDisablePagination] = useState(true);
  const [addApp, setaddApp] = useState([]);
  const [inEligibleContactList, setInEligibleContactList] = useState([]);
  const [isInEligibleModal, setIsInEligibleModal] = useState(false);
  const [isReload, setisReload] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [LOBInfo, setLOBInfo] = useState("");
  const [subscriptionID, setSubscriptionID] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [loadDialogOpen, setloadDialogOpen] = useState(false);
  const [isChargebeeAppModal, setIsChargebeeAppModal] = useState(false);
  const [mongodbvalues, setMongodbvalues] = useState({});
  const [chargebeeErrrorMessage, setChargebeeErrrorMessage] = useState("");
  const [inEligibleModalHeaderName, setInEligibleModalHeaderName] =
    useState("");
  const ctechapp = useRealmApp();
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  const msgTite = "Contact Sync";
  const PC_checkoutType = "Procore-USD-Yearly";
  const QB_checkoutType = "Quickbooks-USD-Yearly";
  const CP_checkoutType = "CP-Pro-USD-Yearly";
  const MB_checkoutType = "The-Master-Bundle";
  var redirectUrl = window.location.origin;

  const redirectURLmap = new Map()
    .set(
      "CP-Pro-USD-Yearly",
      redirectUrl + "/connection/commandpost/setupexisting"
    )
    .set(
      "Quickbooks-USD-Yearly",
      redirectUrl + "/connection/quickbooks/setupexisting"
    )
    .set(
      "Procore-USD-Yearly",
      redirectUrl + "/connection/procore/setupexisting"
    );
  const [msgContent, setmsgContent] = useState(
    "App connection was changed and reloading the page. "
  );
  const [userinfo, setUserinfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    userid: "",
    appid: "",
    action: "",
    auth_sub: "",
  });

  const initialContactStateErros = {
    firstname: "",
    lastname: "",
    email: "",
  };
  const [contactErrors, setContactErrors] = useState(initialContactStateErros);
  //state variables

  //table functions
  const data = useMemo(() => [...list], [list]);
  const appColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },
      {
        Header: "GroupID",
        accessor: "appuserinfo[0].groupid",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },
      {
        Header: () => (
          <div className="text-white" width="32px">
            Icon
          </div>
        ),
        accessor: "appfavlogourl",
        sortable: false,
        Filter: "",
        filter: "",
        Cell: ({ value }) => {
          return (
            <div className="text-center">
              <img
                width="32px"
                height="32px"
                src={!value ? "../assets/img/useraltimg.png" : value}
                className=""
              />
            </div>
          );
        },
      },

      {
        Header: "App display name",
        accessor: "appdisplayname",
        sortable: true,

        Filter: "",
        filter: "",

        width: "30%",
        Cell: ({ row }) => <div>{row?.original?.appdisplayname}</div>,
      },
      {
        Header: "Relationship ",
        accessor: "apprelationship",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
        Cell: ({ row }) => (
          <>
            <div>{row?.original?.apprelationship}</div>
          </>
        ),
      },
      {
        Header: () => <div className="text-center">Total contacts</div>,
        accessor: "totalcontacts",
        sortable: true,
        width: "30%",
        Cell: ({ row }) => (
          <>
            <div className="fw-bolder text-center" style={{ color: "#2ab514" }}>
              {row?.original?.totalcontacts}
            </div>
          </>
        ),
      },
      {
        Header: () => <div className="text-center">Eligible (Ineligible)</div>,
        // Header: "Eligible (Ineligible)",
        accessor: "eligible",
        sortable: true,
        width: "30%",
        Cell: ({ row }) => (
          <div className="fw-bolder text-center">
            <span
              style={
                row?.original?.eligiblecontacts.toString() !== "0"
                  ? { color: "#b58b1d" }
                  : { color: "#2ab514" }
              }
              onClick={(e) => {
                if (row?.original?.eligiblecontacts.toString() !== "0") {
                  handleEligibleContact(
                    e,
                    row.original.appname,
                    row.original.appaccountid
                  );
                }
              }}
            >
              {row?.original?.eligiblecontacts}{" "}
            </span>

            {/* appConstants.ineligibledata for creation only */}
            {/* appname */}
            <span
              onClick={(e) => {
                if (row?.original?.ineligiblecontacts !== "0") {
                  handleInEligibleContact(
                    e,
                    row.original,
                    row.original.appname,
                    row?.original?.ineligiblecontactslist,
                    row.index
                  );
                }
              }}
              style={
                row?.original?.ineligiblecontacts !== "0"
                  ? { color: "#FF0000" }
                  : { color: "#2ab514" }
              }
            >
              ({row?.original?.ineligiblecontacts})
            </span>
          </div>
        ),
      },
      {
        Header: () => <div className="text-center">Imported contacts</div>,

        accessor: "importedcontacts",
        sortable: true,
        width: "30%",
        Cell: ({ row, value }) => (
          <>
            <div className="fw-bolder text-center">
              {Number(value) === 0 ? (
                <span style={{ color: "#b58b1d" }}>{value}</span>
              ) : (
                <span
                  onClick={(e) =>
                    handleImportedContact(
                      e,
                      row.original.appname,
                      row.original.appaccountid
                    )
                  }
                  style={{ color: "#2ab514" }}
                >
                  {value}
                </span>
              )}
            </div>
          </>
        ),
      },
      {
        Header: () => <div className="text-center">Pending creation</div>,

        accessor: "pendingcreationcontacts",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
        Cell: ({ row, value }) => (
          <>
            <div className="fw-bolder text-center">
              {Number(value) === 0 ? (
                <span style={{ color: "#2ab514" }}>{value}</span>
              ) : (
                <span
                  onClick={(e) =>
                    handlePendingCreation(
                      e,
                      row.original.appname,
                      row.original.appaccountid
                    )
                  }
                  style={{ color: "#FF0000" }}
                >
                  {value}
                </span>
              )}
            </div>
          </>
        ),
      },
      {
        Header: () => <div className="text-center">Pending update</div>,

        accessor: "pendingupdatecontacts",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
        Cell: ({ row, value }) => (
          <>
            <div className="fw-bolder text-center">
              {Number(value) === 0 ? (
                <span style={{ color: "#2ab514" }}>{value}</span>
              ) : (
                <span
                  onClick={(e) =>
                    handlePendingUpdate(
                      e,
                      row.original.appname,
                      row.original.appaccountid
                    )
                  }
                  style={{ color: "#b58b1d" }}
                >
                  {value}
                </span>
              )}
            </div>
          </>
        ),
      },
      {
        Header: "Active",
        accessor: "isactive",
        sortable: false,
        width: "10%",
        Filter: "",
        filter: "value",
        Cell: ({ row, value }) => {
          return (
            <div>
              {row.original.istokenvalid ? (
                <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                  <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                  {"Yes"}
                </span>
              ) : row.original.appaccountid === "" ? (
                <span
                  style={{ color: "#b58b1d" }}
                  className="badge border border-warning px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"
                  onClick={() => {
                    popupAppMaster(
                      row?.original?.amappurl +
                      "?data=" +
                      row?.original?.applauchurl
                    );
                    // window.open(
                    //   row?.original?.amappurl +
                    //   "?data=" +
                    //   row?.original?.applauchurl,
                    //   "_blank",
                    //   "noopener"
                    // );
                    // autoRefresh();
                  }}
                >
                  <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                  {"Configure"}
                </span>
              ) : (
                <span
                  className="badge border border-danger text-danger px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"
                  onClick={() => {
                    popupAppMaster(
                      row?.original?.amappurl +
                      "?data=" +
                      row?.original?.applauchurl
                    );
                    // window.open(
                    //   row?.original?.amappurl +
                    //   "?data=" +
                    //   row?.original?.applauchurl,
                    //   "_blank",
                    //   "noopener"
                    // );
                    // autoRefresh();
                  }}
                >
                  <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                  {"Review"}
                </span>
              )}
            </div>
          );
        },
      },
      {
        Header: "Action",

        Cell: ({ row }) => {
          const onDeleteItemClick = () => {
            handleDeleteClickApps(row);
          };
          return (
            <div className="d-flex gap-4">
              <Button
                class="bg-success"
                onClick={() => {
                  popupAppMaster(
                    row?.original?.amappurl +
                    "?data=" +
                    row?.original?.applauchurl
                  );
                  // window.open(
                  //   row?.original?.amappurl +
                  //   "?data=" +
                  //   row?.original?.applauchurl,
                  //   "_blank",
                  //   "noopener"
                  // );
                  // autoRefresh();
                }}
                variant="secondary"
              >
                Edit
              </Button>{" "}
              {row?.original?.appname !== "Contactmaster" && (
                <Button className="btn btn-sm " onClick={onDeleteItemClick}>
                  Disable
                </Button>
              )}
            </div>
          );
        },
      },
    ],
    [list]
  );

  const hiddenappColumns = ["_id", "appuserinfo[0].groupid"];

  const handleEligibleContact = (e, appname, accountid) => {
    console.log(appname, accountid);
    switch (appname.toLowerCase()) {
      case "procore":
        history.push({
          pathname: "/procoreimportwiz",
          state: { accountid: accountid },
        });
        //   window.location.href = "/procoreimportwiz";
        break;
      case "entraid":
        history.push({
          pathname: "/entraidimportwiz",
          state: { accountid: accountid },
        });
        //   window.location.href = "/procoreimportwiz";
        break;

      case "contactmaster":
        history.push({
          pathname: "/contactlist",
          // state: { accountid : accountid },
        });
        //   window.location.href = "/contactlist";
        break;
      case "quickbooks":
        history.push({
          pathname: "/quickbooksimportwiz",
          state: { accountid: accountid },
        });
        // window.location.href = "/quickbooksimportwiz";
        break;
      case "commandpost":
        history.push({
          pathname: "/commandpostimportwiz",
          state: { accountid: accountid },
        });
        //   window.location.href = "/commandpostimportwiz";
        break;
      default:
        break;
    }
  };

  //handleImportedContact
  const handleImportedContact = (e, appname, accountid) => {
    switch (appname.toLowerCase()) {
      case "procore":
        history.push({
          pathname: "/contactlist",
          state: { appname: appname, accountid: accountid },
        });
        //   window.location.href = "/procoreimportwiz";
        break;
      case "entraid":
        history.push({
          pathname: "/contactlist",
          state: { appname: appname, accountid: accountid },
        });
        //   window.location.href = "/procoreimportwiz";
        break;
      case "contactmaster":
        history.push({
          pathname: "/contactlist",
          //state: {},
        });
      case "quickbooks":
        history.push({
          pathname: "/contactlist",
          state: { appname: appname, accountid: accountid },
        });
        // window.location.href = "/quickbooksimportwiz";
        break;
      case "commandpost":
        history.push({
          pathname: "/contactlist",
          state: { appname: appname, accountid: accountid },
        });
        //   window.location.href = "/commandpostimportwiz";
        break;
      default:
        break;
    }
  };

  const handlePendingCreation = (e, appname, accountid) => {
    switch (appname.toLowerCase()) {
      case "procore":
        history.push({
          pathname: "/pendingcreationlist",
          state: { appname: appname, accountid: accountid },
        });
        //   window.location.href = "/procoreimportwiz";
        break;
      case "entraid":
        history.push({
          pathname: "/pendingcreationlist",
          state: { appname: appname, accountid: accountid },
        });
        //   window.location.href = "/procoreimportwiz";
        break;
      case "contactmaster":
        history.push({
          pathname: "/pendingcreationlist",
          //state: {},
        });
      case "quickbooks":
        history.push({
          pathname: "/pendingcreationlist",
          state: { appname: appname, accountid: accountid },
        });
        // window.location.href = "/quickbooksimportwiz";
        break;
      case "commandpost":
        history.push({
          pathname: "/pendingcreationlist",
          state: { appname: appname, accountid: accountid },
        });
        //   window.location.href = "/commandpostimportwiz";
        break;
      default:
        break;
    }
  };

  const handlePendingUpdate = (e, appname, accountid) => {
    switch (appname.toLowerCase()) {
      case "procore":
        history.push({
          pathname: "/pendingupdatelist",
          state: { appname: appname, accountid: accountid },
        });
        //   window.location.href = "/procoreimportwiz";
        break;
      case "contactmaster":
        history.push({
          pathname: "/pendingupdatelist",
          //state: {},
        });
      case "quickbooks":
        history.push({
          pathname: "/pendingupdatelist",
          state: { appname: appname, accountid: accountid },
        });
        break;
      case "entraid":
        history.push({
          pathname: "/pendingupdatelist",
          state: { appname: appname, accountid: accountid },
        });
        // window.location.href = "/quickbooksimportwiz";
        break;
      case "commandpost":
        history.push({
          pathname: "/pendingupdatelist",
          state: { appname: appname, accountid: accountid },
        });
        //   window.location.href = "/commandpostimportwiz";
        break;
      default:
        break;
    }
  };
  const inEligibleContactdata = useMemo(
    () => [...inEligibleContactList],
    [inEligibleContactList]
  );
  const inEligibleContactappColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },
      {
        Header: "Company name ",
        accessor: "companyname",
        sortable: false,
        width: "20%",
        Filter: "",
        filter: "",
      },

      {
        Header: "First name ",
        accessor: "firstname",
        sortable: false,
        width: "20%",
        Filter: "",
        filter: "",
        Cell: ({ value, row }) => {
          const handleUpdate = (e) => {
            handleChange(e, row.index);
          };
          return (
            <div>
              {row.original.selected ? (
                <input
                  type="text"
                  name="firstname"
                  className="form-control"
                  value={value}
                  onChange={handleUpdate}
                ></input>
              ) : (
                <span>{value}</span>
              )}
            </div>
          );
        },
      },

      {
        Header: "Last name ",
        accessor: "lastname",
        sortable: false,
        width: "20%",
        Filter: "",
        filter: "",
        Cell: ({ value, row }) => {
          const handleUpdate = (e) => {
            handleChange(e, row.index);
          };
          return (
            <div>
              {row.original.selected ? (
                <input
                  type="text"
                  name="lastname"
                  className="form-control"
                  value={value}
                  onChange={handleUpdate}
                ></input>
              ) : (
                <span>{value}</span>
              )}
            </div>
          );
        },
      },

      {
        Header: "Email",
        accessor: "email",
        sortable: false,
        width: "20%",
        Filter: "",
        filter: "",
        Cell: ({ value, row }) => {
          const handleUpdate = (e) => {
            handleChange(e, row.index);
          };
          return (
            <div>
              {row.original.selected ? (
                <input
                  type="text"
                  name="email"
                  className="form-control"
                  value={value}
                  onChange={handleUpdate}
                ></input>
              ) : (
                <span>{value}</span>
              )}
            </div>
          );
        },
      },

      {
        Header: "Contact role",
        accessor: "contactrole",
        sortable: false,
        width: "20%",
        Filter: "",
        filter: "",
      },

      {
        Header: "Action",
        accessor: "selected",
        sortable: false,
        width: "20%",
        Filter: "",
        filter: "",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex gap-4">
              {row.original.is_active === 1 ? (
                <div>
                  {!value ? (
                    <Button
                      class="bg-success"
                      onClick={(e) =>
                        handleAction(e, row.original, row.index, "edit")
                      }
                      variant="secondary"
                    >
                      Edit
                    </Button>
                  ) : (
                    <div>
                      <Button
                        className=""
                        onClick={(e) =>
                          handleImport(
                            e,
                            row.original,
                            row.original.source,
                            row.index
                          )
                        }
                        variant="secondary"
                      >
                        Import
                      </Button>
                      <Button
                        className=" ms-2"
                        onClick={(e) =>
                          handleAction(e, row.original, row.index, "cancel")
                        }
                        variant="secondary"
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
                </div>
              ) : (
                <div> In-progress...</div>
              )}
            </div>
          );
        },
      },
    ],
    [inEligibleContactList]
  );

  const hiddenInEligibleContactappColumns = ["id", "contactrole"];
  const hiddenInEligibleContactQBappColumns = ["id"];
  //handle function
  const handleAction = async (e, data, index, name) => {
    let _LOBInfo = { ...LOBInfo };
    switch (_LOBInfo.appname.toLowerCase()) {
      case "commandpost":
        window.open(_LOBInfo.appurl + "contact-detail/" + data.id, "_blank");

        break;
      case "procore":
        window.open(
          _LOBInfo.appurl +
          data.companyid +
          "/company/directory/users/" +
          data.id +
          "?tab=general",
          "_blank"
        );

        break;
      case "entraid":
        window.open(
          _LOBInfo.appurl + "/" + data.id + "/hidePreviewBanner~/true",
          "_blank"
        );

        break;
      case "quickbooks":
        switch (data.contactrole.toLowerCase()) {
          case "employee":
            window.open(_LOBInfo.appurl + "app/employees");
            break;

          case "vendor":
            window.open(_LOBInfo.appurl + "app/vendordetail?nameId=" + data.id);
            break;
          case "customer":
            window.open(
              _LOBInfo.appurl + "app/customerdetail?nameId=" + data.id
            );
            break;
          case "individual contractor":
            window.open(_LOBInfo.appurl + "app/vendordetail?nameId=" + data.id);
            break;
          default:
            break;
        }

        break;
      default:
        break;
    }

    // var _inEligibleContactList = [...inEligibleContactList];
    // switch (name) {
    //   case "edit":
    //     _inEligibleContactList[index].selected = true;
    //     break;
    //   case "cancel":
    //     _inEligibleContactList[index].selected = false;
    //     break;
    // }

    // setInEligibleContactList(_inEligibleContactList);
  };

  const handleChange = (event, index) => {
    const { name, value } = event.target;
    var _inEligibleContactList = [...inEligibleContactList];
    _inEligibleContactList[index][name] = value;
    setInEligibleContactList(_inEligibleContactList);
  };
  const handleInEligibleContact = (
    e,
    lobinfo,
    appname,
    ineligibledata,
    index
  ) => {
    let _lobinfo = { ...lobinfo };
    delete _lobinfo.ineligiblecontactslist;
    setLOBInfo(...LOBInfo, _lobinfo);
    // switch (appname.toLowerCase()) {
    //   case "procore":
    //     break;
    //   case "contactmaster":
    //     break;
    //   case "quickbooks":
    //     break;
    //   case "commandpost":
    //     break;
    // }
    setInEligibleContactList(ineligibledata);
    setInEligibleModalHeaderName(appname);

    setIsInEligibleModal(true);
  };
  const handleImport = async (e, contactdata, appname, rowindex) => {
    let _LOBInfo = { ...LOBInfo };
    let _inEligibleContactList = [...inEligibleContactList];
    let _contactdata = { ...contactdata };
    var apiinfo = {};
    if (validForm(_contactdata)) {
      switch (appname.toLowerCase()) {
        case "commandpost":
          apiinfo.commandpostCompanyID = _LOBInfo.appaccountid;
          apiinfo.appname = _LOBInfo.appname;
          apiinfo.LoggedinBy = user.sub;
          apiinfo.signupurl = window.location.origin;
          await CreateImportContactTask(apiinfo, [_contactdata], rowindex);

          break;
        case "procore":
          apiinfo.procoreCompanyID = _LOBInfo.appaccountid;
          apiinfo.appname = _LOBInfo.appname;
          apiinfo.LoggedinBy = user.sub;
          apiinfo.signupurl = window.location.origin;
          CreateImportContactTask(apiinfo, [_contactdata]);
          break;
        case "entraid":
          apiinfo.entraidCompanyID = _LOBInfo.appaccountid;
          apiinfo.appname = _LOBInfo.appname;
          apiinfo.LoggedinBy = user.sub;
          apiinfo.signupurl = window.location.origin;
          CreateImportContactTask(apiinfo, [_contactdata]);
          break;

        case "quickbooks":
          apiinfo.quickbooksCompanyID = _LOBInfo.appaccountid;
          apiinfo.appname = _LOBInfo.appname;
          apiinfo.LoggedinBy = user.sub;
          apiinfo.signupurl = window.location.origin;
          CreateImportContactTask(apiinfo, [_contactdata]);
          break;
        default:
          break;
      }
    } else {
      setIsAlert(true);
    }
  };
  const validForm = (_contactdata) => {
    try {
      let valid = false;
      valid = handleFieldPersonalListRequiredValidation(_contactdata);
      Object.values(contactErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldPersonalListRequiredValidation = (_contactdata) => {
    let iserror = "";

    setContactErrors({
      ...contactErrors,
      firstname:
        _contactdata.firstname === ""
          ? (iserror =
            appConstants.contact.handlefieldrequiredvalidation.firstname)
          : contactErrors.firstname,
      lastname:
        _contactdata.lastname === ""
          ? (iserror =
            appConstants.contact.handlefieldrequiredvalidation.lastname)
          : contactErrors.lastname,

      email:
        _contactdata.email === ""
          ? (iserror = appConstants.contact.handlefieldrequiredvalidation.email)
          : !isEmail(_contactdata.email)
            ? (iserror = appConstants.contact.handlefieldvalidation.email)
            : contactErrors.email,

      // email: !isEmail(_contactdata.email)
      //   ? (iserror = appConstants.contact.handlefieldvalidation.email)
      //   : contactErrors.email,
    });

    return iserror.length === 0;
  };

  async function CreateImportContactTask(lobinfo, contact, rowindex) {
    let _inEligibleContactList = [...inEligibleContactList];
    await createImportContactTask(
      lobinfo,
      contact,
      "reconcile",
      ctechapp.currentUser,
      user.sub
    )
      .then((response) => {
        if (response != undefined) {
          console.log("_inEligibleContactList", _inEligibleContactList);
          _inEligibleContactList[rowindex].is_active = 0;
          setInEligibleContactList(_inEligibleContactList);
          setIsSuccess(true);
        }
      })
      .catch((err) => {
        console.log("error : " + err);
      });
  }
  const handleDeleteClickApps = (row) => {
    setIsDeleteApp(true);
    setRowToDelete(row.original._id);
  };
  const handleConfirmDelete = () => {
    setIsDeleteApp(false);
    ondelete(rowToDelete);
  };
  const handleConfirmDeleted = (row) => {
    setIsDeleted(false);
    window.location.reload(true);
  };
  const handleCancelDelete = (row) => {
    setIsDeleteApp(false);
  };
  //use effect function
  useEffect(() => {
    if (isAuthenticated) {
      getUserInfoByID();
      getSubscriptionIDByEmail();
      getMongodbConfiguredValues();
    }
  }, []);
  const autoRefresh = () => {
    setisReload(true);
  };
  const handleSucessSweetAlert = () => {
    setisReload(false);
    window.location.reload();
  };

  function getUserInfoByID() {
    try {
      getuserbyauthproviderid(user.sub, ctechapp.currentUser).then(
        (response) => {
          let user_info = JSON.parse(response);
          if (response !== undefined || user_info.length !== 0) {
            setUserinfo(user_info[0]);
            // get_environment_config(user_info[0]);
            getData();
          }
        }
      );
    } catch (exception) {
      console.log(exception);
    }
  }

  function getSubscriptionIDByEmail() {
    try {
      getChargebeeSubscriptionIDByEmail(
        user.email,
        ctechapp.currentUser,
        ctechapp.currentUser.id
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.statusCode === 200) {
            setSubscriptionID(response.data.subscriptionId || "");
            console.log(
              "response.subscriptionId",
              response.data.subscriptionId
            );
          }
        }
      });
    } catch (exception) {
      console.log("getSubscriptionIDByEmail ", exception);
    }
  }

  async function getMongodbConfiguredValues() {
    try {
      await get_mongodb_values(
        ctechapp.currentUser,
        ctechapp.currentUser.id
      ).then((response) => {
        if (response !== undefined && response !== null) {
          setMongodbvalues(response);
          console.log("setMongodbvalues", JSON.stringify(response));
        }
      });
    } catch (exception) {
      console.log("getMongodbConfiguredValues ", exception);
    }
  }

  async function getData() {
    setloadDialogOpen(true);
    try {
      getapplistbyuserid(ctechapp.currentUser, user.sub).then((response) => {
        if (response !== undefined) {
          let apps_list = JSON.parse(response);
          console.log("apps_list", apps_list);
          if (apps_list.length > 0) setaddApp(apps_list[0]);

          apps_list = apps_list.sort((a, b) => {
            if (a.sortOrder < b.sortOrder) {
              return -1;
            }
          });

          setList(apps_list);
          setloadDialogOpen(false);
        } else {
          setloadDialogOpen(false);
        }
      });
    } catch (ex) {
      setloadDialogOpen(false);
    }
  }
  function ondelete(id) {
    try {
      deleteAppByuserID(id, ctechapp.currentUser, userinfo.userid).then(
        (response) => {
          if (response !== undefined) {
            setIsDeleted(true);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  }
  const handleClose = (e, name) => {
    switch (name) {
      case "ineligible":
        setIsInEligibleModal(false);
        setLOBInfo("");
        setInEligibleContactList([]);
        setInEligibleModalHeaderName("");
        break;
      case "import":
        setIsSuccess(false);
        break;
      case "fieldvalidation":
        setIsAlert(false);
        setContactErrors(initialContactStateErros);
        break;
      default:
        break;
    }
  };

  function CheckoutExistingPage(checkoutType) {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      redirectUrl = redirectURLmap.get(checkoutType);

      var raw = JSON.stringify({
        subscriptionId: subscriptionID || "",
        checkoutType: checkoutType || "",
        redirectUrl: redirectUrl || "",
        siteName: mongodbvalues.chargebee_site_name || "",
        apiKey: mongodbvalues.chargebee_api_key || "",
      });

      console.log("raw Values", raw);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("/chargebeecheckoutexistingpage", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          if (result.toUpperCase().includes("[ERROR]:")) {
            // console.log(result);
            var message = result.replace("[error]:", "");
            var errmsg = JSON.parse(message);
            errmsg = JSON.parse(errmsg);
            setChargebeeErrrorMessage(errmsg.error_msg);
            //console.log(JSON.stringify(errmsg));
          } else {
            console.log("chargebeecheckoutexistingpage ", result);
            let _result = JSON.parse(result);
            if (_result?.hosted_page?.url) {
              console.log(
                "_result?.hosted_page?.url ",
                _result?.hosted_page?.url
              );
              const url = new URL(_result.hosted_page.url);
              console.log("url ", url);
              //window.open(url);
              popupChargebeeAppPurchase(_result.hosted_page.url);
            }
          }
        })
        .catch((error) => console.log("error", error));
    } catch (ex) {
      console.log("ex", ex);
    }
  }

  function popupChargebeeAppPurchase(url) {
    window.open(
      url,
      "popUpWindow",
      "height = 700, width = 500, left = 100, top = 100, scrollbars = yes, resizable = yes, menubar = no, toolbar = yes, location = no, directories = no, status = yes"
    );
    setIsChargebeeAppModal(false);
  }
  function popupAppMaster(url) {
    // window.open(url,"_blank","noopener");
    // autoRefresh();
    window.open(
      url,
      "popUpWindow",
      "height = 700, width = 500, left = 100, top = 100, scrollbars = yes, resizable = yes, menubar = no, toolbar = yes, location = no, directories = no, status = yes"
    );
  }
  return (
    <div className="mb-2">
      <div className="d-flex justify-content-between mt-2">
        <h1 className="m-2" align="left">
          Connected Apps
        </h1>
        <div className="p-2 m-2">
          <Button
            class="btn-sm"
            onClick={() => {
              popupAppMaster(
                addApp?.amappurl + "?data=" + addApp?.addapplauchurl
              );
            }}
            variant="secondary"
          >
            Add
          </Button>{" "}
          <Button
            class="bg-success"
            onClick={() => {
              getUserInfoByID();
            }}
            variant="secondary"
          >
            Refresh
          </Button>{" "}
        </div>
      </div>

      {/* <div className="d-flex justify-content-end mb-2">
        <Button
          class="bg-success"
          onClick={(e) => {
            setIsChargebeeAppModal(true);
          }}
          variant="secondary"
        >
          Add
        </Button>{" "}
      </div> */}
      <GenericLlistTableActive
        gcolumns={appColumns}
        gdata={data.length > 0 ? JSON.stringify(data) : ""}
        ghiddencolumns={hiddenappColumns}
        gplaceholder={"Search Apps.."}
        gsearchDisabled={data.length >= 11 ? false : true}
        gdisablePagination={data.length >= 11 ? false : true}
      />
      <div>
        <LoadingDialog
          isOpen={loadDialogOpen}
          onClose={() => setloadDialogOpen(false)}
        />
        {isDeleteApp && (
          <SweetAlert
            warning
            style={{ color: "black" }}
            title=""
            confirmBtnText="Disable"
            cancelBtnText="No"
            showCancel={true}
            onConfirm={handleConfirmDelete}
            onCancel={handleCancelDelete}
          >
            <div>Do you want to disable ?</div>
          </SweetAlert>
        )}
        {isDeleted && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title=""
            confirmBtnText="Ok"
            onConfirm={handleConfirmDeleted}
          >
            <div>App disabled successfully.</div>
          </SweetAlert>
        )}
        {isSuccess && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title="Contact sync"
            confirmBtnText="Ok"
            onConfirm={(e) => handleClose(e, "import")}
          >
            <div>Import contact successfully.</div>
          </SweetAlert>
        )}
        {isAlert && (
          <SweetAlert
            danger
            style={{ color: "black" }}
            title="Contact sync"
            confirmBtnText="Ok"
            onConfirm={(e) => handleClose(e, "fieldvalidation")}
          >
            <div className="text-danger">
              <div>{contactErrors.firstname}</div>
              <div>{contactErrors.lastname}</div>
              <div>{contactErrors.email}</div>
            </div>
          </SweetAlert>
        )}
        {isReload && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title={msgTite}
            onConfirm={handleSucessSweetAlert}
          >
            {msgContent}
          </SweetAlert>
        )}
        {isInEligibleModal && (
          <Modal
            scrollable={true}
            show={isInEligibleModal}
            onHide={(e) => {
              handleClose(e, "ineligible");
            }}
            size="xl"
            centered
          >
            <Modal.Header className="fw-bold h4">
              {inEligibleModalHeaderName} ineligible contact
              <a
                style={{ "text-decoration": "none" }}
                onClick={(e) => {
                  handleClose(e, "ineligible");
                }}
                className="col d-flex justify-content-end text-dark"
              >
                <i class="fa-solid fa-xmark"></i>
              </a>
            </Modal.Header>
            <Modal.Body>
              <GenericLlistTableActive
                gcolumns={inEligibleContactappColumns}
                gdata={
                  inEligibleContactdata.length > 0
                    ? JSON.stringify(inEligibleContactdata)
                    : ""
                }
                ghiddencolumns={
                  inEligibleContactdata[0]?.contactrole
                    ? hiddenInEligibleContactQBappColumns
                    : hiddenInEligibleContactappColumns
                }
                gplaceholder={"Search contact.."}
                gsearchDisabled={
                  inEligibleContactdata.length >= 11 ? false : true
                }
                gdisablePagination={
                  inEligibleContactdata.length >= 11 ? false : true
                }
              />
            </Modal.Body>
            <Modal.Footer>
              {" "}
              <div className="row p-1">
                {" "}
                <div className=" d-flex justify-content-end mt-3 col-sm-12 col-md-12 col-lg-12">
                  <button
                    className=" btn btn-primary me-2 "
                    onClick={(e) => {
                      handleClose(e, "ineligible");
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        )}
        {isChargebeeAppModal && (
          <Modal
            show={isChargebeeAppModal}
            onHide={() => {
              setIsChargebeeAppModal(false);
            }}
            size="sm"
            centered
          >
            <Modal.Body>
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src="/images/cm_logo.png"
                  className="headerLogo"
                  alt="Logo"
                />
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <label className="h5">Add connected Apps</label>
              </div>
              <hr></hr>

              <div className="row d-flex justify-content-center align-self-center mt-2">
                <button
                  className="btn btn-primary btn-block"
                  rel="noreferrer"
                  target="_blank"
                  onClick={(e) => {
                    CheckoutExistingPage(PC_checkoutType);
                  }}
                >
                  Procore
                </button>
              </div>
              <div className="row d-flex justify-content-center align-self-center mt-2">
                <button
                  className="btn btn-primary btn-block"
                  rel="noreferrer"
                  target="_blank"
                  onClick={(e) => {
                    CheckoutExistingPage(QB_checkoutType);
                  }}
                >
                  Quickbooks
                </button>
              </div>
              {chargebeeErrrorMessage.length > 0 && (
                <div className="text-danger">{chargebeeErrrorMessage}</div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex justify-content-end  gap-2">
                <Button
                  className="btn btn-primary "
                  onClick={() => {
                    setIsChargebeeAppModal(false);
                  }}
                >
                  Close
                </Button>
                {/* <Button
                  className="btn btn-primary me-2"
                  disabled={accountEmail.length === 0}
                  onClick={(evnt) => handleEmailSubmit()}
                >
                  submit
                </Button> */}
              </div>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div>
  );
}

export default AppDashboard;
