import { Formik, Field } from "formik";
import { Button, Form } from "react-bootstrap";
import * as yup from "yup";
import * as Realm from "realm-web";
import React from "react";
import Table from "react-bootstrap/Table";
import { useRealmApp } from "../../providers/RealmApp";

import { useEffect, useState } from "react";
import { isAnon } from "../../utils";
import Loading from "../../components/Loading";
import { BSON } from "realm-web";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { getuserbyauthproviderid } from "../../services/users_services";
import EditContact from "./EditContact";
import {
  getapiusersettingsapplistbyuserid,
  getapiusersettingsbyappname_userid,
  getapisettingsbyid,
  updateapiusersettingapirefreshtokenbyid,
} from "../../services/api_user_settings_services";
import { updateuserbyuserid } from "../../services/users_services";
import { getProcoreProjectListbyUserID } from "../../services/procore_project_services";

import { constants } from "buffer";

const userSchema = yup.object().shape({
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string().required(),
});

const qbappanmestatic = "QuickBooks";
const procoreappanmestatic = "Procore";

function ContactProfile() {
  // const userInfo1 = useSelector((state) => state.userInfo);
  // console.log(userInfo1);
  const history = useHistory();
  const [companyprojectlist, setCompanyProjectList] = useState([]);
  const [userprojectinfolist, setUserProjectInfoList] = useState([]);
  const [loading, setLoading] = useState(false);
  const ctechapp = useRealmApp();
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  const [userinfo, setUserinfo] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
  });

  useEffect(() => {
    //redirect to the contact verification page.
    const verification_url = localStorage.getItem(user.email);

    if (verification_url !== null && verification_url.length > 0) {
      localStorage.removeItem(user.email);
      window.location.href = verification_url;
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      // console.log("_usersdata : ", user_data);
      console.log("Profile page loaded");
      getUserInfoByID(ctechapp.currentUser);
    }
  }, []);
  async function getUserInfoByID() {
    try {
      setLoading(true);
      getuserbyauthproviderid(user.sub, ctechapp.currentUser).then(
        (response) => {
          let user_info = JSON.parse(response);
          if (response !== undefined || user_info.length !== 0) {
            setUserinfo(user_info[0]);
          }
        }
      );
    } catch (exception) {
      setLoading(false);
      console.log(exception);
    }
  }

  return (
    <div style={{ margin: 20 }}>
      <div>
        <EditContact title="Profile" />
      </div>
    </div>
  );
}

export default ContactProfile;
