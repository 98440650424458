import React, { useContext, useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { isEmail } from "validator";
import * as Realm from "realm-web";
import {
  get_mongodb_values,
  getChargebeeSubscriptionIDByEmail,
} from "../../services/users_services";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";

function Signup() {
  const [loading, setLoading] = useState(false);
  const [mongodbvalues, setMongodbvalues] = useState({});
  const { user, isAuthenticated, logout } = useAuth0();
  const [isEmailWindowModal, setEmailWindowModal] = useState(false);
  const [accountEmail, setAccountEmail] = useState("");
  const [checkoutType, setCheckoutType] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [chargebeeErrrorMessage, setChargebeeErrrorMessage] = useState("");
  const [isCPCutomerConfirmation, setIsCPCutomerConfirmation] = useState(false);

  //const subscriptionId = "AzZV5gTr33mUxND2";
  const PC_checkoutType = "Procore-USD-Yearly";
  const QB_checkoutType = "Quickbooks-USD-Yearly";
  const CP_checkoutType = "CP-Pro-USD-Yearly";
  const MB_checkoutType = "ContactMaster-USD-Yearly";
  const MB_PC_checkoutType = "ContactMaster-USD-Yearly_PCTYPE";
  const MB_QB_checkoutType = "ContactMaster-USD-Yearly_QBTYPE";
  var redirectUrl = window.location.origin;
  const location = useLocation();

  const redirectManagerURLmap = new Map()
    .set(CP_checkoutType, redirectUrl + "/connection/commandpost/manage")
    .set(QB_checkoutType, redirectUrl + "/connection/quickbooks/manage")
    .set(PC_checkoutType, redirectUrl + "/connection/procore/manage")
    .set(MB_checkoutType, redirectUrl + "/connection/master/manage");

  const redirectURLmap = new Map()
    .set(CP_checkoutType, redirectUrl + "/connection/commandpost/setup")
    .set(QB_checkoutType, redirectUrl + "/connection/quickbooks/setup")
    .set(PC_checkoutType, redirectUrl + "/connection/procore/setup")
    .set(MB_checkoutType, redirectUrl + "/connection/master/setup")
    .set(
      MB_QB_checkoutType,
      redirectUrl + "/connection/master/quickbooks/setup"
    )
    .set(MB_PC_checkoutType, redirectUrl + "/connection/master/procore/setup");

  // Get the API key from the local environment
  const apiKey = process.env.REACT_APP_APIKEY;
  if (!apiKey) {
    throw new Error("Could  not find a Realm Server API Key.");
  }
  // Create an api key credential
  const credentials = Realm.Credentials.apiKey(apiKey);
  const localapp = new Realm.App(process.env.REACT_APP_REALM_APP_ID);

  useEffect(() => {
    try {
      localapp.logIn(credentials).then(() => {
        get_mongodb_values(localapp.currentUser, localapp.currentUser.id).then(
          (response) => {
            if (response !== undefined) {
              setMongodbvalues(response);
              if (
                localapp.currentUser !== null &&
                localapp.currentUser !== undefined
              ) {
                localapp.removeUser(localapp.currentUser);
              }
              if (
                location.pathname
                  .toLowerCase()
                  .includes("/connection/master/procore/signup")
              ) {
                CheckoutNewPageSignup(MB_PC_checkoutType, response);
              } else if (
                location.pathname
                  .toLowerCase()
                  .includes("/connection/master/quickbooks/signup")
              ) {
                CheckoutNewPageSignup(MB_QB_checkoutType, response);
              }
            }
          }
        );
      });
      if (localapp.currentUser !== null && localapp.currentUser !== undefined) {
        localapp.removeUser(localapp.currentUser);
      }
    } catch (err) {
      console.log("err : " + err);
      if (localapp.currentUser !== null && localapp.currentUser !== undefined) {
        localapp.removeUser(localapp.currentUser);
      }
    }
  }, []);
  function MasterBundleChargebee(e) {
    e.preventDefault();
    setIsCPCutomerConfirmation(true);
    //CheckoutNewPage(MB_checkoutType);
  }
  function CommandPostChargebee(e) {
    e.preventDefault();
    CheckoutNewPage(CP_checkoutType);
  }
  function CommandPostChargebeeManage(e) {
    e.preventDefault();
    CheckoutManagePage(PC_checkoutType);
  }

  function ProcoreChargebee(e) {
    e.preventDefault();
    //CheckoutExistingPage(PC_checkoutType);
    // setCheckoutType(PC_checkoutType);
    // setErrorMessage("");
    // setEmailWindowModal(true);
    CheckoutNewPage(PC_checkoutType);
  }
  function ProcoreChargebeeManage(e) {
    e.preventDefault();
    CheckoutManagePage(PC_checkoutType);
  }

  function QuickBooksChargebee(e) {
    e.preventDefault();
    //CheckoutExistingPage(QB_checkoutType);
    // setCheckoutType(QB_checkoutType);
    // setErrorMessage("");
    // setEmailWindowModal(true);
    CheckoutNewPage(QB_checkoutType);
  }
  function QuickBooksChargebeeManage(e) {
    e.preventDefault();
    CheckoutManagePage(QB_checkoutType);
  }

  function CheckoutManagePage(checkoutType) {
    redirectUrl = redirectManagerURLmap.get(checkoutType);
    window.open(redirectUrl);
  }

  function CheckoutExistingPage(checkoutType, subscriptionId) {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      redirectUrl = redirectURLmap.get(checkoutType);

      var raw = JSON.stringify({
        subscriptionId: subscriptionId || "",
        checkoutType: checkoutType || "",
        redirectUrl: redirectUrl || "",
        siteName: mongodbvalues.chargebee_site_name || "",
        apiKey: mongodbvalues.chargebee_api_key || "",
      });

      console.log("raw Values", raw);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("/chargebeecheckoutexistingpage", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          if (result.toUpperCase().includes("[ERROR]:")) {
            console.log(result);
            var message = result.replace("[error]:", "");
            var errmsg = JSON.parse(message);
            console.log(JSON.stringify(errmsg));
          } else {
            let _result = JSON.parse(result);
            if (_result?.hosted_page?.url) {
              const url = new URL(_result.hosted_page.url);
              window.open(url);
            }
          }
        })
        .catch((error) => console.log("error", error));
    } catch (ex) {
      console.log("ex", ex);
    }
  }

  function CheckoutNewPageSignup(checkoutType, chargebeeObj) {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      redirectUrl = redirectURLmap.get(checkoutType);
      let _checkoutType = checkoutType
        .replace("_PCTYPE", "")
        .replace("_QBTYPE", "");

      var raw = JSON.stringify({
        checkoutType: _checkoutType,
        redirectUrl: redirectUrl,
        siteName: chargebeeObj.chargebee_site_name,
        apiKey: chargebeeObj.chargebee_api_key,
      });

      console.log("raw Values", raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("/chargebeecheckoutnewpage", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          if (result.toUpperCase().includes("[ERROR]:")) {
            //console.log(result);
            var message = result.replace("[error]:", "");
            var errmsg = JSON.parse(message);
            errmsg = JSON.parse(errmsg);
            setChargebeeErrrorMessage(errmsg.error_msg);
            //console.log(JSON.stringify(errmsg));
          } else {
            let _result = JSON.parse(result);
            if (_result?.hosted_page?.url) {
              const url = new URL(_result.hosted_page.url);
              window.location.replace(url);
            }
          }
        })
        .catch((error) => console.log("error", error));
    } catch (ex) {
      console.log("ex", ex);
    }
  }

  function CheckoutNewPage(checkoutType) {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      redirectUrl = redirectURLmap.get(checkoutType);
      let _checkoutType = checkoutType
        .replace("_PCTYPE", "")
        .replace("_QBTYPE", "");

      var raw = JSON.stringify({
        checkoutType: _checkoutType,
        redirectUrl: redirectUrl,
        siteName: mongodbvalues.chargebee_site_name,
        apiKey: mongodbvalues.chargebee_api_key,
      });

      console.log("raw Values", raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("/chargebeecheckoutnewpage", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          if (result.toUpperCase().includes("[ERROR]:")) {
            //console.log(result);
            var message = result.replace("[error]:", "");
            var errmsg = JSON.parse(message);
            errmsg = JSON.parse(errmsg);
            setChargebeeErrrorMessage(errmsg.error_msg);
            //console.log(JSON.stringify(errmsg));
          } else {
            let _result = JSON.parse(result);
            if (_result?.hosted_page?.url) {
              const url = new URL(_result.hosted_page.url);
              window.location.replace(url);
            }
          }
        })
        .catch((error) => console.log("error", error));
    } catch (ex) {
      console.log("ex", ex);
    }
  }

  const handleConfirmCPCustomer = (row) => {
    setIsCPCutomerConfirmation(false);
    const url = new URL(mongodbvalues.commandpost_billing_redirect_url);
    window.location.replace(url);
  };

  const handleCancelCPCustomer = (row) => {
    CheckoutNewPage(MB_checkoutType);
    setIsCPCutomerConfirmation(false);
  };
  // async function handleEmailSubmit(e) {
  //   try {
  //     localapp.logIn(credentials).then(async () => {
  //       await getChargebeeSubscriptionIDByEmail(
  //         accountEmail,
  //         localapp.currentUser,
  //         localapp.currentUser.id
  //       ).then(async (response) => {
  //         if (response !== undefined && response !== null) {
  //           //console.log("response", response);
  //           let subscriptionId = response.subscriptionId || "";

  //           CheckoutExistingPage(checkoutType, subscriptionId);
  //           setErrorMessage("");
  //           setEmailWindowModal(false);
  //         }
  //         else {
  //           //setIsError(true);
  //           setErrorMessage("Email address not found in subscription");
  //           console.log("Email address not found in subscription");
  //         }
  //         if (
  //           localapp.currentUser !== null &&
  //           localapp.currentUser !== undefined
  //         ) {
  //           localapp.removeUser(localapp.currentUser);
  //         }
  //       });
  //     });
  //     if (localapp.currentUser !== null && localapp.currentUser !== undefined) {
  //       localapp.removeUser(localapp.currentUser);
  //     }
  //   } catch (err) {
  //     console.log("err : " + err);
  //     if (localapp.currentUser !== null && localapp.currentUser !== undefined) {
  //       localapp.removeUser(localapp.currentUser);
  //     }
  //   }

  // };

  return (
    <div>
      {!location.pathname
        .toLowerCase()
        .includes("/connection/master/procore/signup") &&
        !location.pathname
          .toLowerCase()
          .includes("/connection/master/quickbooks/signup") && (
          <div className="bg-black">
            <div className="container py-5 h-80">
              <div className="row d-flex justify-content-center align-items-center h-100">
                <div className="col-lg-8 col-xl-3">
                  <div className="card rounded-3">
                    <img
                      src="/images/cm_logo.png"
                      className="px-5 mt-5"
                      alt="Logo"
                    />
                    <div className="card-body p-4 p-md-2">

                      <h2 className="text-center">
                        Welcome to Contact Sync.
                      </h2>{" "}

                      <div className="row d-flex justify-content-center align-self-center">
                        {/* <div className="row d-flex justify-content-center align-self-center mt-2">
                      <button
                        className="btn btn-primary btn-block" rel="noreferrer" target='_blank'
                        onClick={(e) => {
                          CommandPostChargebee(e);
                        }}
                      >
                        Commandpost app purchase
                      </button></div> */}
                        <div className="row d-flex justify-content-center align-self-center mt-2">
                          <button
                            className="btn btn-primary btn-block"
                            onClick={MasterBundleChargebee}
                          >
                            Contact Sync Signup
                          </button>
                        </div>
                        <div className="row d-flex justify-content-center align-self-center mt-2">
                          <button
                            className="btn btn-primary btn-block"
                            rel="noreferrer"
                            target="_blank"
                            onClick={(e) => {
                              CheckoutNewPage(MB_PC_checkoutType);
                            }}
                          >
                            Contact Sync Signup (Procore marketplace)
                          </button>
                        </div>
                        <div className="row d-flex justify-content-center align-self-center mt-2">
                          <button
                            className="btn btn-primary btn-block"
                            rel="noreferrer"
                            target="_blank"
                            onClick={(e) => {
                              CheckoutNewPage(MB_QB_checkoutType);
                            }}
                          >
                            Contact Sync Signup (QuickBooks marketplace)
                          </button>
                        </div>

                        {/* <hr></hr>
                    <div className="row d-flex justify-content-center align-self-center mt-2">
                      <button
                        className="btn btn-primary btn-block" rel="noreferrer" target='_blank'
                        onClick={(e) => {
                          CommandPostChargebeeManage(e);
                        }}
                      >
                        Commandpost app manage
                      </button></div>

                    <div className="row d-flex justify-content-center align-self-center mt-2">
                      <button
                        className="btn btn-primary btn-block" rel="noreferrer" target='_blank' onClick={(e) => {
                          ProcoreChargebeeManage(e);
                        }}
                      >
                        Procore app manage
                      </button></div>

                    <div className="row d-flex justify-content-center align-self-center mt-2">
                      <button
                        className="btn btn-primary btn-block" rel="noreferrer" target='_blank' onClick={(e) => {
                          CheckoutManagePage(QB_checkoutType);
                        }}
                      >
                        QuickBooks app manage
                      </button>
                    </div> */}

                        {/* <div className="row d-flex justify-content-center align-self-center mt-2 mb-4">
                    <button
                      className="btn btn-primary btn-block" rel="noreferrer" target='_blank' onClick={(e) => {
                        CheckoutManagePage(MB_checkoutType);
                      }}
                    >
                      Master-bundle app manage
                    </button>
                  </div> */}
                      </div>
                      {chargebeeErrrorMessage &&
                        chargebeeErrrorMessage?.length > 0 && (
                          <div className="text-danger">
                            {chargebeeErrrorMessage}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isCPCutomerConfirmation && (
              <div>
                <SweetAlert
                  warning
                  style={{ color: "black" }}
                  title=""
                  confirmBtnText="Yes"
                  cancelBtnText="No"
                  showCancel={true}
                  onConfirm={handleConfirmCPCustomer}
                  onCancel={handleCancelCPCustomer}
                >
                  <div>Are you a current commandpost customer?</div>
                </SweetAlert>
              </div>
            )}
          </div>
        )}
    </div>
  );
}
export default Signup;
