//getAdminVerificationTaskPendingByID

export const getAdminVerificationTaskPendingByID = async (
  id,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.getAdminVerificationTaskPendingByID(
      id,
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

//services for getAdminVerificationTask
export const getAdminVerificationTaskPendingList = async (
  Ctechapp,
  loggedin_by
) => {
  try {
    console.log("loggedin_by", loggedin_by);
    const data = await Ctechapp.functions.getAdminVerificationTaskPendingList(
      loggedin_by
    );
    console.log("getAdminVerificationTaskPendingList", data);
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateAdminVerificationTaskByID = async (
  contact,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.updateAdminVerificationTaskByID(
      JSON.stringify(contact),
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateAdminVerificationTaskByID_AutoSave = async (
  contact,
  Ctechapp,
  loggedin_by
) => {
  try {
    Ctechapp.functions.updateAdminVerificationTaskByID_AutoSave(
      JSON.stringify(contact),
      loggedin_by
    );
  } catch (error) {
    console.log("Error", error);
  }
};
