import React from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Loading from "../components/Loading";
import { getuserbyauthproviderid } from "../services/users_services";
import { useRealmApp } from "../providers/RealmApp";
const MainNav = () => {
  const history = useHistory();
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const ctechapp = useRealmApp();
  const [loading, setLoading] = useState(false);
  const [isLoadMenu, setisLoadMenu] = useState(false);
  const [isShowMenu, setShowMenu] = useState(true);
  const [userinfo, setUserinfo] = useState({
    firstname: "",
    lastname: "",
    email: "",
    role: "",
  });
  useEffect(() => {
    getUserInfoByID();
    if (
      window.location.pathname.includes("setup") ||
      window.location.pathname.includes("contactverification") ||
      window.location.pathname === "/contactverification" ||
      window.location.pathname.includes("verificationmsg") ||
      window.location.pathname.includes("/manage")
    ) {
      setShowMenu(false);
    }
  }, []);

  async function getUserInfoByID() {
    try {
      setLoading(true);
      if (user !== undefined && ctechapp.currentUser != null)
        getuserbyauthproviderid(user.sub, ctechapp.currentUser).then(
          (response) => {
            if (response !== undefined) {
              let user_info = JSON.parse(response);
              if (user_info.length > 0) {
                if (user_info[0].role === "2" || user_info[0].role === "3")
                  setisLoadMenu(true);
                console.log("user info", user_info);
                setUserinfo(user_info[0]);
              }
            }
          }
        );
      setLoading(false);
    } catch (exception) {
      setLoading(false);
      console.log(exception);
    }
  }
  return (
    <>
      {" "}
      {loading && <Loading />}
      {isAuthenticated && ctechapp.currentUser !== null && (
        <div className="me-auto">
          {isShowMenu && isLoadMenu && (
            <Nav>
              {/*     <Nav.Link
                href="/contactprofile"
                exact
                className="nav-link text-decoration-none"
                activeClassName="router-link-exact-active"
              >
                Contact Profile
              </Nav.Link> */}
              <Nav.Link
                href="/apps"
                exact
                className="nav-link text-decoration-none"
                activeClassName="router-link-exact-active"
              >
                CONNECT APPS
              </Nav.Link>
              <Nav.Link
                href="/addcontact"
                exact
                className="nav-link text-decoration-none"
                activeClassName="router-link-exact-active"
              >
                CREATE NEW CONTACT
              </Nav.Link>
              <NavDropdownMenu
                href="#action/3.1"
                title="IMPORT CONTACT"
                id="collasible-nav-dropdown"
              >
                <DropdownSubmenu title="COMMANDPOST">
                  <NavDropdown.Item href="/commandpostimportwiz">
                    IMPORT WIZARD
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/commandpostimportstatus">
                    IMPORT STATUS
                  </NavDropdown.Item>
                </DropdownSubmenu>

                <DropdownSubmenu title="ENTRA ID">
                  <NavDropdown.Item href="/entraidimportwiz">
                    IMPORT WIZARD
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/entraidimportstatus">
                    IMPORT STATUS
                  </NavDropdown.Item>
                </DropdownSubmenu>
                <DropdownSubmenu title="PROCORE">
                  <NavDropdown.Item href="/procoreimportwiz">
                    IMPORT WIZARD
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/procoreimportstatus">
                    IMPORT STATUS
                  </NavDropdown.Item>
                </DropdownSubmenu>

                <DropdownSubmenu title="QUICKBOOKS">
                  <NavDropdown.Item href="/quickbooksimportwiz">
                    IMPORT WIZARD
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/quickbooksimportstatus">
                    IMPORT STATUS
                  </NavDropdown.Item>
                </DropdownSubmenu>
              </NavDropdownMenu>
              <NavDropdownMenu
                href="#action/4.1"
                title="REVIEW UPDATES"
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item
                  href="/pendingcreationlist"
                  onClick={() => {
                    history.push({
                      pathname: "/pendingcreationlist",
                      state: { appname: "", accountid: "" },
                    });
                  }}
                >
                  PENDING CREATION
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/pendingupdatelist"
                  onClick={() => {
                    history.push({
                      pathname: "/pendingupdatelist",
                      state: { appname: "", accountid: "" },
                    });
                  }}
                >
                  PENDING UPDATE
                </NavDropdown.Item>
                <NavDropdown.Item href="/pendingverificationlist">
                  PENDING VERIFICATION
                </NavDropdown.Item>
              </NavDropdownMenu>
              <Nav.Link
                href="/contactlist"
                exact
                className="nav-link text-decoration-none"
                activeClassName="router-link-exact-active"
              >
                CONTACT LIST
              </Nav.Link>
            </Nav>
          )}
        </div>
      )}
    </>
  );
};

export default MainNav;
