// src/components/login-button.js

import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
const SignupButton = () => {
  function gotosignup() {
    window.location.href = "/Signup/0/0";
  }

  const { loginWithRedirect } = useAuth0();
  return (
    <button
      className="btn btn-primary btn-block"
      onClick={() => {
        gotosignup();
      }}
    >
      Sign Up
    </button>
  );
};

export default SignupButton;
