import React, { useState } from "react";
import { Button } from "react-bootstrap";

import { isEmail } from "validator";
import SweetAlert from "react-bootstrap-sweetalert";
import { useAuth0 } from "@auth0/auth0-react";
import PageSubHeader from "../../components/pagesubheader"
const ChangePassword = () => {
  //state variavles
  const { user, isAuthenticated, logout } = useAuth0();
  const [isvaliddata, setisvaliddata] = useState(false);
  const [isChangePassword, setisChangePassword] = useState(false);
  const [passwordinfo, setpasswordinfo] = useState({
    user_id: "",
    realm_user_id: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    confirm_password: "",
    remoteerror: "",
  });

  const [isFailure, setisFailure] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  //table functions

  const handleChange = (event) => {
    const path = event.target.name.split(".");
    const finalProp = path.pop();
    const newpassword = { ...passwordinfo };
    let pointer = newpassword;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setpasswordinfo(newpassword);
    handleFieldValidation(event);
    setisvaliddata(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (isAuthenticated) {
        try {
          ChangePassword();
        } catch (err) {
          setErrors({ ...errors, remoteerror: err });
        }
      }
    }
  };
  async function ChangePassword() {
    setLoading(true);
    // console.log(JSON.stringify(values));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = {
      email: user.email,
      authId: user.sub,
      password: passwordinfo.password,
    };
    raw = JSON.stringify(raw);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("/changepasswordbyemail", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (result.toUpperCase().includes("[ERROR]:")) {
          // console.log(result);
          var message = result.replace("[error]:", "");
          var errmsg = JSON.parse(message);
          //console.log(JSON.stringify(errmsg));
          seterrorMessage(errmsg);
          setisFailure(true);
        } else {
          setisChangePassword(true);
        }
      })
      .catch((error) => console.log("error", error));
    setLoading(false);
  }
  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };

  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      password:
        passwordinfo.password === ""
          ? (iserror = "Password required")
          : errors.email,

      password:
        passwordinfo.password === ""
          ? (iserror = "Password required")
          : errors.password,
      confirm_password:
        passwordinfo.confirm_password === ""
          ? (iserror = "Confirm password required")
          : passwordinfo.confirm_password !== passwordinfo.password
          ? (iserror = "Password & confirm password are mismatched")
          : errors.confirm_password,
    });
    // console.log(iserror);
    return iserror.length === 0;
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "password":
        localerror =
          value.length < 8 ? "password must be at least 8 characters" : "";
        break;
      case "confirm_password":
        localerror =
          value != passwordinfo.password
            ? "Password & confirm password are mismatched"
            : "";
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
  };

  function handleSweetAlert() {
    window.location.href = "/contactprofile";
  }
  function handleSweetAlert1() {
    setisChangePassword(false);
    logout({
      returnTo: window.location.origin,
    });
  }

  return (
    <div className="divcontainer px-3">
  
      <PageSubHeader title={"Change password"}/>
      <form noValidate>
        <div className="row">
          <div className="mb-3">
            <input
              type="text"
              name="email"
              id="email"
              className="form-control"
              value={user.email}
              noValidate
              disabled={true}
            />
            {errors.email.length > 0 && (
              <small className="form-check-label text-red" htmlFor="email">
                {errors.email}
              </small>
            )}
          </div>
          <div className="mb-3">
            <input
              type="password"
              name="password"
              id="password"
              className="form-control"
              onChange={handleChange}
              placeholder="Enter new password*"
              noValidate
            />
            {errors.password.length > 0 && (
              <small className="text-danger" htmlFor="password">
                {errors.password}
              </small>
            )}
          </div>

          <div className="mb-3">
            <input
              type="password"
              name="confirm_password"
              id="confirm_password"
              className="form-control"
              placeholder="Re enter new password*"
              value={passwordinfo.confirm_password}
              onChange={handleChange}
              noValidate
            />
            {errors.confirm_password.length > 0 && (
              <small className="text-danger" htmlFor="confirm_password">
                {errors.confirm_password}
              </small>
            )}
          </div>
        </div>

        <div className="d-flex justify-content-center gap-2">
          <Button
            type="submit"
            onClick={handleSubmit}
            disabled={!isvaliddata}
            className="btn btn-sm btn-success me-2"
          >
            <i className="fa fa-save"></i>&nbsp; Change password
          </Button>
          <Button
            className="me-1"
            color="default"
            size="md"
            onClick={handleSweetAlert}
          >
            Cancel
          </Button>
        </div>
      </form>

      <div>
        {isChangePassword && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title="Password changed successfully.."
            onConfirm={handleSweetAlert1}
          ></SweetAlert>
        )}
        {errors.remoteerror.length > 0 && (
          <small className="form-check-label text-red" htmlFor="remoteerror">
            {errors.remoteerror}
          </small>
        )}
      </div>
    </div>
  );
};

export default ChangePassword;
