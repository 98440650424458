//services for getuserbyid
export const getContactVerificationTaskByID = async (
  _id,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.getContactVerificationTaskByID(
      _id,
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const getcontactverifciationdetailByToken = async (
  token,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.getcontactverifciationdetailByToken(
      token,
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const resendSignupURL = async (token, Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.resendSignupURL(token, loggedin_by);
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
export const reCreateAffiliationContactVerification = async (
  user,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data =
      await Ctechapp.functions.reCreateAffiliationContactVerification(
        JSON.stringify(user),
        loggedin_by
      );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const resendSignupURLGenerate = async (_id, Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.resendSignupURLGenerate(
      _id,
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateContactVerificationTaskByID = async (
  contact,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.updateContactVerificationTaskByID(
      JSON.stringify(contact),
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateContactVerificationTaskByID_AutoSave = (
  contact,
  Ctechapp,
  loggedin_by
) => {
  try {
    Ctechapp.functions.updateContactVerificationTaskByID_AutoSave(
      JSON.stringify(contact),
      loggedin_by
    );
  } catch (error) {
    console.log("Error", error);
  }
};
