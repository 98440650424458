//services for getuser
export const getImportedStatus = async (
  Appname,
  contactstatus,
  companyColumnName,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.getImportedStatus(
      Appname,
      contactstatus,
      companyColumnName,
      "",
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

//services for getCommandpostImportedStatus
export const getCommandpostImportedStatus = async (
  Appname,
  contactstatus,
  Ctechapp,
  loggedin_by
) => {
  try {
    const data = await Ctechapp.functions.getCommandpostImportedStatus(
      Appname,
      contactstatus,
      "",
      loggedin_by
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

//services for createProcoreImportcontacttask
export const createImportContactTask = async (
  batch_coll_info,
  contact_task_list,

  Ctechapp
) => {
  try {
    const data = await Ctechapp.functions.createImportContactTask(
      batch_coll_info,
      contact_task_list
    );
    if (data !== undefined) {
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
