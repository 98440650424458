import { Button, Form } from "react-bootstrap";
import * as Realm from "realm-web";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import SweetAlert from "react-bootstrap-sweetalert";

import { getLinkedinuserhistoryByID } from "../../services/linkedin_users_history_services";

import { isEmail } from "validator";

function LinkedInProfile() {
  const [initialval, setInitialval] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [clientid, setClientid] = useState("");
  const [clientsecret, setClientsecret] = useState("");
  const [redirecturi, setRedirecturi] = useState("");
  const [firstname, SetFirstname] = useState("");
  const [lastname, SetLastname] = useState("");
  const [phone, SetPhone] = useState("");
  const [email, SetEmail] = useState("");
  const [password, SetPassword] = useState("");
  const [confirmpassword, SetConfirmpassword] = useState("");
  const [isloading, setisloading] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [isFailure, setisFailure] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [validatemsg, SetValidatemsg] = useState("");
  const [isvaliddata, setisvaliddata] = useState(false);
  const { ids } = useParams();

  const handleChange = (event) => {
    SetValidatemsg("");
    const path = event.target.name.split(".");
    const finalProp = path.pop();
    const newuser = { ...initialval };
    let pointer = newuser;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setInitialval(newuser);
    handleFieldValidation(event);
    setisvaliddata(true);
  };
  const handleFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "firstname":
        localerror =
          value.length < 3 ? "First Name must be at least 3 characters" : "";
        break;
      case "lastname":
        localerror =
          value.length < 1 ? "Last Name must be at least 1 characters" : "";
        break;
        // case "phone":
        //   localerror =
        //     value.length < 10 ? "Phone must be at least 10 characters" : "";
        break;
      case "email":
        localerror = !isEmail(value) ? "Invalid Email" : "";
        break;
      case "password":
        localerror =
          value.length < 8 ? "Password must be at least 8 characters" : "";
        break;

      default:
        break;
    }
    setErrors({ ...errors, [name]: localerror });
    setisvaliddata(true);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      await saveProfile(initialval);
    }
  };

  //error handling functions
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  function gotoLogin() {
    window.location.href = "/";
  }
  const handleSucessSweetAlert = () => {
    setisSuccess(false);
    gotoLogin();
  };
  const handleFailureSweetAlert = () => {
    setisFailure(false);
  };

  const handleFieldRequiredValidation = () => {
    let iserror = "";

    setErrors({
      ...errors,
      firstname:
        initialval.firstname === ""
          ? (iserror = "First name required")
          : errors.firstname,
      lastname:
        initialval.lastname === ""
          ? (iserror = "Last name required")
          : errors.lastname,
      email:
        initialval.email === "" ? (iserror = "Email required") : errors.email,
      // phone:
      //   initialval.phone === ""
      //     ? (iserror = "Phone Number required")
      //     : errors.phone,
      password:
        initialval.password === ""
          ? (iserror = "Password required")
          : errors.password,
    });

    return iserror.length === 0;
  };

  useEffect(() => {
    if (ids !== "0") {
      getDatalinkedinhistory();
    }
  }, []);

  async function getDatalinkedinhistory() {
    // Get the API key from the local environment
    const apiKey = process.env.REACT_APP_APIKEY;
    if (!apiKey) {
      throw new Error("Could  not find a Realm Server API Key.");
    }
    const credentials = Realm.Credentials.apiKey(apiKey);
    const localapp = new Realm.App(process.env.REACT_APP_REALM_APP_ID);

    localapp.logIn(credentials).then(() => {
      getLinkedinuserhistoryByID(
        ids,
        localapp.currentUser,
        localapp.currentUser.id
      ).then((response) => {
        let user_info = JSON.parse(response);
        if (user_info !== undefined || user_info.length !== 0) {
          const res = user_info[0];
          setClientid(res.clientid.trim());
          setClientsecret(res.clientsecret.trim());
          setRedirecturi(res.redirecturi.trim());
          setInitialval(res);
        }
      });
    });
    if (localapp.currentUser !== null && localapp.currentUser !== undefined) {
      await localapp.removeUser(localapp.currentUser);
    }
  }

  async function saveProfile(values) {
    setLoading(true);
    // console.log(JSON.stringify(values));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(initialval);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("/createdirectuserbyemail", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (result.toUpperCase().includes("[ERROR]:")) {
          // console.log(result);
          var message = result.replace("[error]:", "");
          var errmsg = JSON.parse(message);
          //console.log(JSON.stringify(errmsg));
          seterrorMessage(errmsg);
          setisFailure(true);
        } else {
          setisSuccess(true);
        }
      })
      .catch((error) => console.log("error", error));
    setLoading(false);
  }

  function getprofile() {
    var width = 450,
      height = 730,
      left = window.screen.width / 2 - width / 2,
      top = window.screen.height / 2 - height / 2;
    let state_var = {
      clientid: clientid.trim(),
      clientsecret: clientsecret.trim(),
      redirecturi: redirecturi.trim(),
    };

    var jsonstate = JSON.stringify(state_var);
    var oauthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientid.trim()}&scope=r_liteprofile,r_emailaddress&state=${jsonstate}&redirect_uri=${redirecturi.trim()}`;

    var parameters =
      "location=1, width=" +
      width +
      ", height=" +
      height +
      ", top=" +
      top +
      ", left=" +
      left;
    var win = window.open(oauthUrl, "Linkedin", parameters);
    var pollOAuth = window.setInterval(function () {
      try {
        if (win.document.URL.indexOf("code") !== -1) {
          window.clearInterval(pollOAuth);
          win.close();
        }
      } catch (e) {
        console.log(e);
      }
    }, 1000);
  }

  function gotoHome() {
    window.location.href = "/";
  }

  return (
    <div className="px-3">
      <h3 align="center">Linked-in</h3>
      <Form>
        <div>
          <div className="row mb-3">
            <label className="col-sm-12 col-md-2">
              Client ID <span style={{ color: "red" }}>*</span>
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                placeholder="client id"
                name="_clientid"
                className="form-control"
                value={clientid}
                onChange={(e) => {
                  setClientid(e.target.value);
                }}
                autoComplete="new-clientid"
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-12 col-md-2">
              {" "}
              Client Secret <span style={{ color: "red" }}>*</span>
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                placeholder="client secret"
                name="_clientsecret"
                className="form-control"
                value={clientsecret}
                onChange={(e) => {
                  setClientsecret(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-12 col-md-2">
              {" "}
              Redirect Url <span style={{ color: "red" }}>*</span>
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                placeholder="redirect uri"
                name="_redirecturl"
                className="form-control "
                value={redirecturi}
                onChange={(e) => {
                  setRedirecturi(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center gap-2 mb-3">
            <pre id="accessToken"></pre>
            <Button
              variant="primary"
              id="procoreToken"
              onClick={() => getprofile()}
              className="btn btn-success"
            >
              Fetch Profile
            </Button>
            <Button variant="secondary" onClick={() => gotoHome()}>
              Cancel
            </Button>
          </div>
          <hr></hr>
          <div className="row mb-3">
            <label className="col-sm-12 col-md-2">
              First Name <span style={{ color: "red" }}>*</span>
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                placeholder="First Name"
                name="firstname"
                id="firstname"
                className="form-control"
                value={initialval.firstname}
                onChange={handleChange}
              />
              {errors.firstname.length > 0 && (
                <small
                  className="form-check-label text-danger"
                  htmlFor="firstname"
                >
                  {errors.firstname}
                </small>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-12 col-md-2">
              Last Name <span style={{ color: "red" }}>*</span>
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                placeholder="Last Name"
                name="lastname"
                id="lastname"
                className="form-control"
                value={initialval.lastname}
                onChange={handleChange}
              />
              {errors.lastname.length > 0 && (
                <small
                  className="form-check-label text-danger"
                  htmlFor="lastname"
                >
                  {errors.lastname}
                </small>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-12 col-md-2">
              Email <span style={{ color: "red" }}>*</span>
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                placeholder="Email"
                name="email"
                className="form-control"
                value={initialval.email}
                onChange={handleChange}
              />
              {errors.email.length > 0 && (
                <small className="form-check-label text-danger" htmlFor="email">
                  {errors.email}
                </small>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-12 col-md-2">Phone </label>
            <div className="col-sm-10">
              <input
                type="number"
                placeholder="Phone"
                name="phone"
                id="phone"
                className="form-control"
                value={initialval.phone}
                onChange={handleChange}
              />
              {errors.phone.length > 0 && (
                <small className="form-check-label text-danger" htmlFor="phone">
                  {errors.phone}
                </small>
              )}
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-12 col-md-2">
              Create Password <span style={{ color: "red" }}>*</span>
            </label>
            <div className="col-sm-10">
              <input
                type="password"
                placeholder="Password"
                name="password"
                id="password"
                className="form-control"
                value={initialval.password}
                autoComplete="new-password"
                onChange={handleChange}
              />
              {errors.password.length > 0 && (
                <small
                  className="form-check-label text-danger"
                  htmlFor="password"
                >
                  {errors.password}
                </small>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-center gap-2 mb-3">
            <Button
              variant="primary"
              id="procoreToken"
              onClick={handleSubmit}
              className="btn btn-success btn-sm"
            >
              Save
            </Button>
            <Button variant="secondary" onClick={() => gotoHome()}>
              Cancel
            </Button>
          </div>
        </div>
        <div>
          {isSuccess && (
            <SweetAlert
              success
              style={{ color: "black" }}
              title="Linkedin user added successfully."
              onConfirm={handleSucessSweetAlert}
            ></SweetAlert>
          )}
        </div>

        <div>
          {isFailure && (
            <SweetAlert
              danger
              style={{ color: "black" }}
              title="Linkedin user failed."
              onConfirm={handleFailureSweetAlert}
            >
              {errorMessage}
            </SweetAlert>
          )}
        </div>
      </Form>
    </div>
  );
}

export default LinkedInProfile;
