//services for getprojectlistbyuserid
export const getprojectlistbyuserid = async (userid, Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.getprojectlistbyuserid(
      userid,
      loggedin_by
    );
    if (data !== undefined) {
      // console.log("data of getprojectlistbyuserid: " + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

//services for insert_procoreprojects_events_history
export const insert_procoreprojects_events_history = async (
  appname,
  appenvironment,
  restapihostname,
  apiappcompanyid,
  apiclientid,
  apiclientsecret,
  apirefreshtoken,
  apiaccesstokenhostname,
  apiredirecturi,
  companyid,
  projectid,
  projectname,
  projectdescription,
  Ctechapp,
  loggedinby
) => {
  try {
    const userdata = {
      appname: appname,
      appenvironment: appenvironment,
      restapihostname: restapihostname,
      apiappcompanyid: apiappcompanyid,
      apiclientid: apiclientid,
      apiclientsecret: apiclientsecret,
      apirefreshtoken: apirefreshtoken,
      apiaccesstokenhostname: apiaccesstokenhostname,
      apiredirecturi: apiredirecturi,
      companyid: companyid,
      projectid: projectid,
      projectname: projectname,
      projectdescription: projectdescription,
    };
    const data = await Ctechapp.functions.insert_procoreprojects_events_history(
      JSON.stringify(userdata),
      loggedinby
    );
    console.log("status: success");
    // console.log("data", data);
    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};
