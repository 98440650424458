//services for GetQuickBooksEmployeeListByCompanyID
export const GetQuickBooksEmployeeListByCompanyID = async (
  QuickBooksCompanyID,
  QuickBooksAccessTokenHostName,
  QuickBooksClientID,
  QuickBooksClientSecret,
  QuickBooksRefreshToken,
  QuickBooksAppToken,
  QuickBooksRedirectUri,
  QuickBooksRestAPIHostName,
  Ctechapp,
  loggedinby
) => {
  try {
    const userdata = {
      QuickBooksRestAPIHostName: QuickBooksRestAPIHostName,
      QuickBooksAccessTokenHostName: QuickBooksAccessTokenHostName,
      QuickBooksClientID: QuickBooksClientID,
      QuickBooksClientSecret: QuickBooksClientSecret,
      QuickBooksRedirectUri: QuickBooksRedirectUri,
      QuickBooksRefreshToken: QuickBooksRefreshToken,
      QuickBooksAppToken: QuickBooksAppToken,
      QuickBooksCompanyID: QuickBooksCompanyID,
    };
    const data = await Ctechapp.functions.GetQuickBooksEmployeeListByCompanyID(
      JSON.stringify(userdata),
      loggedinby
    );
    if (data !== undefined) {
      // console.log("status: success");
      return data;
    }
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

//services for GetImportUserListFromQuickBooks
export const GetImportUserListFromQuickBooks = async (
  QuickBooksCompanyID,
  QuickBooksAccessTokenHostName,
  QuickBooksClientID,
  QuickBooksClientSecret,
  QuickBooksRefreshToken,
  QuickBooksAppToken,
  QuickBooksRedirectUri,
  QuickBooksRestAPIHostName,
  Ctechapp,
  loggedinby
) => {
  try {
    const userdata = {
      QuickBooksRestAPIHostName: QuickBooksRestAPIHostName,
      QuickBooksAccessTokenHostName: QuickBooksAccessTokenHostName,
      QuickBooksClientID: QuickBooksClientID,
      QuickBooksClientSecret: QuickBooksClientSecret,
      QuickBooksRedirectUri: QuickBooksRedirectUri,
      QuickBooksRefreshToken: QuickBooksRefreshToken,
      QuickBooksAppToken: QuickBooksAppToken,
      QuickBooksCompanyID: QuickBooksCompanyID,
    };
    const data = await Ctechapp.functions.getImportUserListFromQuickBooks(
      JSON.stringify(userdata),
      loggedinby
    );
    if (data !== undefined) {
      // console.log("status: success");
      return data;
    }
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

//services for getCompanyListFromQuickBooks
export const getCompanyListFromQuickBooks = async (
  QuickBooksCompanyID,
  QuickBooksAccessTokenHostName,
  QuickBooksClientID,
  QuickBooksClientSecret,
  QuickBooksRefreshToken,
  QuickBooksAppToken,
  QuickBooksRedirectUri,
  QuickBooksRestAPIHostName,
  Ctechapp,
  loggedinby
) => {
  try {
    const companydata = {
      QuickBooksRestAPIHostName: QuickBooksRestAPIHostName,
      QuickBooksAccessTokenHostName: QuickBooksAccessTokenHostName,
      QuickBooksClientID: QuickBooksClientID,
      QuickBooksClientSecret: QuickBooksClientSecret,
      QuickBooksRedirectUri: QuickBooksRedirectUri,
      QuickBooksRefreshToken: QuickBooksRefreshToken,
      QuickBooksAppToken: QuickBooksAppToken,
      QuickBooksCompanyID: QuickBooksCompanyID,
    };
    const data = await Ctechapp.functions.getCompanyListFromQuickBooks(
      companydata,
      loggedinby
    );
    if (data !== undefined) {
      console.log("status: success");
      return data;
    }
  } catch (error) {
    console.log("getCompanyListFromQuickBooks services error : ", error);
    throw Error(error);
  }
};

export const GetQuickBooksEmployeeByID = async (
  QuickBooksApiInfo,
  Ctechapp,
  loggedinby
) => {
  try {
    const data = await Ctechapp.functions.GetQuickBooksEmployeeByID(
      JSON.stringify(QuickBooksApiInfo),
      loggedinby
    );
    if (data !== undefined) {
      // console.log("status: success");
      return data;
    }
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

export const GetQuickBooksContactRole = async (Ctechapp, loggedinby) => {
  try {
    const data = await Ctechapp.functions.GetQuickBooksContactRole(loggedinby);
    if (data !== undefined) {
      // console.log("status: success");
      return JSON.stringify(data);
    }
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

export const getQuickbooksImportUserListByCompanyID = async (
  accountid,
  Ctechapp,
  loggedinby
) => {
  try {
    const data =
      await Ctechapp.functions.getQuickbooksImportUserListByCompanyID(
        accountid,
        loggedinby
      );

    return data;
  } catch (error) {
    console.log("error : ", error);
    throw Error(error);
  }
};

export const getQuickBooksDedupByIDs = async (arg, quickbooksApptokenInfo, Ctechapp) => {
  try {
    const data = await Ctechapp.functions.getQuickBooksDedupByIDs(
      arg,
      quickbooksApptokenInfo,
      Ctechapp.id
    );
    return data;
  } catch (error) {
    console.log("getQuickBooksDedupByIDs error : ", error);
    throw Error(error);
  }
};