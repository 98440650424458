import { useEffect, useState } from "react";
import { useRealmApp } from "../../providers/RealmApp";
import { getAdminVerificationTaskPendingList } from "../../services/adminVerificationTask_services";
import { useAuth0 } from "@auth0/auth0-react";
import { appConstants } from "../../constants/app_constants";
import PendingVerificationCard from "../../components/PendingVerificationCard";
function Pendingverificationlist() {
  const [Contacts, setcontacts] = useState([]);
  const [vaildmsg, setvaildmsg] = useState(false);
  const ctechapp = useRealmApp();
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  useEffect(() => {
    if (isAuthenticated) {
      getData();
    }

    async function getData() {
      getAdminVerificationTaskPendingList(ctechapp.currentUser, user.sub).then(
        (response) => {
          if (response !== undefined) {
            let user_list = JSON.parse(response);
            console.log("user_list", user_list);
            if (user_list.length === 0) {
              setvaildmsg(true);
            }
            setcontacts(user_list);
          }
        }
      );
    }
  }, []);

  return (
    <div className="mt-3">
      <PendingVerificationCard contact={Contacts} />
      {vaildmsg && (
        <div className="form-check-label  text-center" htmlFor="validation">
          <h3 className="text-danger ">
            {appConstants.import.handlefieldvalidation.nodata}
          </h3>
        </div>
      )}
    </div>
  );
}
export default Pendingverificationlist;
