import { Button, Table } from "react-bootstrap";

import { useEffect, useState } from "react";
import {
  getContactDetailByID,
  updateContactDetailByID,
} from "../../services/contactDetailsServices";
import { getPendingCreationTaskByID } from "../../services/pending_create_task_services";
import { getPendingUpdateTaskByID } from "../../services/pending_update_task_services";
import { useParams } from "react-router";
import Select from "react-select";
import customstyles from "../../components/customstyles/react-select-styles";
import { useAuth0 } from "@auth0/auth0-react";
import { getuserbyauthproviderid } from "../../services/users_services";
import {} from "../../services/users_services";
import { useRealmApp } from "../../providers/RealmApp";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import { isEmail } from "validator";
import {
  getAllCountryCodes,
  getCollectionListsByFormName,
} from "../../services/collection_lists_services";
import SweetAlert from "react-bootstrap-sweetalert";
import PhoneInput from "react-phone-input-2";

function DetailContact({ title }) {
  const ctechapp = useRealmApp();
  const history = useHistory();

  const userid = useLocation().state.contact_id;
  const listpagename = useLocation().state.listpagename;
  const [userrole, setUserrole] = useState("1");
  const [loading, setLoading] = useState(false);
  const [PhoneArray, setPhoneArray] = useState([]);
  const [AddressArray, setAddressArray] = useState([]);
  const [EmailArray, setEmailArray] = useState([]);
  const [countryCodeLists, setCountryCodeLists] = useState([]);
  const [salutationOptions, setSalutationOption] = useState([]);
  const [genderOptions, setGenderOption] = useState([]);
  const [phoneOptions, setPhoneOption] = useState([]);
  const [addressOptions, setAddressOption] = useState([]);
  const [userRoleOptions, setUserRoleOption] = useState([]);
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  const [defaultcountry, setdefaultcountry] = useState(1);
  const [isSuccess, setisSuccess] = useState(false);
  const initialpersonalPhoneState = {
    id: "",
    type: "",
    phone: "",
    ext: "",
    extphone: "",
    fullPhone: "",
    country: "",
    isMain: 0,
  };
  const [personalPhone, setPersonalPhone] = useState(initialpersonalPhoneState);
  const [personalPhoneErrors, setPersonalPhoneErrors] = useState(
    initialpersonalPhoneState
  );
  const initialpersonalAddressState = {
    id: "",
    addressLabel: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    country: "",
    state: "",
    postalCode: "",
    isMain: 0,
  };

  const [personalAddress, setPersonalAddress] = useState(
    initialpersonalAddressState
  );
  const [personalAddressErrors, setPersonalAddressErrors] = useState(
    initialpersonalAddressState
  );

  const initialpersonalEmailState = {
    id: "",
    label: "",
    email: "",
    isMain: 0,
  };
  const [personalEmail, setPersonalEmail] = useState(initialpersonalEmailState);
  const [personalEmailErrors, setPersonalEmailErrors] = useState(
    initialpersonalEmailState
  );
  const initialbusinessPhoneState = {
    id: "",
    type: "",
    phone: "",
    ext: "",
    extphone: "",
    country: "",
    isMain: 0,
  };

  const initialbusinessPhoneErrorState = {
    id: "",
    type: "",
    phone: "",
    ext: "",
    extphone: "",
    country: "",
    isMain: 0,
  };
  const [businessPhone, setBusinessPhone] = useState(initialbusinessPhoneState);
  const [businessPhoneErrors, setBusinessPhoneErrors] = useState(
    initialbusinessPhoneErrorState
  );
  const initialbusinessEmailState = {
    id: "",
    label: "",
    email: "",
    isMain: 0,
  };

  const initialbusinessEmailErrorState = {
    id: "",
    label: "",
    email: "",
    isMain: 0,
  };
  const [businessEmail, setBusinessEmail] = useState(initialbusinessEmailState);
  const [businessEmailErrors, setBusinessEmailErrors] = useState(
    initialbusinessEmailErrorState
  );

  const initialbusinessAddressState = {
    id: "",
    addressLabel: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    country: "",
    state: "",
    postalCode: "",
    isMain: 0,
  };
  const initialbusinessAddressErrorState = {
    id: "",
    addressLabel: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    country: "",
    state: "",
    postalCode: "",
    isMain: 0,
  };

  const [businessAddress, setBusinessAddress] = useState(
    initialbusinessAddressState
  );
  const [businessAddressErrors, setBusinessAddressErrors] = useState(
    initialbusinessAddressErrorState
  );

  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    email: "",
  });
  const initialContactState = {
    userid: "",
    authId: "",
    salutation: "",
    firstname: "",
    lastname: "",
    middlename: "",
    phone: "",
    email: "",
    gender: "",
    dateOfBirth: "",
    nickName: "",
    contactOwner: "",
    description: "",
    contactRole: "",
    role: "",
    PhoneArray: [],
    AddressArray: [],
    EmailArray: [],
    contactAffiliationsArray: [],
  };
  const [contactErrors, setContactErrors] = useState(initialContactState);

  const [contact, setContact] = useState(initialContactState);

  const initialbusinessContact = {
    id: uuid(),
    companyName: "",
    contactOwner: "",
    description: "",
    role: "",
    contactRole: "",
    sourceSystem: "",
    sourceSystemId: "",
    contactRecordType: "",
    department: "",
    reportTo: "",
    claimedUrl: "",
    phoneArray: [],
    addressArray: [],
    emailArray: [],
  };

  const [businessContact, setBusinessContact] = useState(
    initialbusinessContact
  );
  const [businessContactErrors, setBusinessContactErrors] = useState(
    initialbusinessContact
  );

  const [isvaliddata, setisvaliddata] = useState(false);
  const [userInfo, setUserInfo] = useState(false);
  const [userphoto, setuserphoto] = useState("../assets/img/usericon.png");

  useEffect(() => {
    if (isAuthenticated) {
      //GetAllCountries();
      // GetAllStates();
      GetAllCountryCodes();
      GetSalutationOptions();
      GetGenderOptions();
      GetPhoneOptions();
      GetAddressOptions();
      GetUserRoleOptions();

      if (listpagename === "pendingcreate") {
        GetPendingCreationContactByID();
        //  title = "Pending Creation Contact Details";
      } else if (listpagename === "pendingupdate") {
        GetPendingUpdateContactByID();
        //   title = "Pending Update Contact Details";
      } else {
        getUserInfoByID();
      }
      if (userid === undefined) {
        setuserphoto(user.picture);
      }
    }
  }, [user.sub]);
  async function getUserInfoByID() {
    try {
      setLoading(true);
      await getuserbyauthproviderid(user.sub, ctechapp.currentUser).then(
        (response) => {
          let user_info = JSON.parse(response);
          if (response !== undefined || user_info.length !== 0) {
            setUserInfo(user_info[0]);
            GetContactDetailByID(user_info[0]?._id);
          }
        }
      );
    } catch (exception) {
      setLoading(false);
      console.log(exception);
    }
  }

  async function GetPendingCreationContactByID() {
    await getPendingCreationTaskByID(
      userid,
      ctechapp.currentUser,
      user.sub
    ).then((response) => {
      if (response !== undefined) {
        let user_info = JSON.parse(response);
        console.log("user_info", user_info[0]);
        var _contact = user_info[0];
        console.log("_contact", _contact);
        setContact(_contact);
        setEmailArray(_contact.emailArray);
        setAddressArray(_contact.addressArray);
        setPhoneArray(_contact.phoneArray);
      }
    });
  }
  async function GetPendingUpdateContactByID() {
    await getPendingUpdateTaskByID(userid, ctechapp.currentUser, user.sub).then(
      (response) => {
        if (response !== undefined) {
          let user_info = JSON.parse(response);
          console.log("user_info", user_info[0]);
          var _contact = user_info[0];
          console.log("_contact", _contact);
          setContact(_contact);
          setEmailArray(_contact.emailArray);
          setAddressArray(_contact.addressArray);
          setPhoneArray(_contact.phoneArray);
        }
      }
    );
  }

  async function GetContactDetailByID(currentuser_id) {
    let useridvalue = userid === undefined ? currentuser_id : userid;
    let usertype = userid === undefined ? "1" : "2";

    console.log("useridvalue: ", useridvalue);

    await getContactDetailByID(
      useridvalue,
      usertype,
      ctechapp.currentUser,
      user.sub
    ).then((response) => {
      if (response !== undefined) {
        let user_info = JSON.parse(response);

        var _contact = user_info[0];
        // if (_contact?.contactAffiliationsArray == null) {
        //   _contact.contactAffiliationsArray = [];
        // //  _contact.contactAffiliationsArray.push(initialbusinessContact);
        // } else {
        //   _contact?.contactAffiliationsArray?.push(initialbusinessContact);
        // }
        console.log("_contact", _contact);
        //personal info
        setContact(_contact);
        setPhoneArray(_contact?.phoneArray);
        setAddressArray(_contact?.addressArray);
        setEmailArray(_contact?.emailArray);
      }
    });
  }

  async function GetAllCountryCodes() {
    await getAllCountryCodes(ctechapp.currentUser).then((response) => {
      if (response !== undefined) {
        let countrycode_info = JSON.parse(response);

        let options = countrycode_info.map(function (country) {
          return { value: country._id, label: country.name };
        });

        setCountryCodeLists(options);
      }
    });
  }

  async function GetSalutationOptions() {
    await getCollectionListsByFormName("Salutation", ctechapp.currentUser).then(
      (response) => {
        if (response !== undefined) {
          let salutation_info = JSON.parse(response);

          let options = salutation_info.map(function (salutation) {
            return { value: salutation.listValue, label: salutation.listName };
          });

          setSalutationOption(options);
        }
      }
    );
  }
  async function GetGenderOptions() {
    await getCollectionListsByFormName("Gender", ctechapp.currentUser).then(
      (response) => {
        if (response !== undefined) {
          let gender_info = JSON.parse(response);

          let options = gender_info.map(function (gender) {
            return { value: gender.listValue, label: gender.listName };
          });

          setGenderOption(options);
        }
      }
    );
  }

  async function GetPhoneOptions() {
    await getCollectionListsByFormName("Phone", ctechapp.currentUser).then(
      (response) => {
        if (response !== undefined) {
          let phone_info = JSON.parse(response);

          let options = phone_info.map(function (phone) {
            return { value: phone.listValue, label: phone.listName };
          });

          setPhoneOption(options);
        }
      }
    );
  }

  async function GetAddressOptions() {
    await getCollectionListsByFormName("Address", ctechapp.currentUser).then(
      (response) => {
        if (response !== undefined) {
          let address_info = JSON.parse(response);
          let options = address_info.map(function (address) {
            return { value: address.listValue, label: address.listName };
          });
          setAddressOption(options);
        }
      }
    );
  }
  async function GetUserRoleOptions() {
    await getCollectionListsByFormName(
      "ContactRole",
      ctechapp.currentUser
    ).then((response) => {
      if (response !== undefined) {
        let userrole_info = JSON.parse(response);

        let options = userrole_info.map(function (userrole) {
          return {
            value: userrole.listValue,
            label: userrole.listName,
          };
        });

        setUserRoleOption(options);
      }
    });
  }

  const handleChangerole = (event) => {
    setUserrole(event.target.value);
  };
  //save functions
  const handleDropDownChange = (selectedOption, itemupdate, index) => {
    const { name, value } = selectedOption.target;
    if (itemupdate === "contact") {
      console.log(name, value);
      setContact({ ...contact, [name]: value });
      setContactErrors({ ...contactErrors, [name]: "" });
    }
    if (itemupdate === "personalphone") {
      setPersonalPhone({ ...personalPhone, [name]: value });
      setPersonalPhoneErrors({ ...personalPhoneErrors, [name]: "" });
    }
    if (itemupdate === "personaladdress") {
      setPersonalAddress({ ...personalAddress, [name]: value });
      setPersonalAddressErrors({ ...personalAddressErrors, [name]: "" });
    }
    if (itemupdate === "personalemail") {
      setPersonalEmail({ ...personalEmail, [name]: value });
    }
  };

  const handleChange_affiliationlist = (index, evnt) => {
    const { name, value } = evnt.target;
    console.log("name ", name, " ", value);
    console.log(contact);

    const objIndex = contact?.contactAffiliationsArray.findIndex(
      (obj) => obj.id === value
    );
    const rowsInput = [contact?.contactAffiliationsArray[objIndex]];

    setBusinessContact(rowsInput[0]);
    console.log("rowsInput : ", rowsInput[0]);
  };

  const new_affiliationlist = () => {
    let _contact = contact;
    _contact.contactAffiliationsArray = [];
    _contact?.contactAffiliationsArray?.push(initialbusinessContact);
    setContact(_contact);
  };

  function handlecontactPhoneChange(value) {
    console.log(value);

    setContact({ ...contact, ["phone"]: value });
    // handlePersonalFieldValidation(event);
    setisvaliddata(true);
  }

  const handlePersonalChange = (event) => {
    const path = event.target.name.split(".");
    const finalProp = path.pop();
    const newpersonalcontact = { ...contact };
    let pointer = newpersonalcontact;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setContact(newpersonalcontact);
    handlePersonalFieldValidation(event);
    setisvaliddata(true);
  };
  function handleBusinessChange(e, itemId, property, islist) {
    const value = islist === true ? e.value : e.target.value;
    //copying data to temp variable so that we do not directly mutate original state
    const temparrayprops = [...contact.contactAffiliationsArray];
    //findIndex to find location of item we need to update
    let index = temparrayprops.findIndex((item) => item.id == itemId);
    // -1 check to see if we found that object in social_media
    if (index != -1) {
      temparrayprops[index] = {
        ...temparrayprops[index], //keeping existing values in object
        [property]: value, //here property can be "link"
      };
    }
    setContact({ ...contact, contactAffiliationsArray: temparrayprops });
  }

  const handlePersonalFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "firstname":
        localerror =
          value.length < 3 ? "first name must be at least 3 characters" : "";
        break;
      case "lastname":
        localerror =
          value.length < 3 ? "last name must be at least 3 characters" : "";
        break;
      case "email":
        localerror = !isEmail(value) ? "Invalid Email" : "";
        break;
      default:
        break;
    }
    setContactErrors({ ...contactErrors, [name]: localerror });
    setisvaliddata(true);
  };

  const handleBusinessFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "companyName":
        localerror =
          value.length < 3 ? "Company name must be at least 3 characters" : "";
        break;

      default:
        break;
    }
    setBusinessContactErrors({ ...contactErrors, [name]: localerror });
    setisvaliddata(true);
  };
  async function submitHandler(values) {
    if (validPersonalForm()) {
      let useridvalue = userid === undefined ? userInfo._id : userid;
      // console.log("useridvalue ", useridvalue);

      let _contactAffiliationsArray = contact.contactAffiliationsArray;
      if (_contactAffiliationsArray !== null) {
        let lastindex = contact.contactAffiliationsArray.length;
        if (lastindex > 0)
          if (
            contact.contactAffiliationsArray[lastindex - 1].companyName === ""
          ) {
            _contactAffiliationsArray.splice(lastindex - 1, 1);
          }
      }

      updateContactDetailByID(
        useridvalue,
        contact.userid,
        contact.authId,
        contact.firstname,
        contact.lastname,
        contact.middlename,
        contact.email,
        contact.phone,
        contact.gender,
        contact.salutation,
        contact.nickName,
        contact.dateOfBirth,
        contact.contactOwner,
        contact.description,
        contact.contactRole,
        contact.role,
        PhoneArray,
        EmailArray,
        AddressArray,
        _contactAffiliationsArray,
        ctechapp.currentUser,
        user.sub
      ).then((res) => {
        //alert("contact details updated successfully");
        setisSuccess(true);
        //if (userid !== undefined) window.location.href = "/contactlist";
      });
    }
  }
  const handleSucessSweetAlert = () => {
    setisSuccess(false);
    if (userid !== undefined) {
      gotoContactList();
    }
  };
  function gotoContactList() {
    window.location.href = "/contactlist";
  }
  const validBusinessForm = () => {
    try {
      let valid = false;
      valid = handleFieldBusinessListRequiredValidation();
      Object.values(personalEmailErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldBusinessListRequiredValidation = () => {
    let iserror = "";

    setBusinessContactErrors({
      ...businessContactErrors,
      companyName:
        businessContact.companyName === ""
          ? (iserror = "Company name required")
          : businessContact.companyName,
    });

    return iserror.length === 0;
  };

  const validPersonalForm = () => {
    try {
      let valid = false;
      valid = handleFieldPersonalListRequiredValidation();
      Object.values(personalEmailErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldPersonalListRequiredValidation = () => {
    let iserror = "";

    setContactErrors({
      ...contactErrors,
      firstname:
        contact.firstname === ""
          ? (iserror = "First name required")
          : contactErrors.firstname,
      lastname:
        contact.lastname === ""
          ? (iserror = "Last name required")
          : contactErrors.lastname,
      // middlename:
      //   contact.middlename === ""
      //     ? (iserror = "Middle name required")
      //     : contactErrors.middlename,
      email:
        contact.email === ""
          ? (iserror = "Email required")
          : contactErrors.email,
      // phone:
      //   contact.phone === ""
      //     ? (iserror = "Phone required")
      //     : contactErrors.phone,
      //     gender:
      //     contact.gender === ""
      //    ? (iserror = "Gender required")

      //     : contactErrors.gender,
      //     salutation:
      //     contact.salutation === ""
      //   ? (iserror = "Salutation required")
      //   : contactErrors.salutation,
      //     nickName:
      //     contact.nickName === ""
      //   ? (iserror = "Nick name required")
      //   : contactErrors.nickName,
      //     dateOfBirth:
      //     contact.dateOfBirth === ""
      //  ? (iserror = "Date of birth required")

      //     : contactErrors.dateOfBirth,
      //     contactOwner:
      //     contact.contactOwner === ""
      // ? (iserror = "Contact owner required")
      // : contactErrors.contactOwner,
      //     description:
      //     contact.description === ""
      //   ? (iserror = "Description required")
      //   : contactErrors.description,
      //     contactRole:
      //     contact.contactRole === ""
      // ? (iserror = "Contact Role required")
      // : contactErrors.contactRole,
    });

    return iserror.length === 0;
  };

  const handleCancel = () => {
    if (listpagename === "pendingcreate") {
      history.push({
        pathname: "/pendingcreationlist",
      });
    } else if (listpagename === "pendingupdate") {
      history.push({
        pathname: "/pendingupdatelist",
      });
    }
  };

  //personal info
  //button action for personal phone
  const SelectPersonalPhoneRow = (index) => {
    const rowsInput = [...PhoneArray];
    setPersonalPhone(rowsInput[index]);
    setPersonalPhoneErrors(initialpersonalPhoneState);
  };

  const deletePersonalPhoneRow = (index) => {
    const rows = [...PhoneArray];
    rows.splice(index, 1);
    setPhoneArray(rows);
  };

  //button action for personal address
  const SelectPersonalAddressRow = (index) => {
    const rowsInput = [...AddressArray];
    setPersonalAddress(rowsInput[index]);
    setPersonalAddressErrors(initialpersonalAddressState);
  };

  const deletePersonalAddressRow = (index) => {
    const rows = [...AddressArray];
    rows.splice(index, 1);
    setAddressArray(rows);
  };

  //button action for personal email

  const SelectPersonalEmailRow = (index) => {
    const rowsInput = [...EmailArray];
    setPersonalEmail(rowsInput[index]);
    setPersonalEmailErrors(initialpersonalEmailState);
  };

  const deletePersonalEmailRow = (index) => {
    const rows = [...EmailArray];
    rows.splice(index, 1);
    setEmailArray(rows);
  };

  // handle change for personal phone
  const handleChangePersonalPhone = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setPersonalPhone({ ...personalPhone, [name]: value });
    setPersonalPhoneErrors({ ...personalPhoneErrors, [name]: "" });

    if (personalPhone.id === "") {
      setPersonalPhoneErrors({ ...personalPhoneErrors, [name]: "" });
      setPersonalPhone({ ...personalPhone, ["id"]: uuid() });
    }
  };

  // handle change for personal address
  const handleChangePersonalAddress = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setPersonalAddress({ ...personalAddress, [name]: value });
    setPersonalAddressErrors({ ...personalAddressErrors, [name]: "" });
    if (personalAddress.id === "") {
      setPersonalAddressErrors({
        ...personalAddressErrors,
        [name]: "",
      });
      setPersonalAddress({ ...personalAddress, ["id"]: uuid() });
    }
  };
  // handle change for personal email
  const handleChangePersonalEmail = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    handlePersonalEmailFieldValidation(event);
    setPersonalEmail({ ...personalEmail, [name]: value });

    if (personalEmail.id === "") {
      handlePersonalEmailFieldValidation(event);
      setPersonalEmail({ ...personalEmail, ["id"]: uuid() });
    }
  };

  const handlePersonalEmailFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "email":
        localerror = !isEmail(value) ? "Invalid Email" : "";
        break;
      default:
        break;
    }
    setPersonalEmailErrors({ ...personalEmailErrors, [name]: localerror });
    setisvaliddata(true);
  };
  //business info
  // handle change for business phone
  function handleChangeBusinessPhone(e, index, property, islist) {
    let value = "";
    if (property === "phone") value = e;
    else value = islist === true ? e.value : e.target.value;
    if (property === "isMain") {
      value = e.target.checked === true ? 1 : 0;
    }
    setBusinessPhone({ ...businessPhone, [property]: value });
  }
  //button action for Business phone
  //button action for Business address
  const SelectBusinessPhoneRow = (affindex, affemailindex, id) => {
    const rows = [...contact.contactAffiliationsArray[affindex].phoneArray];
    setBusinessPhone(rows[affemailindex]);
    //setBusinessPhoneErrors(initialbusinessPhoneErrorState);
  };

  const deleteBusinessPhoneRow = (index, rowindex) => {
    const rows = [...contact.contactAffiliationsArray[index].phoneArray];
    rows.splice(rowindex, 1);
    setContact((prevcontact) => {
      prevcontact.contactAffiliationsArray[index].phoneArray = rows;
      //    console.log("prevcontact",JSON.stringify(prevcontact));
      setBusinessPhone(initialbusinessPhoneState);
      return prevcontact;
    });
  };

  //button action for Business address
  const SelectBusinessAddressRow = (affindex, affemailindex, id) => {
    const rows = [...contact.contactAffiliationsArray[affindex].addressArray];
    setBusinessAddress(rows[affemailindex]);
    //setBusinessAddressErrors(initialbusinessAddressErrorState);
  };

  const deleteBusinessAddressRow = (index, rowindex) => {
    const rows = [...contact.contactAffiliationsArray[index].addressArray];
    rows.splice(rowindex, 1);
    setContact((prevcontact) => {
      prevcontact.contactAffiliationsArray[index].addressArray = rows;
      //    console.log("prevcontact",JSON.stringify(prevcontact));
      setBusinessAddress(initialbusinessAddressState);
      return prevcontact;
    });
  };

  // add business email
  const addBusinessEmail = (e, index) => {
    e.preventDefault();
    if (validBusinessEmailForm(index)) {
      const _businessEmail = { ...businessEmail };

      var _contact = { ...contact };
      let temparrayprops = [
        ...contact.contactAffiliationsArray[index].emailArray,
      ];

      let record_index = temparrayprops.findIndex(
        (item) => item.id == _businessEmail.id
      );

      if (record_index != -1) {
        temparrayprops[record_index] = _businessEmail;
      } else {
        _businessEmail.id = uuid();
        temparrayprops.push(_businessEmail);
      }
      _contact.contactAffiliationsArray[index].emailArray = temparrayprops;
      setContact(_contact);
      setBusinessEmail(initialbusinessEmailState);
    }
  };

  // clear business email
  const clearBusinessEmail = () => {
    setBusinessEmail(initialbusinessEmailState);
  };

  //button action for Business email
  const SelectBusinessEmailRow = (affindex, affemailindex, id) => {
    const rows = [...contact.contactAffiliationsArray[affindex].emailArray];
    setBusinessEmail(rows[affemailindex]);
  };

  const deleteBusinessEmailRow = (index, rowindex) => {
    const rows = [...contact.contactAffiliationsArray[index].emailArray];
    rows.splice(rowindex, 1);
    setContact((prevcontact) => {
      prevcontact.contactAffiliationsArray[index].emailArray = rows;
      setBusinessEmail(initialbusinessEmailState);
      return prevcontact;
    });
  };

  //generate guid
  function uuid() {
    var uuidValue = "",
      k,
      randomValue;
    for (k = 0; k < 32; k++) {
      randomValue = (Math.random() * 16) | 0;

      if (k == 8 || k == 12 || k == 16 || k == 20) {
        uuidValue += "";
      }
      uuidValue += (
        k == 12 ? 4 : k == 16 ? (randomValue & 3) | 8 : randomValue
      ).toString(16);
    }
    return uuidValue;
  }

  const addPersonalEmail = async (e) => {
    e.preventDefault();
    if (validEmailForm()) {
      //console.log("personalEmail : ", personalEmail);
      if (EmailArray !== null) {
        const objIndex = EmailArray.findIndex(
          (obj) => obj.id === personalEmail.id
        );
        console.log("objIndex ", objIndex);
        if (objIndex != -1) {
          const rowsInput = [...EmailArray];
          rowsInput[objIndex] = personalEmail;

          setEmailArray(rowsInput);
          setPersonalEmail(initialpersonalEmailState);
        } else {
          setEmailArray([...EmailArray, personalEmail]);
          setPersonalEmail(initialpersonalEmailState);
        }
      } else {
        let emailArray = [];
        emailArray.push(personalEmail);
        setEmailArray(emailArray);
        setPersonalEmail(initialpersonalEmailState);
      }
    }
  };
  const validEmailForm = () => {
    try {
      let valid = false;
      valid = handleFieldPersonalEmailListRequiredValidation();
      Object.values(personalEmailErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldPersonalEmailListRequiredValidation = () => {
    let iserror = "";

    setPersonalEmailErrors({
      ...personalEmailErrors,
      label:
        personalEmail.label === ""
          ? (iserror = "Label required")
          : personalEmailErrors.label,
      email:
        personalEmail.email === ""
          ? (iserror = "Email  required")
          : personalEmailErrors.email,
    });

    return iserror.length === 0;
  };

  //add personal address

  const addPersonalAddress = async (e) => {
    e.preventDefault();
    if (validAddressForm()) {
      if (AddressArray !== null) {
        const objIndex = AddressArray.findIndex(
          (obj) => obj.id === personalAddress.id
        );
        if (objIndex !== -1) {
          const rowsInput = [...AddressArray];
          rowsInput[objIndex] = personalAddress;

          setAddressArray(rowsInput);
          setPersonalAddress(initialpersonalAddressState);
        } else {
          setAddressArray([...AddressArray, personalAddress]);
          setPersonalAddress(initialpersonalAddressState);
        }
      } else {
        let addressArray = [];
        addressArray.push(personalAddress);
        setAddressArray(addressArray);
        setPersonalAddress(initialpersonalAddressState);
      }
    }
  };
  const validAddressForm = () => {
    try {
      let valid = false;
      valid = handleFieldPersonalAddressListRequiredValidation();
      Object.values(personalAddressErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldPersonalAddressListRequiredValidation = () => {
    let iserror = "";

    setPersonalAddressErrors({
      ...personalAddressErrors,
      addressLabel:
        personalAddress.addressLabel === ""
          ? (iserror = "Label required")
          : personalAddressErrors.addressLabel,
      addressLine1:
        personalAddress.addressLine1 === ""
          ? (iserror = "Address required")
          : personalAddressErrors.addressLine1,
    });

    return iserror.length === 0;
  };

  //add personal phone

  const addPersonalPhone = async (e) => {
    e.preventDefault();
    if (validPhoneForm()) {
      if (PhoneArray !== null) {
        const objIndex = PhoneArray.findIndex(
          (obj) => obj.id === personalPhone.id
        );
        if (objIndex !== -1) {
          const rowsInput = [...PhoneArray];
          rowsInput[objIndex] = personalPhone;

          setPhoneArray(rowsInput);
          setPersonalPhone(initialpersonalPhoneState);
        } else {
          setPhoneArray([...PhoneArray, personalPhone]);
          setPersonalPhone(initialpersonalPhoneState);
        }
      } else {
        let phoneArray = [];
        phoneArray.push(personalPhone);
        setPhoneArray(phoneArray);
        setPersonalPhone(initialpersonalPhoneState);
      }
    }
  };
  const validPhoneForm = () => {
    try {
      let valid = false;
      valid = handleFieldPersonalPhoneListRequiredValidation();
      Object.values(personalPhoneErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldPersonalPhoneListRequiredValidation = () => {
    let iserror = "";

    setPersonalPhoneErrors({
      ...personalPhoneErrors,
      type:
        personalPhone.type === ""
          ? (iserror = "Type required")
          : personalPhoneErrors.type,
      // country:
      //   personalPhone.country === ""
      //     ? (iserror = "Country code required")
      //     : personalPhoneErrors.country,
      phone:
        personalPhone.phone === ""
          ? (iserror = "Phone required")
          : personalPhoneErrors.phone,
    });

    return iserror.length === 0;
  };

  // handle change for business email
  /*event.preventDefault();
   const { name, value } = event.target;
   setBusinessAddress({ ...businessAddress, [name]: value });
   setBusinessAddressErrors({ ...businessAddressErrors, [name]: "" });
   if (businessAddress.id === "") {
     setBusinessAddressErrors({ ...businessAddressErrors, [name]: "" });
     setBusinessAddress({ ...businessAddress, ["id"]: uuid() });
   }*/
  function handleChangeBusinessEmail(e, index, property, islist) {
    let value = islist === true ? e.value : e.target.value;
    if (property === "isMain") {
      value = e.target.checked === true ? 1 : 0;
    }
    setBusinessEmail({ ...businessEmail, [property]: value });
  }

  const validBusinessEmailForm = (index) => {
    try {
      let valid = false;
      return true;
      valid = handleFieldBusinessEmailListRequiredValidation(index);
      Object.values(personalEmailErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldBusinessEmailListRequiredValidation = (index) => {
    let iserror = "";
    return true;
    setBusinessEmailErrors({
      ...businessEmailErrors,
      label:
        businessEmail[index].label === ""
          ? (iserror = "Label required")
          : businessEmailErrors?.label,
      email:
        businessEmail[index].email === ""
          ? (iserror = "Email  required")
          : businessEmailErrors?.email,
    });

    return iserror.length === 0;
  };

  // handle change for business Address

  function handleChangeBusinessAddress(e, property, islist) {
    let value = islist === true ? e.value : e.target.value;
    if (property === "isMain") {
      value = e.target.checked === true ? 1 : 0;
    }
    setBusinessAddress({ ...businessAddress, [property]: value });
  }
  //add business address
  const addBusinessAddress = (e, index) => {
    e.preventDefault();
    if (validBusinessAddressForm(index)) {
      const _businessAddress = { ...businessAddress };

      var _contact = { ...contact };
      let temparrayprops = [
        ...contact.contactAffiliationsArray[index].addressArray,
      ];

      let record_index = temparrayprops.findIndex(
        (item) => item.id == _businessAddress.id
      );

      if (record_index != -1) {
        temparrayprops[record_index] = _businessAddress;
      } else {
        _businessAddress.id = uuid();
        temparrayprops.push(_businessAddress);
      }
      _contact.contactAffiliationsArray[index].addressArray = temparrayprops;
      setContact(_contact);
      setBusinessAddress(initialbusinessAddressState);
    }
  };

  // clear business email
  const clearBusinessAddress = () => {
    let bus_add = { ...businessAddress };
    setBusinessAddress(initialbusinessAddressState);
  };

  const validBusinessAddressForm = (index) => {
    try {
      let valid = true;
      return valid;
      valid = handleFieldBusinessAddressListRequiredValidation();
      Object.values(personalAddressErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldBusinessAddressListRequiredValidation = () => {
    let iserror = "";

    setBusinessAddressErrors({
      ...businessAddressErrors,
      addressLabel:
        businessAddress.addressLabel === ""
          ? (iserror = "Label required")
          : businessAddressErrors.addressLabel,
      addressLine1:
        businessAddress.addressLine1 === ""
          ? (iserror = "Address required")
          : businessAddressErrors.addressLine1,
    });

    return iserror.length === 0;
  };

  //add business phone
  const addBusinessPhone = (e, index) => {
    e.preventDefault();
    if (validBusinessPhoneForm(index)) {
      const _businessPhone = { ...businessPhone };

      var _contact = { ...contact };
      let temparrayprops = [
        ...contact.contactAffiliationsArray[index].phoneArray,
      ];

      let record_index = temparrayprops.findIndex(
        (item) => item.id == _businessPhone.id
      );

      if (record_index != -1) {
        temparrayprops[record_index] = _businessPhone;
      } else {
        _businessPhone.id = uuid();
        temparrayprops.push(_businessPhone);
      }
      _contact.contactAffiliationsArray[index].phoneArray = temparrayprops;
      setContact(_contact);
      setBusinessPhone(initialbusinessPhoneState);
    }
  };

  const validBusinessPhoneForm = (index) => {
    try {
      let valid = true;
      return valid;
      valid = handleFieldBusinessPhoneListRequiredValidation();
      Object.values(businessPhoneErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldBusinessPhoneListRequiredValidation = () => {
    let iserror = "";

    setBusinessPhoneErrors({
      ...businessPhoneErrors,
      type:
        businessPhone.type === ""
          ? (iserror = "Type required")
          : businessPhoneErrors.type,
      country:
        businessPhone.country === ""
          ? (iserror = "Country code required")
          : businessPhoneErrors.country,
      phone:
        businessPhone.phone === ""
          ? (iserror = "Phone required")
          : businessPhoneErrors.phone,
    });

    return iserror.length === 0;
  };
  return (
    <div className="container p-3">
      <div className="row">
        <h3 align="center">
          {title !== undefined ? title : "Contact details"}
        </h3>
      </div>
      <div className="row">
        <div className="col-sm col-md-3 col-lg-3 border">
          <div className="p-1 d-flex justify-content-center">
            <img className="rounded-circle" src={userphoto} alt="" />
          </div>
          <div className="p-3">
            {/* <Button
              className="form-control  mb-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapsePersonalInfo"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapsePersonalInfo"
            >
              Personal Contact info
            </Button> */}
            {contact?.contactAffiliationsArray?.length > 0 &&
              contact?.contactAffiliationsArray?.map((data, index) => {
                return (
                  <Button
                    className=" form-control mb-2"
                    type="button"
                    value={data?.id}
                    data-bs-toggle="collapse"
                    data-bs-target={"#panelsStayOpen-" + data?.id}
                    aria-expanded="false"
                    name={index}
                    aria-controls="panelsStayOpen-collapseBusinessInfo"
                    onClick={(evnt) =>
                      handleChange_affiliationlist(index, evnt)
                    }
                  >
                    {data.companyName === ""
                      ? "Add affiliation"
                      : data.companyName +
                        " (" +
                        data?.sourceSystem?.appname +
                        ")"}
                  </Button>
                );
              })}
          </div>
        </div>
        <div className="col-sm col-md-3 col-lg-8 border">
          <div className="mt-3"></div>
          <div className="accordion mt-2" id="accordionPanelscontact">
            <div className="accordion-item">
              <h2
                className="accordion-header mt-0"
                id="panelsStayOpen-headingPersonalInfo"
              >
                <button
                  className="accordion-button collapsed "
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapsePersonalInfo"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapsePersonalInfo"
                  style={{
                    "background-color": "#f2f2f2",
                  }}
                >
                  Personal contact info
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapsePersonalInfo"
                className="accordion-collapse show"
                aria-labelledby="panelsStayOpen-headingPersonalInfo"
              >
                <div className="accordion-body">
                  <div className="row">
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="form-label form-label ">
                          First name<label className="text-danger">*</label>
                          {contact?.user_verified_status?.firstname?.status ===
                          "verified" ? (
                            <span class="ms-2 badge rounded-pill bg-success">
                              {contact?.user_verified_status?.firstname?.status}
                            </span>
                          ) : (
                            ""
                          )}
                        </label>

                        <input
                          type="text"
                          name="firstname"
                          id="firstname"
                          className="form-control"
                          value={contact?.firstname}
                          onChange={handlePersonalChange}
                        />
                        {contactErrors.firstname.length > 0 && (
                          <div className="text-danger">
                            {contactErrors.firstname}
                          </div>
                        )}
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="form-label form-label">
                          Middle name
                          {contact?.user_verified_status?.middlename?.status ===
                          "verified" ? (
                            <span class="ms-2 badge rounded-pill bg-success">
                              {
                                contact?.user_verified_status?.middlename
                                  ?.status
                              }
                            </span>
                          ) : (
                            ""
                          )}
                        </label>

                        <input
                          type="text"
                          name="middlename"
                          id="middlename"
                          className="form-control"
                          value={contact?.middlename}
                          onChange={handlePersonalChange}
                        />
                        {/* {contactErrors.middlename.length > 0 && (
                  <div className="text-danger">{contactErrors.middlename}</div>
                )} */}
                      </div>

                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="form-label form-label">
                          Last name<label className="text-danger">*</label>
                          {contact?.user_verified_status?.lastname?.status ===
                          "verified" ? (
                            <span class="ms-2 badge rounded-pill bg-success">
                              {contact?.user_verified_status?.lastname?.status}
                            </span>
                          ) : (
                            ""
                          )}
                        </label>
                        <input
                          type="text"
                          name="lastname"
                          id="lastname"
                          className="form-control"
                          value={contact?.lastname}
                          onChange={handlePersonalChange}
                        />
                        {contactErrors.lastname.length > 0 && (
                          <div className="text-danger">
                            {contactErrors.lastname}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="form-label form-label ">
                          Salutation
                          {contact?.user_verified_status?.salutation?.status ===
                          "verified" ? (
                            <span class="ms-2 badge rounded-pill bg-success">
                              {
                                contact?.user_verified_status?.salutation
                                  ?.status
                              }
                            </span>
                          ) : (
                            ""
                          )}
                        </label>
                        <Select
                          name="salutation"
                          options={salutationOptions}
                          className="form-control"
                          isSearchable={true}
                          styles={customstyles}
                          value={salutationOptions.filter(
                            (record) => record.value === contact?.salutation
                          )}
                          onChange={(val) => {
                            handleDropDownChange(
                              {
                                target: {
                                  name: "salutation",
                                  value: val.value,
                                },
                              },
                              "contact"
                            );
                          }}
                        ></Select>
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="form-label form-label">
                          Main email <label className="text-danger ">*</label>
                          {contact?.user_verified_status?.email?.status ===
                          "verified" ? (
                            <span class="ms-2 badge rounded-pill bg-success">
                              {contact?.user_verified_status?.email?.status}
                            </span>
                          ) : (
                            ""
                          )}
                        </label>
                        <input
                          type="text"
                          name="email"
                          id="email"
                          className="form-control"
                          value={contact?.email}
                          onChange={handlePersonalChange}
                        />
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="form-label form-label">
                          Main phone
                          {contact?.user_verified_status?.phone?.status ===
                          "verified" ? (
                            <span class="ms-2 badge rounded-pill bg-success">
                              {contact?.user_verified_status?.phone?.status}
                            </span>
                          ) : (
                            ""
                          )}
                        </label>
                        <PhoneInput
                          inputStyle={{ width: "100%" }}
                          country={"us"}
                          value={contact?.phone}
                          name="phone"
                          id="phone"
                          onChange={handlecontactPhoneChange}
                        />
                        {/* <input
                  type="text"
                  name="phone"
                  id="phone"
                  className="form-control"
                  placeholder="1(111)-111-1111"
                  value={contact.phone}
                  onChange={handlePersonalChange}
                /> */}
                      </div>
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="form-label form-label">
                          Nickname
                          {contact?.verified_status?.nickName?.status ===
                          "verified" ? (
                            <span class="ms-2  badge rounded-pill bg-success">
                              {contact?.verified_status?.nickName?.status}
                            </span>
                          ) : (
                            ""
                          )}
                        </label>
                        <input
                          type="text"
                          name="nickName"
                          id="nickName"
                          className="form-control"
                          value={contact?.nickName}
                          onChange={handlePersonalChange}
                        />
                      </div>

                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="form-label form-label">
                          Gender
                          {contact?.verified_status?.gender?.status ===
                          "verified" ? (
                            <span class="ms-2  badge rounded-pill bg-success">
                              {contact?.verified_status?.gender?.status}
                            </span>
                          ) : (
                            ""
                          )}
                        </label>
                        <Select
                          isSearchable={true}
                          type="text"
                          name="gender"
                          id="gender"
                          styles={customstyles}
                          className="form-control"
                          onChange={(val) => {
                            handleDropDownChange(
                              {
                                target: {
                                  name: "gender",
                                  value: val.value,
                                },
                              },
                              "contact"
                            );
                          }}
                          value={genderOptions.filter(
                            (client) => client.value === contact?.gender
                          )}
                          options={genderOptions}
                        />
                      </div>
                    </div>

                    <div className="row p-1">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="form-label form-label">
                          Date of birth
                          {contact?.verified_status?.dateOfBirth?.status ===
                          "verified" ? (
                            <span class="ms-2  badge rounded-pill bg-success">
                              {contact?.verified_status?.dateOfBirth?.status}
                            </span>
                          ) : (
                            ""
                          )}
                        </label>
                        <input
                          type="date"
                          name="dateOfBirth"
                          id="dateOfBirth"
                          className="form-control"
                          value={contact?.dateOfBirth}
                          onChange={handlePersonalChange}
                        />
                      </div>
                      {/* <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="form-label form-label">
                          Contact owner
                          {contact?.verified_status?.contactOwner?.status ===
                          "verified" ? (
                            <span class="ms-2  badge rounded-pill bg-success">
                              {contact?.verified_status?.contactOwner?.status}
                            </span>
                          ) : (
                            ""
                          )}
                        </label>
                        <input
                          type="text"
                          name="contactOwner"
                          id="contactOwner"
                          className="form-control"
                          styles={customstyles}
                          value={contact?.contactOwner}
                          onChange={handlePersonalChange}
                        />
                      </div> */}
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="form-label form-label">
                          Description
                          {contact?.verified_status?.description?.status ===
                          "verified" ? (
                            <span class="ms-2  badge rounded-pill bg-success">
                              {contact?.verified_status?.description?.status}
                            </span>
                          ) : (
                            ""
                          )}
                        </label>
                        <input
                          type="text"
                          name="description"
                          id="description"
                          className="form-control"
                          value={contact?.description}
                          onChange={handlePersonalChange}
                        />
                      </div>
                    </div>

                    <div className="row p-1">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="form-label form-label">
                          Contact role
                          {contact?.verified_status?.contactRole?.status ===
                          "verified" ? (
                            <span class="ms-2  badge rounded-pill bg-success">
                              {contact?.verified_status?.contactOwner?.status}
                            </span>
                          ) : (
                            ""
                          )}
                        </label>
                        <input
                          type="text"
                          name="contactRole"
                          id="contactRole"
                          className="form-control"
                          value={contact?.contactRole}
                          onChange={handlePersonalChange}
                        />
                      </div>
                    </div>
                    {/* <div className="row p-1">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="form-label form-label">
                          User role
                          {contact?.user_verified_status?.role?.status ===
                          "verified" ? (
                            <span class="ms-2  badge rounded-pill bg-success">
                              {contact?.user_verified_status?.role?.status}
                            </span>
                          ) : (
                            ""
                          )}
                        </label>

                        <Select
                          isSearchable={true}
                          type="text"
                          name="role"
                          id="role"
                          options={userRoleOptions}
                          styles={customstyles}
                          isDisabled={
                            userInfo?.role === "2" || userInfo?.role === "3"
                              ? false
                              : true
                          }
                          className="form-control"
                          value={userRoleOptions.filter(
                            (client) => client.value === contact?.role
                          )}
                          onChange={(val) => {
                            handleDropDownChange(
                              {
                                target: {
                                  name: "role",
                                  value: val.value,
                                },
                              },
                              "contact"
                            );
                          }}
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
                {/* personal email info */}
                <div className="accordion-item p-0">
                  <div
                    className="accordion-header p-2 m-2"
                    style={{
                      "background-color": "#f2f2f2",
                    }}
                  >
                    {" "}
                    Email info
                  </div>

                  {/* <h2
                    className="accordion-header"
                    id="panelsStayOpen-headingPersonalEmailInfo"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapsePersonalEmailInfo"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapsePersonalEmailInfo"
                      style={{
                        "background-color": "#f2f2f2",
                      }}
                    >
                       Alternate Email info
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapsePersonalEmailInfo"
                    className="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingPersonalEmailInfo"
                  > */}
                  <div className="accordion-body">
                    <div className="row p-1" hidden={true}>
                      <div className="col-sm col-md-3 col-lg-10 ">
                        <div className="row p-1">
                          <div className="col-lg-5">
                            <label className="form-label form-label">
                              Email<label className="text-danger">*</label>
                            </label>
                            <input
                              type="text"
                              name="email"
                              id="email"
                              className="form-control"
                              value={personalEmail.email}
                              onChange={handleChangePersonalEmail}
                            />
                            {personalEmailErrors.email.length > 0 && (
                              <small
                                className="form-check-label text-danger"
                                htmlFor="email"
                              >
                                {personalEmailErrors.email}
                              </small>
                            )}
                          </div>
                          <div className="col-sm-12 col-md-3 col-lg-5">
                            <label className="form-label form-label">
                              Label<label className="text-danger">*</label>
                            </label>
                            <input
                              type="text"
                              name="label"
                              id="label"
                              className="form-control"
                              value={personalEmail.label}
                              onChange={handleChangePersonalEmail}
                            />
                            {personalEmailErrors.label.length > 0 && (
                              <small
                                className="form-check-label text-danger"
                                htmlFor="label"
                              >
                                {personalEmailErrors.label}
                              </small>
                            )}
                          </div>
                          <div className="col-sm-12 col-md-3 col-lg-2 mt-5">
                            <input
                              type="checkbox"
                              id="isMain"
                              name="isMain"
                              className="form-check-input ms-3"
                              defaultChecked={false}
                              onChange={(e) => {
                                setPersonalEmail({
                                  ...personalEmail,
                                  [e.target.name]: e.target.checked ? 1 : 0,
                                });
                              }}
                              checked={
                                personalEmail.isMain === 1 ? true : false
                              }
                            />
                            <label className="form-label form-label ms-1">
                              Ismain?
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm col-md-3 col-lg-2 ">
                        <div className="mt-3">
                          <Button
                            variant="primary"
                            id="personalEmailAdd"
                            className="form-control mb-3"
                            onClick={addPersonalEmail}
                          >
                            Submit
                          </Button>
                          <Button
                            variant="primary"
                            id="personalEmailClear"
                            className="form-control mb-3"
                            onClick={() => {
                              setPersonalEmail(initialpersonalEmailState);
                            }}
                          >
                            Clear
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="row p-1 mt-3">
                      <Table striped bordered hover responsive>
                        <thead>
                          <tr>
                            <th>Email</th>
                            <th>Label</th>
                            <th>Ismain</th>
                            <th hidden={true}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {EmailArray?.map((row, index) => (
                            <tr key={index}>
                              <td> {row?.email}</td>
                              <td> {row?.label}</td>
                              <td>
                                {" "}
                                <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                  <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                  {row?.isMain === 1 ? "Yes" : "No"}{" "}
                                </span>
                              </td>
                              <td hidden={true}>
                                <Button
                                  onClick={() => SelectPersonalEmailRow(index)}
                                  className="btn btn-sm me-2"
                                >
                                  Edit
                                </Button>
                                <Button
                                  className="btn btn-sm"
                                  onClick={() => deletePersonalEmailRow(index)}
                                >
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
                {/* personal address info */}
                <div className="accordion-item p-0 ">
                  <div
                    className="accordion-header  p-2 m-2"
                    style={{
                      "background-color": "#f2f2f2",
                    }}
                  >
                    {" "}
                    Address info
                  </div>
                  {/* <h2
                    className="accordion-header"
                    id="panelsStayOpen-headingPersonalAddressInfo"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapsePersonalAddressInfo"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapsePersonalAddressInfo"
                      style={{
                        "background-color": "#f2f2f2",
                      }}
                    >
                       Address info
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapsePersonalAddressInfo"
                    className="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingPersonalAddressInfo"
                  > */}
                  <div className="accordion-body">
                    <div className="row p-1" hidden={true}>
                      <div className="col-sm col-md-3 col-lg-10 ">
                        <div className="row p-1">
                          <div className="col-sm-10 col-md-4 col-lg-5">
                            <label className="form-label form-label">
                              Address label
                              <label className="text-danger">*</label>
                            </label>
                            <Select
                              isSearchable={true}
                              type="text"
                              name="addressLabel"
                              id="addressLabel"
                              className="form-control"
                              styles={customstyles}
                              value={addressOptions.filter(
                                (client) =>
                                  client.value === personalAddress.addressLabel
                              )}
                              onChange={(val) => {
                                handleDropDownChange(
                                  {
                                    target: {
                                      name: "addressLabel",
                                      value: val.value,
                                    },
                                  },
                                  "personaladdress"
                                );
                              }}
                              options={addressOptions}
                            />
                            {personalAddressErrors.addressLabel.length > 0 && (
                              <small
                                className="form-check-label text-danger"
                                htmlFor="addressLabel"
                              >
                                {personalAddressErrors.addressLabel}
                              </small>
                            )}
                          </div>
                          <div className="col-sm-10 col-md-4 col-lg-5">
                            <label className="form-label form-label">
                              Address line 1
                              <label className="text-danger">*</label>
                            </label>
                            <input
                              type="text"
                              name="addressLine1"
                              id="addressLine1"
                              className="form-control"
                              value={personalAddress.addressLine1}
                              onChange={handleChangePersonalAddress}
                            />
                            {personalAddressErrors.addressLine1.length > 0 && (
                              <small
                                className="form-check-label text-danger"
                                htmlFor="addressLine1"
                              >
                                {personalAddressErrors.addressLine1}
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="row p-1">
                          <div className="col-sm-10 col-md-4 col-lg-5">
                            <label className="form-label form-label">
                              Address line 2
                            </label>
                            <input
                              type="text"
                              name="addressLine2"
                              id="addressLine2"
                              className="form-control"
                              value={personalAddress.addressLine2}
                              onChange={handleChangePersonalAddress}
                            />
                          </div>
                          <div className="col-sm-10 col-md-4 col-lg-5">
                            <label className="form-label form-label">
                              City
                            </label>
                            <input
                              type="text"
                              name="city"
                              id="city"
                              className="form-control"
                              value={personalAddress.city}
                              onChange={handleChangePersonalAddress}
                            />
                          </div>
                          <div className="col-sm-10 col-md-4 col-lg-2 mt-5">
                            <input
                              type="checkbox"
                              id="isMain"
                              name="isMain"
                              className="form-check-input ms-3"
                              onChange={(e) => {
                                setPersonalAddress({
                                  ...personalAddress,
                                  [e.target.name]: e.target.checked ? 1 : 0,
                                });
                              }}
                              checked={
                                personalAddress.isMain === 1 ? true : false
                              }
                            />
                            <label className="form-label form-label ms-1">
                              Ismain?
                            </label>
                          </div>
                        </div>
                        <div className="row p-1">
                          <div className="col-sm-10 col-md-4 col-lg-5">
                            <label className="form-label form-label">
                              Country
                            </label>
                            <input
                              type="text"
                              name="country"
                              id="country"
                              className="form-control"
                              value={personalAddress.country}
                              onChange={handleChangePersonalAddress}
                            />
                          </div>
                          <div className="col-sm-10 col-md-4 col-lg-5">
                            <label className="form-label form-label">
                              State
                            </label>

                            <input
                              type="text"
                              name="state"
                              id="state"
                              className="form-control"
                              value={personalAddress.state}
                              onChange={handleChangePersonalAddress}
                            />
                          </div>
                        </div>
                        <div className="row p-1">
                          <div className="col-sm-10 col-md-4 col-lg-5">
                            <label className="form-label form-label">
                              Postal code
                            </label>
                            <input
                              type="number"
                              name="postalCode"
                              id="postalCode"
                              className="form-control"
                              value={personalAddress.postalCode}
                              onChange={handleChangePersonalAddress}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm col-md-3 col-lg-2 ">
                        <div className="mt-3">
                          <Button
                            variant="primary"
                            id="personalAddressAdd"
                            className="form-control mt-5 mb-3"
                            onClick={addPersonalAddress}
                          >
                            Submit
                          </Button>
                          <Button
                            variant="primary"
                            id="personalAddressClear"
                            className="form-control mb-3"
                            onClick={() => {
                              setPersonalAddress(initialpersonalAddressState);
                            }}
                          >
                            Clear
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="row p-1 mt-3">
                      <Table striped bordered hover responsive>
                        <thead>
                          <tr>
                            <th>Address label</th>
                            <th>Address line 1</th>
                            <th>Address line 2</th>
                            <th>City</th>
                            <th>Ismain</th>
                            <th hidden={true}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {AddressArray?.map((row, index) => (
                            <tr key={index}>
                              <td>
                                {addressOptions.map((option) =>
                                  option.value === row?.addressLabel ? (
                                    <span>{option.label}</span>
                                  ) : (
                                    ""
                                  )
                                )}
                              </td>

                              <td> {row?.addressLine1}</td>
                              <td> {row?.addressLine2}</td>
                              <td> {row?.city}</td>
                              <td>
                                {" "}
                                <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                  <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                  {row?.isMain === 1 ? "Yes" : "No"}
                                </span>
                              </td>
                              <td hidden={true}>
                                <Button
                                  onClick={() =>
                                    SelectPersonalAddressRow(index)
                                  }
                                  className="btn-sm me-2"
                                >
                                  Edit
                                </Button>
                                <Button
                                  className="btn-sm"
                                  onClick={() =>
                                    deletePersonalAddressRow(index)
                                  }
                                >
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
                {/* personal phone info */}
                <div className="accordion-item p-0">
                  <div
                    className="accordion-header p-2 m-2"
                    style={{
                      "background-color": "#f2f2f2",
                    }}
                  >
                    {" "}
                    Phone info
                  </div>
                  {/* <h2
                    className="accordion-header"
                    id="panelsStayOpen-headingPersonalPhoneInfo"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapsePersonalPhoneInfo"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapsePersonalPhoneInfo"
                      style={{
                        "background-color": "#f2f2f2",
                      }}
                    >
                       Alternate Phone info
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapsePersonalPhoneInfo"
                    className="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingPersonalPhoneInfo"
                  > */}
                  <div className="accordion-body">
                    <div className="row p-1" hidden={true}>
                      <div className="col-sm col-md-9 col-lg-10 ">
                        <div className="row p-1">
                          <div className="col-sm-10 col-md-4 col-lg-4">
                            <label className="form-label form-label">
                              Phone type
                              <label className="text-danger">*</label>
                            </label>
                            <Select
                              isSearchable={true}
                              type="text"
                              name="type"
                              id="type"
                              styles={customstyles}
                              className="form-control"
                              options={phoneOptions}
                              value={phoneOptions.filter(
                                (client) => client.value === personalPhone.type
                              )}
                              onChange={(val) => {
                                handleDropDownChange(
                                  {
                                    target: {
                                      name: "type",
                                      value: val.value,
                                    },
                                  },
                                  "personalphone"
                                );
                              }}
                            />
                            {personalPhoneErrors.type.length > 0 && (
                              <small
                                className="form-check-label text-danger"
                                htmlFor="type"
                              >
                                {personalPhoneErrors.type}
                              </small>
                            )}
                          </div>
                          <div className="col-sm-10 col-md-4 col-lg-4">
                            <label className="form-label form-label">
                              Phone number{" "}
                              <label className="text-danger">*</label>
                            </label>

                            <PhoneInput
                              inputStyle={{ width: "250px" }}
                              country={"us"}
                              value={personalPhone.phone}
                              name="phone"
                              id="phone"
                              onChange={(phone) =>
                                setPersonalPhone({
                                  ...personalPhone,
                                  ["phone"]: phone,
                                })
                              }
                            />

                            {personalPhoneErrors.phone.length > 0 && (
                              <small
                                className="form-check-label text-danger"
                                htmlFor="phone"
                              >
                                {personalPhoneErrors.phone}
                              </small>
                            )}
                          </div>
                          <div className="col-sm-10 col-md-2 col-lg-2">
                            <label className="form-label form-label">
                              Extn
                            </label>
                            <input
                              type="text"
                              name="ext"
                              id="ext"
                              className="form-control"
                              placeholder="Ext..."
                              value={personalPhone.ext}
                              onChange={handleChangePersonalPhone}
                            />
                          </div>
                          <div className="col-sm-10 col-md-2 col-lg-2 pt-5">
                            <input
                              type="checkbox"
                              className="form-check-input ms-3"
                              id="isMain"
                              name="isMain"
                              onChange={(e) => {
                                setPersonalPhone({
                                  ...personalPhone,
                                  [e.target.name]: e.target.checked ? 1 : 0,
                                });
                              }}
                              checked={
                                personalPhone.isMain === 1 ? true : false
                              }
                            />
                            <label className="form-label ms-1">isMain?</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm col-md-3 col-lg-2 ">
                        <div className="mt-3">
                          <Button
                            variant="primary"
                            id="personalPhoneAdd"
                            className="form-control mb-3"
                            onClick={addPersonalPhone}
                          >
                            Submit
                          </Button>

                          <Button
                            variant="primary"
                            id="personalPhoneAdd"
                            className="form-control mb-3"
                            onClick={() => {
                              setPersonalPhone(initialpersonalPhoneState);
                            }}
                          >
                            Clear
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="row p-1 mt-3">
                      <Table striped bordered hover responsive>
                        <thead>
                          <tr>
                            <th>Phone type</th>

                            <th>Phone number</th>
                            <th>Extn</th>
                            <th>Ismain</th>
                            <th hidden={true}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {PhoneArray &&
                            PhoneArray?.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  {phoneOptions.map((option) =>
                                    option.value === row?.type ? (
                                      <span>{option.label}</span>
                                    ) : (
                                      ""
                                    )
                                  )}
                                </td>

                                <td>
                                  <PhoneInput
                                    inputStyle={{ width: "250px" }}
                                    value={row?.fullPhone}
                                    name="phone"
                                    id="phone"
                                    disabled
                                  />
                                </td>
                                <td> {row?.extphone}</td>
                                <td>
                                  <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                    <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                    {row?.isMain === 1 ? "Yes" : "No"}
                                  </span>
                                </td>
                                <td width="25%" hidden={true}>
                                  <Button
                                    onClick={() =>
                                      SelectPersonalPhoneRow(index)
                                    }
                                    className="btn-sm me-2"
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    className="btn-sm"
                                    onClick={() =>
                                      deletePersonalPhoneRow(index)
                                    }
                                  >
                                    Delete
                                  </Button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>

            {/* business info     */}
            {contact?.contactAffiliationsArray &&
              contact?.contactAffiliationsArray?.map((affiliation, index) => {
                return (
                  <div className="accordion-item mt-2">
                    <h2 className="accordion-header mt-0">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={"#panelsStayOpen-" + affiliation?.id}
                        aria-expanded="false"
                        aria-controls={"panelsStayOpen-" + affiliation?.id}
                        style={{
                          "background-color": "#f2f2f2",
                        }}
                      >
                        {affiliation?.companyName === ""
                          ? "Add affiliation"
                          : affiliation?.companyName +
                            " (" +
                            affiliation?.sourceSystem?.appname +
                            ")"}
                      </button>
                    </h2>
                    <div
                      id={"panelsStayOpen-" + affiliation.id}
                      className="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingBusinessInfo"
                    >
                      <div className="accordion-body">
                        <div className="row">
                          <div className="row p-1">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <label className="form-label form-label ">
                                Company name
                                {contact?.verified_status
                                  ?.contactAffiliationsArray[index]?.companyName
                                  ?.status === "verified" ? (
                                  <span class="ms-2  badge rounded-pill bg-success">
                                    {
                                      contact?.verified_status
                                        ?.contactAffiliationsArray[index]
                                        ?.companyName?.status
                                    }
                                  </span>
                                ) : (
                                  ""
                                )}
                              </label>
                              <input
                                type="text"
                                name={"companyName_" + index}
                                id={"companyName_" + index}
                                className="form-control"
                                value={affiliation?.companyName}
                                disabled={true}
                                onChange={(evnt) =>
                                  handleBusinessChange(
                                    evnt,
                                    affiliation.id,
                                    "companyName"
                                  )
                                }
                              />
                              {businessContactErrors.companyName.length > 0 && (
                                <div className="text-danger">
                                  {businessContactErrors.companyName}
                                </div>
                              )}
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <label className="form-label form-label ">
                                Contact owner
                                {contact?.verified_status
                                  ?.contactAffiliationsArray[index]
                                  ?.contactOwner?.status === "verified" ? (
                                  <span class="ms-2  badge rounded-pill bg-success">
                                    {
                                      contact?.verified_status
                                        ?.contactAffiliationsArray[index]
                                        ?.contactOwner?.status
                                    }
                                  </span>
                                ) : (
                                  ""
                                )}
                              </label>
                              <input
                                type="text"
                                name={"contactOwner_" + index}
                                id={"contactOwner_" + index}
                                className="form-control"
                                value={affiliation?.contactOwner}
                                onChange={(evnt) =>
                                  handleBusinessChange(
                                    evnt,
                                    affiliation.id,
                                    "contactOwner"
                                  )
                                }
                              />
                            </div>
                          </div>

                          <div className="row p-1">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <label className="form-label form-label">
                                Description
                                {contact?.verified_status
                                  ?.contactAffiliationsArray[index]?.description
                                  ?.status === "verified" ? (
                                  <span class="ms-2  badge rounded-pill bg-success">
                                    {
                                      contact?.verified_status
                                        ?.contactAffiliationsArray[index]
                                        ?.description?.status
                                    }
                                  </span>
                                ) : (
                                  ""
                                )}
                              </label>
                              <input
                                type="text"
                                name={"description_" + index}
                                id={"description_" + index}
                                className="form-control"
                                value={affiliation?.description}
                                onChange={(evnt) =>
                                  handleBusinessChange(
                                    evnt,
                                    affiliation.id,
                                    "description"
                                  )
                                }
                              />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <label className="form-label form-label">
                                Contact role{" "}
                                {contact?.verified_status
                                  ?.contactAffiliationsArray[index]?.contactRole
                                  ?.status === "verified" ? (
                                  <span class="ms-2  badge rounded-pill bg-success">
                                    {
                                      contact?.verified_status
                                        ?.contactAffiliationsArray[index]
                                        ?.contactRole?.status
                                    }
                                  </span>
                                ) : (
                                  ""
                                )}
                              </label>
                              {/* this should be in dropdown */}
                              {/* <Select
                        type="text"
                        name="contactRole"
                        id="contactRole"
                        className="form-control"
                        styles={customstyles}
                        value={affiliation.contactRole}
                        onChange={handleBusinessChange}
                        
                      /> */}
                              <input
                                type="text"
                                name={"contactRole_" + index}
                                id={"contactRole_" + index}
                                className="form-control"
                                value={affiliation?.contactRole}
                                onChange={(evnt) =>
                                  handleBusinessChange(
                                    evnt,
                                    affiliation.id,
                                    "contactRole",
                                    1
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="row p-1">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <label className="form-label form-label">
                                Contact record type
                                {contact?.verified_status
                                  ?.contactAffiliationsArray[index]
                                  ?.contactRecordType?.status === "verified" ? (
                                  <span class="ms-2  badge rounded-pill bg-success">
                                    {
                                      contact?.verified_status
                                        ?.contactAffiliationsArray[index]
                                        ?.contactRecordType?.status
                                    }
                                  </span>
                                ) : (
                                  ""
                                )}
                              </label>
                              {/* <Select
                        type="text"
                        name="contactRecordType"
                        id="contactRecordType"
                        className="form-control"
                        styles={customstyles}
                        value={affiliation.contactRecordType}
                        onChange={handleBusinessChange}
                        
                      /> */}
                              <input
                                type="text"
                                name={"contactRecordType_" + index}
                                id={"contactRecordType_" + index}
                                className="form-control"
                                value={affiliation?.contactRecordType}
                                onChange={(evnt) =>
                                  handleBusinessChange(
                                    evnt,
                                    affiliation.id,
                                    "contactRecordType"
                                  )
                                }
                              />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <label className="form-label form-label">
                                Department{" "}
                                {contact?.verified_status
                                  ?.contactAffiliationsArray[index]?.department
                                  ?.status === "verified" ? (
                                  <span class="ms-2  badge rounded-pill bg-success">
                                    {
                                      contact?.verified_status
                                        ?.contactAffiliationsArray[index]
                                        ?.department?.status
                                    }
                                  </span>
                                ) : (
                                  ""
                                )}
                              </label>
                              <input
                                type="text"
                                name={"department_" + index}
                                id={"department_" + index}
                                className="form-control"
                                value={affiliation?.department}
                                onChange={(evnt) =>
                                  handleBusinessChange(
                                    evnt,
                                    affiliation.id,
                                    "department"
                                  )
                                }
                              />
                            </div>
                          </div>

                          <div className="row p-1">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <label className="form-label form-label">
                                Report to
                                {contact?.verified_status
                                  ?.contactAffiliationsArray[index]?.reportTo
                                  ?.status === "verified" ? (
                                  <span class="ms-2  badge rounded-pill bg-success">
                                    {
                                      contact?.verified_status
                                        ?.contactAffiliationsArray[index]
                                        ?.reportTo?.status
                                    }
                                  </span>
                                ) : (
                                  ""
                                )}
                              </label>
                              {/* <Select
                        type="text"
                        name="reportTo"
                        id="reportTo"
                        className="form-control"
                        styles={customstyles}
                        value={affiliation.reportTo}
                        onChange={handleBusinessChange}
                        
                      /> */}
                              <input
                                type="text"
                                name={"reportTo_" + index}
                                id={"reportTo_" + index}
                                className="form-control"
                                value={affiliation?.reportTo}
                                onChange={(evnt) =>
                                  handleBusinessChange(
                                    evnt,
                                    affiliation.id,
                                    "reportTo"
                                  )
                                }
                              />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <label className="form-label form-label">
                                Claimed URL
                                {contact?.verified_status
                                  ?.contactAffiliationsArray[index]?.claimedUrl
                                  ?.status === "verified" ? (
                                  <span class="ms-2  badge rounded-pill bg-success">
                                    {
                                      contact?.verified_status
                                        ?.contactAffiliationsArray[index]
                                        ?.claimedUrl?.status
                                    }
                                  </span>
                                ) : (
                                  ""
                                )}
                              </label>
                              <input
                                type="text"
                                name={"claimedUrl_" + index}
                                id={"claimedUrl_" + index}
                                className="form-control"
                                value={affiliation?.claimedUrl}
                                onChange={(evnt) =>
                                  handleBusinessChange(
                                    evnt,
                                    affiliation.id,
                                    "claimedUrl"
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* business email info */}
                      <div className="accordion-item p-0">
                        <div
                          className="accordion-header p-2 m-2"
                          style={{
                            "background-color": "#f2f2f2",
                          }}
                        >
                          {" "}
                          Business &gt; Email info
                        </div>
                        {/* <h2
                          className="accordion-header"
                          id="panelsStayOpen-headingBusinessEmailInfo"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseBusinessEmailInfo"
                            aria-expanded="false"
                            aria-controls="panelsStayOpen-collapseBusinessEmailInfo"
                            style={{
                              "background-color": "#f2f2f2",
                            }}
                          >
                            Business &gt; Alternate Email info
                          </button>
                        </h2>
                        <div
                          id="panelsStayOpen-collapseBusinessEmailInfo"
                          className="accordion-collapse collapse"
                          aria-labelledby="panelsStayOpen-headingBusinessEmailInfo"
                        > */}
                        <div className="accordion-body">
                          <div className="row p-1" hidden={true}>
                            <div className="col-sm col-md-3 col-lg-10 ">
                              <div className="row p-1">
                                <div className="col-sm-10 col-md-4 col-lg-5">
                                  <label className="form-label form-label">
                                    Email{" "}
                                    <label className="text-danger">*</label>
                                  </label>
                                  <input
                                    type="text"
                                    name="email"
                                    id="email"
                                    className="form-control"
                                    value={businessEmail?.email}
                                    onChange={(evnt) =>
                                      handleChangeBusinessEmail(
                                        evnt,
                                        index,
                                        "email"
                                      )
                                    }
                                  />
                                  {businessEmailErrors.email.length > 0 && (
                                    <small
                                      className="form-check-label text-danger"
                                      htmlFor="email"
                                    >
                                      {businessEmailErrors.email}
                                    </small>
                                  )}
                                </div>
                                <div className="col-sm-10 col-md-4 col-lg-5">
                                  <label className="form-label form-label">
                                    Label
                                    <label className="text-danger">*</label>
                                  </label>
                                  <input
                                    type="text"
                                    name="label"
                                    id="label"
                                    className="form-control"
                                    value={businessEmail?.label}
                                    onChange={(evnt) => {
                                      handleChangeBusinessEmail(
                                        evnt,
                                        index,
                                        "label"
                                      );
                                    }}
                                  />
                                  {businessEmailErrors.label.length > 0 && (
                                    <small
                                      className="form-check-label text-danger"
                                      htmlFor="label"
                                    >
                                      {businessEmailErrors.label}
                                    </small>
                                  )}
                                </div>
                                <div className="col-sm-10 col-md-4 col-lg-2 mt-5">
                                  <input
                                    type="checkbox"
                                    id="isMain"
                                    name="isMain"
                                    className="form-check-input ms-3"
                                    onChange={(evnt) => {
                                      handleChangeBusinessEmail(
                                        evnt,
                                        index,
                                        "isMain"
                                      );
                                    }}
                                    checked={
                                      businessEmail?.isMain === 1 ? true : false
                                    }
                                  />
                                  <label className="form-label form-label ms-1">
                                    Ismain?
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm col-md-3 col-lg-2 ">
                              <div className="mt-3">
                                <Button
                                  variant="primary"
                                  id="businessEmailAdd"
                                  className="form-control mb-3"
                                  onClick={(evnt) => {
                                    addBusinessEmail(evnt, index);
                                  }}
                                >
                                  Submit
                                </Button>
                                <Button
                                  variant="primary"
                                  id="businessPhoneAdd"
                                  className="form-control mb-3"
                                  onClick={() => {
                                    clearBusinessEmail();
                                  }}
                                >
                                  Clear
                                </Button>
                              </div>
                            </div>
                          </div>
                          <div className="row p-1 mt-3">
                            <Table striped bordered hover responsive>
                              <thead>
                                <tr>
                                  <th>Email</th>
                                  <th>Label</th>
                                  <th>Ismain</th>
                                  <th hidden={true}>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {affiliation.emailArray?.map(
                                  (row, rowindex) => (
                                    <tr key={rowindex}>
                                      <td width="30%"> {row?.email}</td>
                                      <td width="30%"> {row?.label}</td>
                                      <td>
                                        {" "}
                                        <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                          <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                          {row?.isMain === 1 ? "Yes" : "No"}
                                        </span>
                                      </td>
                                      <td hidden={true}>
                                        <button
                                          onClick={() =>
                                            SelectBusinessEmailRow(
                                              index,
                                              rowindex,
                                              row?.id
                                            )
                                          }
                                          className="btn btn-sm me-2"
                                        >
                                          Edit
                                        </button>
                                        <button
                                          className="btn btn-sm "
                                          onClick={() =>
                                            deleteBusinessEmailRow(
                                              index,
                                              rowindex
                                            )
                                          }
                                        >
                                          Delete
                                        </button>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                        {/* </div> */}
                      </div>
                      {/* business address info */}
                      <div className="accordion-item p-0">
                        <div
                          className="accordion-header p-2 m-2"
                          style={{
                            "background-color": "#f2f2f2",
                          }}
                        >
                          {" "}
                          Address info
                        </div>
                        {/* <h2
                          className="accordion-header"
                          id="panelsStayOpen-headingBusinessAddresslInfo"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseBusinessAddresslInfo"
                            aria-expanded="false"
                            aria-controls="panelsStayOpen-collapseBusinessAddresslInfo"
                            style={{
                              "background-color": "#f2f2f2",
                            }}
                          >
                            Business &gt; Alternate Address info
                          </button>
                        </h2> 
                        <div
                          id="panelsStayOpen-collapseBusinessAddresslInfo"
                          className="accordion-collapse collapse"
                          aria-labelledby="panelsStayOpen-headingBusinessAddresslInfo"
                          >*/}
                        <div className="accordion-body">
                          <div className="row p-1 mt-3">
                            <Table striped bordered hover responsive>
                              <thead>
                                <tr>
                                  <th>Address label</th>
                                  <th>Address line 1</th>
                                  <th>Address line 2</th>
                                  <th>City</th>
                                  <th>Ismain</th>
                                  <th hidden={true}>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {" "}
                                {affiliation.addressArray?.map(
                                  (row, rowindex) => (
                                    <tr key={rowindex}>
                                      <td>
                                        {addressOptions.map((option) =>
                                          option.value === row?.addressLabel ? (
                                            <span>{option?.label}</span>
                                          ) : (
                                            <span>&nbsp;</span>
                                          )
                                        )}
                                      </td>
                                      <td> {row?.addressLine1}</td>
                                      <td> {row?.addressLine2}</td>
                                      <td> {row?.city}</td>
                                      <td>
                                        {" "}
                                        <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                          <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                          {row?.isMain === 1 ? "Yes" : "No"}
                                        </span>
                                      </td>
                                      <td hidden={true}>
                                        <button
                                          onClick={() =>
                                            SelectBusinessAddressRow(
                                              index,
                                              rowindex,
                                              row?.id
                                            )
                                          }
                                          className="btn btn-sm me-2"
                                        >
                                          Edit
                                        </button>
                                        <button
                                          className="btn btn-sm"
                                          onClick={() =>
                                            deleteBusinessAddressRow(
                                              index,
                                              rowindex
                                            )
                                          }
                                        >
                                          Delete
                                        </button>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                        {/* </div> */}
                      </div>
                      {/* business phone info */}
                      <div className="accordion-item p-0">
                        <div
                          className="accordion-header p-2 m-2"
                          style={{
                            "background-color": "#f2f2f2",
                          }}
                        >
                          {" "}
                          Business &gt; Phone info
                        </div>
                        {/* <h2
                          className="accordion-header"
                          id="panelsStayOpen-headingBusinessPhonelInfo"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseBusinessPhonelInfo"
                            aria-expanded="false"
                            aria-controls="panelsStayOpen-collapseBusinessPhonelInfo"
                            style={{
                              "background-color": "#f2f2f2",
                            }}
                          >
                            Business &gt; Alternate Phone info
                          </button>
                        </h2>
                        <div
                          id="panelsStayOpen-collapseBusinessPhonelInfo"
                          className="accordion-collapse collapse"
                          aria-labelledby="panelsStayOpen-headingBusinessPhonelInfo"
                        > */}
                        <div className="accordion-body">
                          <div className="row p-1" hidden={true}>
                            <div className="col-sm col-md-9 col-lg-10 ">
                              <div className="row p-1">
                                <div className="col-sm-10 col-md-4 col-lg-3">
                                  <label className="form-label form-label">
                                    Phone type
                                    <label className="text-danger">*</label>
                                  </label>
                                  <Select
                                    type="text"
                                    name="type"
                                    id="type"
                                    className="form-control"
                                    styles={customstyles}
                                    value={phoneOptions.filter(
                                      (client) =>
                                        client.value === businessPhone?.type
                                    )}
                                    onChange={(val) => {
                                      handleChangeBusinessPhone(
                                        {
                                          target: {
                                            name: "type",
                                            value: val.value,
                                          },
                                        },
                                        index,
                                        "type"
                                      );
                                    }}
                                    options={phoneOptions}
                                  />
                                  {businessPhoneErrors.type.length > 0 && (
                                    <small
                                      className="form-check-label text-danger"
                                      htmlFor="type"
                                    >
                                      {businessPhoneErrors.type}
                                    </small>
                                  )}
                                </div>
                                <div className="col-sm-10 col-md-4 col-lg-4">
                                  <label className="form-label form-label">
                                    Phone number
                                    <label className="text-danger">*</label>
                                  </label>
                                  <PhoneInput
                                    inputStyle={{ width: "250px" }}
                                    country={"us"}
                                    value={businessPhone?.phone}
                                    name="phone"
                                    id="phone"
                                    onChange={(evnt) =>
                                      handleChangeBusinessPhone(
                                        evnt,
                                        index,
                                        "phone"
                                      )
                                    }
                                  />
                                  {businessPhoneErrors.phone.length > 0 && (
                                    <small
                                      className="form-check-label text-danger"
                                      htmlFor="phone"
                                    >
                                      {businessPhoneErrors.phone}
                                    </small>
                                  )}
                                </div>
                                <div className="col-sm-10 col-md-2 col-lg-2">
                                  <label className="form-label form-label">
                                    Ext
                                  </label>
                                  <input
                                    type="text"
                                    name="ext"
                                    id="ext"
                                    className="form-control"
                                    value={businessPhone?.ext}
                                    onChange={(evnt) =>
                                      handleChangeBusinessPhone(
                                        evnt,
                                        index,
                                        "ext"
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-10 col-md-2 col-lg-2 mt-5">
                                  <input
                                    type="checkbox"
                                    id="isMain"
                                    name="isMain"
                                    className="form-check-input ms-3"
                                    onChange={(evnt) =>
                                      handleChangeBusinessPhone(
                                        evnt,
                                        index,
                                        "isMain"
                                      )
                                    }
                                    checked={
                                      businessPhone.isMain === 1 ? true : false
                                    }
                                  />
                                  <label className="form-label form-label ms-1">
                                    Ismain?
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm col-md-3 col-lg-2 ">
                              <div className="mt-3">
                                <Button
                                  variant="primary"
                                  id="businessPhoneAdd"
                                  className="form-control mb-2"
                                  onClick={(evnt) => {
                                    addBusinessPhone(evnt, index);
                                  }}
                                >
                                  Submit
                                </Button>
                                <Button
                                  variant="primary"
                                  id="businessPhoneClear"
                                  className="form-control "
                                  onClick={() => {
                                    setBusinessPhone(initialbusinessPhoneState);
                                  }}
                                >
                                  Clear
                                </Button>
                              </div>
                            </div>
                          </div>
                          <div className="row p-1 mt-">
                            <Table striped bordered hover responsive>
                              <thead>
                                <tr>
                                  <th>Phone type</th>
                                  <th>Phone number</th>
                                  <th>Extn</th>
                                  <th>Ismain</th>
                                  <th hidden={true}>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {affiliation.phoneArray?.map(
                                  (row, rowindex) => (
                                    <tr key={index}>
                                      {phoneOptions.map((option) =>
                                        option.value === row?.type ? (
                                          <td>{option.label}</td>
                                        ) : (
                                          ""
                                        )
                                      )}
                                      <td>
                                        {" "}
                                        <PhoneInput
                                          inputStyle={{ width: "250px" }}
                                          value={row?.fullPhone}
                                          name="phone"
                                          id="phone"
                                          disabled
                                        />
                                      </td>
                                      <td> {row?.extphone}</td>
                                      <td>
                                        {" "}
                                        <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                          <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                          {row?.isMain === 1 ? "Yes" : "No"}
                                        </span>
                                      </td>
                                      <td hidden={true}>
                                        <Button
                                          onClick={() =>
                                            SelectBusinessPhoneRow(
                                              index,
                                              rowindex,
                                              row?.id
                                            )
                                          }
                                          className="btn-sm me-2"
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          className="btn-sm"
                                          onClick={() =>
                                            deleteBusinessPhoneRow(
                                              index,
                                              rowindex
                                            )
                                          }
                                        >
                                          Delete
                                        </Button>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                );
              })}

            <div className="mt-3">
              <Button
                className="btn btn-success ms-10 me-3"
                onClick={submitHandler}
                hidden={true}
              >
                Save
              </Button>
              {userid !== undefined && (
                <Button className=" btn btn-success " onClick={handleCancel}>
                  Cancel
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        {isSuccess && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title="User updated successfully.."
            onConfirm={handleSucessSweetAlert}
          ></SweetAlert>
        )}
      </div>
    </div>
  );
}
export default DetailContact;
