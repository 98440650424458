import React,{  useState } from "react"; 
import * as Realm from "realm-web";

export const RealmAppContext = React.createContext();

export const useRealmApp = () => {
  const app = React.useContext(RealmAppContext);
  // console.log("useRealmApp app ", app);
  if (!app) {
    throw new Error(
      `You must call useRealmApp() inside of a <RealmAppProvider />`
    );
  }
  return app;
};

export const RealmAppProvider = ({ appId, children }) => {
  const [app, setApp] = React.useState(new Realm.App(appId));
  //console.log(setUserInfo);
  React.useEffect(() => {
    setApp(new Realm.App(appId));
  }, [appId]);

  // Wrap the Realm.App object's user state with React state
  const [currentUser, setCurrentUser] = React.useState(app.currentUser);
  async function logIn(credentials) {
    const loggeduser = await app.logIn(credentials);
    // `App.currentUser` updates to match the logged in user
    // console.assert(loggeduser.id === app.currentUser.id);
    // If successful, app.currentUser is the user that just logged in
    //console.log("loggeduser:", JSON.stringify(loggeduser));
    setCurrentUser(loggeduser);
    return loggeduser;
  }
  async function CreateUserWithoutlogout(email, password) {
    let newuser = "";
    let newuserid = "";
    if (app.currentUser) {
      await app.emailPasswordAuth.registerUser(email, password);
      newuser = await NewUserlogIn(
        Realm.Credentials.emailPassword(email, password)
      );
      newuserid = newuser.id;
    }
    return newuserid;
  }
  async function CreateUser(email, password) {
    let newuser = "";
    let newuserid = "";
    if (app.currentUser) {
      await app.emailPasswordAuth.registerUser(email, password);
      newuser = await NewUserlogIn(
        Realm.Credentials.emailPassword(email, password)
      );
      newuserid = newuser.id;

      logOutByUserID(newuser);
    }

    return newuserid;
    // If successful, app.currentUser is the user that just logged in
    // setCurrentUser(app.currentUser);
    // setUserInfo(app.currentUser);
  }
  async function NewUserlogIn(credentials) {
    const newuser = await app.logIn(credentials);
    return newuser;
    // If successful, app.currentUser is the user that just logged in
    // setCurrentUser(app.currentUser);
    // setUserInfo(app.currentUser);
  }
  async function logOut() {
    // remove the currently active user
    const user = app.currentUser;
    await app.removeUser(user);
    // If another user was logged in too, they're now the current user.
    // Otherwise, app.currentUser is null.
    //setCurrentUser(app.currentUser);
  }
  async function logOutAllUsers() {
    // Log out the currently active user
    for (const userId in app.allUsers) {
      const user = app.allUsers[userId];
      await app.removeUser(user);
    }
  }
  async function ChangePassword(email, password) {
    if (app.currentUser) {
      try {
        await app.emailPasswordAuth.callResetPasswordFunction(email, password);
        return true;
      } catch (err) {
        throw err;
      }
    }
  }
  async function logOutByUserID(user_id) {
    // Log out the currently active user
    for (const userId in app.allUsers) {
      const user = app.allUsers[userId];
      if ((user.id = user_id)) {
        await app.removeUser(user);
        break;
      }
    }
  }
  const wrapped = {
    ...app,
    currentUser,
    logIn,
    logOut,
    NewUserlogIn,
    logOutAllUsers,
    logOutByUserID,
    ChangePassword,
    CreateUser,
    CreateUserWithoutlogout,
  };

  return (
    <RealmAppContext.Provider value={wrapped}>
      {children}
    </RealmAppContext.Provider>
  );
};
