import React, { useEffect, useState } from "react";
import MainNav from "./main-nav";
import AuthNav from "./auth-nav";
import { useAuth0 } from "@auth0/auth0-react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useRealmApp } from "../providers/RealmApp";
import {
  getContactDetailSettingsByID,
  getGeneralSettingByAuthID,
} from "../services/contactDetailsServices";
import { getuserbyauthproviderid } from "../services/users_services";
import { useLocation } from "react-router-dom";
const NavBar = () => {
  const ctechapp = useRealmApp();
  const { isAuthenticated, user } = useAuth0();
  const location = useLocation();
  const [companyLogo, setCompanyLogo] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [advancedMenuEnable, setAdvancedMenuEnable] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      getUserInfoByID();
      const _advancedMode = localStorage.getItem("menuadvancedmode");
      if (_advancedMode) {
        if (_advancedMode === "1") setAdvancedMenuEnable(true);
        else setAdvancedMenuEnable(false);
      } else {
        setAdvancedMenuEnable(false);
      }
    } catch (ex) {
      console.log("ex", ex);
    }
  }, []);
  async function getUserInfoByID() {
    try {
      if (user.sub) {
        await getuserbyauthproviderid(user.sub, ctechapp.currentUser).then(
          (response) => {
            if (response !== undefined && response !== null) {
              let user_info = JSON.parse(response);
              if (user_info.length !== 0) {
                // GetContactDetailSettingsByID(user_info[0]?.userid);
                GetGeneralSettingByAuthID();
              }
            }
          }
        );
      }
    } catch (exception) {
      console.error(exception);
      setLoading(false);
    }
  }
  async function GetGeneralSettingByAuthID() {
    try {
      console.log(location.pathname, location.pathname.includes("/manage"));
      await getGeneralSettingByAuthID(ctechapp.currentUser, user.sub).then(
        (response) => {
          if (response && (response.companyLogo || response.companyName)) {
            let _companyName =
              location.pathname && location.pathname.includes("/manage")
                ? ""
                : response?.companyName || "";
            setCompanyLogo(response.companyLogo || "");
            setCompanyName(_companyName || "");
            setLoading(false);
          }
        }
      );
    } catch (exception) {
      console.error(exception);
    }
     setLoading(false);
  }

  return (
    <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
      <Container>
        <Navbar.Brand href="/dashboard">
          {loading ? (
            <span>Loading...</span>
          ) : companyLogo ? (
              <img
                src={companyLogo}
                width="200px"
                height="80px"
                className="headerLogo d-none d-md-block"
                alt="Company Logo"
              />
            ) : companyName ? (
              <span
                className={companyName.length > 15 ? "h6 fw-bold" : "h5 fw-bold"}
              >
                {companyName}
              </span>
            ) : (
              <img
                src="/images/cm_logo.png"
                    height="80px"
                    width="200px"
                    className="headerLogo d-none d-md-block"
                    alt="Default Logo"
                  />
          )}
        </Navbar.Brand>
        {!window.location.pathname.includes("contactverification") && (
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        )}

        <Navbar.Collapse id="responsive-navbar-nav">
          {!isAuthenticated && <Nav className="me-auto"></Nav>}
          {advancedMenuEnable && <MainNav />}
        </Navbar.Collapse>

        <Navbar.Collapse id="responsive-navbar-nav">
          <AuthNav />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default NavBar;
