// gTable.jsx

import React, { useEffect } from "react";
import {
  useGlobalFilter,
  useTable,
  useSortBy,
  usePagination,
  useColumnOrder,
} from "react-table";
import { GenericSearchWithExport } from "../gridTable/genericsearchwithexport";
import { useHistory } from "react-router";
export default function GenericListTable({
  gcolumns,
  gdata,
  ghiddencolumns,
  gkey,
  gkey1,
  glink,
  gfunction,
  filterDisabled,
  gplaceholder,
  gexportheaders,
  gexportEnabled,
  gdisablePagination,
}) {
  const history = useHistory();
  // Use the useTable Hook to send the columns and data to build the table
  const getData = () => {
    if (gdata !== undefined && gdata != "") {
      var arr = [];
      var obj = JSON.parse(gdata);
      for (var i in obj) arr.push(obj[i]);
      return arr;
    }
    return [];
  };
  const data = React.useMemo(() => getData(), [gdata]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    globalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns: gcolumns,
      data,
      initialState: { hiddenColumns: ghiddencolumns, pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useColumnOrder
  );

  /* 
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  useEffect(() => {
    if (gdisablePagination) {
      setPageSize(Number(gdata.length));
    }
  });
  return (
    <div className="table-responsive mb-0">
          <GenericSearchWithExport
          preGlobalFilteredRows={preGlobalFilteredRows}
          setGlobalFilter={setGlobalFilter}
          globalFilter={globalFilter}
          pageSize={pageSize}
          setPageSize={setPageSize}
          filterDisabled={filterDisabled}
          placeholder={gplaceholder}
          exportEnabled={gexportEnabled}
          gexportHeaders={gexportheaders}
          gexportData={globalFilteredRows}
          gdisablePagination={gdisablePagination}
          globalFilteredRows={globalFilteredRows}
          />
     
      <table
        className="table table-hover table-panel align-middle mb-3"
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div className="d-flex align-items-center">
                    <span>{column.render("Header")}</span>
                    <span className="ms-auto">
                      {column.sortable ? (
                        column.isSorted ? (
                          column.isSortedDesc ? (
                            <i className="fa fa-sort-down fa-fw fs-14px text-blue"></i>
                          ) : (
                            <i className="fa fa-sort-up fa-fw fs-14px text-blue"></i>
                          )
                        ) : (
                          <i className="fa fa-sort fa-fw fs-14px opacity-3"></i>
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                className="highlight"
                style={{ cursor: "pointer" }}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  if (cell.column.Header === "Action" || gkey === "") {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  } else {
                    return (
                      <td
                        {...cell.getCellProps({
                          onClick: (e) => {
                            if (glink === "/") {
                              gfunction(row);
                            } else {
                              history.push({
                                pathname: glink,
                                state: {
                                  [gkey]: row.allCells[0].value,
                                  [gkey1]: row.allCells[1].value,
                                },
                              });
                            }
                          },
                        })}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {!gdisablePagination && (
           <div class="d-flex justify-content-between">
           <div className="d-flex align-items-center ">
            {globalFilteredRows.length>10 && (
              <div className="d-flex align-items-center ">
                <div className="me-1">Go to page:</div>
                <div className="">
                    <input
                      className="form-control"
                      type="number"
                      defaultValue={pageIndex + 1}
                      onChange={(e) => {
                        const page = e.target.value ? Number(e.target.value) - 1 : 0;
                        gotoPage(page);
                      }}
                    />
                  </div> 
              </div> 
            )}
             
             </div>
             <div className="d-flex align-items-center ">
             <div className="me-1">Total records:</div>
               <div className="">
                 <label className="form-control">{globalFilteredRows.length}</label>
               </div>
               
             </div>
             <div>
             {globalFilteredRows.length>10 && (
              <div>
                  <ul className="pagination mb-0">
                 <li className="page-item">
                   <button
                     className="page-link"
                     onClick={() => gotoPage(0)}
                     disabled={!canPreviousPage}
                   >
                     <i className="fa fa-angle-double-left"></i>
                   </button>
                 </li>
                 <li className="page-item">
                   <button
                     className="page-link"
                     onClick={() => previousPage()}
                     disabled={!canPreviousPage}
                   >
                     <i className="fa fa-angle-left"></i>
                   </button>
                 </li>
                 <li className="page-item d-flex align-items-center px-2">
                   <div>
                     Page{" "}
                     <strong>
                       {pageIndex + 1} of {pageOptions.length}
                     </strong>
                   </div>
                 </li>
                 <li className="page-item">
                   <button
                     className="page-link"
                     onClick={() => nextPage()}
                     disabled={!canNextPage}
                   >
                     <i className="fa fa-angle-right"></i>
                   </button>
                 </li>
                 <li className="page-item">
                   <button
                     className="page-link"
                     onClick={() => gotoPage(pageCount - 1)}
                     disabled={!canNextPage}
                   >
                     <i className="fa fa-angle-double-right"></i>
                   </button>
                 </li>
               </ul>
                </div>
             )}
           
             </div>
           
           
            
               
              
           </div>

      )}
   
     
     
    </div>
  );
}
