export const appConstants = {
  YEARFORMAT: "YYYY",
  bannerimage: "/images/cm_logo.png",
  bannersmallimage: "/images/cm_small_logo.png",
  DATEFORMAT: "MM/DD/YYYY",
  DCDATEFORMAT: "YYYY-MM-DD",
  // dropdown for import action category
  // appConstants.selectActionOptions
  selectActionOptions: [
    { value: "0", label: "Select" },
    { value: "1", label: "Approve w/o Verification" },
    { value: "2", label: "Review and Send Verification" },
  ],
  // dropdown for apps
  AutoApprovalList: [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
  ],
  // contact, import, pending(create & update)
  contact: {
    //? --> appConstants.contact.handlefieldvalidation.
    handlefieldvalidation: {
      firstname: "first name must be at least 3 characters",
      lastname: "last name must be at least 3 characters",
      email: "Invalid email",
      companyname: "Company Name must be at least 3 characters",
      nodata: "No records found",
    },
    //? --> appConstants.contact.handlefieldrequiredvalidation.
    handlefieldrequiredvalidation: {
      firstname: "First name required",
      lastname: "Last name required",
      email: "Email required",
      label: "Label required",
      address: "Address required",
      type: "Type required",
      phone: "Phone required",
      companyname: "Company name required",
    },

    //? --> appConstants.contact.alreadyexistmsg.
    alreadyexistmsg: {
      email: "Email already exists.",
      phone: "Phone number already exists",
      ismain: "Ismain already exists.",
      contact: "Contact already exists.",
      affiliation: "Affiliation has been already added",
    },
    //? --> appConstants.contact.confirmationmsg.
    confirmationmsg: {
      company: "Do you want to change company ?",
      delete: "Do you want to delete ?",
      deleteaff: "Do you want to delete this affiliation ?",
      reactiveaff: "Do you want to reactive affiliation ?",
      rejectwish: " Do you wish to reject contact",
      contactverification_profile:
        "You should login to manage your profile. click here to continue.",
      contactverification_linkexpired: " Your link has expired",
      contactverification_linksend: " Do you want to resend the link ?",
      contactverification_thanks:
        "Thank you for verifying your information for",
      contactverification_alreadyverified:
        "Contact has been verified already...",
      contactverification_incorrectsignupurl:
        "We are unable to generate new link. due to incorrect signup URL",
      verificationmsg: "Thank you for verifying your information for ",
    },
    //? --> appConstants.contact.successfullmsg.
    successfullmsg: {
      create: "Contact created successfully.",
      delete: "Contact deleted successfully",
      update: "Contact updated successfully.",
      approve: "Contact approved successfully.",
      reject: "Contact rejected successfully.",
      userupdate: "User info updated successfully.",
      sendverification: "Contact verification SMS sent successfully",
      adminverification: "Admin verification completed successfully.",
      contactverification_newlink: "New link has been sent successfully...",
      generalsetting_success: "Contact settings updated successfully.",
      generalsetting_fail: "Contact settings update failed.",
    },
    //? --> appConstants.contact.notselectedmsg.
    notselectedmsg: {
      company: "Contact company not selected.",
      role: "Contact role not selected.",
    },
  },
  // import
  import: {
    //? --> appConstants.import.handlefieldvalidation.nodata
    alertmsg: {
      newlink: "New link has been sent successfully.",
      errorlink:
        "We are unable to generate new link. due to incorrect signup URL",
    },
    //? --> appConstants.import.handlefieldvalidation.
    handlefieldvalidation: {
      noeligiblerecord: "No eligible records to import.",
      nodata: "No records found",
      companyid: "Account display name required",
      fromdate: "From date required",
      todate: "To date required",
      totaldays:
        "Please select a valid from date and to date/to date should be after a from date",
    },
    //? --> appConstants.import.handlefieldrequiredvalidation.action

    handlefieldrequiredvalidation: {
      action: "Action isn't selected.",
    },
    //? --> appConstants.import.commandpost.
    commandpost: {
      create: "Commandpost contact record import has been initiated.",
      error: "Commandpost contact record import has been not initiated.",
      dedup: "Commandpost deduplication import has been initiated.",
    },
    //? --> appConstants.import.procore.
    procore: {
      create: "Procore contact record import has been initiated.",
      error: "Procore contact record import has been not initiated.",
      dedup: "Procore deduplication import has been initiated.",
    },
    //? --> appConstants.import.quickbooks.
    quickbooks: {
      create: "Quickbooks contact record import has been initiated.",
      Error: "Quickbooks contact record import has been not initiated.",
      role: "Quickbooks app contact role not selected",
      dedup: "Quickbooks deduplication import has been initiated.",
      multipleuser: "Selected user has multiple selected quickbooks applications",
    },
    //? --> appConstants.import.quickbooks.
    entraid: {
      create: "EntraID contact record import has been initiated.",
      Error: "EntraID contact record import has been not initiated.",
      dedup: "EntraID deduplication import has been initiated.",
    },
  },
};
