import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
//import * as yup from "yup";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
//import { useHistory, useParams } from "react-router";
//import Loading from "../components/Loading";
import { BSON } from "realm-web";
import { getapiusersettingsbyappname_userid_companyid } from "../../services/api_user_settings_services";
import { useRealmApp } from "../../providers/RealmApp";

const OAuthClient = require("intuit-oauth");
const _appname = "QuickBooks";
function QBConnect({ mongoContext: { authClient } }) {
  const [restapihostname, SetRestApiHostname] = useState();
  const [apiclientid, SetApiClientid] = useState();
  const [apiclientsecret, SetApiClientSecret] = useState();
  const [apirefreshtoken, setApiRefreshtoken] = useState();
  const [apiapptoken, setApiApptoken] = useState();

  const [apiaccesstokenhostname, SetApiAccesstokenHostname] = useState();
  const [apigranttype, SetApiGrantType] = useState();
  const [apienvironment, SetApiEnvironment] = useState();
  const [apisettingsid, SetApiSettingsid] = useState();
  const [appdisplayname, SetAppDisplayName] = useState();

  const [apiredirecturi, SetApiRedirectUri] = useState();
  //const [apiappname, SetApiAppname] = useState();
  const { companyid } = useParams();
  const ctechapp = useRealmApp();
  const { isLoading, error, isAuthenticated, user } = useAuth0();

  useEffect(() => {
    getQBConnectInfo();
  }, [authClient]);

  function getQBConnectInfo() {
    getapiusersettingsbyappname_userid_companyid(
      _appname,
      user.sub,
      companyid,
      ctechapp.currentUser,
      user.sub
    ).then((response) => {
      if (
        response === null ||
        response.length === 0 ||
        response === undefined
      ) {
        const _resultData = JSON.parse(response);
        _resultData.map((res) => {
          SetApiSettingsid(res._id);
          //  SetApiAppname(res.appname);
          SetRestApiHostname(res.restapihostname);
          SetApiClientid(res.apiclientid);
          SetApiClientSecret(res.apiclientsecret);
          setApiRefreshtoken(res.apirefreshtoken);
          setApiApptoken(res.apiapptoken);
          SetApiAccesstokenHostname(res.apiaccesstokenhostname);
          SetApiGrantType(res.apigranttype);
          // SetApiAppCompanyid(companyid);
          SetApiEnvironment(res.apienvironment);
          SetApiRedirectUri(res.apiredirecturi);
        });
      }
    });
  }

  function authorizeUri(authUri) {
    var oauthClient = new OAuthClient({
      clientId: apiclientid, // enter the apps `clientId`
      clientSecret: apiclientsecret, // enter the apps `clientSecret`
      environment: apienvironment, // enter either `sandbox` or `production`
      redirectUri: apiredirecturi, // enter the redirectUri
      logging: false, // by default the value is `false`
    });

    if (oauthClient.environment === undefined) {
      oauthClient.environment = "sandbox";
    }
    let state_var = {
      clientid: apiclientid,
      clientsecret: apiclientsecret,
      redirecturi: apiredirecturi,
      userid: user.sub,
      emailaddress: user.email,
      environment: apienvironment,
      granttype: apigranttype,
      accesstokenhostname: apiaccesstokenhostname,
      apihostname: restapihostname,
      apiappcompanyid: companyid,
      apiappcompanydisplayname: appdisplayname,
      settingsid: apisettingsid,
      appname: "QuickBooks",
      loggedinuserid: user.sub,
    };
    var jsonstate = JSON.stringify(state_var);

    authUri = oauthClient.authorizeUri({
      scope: [OAuthClient.scopes.Accounting],
      state: jsonstate,
    });

    var width = 450,
      height = 730,
      left = window.screen.width / 2 - width / 2,
      top = window.screen.height / 2 - height / 2;
    // Launch Popup using the JS window Object
    var parameters =
      "location=1, width=" +
      width +
      ", height=" +
      height +
      ", top=" +
      top +
      ", left=" +
      left;
    var win = window.open(authUri, "qbconnect", parameters);
    var pollOAuth = window.setInterval(function () {
      try {
        if (win.document.URL.indexOf("code") !== -1) {
          window.clearInterval(pollOAuth);
          win.close();
        }
      } catch (e) {
        console.log(e);
      }
    }, 1000);
  }
  function gotoprofile() {
    window.location.href = "/contactprofile";
  }

  return (
    <div>
      <h1>
        <img
          alt=""
          width="600"
          height="200"
          src="/images/quickbooks_logo_horz.png"
          id="headerLogo"
        />
      </h1>
      <Form>
        <div>
          <Form.Group>
            <Form.Label>
              {" "}
              ClientID<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="enter the clientId"
              name="clientId"
              value={apiclientid}
              onChange={(e) => {
                SetApiClientid(e.target.value);
              }}
            />
          </Form.Group>
        </div>
        <div>
          <Form.Group>
            <Form.Label>
              ClientSecret<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="enter the clientSecret"
              name="clientSecret"
              value={apiclientsecret}
              onChange={(e) => {
                SetApiClientSecret(e.target.value);
              }}
            />
          </Form.Group>
        </div>
        <div>
          <Form.Group className="mb-3">
            <Form.Label>Environment</Form.Label>
            <Form.Control
              id="environment"
              as="select"
              value={apienvironment}
              onChange={(e) => {
                SetApiEnvironment(e.target.value);
              }}
            >
              <option value="sandbox" selected="selected">
                Sandbox
              </option>
              <option value="production">Production</option>
            </Form.Control>
          </Form.Group>
        </div>
        <div>
          <Form.Group>
            <Form.Label>
              Redirect URI<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="enter the redirectUri"
              name="redirectUri"
              value={apiredirecturi}
              onChange={(e) => {
                SetApiRedirectUri(e.target.value);
              }}
            />
          </Form.Group>
        </div>
        <div>
          <Form.Group>
            <Form.Label>
              Refresh Token<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="enter the refreshtoken"
              name="refreshtoken"
              value={apirefreshtoken}
              onChange={(e) => {
                setApiRefreshtoken(e.target.value);
              }}
            />
          </Form.Group>
        </div>
        <div>
          <Form.Group>
            <Form.Label>
              Access Token<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              disabled
              name="apptoken"
              value={apiapptoken}
              onChange={(e) => {
                setApiApptoken(e.target.value);
              }}
            />
          </Form.Group>
        </div>
        <div>
          <a
            className="imgLink"
            href="#"
            id="authorizeUri"
            onClick={() => authorizeUri()}
          >
            <img
              alt=""
              src="/images/C2QB_green_btn_lg_default.png"
              width="200"
            />
          </a>
          <span> </span>
          <Button
            variant="secondary"
            style={{ margin: 20 }}
            onClick={() => gotoprofile()}
          >
            Close
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default QBConnect;
