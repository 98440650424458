import { Button } from "react-bootstrap";
import Select from "react-select";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import customstyles from "../../components/customstyles/react-select-styles";
import { useAuth0 } from "@auth0/auth0-react";
import { useRealmApp } from "../../providers/RealmApp";
import { isEmail } from "validator";
import {
  getAllCountryCodes,
  getCollectionListsByFormName,
} from "../../services/collection_lists_services";
import SweetAlert from "react-bootstrap-sweetalert";
import PhoneInput from "react-phone-input-2";
import { appConstants } from "../../constants/app_constants";
import {
  getAdminVerificationTaskPendingByID,
  updateAdminVerificationTaskByID,
} from "../../services/adminVerificationTask_services";

function AdminVerificationdetail() {
  const ctechapp = useRealmApp();
  const [userrole, setUserrole] = useState("1");
  const [inputverifyclassName, setinputverifyclassName] = useState(
    "form-control border-0 "
  );
  const [inputnotacceptclassName, setinputnotacceptclassName] = useState(
    "form-control border-1  "
  );
  const [inputverifydisabled, setinputverifydisabled] = useState(true);
  const [inputverifyenabled, setinputverifyenabled] = useState(false);
  const [phonedisabled, setphonedisabled] = useState(true);
  const [selectdisabled, setselectdisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [defaultcountry, setdefaultcountry] = useState(1);
  const [crossbuttonicon, setcrossbuttonicon] = useState("fa  fa-times");
  const [tickbuttonicon, settickbuttonicon] = useState("fa  fa-check");
  const [countryCodeLists, setCountryCodeLists] = useState([]);
  const [salutationOptions, setSalutationOption] = useState([]);
  const [genderOptions, setGenderOption] = useState([]);
  const [phoneOptions, setPhoneOption] = useState([]);
  const [addressOptions, setAddressOption] = useState([]);
  const [userRoleOptions, setUserRoleOption] = useState([]);
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  const [isSuccess, setisSuccess] = useState(false);
  const initialpersonalPhoneState = {
    id: "",
    type: { name: "", value: "", status: "", state: "", newvalue: "" },
    phone: { name: "", value: "", status: "", state: "", newvalue: "" },
    ext: { name: "", value: "", status: "", state: "", newvalue: "" },
    extphone: { name: "", value: "", status: "", state: "", newvalue: "" },
    country: { name: "", value: "", status: "", state: "", newvalue: "" },
    isMain: 0,
  };
  const [personalPhone, setPersonalPhone] = useState(initialpersonalPhoneState);
  const [personalPhoneErrors, setPersonalPhoneErrors] = useState(
    initialpersonalPhoneState
  );
  const initialpersonalAddressState = {
    id: "",
    addressLabel: { name: "", value: "", status: "", state: "", newvalue: "" },
    addressLine1: { name: "", value: "", status: "", state: "", newvalue: "" },
    addressLine2: { name: "", value: "", status: "", state: "", newvalue: "" },
    city: { name: "", value: "", status: "", state: "", newvalue: "" },
    country: { name: "", value: "", status: "", state: "", newvalue: "" },
    state: { name: "", value: "", status: "", state: "", newvalue: "" },
    postalCode: { name: "", value: "", status: "", state: "", newvalue: "" },
    isMain: 0,
  };

  const [personalAddress, setPersonalAddress] = useState(
    initialpersonalAddressState
  );
  const [personalAddressErrors, setPersonalAddressErrors] = useState(
    initialpersonalAddressState
  );

  const initialpersonalEmailState = {
    id: "",
    label: { name: "", value: "", status: "", state: "", newvalue: "" },
    email: { name: "", value: "", status: "", state: "", newvalue: "" },
    isMain: { name: "", value: "", status: "", state: "", newvalue: "" },
  };
  const [PhoneArray, setPhoneArray] = useState(initialpersonalPhoneState);
  const [AddressArray, setAddressArray] = useState(initialpersonalAddressState);
  const [EmailArray, setEmailArray] = useState(initialpersonalEmailState);
  const [personalEmail, setPersonalEmail] = useState(initialpersonalEmailState);
  const [personalEmailErrors, setPersonalEmailErrors] = useState(
    initialpersonalEmailState
  );
  const initialbusinessPhoneState = [
    {
      id: "",
      type: { name: "", value: "", status: "", state: "", newvalue: "" },
      phone: { name: "", value: "", status: "", state: "", newvalue: "" },
      ext: { name: "", value: "", status: "", state: "", newvalue: "" },
      extphone: { name: "", value: "", status: "", state: "", newvalue: "" },
      country: { name: "", value: "", status: "", state: "", newvalue: "" },
      isMain: 0,
    },
  ];
  const initialbusinessPhoneErrorState = {
    id: "",
    type: { name: "", value: "", status: "", state: "", newvalue: "" },
    phone: { name: "", value: "", status: "", state: "", newvalue: "" },
    ext: { name: "", value: "", status: "", state: "", newvalue: "" },
    extphone: { name: "", value: "", status: "", state: "", newvalue: "" },
    country: { name: "", value: "", status: "", state: "", newvalue: "" },
    isMain: 0,
  };
  const [businessPhone, setBusinessPhone] = useState(initialbusinessPhoneState);
  const [businessPhoneErrors, setBusinessPhoneErrors] = useState(
    initialbusinessPhoneErrorState
  );
  const initialbusinessEmailState = [
    {
      id: "",
      label: { name: "", value: "", status: "", state: "", newvalue: "" },
      email: { name: "", value: "", status: "", state: "", newvalue: "" },
      isMain: { name: "", value: "", status: "", state: "", newvalue: "" },
      bus_index: { name: "", value: "", status: "", state: "", newvalue: "" },
      bus_phone_index: {
        name: "",
        value: "",
        status: "",
        state: "",
        newvalue: "",
      },
    },
  ];
  const initialbusinessEmailErrorState = {
    id: "",
    label: { name: "", value: "", status: "", state: "", newvalue: "" },
    email: { name: "", value: "", status: "", state: "", newvalue: "" },
    isMain: { name: "", value: "", status: "", state: "", newvalue: "" },
  };
  const [businessEmail, setBusinessEmail] = useState(initialbusinessEmailState);
  const [businessEmailErrors, setBusinessEmailErrors] = useState(
    initialbusinessEmailErrorState
  );

  const initialbusinessAddressState = [
    {
      id: "",
      addressLabel: {
        name: "",
        value: "",
        status: "",
        state: "",
        newvalue: "",
      },
      addressLine1: {
        name: "",
        value: "",
        status: "",
        state: "",
        newvalue: "",
      },
      addressLine2: {
        name: "",
        value: "",
        status: "",
        state: "",
        newvalue: "",
      },
      city: { name: "", value: "", status: "", state: "", newvalue: "" },
      country: { name: "", value: "", status: "", state: "", newvalue: "" },
      state: { name: "", value: "", status: "", state: "", newvalue: "" },
      postalCode: { name: "", value: "", status: "", state: "", newvalue: "" },
      isMain: { name: "", value: "", status: "", state: "", newvalue: "" },
    },
  ];
  const initialbusinessAddressErrorState = {
    id: "",
    addressLabel: { name: "", value: "", status: "", state: "", newvalue: "" },
    addressLine1: { name: "", value: "", status: "", state: "", newvalue: "" },
    addressLine2: { name: "", value: "", status: "", state: "", newvalue: "" },
    city: { name: "", value: "", status: "", state: "", newvalue: "" },
    country: { name: "", value: "", status: "", state: "", newvalue: "" },
    state: { name: "", value: "", status: "", state: "", newvalue: "" },
    postalCode: { name: "", value: "", status: "", state: "", newvalue: "" },
    isMain: { name: "", value: "", status: "", state: "", newvalue: "" },
  };

  const [businessAddress, setBusinessAddress] = useState(
    initialbusinessAddressState
  );
  const [businessAddressErrors, setBusinessAddressErrors] = useState(
    initialbusinessAddressErrorState
  );

  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    email: "",
  });
  const initialContactState = {
    id: "",
    userid: "",
    firstname: {
      name: "",
      value: "",
      status: "",
      state: "",
      newvalue: "",
      approved_on: "",
      approved_by: "",
    },
    salutation: {
      name: "",
      value: "",
      status: "",
      state: "",
      newvalue: "",
      approved_on: "",
      approved_by: "",
    },
    lastname: {
      name: "",
      value: "",
      status: "",
      state: "",
      newvalue: "",
      approved_on: "",
      approved_by: "",
    },
    middlename: {
      name: "",
      value: "",
      status: "",
      state: "",
      newvalue: "",
      approved_on: "",
      approved_by: "",
    },
    phone: {
      name: "",
      value: "",
      status: "",
      state: "",
      newvalue: "",
      approved_on: "",
      approved_by: "",
    },
    email: {
      name: "",
      value: "",
      status: "",
      state: "",
      newvalue: "",
      approved_on: "",
      approved_by: "",
    },
    gender: {
      name: "",
      value: "",
      status: "",
      state: "",
      newvalue: "",
      approved_on: "",
      approved_by: "",
    },
    dateOfBirth: {
      name: "",
      value: "",
      status: "",
      state: "",
      newvalue: "",
      approved_on: "",
      approved_by: "",
    },
    nickName: {
      name: "",
      value: "",
      status: "",
      state: "",
      newvalue: "",
      approved_on: "",
      approved_by: "",
    },
    contactOwner: {
      name: "",
      value: "",
      status: "",
      state: "",
      newvalue: "",
      approved_on: "",
      approved_by: "",
    },
    description: {
      name: "",
      value: "",
      status: "",
      state: "",
      newvalue: "",
      approved_on: "",
      approved_by: "",
    },
    contactRole: {
      name: "",
      value: "",
      status: "",
      state: "",
      newvalue: "",
      approved_on: "",
      approved_by: "",
    },
    role: {
      name: "",
      value: "",
      status: "",
      state: "",
      newvalue: "",
      approved_on: "",
      approved_by: "",
    },
    PhoneArray: [],
    AddressArray: [],
    EmailArray: [],
    contactAffiliationsArray: [],
  };
  const [contactErrors, setContactErrors] = useState(initialContactState);

  const [contact, setContact] = useState(initialContactState);
  const initialbusinessContact = {
    id: uuid(),
    companyName: { name: "", value: "", status: "", state: "", newvalue: "" },
    contactOwner: { name: "", value: "", status: "", state: "", newvalue: "" },
    description: { name: "", value: "", status: "", state: "", newvalue: "" },
    role: { name: "", value: "", status: "", state: "", newvalue: "" },
    contactRole: { name: "", value: "", status: "", state: "", newvalue: "" },
    sourceSystemId: {
      name: "",
      value: "",
      status: "",
      state: "",
      newvalue: "",
    },
    contactRecordType: {
      name: "",
      value: "",
      status: "",
      state: "",
      newvalue: "",
    },
    department: { name: "", value: "", status: "", state: "", newvalue: "" },
    reportTo: { name: "", value: "", status: "", state: "", newvalue: "" },
    claimedUrl: { name: "", value: "", status: "", state: "", newvalue: "" },
    phoneArray: [],
    addressArray: [],
    emailArray: [],
  };

  const [businessContact, setBusinessContact] = useState(
    initialbusinessContact
  );
  const [businessContactErrors, setBusinessContactErrors] = useState(
    initialbusinessContact
  );

  const [isvaliddata, setisvaliddata] = useState(false);
  const [userInfo, setUserInfo] = useState(false);

  const { ids } = useParams();

  useEffect(() => {
    if (isAuthenticated) {
      getDataContactTaskDetailsByID();
      GetSalutationOptions();
      GetGenderOptions();
      GetPhoneOptions();
      GetAddressOptions();
      GetUserRoleOptions();
    }
  }, [user.sub]);

  async function getDataContactTaskDetailsByID() {
    setLoading(true);
    console.log(ids, ctechapp.currentUser, user.sub);
    getAdminVerificationTaskPendingByID(
      ids,
      ctechapp.currentUser,
      user.sub
    ).then((response) => {
      let user_info = JSON.parse(response);
      if (user_info !== undefined || user_info.length !== 0) {
        const res = user_info[0];
        console.log("res ", res);
        console.log(res);
        setContact(res);
        setPhoneArray(res.phoneArray);
        setAddressArray(res.addressArray);
        setEmailArray(res.emailArray);
      }
    });
  }

  async function GetSalutationOptions() {
    await getCollectionListsByFormName("Salutation", ctechapp.currentUser).then(
      (response) => {
        if (response !== undefined) {
          let salutation_info = JSON.parse(response);

          let options = salutation_info.map(function (salutation) {
            return { value: salutation.listValue, label: salutation.listName };
          });

          setSalutationOption(options);
        }
      }
    );
  }
  async function GetGenderOptions() {
    await getCollectionListsByFormName("Gender", ctechapp.currentUser).then(
      (response) => {
        if (response !== undefined) {
          let gender_info = JSON.parse(response);

          let options = gender_info.map(function (gender) {
            return { value: gender.listValue, label: gender.listName };
          });

          setGenderOption(options);
        }
      }
    );
  }

  async function GetPhoneOptions() {
    await getCollectionListsByFormName("Phone", ctechapp.currentUser).then(
      (response) => {
        if (response !== undefined) {
          let phone_info = JSON.parse(response);

          let options = phone_info.map(function (phone) {
            return { value: phone.listValue, label: phone.listName };
          });

          setPhoneOption(options);
        }
      }
    );
  }

  async function GetAddressOptions() {
    await getCollectionListsByFormName("Address", ctechapp.currentUser).then(
      (response) => {
        if (response !== undefined) {
          let address_info = JSON.parse(response);
          let options = address_info.map(function (address) {
            return { value: address.listValue, label: address.listName };
          });
          setAddressOption(options);
        }
      }
    );
  }
  async function GetUserRoleOptions() {
    await getCollectionListsByFormName(
      "ContactRole",
      ctechapp.currentUser
    ).then((response) => {
      if (response !== undefined) {
        let userrole_info = JSON.parse(response);

        let options = userrole_info.map(function (userrole) {
          return {
            value: userrole.listValue,
            label: userrole.listName,
          };
        });

        setUserRoleOption(options);
      }
    });
  }

  const handleChangerole = (event) => {
    setUserrole(event.target.value);
  };
  //save functions
  const handleDropDownChange = (selectedOption, itemupdate, index) => {
    const { name, value } = selectedOption.target;
    if (itemupdate === "contact") {
      console.log(name, value);
      setContact({
        ...contact,
        [name]: {
          ...contact[name],
          newvalue: value,
        },
      });
      //   setContact({ ...contact, [name]: value });
      setContactErrors({ ...contactErrors, [name]: "" });
    }
    if (itemupdate === "personalphone") {
      setPersonalPhone({ ...personalPhone, [name]: value });
      setPersonalPhoneErrors({ ...personalPhoneErrors, [name]: "" });
    }
    if (itemupdate === "personaladdress") {
      setPersonalAddress({ ...personalAddress, [name]: value });
      setPersonalAddressErrors({ ...personalAddressErrors, [name]: "" });
    }
    if (itemupdate === "personalemail") {
      setPersonalEmail({ ...personalEmail, [name]: value });
    }
    if (itemupdate === "businessemail") {
      setBusinessEmail({ ...businessEmail, [name]: value });
    }
    if (itemupdate === "businessphone") {
      //copying data to temp variable so that we do not directly mutate original state
      const temparrayprops = [...businessPhone];
      //findIndex to find location of item we need to update
      //  let index = temparrayprops.findIndex((item) => item.id == itemId);
      // -1 check to see if we found that object in social_media
      if (index != -1) {
        temparrayprops[index] = {
          ...temparrayprops[index], //keeping existing values in object
          [name]: value, //here property can be "link"
        };
      }

      setBusinessPhone((prevList) => {
        prevList = temparrayprops;
        return prevList;
      });
      setBusinessPhoneErrors({ ...businessPhoneErrors, [name]: "" });
    }
    if (itemupdate === "businessaddress") {
      //copying data to temp variable so that we do not directly mutate original state
      const temparrayprops = [...businessAddress];
      //findIndex to find location of item we need to update
      //  let index = temparrayprops.findIndex((item) => item.id == itemId);
      // -1 check to see if we found that object in social_media
      if (index != -1) {
        temparrayprops[index] = {
          ...temparrayprops[index], //keeping existing values in object
          [name]: value, //here property can be "link"
        };
      }

      setBusinessAddress((prevList) => {
        prevList = temparrayprops;
        return prevList;
      });
      setBusinessAddressErrors({ ...businessAddressErrors, [name]: "" });
    }
  };

  function handlecontactPhoneChange(event, type) {
    // console.log(target);
    const { name, value } = event.target;
    const path = name.split(".");
    const newpersonalcontact = { ...contact };
    let pointer = newpersonalcontact;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
      pointer.newvalue = value;
      //  console.log("el ", el);
    });

    setContact(newpersonalcontact);
    // handlePersonalFieldValidation(event);
    setisvaliddata(true);
  }
  /* function handlecontactPhoneChange(value) {
    console.log(value);

    setContact({ ...contact, ["phone"]: value });
    // handlePersonalFieldValidation(event);
    setisvaliddata(true);
  } */
  function handleBusinessChange(e, itemId, property, index, islist) {
    const value = islist === true ? e.value : e.target.value;
    //copying data to temp variable so that we do not directly mutate original state

    var _contact = { ...contact };
    var temparrayprops = _contact.contactAffiliationsArray[index];
    temparrayprops[property]["newvalue"] = value;
    temparrayprops[property]["state"] = "modified";
    //console.log("temparrayprops ", temparrayprops);

    _contact.contactAffiliationsArray[index] = temparrayprops;

    setContact(_contact);
  }
  // function handleBusinessChange(e, itemId, property, islist) {
  //   const value = islist === true ? e.value : e.target.value;
  //   //copying data to temp variable so that we do not directly mutate original state
  //   const temparrayprops = [...contact?.contactAffiliationsArray];
  //   //findIndex to find location of item we need to update
  //   let index = temparrayprops.findIndex((item) => item.id == itemId);
  //   // -1 check to see if we found that object in social_media
  //   if (index != -1) {
  //     temparrayprops[index] = {
  //       ...temparrayprops[index], //keeping existing values in object
  //       [property]: value, //here property can be "link"
  //       state:"modified"
  //     };
  //   }
  //   setContact({ ...contact, contactAffiliationsArray: temparrayprops });
  // }

  const handlePersonalFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "firstname":
        localerror =
          value.length < 3 ? "first name must be at least 3 characters" : "";
        break;
      case "lastname":
        localerror =
          value.length < 3 ? "last name must be at least 3 characters" : "";
        break;
      case "email":
        localerror = !isEmail(value) ? "Invalid Email" : "";
        break;
      default:
        break;
    }
    setContactErrors({ ...contactErrors, [name]: localerror });
    setisvaliddata(true);
  };

  const handleBusinessFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "companyName":
        localerror =
          value.length < 3 ? "Company name must be at least 3 characters" : "";
        break;

      default:
        break;
    }
    setBusinessContactErrors({ ...contactErrors, [name]: localerror });
    setisvaliddata(true);
  };
  async function submitHandler(values) {
    console.log("contactwhilesubmit", contact);

    console.log("contact", contact);
    updateAdminVerificationTaskByID(
      contact,
      ctechapp.currentUser,
      user.sub
    ).then((res) => {
      setisSuccess(true);
    });
  }
  const handleSucessSweetAlert = () => {
    setisSuccess(false);
    if (ids !== undefined) {
      gotoPendingVerificationList();
    }
  };
  function gotoPendingVerificationList() {
    window.location.href = "/pendingverificationlist";
  }
  const validBusinessForm = () => {
    try {
      let valid = false;
      valid = handleFieldBusinessListRequiredValidation();
      Object.values(personalEmailErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldBusinessListRequiredValidation = () => {
    let iserror = "";

    setBusinessContactErrors({
      ...businessContactErrors,
      companyName:
        businessContact.companyName === ""
          ? (iserror = "Company name required")
          : businessContact.companyName,
    });

    return iserror.length === 0;
  };

  const validPersonalForm = () => {
    try {
      let valid = false;
      valid = handleFieldPersonalListRequiredValidation();
      Object.values(personalEmailErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldPersonalListRequiredValidation = () => {
    let iserror = "";

    setContactErrors({
      ...contactErrors,
      firstname:
        contact?.firstname === ""
          ? (iserror = "First name required")
          : contactErrors.firstname,
      lastname:
        contact?.lastname === ""
          ? (iserror = "Last name required")
          : contactErrors.lastname,

      email:
        contact?.email === ""
          ? (iserror = "Email required")
          : contactErrors.email,
    });

    return iserror.length === 0;
  };

  const handleCancel = () => {
    if (ids !== undefined) window.location.href = "/pendingverificationlist";
  };

  //personal info
  //button action for personal phone
  const SelectPersonalPhoneRow = (index) => {
    const rowsInput = [...PhoneArray];
    setPersonalPhone(rowsInput[index]);
    setPersonalPhoneErrors(initialpersonalPhoneState);
  };

  const deletePersonalPhoneRow = (index) => {
    const rows = [...PhoneArray];
    rows.splice(index, 1);
    setPhoneArray(rows);
  };

  //button action for personal address
  const SelectPersonalAddressRow = (index) => {
    const rowsInput = [...AddressArray];
    setPersonalAddress(rowsInput[index]);
    setPersonalAddressErrors(initialpersonalAddressState);
  };

  const deletePersonalAddressRow = (index) => {
    const rows = [...AddressArray];
    rows.splice(index, 1);
    setAddressArray(rows);
  };

  //button action for personal email

  const SelectPersonalEmailRow = (index) => {
    const rowsInput = [...EmailArray];
    setPersonalEmail(rowsInput[index]);
    setPersonalEmailErrors(initialpersonalEmailState);
  };

  const deletePersonalEmailRow = (index) => {
    const rows = [...EmailArray];
    rows.splice(index, 1);
    setEmailArray(rows);
  };

  // handle change for personal phone

  const handleChangePersonalPhone = (
    event,
    itemid,
    index,
    pname,
    property,
    islist
  ) => {
    let name = "";
    let value = "";
    //event.preventDefault();
    if (islist === "true") {
      value = event.value;
      name = pname;
    } else {
      value = property === "fullPhone" ? event : event.target.value;
      name = property === "fullPhone" ? pname : event.target.name;
    }

    if (property === "isMain") {
      value = event.target.checked === true ? 1 : 0;
    }
    if (islist !== "true" && property != "fullPhone")
      handlePersonalEmailFieldValidation(event);

    name = name.replaceAll(/[_]/g, ".");
    const path = name.split(".");

    if (path.length === 3) {
      var el = path[0];
      var el1 = path[1];
      if (index > -1) {
        //copying data to temp variable so that we do not directly mutate original state
        const temparrayprops = [...PhoneArray];
        //findIndex to find location of item we need to update
        let sindex = temparrayprops.findIndex((item) => item.id == itemid);
        // -1 check to see if we found that object in social_media
        if (sindex != -1) {
          temparrayprops[sindex][el1] = {
            ...temparrayprops[sindex][el1], //keeping existing values in object
            newvalue: value, //here property can be "link"
            state: "modified",
          };
        }
        console.log("temparrayprops", temparrayprops);
        setPhoneArray(temparrayprops);
      }
    }
  };

  // handle change for personal address
  const handleChangePersonalAddress = (
    event,
    itemid,
    index,
    property,
    islist
  ) => {
    // event.preventDefault();
    let { name } = event.target;

    let value =
      islist === true
        ? event.value
        : property === "phone"
        ? event
        : event.target.value;
    if (property === "isMain") {
      value = event.target.checked === true ? 1 : 0;
    }

    if (property !== "addresslabel") handlePersonalEmailFieldValidation(event);
    name = name.replaceAll(/[_]/g, ".");
    const path = name.split(".");

    if (path.length === 3) {
      var el = path[0];
      var el1 = path[1];
      if (index > -1) {
        //copying data to temp variable so that we do not directly mutate original state
        const temparrayprops = [...AddressArray];
        //findIndex to find location of item we need to update
        let sindex = temparrayprops.findIndex((item) => item.id == itemid);
        // -1 check to see if we found that object in social_media
        if (sindex != -1) {
          temparrayprops[sindex][el1] = {
            ...temparrayprops[sindex][el1], //keeping existing values in object
            newvalue: value, //here property can be "link"
            state: "modified", //here property can be "link"
          };
        }
        setAddressArray(temparrayprops);
      }
    }
  };

  // handle change for personal email
  const handleChangePersonalEmail = (event, itemid, index, property) => {
    // event.preventDefault();
    let { name, value } = event.target;
    if (property === "isMain") {
      value = event.target.checked === true ? 1 : 0;
    }
    handlePersonalEmailFieldValidation(event);
    name = name.replaceAll(/[_]/g, ".");
    const path = name.split(".");

    if (path.length === 3) {
      var el = path[0];
      var el1 = path[1];
      if (index > -1) {
        //copying data to temp variable so that we do not directly mutate original state
        const temparrayprops = [...EmailArray];
        //findIndex to find location of item we need to update
        let sindex = temparrayprops.findIndex((item) => item.id == itemid);
        // -1 check to see if we found that object in social_media
        if (sindex != -1) {
          temparrayprops[sindex][el1] = {
            ...temparrayprops[sindex][el1], //keeping existing values in object
            newvalue: value, //here property can be "link"
            state: "modified",
          };
        }
        setEmailArray(temparrayprops);
      }
    }
  };
  // const handleChangePersonalEmail = (event) => {
  //   event.preventDefault();
  //   const { name, value } = event.target;
  //   handlePersonalEmailFieldValidation(event);
  //   setPersonalEmail({ ...personalEmail, [name]: value });

  //   if (personalEmail.id === "") {
  //     handlePersonalEmailFieldValidation(event);
  //     setPersonalEmail({ ...personalEmail, ["id"]: uuid() });
  //   }
  // };

  const handlePersonalEmailFieldValidation = (event) => {
    //event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "email":
        localerror = !isEmail(value) ? "Invalid email" : "";
        break;
      default:
        break;
    }
    setPersonalEmailErrors({ ...personalEmailErrors, [name]: localerror });
    setisvaliddata(true);
  };
  //business info
  // handle change for business phone
  function handleChangeBusinessPhone(
    e,
    afflist_index,
    _phoneList_index,
    property,
    islist
  ) {
    let value =
      islist === true ? e.value : property === "fullPhone" ? e : e.target.value;
    if (property === "isMain") {
      value = e.target.checked === true ? 1 : 0;
    }

    var _contact = { ...contact };
    var temparrayprops =
      _contact.contactAffiliationsArray[afflist_index].phoneArray[
        _phoneList_index
      ];
    temparrayprops[property]["newvalue"] = value;
    temparrayprops[property]["state"] = "modified";
    //console.log("temparrayprops ", temparrayprops);

    _contact.contactAffiliationsArray[afflist_index].phoneArray[
      _phoneList_index
    ] = temparrayprops;

    setContact(_contact);
  }
  //button action for Business phone
  //button action for Business address
  const SelectBusinessPhoneRow = (index, id) => {
    const rowsInput = [...contact?.contactAffiliationsArray[index].phoneArray];
    setBusinessPhone((prevbusinenessphone) => {
      //  let localbusinessEmail=prevbusinenessemail[index];
      rowsInput.forEach((element) => {
        if (element.id === id) prevbusinenessphone[index] = element;
      });
      return prevbusinenessphone;
    });
    setBusinessPhoneErrors(initialbusinessPhoneErrorState);
  };

  const deleteBusinessPhoneRow = (index, rowindex) => {
    const rows = [...contact?.contactAffiliationsArray[index].phoneArray];
    rows.splice(rowindex, 1);
    setContact((prevcontact) => {
      prevcontact.contactAffiliationsArray[index].phoneArray = rows;
      //    console.log("prevcontact",JSON.stringify(prevcontact));
      setBusinessPhone(initialbusinessPhoneState);
      return prevcontact;
    });
  };

  //button action for Business address
  const SelectBusinessAddressRow = (index, id) => {
    const rowsInput = [
      ...contact?.contactAffiliationsArray[index].addressArray,
    ];
    setBusinessAddress((prevbusinenessemail) => {
      //  let localbusinessEmail=prevbusinenessemail[index];
      rowsInput.forEach((element) => {
        if (element.id === id) prevbusinenessemail[index] = element;
      });
      return prevbusinenessemail;
    });
    setBusinessAddressErrors(initialbusinessAddressErrorState);
  };

  const deleteBusinessAddressRow = (index, rowindex) => {
    const rows = [...contact?.contactAffiliationsArray[index].addressArray];
    rows.splice(rowindex, 1);
    setContact((prevcontact) => {
      prevcontact.contactAffiliationsArray[index].addressArray = rows;
      //    console.log("prevcontact",JSON.stringify(prevcontact));
      setBusinessAddress(initialbusinessAddressState);
      return prevcontact;
    });
  };

  //button action for Business email

  const SelectBusinessEmailRow = (index, id) => {
    const rowsInput = [...contact?.contactAffiliationsArray[index].emailArray];
    setBusinessEmail((prevbusinenessemail) => {
      //  let localbusinessEmail=prevbusinenessemail[index];
      rowsInput.forEach((element) => {
        if (element.id === id) prevbusinenessemail[index] = element;
      });
      return prevbusinenessemail;
    });
    setBusinessEmailErrors(initialbusinessEmailErrorState);
  };

  const deleteBusinessEmailRow = (index, rowindex) => {
    const rows = [...contact?.contactAffiliationsArray[index].emailArray];
    rows.splice(rowindex, 1);
    setContact((prevcontact) => {
      prevcontact.contactAffiliationsArray[index].emailArray = rows;
      //    console.log("prevcontact",JSON.stringify(prevcontact));
      setBusinessEmail(initialbusinessEmailState);
      return prevcontact;
    });
  };

  //generate guid
  function uuid() {
    var uuidValue = "",
      k,
      randomValue;
    for (k = 0; k < 32; k++) {
      randomValue = (Math.random() * 16) | 0;

      if (k == 8 || k == 12 || k == 16 || k == 20) {
        uuidValue += "";
      }
      uuidValue += (
        k == 12 ? 4 : k == 16 ? (randomValue & 3) | 8 : randomValue
      ).toString(16);
    }
    return uuidValue;
  }

  const addPersonalEmail = async (e) => {
    e.preventDefault();
    if (validEmailForm()) {
      //console.log("personalEmail : ", personalEmail);
      if (EmailArray !== null) {
        const objIndex = EmailArray.findIndex(
          (obj) => obj.id === personalEmail.id
        );
        console.log("objIndex ", objIndex);
        if (objIndex != -1) {
          const rowsInput = [...EmailArray];
          rowsInput[objIndex] = personalEmail;

          setEmailArray(rowsInput);
          setPersonalEmail(initialpersonalEmailState);
        } else {
          setEmailArray([...EmailArray, personalEmail]);
          setPersonalEmail(initialpersonalEmailState);
        }
      } else {
        let emailArray = [];
        emailArray.push(personalEmail);
        setEmailArray(emailArray);
        setPersonalEmail(initialpersonalEmailState);
      }
    }
  };
  const validEmailForm = () => {
    try {
      let valid = false;
      valid = handleFieldPersonalEmailListRequiredValidation();
      Object.values(personalEmailErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldPersonalEmailListRequiredValidation = () => {
    let iserror = "";

    setPersonalEmailErrors({
      ...personalEmailErrors,
      label:
        personalEmail.label === ""
          ? (iserror = "Label required")
          : personalEmailErrors.label,
      email:
        personalEmail.email === ""
          ? (iserror = "Email  required")
          : personalEmailErrors.email,
    });

    return iserror.length === 0;
  };

  //add personal address

  const addPersonalAddress = async (e) => {
    e.preventDefault();
    if (validAddressForm()) {
      if (AddressArray !== null) {
        const objIndex = AddressArray.findIndex(
          (obj) => obj.id === personalAddress.id
        );
        if (objIndex !== -1) {
          const rowsInput = [...AddressArray];
          rowsInput[objIndex] = personalAddress;

          setAddressArray(rowsInput);
          setPersonalAddress(initialpersonalAddressState);
        } else {
          setAddressArray([...AddressArray, personalAddress]);
          setPersonalAddress(initialpersonalAddressState);
        }
      } else {
        let addressArray = [];
        addressArray.push(personalAddress);
        setAddressArray(addressArray);
        setPersonalAddress(initialpersonalAddressState);
      }
    }
  };
  const validAddressForm = () => {
    try {
      let valid = false;
      valid = handleFieldPersonalAddressListRequiredValidation();
      Object.values(personalAddressErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldPersonalAddressListRequiredValidation = () => {
    let iserror = "";

    setPersonalAddressErrors({
      ...personalAddressErrors,
      addressLabel:
        personalAddress.addressLabel === ""
          ? (iserror = "Label required")
          : personalAddressErrors.addressLabel,
      addressLine1:
        personalAddress.addressLine1 === ""
          ? (iserror = "Address required")
          : personalAddressErrors.addressLine1,
    });

    return iserror.length === 0;
  };

  //add personal phone

  const addPersonalPhone = async (e) => {
    e.preventDefault();
    if (validPhoneForm()) {
      if (PhoneArray !== null) {
        const objIndex = PhoneArray.findIndex(
          (obj) => obj.id === personalPhone.id
        );
        if (objIndex !== -1) {
          const rowsInput = [...PhoneArray];
          rowsInput[objIndex] = personalPhone;

          setPhoneArray(rowsInput);
          setPersonalPhone(initialpersonalPhoneState);
        } else {
          setPhoneArray([...PhoneArray, personalPhone]);
          setPersonalPhone(initialpersonalPhoneState);
        }
      } else {
        let phoneArray = [];
        phoneArray.push(personalPhone);
        setPhoneArray(phoneArray);
        setPersonalPhone(initialpersonalPhoneState);
      }
    }
  };
  const validPhoneForm = () => {
    try {
      let valid = false;
      valid = handleFieldPersonalPhoneListRequiredValidation();
      Object.values(personalPhoneErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldPersonalPhoneListRequiredValidation = () => {
    let iserror = "";

    setPersonalPhoneErrors({
      ...personalPhoneErrors,
      type:
        personalPhone.type === ""
          ? (iserror = "Type required")
          : personalPhoneErrors.type,
      phone:
        personalPhone.phone === ""
          ? (iserror = "Phone required")
          : personalPhoneErrors.phone,
    });

    return iserror.length === 0;
  };
  function handleChangeBusinessEmail(
    e,
    afflist_index,
    _emailList_index,
    property,
    islist
  ) {
    let value =
      islist === true ? e.value : property === "phone" ? e : e.target.value;
    if (property === "isMain") {
      value = e.target.checked === true ? 1 : 0;
    }

    var _contact = { ...contact };
    var temparrayprops =
      _contact.contactAffiliationsArray[afflist_index].emailArray[
        _emailList_index
      ];
    temparrayprops[property]["newvalue"] = value;
    temparrayprops[property]["state"] = "modified";
    console.log("temparrayprops ", temparrayprops);

    _contact.contactAffiliationsArray[afflist_index].emailArray[
      _emailList_index
    ] = temparrayprops;

    setContact(_contact);
  }
  // function handleChangeBusinessEmail(e, index, property, islist) {
  //   let value = islist === true ? e.value : e.target.value;
  //   if (property === "isMain") {
  //     value = e.target.value === "on" ? 1 : 0;
  //   }
  //   //copying data to temp variable so that we do not directly mutate original state
  //   const temparrayprops = [...businessAddress];
  //   //findIndex to find location of item we need to update
  //   //  let index = temparrayprops.findIndex((item) => item.id == itemId);
  //   // -1 check to see if we found that object in social_media
  //   if (index != -1) {
  //     temparrayprops[index] = {
  //       ...temparrayprops[index], //keeping existing values in object
  //       [property]: value, //here property can be "link"
  //     };
  //   }
  //   //  setBusinessEmail(temparrayprops);
  //   setBusinessEmail((prevList) => {
  //     prevList = temparrayprops;
  //     return prevList;
  //   });
  // }
  // add business email
  const addBusinessEmail = (e, index) => {
    e.preventDefault();
    if (validBusinessEmailForm(index)) {
      const _businessEmail = businessEmail[index];
      //copying data to temp variable so that we do not directly mutate original state
      let temparrayprops = [
        ...contact?.contactAffiliationsArray[index].emailArray,
      ];
      //findIndex to find location of item we need to update
      let record_index = temparrayprops.findIndex(
        (item) => item.id == _businessEmail.id
      );
      // -1 check to see if we found that object in social_media
      if (record_index != -1) {
        temparrayprops[record_index] = _businessEmail;
        // ...temparrayprops[index], //keeping existing values in object
        // _businessEmail: value, //here property can be "link"
      } else {
        _businessEmail.id = uuid();
        temparrayprops.push(_businessEmail);
      }
      setContact((prevcontact) => {
        prevcontact.contactAffiliationsArray[index].emailArray = temparrayprops;
        //    console.log("prevcontact",JSON.stringify(prevcontact));
        setBusinessEmail(initialbusinessEmailState);
        return prevcontact;
      });
    }
  };

  const validBusinessEmailForm = (index) => {
    try {
      let valid = false;
      return true;
      valid = handleFieldBusinessEmailListRequiredValidation(index);
      Object.values(personalEmailErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldBusinessEmailListRequiredValidation = (index) => {
    let iserror = "";
    return true;
    setBusinessEmailErrors({
      ...businessEmailErrors,
      label:
        businessEmail[index].label === ""
          ? (iserror = "Label required")
          : businessEmailErrors?.label,
      email:
        businessEmail[index].email === ""
          ? (iserror = "Email  required")
          : businessEmailErrors?.email,
    });

    return iserror.length === 0;
  };

  function handleChangeBusinessAddress(
    e,
    afflist_index,
    _addressList_index,
    property,
    islist
  ) {
    let value = islist === true ? e.value : e.target.value;
    if (property === "isMain") {
      value = e.target.checked === true ? 1 : 0;
    }

    var _contact = { ...contact };
    var temparrayprops =
      _contact.contactAffiliationsArray[afflist_index].addressArray[
        _addressList_index
      ];
    temparrayprops[property]["newvalue"] = value;
    temparrayprops[property]["state"] = "modified";
    // console.log("temparrayprops ", temparrayprops);

    _contact.contactAffiliationsArray[afflist_index].addressArray[
      _addressList_index
    ] = temparrayprops;

    setContact(_contact);
  }
  //add business address
  const addBusinessAddress = (e, index) => {
    e.preventDefault();
    if (validBusinessAddressForm(index)) {
      const _businessAddress = businessAddress[index];
      //copying data to temp variable so that we do not directly mutate original state
      let temparrayprops = [
        ...contact?.contactAffiliationsArray[index].addressArray,
      ];
      //findIndex to find location of item we need to update
      let record_index = temparrayprops.findIndex(
        (item) => item.id == _businessAddress.id
      );
      // -1 check to see if we found that object in social_media
      if (record_index != -1) {
        temparrayprops[record_index] = _businessAddress;
        // ...temparrayprops[index], //keeping existing values in object
        // _businessEmail: value, //here property can be "link"
      } else {
        _businessAddress.id = uuid();
        temparrayprops.push(_businessAddress);
      }
      setContact((prevcontact) => {
        prevcontact.contactAffiliationsArray[index].addressArray =
          temparrayprops;
        //    console.log("prevcontact",JSON.stringify(prevcontact));
        setBusinessAddress(initialbusinessAddressState);
        return prevcontact;
      });
    }
  };

  const validBusinessAddressForm = (index) => {
    try {
      let valid = true;
      return valid;
      valid = handleFieldBusinessAddressListRequiredValidation();
      Object.values(personalAddressErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldBusinessAddressListRequiredValidation = () => {
    let iserror = "";

    setBusinessAddressErrors({
      ...businessAddressErrors,
      addressLabel:
        businessAddress.addressLabel === ""
          ? (iserror = "Label required")
          : businessAddressErrors.addressLabel,
      addressLine1:
        businessAddress.addressLine1 === ""
          ? (iserror = "Address required")
          : businessAddressErrors.addressLine1,
    });

    return iserror.length === 0;
  };

  //add business phone
  const addBusinessPhone = (e, index) => {
    e.preventDefault();
    if (validBusinessPhoneForm(index)) {
      const _businessPhone = businessPhone[index];
      //copying data to temp variable so that we do not directly mutate original state
      let temparrayprops = [
        ...contact?.contactAffiliationsArray[index].phoneArray,
      ];
      //findIndex to find location of item we need to update
      let record_index = temparrayprops.findIndex(
        (item) => item.id == _businessPhone.id
      );
      // -1 check to see if we found that object in social_media
      if (record_index != -1) {
        temparrayprops[record_index] = _businessPhone;
        // ...temparrayprops[index], //keeping existing values in object
        // _businessEmail: value, //here property can be "link"
      } else {
        _businessPhone.id = uuid();
        temparrayprops.push(_businessPhone);
      }
      setContact((prevcontact) => {
        prevcontact.contactAffiliationsArray[index].phoneArray = temparrayprops;
        //    console.log("prevcontact",JSON.stringify(prevcontact));
        setBusinessAddress(initialbusinessPhoneState);
        return prevcontact;
      });
    }
  };

  const validBusinessPhoneForm = (index) => {
    try {
      let valid = true;
      return valid;
      valid = handleFieldBusinessPhoneListRequiredValidation();
      Object.values(businessPhoneErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldBusinessPhoneListRequiredValidation = () => {
    let iserror = "";

    setBusinessPhoneErrors({
      ...businessPhoneErrors,
      type:
        businessPhone.type === ""
          ? (iserror = "Type required")
          : businessPhoneErrors.type,
      country:
        businessPhone.country === ""
          ? (iserror = "Country code required")
          : businessPhoneErrors.country,
      phone:
        businessPhone.phone === ""
          ? (iserror = "Phone required")
          : businessPhoneErrors.phone,
    });

    return iserror.length === 0;
  };
  const handlePersonalChange = (event) => {
    console.log("event", event);
    var name = event.target.name;
    var value = event.target.value;
    const path = name.split(".");
    const newpersonalcontact = { ...contact };
    let pointer = newpersonalcontact;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
      pointer.newvalue = value;
      pointer.state = "modified";
      //  console.log("el ", el);
    });

    setContact(newpersonalcontact);
    setisvaliddata(true);
  };
  const handlePersonalChange_acceptsave = (controlname, value) => {
    //console.log("event", event);
    var name = controlname.name;
    // var value = "verified";
    var index = controlname.index;
    name = name.replaceAll(/[_]/g, ".");
    const path = name.split(".");

    if (path.length === 1) {
      const newpersonalcontact = { ...contact };
      let pointer = newpersonalcontact;
      var el = path[0];
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
      if (pointer !== undefined) pointer.status = value;
      setContact(newpersonalcontact);
    } else if (path.length === 3) {
      var el = path[0];
      var el1 = path[1];
      if (index > -1) {
        //copying data to temp variable so that we do not directly mutate original state
        const temparrayprops = [...contact[el]];
        //findIndex to find location of item we need to update
        let sindex = temparrayprops.findIndex(
          (item) => item.id == controlname.itemid
        );
        // -1 check to see if we found that object in social_media
        if (sindex != -1) {
          temparrayprops[sindex][el1] = {
            ...temparrayprops[sindex][el1], //keeping existing values in object
            status: value, //here property can be "link"
          };
        }
        const newpersonalcontact = { ...contact, [el]: temparrayprops };
        setContact(newpersonalcontact);
      }
    } else if (path.length === 5) {
      var el = path[0];
      var el1 = path[1];
      var el2 = path[2];
      if (index > -1) {
        //copying data to temp variable so that we do not directly mutate original state
        const temparrayprops = [...contact?.contactAffiliationsArray];

        //findIndex to find location of item we need to update
        let sindex = -1;
        if (el1 === "emailArray") {
          sindex = temparrayprops[index].emailArray.findIndex(
            (item) => item.id == controlname.subitemid
          );
        }
        if (el1 === "addressArray") {
          sindex = temparrayprops[index].addressArray.findIndex(
            (item) => item.id == controlname.subitemid
          );
        }
        if (el1 === "phoneArray") {
          sindex = temparrayprops[index].phoneArray.findIndex(
            (item) => item.id == controlname.subitemid
          );
        }
        // -1 check to see if we found that object in social_media
        if (sindex != -1) {
          temparrayprops[index][el1][sindex][el2] = {
            ...temparrayprops[index][el1][sindex][el2], //keeping existing values in object
            status: value,
          };
        }

        const newpersonalcontact = { ...contact, [el]: temparrayprops };
        setContact(newpersonalcontact);
      }
    }

    let fieldname = name + ".newvalue";

    let updatefield = {
      $fieldname: value,
    };

    //    console.log("updatefield", JSON.stringify(updatefield));
    //  console.log("id", contact?._id);

    // updateContactVerificationAutosaveTaskByID(
    //   contact?._id,
    //   updatefield,
    //   ctechapp.currentUser,
    //   ctechapp.currentUser.id
    // ).then((res) => {
    //   console.log("res ", res);
    // });

    setisvaliddata(true);
  };
  const handletickbutton = (
    e,
    controlname,
    tickbuttonname,
    crossbuttonname,
    tickverifyiconname,
    crossverifyiconname,
    tickaccepticonname,
    crossaccepticonname
  ) => {
    const divacceptcontrolname = "divaccept_" + controlname.name;
    const divverifycontrolname = "divverify_" + controlname.name;
    const inputcontrolname = controlname.name;
    const crosscontrol = document.querySelectorAll(`#${inputcontrolname}`);
    if (crosscontrol.length > 0) {
      crosscontrol[0].className = inputverifyclassName;
      crosscontrol[0].disabled = !inputverifyenabled;
      crosscontrol[0].isDisabled = !inputverifyenabled;
    }
    //save state
    let value = "verified";
    handlePersonalChange_acceptsave(controlname, value);
  };
  const handlecrossbutton = (
    e,
    controlname,
    tickbuttonname,
    crossbuttonname,
    tickverifyiconname,
    crossverifyiconname,
    tickaccepticonname,
    crossaccepticonname
  ) => {
    if (
      controlname?.controltype === "isMain" ||
      controlname?.controltype === "dropdown" ||
      controlname?.controltype === "phone"
    ) {
      if (controlname.status === "verified") {
        var value = "not verified";
        handlePersonalChange_acceptsave(controlname, value);
      } else {
        var diveditcontrolname = "div_edit_" + controlname.name;
        var divviewcontrolname = "div_view_" + controlname.name;
        const diveditcontrol = document.querySelectorAll(
          `#${diveditcontrolname}`
        );
        const divviewcontrol = document.querySelectorAll(
          `#${divviewcontrolname}`
        );
        if (diveditcontrol != undefined && diveditcontrol.length > 0) {
          diveditcontrol[0].className =
            diveditcontrol[0].className === "col" ? "col d-none" : "col";
        }

        if (divviewcontrol != undefined && divviewcontrol.length > 0) {
          divviewcontrol[0].className =
            divviewcontrol[0].className === "col" ? "col d-none" : "col";
        }

        return;
      }
    }

    //icon change fa  fa-times fa  fa-undo
    const crossiconcontrol = document.querySelectorAll(
      `#${crossverifyiconname}`
    );

    if (controlname.status === "verified") {
      if (
        controlname.value === controlname.newvalue &&
        controlname.state === "current"
      )
        return;
      var value = "notverified";
      handlePersonalChange_acceptsave(controlname, value);
    } else {
      const inputcontrolname = controlname.name;

      const crosscontrol = document.querySelectorAll(`#${inputcontrolname}`);
      if (crosscontrol.length > 0) {
        crosscontrol[0].className = inputnotacceptclassName;
        crosscontrol[0].disabled = inputverifyenabled;
        crosscontrol[0].isDisabled = inputverifyenabled;
      }
    }
    // if (crossiconcontrol[0].className === "fa  fa-times") {
    //   // const divacceptcontrolname = "divaccept_" + controlname.name;
    //   // const divverifycontrolname = "divverify_" + controlname.name;

    // }
    /* else {
        const divacceptcontrolname = "divaccept_" + controlname.name;
        const divverifycontrolname = "divverify_" + controlname.name;
        const inputcontrolname = controlname.name;
       
        const crosscontrol = document.querySelectorAll(`#${inputcontrolname}`);
        if (crosscontrol.length > 0) {
          crosscontrol[0].className = inputverifyclassName;
          crosscontrol[0].disabled = !inputverifyenabled;
          crosscontrol[0].isDisabled = !inputverifyenabled;
        }

        crossiconcontrol[0].className = "fa  fa-times";
      } */
  };
  function VerifyUserActions(controlname) {
    const divbuttonname = "divverify_" + controlname.name;
    const tickbuttonname = "tick_" + controlname.name;
    const crossbuttonname = "cross_" + controlname.name;
    const tickverifyiconname = "tick_verifyicon_" + controlname.name;
    const crossverifyiconname = "cross_verifyicon_" + controlname.name;
    const tickaccepticonname = "tick_accepticon_" + controlname.name;
    const crossaccepticonname = "cross_accepticon_" + controlname.name;
    if (controlname.status === "verified") {
      return (
        <div
          name={divbuttonname}
          id={divbuttonname}
          className="d-flex gap-4 justify-content-end d-none"
        >
          <button
            onClick={(e) => {
              handletickbutton(
                e,
                controlname,
                tickbuttonname,
                crossbuttonname,
                tickverifyiconname,
                crossverifyiconname,
                tickaccepticonname,
                crossaccepticonname
              );
            }}
            name={tickbuttonname}
            id={tickbuttonname}
            className="cmbtn cmbtn-circle cmbtn-outline-success"
          >
            <i id={tickverifyiconname} className={tickbuttonicon}></i>
          </button>
          <button
            name={crossbuttonname}
            id={crossbuttonname}
            onClick={(e) => {
              handlecrossbutton(
                e,
                controlname,
                tickbuttonname,
                crossbuttonname,
                tickverifyiconname,
                crossverifyiconname,
                tickaccepticonname,
                crossaccepticonname
              );
            }}
            className="cmbtn cmbtn-circle cmbtn-outline-danger"
          >
            <i id={crossverifyiconname} className="fa  fa-times"></i>
          </button>
        </div>
      );
    } else {
      return (
        <div
          name={divbuttonname}
          id={divbuttonname}
          className={
            controlname.value === controlname.newvalue &&
            controlname.state === "current"
              ? "d-flex gap-4 justify-content-end d-none 23"
              : "d-flex gap-4 justify-content-end 12"
          }
        >
          <button
            onClick={(e) => {
              handletickbutton(
                e,
                controlname,
                tickbuttonname,
                crossbuttonname,
                tickverifyiconname,
                crossverifyiconname,
                tickaccepticonname,
                crossaccepticonname
              );
            }}
            name={tickbuttonname}
            id={tickbuttonname}
            className="cmbtn cmbtn-circle cmbtn-outline-success"
          >
            <i id={tickverifyiconname} className={tickbuttonicon}></i>
          </button>
          <button
            name={crossbuttonname}
            id={crossbuttonname}
            onClick={(e) => {
              handlecrossbutton(
                e,
                controlname,
                tickbuttonname,
                crossbuttonname,
                tickverifyiconname,
                crossverifyiconname,
                tickaccepticonname,
                crossaccepticonname
              );
            }}
            className="cmbtn cmbtn-circle cmbtn-outline-danger"
          >
            <i id={crossverifyiconname} className={crossbuttonicon}></i>
          </button>
        </div>
      );
    }
  }
  function AcceptUserActions(controlname) {
    const divbuttonname = "divaccept_" + controlname.name;
    const tickbuttonname = "tick_" + controlname.name;
    const crossbuttonname = "cross_" + controlname.name;
    const tickverifyiconname = "tick_verifyicon_" + controlname.name;
    const crossverifyiconname = "cross_verifyicon_" + controlname.name;
    const tickaccepticonname = "tick_accepticon_" + controlname.name;
    const crossaccepticonname = "cross_accepticon_" + controlname.name;
    if (controlname.status === "verified") {
      if (
        controlname?.controltype === "dropdown" ||
        controlname?.controltype === "isMain" ||
        controlname?.controltype === "phone"
      ) {
        var diveditcontrolname = "div_edit_" + controlname.name;
        var divviewcontrolname = "div_view_" + controlname.name;
        const diveditcontrol = document.querySelectorAll(
          `#${diveditcontrolname}`
        );
        const divviewcontrol = document.querySelectorAll(
          `#${divviewcontrolname}`
        );
        if (diveditcontrol != undefined && diveditcontrol.length > 0) {
          diveditcontrol[0].className = "col d-none";
        }

        if (divviewcontrol != undefined && divviewcontrol.length > 0) {
          divviewcontrol[0].className = "col";
        }
      }
      return (
        <div
          name={divbuttonname}
          id={divbuttonname}
          className="d-flex gap-4 justify-content-end"
        >
          <div class="dropdown w-10">
            <button
              class="cmbtn cmbtn-circle cmbtn-success "
              type="button"
              id="dropdownMenuButton1"
              // data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={(e) => {
                handlecrossbutton(
                  e,
                  controlname,
                  tickbuttonname,
                  crossbuttonname,
                  tickverifyiconname,
                  crossverifyiconname,
                  tickaccepticonname,
                  crossaccepticonname
                );
              }}
            >
              <i id={tickaccepticonname} className={tickbuttonicon}></i>
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div
          name={divbuttonname}
          id={divbuttonname}
          className="d-flex gap-4 justify-content-end d-none"
        >
          <div class="dropdown w-10">
            <button
              class="cmbtn cmbtn-circle cmbtn-success dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i id={tickaccepticonname} className={tickbuttonicon}></i>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <a class="dropdown-item" href="#">
                  <button
                    name={crossbuttonname}
                    id={crossbuttonname}
                    onClick={(e) => {
                      handlecrossbutton(
                        e,
                        controlname,
                        tickbuttonname,
                        crossbuttonname,
                        tickverifyiconname,
                        crossverifyiconname,
                        tickaccepticonname,
                        crossaccepticonname
                      );
                    }}
                    className="cmbtn cmbtn-circle cmbtn-outline-danger"
                  >
                    <i id={crossaccepticonname} className={crossbuttonicon}></i>
                  </button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      );
    }
  }

  function UnVerifiedStatus(values) {
    //console.log("UnVerifiedStatus2 value", values?.status);
    if (
      values.status?.value === values.status?.newvalue &&
      values.status?.status !== "verified" &&
      values.status?.state === "current"
    ) {
      return (
        <span name={values.name} className="ms-2 ">
          <i class="fa fa-question-circle fa-1x" aria-hidden="true"></i>{" "}
        </span>
      );
    } else {
      //      console.log(values);
      return <span name={values.name}></span>;
    }
  }
  return (
    <div className="container p-1">
      <div className="row">
        <h3 align="center">Pending verification</h3>
      </div>
      <div className="row">
        <div className="accordion mt-2" id="accordionPanelscontact">
          <div className="accordion-item p-0">
            <div
              className="accordion-header p-2"
              style={{
                "background-color": "#f2f2f2",
              }}
            >
              {" "}
              Personal contact info
            </div>
            <div>
              <div className="accordion-body">
                <div className="">
                  <div className="row ">
                    {(contact?.firstname?.value !==
                      contact?.firstname?.newvalue ||
                      contact?.firstname?.status !== "notverified") && (
                      <div className="col-sm-12 col-md-6 col-lg-6 ">
                        <div className="form-group">
                          <label className="form-label ">
                            First name <label className="text-danger">*</label>
                            <UnVerifiedStatus
                              status={contact?.firstname}
                              name="firstname_unv"
                              value={contact?.firstname?.value}
                              newvalue={contact?.firstname?.newvalue}
                              state={contact?.firstname?.state}
                            />
                          </label>
                          <div className="row">
                            {(contact?.firstname?.value !==
                              contact?.firstname?.newvalue ||
                              contact?.firstname?.state === "modified") && (
                              <div className="col-4 ">
                                <input
                                  type="text"
                                  name="firstname_old"
                                  id="firstname_old"
                                  disabled={true}
                                  className={inputverifyclassName}
                                  value={contact?.firstname?.value}
                                />
                              </div>
                            )}

                            <div className="col">
                              <input
                                type="text"
                                name="firstname"
                                id="firstname"
                                disabled={inputverifydisabled}
                                className={inputverifyclassName}
                                value={contact?.firstname?.newvalue}
                                onChange={(e) => handlePersonalChange(e)}
                              />
                            </div>
                            <div className="col-3 p-0">
                              <VerifyUserActions
                                name="firstname"
                                status={contact?.firstname?.status}
                                value={contact?.firstname?.value}
                                newvalue={contact?.firstname?.newvalue}
                                controltype="firstname"
                                state={contact?.firstname?.state}
                              />
                              <AcceptUserActions
                                name="firstname"
                                controltype="firstname"
                                status={contact?.firstname?.status}
                                value={contact?.firstname?.value}
                                newvalue={contact?.firstname?.newvalue}
                                state={contact?.firstname?.state}
                              />
                            </div>
                            {contactErrors.firstname?.valuelength > 0 && (
                              <div className="text-danger">
                                {contactErrors.firstname?.newvalue}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {(contact?.middlename?.value !==
                      contact?.middlename?.newvalue ||
                      contact?.middlename?.status !== "notverified") && (
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label className="form-label">
                            Middle name
                            <UnVerifiedStatus
                              status={contact?.middlename}
                              name="middlename_unv"
                              value={contact?.middlename?.value}
                              newvalue={contact?.middlename?.newvalue}
                              state={contact?.middlename?.state}
                            />
                          </label>

                          <div className="row">
                            {(contact?.middlename?.value !==
                              contact?.middlename?.newvalue ||
                              contact?.middlename?.state === "modified") && (
                              <div className="col-4">
                                <input
                                  type="text"
                                  name="middlename_old"
                                  id="middlename_old"
                                  disabled={true}
                                  className={inputverifyclassName}
                                  value={contact?.middlename?.value}
                                />
                              </div>
                            )}

                            <div className="col">
                              <input
                                type="text"
                                name="middlename"
                                id="middlename"
                                disabled={inputverifydisabled}
                                className={inputverifyclassName}
                                value={contact?.middlename?.newvalue}
                                onChange={(e) => handlePersonalChange(e)}
                              />
                            </div>

                            <div className="col-3 p-0">
                              <VerifyUserActions
                                name="middlename"
                                controltype="middlename"
                                status={contact?.middlename?.status}
                                value={contact?.middlename?.value}
                                newvalue={contact?.middlename?.newvalue}
                                state={contact?.middlename?.state}
                              />

                              <AcceptUserActions
                                name="middlename"
                                controltype="middlename"
                                status={contact?.middlename?.status}
                                value={contact?.middlename?.value}
                                newvalue={contact?.middlename?.newvalue}
                                state={contact?.middlename?.state}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="row ">
                    {(contact?.lastname?.value !==
                      contact?.lastname?.newvalue ||
                      contact?.lastname?.status !== "notverified") && (
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label className="form-label">
                            Last name
                            <label className="text-danger">*</label>
                            <UnVerifiedStatus
                              status={contact?.lastname}
                              name="lastname_unv"
                              value={contact?.lastname?.value}
                              newvalue={contact?.lastname?.newvalue}
                              state={contact?.lastname?.state}
                            />
                          </label>
                          <div className="row">
                            {(contact?.lastname?.value !==
                              contact?.lastname?.newvalue ||
                              contact?.lastname?.state === "modified") && (
                              <div className="col-4">
                                <input
                                  type="text"
                                  name="lastname_old"
                                  id="lastname_old"
                                  disabled={true}
                                  className={inputverifyclassName}
                                  value={contact?.lastname?.value}
                                />
                              </div>
                            )}

                            <div name="lastname_div" className="col">
                              <input
                                type="text"
                                name="lastname"
                                id="lastname"
                                disabled={inputverifydisabled}
                                className={inputverifyclassName}
                                value={contact?.lastname?.newvalue}
                                onChange={(e) => handlePersonalChange(e)}
                              />
                            </div>
                            <div className="col-3 p-0">
                              <div>
                                <VerifyUserActions
                                  name="lastname"
                                  controltype="lastname"
                                  status={contact?.lastname?.status}
                                  value={contact?.lastname?.value}
                                  newvalue={contact?.lastname?.newvalue}
                                  state={contact?.lastname?.state}
                                />
                                <AcceptUserActions
                                  name="lastname"
                                  controltype="lastname"
                                  status={contact?.lastname?.status}
                                  value={contact?.lastname?.value}
                                  newvalue={contact?.lastname?.newvalue}
                                  state={contact?.lastname?.state}
                                />
                              </div>
                            </div>

                            {contactErrors.lastname?.newvalue.length > 0 && (
                              <div className="text-danger">
                                {contactErrors.lastname?.newvalue}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {/* <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="form-label ">
                          Salutation
                          <UnVerifiedStatus
                            status={contact?.salutation}
                            name="salutation_unv"
                            value={contact?.salutation?.value}
                            newvalue={contact?.salutation?.newvalue}
                            state={contact?.salutation?.state}
                          />
                        </label>
                        <div className="row">
                          {(contact?.salutation?.value !==
                            contact?.salutation?.newvalue ||
                            contact?.salutation?.state === "modified") && (
                            <div className="col-4">
                              <Select
                                isSearchable={true}
                                type="text"
                                name="salutation_old"
                                id="salutation_old"
                                className={inputverifyclassName}
                                styles={customstyles}
                                value={salutationOptions.filter(
                                  (record) =>
                                    record.value === contact?.salutation?.value
                                )}
                                options={salutationOptions}
                                isDisabled={true}
                              />
                            </div>
                          )}

                          <div className="col" id="div_view_salutation">
                            <Select
                              isSearchable={true}
                              type="text"
                              name="salutation"
                              id="salutation"
                              isDisabled={true}
                              className={inputverifyclassName}
                              styles={customstyles}
                              value={salutationOptions.filter(
                                (record) =>
                                  record.value === contact?.salutation?.newvalue
                              )}
                              onChange={(val) => {
                                handleDropDownChange(
                                  {
                                    target: {
                                      name: "salutation",
                                      value: val.value,
                                    },
                                  },
                                  "contact"
                                );
                              }}
                              options={salutationOptions}
                            />
                          </div>
                          <div className="col d-none" id="div_edit_salutation">
                            <Select
                              isSearchable={true}
                              type="text"
                              name="salutation"
                              id="salutation"
                              className={inputverifyclassName}
                              styles={customstyles}
                              value={salutationOptions.filter(
                                (record) =>
                                  record.value === contact?.salutation?.newvalue
                              )}
                              onChange={(val) => {
                                handleDropDownChange(
                                  {
                                    target: {
                                      name: "salutation",
                                      value: val.value,
                                    },
                                  },
                                  "contact"
                                );
                              }}
                              options={salutationOptions}
                            />
                          </div>
                          <div className="col-3 p-0">
                            <VerifyUserActions
                              name="salutation"
                              status={contact?.salutation?.status}
                              value={contact?.salutation?.value}
                              newvalue={contact?.salutation?.newvalue}
                              controltype="dropdown"
                              state={contact?.salutation?.state}
                            />
                            <AcceptUserActions
                              name="salutation"
                              status={contact?.salutation?.status}
                              value={contact?.salutation?.value}
                              newvalue={contact?.salutation?.newvalue}
                              controltype="dropdown"
                              state={contact?.salutation?.state}
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="row ">
                    {(contact?.email?.value !== contact?.email?.newvalue ||
                      contact?.email?.status !== "notverified") && (
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label className="form-label">
                            Main email <label className="text-danger ">*</label>
                            <UnVerifiedStatus
                              status={contact?.email}
                              name="email_unv"
                              value={contact?.email?.value}
                              newvalue={contact?.email?.newvalue}
                              state={contact?.email?.state}
                            />
                          </label>
                          <div className="row">
                            {(contact?.email?.value !==
                              contact?.email?.newvalue ||
                              contact?.email?.state === "modified") && (
                              <div className="col-4">
                                <input
                                  type="text"
                                  name="email_old"
                                  id="email_old"
                                  disabled={true}
                                  className={inputverifyclassName}
                                  value={contact?.email?.value}
                                />
                              </div>
                            )}

                            <div className="col">
                              <input
                                type="text"
                                name="email"
                                id="email"
                                disabled={inputverifydisabled}
                                className={inputverifyclassName}
                                value={contact?.email?.newvalue}
                                onChange={handlePersonalChange}
                              />
                            </div>
                            <div className="col-3 p-0">
                              <VerifyUserActions
                                name="email"
                                status={contact?.email?.status}
                                value={contact?.email?.value}
                                newvalue={contact?.email?.newvalue}
                                state={contact?.email?.state}
                              />
                              <AcceptUserActions
                                name="email"
                                status={contact?.email?.status}
                                value={contact?.email?.value}
                                newvalue={contact?.email?.newvalue}
                                state={contact?.email?.state}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {(contact?.phone?.value !== contact?.phone?.newvalue ||
                      contact?.phone?.status !== "notverified") && (
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label className="form-label">
                            Main phone
                            <UnVerifiedStatus
                              status={contact?.phone}
                              name="phone_unv"
                              value={contact?.phone?.value}
                              newvalue={contact?.phone?.newvalue}
                              state={contact?.phone?.state}
                            />
                          </label>
                          <div className="row">
                            {(contact?.phone?.value !==
                              contact?.phone?.newvalue ||
                              contact?.phone?.state === "modified") && (
                              <div className="col-4">
                                <PhoneInput
                                  inputStyle={{ width: "100%", border: 0 }}
                                  country={"us"}
                                  value={contact?.phone?.value}
                                  name="phone_old"
                                  id="phone_old"
                                  disabled={true}
                                />
                              </div>
                            )}

                            <div className="col" id="div_view_phone">
                              <PhoneInput
                                inputStyle={{ width: "100%", border: 0 }}
                                country={"us"}
                                value={contact?.phone?.newvalue}
                                name="phone"
                                id="phone"
                                disabled={true}
                                onChange={(val) => {
                                  handlecontactPhoneChange(
                                    {
                                      target: {
                                        name: "phone",
                                        value: val,
                                      },
                                    },
                                    "phone"
                                  );
                                }}
                              />
                            </div>
                            <div className="col d-none" id="div_edit_phone">
                              <PhoneInput
                                inputStyle={{ width: "100%", border: 0 }}
                                country={"us"}
                                value={contact?.phone?.newvalue}
                                name="phone"
                                id="phone"
                                onChange={(val) => {
                                  handlecontactPhoneChange(
                                    {
                                      target: {
                                        name: "phone",
                                        value: val,
                                      },
                                    },
                                    "phone"
                                  );
                                }}
                              />
                            </div>
                            <div className="col-3 p-0">
                              <VerifyUserActions
                                name="phone"
                                status={contact?.phone?.status}
                                value={contact?.phone?.value}
                                newvalue={contact?.phone?.newvalue}
                                controltype="phone"
                                state={contact?.phone?.state}
                              />
                              <AcceptUserActions
                                name="phone"
                                status={contact?.phone?.status}
                                value={contact?.phone?.value}
                                newvalue={contact?.phone?.newvalue}
                                controltype="phone"
                                state={contact?.phone?.state}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <hr className="mt-1"></hr>
                  <div className="row p-1">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="form-label">
                          Nickname
                          <UnVerifiedStatus
                            status={contact?.nickName}
                            name="nickName_unv"
                            value={contact?.nickName?.value}
                            newvalue={contact?.nickName?.newvalue}
                            state={contact?.nickName?.state}
                          />
                        </label>
                        <div className="row">
                          {(contact?.nickName?.value !==
                            contact?.nickName?.newvalue ||
                            contact?.nickName?.state === "modified") && (
                            <div className="col-4">
                              <input
                                type="text"
                                name="nickName_old"
                                id="nickName_old"
                                value={contact?.nickName?.value}
                                disabled={true}
                                className={inputverifyclassName}
                              />
                            </div>
                          )}

                          <div className="col">
                            <input
                              type="text"
                              name="nickName"
                              id="nickName"
                              value={contact?.nickName?.newvalue}
                              onChange={handlePersonalChange}
                              disabled={inputverifydisabled}
                              className={inputverifyclassName}
                            />
                          </div>
                          <div className="col-3 p-0">
                            <VerifyUserActions
                              name="nickName"
                              status={contact?.nickName?.status}
                              value={contact?.nickName?.value}
                              newvalue={contact?.nickName?.newvalue}
                              state={contact?.nickName?.state}
                            />
                            <AcceptUserActions
                              name="nickName"
                              status={contact?.nickName?.status}
                              value={contact?.nickName?.value}
                              newvalue={contact?.nickName?.newvalue}
                              state={contact?.nickName?.state}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="form-label">
                          Gender
                          <UnVerifiedStatus
                            status={contact?.gender}
                            name="gender_unv"
                            value={contact?.gender?.value}
                            newvalue={contact?.gender?.newvalue}
                            state={contact?.gender?.state}
                          />
                        </label>
                        <div className="row">
                          {(contact?.gender?.value !==
                            contact?.gender?.newvalue ||
                            contact?.gender?.state === "modified") && (
                            <div className="col-4">
                              <Select
                                isSearchable={true}
                                type="text"
                                name="gender_old"
                                id="gender_old"
                                styles={customstyles}
                                value={genderOptions.filter(
                                  (client) =>
                                    client.value === contact?.gender?.value
                                )}
                                options={genderOptions}
                                isDisabled={true}
                                className={inputverifyclassName}
                              />
                            </div>
                          )}

                          <div className="col" id="div_view_gender">
                            <Select
                              isSearchable={true}
                              type="text"
                              name="gender"
                              id="gender"
                              isDisabled={true}
                              styles={customstyles}
                              onChange={(val) => {
                                handleDropDownChange(
                                  {
                                    target: {
                                      name: "gender",
                                      value: val.value,
                                    },
                                  },
                                  "contact"
                                );
                              }}
                              value={genderOptions.filter(
                                (client) =>
                                  client.value === contact?.gender?.newvalue
                              )}
                              options={genderOptions}
                            />
                          </div>
                          <div className="col d-none" id="div_edit_gender">
                            <Select
                              isSearchable={true}
                              type="text"
                              name="gender"
                              id="gender"
                              styles={customstyles}
                              onChange={(val) => {
                                handleDropDownChange(
                                  {
                                    target: {
                                      name: "gender",
                                      value: val.value,
                                    },
                                  },
                                  "contact"
                                );
                              }}
                              value={genderOptions.filter(
                                (client) =>
                                  client.value === contact?.gender?.newvalue
                              )}
                              options={genderOptions}
                            />
                          </div>
                          <div className="col-3 p-0">
                            <VerifyUserActions
                              name="gender"
                              status={contact?.gender?.status}
                              value={contact?.gender?.value}
                              newvalue={contact?.gender?.newvalue}
                              controltype="dropdown"
                              state={contact?.gender?.state}
                            />
                            <AcceptUserActions
                              name="gender"
                              status={contact?.gender?.status}
                              value={contact?.gender?.value}
                              newvalue={contact?.gender?.newvalue}
                              controltype="dropdown"
                              state={contact?.gender?.state}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row p-1">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="form-label">
                          Date of birth
                          <UnVerifiedStatus
                            status={contact?.dateOfBirth}
                            name="dateOfBirth_unv"
                            value={contact?.dateOfBirth?.value}
                            newvalue={contact?.dateOfBirth?.newvalue}
                            state={contact?.dateOfBirth?.state}
                          />
                        </label>
                        <div className="row">
                          {(contact?.dateOfBirth?.value !==
                            contact?.dateOfBirth?.newvalue ||
                            contact?.dateOfBirth?.state === "modified") && (
                            <div className="col-4">
                              <input
                                type="date"
                                name="dateOfBirth_old"
                                id="dateOfBirth_old"
                                value={contact?.dateOfBirth?.value}
                                disabled={true}
                                className={inputverifyclassName}
                              />
                            </div>
                          )}

                          <div className="col">
                            <input
                              type="date"
                              name="dateOfBirth"
                              id="dateOfBirth"
                              value={contact?.dateOfBirth?.newvalue}
                              onChange={handlePersonalChange}
                              disabled={inputverifydisabled}
                              className={inputverifyclassName}
                            />
                          </div>
                          <div className="col-3 p-0">
                            <VerifyUserActions
                              name="dateOfBirth"
                              status={contact?.dateOfBirth?.status}
                              value={contact?.dateOfBirth?.value}
                              newvalue={contact?.dateOfBirth?.newvalue}
                              state={contact?.dateOfBirth?.state}
                            />
                            <AcceptUserActions
                              name="dateOfBirth"
                              status={contact?.dateOfBirth?.status}
                              value={contact?.dateOfBirth?.value}
                              newvalue={contact?.dateOfBirth?.newvalue}
                              state={contact?.dateOfBirth?.state}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="form-label">
                          Description
                          <UnVerifiedStatus
                            status={contact?.description}
                            name="description_unv"
                            value={contact?.description?.value}
                            newvalue={contact?.description?.newvalue}
                            state={contact?.description?.state}
                          />
                        </label>
                        <div className="row">
                          {(contact?.description?.value !==
                            contact?.description?.newvalue ||
                            contact?.description?.state === "modified") && (
                            <div className="col-4">
                              <input
                                type="text"
                                name="description_old"
                                id="description_old"
                                value={contact?.description?.value}
                                disabled={true}
                                className={inputverifyclassName}
                              />
                            </div>
                          )}

                          <div className="col">
                            <input
                              type="text"
                              name="description"
                              id="description"
                              value={contact?.description?.newvalue}
                              onChange={handlePersonalChange}
                              disabled={inputverifydisabled}
                              className={inputverifyclassName}
                            />
                          </div>
                          <div className="col-3 p-0">
                            <VerifyUserActions
                              name="description"
                              status={contact?.description?.status}
                              value={contact?.description?.value}
                              newvalue={contact?.description?.newvalue}
                              state={contact?.description?.state}
                            />
                            <AcceptUserActions
                              name="description"
                              status={contact?.description?.status}
                              value={contact?.description?.value}
                              newvalue={contact?.description?.newvalue}
                              state={contact?.description?.state}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row p-1">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="form-label">
                          Contact role
                          <UnVerifiedStatus
                            status={contact?.contactRole}
                            name="contactRole_unv"
                            value={contact?.contactRole?.value}
                            newvalue={contact?.contactRole?.newvalue}
                            state={contact?.contactRole?.state}
                          />
                        </label>
                        <div className="row">
                          {(contact?.contactRole?.value !==
                            contact?.contactRole?.newvalue ||
                            contact?.contactRole?.state === "modified") && (
                            <div className="col-4">
                              <input
                                type="text"
                                name="contactRole_old"
                                id="contactRole_old"
                                value={contact?.contactRole?.value}
                                //onChange={handlePersonalChange}
                                disabled={true}
                                className={inputverifyclassName}
                              />
                            </div>
                          )}

                          <div className="col">
                            <input
                              type="text"
                              name="contactRole"
                              id="contactRole"
                              value={contact?.contactRole?.newvalue}
                              onChange={handlePersonalChange}
                              disabled={inputverifydisabled}
                              className={inputverifyclassName}
                            />
                          </div>
                          <div className="col-3 p-0">
                            <VerifyUserActions
                              name="contactRole"
                              status={contact?.contactRole?.status}
                              value={contact?.contactRole?.value}
                              newvalue={contact?.contactRole?.newvalue}
                              state={contact?.contactRole?.state}
                            />
                            <AcceptUserActions
                              name="contactRole"
                              status={contact?.contactRole?.status}
                              value={contact?.contactRole?.value}
                              newvalue={contact?.contactRole?.newvalue}
                              state={contact?.contactRole?.state}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>*/}
                </div>
                {/* personal email info */}
                {EmailArray.length > 0 && (
                  <div className="row ">
                    {EmailArray.length > 0 &&
                      EmailArray.map((row, index) => (
                        <div>
                          {(row?.email?.value !== row?.email?.newvalue ||
                            row?.email?.status !== "notverified") && (
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <div className="form-group">
                                <label className="form-label form-label">
                                  Other email
                                  <UnVerifiedStatus
                                    status={row?.email}
                                    name={"emailArray_email_unv_" + index}
                                    value={row?.email?.value}
                                    newvalue={row?.email?.newvalue}
                                    state={row?.email?.state}
                                  />
                                </label>
                                <div className="row">
                                  {(row?.email?.value !==
                                    row?.email?.newvalue ||
                                    row?.email?.state === "modified") && (
                                      <div className="col-4">
                                        <input
                                          type="text"
                                          name={"emailArray_email_old" + index}
                                          id={"emailArray_email_old" + index}
                                          value={row?.email?.value}
                                          disabled={true}
                                          className={inputverifyclassName}
                                        />
                                      </div>
                                    )}

                                  <div className="col">
                                    <input
                                      type="text"
                                      name={"emailArray_email_" + index}
                                      id={"emailArray_email_" + index}
                                      disabled={inputverifydisabled}
                                      className={inputverifyclassName}
                                      value={row?.email?.newvalue}
                                      onChange={(e) =>
                                        handleChangePersonalEmail(
                                          e,
                                          row?.id,
                                          index
                                        )
                                      }
                                    />
                                  </div>
                                    <div className="col-3">
                                      <VerifyUserActions
                                        name={"emailArray_email_" + index}
                                        status={row?.email?.status}
                                        index={index}
                                        itemid={row?.id}
                                        value={row?.email?.value}
                                        newvalue={row?.email?.newvalue}
                                        state={row?.email?.state}
                                      />
                                      <AcceptUserActions
                                        name={"emailArray_email_" + index}
                                        status={row?.email?.status}
                                        index={index}
                                        itemid={row?.id}
                                        value={row?.email?.value}
                                        newvalue={row?.email?.newvalue}
                                        state={row?.email?.state}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          {/* <div className="row mb-2">
                              <div className="form-group">
                                <label className="form-label form-label">
                                  Label
                                  <UnVerifiedStatus
                                    status={row?.label}
                                    name={"emailArray_label_unv_" + index}
                                    value={row?.label?.value}
                                    newvalue={row?.label?.newvalue}
                                    state={row?.label?.state}
                                  />
                                </label>
                                <div className="row">
                                  {row?.label?.value !==
                                    row?.label?.newvalue && (
                                    <div className="col-4">
                                      <input
                                        type="text"
                                        name={"emailArray_label_old_" + index}
                                        id={"emailArray_label_old_" + index}
                                        disabled={true}
                                        className={inputverifyclassName}
                                        value={row?.label?.value}
                                      />
                                    </div>
                                  )}
                                  <div className="col">
                                    <input
                                      type="text"
                                      name={"emailArray_label_" + index}
                                      id={"emailArray_label_" + index}
                                      className={inputverifyclassName}
                                      value={row?.label?.newvalue}
                                      onChange={(e) =>
                                        handleChangePersonalEmail(
                                          e,
                                          row?.id,
                                          index
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="col-3 p-0">
                                    <VerifyUserActions
                                      name={"emailArray_label_" + index}
                                      status={row?.label?.status}
                                      index={index}
                                      itemid={row?.id}
                                      value={row?.label?.value}
                                      newvalue={row?.label?.newvalue}
                                      state={row?.label?.state}
                                    />
                                    <AcceptUserActions
                                      name={"emailArray_label_" + index}
                                      status={row?.label?.status}
                                      index={index}
                                      itemid={row?.id}
                                      value={row?.label?.value}
                                      newvalue={row?.label?.newvalue}
                                      state={row?.label?.state}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mb-2">
                              <div className="form-group">
                                <label className="form-label form-label ms-1">
                                  Ismain?
                                  <UnVerifiedStatus
                                    status={row?.isMain}
                                    name={"emailArray_isMain_unv_" + index}
                                    value={row?.isMain?.value}
                                    newvalue={row?.isMain?.newvalue}
                                  />
                                </label>
                                <div className="row">
                                  {(row?.isMain?.value !==
                                    row?.isMain?.newvalue ||
                                    row.isMain.state === "modified") && (
                                    <div className="col-4">
                                      <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                        <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                        {row?.isMain?.value === 1
                                          ? "Yes"
                                          : "No"}
                                      </span>
                                    </div>
                                  )}
                                  <div
                                    className="col"
                                    id={"div_view_emailArray_isMain_" + index}
                                  >
                                    <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                      <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                      {row?.isMain?.newvalue === 1
                                        ? "Yes"
                                        : "No"}
                                    </span>
                                  </div>
                                  <div
                                    className="col d-none"
                                    id={"div_edit_emailArray_isMain_" + index}
                                  >
                                    <input
                                      type="checkbox"
                                      name={"emailArray_isMain_" + index}
                                      id={"emailArray_isMainl_" + index}
                                      className="form-check-input ms-3"
                                      defaultChecked={false}
                                      onChange={(e) =>
                                        handleChangePersonalEmail(
                                          e,
                                          row.id,
                                          index,
                                          "isMain"
                                        )
                                      }
                                      checked={
                                        row.isMain?.newvalue === 1
                                          ? true
                                          : false
                                      }
                                    />
                                    <label className="form-label form-label ms-1">
                                      Yes/No
                                    </label>
                                  </div>
                                  <div className="col-3 p-0">
                                    <VerifyUserActions
                                      name={"emailArray_isMain_" + index}
                                      status={row?.isMain?.status}
                                      index={index}
                                      itemid={row?.id}
                                      value={row?.isMain?.value}
                                      newvalue={row?.isMain?.newvalue}
                                      controltype={"isMain"}
                                      state={row?.isMain?.state}
                                    />
                                    <AcceptUserActions
                                      name={"emailArray_isMain_" + index}
                                      status={row?.isMain?.status}
                                      index={index}
                                      itemid={row?.id}
                                      value={row?.isMain?.value}
                                      newvalue={row?.isMain?.newvalue}
                                      controltype={"isMain"}
                                      state={row?.isMain?.state}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr></hr> */}
                        </div>
                      ))}
                  </div>
                )}
                {/* personal address info */}
                {AddressArray.length > 0 && (
                  <div className="row mt-3">
                    {AddressArray.length > 0 &&
                      AddressArray.map((row, index) => (
                        <div>
                          {/* <div className="row mb-3">
                              <div className="form-group">
                                <label className="form-label">
                                  Address label
                                  <UnVerifiedStatus
                                    status={row?.addressLabel}
                                    name={
                                      "addressArray_addressLabel_unv_" + index
                                    }
                                    value={row?.addressLabel?.value}
                                    newvalue={row?.addressLabel?.newvalue}
                                    state={row?.addressLabel?.state}
                                  />
                                </label>
                                <div className="row">
                                  {(row?.addressLabel?.value !==
                                    row?.addressLabel?.newvalue ||
                                    row.addressLabel.state === "modified") && (
                                    <div className="col-4">
                                      <Select
                                        isSearchable={true}
                                        type="text"
                                        name={
                                          "addressArray_addressLabel_old_" +
                                          index
                                        }
                                        id={
                                          "addressArray_addressLabel_old_" +
                                          index
                                        }
                                        isDisabled={true}
                                        className={inputverifyclassName}
                                        styles={customstyles}
                                        value={addressOptions.filter(
                                          (client) =>
                                            client.value ===
                                            row?.addressLabel?.value
                                        )}
                                        options={addressOptions}
                                      />
                                    </div>
                                  )}

                                  <div
                                    className="col"
                                    id={
                                      "div_view_addressArray_addressLabel_" +
                                      index
                                    }
                                  >
                                    <Select
                                      isSearchable={true}
                                      type="text"
                                      name={
                                        "addressArray_addressLabel_" + index
                                      }
                                      id={"addressArray_addressLabel_" + index}
                                      isDisabled={true}
                                      className={inputverifyclassName}
                                      styles={customstyles}
                                      value={addressOptions.filter(
                                        (client) =>
                                          client.value ===
                                          row?.addressLabel?.newvalue
                                      )}
                                      options={addressOptions}
                                    />
                                  </div>
                                  <div
                                    className="col d-none"
                                    id={
                                      "div_edit_addressArray_addressLabel_" +
                                      index
                                    }
                                  >
                                    <Select
                                      isSearchable={true}
                                      type="text"
                                      name={
                                        "addressArray_addressLabel_" + index
                                      }
                                      id={"addressArray_addressLabel_" + index}
                                      className={inputverifyclassName}
                                      styles={customstyles}
                                      value={addressOptions.filter(
                                        (client) =>
                                          client.value ===
                                          row?.addressLabel?.newvalue
                                      )}
                                      onChange={(e) => {
                                        handleChangePersonalAddress(
                                          {
                                            target: {
                                              name:
                                                "addressArray_addressLabel_" +
                                                index,
                                              value: e.value,
                                              newvalue: e.newvalue,
                                              index: index,
                                            },
                                          },
                                          row.id,
                                          index,
                                          "addresslabel"
                                        );
                                      }}
                                      options={addressOptions}
                                    />
                                  </div>
                                  <div className="col-3 p-0">
                                    <VerifyUserActions
                                      name={
                                        "addressArray_addressLabel_" + index
                                      }
                                      value={row?.addressLabel?.value}
                                      newvalue={row?.addressLabel?.newvalue}
                                      status={row?.addressLabel?.status}
                                      state={row?.addressLabel?.state}
                                      index={index}
                                      itemid={row?.id}
                                      controltype="dropdown"
                                    />
                                    <AcceptUserActions
                                      name={
                                        "addressArray_addressLabel_" + index
                                      }
                                      value={row?.addressLabel?.value}
                                      newvalue={row?.addressLabel?.newvalue}
                                      status={row?.addressLabel?.status}
                                      state={row?.addressLabel?.state}
                                      index={index}
                                      itemid={row?.id}
                                      controltype="dropdown"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          {(row?.addressLine1?.value !==
                            row?.addressLine1?.newvalue ||
                            row?.addressLine1.status !== "notverified") && (
                              <div className="row mb-3">
                                <div className="form-group">
                                  <label className="form-label">
                                    Address line 1
                                    <UnVerifiedStatus
                                      status={row?.addressLine1}
                                      name={
                                        "addressArray_addressLine1_unv_" + index
                                      }
                                      value={row?.addressLine1?.value}
                                      newvalue={row?.addressLine1?.newvalue}
                                      state={row?.addressLabel?.state}
                                    />
                                  </label>
                                  <div className="row">
                                    {(row?.addressLine1?.value !==
                                      row?.addressLine1?.newvalue ||
                                    row?.addressLine1.state === "modified") && (
                                      <div className="col-4">
                                        <input
                                          type="text"
                                          name={
                                            "addressArray_addressLine1_old_" +
                                            index
                                          }
                                          id={
                                            "addressArray_addressLine1_old_" +
                                            index
                                          }
                                          disabled={true}
                                          className={inputverifyclassName}
                                          value={row?.addressLine1?.value}
                                        />
                                      </div>
                                    )}

                                  <div className="col">
                                    <input
                                      type="text"
                                      name={
                                        "addressArray_addressLine1_" + index
                                      }
                                      id={"addressArray_addressLine1_" + index}
                                      disabled={inputverifydisabled}
                                      className={inputverifyclassName}
                                      value={row?.addressLine1?.newvalue}
                                      onChange={(e) => {
                                        handleChangePersonalAddress(
                                          e,
                                          row?.id,
                                          index
                                        );
                                      }}
                                    />
                                  </div>
                                    <div className="col-3 ">
                                      <VerifyUserActions
                                        name={
                                          "addressArray_addressLine1_" + index
                                        }
                                        value={row?.addressLine1?.value}
                                        newvalue={row?.addressLine1?.newvalue}
                                        status={row?.addressLine1?.status}
                                        index={index}
                                        itemid={row?.id}
                                        state={row?.addressLabel?.state}
                                      />
                                      <AcceptUserActions
                                        name={
                                          "addressArray_addressLine1_" + index
                                        }
                                        value={row?.addressLine1?.value}
                                        newvalue={row?.addressLine1?.newvalue}
                                        status={row?.addressLine1?.status}
                                        index={index}
                                        itemid={row?.id}
                                        state={row?.addressLabel?.state}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          {(row?.addressLine2?.value !==
                            row?.addressLine2?.newvalue ||
                            row?.addressLine2.status !== "notverified") && (
                              <div className="row mb-3">
                                <div className="form-group">
                                  <label className="form-label">
                                    Address line 2
                                    <UnVerifiedStatus
                                      status={row?.addressLine2}
                                      name={
                                        "addressArray_addressLine2_unv_" + index
                                      }
                                      value={row?.addressLine2?.value}
                                      newvalue={row?.addressLine2?.newvalue}
                                      state={row?.addressLine2?.state}
                                    />
                                  </label>
                                  <div className="row">
                                    {(row?.addressLine2?.value !==
                                      row?.addressLine2?.newvalue ||
                                    row?.addressLine2.state === "modified") && (
                                      <div className="col-4">
                                        <input
                                          type="text"
                                          name={
                                            "addressArray_addressLine2_old_" +
                                            index
                                          }
                                          id={
                                            "addressArray_addressLine2_old_" +
                                            index
                                          }
                                          disabled={true}
                                          className={inputverifyclassName}
                                          value={row?.addressLine2?.value}
                                        />
                                      </div>
                                    )}

                                  <div className="col">
                                    <input
                                      type="text"
                                      name={
                                        "addressArray_addressLine2_" + index
                                      }
                                        id={"addressArray_addressLine2_" + index}
                                        disabled={inputverifydisabled}
                                        className={inputverifyclassName}
                                        value={row?.addressLine2?.newvalue}
                                        onChange={(e) => {
                                          handleChangePersonalAddress(
                                            e,
                                            row?.id,
                                            index
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="col-3 p-0">
                                      <VerifyUserActions
                                        name={
                                          "addressArray_addressLine2_" + index
                                        }
                                        value={row?.addressLine2?.value}
                                        newvalue={row?.addressLine2?.newvalue}
                                        status={row?.addressLine2?.status}
                                        index={index}
                                        itemid={row?.id}
                                        state={row?.addressLabel?.state}
                                      />
                                      <AcceptUserActions
                                        name={
                                          "addressArray_addressLine2_" + index
                                        }
                                        value={row?.addressLine2?.value}
                                        newvalue={row?.addressLine2?.newvalue}
                                        status={row?.addressLine2?.status}
                                        index={index}
                                        itemid={row?.id}
                                        state={row?.addressLabel?.state}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          {(row?.city?.value !== row?.city?.newvalue ||
                            row?.city.status !== "notverified") && (
                              <div className="row mb-3">
                                <div className="form-group">
                                  <label className="form-label">
                                    City
                                    <UnVerifiedStatus
                                      status={row?.city}
                                      name={"addressArray_city_unv_" + index}
                                      value={row?.city?.value}
                                      newvalue={row?.city?.newvalue}
                                      state={row?.city?.state}
                                    />
                                  </label>
                                  <div className="row">
                                  {(row?.city?.value !== row?.city?.newvalue ||
                                    row?.city.state === "modified") && (
                                      <div className="col-4">
                                        <input
                                          type="text"
                                          name={"addressArray_city_old_" + index}
                                          id={"addressArray_city_old_" + index}
                                          disabled={true}
                                          className={inputverifyclassName}
                                          value={row?.city?.value}
                                        />
                                      </div>
                                    )}

                                    <div className="col">
                                      <input
                                        type="text"
                                        name={"addressArray_city_" + index}
                                        id={"addressArray_city_" + index}
                                        disabled={inputverifydisabled}
                                        className={inputverifyclassName}
                                        value={row?.city?.newvalue}
                                        onChange={(e) => {
                                          handleChangePersonalAddress(
                                            e,
                                            row?.id,
                                            index
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="col-3 p-0">
                                      <VerifyUserActions
                                        name={"addressArray_city_" + index}
                                        value={row?.city?.value}
                                        newvalue={row?.city?.newvalue}
                                        status={row?.city?.status}
                                        index={index}
                                        itemid={row?.id}
                                        state={row?.addressLabel?.state}
                                      />
                                      <AcceptUserActions
                                        name={"addressArray_city_" + index}
                                        value={row?.city?.value}
                                        newvalue={row?.city?.newvalue}
                                        status={row?.city?.status}
                                        index={index}
                                        itemid={row?.id}
                                        state={row?.addressLabel?.state}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          {/* <div className="row mb-3">
                              <div className="form-group">
                                <label className="form-label ms-1">
                                  Ismain?
                                  <UnVerifiedStatus
                                    status={row?.isMain}
                                    name={"addressArray_isMain_unv_" + index}
                                    value={row?.isMain?.value}
                                    newvalue={row?.isMain?.newvalue}
                                    state={row?.isMain?.state}
                                  />
                                </label>
                                <div className="row">
                                  {(row?.isMain?.value !==
                                    row?.isMain?.newvalue ||
                                    row?.isMain.state === "modified") && (
                                    <div className="col-4">
                                      <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                        <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                        {row?.isMain?.value === 1
                                          ? "Yes"
                                          : "No"}{" "}
                                      </span>
                                    </div>
                                  )}

                                  <div
                                    className="col"
                                    id={"div_view_addressArray_isMain_" + index}
                                  >
                                    <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                      <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                      {row?.isMain?.newvalue === 1
                                        ? "Yes"
                                        : "No"}{" "}
                                    </span>
                                  </div>
                                  <div
                                    className="col d-none"
                                    id={"div_edit_addressArray_isMain_" + index}
                                  >
                                    <input
                                      type="checkbox"
                                      id={"addressArray_isMain_" + index}
                                      name={"addressArray_isMain_" + index}
                                      className="form-check-input ms-3"
                                      defaultChecked={false}
                                      onChange={(e) => {
                                        handleChangePersonalAddress(
                                          e,
                                          row.id,
                                          index,
                                          "isMain"
                                        );
                                      }}
                                      checked={
                                        row.isMain?.newvalue === 1
                                          ? true
                                          : false
                                      }
                                    />
                                    <label className="form-label form-label ms-1">
                                      Yes/No
                                    </label>
                                  </div>
                                  <div className="col-3 p-0">
                                    <VerifyUserActions
                                      name={"addressArray_isMain_" + index}
                                      value={row?.isMain?.value}
                                      newvalue={row?.isMain?.newvalue}
                                      status={row?.isMain?.status}
                                      index={index}
                                      itemid={row?.id}
                                      state={row?.isMain?.state}
                                      controltype={"isMain"}
                                    />
                                    <AcceptUserActions
                                      name={"addressArray_isMain_" + index}
                                      value={row?.isMain?.value}
                                      newvalue={row?.isMain?.newvalue}
                                      status={row?.isMain?.status}
                                      index={index}
                                      itemid={row?.id}
                                      state={row?.isMain?.state}
                                      controltype={"isMain"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          {(row?.country?.value !== row?.country?.newvalue ||
                            row?.country.status !== "notverified") && (
                              <div className="row mb-3">
                                <div className="form-group">
                                  <label className="form-label">
                                    Country
                                    <UnVerifiedStatus
                                      status={row?.country}
                                      name={"addressArray_country_unv_" + index}
                                      value={row?.country?.value}
                                      newvalue={row?.country?.newvalue}
                                      state={row?.country?.state}
                                    />
                                  </label>
                                  <div className="row">
                                    {row?.country?.value !==
                                      row?.country?.newvalue && (
                                        <div className="col-4">
                                          <input
                                            type="text"
                                            name={
                                              "addressArray_country_old_" + index
                                            }
                                          id={"addressArray_country_old_" + index}
                                          disabled={true}
                                          className={inputverifyclassName}
                                          value={row?.country?.value}
                                        />
                                      </div>
                                    )}

                                    <div className="col">
                                      <input
                                        type="text"
                                        name={"addressArray_country_" + index}
                                        id={"addressArray_country_" + index}
                                        disabled={inputverifydisabled}
                                        className={inputverifyclassName}
                                        value={row?.country?.newvalue}
                                        onChange={(e) => {
                                          handleChangePersonalAddress(
                                            e,
                                            row?.id,
                                            index
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="col-3 p-0">
                                      <VerifyUserActions
                                        name={"addressArray_country_" + index}
                                        value={row?.country?.value}
                                        newvalue={row?.country?.newvalue}
                                        status={row?.country?.status}
                                        index={index}
                                        itemid={row?.id}
                                        state={row?.addressLabel?.state}
                                      />
                                      <AcceptUserActions
                                        name={"addressArray_country_" + index}
                                        value={row?.country?.value}
                                        newvalue={row?.country?.newvalue}
                                        status={row?.country?.status}
                                        index={index}
                                        itemid={row?.id}
                                        state={row?.addressLabel?.state}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          {(row?.state?.value !== row?.state?.newvalue ||
                            row?.state.status !== "notverified") && (
                              <div className="row mb-3">
                                <div className="form-group">
                                  <label className="form-label">
                                    State
                                    <UnVerifiedStatus
                                      status={row?.state}
                                      name={"addressArray_state_unv_" + index}
                                      value={row?.state?.value}
                                      newvalue={row?.state?.newvalue}
                                      state={row?.state?.state}
                                    />
                                  </label>
                                  <div className="row">
                                    {row?.state?.value !==
                                      row?.state?.newvalue && (
                                        <div className="col-4">
                                          <input
                                            type="text"
                                          name={"addressArray_state_old_" + index}
                                          id={"addressArray_state_old_" + index}
                                          disabled={true}
                                          className={inputverifyclassName}
                                          value={row?.state?.value}
                                        />
                                      </div>
                                    )}

                                    <div className="col">
                                      <input
                                        type="text"
                                        name={"addressArray_state_" + index}
                                        id={"addressArray_state_" + index}
                                        disabled={inputverifydisabled}
                                        className={inputverifyclassName}
                                        value={row?.state?.newvalue}
                                        onChange={(e) => {
                                          handleChangePersonalAddress(
                                            e,
                                            row?.id,
                                            index
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="col-3 p-0">
                                      <VerifyUserActions
                                        name={"addressArray_state_" + index}
                                        value={row?.state?.value}
                                        newvalue={row?.state?.newvalue}
                                        status={row?.state?.status}
                                        index={index}
                                        itemid={row?.id}
                                        state={row?.addressLabel?.state}
                                      />
                                      <AcceptUserActions
                                        name={"addressArray_state_" + index}
                                        value={row?.state?.value}
                                        newvalue={row?.state?.newvalue}
                                        status={row?.state?.status}
                                        index={index}
                                        itemid={row?.id}
                                        state={row?.addressLabel?.state}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          {(row?.postalCode?.value !==
                            row?.postalCode?.newvalue ||
                            row?.postalCode.status !== "notverified") && (
                              <div className="row mb-3">
                                <div className="form-group">
                                  <label className="form-label">
                                    Postal code
                                    <UnVerifiedStatus
                                      status={row?.postalCode}
                                      name={
                                        "addressArray_postalCode_unv_" + index
                                      }
                                      value={row?.postalCode?.value}
                                      newvalue={row?.postalCode?.newvalue}
                                      state={row?.postalCode?.state}
                                    />
                                  </label>
                                  <div className="row">
                                    {row?.postalCode?.value !==
                                      row?.postalCode?.newvalue && (
                                        <div className="col-4">
                                          <input
                                            type="number"
                                            name={
                                          "addressArray_postalCode_old_" + index
                                        }
                                        id={
                                            "addressArray_postalCode_old_" + index
                                          }
                                          disabled={true}
                                          className={inputverifyclassName}
                                          value={row?.postalCode?.value}
                                        />
                                      </div>
                                    )}

                                  <div className="col">
                                    <input
                                      type="number"
                                      name={"addressArray_postalCode_" + index}
                                      id={"addressArray_postalCode_" + index}
                                      disabled={inputverifydisabled}
                                      className={inputverifyclassName}
                                      value={row?.postalCode?.newvalue}
                                      onChange={(e) => {
                                        handleChangePersonalAddress(
                                          e,
                                          row?.id,
                                          index
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="col-3 p-0">
                                    <VerifyUserActions
                                      name={"addressArray_postalCode_" + index}
                                      value={row?.postalCode?.value}
                                      newvalue={row?.postalCode?.newvalue}
                                      status={row?.postalCode?.status}
                                      index={index}
                                      itemid={row?.id}
                                      state={row?.addressLabel?.state}
                                    />
                                    <AcceptUserActions
                                        name={"addressArray_postalCode_" + index}
                                        value={row?.postalCode?.value}
                                        newvalue={row?.postalCode?.newvalue}
                                        status={row?.postalCode?.status}
                                        index={index}
                                        itemid={row?.id}
                                        state={row?.addressLabel?.state}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>
                      ))}
                  </div>
                )}

                {/* personal phone info */}
                {PhoneArray.length > 0 && (
                  <div className="row">
                    {PhoneArray.length > 0 &&
                      PhoneArray.map((row, index) => (
                        <div>
                          {(row?.fullPhone?.value !==
                            row?.fullPhone?.newvalue ||
                            row.fullPhone.status !== "notverified") && (
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <div className="form-group">
                                <label className="form-label">
                                  Other phone
                                  <UnVerifiedStatus
                                    status={row?.fullPhone}
                                    name={"phoneArray_fullPhone_unv_" + index}
                                    value={row?.fullPhone?.value}
                                    newvalue={row?.fullPhone?.newvalue}
                                    state={row?.fullPhone?.state}
                                  />
                                </label>
                                <div className="row">
                                  {(row?.fullPhone?.value !==
                                    row?.fullPhone?.newvalue ||
                                    row.fullPhone.state === "modified") && (
                                      <div className="col-4">
                                        <PhoneInput
                                          inputStyle={{ width: "180px" }}
                                          country={"us"}
                                          value={row?.fullPhone?.value}
                                          name={
                                            "phoneArray_fullPhone_old_" + index
                                          }
                                          id={"phoneArray_fullPhone_old_" + index}
                                          disabled={true}
                                        />
                                      </div>
                                    )}

                                  <div
                                    className="col"
                                    id={
                                      "div_view_phoneArray_fullPhone_" + index
                                    }
                                  >
                                    <PhoneInput
                                      inputStyle={{ width: "180px" }}
                                      country={"us"}
                                      value={row?.fullPhone?.newvalue}
                                      name={"phoneArray_fullPhone_" + index}
                                      id={"phoneArray_fullPhone_" + index}
                                      disabled={true}
                                    />
                                  </div>
                                  <div
                                    className="col d-none"
                                    id={
                                      "div_edit_phoneArray_fullPhone_" + index
                                    }
                                  >
                                    <PhoneInput
                                      inputStyle={{ width: "180px" }}
                                      country={"us"}
                                      value={row?.fullPhone?.newvalue}
                                      name={"phoneArray_fullPhone_" + index}
                                      id={"phoneArray_fullPhone_" + index}
                                      onChange={(e) => {
                                        handleChangePersonalPhone(
                                          e,
                                          row.id,
                                          index,
                                          "phoneArray_fullPhone_" + index,
                                          "fullPhone",
                                          "false"
                                        );
                                      }}
                                    />
                                  </div>
                                    <div className="col-3">
                                      <VerifyUserActions
                                        name={"phoneArray_fullPhone_" + index}
                                        value={row?.fullPhone?.value}
                                        newvalue={row?.fullPhone?.newvalue}
                                        status={row?.fullPhone?.status}
                                        index={index}
                                        itemid={row?.id}
                                        controltype="fullPhone"
                                        state={row?.fullPhone?.state}
                                      />
                                      <AcceptUserActions
                                        name={"phoneArray_fullPhone_" + index}
                                        value={row?.fullPhone?.value}
                                        newvalue={row?.fullPhone?.newvalue}
                                        status={row?.fullPhone?.status}
                                        index={index}
                                        itemid={row?.id}
                                        controltype="fullPhone"
                                        state={row?.fullPhone?.state}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          {/* <div className="row mb-3">
                              <div className="form-group">
                                <label className="form-label">
                                  Extn
                                  <UnVerifiedStatus
                                    status={row?.ext}
                                    name={"phoneArray_ext_unv_" + index}
                                    value={row?.ext?.value}
                                    newvalue={row?.ext?.newvalue}
                                  />
                                </label>
                                <div className="row">
                                  {(row?.ext?.value !== row?.ext?.newvalue ||
                                    row.ext.state === "modified") && (
                                    <div className="col-4">
                                      <input
                                        type="text"
                                        name={"phoneArray_ext_old_" + index}
                                        id={"phoneArray_ext_old_" + index}
                                        disabled={true}
                                        className={inputverifyclassName}
                                        placeholder="Ext..."
                                        value={row?.ext?.value}
                                      />
                                    </div>
                                  )}

                                  <div className="col">
                                    <input
                                      type="text"
                                      name={"phoneArray_ext_" + index}
                                      id={"phoneArray_ext_" + index}
                                      disabled={inputverifydisabled}
                                      className={inputverifyclassName}
                                      placeholder="Ext..."
                                      value={row?.ext?.newvalue}
                                      onChange={(e) => {
                                        handleChangePersonalPhone(
                                          e,
                                          row?.id,
                                          index
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="col-3 p-0">
                                    <VerifyUserActions
                                      name={"phoneArray_ext_" + index}
                                      value={row?.ext?.value}
                                      newvalue={row?.ext?.newvalue}
                                      status={row?.ext?.status}
                                      index={index}
                                      itemid={row?.id}
                                      state={row?.ext?.state}
                                    />
                                    <AcceptUserActions
                                      name={"phoneArray_ext_" + index}
                                      value={row?.ext?.value}
                                      newvalue={row?.ext?.newvalue}
                                      status={row?.ext?.status}
                                      index={index}
                                      itemid={row?.id}
                                      state={row?.ext?.state}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="form-group">
                                <label className="form-label ms-1">
                                  Ismain?
                                  <UnVerifiedStatus
                                    status={row?.isMain}
                                    name={"phoneArray_isMain_unv_" + index}
                                    value={row?.isMain?.value}
                                    newvalue={row?.isMain?.newvalue}
                                    state={row?.isMain?.state}
                                  />
                                </label>
                                <div className="row">
                                  {(row?.isMain?.value !==
                                    row?.isMain?.newvalue ||
                                    row.isMain.state === "modified") && (
                                    <div className="col-4">
                                      <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                        <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                        {row?.isMain?.value === 1
                                          ? "Yes"
                                          : "No"}{" "}
                                      </span>
                                    </div>
                                  )}

                                  <div
                                    className="col"
                                    id={"div_view_phoneArray_isMain_" + index}
                                  >
                                    <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                      <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                      {row?.isMain?.newvalue === 1
                                        ? "Yes"
                                        : "No"}{" "}
                                    </span>
                                  </div>
                                  <div
                                    className="col d-none"
                                    id={"div_edit_phoneArray_isMain_" + index}
                                  >
                                    <input
                                      type="checkbox"
                                      id={"phoneArray_isMain_" + index}
                                      name={"phoneArray_isMain_" + index}
                                      className="form-check-input ms-3"
                                      defaultChecked={false}
                                      onChange={(e) => {
                                        handleChangePersonalPhone(
                                          e,
                                          row.id,
                                          index,
                                          "phoneArray_isMain_" + index,
                                          "isMain",
                                          "false"
                                        );
                                      }}
                                      checked={
                                        row.isMain?.newvalue === 1
                                          ? true
                                          : false
                                      }
                                    />
                                    <label className="form-label form-label ms-1">
                                      Yes/No
                                    </label>
                                  </div>
                                  <div className="col-3 p-0">
                                    <VerifyUserActions
                                      name={"phoneArray_isMain_" + index}
                                      value={row?.isMain?.value}
                                      newvalue={row?.isMain?.newvalue}
                                      status={row?.isMain?.status}
                                      index={index}
                                      itemid={row?.id}
                                      state={row?.isMain?.state}
                                      controltype={"isMain"}
                                    />
                                    <AcceptUserActions
                                      name={"phoneArray_isMain_" + index}
                                      value={row?.isMain?.value}
                                      newvalue={row?.isMain?.newvalue}
                                      status={row?.isMain?.status}
                                      index={index}
                                      itemid={row?.id}
                                      state={row?.isMain?.state}
                                      controltype={"isMain"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr></hr> */}
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* business info     */}
          {contact?.contactAffiliationsArray.length > 0 &&
            contact?.contactAffiliationsArray.map((affiliation, index) => {
              return (
                <div className="accordion-item mt-2">
                  <div
                    className="accordion-header p-3"
                    style={{
                      "background-color": "#f2f2f2",
                    }}
                  >
                    {" "}
                    {affiliation?.companyName?.value +
                      " (" +
                      affiliation.sourceSystem.appname +
                      ")"}
                  </div>
                  <div className="accordion-body">
                    <div className="row">
                      <div className="row p-1">
                        {/* <div className="col-sm-12 col-md-6 col-lg-6">
                          <div className="form-group">
                            <label className="form-label">
                              Description
                              <UnVerifiedStatus
                                status={affiliation?.description}
                                name={
                                  "contactAffiliationsArray_description_unv_" +
                                  index
                                }
                                value={affiliation?.description?.value}
                                newvalue={affiliation?.description?.newvalue}
                                state={affiliation?.description?.state}
                              />
                            </label>
                            <div className="row">
                              {(affiliation?.description?.value !==
                                affiliation?.description?.newvalue ||
                                affiliation?.description?.state ===
                                  "modified") && (
                                <div className="col-4">
                                  <input
                                    type="text"
                                    name={
                                      "contactAffiliationsArray_description_old_" +
                                      index
                                    }
                                    id={
                                      "contactAffiliationsArray_description_old_" +
                                      index
                                    }
                                    disabled={true}
                                    className={inputverifyclassName}
                                    value={affiliation?.description?.value}
                                  />
                                </div>
                              )}

                              <div className="col">
                                <input
                                  type="text"
                                  name={
                                    "contactAffiliationsArray_description_" +
                                    index
                                  }
                                  id={
                                    "contactAffiliationsArray_description_" +
                                    index
                                  }
                                  disabled={inputverifydisabled}
                                  className={inputverifyclassName}
                                  value={affiliation?.description?.newvalue}
                                  onChange={(evnt) =>
                                    handleBusinessChange(
                                      evnt,
                                      affiliation.id,
                                      "description",
                                      index
                                    )
                                  }
                                />
                              </div>
                              <div className="col-3 p-0">
                                <VerifyUserActions
                                  name={
                                    "contactAffiliationsArray_description_" +
                                    index
                                  }
                                  status={affiliation?.description?.status}
                                  index={index}
                                  itemid={affiliation?.id}
                                  value={affiliation?.description?.value}
                                  newvalue={affiliation?.description?.newvalue}
                                  state={affiliation?.description?.state}
                                />
                                <AcceptUserActions
                                  name={
                                    "contactAffiliationsArray_description_" +
                                    index
                                  }
                                  status={affiliation?.description?.status}
                                  index={index}
                                  itemid={affiliation?.id}
                                  value={affiliation?.description?.value}
                                  newvalue={affiliation?.description?.newvalue}
                                  state={affiliation?.description?.state}
                                />
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>

                      {/* <div className="row p-1">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                          <div className="form-group">
                            <label className="form-label">
                              Contact role{" "}
                              <UnVerifiedStatus
                                status={affiliation?.contactRole}
                                name={
                                  "contactAffiliationsArray_contactRole_unv_" +
                                  index
                                }
                                value={affiliation?.contactRole?.value}
                                newvalue={affiliation?.contactRole?.newvalue}
                                state={affiliation?.contactRole?.state}
                              />
                            </label>
                            <div className="row">
                              {(affiliation?.contactRole?.value !==
                                affiliation?.contactRole?.newvalue ||
                                affiliation?.contactRole?.state ===
                                  "modified") && (
                                <div className="col-4">
                                  <input
                                    type="text"
                                    name={
                                      "contactAffiliationsArray_contactRole_old_" +
                                      index
                                    }
                                    id={
                                      "contactAffiliationsArray_contactRole_old_" +
                                      index
                                    }
                                    disabled={true}
                                    className={inputverifyclassName}
                                    value={affiliation?.contactRole?.value}
                                  />
                                </div>
                              )}

                              <div className="col">
                                <input
                                  type="text"
                                  name={
                                    "contactAffiliationsArray_contactRole_" +
                                    index
                                  }
                                  id={
                                    "contactAffiliationsArray_contactRole_" +
                                    index
                                  }
                                  disabled={inputverifydisabled}
                                  className={inputverifyclassName}
                                  value={affiliation?.contactRole?.newvalue}
                                  onChange={(evnt) =>
                                    handleBusinessChange(
                                      evnt,
                                      affiliation.id,
                                      "contactRole",
                                      index
                                    )
                                  }
                                />
                              </div>
                              <div className="col-3 p-0">
                                <VerifyUserActions
                                  name={
                                    "contactAffiliationsArray_contactRole_" +
                                    index
                                  }
                                  status={affiliation?.contactRole?.status}
                                  index={index}
                                  itemid={affiliation?.id}
                                  value={affiliation?.contactRole?.value}
                                  newvalue={affiliation?.contactRole?.newvalue}
                                  state={affiliation?.contactRole?.state}
                                />
                                <AcceptUserActions
                                  name={
                                    "contactAffiliationsArray_contactRole_" +
                                    index
                                  }
                                  status={affiliation?.contactRole?.status}
                                  index={index}
                                  itemid={affiliation?.id}
                                  value={affiliation?.contactRole?.value}
                                  newvalue={affiliation?.contactRole?.newvalue}
                                  state={affiliation?.contactRole?.state}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                          <div className="form-group">
                            <label className="form-label">
                              Contact record type
                              <UnVerifiedStatus
                                status={affiliation?.contactRecordType}
                                name={
                                  "contactAffiliationsArray_contactRecordType_unv_" +
                                  index
                                }
                                value={affiliation?.contactRecordType?.value}
                                newvalue={
                                  affiliation?.contactRecordType?.newvalue
                                }
                                state={affiliation?.contactRecordType?.state}
                              />
                            </label>
                            <div className="row">
                              {(affiliation?.contactRecordType?.value !==
                                affiliation?.contactRecordType?.newvalue ||
                                affiliation?.contactRecordType?.state ===
                                  "modified") && (
                                <div className="col-4">
                                  <input
                                    type="text"
                                    name={
                                      "contactAffiliationsArray_contactRecordType_old_" +
                                      index
                                    }
                                    id={
                                      "contactAffiliationsArray_contactRecordType_old_" +
                                      index
                                    }
                                    disabled={true}
                                    className={inputverifyclassName}
                                    value={
                                      affiliation?.contactRecordType?.value
                                    }
                                  />
                                </div>
                              )}
                              <div className="col">
                                <input
                                  type="text"
                                  name={
                                    "contactAffiliationsArray_contactRecordType_" +
                                    index
                                  }
                                  id={
                                    "contactAffiliationsArray_contactRecordType_" +
                                    index
                                  }
                                  disabled={inputverifydisabled}
                                  className={inputverifyclassName}
                                  value={
                                    affiliation?.contactRecordType?.newvalue
                                  }
                                  onChange={(evnt) =>
                                    handleBusinessChange(
                                      evnt,
                                      affiliation.id,
                                      "contactRecordType",
                                      index
                                    )
                                  }
                                />
                              </div>

                              <div className="col-3 p-0">
                                <VerifyUserActions
                                  name={
                                    "contactAffiliationsArray_contactRecordType_" +
                                    index
                                  }
                                  status={
                                    affiliation?.contactRecordType?.status
                                  }
                                  index={index}
                                  itemid={affiliation?.id}
                                  value={affiliation?.contactRecordType?.value}
                                  newvalue={
                                    affiliation?.contactRecordType?.newvalue
                                  }
                                  state={affiliation?.contactRecordType?.state}
                                />
                                <AcceptUserActions
                                  name={
                                    "contactAffiliationsArray_contactRecordType_" +
                                    index
                                  }
                                  status={
                                    affiliation?.contactRecordType?.status
                                  }
                                  index={index}
                                  itemid={affiliation?.id}
                                  value={affiliation?.contactRecordType?.value}
                                  newvalue={
                                    affiliation?.contactRecordType?.newvalue
                                  }
                                  state={affiliation?.contactRecordType?.state}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                          <div className="form-group">
                            <label className="form-label">
                              Department
                              <UnVerifiedStatus
                                status={affiliation?.department}
                                name={
                                  "contactAffiliationsArray_department_unv_" +
                                  index
                                }
                                value={affiliation?.department?.value}
                                newvalue={affiliation?.department?.newvalue}
                                state={affiliation?.department?.state}
                              />
                            </label>
                            <div className="row">
                              {(affiliation?.department?.value !==
                                affiliation?.department?.newvalue ||
                                affiliation?.department?.state ===
                                  "modified") && (
                                <div className="col-4">
                                  <input
                                    type="text"
                                    name={
                                      "contactAffiliationsArray_department_old_" +
                                      index
                                    }
                                    id={
                                      "contactAffiliationsArray_department_old_" +
                                      index
                                    }
                                    disabled={true}
                                    className={inputverifyclassName}
                                    value={affiliation?.department?.value}
                                  />
                                </div>
                              )}
                              <div className="col">
                                <input
                                  type="text"
                                  name={
                                    "contactAffiliationsArray_department_" +
                                    index
                                  }
                                  id={
                                    "contactAffiliationsArray_department_" +
                                    index
                                  }
                                  disabled={inputverifydisabled}
                                  className={inputverifyclassName}
                                  value={affiliation?.department?.newvalue}
                                  onChange={(evnt) =>
                                    handleBusinessChange(
                                      evnt,
                                      affiliation.id,
                                      "department",
                                      index
                                    )
                                  }
                                />
                              </div>
                              <div className="col-3 p-0">
                                <VerifyUserActions
                                  name={
                                    "contactAffiliationsArray_department_" +
                                    index
                                  }
                                  status={affiliation?.department?.status}
                                  index={index}
                                  itemid={affiliation?.id}
                                  value={affiliation?.department?.value}
                                  newvalue={affiliation?.department?.newvalue}
                                  state={affiliation?.department?.state}
                                />
                                <AcceptUserActions
                                  name={
                                    "contactAffiliationsArray_department_" +
                                    index
                                  }
                                  status={affiliation?.department?.status}
                                  index={index}
                                  itemid={affiliation?.id}
                                  value={affiliation?.department?.value}
                                  newvalue={affiliation?.department?.newvalue}
                                  state={affiliation?.department?.state}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                          <div className="form-group">
                            <label className="form-label">
                              Report to
                              <UnVerifiedStatus
                                status={affiliation?.reportTo}
                                name={
                                  "contactAffiliationsArray_reportTo_unv_" +
                                  index
                                }
                                value={affiliation?.reportTo?.value}
                                newvalue={affiliation?.reportTo?.newvalue}
                                state={affiliation?.reportTo?.state}
                              />
                            </label>
                            <div className="row">
                              {(affiliation?.reportTo?.value !==
                                affiliation?.reportTo?.newvalue ||
                                affiliation?.reportTo?.state ===
                                  "modified") && (
                                <div className="col-4">
                                  <input
                                    type="text"
                                    name={
                                      "contactAffiliationsArray_reportTo_old_" +
                                      index
                                    }
                                    id={
                                      "contactAffiliationsArray_reportTo_old_" +
                                      index
                                    }
                                    disabled={true}
                                    className={inputverifyclassName}
                                    value={affiliation?.reportTo?.value}
                                  />
                                </div>
                              )}

                              <div className="col">
                                <input
                                  type="text"
                                  name={
                                    "contactAffiliationsArray_reportTo_" + index
                                  }
                                  id={
                                    "contactAffiliationsArray_reportTo_" + index
                                  }
                                  disabled={inputverifydisabled}
                                  className={inputverifyclassName}
                                  value={affiliation?.reportTo?.newvalue}
                                  onChange={(evnt) =>
                                    handleBusinessChange(
                                      evnt,
                                      affiliation.id,
                                      "reportTo",
                                      index
                                    )
                                  }
                                />
                              </div>
                              <div className="col-3 p-0">
                                <VerifyUserActions
                                  name={
                                    "contactAffiliationsArray_reportTo_" + index
                                  }
                                  status={affiliation?.reportTo?.status}
                                  index={index}
                                  itemid={affiliation?.id}
                                  value={affiliation?.reportTo?.value}
                                  newvalue={affiliation?.reportTo?.newvalue}
                                  state={affiliation?.reportTo?.state}
                                />
                                <AcceptUserActions
                                  name={
                                    "contactAffiliationsArray_reportTo_" + index
                                  }
                                  status={affiliation?.reportTo?.status}
                                  index={index}
                                  itemid={affiliation?.id}
                                  value={affiliation?.reportTo?.value}
                                  state={affiliation?.reportTo?.state}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row p-1">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                          <div className="form-group">
                            <label className="form-label">
                              Claimed URL
                              <UnVerifiedStatus
                                status={affiliation?.claimedUrl}
                                name={
                                  "contactAffiliationsArray_claimedUrl_unv_" +
                                  index
                                }
                                value={affiliation?.claimedUrl?.value}
                                newvalue={affiliation?.claimedUrl?.newvalue}
                                state={affiliation?.claimedUrl?.state}
                              />
                            </label>
                            <div className="row">
                              {(affiliation?.claimedUrl?.value !==
                                affiliation?.claimedUrl?.newvalue ||
                                affiliation?.claimedUrl?.state ===
                                  "modified") && (
                                <div className="col-4">
                                  <input
                                    type="text"
                                    name={
                                      "contactAffiliationsArray_claimedUrl_old_" +
                                      index
                                    }
                                    id={
                                      "contactAffiliationsArray_claimedUrl_old_" +
                                      index
                                    }
                                    disabled={true}
                                    className={inputverifyclassName}
                                    value={affiliation?.claimedUrl?.value}
                                  />
                                </div>
                              )}

                              <div className="col">
                                <input
                                  type="text"
                                  name={
                                    "contactAffiliationsArray_claimedUrl_" +
                                    index
                                  }
                                  id={
                                    "contactAffiliationsArray_claimedUrl_" +
                                    index
                                  }
                                  disabled={inputverifydisabled}
                                  className={inputverifyclassName}
                                  value={affiliation?.claimedUrl?.newvalue}
                                  onChange={(evnt) =>
                                    handleBusinessChange(
                                      evnt,
                                      affiliation.id,
                                      "claimedUrl",
                                      index
                                    )
                                  }
                                />
                              </div>
                              <div className="col-3 p-0">
                                <VerifyUserActions
                                  name={
                                    "contactAffiliationsArray_claimedUrl_" +
                                    index
                                  }
                                  status={affiliation?.claimedUrl?.status}
                                  index={index}
                                  itemid={affiliation?.id}
                                  value={affiliation?.claimedUrl?.value}
                                  newvalue={affiliation?.claimedUrl?.newvalue}
                                  state={affiliation?.claimedUrl?.state}
                                />
                                <AcceptUserActions
                                  name={
                                    "contactAffiliationsArray_claimedUrl_" +
                                    index
                                  }
                                  status={affiliation?.claimedUrl?.status}
                                  index={index}
                                  itemid={affiliation?.id}
                                  value={affiliation?.claimedUrl?.value}
                                  state={affiliation?.claimedUrl?.state}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* business email info */}
                      {affiliation?.emailArray !== undefined &&
                        affiliation?.emailArray.length > 0 && (
                          <div className="row p-1 m-2">
                            {affiliation?.emailArray?.map((row, rowindex) => (
                              <div>
                                {(row?.email?.value !== row?.email?.newvalue ||
                                  row?.email?.status !== "notverified") && (
                                  <div className="row mb-2">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Email
                                        <UnVerifiedStatus
                                          status={row?.email}
                                          name={
                                            "contactAffiliationsArray_emailArray_email_unv_" +
                                            index +
                                            "_" +
                                            rowindex
                                          }
                                          value={row?.email?.value}
                                          newvalue={row?.email?.newvalue}
                                          state={row?.email?.state}
                                        />
                                      </label>
                                      <div className="row">
                                        {(row?.email?.value !==
                                          row?.email?.newvalue ||
                                          row?.email?.state === "modified") && (
                                          <div className="col-4">
                                            <input
                                              type="text"
                                              name={
                                                "contactAffiliationsArray_emailArray_email_old_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              id={
                                                "contactAffiliationsArray_emailArray_email_old_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              disabled={true}
                                              className={inputverifyclassName}
                                              value={row?.email?.value}
                                            />
                                          </div>
                                        )}

                                        <div className="col">
                                          <input
                                            type="text"
                                            name={
                                              "contactAffiliationsArray_emailArray_email_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            id={
                                              "contactAffiliationsArray_emailArray_email_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            disabled={inputverifydisabled}
                                            className={inputverifyclassName}
                                            value={row?.email?.newvalue}
                                            onChange={(evnt) =>
                                              handleChangeBusinessEmail(
                                                evnt,
                                                index,
                                                rowindex,
                                                "email"
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="col-3 p-0">
                                          <VerifyUserActions
                                            name={
                                              "contactAffiliationsArray_emailArray_email_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            status={row?.email?.status}
                                            index={index}
                                            itemid={affiliation?.id}
                                            subitemid={row?.id}
                                            value={row?.email?.value}
                                            newvalue={row?.email?.newvalue}
                                            state={row?.email?.state}
                                          />
                                          <AcceptUserActions
                                            name={
                                              "contactAffiliationsArray_emailArray_email_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            status={row?.email?.status}
                                            index={index}
                                            itemid={affiliation?.id}
                                            subitemid={row?.id}
                                            value={row?.email?.value}
                                            newvalue={row?.email?.newvalue}
                                            state={row?.email?.state}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {/* <div className="row mb-2">
                                    <div className="form-group">
                                      <label className="form-label form-label">
                                        Label
                                        <UnVerifiedStatus
                                          status={row?.label}
                                          name={
                                            "contactAffiliationsArray_emailArray_label_unv_" +
                                            index +
                                            "_" +
                                            rowindex
                                          }
                                          value={row?.label?.value}
                                          newvalue={row?.label?.newvalue}
                                          state={row?.label?.state}
                                        />
                                      </label>
                                      <div className="row">
                                        {(row?.label?.value !==
                                          row?.label?.newvalue ||
                                          row?.label?.state == "modified") && (
                                          <div className="col-4">
                                            <input
                                              type="text"
                                              name={
                                                "contactAffiliationsArray_emailArray_label_old_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              id={
                                                "contactAffiliationsArray_emailArray_label_old_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              disabled={true}
                                              className={inputverifyclassName}
                                              value={row?.label?.value}
                                            />
                                          </div>
                                        )}

                                        <div className="col">
                                          <input
                                            type="text"
                                            name={
                                              "contactAffiliationsArray_emailArray_label_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            id={
                                              "contactAffiliationsArray_emailArray_label_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            disabled={inputverifydisabled}
                                            className={inputverifyclassName}
                                            value={row?.label?.newvalue}
                                            onChange={(evnt) => {
                                              handleChangeBusinessEmail(
                                                evnt,
                                                index,
                                                rowindex,
                                                "label"
                                              );
                                            }}
                                          />
                                        </div>
                                        <div className="col-3 p-0">
                                          <VerifyUserActions
                                            name={
                                              "contactAffiliationsArray_emailArray_label_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            status={row?.label?.status}
                                            index={index}
                                            itemid={affiliation?.id}
                                            subitemid={row?.id}
                                            value={row?.label?.value}
                                            newvalue={row?.label?.newvalue}
                                            state={row?.label?.state}
                                          />
                                          <AcceptUserActions
                                            name={
                                              "contactAffiliationsArray_emailArray_label_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            status={row?.label?.status}
                                            index={index}
                                            itemid={affiliation?.id}
                                            subitemid={row?.id}
                                            value={row?.label?.value}
                                            newvalue={row?.label?.newvalue}
                                            state={row?.label?.state}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row mb-2">
                                    <div className="form-group">
                                      <label className="form-label form-label ms-1">
                                        Ismain?
                                        <UnVerifiedStatus
                                          status={row?.isMain}
                                          name={
                                            "contactAffiliationsArray_emailArray_isMain_unv_" +
                                            index +
                                            "_" +
                                            rowindex
                                          }
                                          value={row?.isMain?.value}
                                          newvalue={row?.isMain?.newvalue}
                                          state={row?.isMain?.state}
                                        />
                                      </label>
                                      <div className="row">
                                        {(row?.isMain?.value !==
                                          row?.isMain?.newvalue ||
                                          row?.isMain?.state ===
                                            "modified") && (
                                          <div className="col-4">
                                            <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                              <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                              {row?.isMain?.value === 1
                                                ? "Yes"
                                                : "No"}{" "}
                                            </span>
                                          </div>
                                        )}

                                        <div
                                          className="col"
                                          id={
                                            "div_view_contactAffiliationsArray_emailArray_isMain_" +
                                            index +
                                            "_" +
                                            rowindex
                                          }
                                        >
                                          <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                            <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                            {row?.isMain?.newvalue === 1
                                              ? "Yes"
                                              : "No"}{" "}
                                          </span>
                                        </div>
                                        <div
                                          className="col d-none"
                                          id={
                                            "div_edit_contactAffiliationsArray_emailArray_isMain_" +
                                            index +
                                            "_" +
                                            rowindex
                                          }
                                        >
                                          <input
                                            type="checkbox"
                                            id="isMain"
                                            name="isMain"
                                            className="form-check-input ms-3"
                                            defaultChecked={false}
                                            onChange={(evnt) =>
                                              handleChangeBusinessEmail(
                                                evnt,
                                                index,
                                                rowindex,
                                                "isMain"
                                              )
                                            }
                                            checked={
                                              row.isMain?.newvalue === 1
                                                ? true
                                                : false
                                            }
                                          />
                                          <label className="form-label form-label ms-1">
                                            Yes/No
                                          </label>
                                        </div>
                                        <div className="col-3 p-0">
                                          <VerifyUserActions
                                            name={
                                              "contactAffiliationsArray_emailArray_isMain_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            status={row?.isMain?.status}
                                            index={index}
                                            itemid={affiliation?.id}
                                            subitemid={row?.id}
                                            value={row?.isMain?.value}
                                            newvalue={row?.isMain?.newvalue}
                                            state={row?.isMain?.state}
                                            controltype={"isMain"}
                                          />
                                          <AcceptUserActions
                                            name={
                                              "contactAffiliationsArray_emailArray_isMain_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            status={row?.isMain?.status}
                                            index={index}
                                            itemid={affiliation?.id}
                                            subitemid={row?.id}
                                            value={row?.isMain?.value}
                                            newvalue={row?.isMain?.newvalue}
                                            state={row?.isMain?.state}
                                            controltype={"isMain"}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <hr></hr> */}
                              </div>
                            ))}
                          </div>
                        )}

                      {/* business address info */}
                      {affiliation?.addressArray !== undefined &&
                        affiliation?.addressArray.length > 0 && (
                          <div className="row p-1 m-2">
                            {affiliation?.addressArray?.map((row, rowindex) => (
                              <diV>
                                {/* <div className="row mb-3">
                                      <div className="form-group">
                                        <label className="form-label">
                                          Address label
                                          <UnVerifiedStatus
                                            status={row?.addressLabel}
                                            name={
                                              "contactAffiliationsArray_addressArray_addressLabel_unv_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            value={row?.addressLabel?.value}
                                            newvalue={
                                              row?.addressLabel?.newvalue
                                            }
                                            state={row?.addressLabel?.state}
                                          />
                                        </label>
                                        <div className="row">
                                          {(row?.addressLabel?.value !==
                                            row?.addressLabel?.newvalue ||
                                            row?.addressLabel?.state ===
                                              "modified") && (
                                            <div className="col-4">
                                              <Select
                                                type="text"
                                                name={
                                                  "contactAffiliationsArray_addressArray_addressLabel_old_" +
                                                  index +
                                                  "_" +
                                                  rowindex
                                                }
                                                id={
                                                  "contactAffiliationsArray_addressArray_addressLabel_old_" +
                                                  index +
                                                  "_" +
                                                  rowindex
                                                }
                                                isDisabled={true}
                                                className={inputverifyclassName}
                                                styles={customstyles}
                                                options={addressOptions}
                                                value={addressOptions.filter(
                                                  (client) =>
                                                    client.value ===
                                                    row?.addressLabel?.value
                                                )}
                                              />
                                            </div>
                                          )}

                                          <div
                                            className="col"
                                            id={
                                              "div_view_contactAffiliationsArray_addressArray_addressLabel_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                          >
                                            <Select
                                              type="text"
                                              name={
                                                "contactAffiliationsArray_addressArray_addressLabel_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              id={
                                                "contactAffiliationsArray_addressArray_addressLabel_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              isDisabled={true}
                                              className={inputverifyclassName}
                                              styles={customstyles}
                                              options={addressOptions}
                                              value={addressOptions.filter(
                                                (client) =>
                                                  client.value ===
                                                  row?.addressLabel?.newvalue
                                              )}
                                            />
                                          </div>
                                          <div
                                            className="col d-none"
                                            id={
                                              "div_edit_contactAffiliationsArray_addressArray_addressLabel_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                          >
                                            <Select
                                              type="text"
                                              name={
                                                "contactAffiliationsArray_addressArray_addressLabel_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              id={
                                                "contactAffiliationsArray_addressArray_addressLabel_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              className={inputverifyclassName}
                                              styles={customstyles}
                                              options={addressOptions}
                                              value={addressOptions.filter(
                                                (client) =>
                                                  client.value ===
                                                  row?.addressLabel?.newvalue
                                              )}
                                              onChange={(val) => {
                                                handleChangeBusinessAddress(
                                                  {
                                                    target: {
                                                      name: "addressLabel",
                                                      value: val.value,
                                                      newvalue: val.newvalue,
                                                    },
                                                  },
                                                  index,
                                                  rowindex,
                                                  "addressLabel"
                                                );
                                              }}
                                            />
                                          </div>
                                          <div className="col-3 p-0">
                                            <VerifyUserActions
                                              name={
                                                "contactAffiliationsArray_addressArray_addressLabel_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              status={row?.addressLabel?.status}
                                              index={index}
                                              itemid={affiliation?.id}
                                              subitemid={row?.id}
                                              value={row?.addressLabel?.value}
                                              newvalue={
                                                row?.addressLabel?.newvalue
                                              }
                                              state={row?.addressLabel?.state}
                                              controltype="dropdown"
                                            />
                                            <AcceptUserActions
                                              name={
                                                "contactAffiliationsArray_addressArray_addressLabel_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              status={row?.addressLabel?.status}
                                              index={index}
                                              itemid={affiliation?.id}
                                              subitemid={row?.id}
                                              value={row?.addressLabel?.value}
                                              newvalue={
                                                row?.addressLabel?.newvalue
                                              }
                                              state={row?.addressLabel?.state}
                                              controltype="dropdown"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div> */}
                                {(row?.addressLine1?.value !==
                                  row?.addressLine1?.newvalue ||
                                  row?.addressLine1?.status !==
                                    "notverified") && (
                                  <div className="row mb-3">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Address line 1
                                        <UnVerifiedStatus
                                          status={row?.addressLine1}
                                          name={
                                            "contactAffiliationsArray_addressArray_addressLine1_unv_" +
                                            index +
                                            "_" +
                                            rowindex
                                          }
                                          value={row?.addressLine1?.value}
                                          newvalue={row?.addressLine1?.newvalue}
                                          state={row?.addressLine1?.state}
                                        />
                                      </label>
                                      <div className="row">
                                        {(row?.addressLine1?.value !==
                                          row?.addressLine1?.newvalue ||
                                          row?.addressLine1?.state ===
                                            "modified") && (
                                          <div className="col-4">
                                            <input
                                              type="text"
                                              name={
                                                "contactAffiliationsArray_addressArray_addressLine1_old_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              id={
                                                "contactAffiliationsArray_addressArray_addressLine1_old_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              disabled={true}
                                              className={inputverifyclassName}
                                              value={row?.addressLine1?.value}
                                            />
                                          </div>
                                        )}

                                        <div className="col">
                                          <input
                                            type="text"
                                            name={
                                              "contactAffiliationsArray_addressArray_addressLine1_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            id={
                                              "contactAffiliationsArray_addressArray_addressLine1_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            disabled={inputverifydisabled}
                                            className={inputverifyclassName}
                                            value={row?.addressLine1?.newvalue}
                                            onChange={(evnt) =>
                                              handleChangeBusinessAddress(
                                                evnt,
                                                index,
                                                rowindex,
                                                "addressLine1"
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="col-3 p-0">
                                          <VerifyUserActions
                                            name={
                                              "contactAffiliationsArray_addressArray_addressLine1_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            status={row?.addressLine1?.status}
                                            index={index}
                                            itemid={affiliation?.id}
                                            subitemid={row?.id}
                                            value={row?.addressLine1?.value}
                                            newvalue={
                                              row?.addressLine1?.newvalue
                                            }
                                            state={row?.addressLine1?.state}
                                          />
                                          <AcceptUserActions
                                            name={
                                              "contactAffiliationsArray_addressArray_addressLine1_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            status={row?.addressLine1?.status}
                                            index={index}
                                            itemid={affiliation?.id}
                                            subitemid={row?.id}
                                            value={row?.addressLine1?.value}
                                            newvalue={
                                              row?.addressLine1?.newvalue
                                            }
                                            state={row?.addressLine1?.state}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {(row?.addressLine2?.value !==
                                  row?.addressLine2?.newvalue ||
                                  row?.addressLine2?.status !==
                                    "notverified") && (
                                  <div className="row mb-3">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Address line 2
                                        <UnVerifiedStatus
                                          status={row?.addressLine2}
                                          name={
                                            "contactAffiliationsArray_addressArray_addressLine2_unv_" +
                                            index +
                                            "_" +
                                            rowindex
                                          }
                                          value={row?.addressLine2?.value}
                                          newvalue={row?.addressLine2?.newvalue}
                                          state={row?.addressLine2?.state}
                                        />
                                      </label>
                                      <div className="row">
                                        {(row?.addressLine2?.value !==
                                          row?.addressLine2?.newvalue ||
                                          row?.addressLine2?.state ===
                                            "modified") && (
                                          <div className="col-4">
                                            <input
                                              type="text"
                                              name={
                                                "contactAffiliationsArray_addressArray_addressLine2_old_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              id={
                                                "contactAffiliationsArray_addressArray_addressLine2_old_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              disabled={true}
                                              className={inputverifyclassName}
                                              value={row?.addressLine2?.value}
                                            />
                                          </div>
                                        )}

                                        <div className="col">
                                          <input
                                            type="text"
                                            name={
                                              "contactAffiliationsArray_addressArray_addressLine2_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            id={
                                              "contactAffiliationsArray_addressArray_addressLine2_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            disabled={inputverifydisabled}
                                            className={inputverifyclassName}
                                            value={row?.addressLine2?.newvalue}
                                            onChange={(evnt) =>
                                              handleChangeBusinessAddress(
                                                evnt,
                                                index,
                                                rowindex,
                                                "addressLine2"
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="col-3 p-0">
                                          <VerifyUserActions
                                            name={
                                              "contactAffiliationsArray_addressArray_addressLine2_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            status={row?.addressLine2?.status}
                                            index={index}
                                            itemid={affiliation?.id}
                                            subitemid={row?.id}
                                            value={row?.addressLine2?.value}
                                            newvalue={
                                              row?.addressLine2?.newvalue
                                            }
                                            state={row?.addressLine2?.state}
                                          />
                                          <AcceptUserActions
                                            name={
                                              "contactAffiliationsArray_addressArray_addressLine2_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            status={row?.addressLine2?.status}
                                            index={index}
                                            itemid={affiliation?.id}
                                            subitemid={row?.id}
                                            value={row?.addressLine2?.value}
                                            newvalue={
                                              row?.addressLine2?.newvalue
                                            }
                                            state={row?.addressLine2?.state}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {(row?.city?.value !== row?.city?.newvalue ||
                                  row?.city?.status !== "notverified") && (
                                  <div className="row mb-3">
                                    <div className="form-group">
                                      <label className="form-label">
                                        City
                                        <UnVerifiedStatus
                                          status={row?.city}
                                          name={
                                            "contactAffiliationsArray_addressArray_city_unv_" +
                                            index +
                                            "_" +
                                            rowindex
                                          }
                                          value={row?.city?.value}
                                          newvalue={row?.city?.newvalue}
                                          state={row?.city?.state}
                                        />
                                      </label>
                                      <div className="row">
                                        {(row?.city?.value !==
                                          row?.city?.newvalue ||
                                          row?.city?.state === "modified") && (
                                          <div className="col-4">
                                            <input
                                              type="text"
                                              name={
                                                "contactAffiliationsArray_addressArray_city_old_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              id={
                                                "contactAffiliationsArray_addressArray_city_old_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              disabled={true}
                                              className={inputverifyclassName}
                                              value={row?.city?.value}
                                            />
                                          </div>
                                        )}

                                        <div className="col">
                                          <input
                                            type="text"
                                            name={
                                              "contactAffiliationsArray_addressArray_city_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            id={
                                              "contactAffiliationsArray_addressArray_city_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            disabled={inputverifydisabled}
                                            className={inputverifyclassName}
                                            value={row?.city?.newvalue}
                                            onChange={(evnt) =>
                                              handleChangeBusinessAddress(
                                                evnt,
                                                index,
                                                rowindex,
                                                "city"
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="col-3 p-0">
                                          <VerifyUserActions
                                            name={
                                              "contactAffiliationsArray_addressArray_city_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            status={row?.city?.status}
                                            index={index}
                                            itemid={affiliation?.id}
                                            subitemid={row?.id}
                                            value={row?.city?.value}
                                            newvalue={row?.city?.newvalue}
                                            state={row?.city?.state}
                                          />
                                          <AcceptUserActions
                                            name={
                                              "contactAffiliationsArray_addressArray_city_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            status={row?.city?.status}
                                            index={index}
                                            itemid={affiliation?.id}
                                            subitemid={row?.id}
                                            value={row?.city?.value}
                                            newvalue={row?.city?.newvalue}
                                            state={row?.city?.state}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {/* <div className="row mb-3">
                                      <div className="form-group">
                                        <label className="form-label ms-1">
                                          Ismain?
                                          <UnVerifiedStatus
                                            status={row?.isMain}
                                            name={
                                              "contactAffiliationsArray_addressArray_isMain_unv_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            value={row?.isMain?.value}
                                            newvalue={row?.isMain?.newvalue}
                                            state={row?.isMain?.state}
                                          />
                                        </label>
                                        <div className="row">
                                          {(row?.isMain?.value !==
                                            row?.isMain?.newvalue ||
                                            row?.isMain?.state ===
                                              "modified") && (
                                            <div className="col-4">
                                              <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                                <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                                {row?.isMain?.value === 1
                                                  ? "Yes"
                                                  : "No"}{" "}
                                              </span>
                                            </div>
                                          )}

                                          <div
                                            className="col"
                                            id={
                                              "div_view_contactAffiliationsArray_addressArray_isMain_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                          >
                                            <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                              <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                              {row?.isMain?.newvalue === 1
                                                ? "Yes"
                                                : "No"}{" "}
                                            </span>
                                          </div>
                                          <div
                                            className="col d-none"
                                            id={
                                              "div_edit_contactAffiliationsArray_addressArray_isMain_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                          >
                                            <input
                                              type="checkbox"
                                              id="isMain"
                                              name="isMain"
                                              className="form-check-input ms-3"
                                              defaultChecked={false}
                                              onChange={(evnt) =>
                                                handleChangeBusinessAddress(
                                                  evnt,
                                                  index,
                                                  rowindex,
                                                  "isMain"
                                                )
                                              }
                                              checked={
                                                row.isMain?.newvalue === 1
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <label className="form-label form-label ms-1">
                                              Yes/No
                                            </label>
                                          </div>
                                          <div className="col-3 p-0">
                                            <VerifyUserActions
                                              name={
                                                "contactAffiliationsArray_addressArray_isMain_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              status={row?.isMain?.status}
                                              index={index}
                                              itemid={affiliation?.id}
                                              subitemid={row?.id}
                                              value={row?.isMain?.value}
                                              newvalue={row?.isMain?.newvalue}
                                              state={row?.isMain?.state}
                                              controltype={"isMain"}
                                            />
                                            <AcceptUserActions
                                              name={
                                                "contactAffiliationsArray_addressArray_isMain_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              status={row?.isMain?.status}
                                              index={index}
                                              itemid={affiliation?.id}
                                              subitemid={row?.id}
                                              value={row?.isMain?.value}
                                              newvalue={row?.isMain?.newvalue}
                                              state={row?.isMain?.state}
                                              controltype={"isMain"}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div> */}
                                {(row?.country?.value !==
                                  row?.country?.newvalue ||
                                  row?.country?.status !== "notverified") && (
                                  <div className="row mb-3">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Country
                                        <UnVerifiedStatus
                                          status={row?.country}
                                          name={
                                            "contactAffiliationsArray_addressArray_country_unv_" +
                                            index +
                                            "_" +
                                            rowindex
                                          }
                                          value={row?.country?.value}
                                          newvalue={row?.country?.newvalue}
                                          state={row?.country?.state}
                                        />
                                      </label>
                                      <div className="row">
                                        {(row?.country?.value !==
                                          row?.country?.newvalue ||
                                          row?.country?.state ===
                                            "modified") && (
                                          <div className="col-4">
                                            <input
                                              type="text"
                                              name={
                                                "contactAffiliationsArray_addressArray_country_old_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              id={
                                                "contactAffiliationsArray_addressArray_country_old_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              disabled={true}
                                              className={inputverifyclassName}
                                              value={row?.country?.value}
                                            />
                                          </div>
                                        )}

                                        <div className="col">
                                          <input
                                            type="text"
                                            name={
                                              "contactAffiliationsArray_addressArray_country_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            id={
                                              "contactAffiliationsArray_addressArray_country_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            disabled={inputverifydisabled}
                                            className={inputverifyclassName}
                                            value={row?.country?.newvalue}
                                            onChange={(evnt) =>
                                              handleChangeBusinessAddress(
                                                evnt,
                                                index,
                                                rowindex,
                                                "country"
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="col-3 p-0">
                                          <VerifyUserActions
                                            name={
                                              "contactAffiliationsArray_addressArray_country_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            status={row?.country?.status}
                                            index={index}
                                            itemid={affiliation?.id}
                                            subitemid={row?.id}
                                            value={row?.country?.value}
                                            newvalue={row?.country?.newvalue}
                                            state={row?.country?.state}
                                          />
                                          <AcceptUserActions
                                            name={
                                              "contactAffiliationsArray_addressArray_country_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            status={row?.country?.status}
                                            index={index}
                                            itemid={affiliation?.id}
                                            subitemid={row?.id}
                                            value={row?.country?.value}
                                            newvalue={row?.country?.newvalue}
                                            state={row?.country?.state}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {(row?.state?.value !== row?.state?.newvalue ||
                                  row?.state?.status !== "notverified") && (
                                  <div className="row mb-3">
                                    <div className="form-group">
                                      <label className="form-label">
                                        State
                                        <UnVerifiedStatus
                                          status={row?.state}
                                          name={
                                            "contactAffiliationsArray_addressArray_state_unv_" +
                                            index +
                                            "_" +
                                            rowindex
                                          }
                                          value={row?.state?.value}
                                          newvalue={row?.state?.newvalue}
                                          state={row?.state?.state}
                                        />
                                      </label>
                                      <div className="row">
                                        {(row?.state?.value !==
                                          row?.state?.newvalue ||
                                          row?.state?.state === "modified") && (
                                          <div className="col-4">
                                            <input
                                              type="text"
                                              name={
                                                "contactAffiliationsArray_addressArray_state_old_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              id={
                                                "contactAffiliationsArray_addressArray_state_old_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              disabled={true}
                                              className={inputverifyclassName}
                                              value={row?.state?.value}
                                            />
                                          </div>
                                        )}

                                        <div className="col">
                                          <input
                                            type="text"
                                            name={
                                              "contactAffiliationsArray_addressArray_state_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            id={
                                              "contactAffiliationsArray_addressArray_state_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            disabled={inputverifydisabled}
                                            className={inputverifyclassName}
                                            value={row?.state?.newvalue}
                                            onChange={(evnt) =>
                                              handleChangeBusinessAddress(
                                                evnt,
                                                index,
                                                rowindex,
                                                "state"
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="col-3 p-0">
                                          <VerifyUserActions
                                            name={
                                              "contactAffiliationsArray_addressArray_state_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            status={row?.state?.status}
                                            index={index}
                                            itemid={affiliation?.id}
                                            subitemid={row?.id}
                                            value={row?.state?.value}
                                            newvalue={row?.state?.newvalue}
                                            state={row?.state?.state}
                                          />
                                          <AcceptUserActions
                                            name={
                                              "contactAffiliationsArray_addressArray_state_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            status={row?.state?.status}
                                            index={index}
                                            itemid={affiliation?.id}
                                            subitemid={row?.id}
                                            value={row?.state?.value}
                                            newvalue={row?.state?.newvalue}
                                            state={row?.state?.state}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {(row?.postalCode?.value !==
                                  row?.postalCode?.newvalue ||
                                  row?.postalCode?.status !==
                                    "notverified") && (
                                  <div className="row mb-3">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Postal code
                                        <UnVerifiedStatus
                                          status={row?.postalCode}
                                          name={
                                            "contactAffiliationsArray_addressArray_postalCode_unv_" +
                                            index +
                                            "_" +
                                            rowindex
                                          }
                                          value={row?.postalCode?.value}
                                          newvalue={row?.postalCode?.newvalue}
                                          state={row?.postalCode?.state}
                                        />
                                      </label>
                                      <div className="row">
                                        {(row?.postalCode?.value !==
                                          row?.postalCode?.newvalue ||
                                          row?.postalCode?.state ==
                                            "modified") && (
                                          <div className="col-4">
                                            <input
                                              type="number"
                                              name={
                                                "contactAffiliationsArray_addressArray_postalCode_old_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              id={
                                                "contactAffiliationsArray_addressArray_postalCode_old_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              disabled={true}
                                              className={inputverifyclassName}
                                              value={row?.postalCode?.value}
                                            />
                                          </div>
                                        )}

                                        <div className="col">
                                          <input
                                            type="number"
                                            name={
                                              "contactAffiliationsArray_addressArray_postalCode_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            id={
                                              "contactAffiliationsArray_addressArray_postalCode_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            disabled={inputverifydisabled}
                                            className={inputverifyclassName}
                                            value={row?.postalCode?.newvalue}
                                            onChange={(evnt) =>
                                              handleChangeBusinessAddress(
                                                evnt,
                                                index,
                                                rowindex,
                                                "postalCode"
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="col-3 p-0">
                                          <VerifyUserActions
                                            name={
                                              "contactAffiliationsArray_addressArray_postalCode_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            status={row?.postalCode?.status}
                                            index={index}
                                            itemid={affiliation?.id}
                                            subitemid={row?.id}
                                            value={row?.postalCode?.value}
                                            newvalue={row?.postalCode?.newvalue}
                                            state={row?.postalCode?.state}
                                          />
                                          <AcceptUserActions
                                            name={
                                              "contactAffiliationsArray_addressArray_postalCode_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            status={row?.postalCode?.status}
                                            index={index}
                                            itemid={affiliation?.id}
                                            subitemid={row?.id}
                                            value={row?.postalCode?.value}
                                            newvalue={row?.postalCode?.newvalue}
                                            state={row?.postalCode?.state}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </diV>
                            ))}
                          </div>
                        )}

                      {/* business phone info */}
                      {affiliation?.phoneArray !== undefined &&
                        affiliation?.phoneArray.length > 0 && (
                          <div className="row p-1 m-2">
                            {affiliation?.phoneArray?.map((row, rowindex) => (
                              <div>
                                {/* <div className="row mb-3">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Phone type
                                        <UnVerifiedStatus
                                          status={row?.type}
                                          name={
                                            "contactAffiliationsArray_phoneArray_type_unv_" +
                                            index +
                                            "_" +
                                            rowindex
                                          }
                                          value={row?.type?.value}
                                          newvalue={row?.type?.newvalue}
                                          state={row?.type?.state}
                                        />
                                      </label>
                                      <div className="row">
                                        {(row?.type?.value !==
                                          row?.type?.newvalue ||
                                          row?.type?.state === "modified") && (
                                          <div className="col-4">
                                            <Select
                                              type="text"
                                              name={
                                                "contactAffiliationsArray_phoneArray_type_old_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              id={
                                                "contactAffiliationsArray_phoneArray_type_old_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              isDisabled={true}
                                              className={inputverifyclassName}
                                              styles={customstyles}
                                              value={phoneOptions.filter(
                                                (client) =>
                                                  client.value ===
                                                  row?.type?.value
                                              )}
                                              options={phoneOptions}
                                            />
                                          </div>
                                        )}

                                        <div
                                          className="col"
                                          id={
                                            "div_view_contactAffiliationsArray_phoneArray_type_" +
                                            index +
                                            "_" +
                                            rowindex
                                          }
                                        >
                                          <Select
                                            type="text"
                                            name={
                                              "contactAffiliationsArray_phoneArray_type_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            id={
                                              "contactAffiliationsArray_phoneArray_type_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            isDisabled={true}
                                            className={inputverifyclassName}
                                            styles={customstyles}
                                            value={phoneOptions.filter(
                                              (client) =>
                                                client.value ===
                                                row?.type?.newvalue
                                            )}
                                            options={phoneOptions}
                                          />
                                        </div>
                                        <div
                                          className="col d-none"
                                          id={
                                            "div_edit_contactAffiliationsArray_phoneArray_type_" +
                                            index +
                                            "_" +
                                            rowindex
                                          }
                                        >
                                          <Select
                                            type="text"
                                            name={
                                              "contactAffiliationsArray_phoneArray_type_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            id={
                                              "contactAffiliationsArray_phoneArray_type_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            className={inputverifyclassName}
                                            styles={customstyles}
                                            value={phoneOptions.filter(
                                              (client) =>
                                                client.value ===
                                                row?.type?.newvalue
                                            )}
                                            onChange={(val) => {
                                              handleChangeBusinessPhone(
                                                {
                                                  target: {
                                                    name: "type",
                                                    value: val.value,
                                                  },
                                                },
                                                index,
                                                rowindex,
                                                "type"
                                              );
                                            }}
                                            options={phoneOptions}
                                          />
                                        </div>
                                        <div className="col-3 p-0">
                                          <VerifyUserActions
                                            name={
                                              "contactAffiliationsArray_phoneArray_type_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            status={row?.type?.status}
                                            index={index}
                                            itemid={affiliation?.id}
                                            subitemid={row?.id}
                                            value={row?.type?.value}
                                            newvalue={row?.type?.newvalue}
                                            state={row?.type?.state}
                                            controltype="dropdown"
                                          />
                                          <AcceptUserActions
                                            name={
                                              "contactAffiliationsArray_phoneArray_type_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            status={row?.type?.status}
                                            index={index}
                                            itemid={affiliation?.id}
                                            subitemid={row?.id}
                                            value={row?.type?.value}
                                            newvalue={row?.type?.newvalue}
                                            state={row?.type?.state}
                                            controltype="dropdown"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                                {(row?.fullPhone?.value !==
                                  row?.fullPhone?.newvalue ||
                                  row?.fullPhone?.status !== "notverified") && (
                                  <div className="row mb-3">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Phone number{" "}
                                        <UnVerifiedStatus
                                          status={row?.fullPhone}
                                          name={
                                            "contactAffiliationsArray_phoneArray_fullPhone_unv_" +
                                            index +
                                            "_" +
                                            rowindex
                                          }
                                          value={row?.fullPhone?.value}
                                          newvalue={row?.fullPhone?.newvalue}
                                          state={row?.fullPhone?.state}
                                        />
                                      </label>
                                      <div className="row">
                                        {(row?.fullPhone?.value !==
                                          row?.fullPhone?.newvalue ||
                                          row?.fullPhone?.state ===
                                            "modified") && (
                                          <div className="col-4">
                                            <PhoneInput
                                              inputStyle={{
                                                width: "180px",
                                              }}
                                              country={"us"}
                                              value={row?.fullPhone?.value}
                                              name={
                                                "contactAffiliationsArray_phoneArray_fullPhone_old_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              id={
                                                "contactAffiliationsArray_phoneArray_fullPhone_old_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              disabled={true}
                                            />
                                          </div>
                                        )}

                                        <div
                                          className="col"
                                          id={
                                            "div_view_contactAffiliationsArray_phoneArray_fullPhone_" +
                                            index +
                                            "_" +
                                            rowindex
                                          }
                                        >
                                          <PhoneInput
                                            inputStyle={{
                                              width: "180px",
                                            }}
                                            disabled={true}
                                            country={"us"}
                                            value={row?.fullPhone?.newvalue}
                                            name={
                                              "contactAffiliationsArray_phoneArray_fullPhone_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            id={
                                              "contactAffiliationsArray_phoneArray_fullPhone_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                          />
                                        </div>
                                        <div
                                          className="col d-none"
                                          id={
                                            "div_edit_contactAffiliationsArray_phoneArray_fullPhone_" +
                                            index +
                                            "_" +
                                            rowindex
                                          }
                                        >
                                          <PhoneInput
                                            inputStyle={{
                                              width: "200px",
                                            }}
                                            country={"us"}
                                            value={row?.fullPhone?.newvalue}
                                            name={
                                              "contactAffiliationsArray_phoneArray_fullPhone_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            id={
                                              "contactAffiliationsArray_phoneArray_fullPhone_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            onChange={(evnt) =>
                                              handleChangeBusinessPhone(
                                                evnt,
                                                index,
                                                rowindex,
                                                "fullPhone"
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="col-3 p-0">
                                          <VerifyUserActions
                                            name={
                                              "contactAffiliationsArray_phoneArray_fullPhone_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            status={row?.fullPhone?.status}
                                            index={index}
                                            itemid={affiliation?.id}
                                            subitemid={row?.id}
                                            value={row?.fullPhone?.value}
                                            newvalue={row?.fullPhone?.newvalue}
                                            state={row?.fullPhone?.state}
                                            controltype="fullPhone"
                                          />
                                          <AcceptUserActions
                                            name={
                                              "contactAffiliationsArray_phoneArray_fullPhone_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            status={row?.fullPhone?.status}
                                            index={index}
                                            itemid={affiliation?.id}
                                            subitemid={row?.id}
                                            value={row?.fullPhone?.value}
                                            newvalue={row?.fullPhone?.newvalue}
                                            state={row?.fullPhone?.state}
                                            controltype="fullPhone"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {/* <div className="row mb-3">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Extn
                                        <UnVerifiedStatus
                                          status={row?.ext}
                                          name={
                                            "contactAffiliationsArray_phoneArray_ext_unv_" +
                                            index +
                                            "_" +
                                            rowindex
                                          }
                                          value={row?.ext?.value}
                                          newvalue={row?.ext?.newvalue}
                                          state={row?.ext?.state}
                                        />
                                      </label>
                                      <div className="row">
                                        {(row?.ext?.value !==
                                          row?.ext?.newvalue ||
                                          row?.ext?.state == "modified") && (
                                          <div className="col-4">
                                            <input
                                              type="text"
                                              name={
                                                "contactAffiliationsArray_phoneArray_ext_old_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              id={
                                                "contactAffiliationsArray_phoneArray_ext_old_" +
                                                index +
                                                "_" +
                                                rowindex
                                              }
                                              disabled={true}
                                              className={inputverifyclassName}
                                              value={row?.ext?.value}
                                            />
                                          </div>
                                        )}

                                        <div className="col">
                                          <input
                                            type="text"
                                            name={
                                              "contactAffiliationsArray_phoneArray_ext_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            id={
                                              "contactAffiliationsArray_phoneArray_ext_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            disabled={inputverifydisabled}
                                            className={inputverifyclassName}
                                            value={row?.ext?.newvalue}
                                            onChange={(evnt) =>
                                              handleChangeBusinessPhone(
                                                evnt,
                                                index,
                                                rowindex,
                                                "ext"
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="col-3 p-0">
                                          <VerifyUserActions
                                            name={
                                              "contactAffiliationsArray_phoneArray_ext_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            status={row?.ext?.status}
                                            index={index}
                                            itemid={affiliation?.id}
                                            subitemid={row?.id}
                                            value={row?.ext?.value}
                                            newvalue={row?.ext?.newvalue}
                                            state={row?.ext?.state}
                                          />
                                          <AcceptUserActions
                                            name={
                                              "contactAffiliationsArray_phoneArray_ext_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            status={row?.ext?.status}
                                            index={index}
                                            itemid={affiliation?.id}
                                            subitemid={row?.id}
                                            value={row?.ext?.value}
                                            newvalue={row?.ext?.newvalue}
                                            state={row?.ext?.state}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row mb-3">
                                    <div className="form-group">
                                      <label className="form-label ms-1">
                                        Ismain?
                                        <UnVerifiedStatus
                                          status={row?.isMain}
                                          name={
                                            "contactAffiliationsArray_phoneArray_isMain_unv_" +
                                            index +
                                            "_" +
                                            rowindex
                                          }
                                          value={row?.isMain?.value}
                                          newvalue={row?.isMain?.newvalue}
                                          state={row?.isMain?.state}
                                        />
                                      </label>
                                      <div className="row">
                                        {(row?.isMain?.value !==
                                          row?.isMain?.newvalue ||
                                          row?.isMain?.state ===
                                            "modified") && (
                                          <div className="col-4">
                                            <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                              <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                              {row?.isMain?.value === 1
                                                ? "Yes"
                                                : "No"}{" "}
                                            </span>
                                          </div>
                                        )}

                                        <div
                                          className="col"
                                          id={
                                            "div_view_contactAffiliationsArray_phoneArray_isMain_" +
                                            index +
                                            "_" +
                                            rowindex
                                          }
                                        >
                                          <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                            <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                            {row?.isMain?.newvalue === 1
                                              ? "Yes"
                                              : "No"}{" "}
                                          </span>
                                        </div>
                                        <div
                                          className="col d-none"
                                          id={
                                            "div_edit_contactAffiliationsArray_phoneArray_isMain_" +
                                            index +
                                            "_" +
                                            rowindex
                                          }
                                        >
                                          <input
                                            type="checkbox"
                                            id="isMain"
                                            name="isMain"
                                            className="form-check-input ms-3"
                                            defaultChecked={false}
                                            onChange={(evnt) =>
                                              handleChangeBusinessPhone(
                                                evnt,
                                                index,
                                                rowindex,
                                                "isMain"
                                              )
                                            }
                                            checked={
                                              row.isMain?.newvalue === 1
                                                ? true
                                                : false
                                            }
                                          />
                                          <label className="form-label form-label ms-1">
                                            Yes/No
                                          </label>
                                        </div>
                                        <div className="col-3 p-0">
                                          <VerifyUserActions
                                            name={
                                              "contactAffiliationsArray_phoneArray_isMain_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            status={row?.isMain?.status}
                                            index={index}
                                            itemid={affiliation?.id}
                                            subitemid={row?.id}
                                            value={row?.isMain?.value}
                                            newvalue={row?.isMain?.newvalue}
                                            state={row?.isMain?.state}
                                            controltype={"isMain"}
                                          />
                                          <AcceptUserActions
                                            name={
                                              "contactAffiliationsArray_phoneArray_isMain_" +
                                              index +
                                              "_" +
                                              rowindex
                                            }
                                            status={row?.isMain?.status}
                                            index={index}
                                            itemid={affiliation?.id}
                                            subitemid={row?.id}
                                            value={row?.isMain?.value}
                                            newvalue={row?.isMain?.newvalue}
                                            state={row?.isMain?.state}
                                            controltype={"isMain"}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <hr></hr> */}
                              </div>
                            ))}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              );
            })}

          <div className="d-flex justify-content-center mt-3 gap-2">
            <Button className=" btn btn-success " onClick={handleCancel}>
              Close
            </Button>
            <Button
              className="btn btn-success ms-10 me-3"
              onClick={submitHandler}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
      <div>
        {isSuccess && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title={appConstants.contact.successfullmsg.adminverification}
            onConfirm={handleSucessSweetAlert}
          ></SweetAlert>
        )}
      </div>
    </div>
  );
}
export default AdminVerificationdetail;
