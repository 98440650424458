import { ButtonGroup, Button, Table } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router";
import { Panel, PanelHeader, PanelBody } from "../../components/panel/panel";
import Loading from "../../components/Loading";
import FileBase from "react-file-base64";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  getapplicationsbyid,
  getapplistbyuserid,
  getAdminVerificationTaskByParent,
  updateApplicationByID,
  updateApplicationRelationshipByID,
  updateApplicationByLogo,
  isValidApplication,
  get_environment_config_value,
  getCommandpostAccountinfoByEmail,
} from "../../services/applications_services";
import { getLOBApps } from "../../services/lob_app_info_services";
import { getCollectionListsByFormName } from "../../services/collection_lists_services";
import { useRealmApp } from "../../providers/RealmApp";
import Select from "react-select";
import customstyles from "../../components/customstyles/react-select-styles";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router";
import GenericSimpleTable from "../../components/gridTable/genericSimpleTable";
import { v4 as uuidv4 } from "uuid";
import { getuserbyauthproviderid } from "../../services/users_services";
const OAuthClient = require("intuit-oauth");

function Editapps() {
  const [loading, setLoading] = useState(false);
  const [isvalid, setIsvalid] = useState(true);
  const [isShowError, setisShowError] = useState(false);
  const [isShowDuplicatevalidation, setisShowDuplicatevalidation] =
    useState(false);
  const [showGeneralSection, setshowGeneralSection] = useState(true);
  const [showAccountSection, setshowAccountSection] = useState(true);
  const [showSecuritySection, setshowSecuritySection] = useState(true);
  const [showTokenSection, setshowTokenSection] = useState(true);
  const [showEndpointSection, setshowEndpointSection] = useState(true);
  const [ownerID, setOwnerID] = useState("");
  const [lobapps, setLOBApps] = useState([]);
  const [applist, setAppList] = useState([]);
  const [isvaliddata, setisvaliddata] = useState(false);
  const [isSuccessForConnect, setisSuccessForConnect] = useState(false);
  const [isSuccessForSave, setisSuccessForSave] = useState(false);
  const [environmentlist, setEnvironmentList] = useState([]);
  const [endpointlist, setEndpointList] = useState([]);
  const [endpointtypelookuplist, setEndpointtypelookuplist] = useState([]);
  let [appid, SetAppid] = useState("");
  const { ids } = useParams();
  const [showsave, setshowsave] = useState(false);
  const [loggedinuserdata, setLoggedinUserdata] = useState();

  const [validatemsg, SetValidatemsg] = useState("");
  const [endpointlistmandatory, setEndpointlistmandatory] = useState("");
  const ctechapp = useRealmApp();
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  const [existAppForConnect, setExistAppForConnect] = useState();
  const [isExistAppForConnect, setIsExistAppForConnect] = useState(false);
  const [existAppForSave, setExistAppForSave] = useState();
  const [isExistAppForSave, setIsExistAppForSave] = useState(false);
  const [isAppIsUpdated, setIsAppIsUpdated] = useState(false);
  const [isconnect, setisConnect] = useState("false");
  const [isendpoint, setisEndpoint] = useState("false");
  const [isparentAppChanged, setisparentAppChanged] = useState("false");

  const [devenv, setDevEnv] = useState("");
  const [commandpostaccountinfo, setcommandpostaccountinfo] = useState();
  const [iscommandpostaccountinfo, setiscommandpostaccountinfo] =
    useState(false);

  const [userinfo, setUserinfo] = useState({
    firstname: "",
    lastname: "",
    email: "",
    role: "",
  });

  const initialappuserinfo = {
    id: "",
    userid: user.sub,
    appdisplayname: "",
    apprefreshtoken: "",
    apptoken: "",
    status: "",
    is_activeuser: "true",
  };
  const [appuserinforow, setAppuserinforow] = useState(initialappuserinfo);

  const initialapiendpoints = {
    id: "",
    userid: user.sub,
    endpointtype: "",
    endpointname: "",
    endpointvalue: "",
    endpointsecret: "",
    endpointminval: "",
  };
  const [apiendpointrow, setApiendpointrow] = useState(initialapiendpoints);

  const [app, setApp] = useState({
    _id: "",
    appname: "",
    appenvironment: "",
    apprelationship: "",
    appautoapproval: "",
    applogo: "",
    appclientid: "",
    appclientsecret: "",
    appredirecturi: "",
    appresthostname: "",
    appaccesstokenhostname: "",
    appaccountid: "",
    appuserinfo: {},
    apiendpoints: [],
    appaccountuserid: "",
  });

  const [logo, setLogo] = useState({
    applogo: false,
  });

  const [errors, setErrors] = useState({
    appname: "",
    appenvironment: "",
    apprelationship: "",
    appautoapproval: "",
    appaccountuserid: "",
  });

  const [errorsisconnect, setErrorsisconnect] = useState({
    appclientid: "",
    appclientsecret: "",
    appredirecturi: "",
    appaccountid: "",
    appdisplayname: "",
    appresthostname: "",
    appaccesstokenhostname: "",
  });

  const [errorsendpoint, setErrorsEndpoint] = useState({
    endpointtype: "",
    endpointname: "",
    endpointvalue: "",
    endpointsecret: "",
    endpointminval: "",
  });

  let AutoApprovalList = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
  ];

  const [relationshipList, setRelationshipList] = useState([]);

  useEffect(() => {
    if (isAuthenticated) {
      setLoggedinUserdata(ctechapp.currentUser);
      if (ids !== "0") {
        getData(ctechapp.currentUser);
        SetAppid(ids);
      }
    }
  }, [appid, devenv]);

  useEffect(() => {
    getisconnectstatus(app.appname);
    getapphostname();
    getEndpointListLoad(app.appname);
  }, [app.appname]);

  useEffect(() => {
    getapphostname();
  }, [app.appenvironment]);

  useEffect(() => {
    loadLOBApps();
    GetEndpointTypeLookup();
    getUserInfoByID();
    get_environment_config();
  }, []);
  useEffect(() => {
    if (logo.applogo !== false) {
      UpdateAppllicationByLogo();
    }
  }, [logo.applogo]);

  async function UpdateAppllicationByLogo() {
    try {
      logo._id = ids;
      updateApplicationByLogo(logo, ctechapp.currentUser, user.sub).then(
        (res) => {
          console.log("logo updated successfully");
        }
      );
    } catch (exception) {
      setLoading(false);
      console.log(exception);
    }
  }

  async function get_environment_config() {
    get_environment_config_value(ctechapp.currentUser, userinfo).then(
      (response) => {
        if (response !== undefined) {
          setDevEnv(response?.toLowerCase());
        }
      }
    );
  }

  async function getUserInfoByID() {
    try {
      setLoading(true);
      getuserbyauthproviderid(user.sub, ctechapp.currentUser).then(
        (response) => {
          let user_info = JSON.parse(response);
          if (response !== undefined || user_info.length !== 0) {
            setUserinfo(user_info[0]);
          }
        }
      );
      setLoading(false);
    } catch (exception) {
      setLoading(false);
      console.log(exception);
    }
  }

  async function loadLOBApps() {
    try {
      getLOBApps(ctechapp.currentUser).then((response) => {
        let lob_info = JSON.parse(response);
        if (response !== undefined || lob_info.length !== 0) {
          setLOBApps(lob_info);

          let _applist = [...new Set(lob_info.map((x) => x.appname))];
          let options = _applist.map(function (row) {
            return { value: row, label: row };
          });
          setAppList(options);

          let _appenvlist = [...new Set(lob_info.map((x) => x.appenv))];
          let options_env = _appenvlist.map(function (row) {
            return { value: row, label: row };
          });
          setEnvironmentList(options_env);
        }
      });
    } catch (exception) {
      setLoading(false);
      console.log(exception);
    }
  }

  async function GetEndpointTypeLookup() {
    await getCollectionListsByFormName(
      "EndpointList",
      ctechapp.currentUser
    ).then((response) => {
      if (response !== undefined) {
        let endpointtype_ddl_info = JSON.parse(response);

        let options = endpointtype_ddl_info.map(function (endpointtype_ddl) {
          return {
            value: endpointtype_ddl.listValue,
            label: endpointtype_ddl.listName,
          };
        });
        setEndpointtypelookuplist(options);
      }
    });
  }

  function getData() {
    setLoading(false);

    getapplicationsbyid(ids, ctechapp.currentUser, user.sub).then(
      (response) => {
        if (response !== undefined) {
          let res_value = JSON.parse(response);
          let res = res_value[0];

          if (
            res.appname !== "Contactmaster" &&
            res.appname !== "CommandPost"
          ) {
            setisConnect("true");
          }

          getapplistbyuserid(ctechapp.currentUser, user.sub).then(
            (response) => {
              if (response !== undefined) {
                let app_list = JSON.parse(response);
                //setList(app_list);
                if (app_list.length > 0) {
                  let parent_app = app_list.filter(
                    (row) => row.apprelationship.toLowerCase() === "parent"
                  );
                  if (parent_app.length === 0) {
                    setRelationshipList([
                      { value: "Parent", label: "Parent" },
                      { value: "Child", label: "Child" },
                    ]);
                  } else if (res?.apprelationship.toLowerCase() === "parent") {
                    setRelationshipList([
                      { value: "Parent", label: "Parent" },
                      { value: "Child", label: "Child" },
                    ]);
                  } else {
                    setRelationshipList([{ value: "Child", label: "Child" }]);
                  }
                }
              }
            }
          );

          // if (ids !== "0" &&  res.appname === "QuickBooks" ) {
          //   setisConnect("false");
          // }
          // if (ids !== "0" &&  res.appname === "Procore" ) {
          //   setisConnect("false");
          // }
          getisconnectstatus(res.appclientid);
          if (res.apiendpoints !== undefined) {
            setEndpointList(res.apiendpoints);
          }
          setAppuserinforow(res.appuserinfo[0]);

          setApp(res);
          switch (res.appname) {
            case "Procore":
              setshowAccountSection(true);
              setshowEndpointSection(false);
              if (devenv === "dev") {
                setshowTokenSection(true);
                setshowSecuritySection(true);
              } else {
                setshowSecuritySection(false);
                setshowTokenSection(false);
              }
              return;

            case "QuickBooks":
              setshowAccountSection(true);
              if (devenv === "dev") {
                setshowTokenSection(true);
                setshowSecuritySection(true);
              } else {
                setshowSecuritySection(false);
                setshowTokenSection(false);
                setshowEndpointSection(false);
              }

              return;
            case "CommandPost":
              setshowAccountSection(true);
              if (devenv === "dev") {
                setshowEndpointSection(true);
              } else {
                setshowSecuritySection(false);
                setshowTokenSection(false);
                setshowEndpointSection(false);
              }
              return;
            case "Contactmaster":
              setshowAccountSection(true);
              setshowSecuritySection(false);
              setshowTokenSection(false);
              setshowEndpointSection(false);
              return;
          }
        }
      }
    );
  }

  function gotosettings() {
    window.location.href = "/dashboard";
  }

  const btnSave = async () => {
    try {
      if (validateForm()) {
        let _appinfo = { ...app };
        const isvalid = await handleDuplicationValidation(_appinfo);
        if (!isvalid) {
          SetValidatemsg("Connect apps already exists");
          setisShowDuplicatevalidation(true);
          return;
        }
        if (appuserinforow.id === "") {
          appuserinforow.id = uuidv4();
        }
        appuserinforow.id === ""
          ? (appuserinforow.status = "created")
          : (appuserinforow.status = "linked");
        _appinfo.appuserinfo = appuserinforow;

        if (endpointlist.length > 0) {
          _appinfo.apiendpoints = endpointlist;
        }

        const data_result =
          await ctechapp.currentUser.functions.updateApplicationByID(
            _appinfo,
            user.sub.trim()
          );
        if (data_result !== null || data_result !== undefined) {
          setIsAppIsUpdated(true);
          // alert(" App Updated successfully");
          // gotosettings();
        }
      }
    } catch (e) {
      setisShowError(true);
      SetValidatemsg(e.error);
    }
  };

  function authorizeUriTrue() {
    try {
      if (app.apprelationship === "Parent" && isparentAppChanged === "true") {
        var appinfo = {
          _id: app._id,
          appname: app.appname,
          appenvironment: app.appenvironment,
          apprelationship: app.apprelationship,
          appuserinfo: app.appuserinfo,
        };
        getAdminVerificationTaskByParent(
          appinfo,
          ctechapp.currentUser,
          user.sub
        ).then((response) => {
          let user_info = JSON.parse(response);
          if (user_info.length !== 0) {
            setExistAppForConnect(user_info[0]);
            if (user_info.length !== 0) {
              setIsExistAppForSave(true);
            } else {
              authorizeUri();
            }
            setIsExistAppForConnect(true);
          } else {
            authorizeUri();
          }
        });
      } else {
        authorizeUri();
      }
    } catch (e) {
      setisShowError(true);
      SetValidatemsg(e.error);
    }
  }
  function btnSaveTrue() {
    try {
      if (app.apprelationship === "Parent") {
        var appinfo = {
          _id: app._id,
          appname: app.appname,
          appenvironment: app.appenvironment,
          apprelationship: app.apprelationship,
          appuserinfo: app.appuserinfo,
        };
        getAdminVerificationTaskByParent(
          appinfo,
          ctechapp.currentUser,
          user.sub
        ).then((response) => {
          let user_info = JSON.parse(response);
          if (user_info.length !== 0) {
            setExistAppForSave(user_info[0]);

            if (user_info[0].apprelationship === "Parent") {
              setIsExistAppForSave(true);
            } else {
              btnSave();
            }
          } else {
            btnSave();
          }
        });
      } else {
        btnSave();
      }
    } catch (e) {
      setisShowError(true);
      SetValidatemsg(e.error);
    }
  }

  const authorizeUri = async (authUri) => {
    if (validateForm()) {
      let _appinfo = { ...app };
      const isvalid = await handleDuplicationValidation(_appinfo);
      if (!isvalid) {
        SetValidatemsg("Connect apps already exists");
        setisShowDuplicatevalidation(true);
        return;
      }
      delete _appinfo?.activity_history;
      delete _appinfo?.applogo;

      if (appuserinforow.id === "") {
        appuserinforow.id = uuidv4();
      }
      appuserinforow.id === ""
        ? (appuserinforow.status = "created")
        : (appuserinforow.status = "linked");
      _appinfo.appuserinfo = appuserinforow;

      if (endpointlist.length > 0) {
        _appinfo.apiendpoints = endpointlist;
      }

      const data_result =
        await ctechapp.currentUser.functions.updateApplicationByID(
          _appinfo,
          user.sub.trim()
        );

      var state_var = "";
      let connectapp = "";
      if (app.appname === "Procore") {
        state_var = {
          //appinfo: _appinfo,
          _id: data_result,
          clientid: app.appclientid.trim(),
          clientsecret: app.appclientsecret.trim(),
          redirecturi: app.appredirecturi.trim(),
          userid: user.sub.trim(),
          emailaddress: userinfo.email.trim(),
          environment: app.appenvironment.trim(),
          granttype: "",
          accesstokenhostname: app.appaccesstokenhostname.trim(),
          apihostname: app.appresthostname.trim(),
          apiappcompanyid: app.appaccountid.trim(),
          apiappcompanydisplayname: appuserinforow.appdisplayname.trim(),
          settingsid: data_result,
          callbackpage: "editapps",
          appname: app.appname.trim(),
        };
        var jsonstate = JSON.stringify(state_var);
      }
      if (app.appname === "QuickBooks") {
        connectapp = "qbconnect";
        state_var = {
          // appinfo: _appinfo,
          _id: data_result,
          clientid: app.appclientid.trim(),
          clientsecret: app.appclientsecret.trim(),
          redirecturi: app.appredirecturi.trim(),
          userid: user.sub.trim(),
          emailaddress: userinfo.email.trim(),
          environment: app.appenvironment.trim(),
          granttype: "",
          accesstokenhostname: app.appaccesstokenhostname.trim(),
          apihostname: app.appresthostname.trim(),
          apiappcompanyid: app.appaccountid.trim(),
          apiappcompanydisplayname: appuserinforow.appdisplayname.trim(),
          settingsid: data_result,
          callbackpage: "editapps",
          appname: app.appname.trim(),
        };
        jsonstate = JSON.stringify(state_var);
        var oauthClient = new OAuthClient({
          clientId: app.appclientid.trim(), // enter the apps `clientId`
          clientSecret: app.appclientsecret.trim(), // enter the apps `clientSecret`
          environment: app.appenvironment.trim(), // enter either `sandbox` or `production`
          redirectUri: app.appredirecturi.trim(), // enter the redirectUri
          logging: false, // by default the value is `false`
        });
        authUri = oauthClient.authorizeUri({
          scope: [OAuthClient.scopes.Accounting],
          state: jsonstate,
        });
        //authUri = `https://appcenter.intuit.com/connect/oauth2?response_type=code&client_id=${apiclientid}&client_secret=${apiclientsecret}&redirect_uri=${apiredirecturi}&scope=${iscope}&state=${jsonstate}`
      } else {
        authUri =
          app.appaccesstokenhostname.trim() +
          `/authorize?response_type=code&client_id=${app.appclientid.trim()}&state=${jsonstate}&redirect_uri=${app.appredirecturi.trim()}`;
        connectapp = "procoreconnect";
      }
      var width = 450,
        height = 730,
        left = window.screen.width / 2 - width / 2,
        top = window.screen.height / 2 - height / 2;
      // Launch Popup using the JS window Object
      var parameters =
        "location=1, width=" +
        width +
        ", height=" +
        height +
        ", top=" +
        top +
        ", left=" +
        left;
      var win = window.open(authUri, connectapp, parameters);
      var pollOAuth = window.setInterval(function () {
        try {
          if (win.document.URL.indexOf("code") !== -1) {
            window.clearInterval(pollOAuth);
            win.close();
          }
        } catch (e) { }
      }, 1000);
    }
  };

  const handleDropDownChange = (selectedOption, updatedata) => {
    const { name, value } = selectedOption.target;
    if (updatedata == "endpoint") {
      setApiendpointrow({ ...apiendpointrow, [name]: value.trim() });
      setErrorsEndpoint({ ...errorsendpoint, [name]: "" });
    } else {
      setApp({ ...app, [name]: value.trim() });
      setErrors({ ...errors, [name]: "" });
      setErrorsisconnect({ ...errorsisconnect, [name]: "" });
    }
    if (name === "apprelationship" && value === "Parent") {
      setisparentAppChanged("true");
    }
  };

  const handleDropDownChangeExistAppForConnect = (selectedOption) => {
    const { name, value } = selectedOption.target;
    setExistAppForConnect({ ...existAppForConnect, [name]: value.trim() });
    setisvaliddata(true);
  };

  const handleDropDownChangeExistAppForSave = (selectedOption) => {
    const { name, value } = selectedOption.target;

    setExistAppForSave({ ...existAppForSave, [name]: value.trim() });
    if (value === "Child") setisvaliddata(true);
    else setisvaliddata(false);
  };
  const handleSubmitForSave = () => {
    if (existAppForSave.apprelationship === "Parent") {
      setIsExistAppForSave(true);
      return;
    }
    setIsExistAppForSave(false);
    updateApplicationRelationshipByID(
      existAppForSave,
      ctechapp.currentUser,
      user.sub
    ).then((res) => {
      btnSave();
      // setisSuccessForSave(true);
    });
  };

  const handleSubmitForConnect = () => {
    setIsExistAppForConnect(false);
    updateApplicationRelationshipByID(
      existAppForConnect,
      ctechapp.currentUser,
      user.sub
    ).then((res) => {
      authorizeUri();
      //  setisSuccessForConnect(true);
    });
  };
  const goToAppListPage = () => {
    setIsAppIsUpdated(false);
    gotosettings();
  };
  const handleSucessSweetAlertConnect = () => {
    setIsExistAppForConnect(false);
  };
  const handleSucessSweetAlertSave = () => {
    setIsExistAppForSave(false);
  };
  const handleSucessSweetAlertForConnect = () => {
    setisSuccessForConnect(false);
    authorizeUri();
  };

  const handleSucessSweetAlertForSave = () => {
    setisSuccessForSave(false);
    btnSave();
  };

  const handleSweetAlertValidationMessage = () => {
    setisShowDuplicatevalidation(false);
    setisShowError(false);
    SetValidatemsg("");
  };
  const handleToRemoveAppLogo = () => {
    var _app = { ...app };
    _app.applogo = "";
    setApp(_app);
    // _app = { ...logo };
    // _app.applogo = "";
    // setLogo(_app);
  };
  const getapphostname = () => {
    let _appdata = { ...app };

    let api_info = lobapps.filter(
      (record) =>
        record.appname === app.appname && record.appenv === app.appenvironment
    );
    if (api_info.length > 0) {
      _appdata.appresthostname = api_info[0].apphostname;
      _appdata.appaccesstokenhostname = api_info[0].apptokenhostname;
      setApp(_appdata);
    }
  };

  const getEndpointListLoad = (appname) => {
    if (ids === "0" && appname === "CommandPost") {
      getCollectionListsByFormName("EndpointList", ctechapp.currentUser).then(
        (response) => {
          if (response !== undefined) {
            var _endpointlist = JSON.parse(response);
            var endointdataList = [];
            if (_endpointlist.length > 0) {
              _endpointlist.map((endpointinfo) => {
                var _apiendpointrow = {};
                _apiendpointrow.id = uuidv4();
                _apiendpointrow.userid = user.sub;
                _apiendpointrow.endpointtype = endpointinfo.listValue;
                _apiendpointrow.endpointname = endpointinfo.endpointName;
                _apiendpointrow.endpointvalue = endpointinfo.endpointValue;
                _apiendpointrow.endpointsecret = endpointinfo.endpointSecret;
                _apiendpointrow.endpointminval = "";
                endointdataList.push(_apiendpointrow);
              });

              setEndpointList(endointdataList);
            }
          }
        }
      );
    } else if (ids === "0" && appname !== "CommandPost") {
      setEndpointList([]);
    }
  };

  const getisconnectstatus = (appname) => {
    const api_info = lobapps.filter((record) => record.appname === appname);

    if (api_info.length > 0) {
      setisConnect(api_info[0].isconnect);
      setisEndpoint(api_info[0].isendpoint);
    }
  };

  const handleChange = (event, updatedata) => {
    SetValidatemsg("");

    if (updatedata === "endpoint") {
      setApiendpointrow({
        ...apiendpointrow,
        [event.target.name]: event.target.value.trim(),
      });
      setErrorsEndpoint({
        ...errorsendpoint,
        [event.target.name]: "",
      });
    } else if (updatedata === "appuser") {
      setAppuserinforow({
        ...appuserinforow,
        [event.target.name]: event.target.value.trim(),
      });

      setErrorsisconnect({ ...errorsisconnect, [event.target.name]: "" });
    } else {
      const path = event.target.name.split(".");
      const finalProp = path.pop();
      const newuser = { ...app };
      let pointer = newuser;
      path.forEach((el) => {
        pointer[el] = { ...pointer[el] };
        pointer = pointer[el];
      });
      pointer[finalProp] =
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value.trim();

      setApp(newuser);
      setErrors({ ...errors, [event.target.name]: "" });
      setErrorsisconnect({ ...errorsisconnect, [event.target.name]: "" });
    }
  };

  const EndpointvalidateForm = () => {
    try {
      let valid = false;
      valid = handleFieldEndpointRequiredValidation();
      Object.values(errorsendpoint).forEach((val) => {
        if (val.length > 0) valid = false;
      });

      return valid;
    } catch (error) {
      return false;
    }
  };

  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });

      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleDuplicationValidation = async (_appinfo) => {
    const data_result = await ctechapp.currentUser.functions.isValidApplication(
      _appinfo,
      user.sub.trim()
    );
    return data_result;
  };
  const handleFieldRequiredValidation = () => {
    let iserror = "";

    // Auto mandatory field validation
    var errors1 = { ...errors };
    var errors1 = { ...errors };

    setErrors({
      ...errors,
      appname:
        app.appname === "" ? (iserror = "App name required") : errors.appname,
      appenvironment:
        app.appenvironment === ""
          ? (iserror = "Environment required")
          : errors.appenvironment,
      apprelationship:
        app.apprelationship === ""
          ? (iserror = "Relationship required")
          : errors.apprelationship,
      appautoapproval:
        app.appautoapproval === ""
          ? (iserror = "Auto approval required")
          : errors.appautoapproval,
      appaccountuserid:
        app.appname?.toLowerCase() === "commandpost" &&
          app.appaccountuserid === ""
          ? (iserror = " Account user ID  required")
          : errors.appaccountuserid,
    });

    // secutiry and token field validation
    if (isconnect === "true") {
      setErrorsisconnect({
        ...errorsisconnect,

        appclientid:
          app.appclientid === ""
            ? (iserror = "Client ID required")
            : errorsisconnect.appclientid,
        appclientsecret:
          app.appclientsecret === ""
            ? (iserror = "Client secret required")
            : errorsisconnect.appclientsecret,
        appredirecturi:
          app.appredirecturi === ""
            ? (iserror = "Redirect URL required")
            : errorsisconnect.appredirecturi,
        appaccountid:
          app.appaccountid === ""
            ? (iserror = "Account ID required")
            : errorsisconnect.appaccountid,
        appdisplayname:
          appuserinforow.appdisplayname === ""
            ? (iserror = "Account displayname required")
            : errorsisconnect.appdisplayname,
        appresthostname:
          app.appresthostname === ""
            ? (iserror = "App hostname required")
            : errorsisconnect.appresthostname,
        appaccesstokenhostname:
          app.appaccesstokenhostname === ""
            ? (iserror = "App token hostname required")
            : errorsisconnect.appaccesstokenhostname,
      });
    }

    if (isendpoint === "true") {
      setErrorsEndpoint({
        ...errorsendpoint,
        endpointminval:
          endpointlist.length === 0
            ? (iserror = "Atleast one list value required")
            : errorsendpoint.endpointminval,
      });
    }

    return iserror.length === 0;
  };

  const handleFieldEndpointRequiredValidation = () => {
    let iserror = "";
    setErrorsEndpoint({
      ...errorsendpoint,
      endpointtype:
        apiendpointrow.endpointtype === ""
          ? (iserror = "Endpoint type required")
          : errorsendpoint.endpointtype,
      endpointname:
        apiendpointrow.endpointname === ""
          ? (iserror = "Endpoint name required")
          : errorsendpoint.endpointname,
      endpointvalue:
        apiendpointrow.endpointvalue === ""
          ? (iserror = "Endpoint value required")
          : errorsendpoint.endpointvalue,
      endpointsecret:
        apiendpointrow.endpointsecret === ""
          ? (iserror = "Endpoint secret required")
          : errorsendpoint.endpointsecret,
    });

    return iserror.length === 0;
  };

  const addendpointinfo = async () => {
    if (EndpointvalidateForm()) {
      setEndpointlistmandatory("");

      if (endpointlist !== null) {
        const objIndex = endpointlist.findIndex(
          (obj) => obj.endpointtype === apiendpointrow.endpointtype
        );
        if (objIndex !== -1) {
          const rowsInput = [...endpointlist];
          rowsInput[objIndex] = apiendpointrow;
          setEndpointList(rowsInput);
          setApiendpointrow(initialapiendpoints);
        } else {
          setEndpointList([...endpointlist, apiendpointrow]);
          setApiendpointrow(initialapiendpoints);
        }
      } else {
        let _endpointlist = [];
        _endpointlist.push(apiendpointrow);
        setEndpointList(_endpointlist);
        setApiendpointrow(initialapiendpoints);
      }
      setErrorsEndpoint({
        ...errorsendpoint,
        ["endpointminval"]: "",
      });
    }
  };
  const clearenvironmentinfo = async (e) => {
    e.preventDefault();
    setApiendpointrow(initialapiendpoints);
  };

  //state variables
  const history = useHistory();
  //table functions
  const data = useMemo(() => [...endpointlist], [endpointlist]);
  const appColumns = useMemo(
    () => [
      {
        Header: "userid",
        accessor: "userid",
        sortable: false,
        width: "20%",
      },

      {
        Header: "Endpoint type ",
        accessor: "endpointtype",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },
      {
        Header: "Endpoint name",
        accessor: "endpointname",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
      },
      {
        Header: "Endpoint value",
        accessor: "endpointvalue",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
      },
      {
        Header: "Endpoint secret",
        accessor: "endpointsecret",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
      },
      {
        Header: "Action",
        Filter: "",
        filter: "",
        Cell: (rowdata) => {
          const onEditItemClick = () => {
            const dataCopy = rowdata.data;
            if (dataCopy.length > 0) {
              setApiendpointrow(rowdata.row.original);
            }
          };
          const onDeleteItemClick = () => {
            const dataCopy = rowdata.data;
            if (dataCopy.length > 0) {
              dataCopy.splice(rowdata.row.index, 1);
              setEndpointList(dataCopy);
            }
          };
          return (
            <div className="d-flex gap-4">
              <span
                className="btn btn-lg btn-icon btn-circle btn-primary "
                onClick={onEditItemClick}
              >
                {/* <i className="fas fa-2x  fa-pencil"></i> */}
                Edit
              </span>
              <span
                className="btn btn-lg btn-icon btn-circle btn-danger"
                onClick={onDeleteItemClick}
              >
                {/* <i className="fas fa-2x  fa-trash-can"></i> */}
                Delete
              </span>
            </div>
          );
        },
      },
    ],
    []
  );

  const hiddenappColumns = ["userid"];

  return (
    <div>
      <h3 align="center">Edit connect apps</h3>
      <Form>
        {loading && <Loading />}
        {showGeneralSection && (
          <div id="generalsection">
            <div
              className="row p-2 mb-3"
              style={{
                "background-color": "#f2f2f2",
              }}
            >
              <label className="fw-bolder">General section</label>
            </div>
          </div>
        )}
        <div className="row">
          <Form.Group className=" col-sm-12 col-md-4 col-lg-4 mb-3">
            <Form.Label>App name</Form.Label>
            <Select
              name="appname"
              options={applist}
              isDisabled={true}
              className="form-control"
              isSearchable={true}
              styles={customstyles}
              value={applist.filter((record) => record.value === app.appname)}
              onChange={(val) => {
                handleDropDownChange(
                  {
                    target: {
                      name: "appname",
                      value: val.value,
                    },
                  },
                  "contact"
                );
              }}
            ></Select>
            {errors.appname.length > 0 && (
              <small className="form-check-label text-danger" htmlFor="appname">
                {errors.appname}
              </small>
            )}
          </Form.Group>
          <Form.Group className="col-sm-12 col-md-4 col-lg-4 mb-3">
            <Form.Label>Environment</Form.Label>

            <Select
              name="appenvironment"
              options={environmentlist}
              isDisabled={true}
              className="form-control"
              isSearchable={true}
              styles={customstyles}
              value={environmentlist.filter(
                (record) => record.value === app.appenvironment
              )}
              onChange={(val) => {
                handleDropDownChange(
                  {
                    target: {
                      name: "appenvironment",
                      value: val.value,
                    },
                  },
                  "contact"
                );
              }}
            ></Select>
            {errors.appenvironment.length > 0 && (
              <small
                className="form-check-label text-danger"
                htmlFor="appenvironment"
              >
                {errors.appenvironment}
              </small>
            )}
          </Form.Group>

          <Form.Group className="col-sm-12 col-md-2 col-lg-2 mb-2">
            <Form.Label>Relationship</Form.Label>

            <Select
              name="apprelationship"
              options={relationshipList}
              className="form-control"
              isSearchable={true}
              styles={customstyles}
              value={relationshipList.filter(
                (record) => record.value === app.apprelationship
              )}
              onChange={(val) => {
                handleDropDownChange(
                  {
                    target: {
                      name: "apprelationship",
                      value: val.value,
                    },
                  },
                  "contact"
                );
              }}
            ></Select>
            {errors.apprelationship.length > 0 && (
              <small
                className="form-check-label text-danger"
                htmlFor="apprelationship"
              >
                {errors.apprelationship}
              </small>
            )}
          </Form.Group>
          <Form.Group className="col-sm-12 col-md-2 col-lg-2 mb-2">
            <Form.Label>Auto approval</Form.Label>

            <Select
              name="appautoapproval"
              options={AutoApprovalList}
              className="form-control"
              isSearchable={true}
              styles={customstyles}
              value={AutoApprovalList.filter(
                (record) => record.value === app.appautoapproval
              )}
              onChange={(val) => {
                handleDropDownChange(
                  {
                    target: {
                      name: "appautoapproval",
                      value: val.value,
                    },
                  },
                  "contact"
                );
              }}
            ></Select>
            {errors.appautoapproval.length > 0 && (
              <small
                className="form-check-label text-danger"
                htmlFor="appautoapproval"
              >
                {errors.appautoapproval}
              </small>
            )}
          </Form.Group>
          {ids !== "0" && devenv === "dev" && (
            <Form.Group className="col-sm-12 col-md-2 col-lg-2 mb-2">
              <Form.Label>App logo</Form.Label>
              <div className="row">
                <div className="col-3">
                  <Button
                    style={{ padding: "1px 6px" }}
                    onClick={() => handleToRemoveAppLogo()}
                  >
                    Clear
                  </Button>
                </div>
                <div className=" col-1">
                  {/* <input
                  type="file"
                  onchange={({ base64 }: { base64: string }) => {
                    setApp({ ...app, ["applogo"]: base64 });
                   
                  }}
                /> */}
                  <FileBase
                    type="file"
                    multiple={false}
                    onDone={({ base64 }: { base64: string }) => {
                      setApp({ ...app, ["applogo"]: base64 });
                      // setLogo({ ...logo, ["applogo"]: base64 });
                    }}
                  />
                </div>
              </div>

              <img
                src={
                  app.applogo === "" || app.applogo === undefined
                    ? "../assets/img/noimg1.png"
                    : app.applogo
                }
                className="rounded mx-auto d-block mw-100 mt-2"
                width="250"
                height="75"
              />
            </Form.Group>
          )}
        </div>

        {showAccountSection && (
          <div id="accountsection">
            <div
              className="row p-2 mb-3"
              style={{
                "background-color": "#f2f2f2",
              }}
            >
              <label className="fw-bolder">Account section</label>
            </div>
            <div className="row">
              {((app.appname?.toLowerCase() !== "contactmaster" &&
                app.appname?.toLowerCase() !== "commandpost") ||
                devenv === "dev") && (
                  <Form.Group className="col-sm-12 col-md-3 col-lg-3 mb-3">
                    <Form.Label>Account ID</Form.Label>
                    <input
                      type="text"
                      name="appaccountid"
                      id="appaccountid"
                      className="form-control"
                      onChange={handleChange}
                      value={app.appaccountid}
                    />
                    {errorsisconnect.appaccountid.length > 0 && (
                      <small
                        className="form-check-label text-danger"
                        htmlFor="appaccountid"
                      >
                        {errorsisconnect.appaccountid}
                      </small>
                    )}
                  </Form.Group>
                )}

              <Form.Group className="col-sm-12 col-md-3 col-lg-3 mb-3">
                <Form.Label>Account display name</Form.Label>
                <label className="text-danger">*</label>
                <input
                  type="text"
                  name="appdisplayname"
                  id="appdisplayname"
                  className="form-control"
                  onChange={(e) => handleChange(e, "appuser")}
                  value={appuserinforow.appdisplayname}
                />
                {errorsisconnect.appdisplayname.length > 0 && (
                  <small
                    className="form-check-label text-danger"
                    htmlFor="appdisplayname"
                  >
                    {errorsisconnect.appdisplayname}
                  </small>
                )}
              </Form.Group>
              {app.appname.toLowerCase() === "commandpost" &&
                devenv === "dev" && (
                  <Form.Group className="col-sm-12 col-md-3 col-lg-3 mb-3">
                    <Form.Label> Account user ID</Form.Label>
                    <label className="text-danger">*</label>
                    <input
                      type="text"
                      name="appaccountuserid"
                      id="appaccountuserid"
                      className="form-control"
                      onChange={(e) => handleChange(e, "app")}
                      value={app.appaccountuserid}
                    />
                    {errors.appaccountuserid.length > 0 && (
                      <small
                        className="form-check-label text-danger"
                        htmlFor="appaccountuserid"
                      >
                        {errors.appaccountuserid}
                      </small>
                    )}
                  </Form.Group>
                )}
            </div>
          </div>
        )}
        {showSecuritySection && (
          <div id="securitysection">
            <div
              className="row p-2 mb-3"
              style={{
                "background-color": "#f2f2f2",
              }}
            >
              <label className="fw-bolder">Security section</label>
            </div>
            <div className="row">
              <Form.Group className="col-sm-12 col-md-6 col-lg-6 mb-3">
                <Form.Label>Client ID</Form.Label>
                <input
                  type="text"
                  name="appclientid"
                  id="appclientid"
                  className="form-control"
                  onChange={handleChange}
                  value={app.appclientid}
                />
                {errorsisconnect.appclientid.length > 0 && (
                  <small
                    className="form-check-label text-danger"
                    htmlFor="appclientid"
                  >
                    {errorsisconnect.appclientid}
                  </small>
                )}
              </Form.Group>

              <Form.Group className=" col-sm-12 col-md-6 col-lg-6 mb-3">
                <Form.Label>Client secret</Form.Label>
                <input
                  type="text"
                  name="appclientsecret"
                  id="appclientsecret"
                  className="form-control"
                  onChange={handleChange}
                  value={app.appclientsecret}
                />
                {errorsisconnect.appclientsecret.length > 0 && (
                  <small
                    className="form-check-label text-danger"
                    htmlFor="appclientsecret"
                  >
                    {errorsisconnect.appclientsecret}
                  </small>
                )}
              </Form.Group>

              <Form.Group className="col-sm-12 col-md-6 col-lg-6 mb-3">
                <Form.Label>Redirect URL</Form.Label>
                <input
                  type="text"
                  name="appredirecturi"
                  id="appredirecturi"
                  className="form-control"
                  onChange={handleChange}
                  value={app.appredirecturi}
                />
                {errorsisconnect.appredirecturi.length > 0 && (
                  <small
                    className="form-check-label text-danger"
                    htmlFor="appredirecturi"
                  >
                    {errorsisconnect.appredirecturi}
                  </small>
                )}
              </Form.Group>
            </div>
          </div>
        )}
        {showTokenSection && (
          <div id="tokensection">
            <div
              className="row p-2 mb-3"
              style={{
                "background-color": "#f2f2f2",
              }}
            >
              <label className="fw-bolder">Token section</label>
            </div>
            <div className="row">
              <Form.Group className="col-sm-12 col-md-6 col-lg-6 mb-3">
                <Form.Label>App host name</Form.Label>
                <input
                  type="text"
                  name="appresthostname"
                  id="appresthostname"
                  className="form-control"
                  onChange={handleChange}
                  value={app.appresthostname}
                />
                {errorsisconnect.appresthostname.length > 0 && (
                  <small
                    className="form-check-label text-danger"
                    htmlFor="appresthostname"
                  >
                    {errorsisconnect.appresthostname}
                  </small>
                )}
              </Form.Group>

              <Form.Group className="col-sm-12 col-md-6 col-lg-6 mb-3">
                <Form.Label>App token host name</Form.Label>
                <input
                  type="text"
                  name="appaccesstokenhostname"
                  id="appaccesstokenhostname"
                  className="form-control"
                  onChange={handleChange}
                  value={app.appaccesstokenhostname}
                />
                {errorsisconnect.appaccesstokenhostname.length > 0 && (
                  <small
                    className="form-check-label text-danger"
                    htmlFor="appaccesstokenhostname"
                  >
                    {errorsisconnect.appaccesstokenhostname}
                  </small>
                )}
              </Form.Group>

              <Form.Group className="col-sm-12 col-md-6 col-lg-6 mb-3">
                <Form.Label>Access token</Form.Label>
                <input
                  type="text"
                  name="apptoken"
                  id="apptoken"
                  className="form-control"
                  disabled="true"
                  onChange={handleChange}
                  value={appuserinforow.apptoken}
                />
              </Form.Group>
              <Form.Group className="col-sm-12 col-md-6 col-lg-6 mb-3">
                <Form.Label>Refresh token</Form.Label>
                <input
                  type="text"
                  name="apprefreshtoken"
                  id="apprefreshtoken"
                  disabled="true"
                  className="form-control"
                  onChange={handleChange}
                  value={appuserinforow.apprefreshtoken}
                />
              </Form.Group>
            </div>
          </div>
        )}
        {showEndpointSection && (
          <div id="endpointsection">
            <div
              className="row p-2 mb-3"
              style={{
                "background-color": "#f2f2f2",
              }}
            >
              <label className="fw-bolder">Endpoint section</label>
            </div>
            <div className="row">
              <Form.Group className="col-sm-12 col-md-4 col-lg-2 mb-3">
                <Form.Label>Endpoint type</Form.Label>
                <Select
                  name="endpointtype"
                  options={endpointtypelookuplist}
                  className="form-control"
                  isSearchable={true}
                  styles={customstyles}
                  value={endpointtypelookuplist.filter(
                    (record) => record.value === apiendpointrow.endpointtype
                  )}
                  onChange={(val) => {
                    handleDropDownChange(
                      {
                        target: {
                          name: "endpointtype",
                          value: val.value,
                        },
                      },
                      "endpoint"
                    );
                  }}
                ></Select>
                {errorsendpoint.endpointtype.length > 0 && (
                  <small
                    className="form-check-label text-danger"
                    htmlFor="endpointtype"
                  >
                    {errorsendpoint.endpointtype}
                  </small>
                )}
              </Form.Group>

              <Form.Group className="col-sm-12 col-md-4 col-lg-2 mb-3">
                <Form.Label>Endpoint name</Form.Label>
                <input
                  type="text"
                  name="endpointname"
                  id="endpointname"
                  className="form-control"
                  onChange={(e) => handleChange(e, "endpoint")}
                  value={apiendpointrow.endpointname}
                />
                {errorsendpoint.endpointname.length > 0 && (
                  <small
                    className="form-check-label text-danger"
                    htmlFor="endpointname"
                  >
                    {errorsendpoint.endpointname}
                  </small>
                )}
              </Form.Group>

              <Form.Group className="col-sm-12 col-md-4 col-lg-3 mb-3">
                <Form.Label>Endpoint value</Form.Label>
                <input
                  type="text"
                  name="endpointvalue"
                  id="endpointvalue"
                  className="form-control"
                  onChange={(e) => handleChange(e, "endpoint")}
                  value={apiendpointrow.endpointvalue}
                />
                {errorsendpoint.endpointvalue.length > 0 && (
                  <small
                    className="form-check-label text-danger"
                    htmlFor="endpointvalue"
                  >
                    {errorsendpoint.endpointvalue}
                  </small>
                )}
              </Form.Group>
              <Form.Group className="col-sm-12 col-md-4 col-lg-5 mb-3">
                <Form.Label>Endpoint secret</Form.Label>
                <input
                  type="text"
                  name="endpointsecret"
                  id="endpointsecret"
                  className="form-control"
                  onChange={(e) => handleChange(e, "endpoint")}
                  value={apiendpointrow.endpointsecret}
                />
                {errorsendpoint.endpointsecret.length > 0 && (
                  <small
                    className="form-check-label text-danger"
                    htmlFor="endpointsecret"
                  >
                    {errorsendpoint.endpointsecret}
                  </small>
                )}
              </Form.Group>
              <div className="row">
                <div className="d-flex gap-3 justify-content-end align-items-center m-2">
                  <Button
                    color="default"
                    id="clear"
                    size="md"
                    onClick={clearenvironmentinfo}
                  >
                    Clear
                  </Button>
                  <Button
                    className=""
                    color="default"
                    size="md"
                    onClick={addendpointinfo}
                  >
                    Add to list
                  </Button>
                  {errorsendpoint.endpointminval > 0 && (
                    <small
                      className="form-check-label text-danger"
                      htmlFor="endpointminval"
                    >
                      {errorsendpoint.endpointminval}
                    </small>
                  )}
                </div>
              </div>
              <div className="row p-1 mt-3">
                <GenericSimpleTable
                  gcolumns={appColumns}
                  gdata={
                    endpointlist.length > 0 ? JSON.stringify(endpointlist) : ""
                  }
                  ghiddencolumns={hiddenappColumns}
                />
              </div>
            </div>
          </div>
        )}
        <div className="d-flex gap-3 justify-content-end align-items-center m-2">
          <Button variant="secondary" onClick={() => gotosettings()}>
            Close
          </Button>
          <Button
            variant="primary"
            id="save"
            onClick={() => btnSaveTrue()}
            className="btn btn-success"
          >
            Save
          </Button>
          {isconnect === "true" ? (
            <Button
              variant="primary"
              id="procoreToken"
              onClick={() => authorizeUriTrue()}
              className="btn btn-success"
            >
              {ids === "0" ? "Connect" : "Reconnect"}
            </Button>
          ) : (
            ""
          )}
        </div>
        <div>
          {isAppIsUpdated && (
            <SweetAlert
              success
              style={{ color: "black" }}
              title=""
              onConfirm={goToAppListPage}
            >
              <div>App updated successfully</div>
            </SweetAlert>
          )}

          {isSuccessForConnect && (
            <SweetAlert
              success
              style={{ color: "black" }}
              title=""
              onConfirm={handleSucessSweetAlertForConnect}
            >
              <div>{existAppForConnect.appname} updated successfully</div>
            </SweetAlert>
          )}
          {isSuccessForSave && (
            <SweetAlert
              success
              style={{ color: "black" }}
              title=""
              onConfirm={handleSucessSweetAlertForSave}
            >
              <div>{existAppForSave.appname} was updated successfully</div>
            </SweetAlert>
          )}
          {isExistAppForConnect && existAppForConnect !== undefined && (
            <SweetAlert
              warning
              style={{ color: "black" }}
              confirmBtnText="Submit"
              cancelBtnText="Cancel"
              showCancel={false}
              showConfirm={false}
              onConfirm={handleSubmitForConnect}
              onCancel={handleSucessSweetAlertConnect}
            >
              <div>
                {" "}
                <div className="mb-3 font-weight-bold text-warning">
                  Parent app already exists
                </div>
                <div className="row mb-5">
                  <div className="col-sm-3 col-md-6 col-lg-6">
                    <label>App name</label>
                    <input
                      className="form-control mt-2 mb-2"
                      isSearchable={true}
                      disabled={true}
                      value={existAppForConnect.appname}
                    />
                  </div>
                  <div className="col-sm-3 col-md-6 col-lg-6">
                    <label>Relationship</label>
                    <Select
                      options={relationshipList}
                      className="form-control mt-2 mb-2"
                      styles={customstyles}
                      value={relationshipList.filter(
                        (record) =>
                          record.value === existAppForConnect.apprelationship
                      )}
                      onChange={(val) => {
                        handleDropDownChangeExistAppForConnect({
                          target: {
                            name: "apprelationship",
                            value: val.value,
                          },
                        });
                      }}
                    ></Select>
                  </div>
                </div>
                <div>
                  {" "}
                  <Button
                    variant="primary"
                    id="procoreToken"
                    disabled={!isvaliddata}
                    onClick={() => handleSubmitForConnect()}
                    className="btn btn-success"
                  >
                    Submit
                  </Button>
                  <Button
                    variant="primary"
                    id="procoreToken"
                    onClick={() => handleSucessSweetAlertConnect()}
                    className="btn btn-success ms-2"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </SweetAlert>
          )}
          {isExistAppForSave && existAppForSave !== undefined && (
            <SweetAlert
              warning
              style={{ color: "black" }}
              confirmBtnText="Submit"
              cancelBtnText="Cancel"
              showCancel={false}
              showConfirm={false}
              onConfirm={handleSubmitForSave}
              onCancel={handleSucessSweetAlertSave}
            >
              <div>
                {" "}
                <div className="mb-3 font-weight-bold text-warning">
                  Parent app already exists
                </div>
                <div className="row mb-5">
                  <div className="col-sm-3 col-md-6 col-lg-6">
                    <label>App name</label>
                    <input
                      className="form-control mt-2 mb-2"
                      isSearchable={true}
                      disabled={true}
                      value={existAppForSave.appname}
                    />
                  </div>
                  <div className="col-sm-3 col-md-6 col-lg-6">
                    <label>Relationship</label>
                    <Select
                      options={relationshipList}
                      className="form-control mt-2 mb-2"
                      styles={customstyles}
                      value={relationshipList.filter(
                        (record) =>
                          record.value === existAppForSave.apprelationship
                      )}
                      onChange={(val) => {
                        handleDropDownChangeExistAppForSave({
                          target: {
                            name: "apprelationship",
                            value: val.value,
                          },
                        });
                      }}
                    ></Select>
                  </div>
                </div>
                <div>
                  {" "}
                  <Button
                    variant="primary"
                    id="procoreToken"
                    disabled={!isvaliddata}
                    onClick={() => handleSubmitForSave()}
                    className="btn btn-success"
                  >
                    Submit
                  </Button>
                  <Button
                    variant="primary"
                    id="procoreToken"
                    onClick={() => handleSucessSweetAlertSave()}
                    className="btn btn-success ms-2"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </SweetAlert>
          )}

          {isShowDuplicatevalidation && (
            <SweetAlert
              warning
              style={{ color: "black" }}
              title="Validation info"
              onConfirm={handleSweetAlertValidationMessage}
            >
              <div>{validatemsg}</div>
            </SweetAlert>
          )}
          {isShowError && (
            <SweetAlert
              danger
              style={{ color: "black" }}
              title="Error info"
              onConfirm={handleSweetAlertValidationMessage}
            >
              <div>{validatemsg}</div>
            </SweetAlert>
          )}
        </div>
      </Form>
    </div>
  );
}

export default Editapps;
