import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useRealmApp } from "../../providers/RealmApp";
import Loading from "../../components/Loading";
import {
  getcompanylistbyuseridandappname,
  getapisettingbyappname_user,
} from "../../services/api_settings_services";
import { getapiusersettingsbyappname_userid_companyid } from "../../services/api_user_settings_services";
import { useAuth0 } from "@auth0/auth0-react";

import { getProcoreprojectlistbycompanyid } from "../../services/procore_project_services";

const _appname = "Procore";

function ProcoreProjects() {
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  var [selectedcompany, setSelectedCompany] = useState("0");
  var [companylist, setCompanyList] = useState([]);
  var [projectlist, setProjectList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { companyid } = useParams();
  const [errormsg, setErrorMsg] = useState("");
  const ctechapp = useRealmApp();
  const [loggedinuserdata, setLoggedinUserdata] = useState();

  useEffect(() => {
    setLoggedinUserdata(ctechapp.currentUser);
    getCompanyListDataData(ctechapp.currentUser);
    setSelectedCompany(companyid);
    handleCompanyDefault(ctechapp.currentUser, companyid);
  }, []);

  useEffect(() => {
    if (projectlist.length === 0 && selectedcompany === "0") {
      setErrorMsg("");
    } else if (
      projectlist.length === 0 &&
      selectedcompany !== "0" &&
      loading === false
    ) {
      setErrorMsg("User does not have access for projects.");
    } else if (loading === true) {
      setErrorMsg("");
    }
  }, [projectlist, loading]);

  async function getCompanyListDataData(userInfo) {
    if (userInfo === "2") {
      getcompanylistbyuseridandappname(_appname, userInfo, user.sub).then(
        (response) => {
          if (response !== undefined) {
            let companylist = JSON.parse(response);
            console.log("companylist : ", companylist);
            setCompanyList(companylist);
          }
        }
      );
    } else {
      getapisettingbyappname_user(
        _appname,
        user.sub,
        ctechapp.currentUser
      ).then((response) => {
        if (response !== undefined) {
          let company_info = JSON.parse(response);

          const companyuserList = [];
          for (const apicompany of company_info) {
            if (apicompany.users.length !== 0) {
              const {
                users: [{ firstname, lastname }],
              } = apicompany;
              apicompany["displaynamevalue"] =
                " (" + firstname + " " + lastname + ")";
            } else {
              apicompany["displaynamevalue"] = "";
            }
            companyuserList.push(apicompany);
          }
          setCompanyList(companyuserList);

          setLoading(false);
        }
      });
    }
  }

  function getDataappinfo(companyid) {
    getapiusersettingsbyappname_userid_companyid(
      _appname,
      user.sub,
      companyid,
      ctechapp.currentUser,
      user.sub
    ).then((response) => {
      if (response !== undefined) {
        let company_info = JSON.parse(response);
        let res = company_info[0];

        setProjectList([]);
        setErrorMsg("");
        setSelectedCompany(companyid);

        getProjectListData(
          user.sub,
          companyid,
          res.apiaccesstokenhostname,
          res.apiclientid,
          res.apiclientsecret,
          res.apirefreshtoken,
          res.apiapptoken,
          res.apiredirecturi,
          res.restapihostname
        );
      }
    });
  }

  async function getProjectListData(
    procoreUserID,
    procoreCompanyID,
    procoreAccessTokenHostName,
    apiclientid,
    apiclientsecret,
    apirefreshtoken,
    apiapptoken,
    apiredirecturi,
    procoreRestAPIHostName
  ) {
    getProcoreprojectlistbycompanyid(
      procoreUserID,
      procoreCompanyID,
      procoreAccessTokenHostName,
      apiclientid,
      apiclientsecret,
      apirefreshtoken,
      apiapptoken,
      apiredirecturi,
      procoreRestAPIHostName,
      ctechapp.currentUser,
      user.sub
    ).then((response) => {
      if (response !== undefined || response.length !== 0) {
        setProjectList(response);
        setLoading(false);
      }
    });
  }

  const handleCompanyDefault = (Userinfo, id) => {
    if (id !== "0") {
      setLoading(true);
      getDataappinfo(id);
    }
  };

  const handleCompanyChange = (event) => {
    setLoading(true);
    setErrorMsg("");
    getDataappinfo(event.target.value);
  };

  return companylist.length > 0 ? (
    <div>
      {loading && <Loading />}
      <h3>Procore Projects</h3>
      <div>
        <span> Account Display Name </span>

        <select value={selectedcompany} onChange={handleCompanyChange}>
          <option>Select company</option>
          {companylist.map((companylist) => (
            <option value={companylist.apiappcompanyid}>
              {companylist.appdisplayname}
              {companylist.displaynamevalue}
            </option>
          ))}
        </select>
      </div>

      <Table striped bordered hover>
        <thead className="tablehead">
          <tr>
            <th>Actions</th>
            <th>Project#</th>
            <th>Project Name</th>
            <th>Total Value</th>
            <th>Start Date</th>
            <th>Completion Date</th>
            <th>Actual Start Date</th>
            <th>Projected Finish Date</th>
            <th>Description</th>
            <th>Isactive</th>
          </tr>
        </thead>
        <tbody>
          {projectlist.map((row) => (
            <tr>
              <td>
                {" "}
                <Link
                  to={`/editproject/${row.id}/${selectedcompany}`}
                  className="card-link"
                >
                  Edit
                </Link>{" "}
              </td>
              <td width="5">{row.project_number}</td>
              <td> {row.name}</td>
              <td> {row.total_value}</td>
              <td>{row.start_date}</td>
              <td>{row.completion_date}</td>
              <td>{row.actual_start_date}</td>
              <td>{row.projected_finish_date}</td>
              <td>{row.description}</td>
              <td>True</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <label for="error1">{errormsg}</label>
    </div>
  ) : (
    <div>Procore Project doesn't have an access.</div>
  );
}

export default ProcoreProjects;
