export const getPendingCreationTask = async (Ctechapp, loggedin_by) => {
  try {
    const data = await Ctechapp.functions.getPendingCreationTask(loggedin_by);
    if (data !== undefined) {
      //console.log("data of getapplistbyuserid :" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

//getPendingCreationTaskByID
export const getPendingCreationTaskByID = async (id, Ctechapp, loggedin_by) => {
  try {
    console.log("getPendingCreationTaskByID Services", id, loggedin_by);
    const data = await Ctechapp.functions.getPendingCreationTaskByID(
      id,
      loggedin_by
    );
    if (data !== undefined) {
      //console.log("data of getapplistbyuserid :" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};

//updatePendingCreateTaskByBatchStatus
export const updatePendingCreateTaskByBatchStatus = async (
  payload,
  statusupdaterecord,
  status,
  Ctechapp,
  loggedin_by
) => {
  try {
    console.log("updatePendingCreateTaskByBatchStatus Services", loggedin_by);
    const data = await Ctechapp.functions.updatePendingCreateTaskByBatchStatus(
      JSON.stringify(payload),
      JSON.stringify(statusupdaterecord),
      status,
      loggedin_by
    );
    if (data !== undefined) {
      //console.log("data of updatePendingCreateTaskByBatchStatus :" + JSON.stringify(data));
      return JSON.stringify(data);
    }
  } catch (error) {
    return error.response.data.message;
  }
};
