import { Button, Table, Modal } from "react-bootstrap";
import { useEffect, useState, useCallback } from "react";
import { insertUserAndContactDetail } from "../../services/users_services";
import { useParams } from "react-router";
import Select from "react-select";
import customstyles from "../../components/customstyles/react-select-styles";
import LoadingDialog from "../../components/LoadingDialog";
import { useAuth0 } from "@auth0/auth0-react";
import { getuserbyauthproviderid } from "../../services/users_services";
import {
  getapplistbyfilter,
  getProcorevendorlistbycompanyid,
} from "../../services/applications_services";
import { getentraidcompanylistbycompanyid } from "../../services/entraid_contact_services";
import {
  getCompanyListFromQuickBooks,
  GetQuickBooksContactRole,
} from "../../services/quickbooks_contact_services";
import { getcommandpostcompanylistbyaccountid_wrapper } from "../../services/commandpost_contact_services";
import { useRealmApp } from "../../providers/RealmApp";
import { getLOBApps } from "../../services/lob_app_info_services";
import { isEmail } from "validator";
import CreatableSelect from "react-select/creatable";
import { appConstants } from "../../constants/app_constants";
import {
  getAllCountryCodes,
  getCollectionListsByFormName,
} from "../../services/collection_lists_services";

import SweetAlert from "react-bootstrap-sweetalert";
import PhoneInput from "react-phone-input-2";
import PageSubHeader from "../../components/pagesubheader";
function AddContact() {
  const ctechapp = useRealmApp();
  const [userrole, setUserrole] = useState("1");
  const [loading, setLoading] = useState(false);
  const [loadDialogOpen, setloadDialogOpen] = useState(false);
  const [PhoneArray, setPhoneArray] = useState([]);
  const [AddressArray, setAddressArray] = useState([]);
  const [EmailArray, setEmailArray] = useState([]);
  const [countryCodeLists, setCountryCodeLists] = useState([]);
  const [salutationOptions, setSalutationOption] = useState([]);
  const [genderOptions, setGenderOption] = useState([]);
  const [phoneOptions, setPhoneOption] = useState([]);
  const [addressOptions, setAddressOption] = useState([]);
  const [userRoleOptions, setUserRoleOption] = useState([]);
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  const [containPersonalEmailError, setContainPersonalEmailError] =
    useState("");
  const [AppArray, setAppArray] = useState([]);
  const [isSuccess, setisSuccess] = useState(false);
  const [isAlreadyExist, setIsAlreadyExist] = useState(false);
  const [isCompany, setIsCompany] = useState(false);
  const [isvaliddata, setisvaliddata] = useState(false);
  const [userInfo, setUserInfo] = useState(false);
  const [isVaildData, setIsVaildData] = useState(false);
  const [appAffList, setAppAffList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [contactRoleList, setContactRoleList] = useState([]);
  const [quickBooksCompanyList, setQuickBooksCompanyList] = useState([]);
  const [vendorListSearchLookup, setVendorListSearchLookup] = useState([]);
  const [containPersonalPhoneError, setContainPersonalPhoneError] =
    useState("");
  const [isDeleteAffiliation, setIsDeleteAffiliation] = useState(false);
  const [deleteAffiliation, setDeleteAffiliation] = useState("");
  const [errormsg, setErrormsg] = useState("");
  const [userphoto, setuserphoto] = useState("../assets/img/usericon.png");
  const [containPersonalAddressError, setContainPersonalAddressError] =
    useState("");
  const [containBusinessPhoneError, setContainBusinessPhoneError] =
    useState("");
  const [containBusinessEmailError, setContainBusinessEmailError] =
    useState("");
  const [containBusinessAddressError, setContainBusinessAddressError] =
    useState("");
  const [isVendorChange, setIsVendorChange] = useState(false);

  const initialbusinessPhoneState = {
    id: "",
    type: "",
    phone: "",
    ext: "",
    fullPhone: "",
    extphone: "",
    country: "",
    isMain: 0,
  };

  const initialbusinessPhoneErrorState = {
    id: "",
    type: "",
    phone: "",
    fullPhone: "",
    ext: "",
    extphone: "",
    country: "",
    isMain: 0,
  };
  const [businessPhone, setBusinessPhone] = useState(initialbusinessPhoneState);
  const [businessPhoneErrors, setBusinessPhoneErrors] = useState(
    initialbusinessPhoneErrorState
  );
  const initialbusinessEmailState = {
    id: "",
    label: "",
    email: "",
    isMain: 0,
  };

  const initialbusinessEmailErrorState = {
    id: "",
    label: "",
    email: "",
    isMain: 0,
  };
  const [businessEmail, setBusinessEmail] = useState(initialbusinessEmailState);
  const [businessEmailErrors, setBusinessEmailErrors] = useState(
    initialbusinessEmailErrorState
  );

  const initialbusinessAddressState = {
    id: "",
    addressLabel: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    country: "",
    state: "",
    postalCode: "",
    isMain: 0,
  };
  const initialbusinessAddressErrorState = {
    id: "",
    addressLabel: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    country: "",
    state: "",
    postalCode: "",
    isMain: 0,
  };

  const [businessAddress, setBusinessAddress] = useState(
    initialbusinessAddressState
  );
  const [businessAddressErrors, setBusinessAddressErrors] = useState(
    initialbusinessAddressErrorState
  );

  const initialpersonalPhoneState = {
    id: "",
    type: "",
    phone: "",
    ext: "",
    extphone: "",
    fullPhone: "",
    country: "",
    isMain: 0,
  };
  const [personalPhone, setPersonalPhone] = useState(initialpersonalPhoneState);
  const [personalPhoneErrors, setPersonalPhoneErrors] = useState(
    initialpersonalPhoneState
  );
  const initialpersonalAddressState = {
    id: "",
    addressLabel: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    country: "",
    state: "",
    postalCode: "",
    isMain: 0,
  };

  const [personalAddress, setPersonalAddress] = useState(
    initialpersonalAddressState
  );
  const [personalAddressErrors, setPersonalAddressErrors] = useState(
    initialpersonalAddressState
  );

  const initialpersonalEmailState = {
    id: "",
    label: "",
    email: "",
    isMain: 0,
  };
  const [personalEmail, setPersonalEmail] = useState(initialpersonalEmailState);
  const [personalEmailErrors, setPersonalEmailErrors] = useState(
    initialpersonalEmailState
  );

  const initialContactState = {
    userid: "",
    authId: "",
    salutation: "",
    firstname: "",
    lastname: "",
    middlename: "",
    phone: "",
    email: "",
    gender: "",
    dateOfBirth: "",
    nickName: "",
    contactOwner: "",
    description: "",
    contactRole: "",
    role: "1",
    sourceSystem: [],
    phoneArray: [],
    addressArray: [],
    emailArray: [],
    contactAffiliationsArray: [],
  };
  const initialContactStateErros = {
    firstname: "",
    lastname: "",
    email: "",
  };
  const [contactErrors, setContactErrors] = useState(initialContactStateErros);
  const [contact, setContact] = useState(initialContactState);
  const [vendorsearch, setVendorsearch] = useState("");
  const [changeVendorRow, setchangeVendorRow] = useState("");
  const initialbusinessContact = {
    id: uuid(),
    companyName: "",
    contactOwner: "",
    description: "",
    role: "",
    contactRole: "",
    sourceSystem: "",
    sourceSystemId: "",
    contactRecordType: "",
    department: "",
    reportTo: "",
    claimedUrl: "",
    phoneArray: [],
    addressArray: [],
    emailArray: [],
  };
  const [businessContact, setBusinessContact] = useState(
    initialbusinessContact
  );
  const [businessContactErrors, setBusinessContactErrors] = useState(
    initialbusinessContact
  );
  const initialappState = {
    id: "",
    appid: "",
  };
  const [app, setApp] = useState(initialappState);

  //const [appErrors, setAppErrors] = useState(initialappState);

  const { userid } = useParams();

  useEffect(() => {
    if (isAuthenticated) {
      try {
        setloadDialogOpen(true);
        GetAllCountryCodes();
        GetSalutationOptions();
        GetGenderOptions();
        GetPhoneOptions();
        GetAddressOptions();
        GetUserRoleOptions();
        getUserInfoByID();
        getAccountCompanyList();
        GetQuickBooksContactRoleList();
      } catch (e) {
        // console.log(e);
        setloadDialogOpen(false);
      }
      //GetAllCountries();
      // GetAllStates();
    }
  }, [user.sub]);

  async function getUserInfoByID() {
    try {
      setloadDialogOpen(true);
      await getuserbyauthproviderid(user.sub, ctechapp.currentUser).then(
        (response) => {
          let user_info = JSON.parse(response);
          if (response !== undefined || user_info.length !== 0) {
            // console.log(user_info[0]);
            setUserInfo(user_info[0]);
            setloadDialogOpen(false);
            //    GetContactDetailByID(user_info[0]?._id);
          }
        }
      );
      setloadDialogOpen(false);
    } catch (exception) {
      setloadDialogOpen(false);
      console.log(exception);
    }
  }

  async function GetAllCountryCodes() {
    await getAllCountryCodes(ctechapp.currentUser).then((response) => {
      if (response !== undefined) {
        let countrycode_info = JSON.parse(response);

        let options = countrycode_info.map(function (country) {
          return { value: country._id, label: country.name };
        });

        setCountryCodeLists(options);
      }
    });
  }

  async function GetSalutationOptions() {
    await getCollectionListsByFormName("Salutation", ctechapp.currentUser).then(
      (response) => {
        if (response !== undefined) {
          let salutation_info = JSON.parse(response);

          let options = salutation_info.map(function (salutation) {
            return { value: salutation.listValue, label: salutation.listName };
          });

          setSalutationOption(options);
        }
      }
    );
  }

  async function GetQuickBooksContactRoleList() {
    setloadDialogOpen(true);
    await GetQuickBooksContactRole(ctechapp.currentUser, user.sub).then(
      (response) => {
        if (response !== undefined) {
          let contact_role = JSON.parse(response);

          // let options = salutation_info.map(function (salutation) {
          //   return { value: salutation.listValue, label: salutation.listName };
          // });

          const contact_role_sorted = contact_role.sort((a, b) =>
            a["label"].toLowerCase() > b["label"].toLowerCase() ? 1 : -1
          );

          setContactRoleList(contact_role_sorted);
        }
      }
    );
    setloadDialogOpen(false);
  }

  async function GetGenderOptions() {
    await getCollectionListsByFormName("Gender", ctechapp.currentUser).then(
      (response) => {
        if (response !== undefined) {
          let gender_info = JSON.parse(response);

          let options = gender_info.map(function (gender) {
            return { value: gender.listValue, label: gender.listName };
          });

          setGenderOption(options);
        }
      }
    );
  }

  async function GetPhoneOptions() {
    await getCollectionListsByFormName("Phone", ctechapp.currentUser).then(
      (response) => {
        if (response !== undefined) {
          let phone_info = JSON.parse(response);

          let options = phone_info.map(function (phone) {
            return { value: phone.listValue, label: phone.listName };
          });

          setPhoneOption(options);
        }
      }
    );
  }

  async function GetAddressOptions() {
    await getCollectionListsByFormName("Address", ctechapp.currentUser).then(
      (response) => {
        if (response !== undefined) {
          let address_info = JSON.parse(response);
          let options = address_info.map(function (address) {
            return { value: address.listValue, label: address.listName };
          });
          setAddressOption(options);
        }
      }
    );
  }
  async function GetUserRoleOptions() {
    await getCollectionListsByFormName(
      "ContactRole",
      ctechapp.currentUser
    ).then((response) => {
      if (response !== undefined) {
        let userrole_info = JSON.parse(response);

        let options = userrole_info.map(function (userrole) {
          return {
            value: userrole.listValue,
            label: userrole.listName,
          };
        });

        setUserRoleOption(options);
      }
    });
  }

  async function getAccountCompanyList() {
    setloadDialogOpen(true);
    var tempAppList = [];
    var query = {
      "appuserinfo.userid": user.sub,
      appname: { $in: ["Procore", "CommandPost", "QuickBooks", "EntraID"] },
      isactive: "1",
    };
    getapplistbyfilter(ctechapp.currentUser, query, user.sub).then(
      (response) => {
        if (response !== undefined) {
          var app_list = JSON.parse(response);

          app_list.map((app_row) => {
            app_row.SelectSource = true;
            app_row.guid = uuid();
            app_row.companyid = app_row.appaccountid;
            app_row.selectvalue = "";
            app_row.vendorid = "";
            app_row.vendorname = "";
            app_row.contactrole = "";
            app_row.vendordisplayname = "";
            tempAppList.push(app_row);
            return app_row;
          });
          GetVendorList(app_list);
          setAppAffList(app_list);
          setloadDialogOpen(false);
        }
      }
    );
  }
  const GetVendorListByCompanyID = useCallback((companyid, appname, index) => {
    //console.log("vendorList", vendorList);

    const vendorList_sorted = vendorList.sort((a, b) =>
      a["vendorname"].toLowerCase() > b["vendorname"].toLowerCase() ? 1 : -1
    );

    var _resultvendors = vendorList_sorted.filter(
      (vendorinfo) =>
        vendorinfo.companyid === companyid &&
        vendorinfo?.appname?.toLowerCase() === appname.toLowerCase()
    );

    var uniqueVendors = _resultvendors.filter(
      (arr, index, self) =>
        index === self.findIndex((t) => t.label === arr.label)
    );

    return uniqueVendors;
  });

  const GetVendorQuickbooksListByCompanyID = (
    companyid,
    appname,
    contactRole,
    index
  ) => {
    try {
      //setloadDialogOpen(true);
      var _resultvendors = vendorList.filter(
        (vendorinfo) =>
          vendorinfo.companyid === companyid &&
          vendorinfo?.appname?.toLowerCase() === appname.toLowerCase() &&
          vendorinfo?.contactrole?.toLowerCase() === contactRole?.toLowerCase()
      );

      var uniqueVendors = _resultvendors.filter(
        (arr, index, self) =>
          index === self.findIndex((t) => t.label === arr.label)
      );

      //console.log("uniqueVendors1", uniqueVendors);

      const uniqueVendors_sorted = uniqueVendors.sort((a, b) =>
        a["vendorname"].toLowerCase() > b["vendorname"].toLowerCase() ? 1 : -1
      );

      setQuickBooksCompanyList(uniqueVendors_sorted);
      //setloadDialogOpen(false);
    } catch (error) {
      // setloadDialogOpen(false);
      console.log("Error in GetVendorQuickbooksListByCompanyID", error);
    }

    //setloadDialogOpen(false);
  };

  async function GetVendorList(applist) {
    try {
      var _vendorLookup = [];
      var templist = [];
      await applist.map(async (row) => {
        if (row?.appname.toLowerCase() === "procore") {
          const token_input = {
            procoreClientID: row?.appclientid,
            procoreClientSecret: row?.appclientsecret,
            procoreRedirectUri: row?.appredirecturi,
            procoreAppToken: row?.appuserinfo[0].apptoken,
            procoreRefreshToken: row?.appuserinfo[0].apprefreshtoken,
            procoreRestAPIHostName: row?.appresthostname,
            procoreCompanyID: row?.appaccountid,
            loggedin_by: user.sub,
            procoreAccessTokenHostName: row?.appaccesstokenhostname,
          };

          var _procoreList = await getProcorevendorlistbycompanyid(
            token_input,
            ctechapp.currentUser,
            user.sub
          );

          if (_procoreList !== undefined) {
            //  var templist = vendorList;
            _procoreList = JSON.parse(_procoreList);
            if (_procoreList.length > 0) {
              await _procoreList.map(async (procore) => {
                templist.push({
                  vendorid: procore.id,
                  vendorname: procore.name,
                  vendordisplayname: procore.name,
                  companyid: procore.company_ID,
                  appname: row?.appname,
                  guid: row?.guid,
                });
              });
            }
          }
        } else if (row?.appname.toLowerCase() === "entraid") {
          const EID_token_input = {
            entraidClientID: row.appclientid || "",
            entraidClientSecret: row.appclientsecret || "",
            entraidRedirectUri: row.appredirecturi || "",
            entraidAppToken: row.appuserinfo[0].apptoken || "",
            entraidRefreshToken: row.appuserinfo[0].apprefreshtoken || "",
            expires_in_seconds: row.appuserinfo[0].expires_in_seconds || 0,
            token_createdon: row.appuserinfo[0].token_createdon || "",
            entraidRestAPIHostName: row.appresthostname || "",
            entraidAccessTokenHostName: row.appaccesstokenhostname || "",
            entraidTenantID: row.tenantid || "",
            entraidTenantName: row.tenantname || "",
            entraidCompanyID: row.appaccountid || "",
            loggedin_by: user.sub,
          };

          var _entraidList = await getentraidcompanylistbycompanyid(
            EID_token_input,
            ctechapp.currentUser,
            user.sub
          );

          if (_entraidList !== undefined && _entraidList.statusCode === 200) {
            //  var templist = vendorList;
            //_entraidList = JSON.parse(_entraidList.data);
            _entraidList = _entraidList.data;
            if (_entraidList.length > 0) {
              await _entraidList.map(async (entraid) => {
                let obj = {
                  vendorid: entraid.id,
                  vendorname: entraid.companyName,
                  vendordisplayname: entraid.companyName,
                  companyid: EID_token_input.entraidTenantID || "",
                  appname: "EntraID",
                  guid: row?.guid,
                };
                console.log("obj ", JSON.stringify(obj));
                templist.push(obj);
              });
            }
          }
        } else if (row?.appname.toLowerCase() === "quickbooks") {
          var _quickbooksList = await getCompanyListFromQuickBooks(
            row?.appaccountid,
            row?.appaccesstokenhostname,
            row?.appclientid,
            row?.appclientsecret,
            row?.appuserinfo[0].apprefreshtoken,
            row?.appuserinfo[0].apptoken,
            row?.appredirecturi,
            row?.appresthostname,
            ctechapp.currentUser,
            user.sub
          );

          if (_quickbooksList !== undefined && _quickbooksList.length > 0) {
            //console.log("qblist", JSON.stringify(_quickbooksList));
            //  var templist = vendorList;
            await _quickbooksList.map(async (qb) => {
              templist.push({
                vendorid: qb.vendorid,
                vendorname: qb.vendorname,
                companyid: qb.companyid,
                appname: qb.appname,
                contactrole: qb.contactrole,
                vendordisplayname: qb.vendordisplayname,
                guid: row?.guid,
              });
            });
          }
        } else if (row?.appname.toLowerCase() === "commandpost") {
          var _commandpostList =
            await getcommandpostcompanylistbyaccountid_wrapper(
              row?.appaccountid,
              ctechapp.currentUser,
              user.sub
            );

          if (_commandpostList !== undefined) {
            //   var templist = vendorList;
            _commandpostList = JSON.parse(_commandpostList);
            if (_commandpostList?.length > 0) {
              await _commandpostList?.map(async (commandpost) => {
                templist.push({
                  vendorid: commandpost._id,
                  vendorname: commandpost.companyName,
                  vendordisplayname: commandpost.companyName,
                  companyid: row?.appaccountid,
                  appname: row?.appname,
                  guid: row?.guid,
                });
              });
            }
          }
        }
        try {
          var _vendorLookup1 = templist;
          //   console.log("templist2", JSON.stringify(templist));

          if (templist.length > 0) {
            templist.map((vendor1) => {
              let _name1 = vendor1.vendorname;
              var result = [];
              result = _vendorLookup1.filter(
                (_namevalue) => _namevalue?.keyvalue !== _name1
              );

              if (_vendorLookup.length === 0 || result.length > 0) {
                _vendorLookup.push({
                  label: _name1.concat(" (" + vendor1.appname + ")"),
                  value: _name1.concat(" (" + vendor1.appname + ")"),
                  vendorid: vendor1.vendorid,
                  vendorname: vendor1.vendorname,
                  companyid: vendor1.companyid,
                  appname: vendor1.appname,
                  vendordisplayname: vendor1.vendordisplayname,
                  contactrole:
                    vendor1.contactrole === undefined
                      ? ""
                      : vendor1.contactrole,
                });
              }
            });
            setVendorList(_vendorLookup);
            //console.log("_vendorLookup", _vendorLookup);

            const key = "label";

            const arrayUniqueByKey = [
              ...new Map(
                _vendorLookup.map((item) => [item[key], item])
              ).values(),
            ];
            setVendorListSearchLookup(arrayUniqueByKey);
          }
        } catch (exception) {
          console.log("getProcorevendorlistbycompanyid Error", exception);
        }
      });
    } catch (error) {
      console.log("getProcorevendorlistbycompanyid Error", error);
    }
  }
  const handleChangerole = (event) => {
    setUserrole(event.target.value);
  };
  //save functions
  const handleDropDownChange = (selectedOption, itemupdate, index) => {
    const { name, value } = selectedOption.target;
    if (itemupdate === "contact") {
      // console.log(name, value);
      setContact({ ...contact, [name]: value });
      setContactErrors({ ...contactErrors, [name]: "" });
    }
    if (itemupdate === "app") {
      //  console.log(name, value);
      setApp({ ...app, [name]: value });

      if (AppArray !== null) {
        const objIndex = AppArray.findIndex((obj) => obj._id === value._id);
        //  console.log("objIndex ", objIndex);
        if (objIndex != -1) {
          const rowsInput = [...AppArray];
          rowsInput[objIndex] = value;

          setAppArray(rowsInput);
          setApp(initialappState);
        } else {
          setAppArray([...AppArray, value]);
          setApp(initialappState);
        }
      }
      //  setAppErrors({ ...appErrors, [name]: "" });
    }
    if (itemupdate === "personalphone") {
      setPersonalPhone({ ...personalPhone, [name]: value });
      setPersonalPhoneErrors({ ...personalPhoneErrors, [name]: "" });
    }
    if (itemupdate === "personaladdress") {
      setPersonalAddress({ ...personalAddress, [name]: value });
      setPersonalAddressErrors({ ...personalAddressErrors, [name]: "" });
    }
    if (itemupdate === "personalemail") {
      setPersonalEmail({ ...personalEmail, [name]: value });
    }
  };

  const handleDropDownChangeAddCompany = (vendorinfo) => {
    //console.log("addcompany appAffList", appAffList);
    //console.log("addcompany vendorList", vendorList);
    //console.log("addcompany vendorListSearchLookup", vendorListSearchLookup);

    setErrormsg("");

    const { value, name, rowindex } = vendorinfo;
    var _appAffList = [...appAffList];
    var _vendorList = [...vendorList];
    if (name === "vendorsearch") {
      appAffList?.map((app_row, index) => {
        if (value.vendorname !== undefined) {
          _appAffList[index].selectvalue = value.vendorname;
          _appAffList[index].vendorid = value.vendorid;
          _appAffList[index].vendorname = value.vendorname;
          _appAffList[index].vendordisplayname = value.vendordisplayname;
        } else {
          _appAffList[index].selectvalue = value?.value;
          _appAffList[index].vendorid = "";
          _appAffList[index].vendorname = value?.value;
          _appAffList[index].vendordisplayname = value?.value;
          var newVendorinfo = {
            label: value?.value?.toString(),
            value:
              value?.value?.toString() +
              " (" +
              app_row?.appname?.toString() +
              ")",
            vendorid: "",
            vendorname: value?.value?.toString(),
            vendordisplayname: value?.value?.toString(),
            companyid: app_row?.appaccountid?.toString(),
            appname: app_row?.appname?.toString(),
          };
          _vendorList.push(newVendorinfo);
        }
      });
      setVendorList(_vendorList);
    }
    if (name === "vendor") {
      appAffList?.map((app_row, index) => {
        //console.log("value", value);

        if (value.vendorname !== undefined) {
          _appAffList[rowindex].selectvalue = value.vendorname;
          _appAffList[rowindex].vendorid = value.vendorid;
          _appAffList[rowindex].vendorname = value.vendorname;
          _appAffList[rowindex].vendordisplayname = value.vendorname;
        } else {
          _appAffList[rowindex].selectvalue = value?.value;
          _appAffList[rowindex].vendorid = "";
          _appAffList[rowindex].vendorname = value?.value;
          _appAffList[rowindex].vendordisplayname = value?.value;
          var newVendorinfo = {
            label: value?.value?.toString(),
            value:
              value?.value?.toString() +
              " (" +
              app_row?.appname?.toString() +
              ")",
            vendorid: "",
            vendorname: value?.value?.toString(),
            vendordisplayname: value?.value?.toString(),
            companyid: app_row?.appaccountid?.toString(),
            appname: app_row?.appname?.toString(),
          };
          _vendorList.push(newVendorinfo);
        }
      });
      setVendorList(_vendorList);
    }
  };

  function handlecontactPhoneChange(value, data) {
    handleMainPhone(value, data);
    setContact({ ...contact, ["phone"]: value });

    setisvaliddata(true);
  }
  const handleMainEmail = (event) => {
    const path = event.target.name.split(".");
    if (path[0] === "email") {
      if (EmailArray.length > 0) {
        let _emailismain = EmailArray.filter((email) => {
          return email.isMain === 1;
        });
        _emailismain = _emailismain[0].email = event.target.value;
      } else {
        var email = {
          id: uuid(),
          label: "Primary",
          email: event.target.value,
          isMain: 1,
        };
        if (EmailArray?.length === 0) {
          var _emailArray = EmailArray;
          _emailArray.push(email);
          setEmailArray(_emailArray);
        } else if (EmailArray !== null) {
          var _emailArray = EmailArray;
          _emailArray.push(email);
          setEmailArray(_emailArray);
        } else {
          let emailArray = [];
          emailArray.push(email);
          setEmailArray(emailArray);
        }
      }
    }
    let _email = [...EmailArray];
    setEmailArray(_email);
    handleBusinessMainEmail(_email);
  };
  const handleBusinessMainEmail = (data) => {
    const _emailArray = data;
    if (_emailArray?.length > 0) {
      var main_email = _emailArray?.filter((email) => email.isMain === 1);
      if (main_email.length > 0) {
        contact.contactAffiliationsArray.map((aff) => {
          if (aff.emailArray.length > 0) {
            let email_index = aff.emailArray.findIndex(
              (email) => email.isMain === 1
            );
            if (email_index !== -1) {
              aff.emailArray[email_index] = main_email[0];
            } else {
              aff.emailArray.push(main_email[0]);
            }
          } else {
            aff.emailArray.push(main_email[0]);
          }
        });
      }
    }
  };
  const handleMainPhone = (value, data, event, formattedValue) => {
    //console.log("main phone value", value);
    //console.log("main phone data", data);
    let fullphone = value;
    if (PhoneArray.length > 0) {
      let _phone = PhoneArray.filter((phone) => {
        return phone.isMain === 1;
      });
      _phone = _phone[0];
      _phone.country = data.countryCode.toUpperCase();
      _phone.ext = data.dialCode;
      _phone.phone = fullphone.replace(data.dialCode, "");
      _phone.fullPhone = fullphone;
    } else {
      var phone = {
        id: uuid(),
        type: "1",
        country: data.countryCode.toUpperCase(),
        ext: data.dialCode,
        extphone: "",
        fullPhone: fullphone,
        phone: fullphone.replace(data.dialCode, ""),
        isMain: 1,
      };
      PhoneArray.push(phone);
    }
    let _phone = [...PhoneArray];
    setPhoneArray(_phone);
    handleBusinessMainPhone(_phone);
  };
  const handleBusinessMainPhone = (data) => {
    const _phoneArray = data;
    if (_phoneArray?.length > 0) {
      var main_phone = _phoneArray?.filter((phone) => phone.isMain === 1);
      if (main_phone.length > 0) {
        contact.contactAffiliationsArray.map((aff) => {
          if (aff.phoneArray.length > 0) {
            let phone_index = aff.phoneArray.findIndex(
              (phone) => phone.isMain === 1
            );
            if (phone_index !== -1) {
              aff.phoneArray[phone_index] = main_phone[0];
            } else {
              aff.phoneArray.push(main_phone[0]);
            }
          } else {
            aff.phoneArray.push(main_phone[0]);
          }
        });
      }
    }
  };
  const handlePersonalChange = (event) => {
    const path = event.target.name.split(".");
    const finalProp = path.pop();
    const newpersonalcontact = { ...contact };
    let pointer = newpersonalcontact;
    path.forEach((el) => {
      pointer[el] = { ...pointer[el] };
      pointer = pointer[el];
    });
    pointer[finalProp] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    // //console.log("newpersonalcontact", newpersonalcontact);
    setContact(newpersonalcontact);
    handlePersonalFieldValidation(event);
    setisvaliddata(true);
  };

  const handlePersonalFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "firstname":
        localerror =
          value.length < 3
            ? appConstants.contact.handlefieldvalidation.firstname
            : "";
        break;
      case "lastname":
        localerror =
          value.length < 3
            ? appConstants.contact.handlefieldvalidation.lastname
            : "";
        break;
      case "email":
        localerror = !isEmail(value)
          ? appConstants.contact.handlefieldvalidation.email
          : "";
        break;
      default:
        break;
    }
    setContactErrors({ ...contactErrors, [name]: localerror });
    setisvaliddata(true);
  };

  const handleOnPhoneChange = (value, data, event, formattedValue) => {
    setContainPersonalPhoneError("");
    setPersonalPhoneErrors({ ...personalPhoneErrors, ["phone"]: "" });
    var _personalphone = { ...personalPhone };
    const dialcode = data.dialCode;
    if (_personalphone.id === undefined || _personalphone.id === "") {
      _personalphone.id = uuid();
    }
    _personalphone.ext = data.dialCode;
    _personalphone.country = data.countryCode;
    const phonevalue = value;
    _personalphone.fullPhone = phonevalue;
    _personalphone.phone = phonevalue.replace(dialcode, "");
    setPersonalPhone(_personalphone);
  };
  const handleOnBusinessPhoneChange = (value, data, event, formattedValue) => {
    setContainBusinessPhoneError("");
    setBusinessPhoneErrors({ ...businessPhoneErrors, ["phone"]: "" });
    var _businessphone = { ...businessPhone };
    const dialcode = data.dialCode;
    if (_businessphone.id === undefined || _businessphone.id === "") {
      _businessphone.id = uuid();
    }
    _businessphone.ext = data.dialCode;
    _businessphone.country = data.countryCode;
    const phonevalue = value;
    _businessphone.fullPhone = phonevalue;
    _businessphone.phone = phonevalue.replace(dialcode, "");
    setBusinessPhone(_businessphone);
  };
  async function submitHandler(values) {
    //console.log("contact", contact);
    if (PhoneArray.length > 0) {
      contact.phoneArray = PhoneArray;
    }
    if (EmailArray.length > 0) {
      contact.emailArray = EmailArray;
    }
    if (AddressArray.length > 0) {
      contact.addressArray = AddressArray;
    }
    if (contact.phoneArray.length > 0) {
      let phone = contact.phoneArray.filter((phone) => phone.isMain === 1);
      if (phone.length === 0) {
        contact.phoneArray[0].isMain = 1;
      }
    }
    if (contact.addressArray.length > 0) {
      let address = contact.addressArray.filter(
        (address) => address.isMain === 1
      );
      if (address.length === 0) {
        contact.addressArray[0].isMain = 1;
      }
    }
    if (contact.emailArray.length > 0) {
      let email = contact.emailArray.filter((email) => email.isMain === 1);
      if (email.length === 0) {
        contact.emailArray[0].isMain = 1;
      }
    }
    if (contact.contactAffiliationsArray.length > 0) {
      contact.contactAffiliationsArray.map((aff) => {
        if (aff.phoneArray.length > 0) {
          let phone = aff.phoneArray.filter((phone) => phone.isMain === 1);
          if (phone.length === 0) {
            aff.phoneArray[0].isMain = 1;
          }
        }
        if (aff.addressArray.length > 0) {
          let address = aff.addressArray.filter(
            (address) => address.isMain === 1
          );
          if (address.length === 0) {
            aff.addressArray[0].isMain = 1;
          }
        }
        if (aff.emailArray.length > 0) {
          let email = aff.emailArray.filter((email) => email.isMain === 1);
          if (email.length === 0) {
            aff.emailArray[0].isMain = 1;
          }
        }
      });
    }
    //console.log("contact.sourceSystem", contact);
    if (validPersonalForm()) {
      //console.log("contact", contact);
      insertUserAndContactDetail(contact, ctechapp.currentUser, user.sub).then(
        (res) => {
          if (res !== undefined) {
            if (res === "false") {
              setIsAlreadyExist(true);
            } else {
              setisSuccess(true);
            }
          }
        }
      );
    }
  }

  async function submitConfirm(values) {
    let _selectApp = appAffList.filter(
      (record) => record.SelectSource === true
    );

    let _selectAppValidation = _selectApp.filter(
      (record) => record.selectvalue === ""
    );

    let _selectAppcontactroleValidation = _selectApp.filter(
      (record) =>
        record.appname.toLowerCase() === "quickbooks" &&
        record.contactrole === ""
    );

    if (_selectAppValidation.length > 0) {
      setErrormsg(appConstants.contact.notselectedmsg.company);
      return;
    }

    if (_selectAppcontactroleValidation.length > 0) {
      setErrormsg(appConstants.contact.notselectedmsg.role);
      return;
    }

    // new_affiliationlist()
    //console.log("_selectApp", _selectApp);

    let _contact = { ...contact };

    _selectApp.map((app) => {
      var objindex = contact?.contactAffiliationsArray?.findIndex(
        (affinfo) =>
          affinfo?.sourceSystem?.appname?.toLowerCase() ===
            app?.appname?.toLowerCase() &&
          affinfo?.sourceSystem?.companyid?.toLowerCase() ===
            app?.appaccountid?.toLowerCase() &&
          affinfo?.sourceSystem?.clientid?.toLowerCase() ===
            app?.appclientid?.toLowerCase()
      );

      if (objindex === -1) {
        var initialaff = {
          id: uuid(),
          companyId: app.vendorid,
          companyName: app.vendorname,
          companyDBA: app.appname === "QuickBooks" ? app.vendordisplayname : "",
          contactOwner: "",
          description: "",
          contactRole: app.contactrole,
          sourceSystem: {
            appuserid: "",
            appname: app.appname,
            clientid: app.appclientid,
            companyid: app.appaccountid,
            contact_category: "",
            imported_on: new Date(),
            contactrole: app.contactrole,
            status: "Created",
          },
          title: "",
          isContractor: "",
          claimedUrl: "",
          contactRecordType: "",
          department: "",
          reportTo: "",
          emailArray: EmailArray,
          phoneArray: PhoneArray,
          addressArray: AddressArray,
        };
        //console.log("initialaff", initialaff);
        let _samecompanyaff = _contact.contactAffiliationsArray.filter(
          (row) => row.companyName.trim() === app.vendorname.trim()
        );
        console.log("_samecompanyaff", _samecompanyaff);
        _contact.sourceSystem.push(initialaff.sourceSystem);
        // _contact.contactAffiliationsArray.push(initialaff);
        if (_samecompanyaff && _samecompanyaff.length > 0) {
          initialaff.emailArray = _samecompanyaff[0].emailArray;
          initialaff.addressArray = _samecompanyaff[0].addressArray;
          initialaff.phoneArray = _samecompanyaff[0].phoneArray;
        }

        new_affiliationlist(initialaff);
      } else {
        _contact.contactAffiliationsArray[objindex].companyId = app?.vendorid;
        _contact.contactAffiliationsArray[objindex].companyName =
          app?.vendorname;
      }
    });
    setContact(_contact);
    setIsCompany(false);
  }
  const handleSucessSweetAlert1 = () => {
    setIsAlreadyExist(false);
  };
  const handleSucessSweetAlert = () => {
    setisSuccess(false);
    gotoContactList();
  };
  const submitAddCompany = () => {
    setIsCompany(true);
  };
  function gotoContactList() {
    window.location.href = "/contactlist";
  }

  const validPersonalForm = () => {
    try {
      let valid = false;
      valid = handleFieldPersonalListRequiredValidation();
      Object.values(contactErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldPersonalListRequiredValidation = () => {
    let iserror = "";

    setContactErrors({
      ...contactErrors,
      firstname:
        contact.firstname === ""
          ? (iserror =
              appConstants.contact.handlefieldrequiredvalidation.firstname)
          : contactErrors.firstname,
      lastname:
        contact.lastname === ""
          ? (iserror =
              appConstants.contact.handlefieldrequiredvalidation.lastname)
          : contactErrors.lastname,
      email:
        contact.email === ""
          ? (iserror = appConstants.contact.handlefieldrequiredvalidation.email)
          : contactErrors.email,
    });

    return iserror.length === 0;
  };

  const handleCancel = () => {
    window.location.href = "/contactlist";
  };

  //personal info
  //button action for personal phone
  const SelectPersonalPhoneRow = (index) => {
    const rowsInput = [...PhoneArray];
    setPersonalPhone(rowsInput[index]);
    setPersonalPhoneErrors(initialpersonalPhoneState);
  };

  const deletePersonalPhoneRow = (index) => {
    const rows = [...PhoneArray];
    rows.splice(index, 1);
    setPhoneArray(rows);
  };

  //button action for personal address
  const SelectPersonalAddressRow = (index) => {
    const rowsInput = [...AddressArray];
    setPersonalAddress(rowsInput[index]);
    setPersonalAddressErrors(initialpersonalAddressState);
  };

  const deletePersonalAddressRow = (index) => {
    const rows = [...AddressArray];
    rows.splice(index, 1);
    setAddressArray(rows);
  };

  //button action for personal email

  const SelectPersonalEmailRow = (index) => {
    const rowsInput = [...EmailArray];
    setPersonalEmail(rowsInput[index]);
    setPersonalEmailErrors(initialpersonalEmailState);
  };

  const deletePersonalEmailRow = (index) => {
    const rows = [...EmailArray];
    rows.splice(index, 1);
    setEmailArray(rows);
  };

  // handle change for personal phone
  const handleChangePersonalPhone = (event) => {
    setContainPersonalPhoneError("");
    event.preventDefault();
    const { name, value } = event.target;
    setPersonalPhone({ ...personalPhone, [name]: value });
    setPersonalPhoneErrors({ ...personalPhoneErrors, [name]: "" });

    if (personalPhone.id === "") {
      setPersonalPhoneErrors({ ...personalPhoneErrors, [name]: "" });
      setPersonalPhone({ ...personalPhone, ["id"]: uuid() });
    }
  };

  // handle change for personal address
  const handleChangePersonalAddress = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setPersonalAddress({ ...personalAddress, [name]: value });
    setPersonalAddressErrors({ ...personalAddressErrors, [name]: "" });
    if (personalAddress.id === "") {
      setPersonalAddressErrors({
        ...personalAddressErrors,
        [name]: "",
      });
      setPersonalAddress({ ...personalAddress, ["id"]: uuid() });
    }
  };
  // handle change for personal email
  const handleChangePersonalEmail = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    handlePersonalEmailFieldValidation(event);
    setPersonalEmail({ ...personalEmail, [name]: value });

    if (personalEmail.id === "") {
      handlePersonalEmailFieldValidation(event);
      setPersonalEmail({ ...personalEmail, ["id"]: uuid() });
    }
  };

  const handlePersonalEmailFieldValidation = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let localerror = "";
    switch (name) {
      case "email":
        localerror = !isEmail(value)
          ? appConstants.contact.handlefieldvalidation.email
          : "";
        break;
      default:
        break;
    }
    setPersonalEmailErrors({ ...personalEmailErrors, [name]: localerror });
    setisvaliddata(true);
  };

  //business info
  // handle change for business phone
  function handleChangeBusinessPhone(e, index, property, islist) {
    let value = "";
    if (property === "phone") value = e;
    else value = islist === true ? e.value : e.target.value;
    if (property === "isMain") {
      value = e.target.checked === true ? 1 : 0;
    }
    setBusinessPhoneErrors({ ...businessPhoneErrors, [property]: "" });
    setBusinessPhone({ ...businessPhone, [property]: value });
  }
  //button action for Business phone
  //button action for Business address
  const SelectBusinessPhoneRow = (affindex, affemailindex, id) => {
    const rows = [...contact.contactAffiliationsArray[affindex].phoneArray];
    setBusinessPhone(rows[affemailindex]);
    setBusinessPhoneErrors(initialbusinessPhoneErrorState);
  };

  const deleteBusinessPhoneRow = (index, rowindex) => {
    const rows = [...contact.contactAffiliationsArray[index].phoneArray];
    rows.splice(rowindex, 1);
    setContact((prevcontact) => {
      prevcontact.contactAffiliationsArray[index].phoneArray = rows;
      //    console.log("prevcontact",JSON.stringify(prevcontact));
      setBusinessPhone(initialbusinessPhoneState);
      return prevcontact;
    });
  };

  //button action for Business address
  const SelectBusinessAddressRow = (affindex, affemailindex, id) => {
    const rows = [...contact.contactAffiliationsArray[affindex].addressArray];
    setBusinessAddress(rows[affemailindex]);
    setBusinessAddressErrors(initialbusinessAddressErrorState);
  };

  const deleteBusinessAddressRow = (index, rowindex) => {
    const rows = [...contact.contactAffiliationsArray[index].addressArray];
    rows.splice(rowindex, 1);
    setContact((prevcontact) => {
      prevcontact.contactAffiliationsArray[index].addressArray = rows;
      //    console.log("prevcontact",JSON.stringify(prevcontact));
      setBusinessAddress(initialbusinessAddressState);
      return prevcontact;
    });
  };

  // add business email
  const addBusinessEmail = (e, index) => {
    e.preventDefault();
    if (validBusinessEmailForm(index)) {
      const _businessEmail = { ...businessEmail };
      if (_businessEmail.email.length > 0) {
        var _contact = { ...contact };
        let temparrayprops = [
          ...contact.contactAffiliationsArray[index].emailArray,
        ];

        let record_index = temparrayprops.findIndex(
          (item) => item.id == _businessEmail.id
        );

        if (record_index != -1) {
          const containsvalue_email = !!temparrayprops.find((emailinfo) => {
            return (
              emailinfo?.email === _businessEmail?.email &&
              emailinfo.id !== _businessEmail.id
            );
          });
          const containsvalue_ismain = !!temparrayprops.find((emailinfo) => {
            if (emailinfo.isMain !== 0) {
              return (
                emailinfo?.isMain === _businessEmail?.isMain &&
                emailinfo.id !== _businessEmail.id
              );
            } else {
              return false;
            }
          });
          if (!containsvalue_ismain) {
            if (!containsvalue_email) {
              temparrayprops[record_index] = _businessEmail;
            } else {
              setContainBusinessEmailError(
                appConstants.contact.alreadyexistmsg.email
              );
              return;
            }
          } else {
            setContainBusinessEmailError(
              appConstants.contact.alreadyexistmsg.ismain
            );
            return;
          }
        } else {
          const containsvalue_email = !!temparrayprops.find((emailinfo) => {
            return (
              emailinfo?.email === _businessEmail?.email &&
              emailinfo.id !== _businessEmail.id
            );
          });
          const containsvalue_ismain = !!temparrayprops.find((emailinfo) => {
            if (emailinfo.isMain !== 0) {
              return (
                emailinfo?.isMain === _businessEmail?.isMain &&
                emailinfo.id !== _businessEmail.id
              );
            } else {
              return false;
            }
          });
          if (!containsvalue_ismain) {
            if (!containsvalue_email) {
              _businessEmail.id = uuid();
              temparrayprops.push(_businessEmail);
            } else {
              setContainBusinessEmailError(
                appConstants.contact.alreadyexistmsg.email
              );
              return;
            }
          } else {
            setContainBusinessEmailError(
              appConstants.contact.alreadyexistmsg.ismain
            );
            return;
          }
        }
        _contact.contactAffiliationsArray[index].emailArray = temparrayprops;
        //email array add other affiliations
        if (_businessEmail.isMain === 1) {
          _contact.contactAffiliationsArray?.map((lobaffinfo, affindex) => {
            var _lobaffinfo = { ...lobaffinfo };
            if (
              _lobaffinfo.id !== _contact.contactAffiliationsArray[index].id
            ) {
              if (
                _lobaffinfo.companyName.toLowerCase() ===
                _contact.contactAffiliationsArray[
                  index
                ].companyName.toLowerCase()
              ) {
                let isMain_index = _lobaffinfo.emailArray.findIndex(
                  (item) => item.isMain == 1
                );
                let isEmail_index = _lobaffinfo.emailArray.findIndex(
                  (item) => item.email == _businessEmail.email
                );
                if (isMain_index !== -1) {
                  isMain_index = isEmail_index;
                }

                if (isMain_index !== -1) {
                  _lobaffinfo.emailArray[isMain_index] = _businessEmail;
                } else {
                  _lobaffinfo.emailArray.push(_businessEmail);
                }
                _contact.contactAffiliationsArray[affindex] = _lobaffinfo;
              }
            }
          });
        }
        setContact(_contact);
        setBusinessEmail(initialbusinessEmailState);
      }
    }
  };

  // clear business email
  const clearBusinessEmail = () => {
    setBusinessEmailErrors(initialbusinessEmailState);
    setContainBusinessEmailError("");
    setBusinessEmail(initialbusinessEmailState);
  };

  //button action for Business email
  const SelectBusinessEmailRow = (affindex, affemailindex, id) => {
    const rows = [...contact.contactAffiliationsArray[affindex].emailArray];
    setBusinessEmail(rows[affemailindex]);
    setBusinessEmailErrors(initialbusinessEmailState);
    setContainBusinessEmailError("");
    //clearBusinessEmail();
  };

  const deleteBusinessEmailRow = (index, rowindex) => {
    const rows = [...contact.contactAffiliationsArray[index].emailArray];
    rows.splice(rowindex, 1);
    setContact((prevcontact) => {
      prevcontact.contactAffiliationsArray[index].emailArray = rows;
      setBusinessEmail(initialbusinessEmailState);
      clearBusinessEmail();
      return prevcontact;
    });
  };
  const validBusinessEmailForm = (index) => {
    try {
      let valid = false;
      return true;
      valid = handleFieldBusinessEmailListRequiredValidation(index);
      Object.values(personalEmailErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldBusinessEmailListRequiredValidation = (index) => {
    let iserror = "";
    return true;
    setBusinessEmailErrors({
      ...businessEmailErrors,
      label:
        businessEmail[index].label === ""
          ? (iserror = appConstants.contact.handlefieldrequiredvalidation.label)
          : businessEmailErrors?.label,
      email:
        businessEmail[index].email === ""
          ? (iserror = appConstants.contact.handlefieldrequiredvalidation.email)
          : businessEmailErrors?.email,
    });

    return iserror.length === 0;
  };

  // handle change for business Address

  function handleChangeBusinessAddress(e, property, islist) {
    let value = islist === true ? e.value : e.target.value;
    if (property === "isMain") {
      value = e.target.checked === true ? 1 : 0;
    }
    setBusinessAddressErrors({ ...businessAddressErrors, [property]: "" });
    setBusinessAddress({ ...businessAddress, [property]: value });
  }
  function handleBusinessChange(e, itemId, property, islist) {
    const value = islist === true ? e.value : e.target.value;
    //copying data to temp variable so that we do not directly mutate original state
    const temparrayprops = [...contact.contactAffiliationsArray];
    //findIndex to find location of item we need to update
    let index = temparrayprops.findIndex((item) => item.id == itemId);
    // -1 check to see if we found that object in social_media
    if (index != -1) {
      temparrayprops[index] = {
        ...temparrayprops[index], //keeping existing values in object
        [property]: value, //here property can be "link"
      };
    }
    setContact({ ...contact, contactAffiliationsArray: temparrayprops });
  }
  function handleChangeBusinessEmail(e, index, property, islist) {
    let value = islist === true ? e.value : e.target.value;
    if (property === "isMain") {
      value = e.target.checked === true ? 1 : 0;
    }
    setBusinessEmailErrors({ ...businessEmailErrors, [property]: "" });
    setBusinessEmail({ ...businessEmail, [property]: value });
  }

  //add business address
  const addBusinessAddress = (e, index) => {
    e.preventDefault();
    if (validBusinessAddressForm(index)) {
      const _businessAddress = { ...businessAddress };

      var _contact = { ...contact };
      let temparrayprops = [
        ...contact.contactAffiliationsArray[index].addressArray,
      ];

      let record_index = temparrayprops.findIndex(
        (item) => item.id == _businessAddress.id
      );

      if (record_index != -1) {
        const containsvalue_ismain = !!temparrayprops.find((addressinfo) => {
          if (addressinfo.isMain !== 0) {
            return (
              addressinfo?.isMain === _businessAddress?.isMain &&
              addressinfo.id !== _businessAddress.id
            );
          } else {
            return false;
          }
        });
        if (!containsvalue_ismain) {
          temparrayprops[record_index] = _businessAddress;
        } else {
          setContainBusinessAddressError(
            appConstants.contact.alreadyexistmsg.ismain
          );
          return;
        }
      } else {
        const containsvalue_ismain = !!temparrayprops.find((addressinfo) => {
          if (addressinfo.isMain !== 0) {
            return (
              addressinfo?.isMain === _businessAddress?.isMain &&
              addressinfo.id !== _businessAddress.id
            );
          } else {
            return false;
          }
        });
        if (!containsvalue_ismain) {
          _businessAddress.id = uuid();
          temparrayprops.push(_businessAddress);
        } else {
          setContainBusinessAddressError(
            appConstants.contact.alreadyexistmsg.ismain
          );
          return;
        }
      }
      _contact.contactAffiliationsArray[index].addressArray = temparrayprops;

      //address array add other affiliations
      if (_businessAddress.isMain === 1) {
        _contact.contactAffiliationsArray?.map((lobaffinfo, affindex) => {
          var _lobaffinfo = { ...lobaffinfo };
          if (_lobaffinfo.id !== _contact.contactAffiliationsArray[index].id) {
            if (
              _lobaffinfo.companyName.toLowerCase() ===
              _contact.contactAffiliationsArray[index].companyName.toLowerCase()
            ) {
              let isMain_index = _lobaffinfo.addressArray.findIndex(
                (item) => item.isMain == 1
              );
              if (isMain_index !== -1) {
                _lobaffinfo.addressArray[isMain_index] = _businessAddress;
              } else {
                _lobaffinfo.addressArray.push(_businessAddress);
              }
              _contact.contactAffiliationsArray[affindex] = _lobaffinfo;
            }
          }
        });
      }

      setContact(_contact);
      setBusinessAddress(initialbusinessAddressState);
    }
  };

  // clear business email
  const clearBusinessAddress = () => {
    setBusinessAddressErrors(initialbusinessAddressState);
    setContainBusinessAddressError("");
    let bus_add = { ...businessAddress };
    setBusinessAddress(initialbusinessAddressState);
  };

  //add business phone
  const addBusinessPhone = (e, index) => {
    e.preventDefault();
    if (validBusinessPhoneForm(index)) {
      const _businessPhone = { ...businessPhone };
      if (_businessPhone.fullPhone.length > 0) {
        var _contact = { ...contact };
        let temparrayprops = [
          ...contact.contactAffiliationsArray[index].phoneArray,
        ];

        let record_index = temparrayprops.findIndex(
          (item) => item.id == _businessPhone.id
        );

        if (record_index != -1) {
          const containsvalue_phone = !!temparrayprops.find((phoneinfo) => {
            return (
              phoneinfo?.fullPhone === _businessPhone?.fullPhone &&
              phoneinfo?.extphone === _businessPhone?.extphone &&
              phoneinfo.id !== _businessPhone.id
            );
          });
          const containsvalue_ismain = !!temparrayprops.find((phoneinfo) => {
            if (phoneinfo.isMain !== 0) {
              return (
                phoneinfo?.isMain === _businessPhone?.isMain &&
                phoneinfo.id !== _businessPhone.id
              );
            } else {
              return false;
            }
          });
          if (!containsvalue_ismain) {
            if (!containsvalue_phone) {
              temparrayprops[record_index] = _businessPhone;
            } else {
              setContainBusinessPhoneError(
                appConstants.contact.alreadyexistmsg.phone
              );
              return;
            }
          } else {
            setContainBusinessPhoneError(
              appConstants.contact.alreadyexistmsg.ismain
            );
            return;
          }
        } else {
          const containsvalue_phone = !!temparrayprops.find((phoneinfo) => {
            return (
              phoneinfo?.fullPhone === _businessPhone?.fullPhone &&
              phoneinfo?.extphone === _businessPhone?.extphone &&
              phoneinfo.id !== _businessPhone.id
            );
          });
          const containsvalue_ismain = !!temparrayprops.find((phoneinfo) => {
            if (phoneinfo.isMain !== 0) {
              return (
                phoneinfo?.isMain === _businessPhone?.isMain &&
                phoneinfo.id !== _businessPhone.id
              );
            } else {
              return false;
            }
          });
          if (!containsvalue_ismain) {
            if (!containsvalue_phone) {
              _businessPhone.id = uuid();
              temparrayprops.push(_businessPhone);
            } else {
              setContainBusinessPhoneError(
                appConstants.contact.alreadyexistmsg.phone
              );
              return;
            }
          } else {
            setContainBusinessPhoneError(
              appConstants.contact.alreadyexistmsg.ismain
            );
            return;
          }
        }
        _contact.contactAffiliationsArray[index].phoneArray = temparrayprops;

        //phone array add other affiliations
        if (_businessPhone.isMain === 1) {
          _contact.contactAffiliationsArray?.map((lobaffinfo, affindex) => {
            var _lobaffinfo = { ...lobaffinfo };
            if (
              _lobaffinfo.id !== _contact.contactAffiliationsArray[index].id
            ) {
              if (
                _lobaffinfo.companyName.toLowerCase() ===
                _contact.contactAffiliationsArray[
                  index
                ].companyName.toLowerCase()
              ) {
                let isMain_index = _lobaffinfo.phoneArray.findIndex(
                  (item) => item.isMain == 1
                );
                if (isMain_index !== -1) {
                  _lobaffinfo.phoneArray[isMain_index] = _businessPhone;
                } else {
                  _lobaffinfo.phoneArray.push(_businessPhone);
                }
                _contact.contactAffiliationsArray[affindex] = _lobaffinfo;
              }
            }
          });
        }

        setContact(_contact);
        setBusinessPhone(initialbusinessPhoneState);
      }
    }
  };
  //generate guid
  function uuid() {
    var uuidValue = "",
      k,
      randomValue;
    for (k = 0; k < 32; k++) {
      randomValue = (Math.random() * 16) | 0;

      if (k == 8 || k == 12 || k == 16 || k == 20) {
        uuidValue += "";
      }
      uuidValue += (
        k == 12 ? 4 : k == 16 ? (randomValue & 3) | 8 : randomValue
      ).toString(16);
    }
    return uuidValue;
  }

  const addPersonalEmail = async (e) => {
    e.preventDefault();
    //console.log("personalEmail", personalEmail);
    if (validEmailForm()) {
      //console.log("personalEmail : ", personalEmail);
      if (EmailArray !== null) {
        const objIndex = EmailArray.findIndex(
          (obj) => obj.id === personalEmail.id
        );
        //console.log("objIndex ", objIndex);
        if (objIndex != -1) {
          const containsvalue_email = !!EmailArray.find((emailinfo) => {
            return (
              emailinfo?.email === personalEmail?.email &&
              emailinfo.id !== personalEmail.id
            );
          });
          const containsvalue_ismain = !!EmailArray.find((emailinfo) => {
            if (emailinfo.isMain !== 0) {
              return (
                emailinfo?.isMain === personalEmail?.isMain &&
                emailinfo.id !== personalEmail.id
              );
            } else {
              return false;
            }
          });

          if (!containsvalue_ismain) {
            if (!containsvalue_email) {
              const rowsInput = [...EmailArray];
              rowsInput[objIndex] = personalEmail;

              setEmailArray(rowsInput);
              setPersonalEmail(initialpersonalEmailState);
              if (personalEmail.isMain === 1) {
                var _contact = { ...contact };
                _contact.email = personalEmail.email;
                setContact(_contact);
                setContactErrors({ ...contactErrors, ["email"]: "" });
              }
            } else {
              setContainPersonalEmailError(
                appConstants.contact.alreadyexistmsg.email
              );
            }
          } else {
            setContainPersonalEmailError(
              appConstants.contact.alreadyexistmsg.ismain
            );
          }

          // setEmailArray(rowsInput);
          // setPersonalEmail(initialpersonalEmailState);
        } else {
          const containsvalue_email = !!EmailArray.find((emailinfo) => {
            return emailinfo?.email === personalEmail?.email;
          });
          const containsvalue_ismain = !!EmailArray.find((emailinfo) => {
            if (emailinfo.isMain !== 0) {
              return emailinfo?.isMain === personalEmail?.isMain;
            } else {
              return false;
            }
          });
          if (!containsvalue_ismain) {
            if (!containsvalue_email) {
              setEmailArray([...EmailArray, personalEmail]);
              setPersonalEmail(initialpersonalEmailState);
              if (personalEmail.isMain === 1) {
                var _contact = { ...contact };
                _contact.email = personalEmail.email;
                setContact(_contact);
                setContactErrors({ ...contactErrors, ["email"]: "" });
              }
            } else {
              setContainPersonalEmailError(
                appConstants.contact.alreadyexistmsg.email
              );
            }
          } else {
            setContainPersonalEmailError(
              appConstants.contact.alreadyexistmsg.ismain
            );
          }
          // setEmailArray([...EmailArray, personalEmail]);
          // setPersonalEmail(initialpersonalEmailState);
        }
      } else {
        let emailArray = [];
        emailArray.push(personalEmail);
        setEmailArray(emailArray);
        setPersonalEmail(initialpersonalEmailState);
      }
      var emailinfo = [...EmailArray];
      handleBusinessMainEmail(emailinfo);
    }
  };

  const validEmailForm = () => {
    try {
      let valid = false;
      valid = handleFieldPersonalEmailListRequiredValidation();
      Object.values(personalEmailErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldPersonalEmailListRequiredValidation = () => {
    let iserror = "";

    setPersonalEmailErrors({
      ...personalEmailErrors,
      label:
        personalEmail.label === ""
          ? (iserror = appConstants.contact.handlefieldrequiredvalidation.label)
          : personalEmailErrors.label,
      email:
        personalEmail.email === ""
          ? (iserror = appConstants.contact.handlefieldrequiredvalidation.email)
          : personalEmailErrors.email,
    });

    return iserror.length === 0;
  };

  //add personal address

  const addPersonalAddress = async (e) => {
    e.preventDefault();
    if (validAddressForm()) {
      if (AddressArray !== null) {
        const objIndex = AddressArray.findIndex(
          (obj) => obj.id === personalAddress.id
        );
        if (objIndex !== -1) {
          const containsvalue_ismain = !!AddressArray.find((addressinfo) => {
            if (addressinfo.isMain !== 0) {
              return (
                addressinfo?.isMain === personalAddress?.isMain &&
                addressinfo.id !== personalAddress.id
              );
            } else {
              return false;
            }
          });
          if (!containsvalue_ismain) {
            const rowsInput = [...AddressArray];
            rowsInput[objIndex] = personalAddress;
            setAddressArray(rowsInput);
            setPersonalAddress(initialpersonalAddressState);
          } else {
            setContainPersonalAddressError(
              appConstants.contact.alreadyexistmsg.ismain
            );
          }
        } else {
          const containsvalue_ismain = !!AddressArray.find((addressinfo) => {
            if (addressinfo.isMain !== 0) {
              return addressinfo?.isMain === personalAddress?.isMain;
            } else {
              return false;
            }
          });
          if (!containsvalue_ismain) {
            setAddressArray([...AddressArray, personalAddress]);
            setPersonalAddress(initialpersonalAddressState);
          } else {
            setContainPersonalAddressError(
              appConstants.contact.alreadyexistmsg.ismain
            );
          }
        }
      } else {
        let addressArray = [];
        addressArray.push(personalAddress);
        setAddressArray(addressArray);
        setPersonalAddress(initialpersonalAddressState);
      }
    }
  };
  const validAddressForm = () => {
    try {
      let valid = false;
      valid = handleFieldPersonalAddressListRequiredValidation();
      Object.values(personalAddressErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldPersonalAddressListRequiredValidation = () => {
    let iserror = "";

    setPersonalAddressErrors({
      ...personalAddressErrors,
      addressLabel:
        personalAddress.addressLabel === ""
          ? (iserror = appConstants.contact.handlefieldrequiredvalidation.label)
          : personalAddressErrors.addressLabel,
      addressLine1:
        personalAddress.addressLine1 === ""
          ? (iserror =
              appConstants.contact.handlefieldrequiredvalidation.address)
          : personalAddressErrors.addressLine1,
    });

    return iserror.length === 0;
  };

  //add personal phone

  const addPersonalPhone = async (e) => {
    e.preventDefault();
    if (validPhoneForm()) {
      if (PhoneArray !== null) {
        const objIndex = PhoneArray.findIndex(
          (obj) => obj.id === personalPhone.id
        );
        if (objIndex !== -1) {
          const containsvalue_phone = !!PhoneArray.find((phoneinfo) => {
            return (
              phoneinfo?.fullPhone === personalPhone?.fullPhone &&
              phoneinfo?.extphone === personalPhone?.extphone &&
              phoneinfo.id !== personalPhone.id
            );
          });
          const containsvalue_ismain = !!PhoneArray.find((phoneinfo) => {
            if (phoneinfo.isMain !== 0) {
              return (
                phoneinfo?.isMain === personalPhone?.isMain &&
                phoneinfo.id !== personalPhone.id
              );
            } else {
              return false;
            }
          });
          //console.log("containsvalue_phone", containsvalue_phone);
          //console.log("containsvalue_ismain", containsvalue_ismain);
          if (!containsvalue_ismain) {
            if (!containsvalue_phone) {
              const rowsInput = [...PhoneArray];
              rowsInput[objIndex] = personalPhone;
              setPhoneArray(rowsInput);
              setPersonalPhone(initialpersonalPhoneState);
            } else {
              setContainPersonalPhoneError(
                appConstants.contact.alreadyexistmsg.phone
              );
              return;
            }
          } else {
            setContainPersonalPhoneError(
              appConstants.contact.alreadyexistmsg.ismain
            );
            return;
          }
          if (personalPhone.isMain === 1) {
            var _contact = { ...contact };
            _contact.phone = personalPhone.fullPhone;
            setContact(_contact);
          }
        } else {
          const containsvalue_phone = !!PhoneArray.find((phoneinfo) => {
            return (
              phoneinfo?.fullPhone === personalPhone?.fullPhone &&
              phoneinfo?.extphone === personalPhone?.extphone &&
              phoneinfo.id !== personalPhone.id
            );
          });
          const containsvalue_ismain = !!PhoneArray.find((phoneinfo) => {
            if (phoneinfo.isMain !== 0) {
              return (
                phoneinfo?.isMain === personalPhone?.isMain &&
                phoneinfo.id !== personalPhone.id
              );
            } else {
              return false;
            }
          });
          //console.log("else containsvalue_phone", containsvalue_phone);
          //console.log("else containsvalue_ismain", containsvalue_ismain);
          if (!containsvalue_ismain) {
            if (!containsvalue_phone) {
              setPhoneArray([...PhoneArray, personalPhone]);
              setPersonalPhone(initialpersonalPhoneState);
            } else {
              setContainPersonalPhoneError(
                appConstants.contact.alreadyexistmsg.phone
              );
              return;
            }
          } else {
            setContainPersonalPhoneError(
              appConstants.contact.alreadyexistmsg.ismain
            );
            return;
          }
          if (personalPhone.isMain === 1) {
            var _contact = { ...contact };
            _contact.phone = personalPhone.fullPhone;
            setContact(_contact);
          }
        }
      } else {
        let phoneArray = [];
        phoneArray.push(personalPhone);
        setPhoneArray(phoneArray);
        setPersonalPhone(initialpersonalPhoneState);
      }
      var phoneinfo = [...PhoneArray];
      handleBusinessMainPhone(phoneinfo);
    }
  };
  const validPhoneForm = () => {
    try {
      let valid = false;
      valid = handleFieldPersonalPhoneListRequiredValidation();
      Object.values(personalPhoneErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldPersonalPhoneListRequiredValidation = () => {
    let iserror = "";

    setPersonalPhoneErrors({
      ...personalPhoneErrors,
      type:
        personalPhone.type === ""
          ? (iserror = appConstants.contact.handlefieldrequiredvalidation.type)
          : personalPhoneErrors.type,
      // country:
      //   personalPhone.country === ""
      //     ? (iserror = "Country Code required")
      //     : personalPhoneErrors.country,
      phone:
        personalPhone.phone === ""
          ? (iserror = appConstants.contact.handlefieldrequiredvalidation.phone)
          : personalPhoneErrors.phone,
    });

    return iserror.length === 0;
  };
  const handleChange_AppSelect = (e, data, index) => {
    const { name, checked } = e.target;
    var _selectappList = [...appAffList];
    if (name === "SelectSource") {
      if (checked) {
        const objIndex = _selectappList.findIndex(
          (obj) => obj._id === data._id
        );
        if (objIndex != -1) {
          _selectappList[objIndex].SelectSource = true;
        }
      } else {
        const objIndex = _selectappList.findIndex(
          (obj) => obj._id === data._id
        );
        if (objIndex != -1) {
          _selectappList[objIndex].SelectSource = false;
        }
      }
      setAppAffList(_selectappList);
    } else if (name === "allSelectSource") {
      if (checked) {
        appAffList.map((obj, index) => {
          _selectappList[index].SelectSource = true;
        });
      } else {
        appAffList.map((obj, index) => {
          _selectappList[index].SelectSource = false;
        });
      }
      setAppAffList(_selectappList);
    }

    let _selectApp = _selectappList.filter(
      (record) => record.SelectSource === true
    );

    if (_selectApp.length === _selectappList.length) {
      document.getElementById("allSelectSource").checked = true;
    } else {
      document.getElementById("allSelectSource").checked = false;
    }
  };

  const validBusinessAddressForm = (index) => {
    try {
      let valid = true;

      valid = handleFieldBusinessAddressListRequiredValidation();
      Object.values(businessAddressErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldBusinessAddressListRequiredValidation = () => {
    const _businessAddress = { ...businessAddress };
    let iserror = "";

    setBusinessAddressErrors({
      ...businessAddressErrors,
      addressLabel:
        _businessAddress.addressLabel === ""
          ? (iserror = appConstants.contact.handlefieldrequiredvalidation.label)
          : businessAddressErrors.addressLabel,
      addressLine1:
        _businessAddress.addressLine1 === ""
          ? (iserror =
              appConstants.contact.handlefieldrequiredvalidation.address)
          : businessAddressErrors.addressLine1,
    });

    return iserror.length === 0;
  };

  const validBusinessPhoneForm = (index) => {
    try {
      let valid = true;
      valid = handleFieldBusinessPhoneListRequiredValidation();
      Object.values(businessPhoneErrors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      //console.log("valid : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldBusinessPhoneListRequiredValidation = () => {
    const _businessPhone = { ...businessPhone };
    let iserror = "";

    setBusinessPhoneErrors({
      ...businessPhoneErrors,
      type:
        _businessPhone.type === ""
          ? (iserror = appConstants.contact.handlefieldrequiredvalidation.type)
          : businessPhoneErrors.type,
      phone:
        _businessPhone.phone === ""
          ? (iserror = appConstants.contact.handlefieldrequiredvalidation.phone)
          : businessPhoneErrors.phone,
    });

    return iserror.length === 0;
  };

  const new_affiliationlist = (values) => {
    let _contact = contact;
    _contact?.contactAffiliationsArray?.push(values);
    setContact(_contact);
  };

  const handleChange_affiliationlist = (index, evnt) => {
    const { name, value } = evnt.target;
    //console.log("name ", name, " ", value);
    //console.log(contact);

    const objIndex = contact?.contactAffiliationsArray.findIndex(
      (obj) => obj.id === value
    );
    const rowsInput = [contact?.contactAffiliationsArray[objIndex]];

    setBusinessContact(rowsInput[0]);
    //console.log("rowsInput : ", rowsInput[0]);
  };

  const deleteAppArray = (index) => {
    const rows = [...AppArray];
    rows.splice(index, 1);
    setAppArray(rows);
  };

  // vendor change confirm window

  const handleConfirmVendor = (row) => {
    handleDropDownChangeAddCompany(changeVendorRow);
    setIsVendorChange(false);
  };

  const handleCancelVendor = (row) => {
    setIsVendorChange(false);
  };
  const handleAffiliations = (e, type, index, data) => {
    switch (type) {
      case "inactiveaffiliation":
        setDeleteAffiliation(index);
        setIsDeleteAffiliation(true);

        break;
    }
  };

  const handleConfirmAffiliation = (e, type) => {
    let _contact = { ...contact };
    switch (type) {
      case "delete":
        _contact.contactAffiliationsArray.splice(deleteAffiliation, 1);
        _contact.sourceSystem.splice(deleteAffiliation, 1);

        setContact(_contact);
        //   submitHandler(_contact, "delete");
        setIsDeleteAffiliation(false);
        break;
    }
  };

  return (
    <div className="container p-3">
      <PageSubHeader title={"Add contact"} />

      <div className="row">
        <div className="col-sm col-md-3 col-lg-3 border">
          <div className="p-1 d-flex justify-content-center">
            <img className="rounded-circle" src={userphoto} alt="" />
          </div>
          <div className="p-3">
            <div className="d-flex justify-content-center flex-column align-items-center">
              {contact?.contactAffiliationsArray?.length > 0 &&
                contact?.contactAffiliationsArray?.map((data, index) => {
                  return (
                    <Button
                      className=" form-control mb-2"
                      type="button"
                      value={data?.id}
                      data-bs-toggle="collapse"
                      data-bs-target={"#panelsStayOpen-" + data?.id}
                      aria-expanded="false"
                      name={index}
                      aria-controls="panelsStayOpen-collapseBusinessInfo"
                      onClick={(evnt) =>
                        handleChange_affiliationlist(index, evnt)
                      }
                    >
                      {data.companyName === ""
                        ? "Add affiliation"
                        : data.companyName +
                          " (" +
                          data?.sourceSystem?.appname +
                          ")"}
                    </Button>
                  );
                })}
              <Button
                className="btn btn-success mt-1 "
                onClick={submitAddCompany}
                disabled={
                  contact.firstname === "" ||
                  contact.lastname === "" ||
                  contact.email === ""
                }
              >
                Add company affiliation
              </Button>
            </div>
          </div>
        </div>
        <div className="col-sm col-md-3 col-lg-8 border">
          <div className="accordion mt-2" id="accordionPanelscontact">
            <div className="accordion-item">
              <h2
                className="accordion-header mt-0"
                id="panelsStayOpen-headingPersonalInfo"
              >
                <button
                  className="accordion-button collapsed fw-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapsePersonalInfo"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapsePersonalInfo"
                  style={{
                    "background-color": "#f2f2f2",
                  }}
                >
                  Personal contact info
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapsePersonalInfo"
                className="accordion-collapse collapse show "
                aria-labelledby="panelsStayOpen-headingPersonalInfo"
              >
                <div className="accordion-body">
                  <div className="row">
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="form-label form-label ">
                          First name<label className="text-danger">*</label>
                        </label>
                        <input
                          type="text"
                          name="firstname"
                          id="firstname"
                          className="form-control"
                          value={contact?.firstname}
                          onChange={handlePersonalChange}
                        />
                        {contactErrors.firstname.length > 0 && (
                          <div className="text-danger">
                            {contactErrors.firstname}
                          </div>
                        )}
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="form-label form-label">
                          Middle name
                        </label>
                        <input
                          type="text"
                          name="middlename"
                          id="middlename"
                          className="form-control"
                          value={contact?.middlename}
                          onChange={handlePersonalChange}
                        />
                      </div>

                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="form-label form-label">
                          Last name<label className="text-danger">*</label>
                        </label>
                        <input
                          type="text"
                          name="lastname"
                          id="lastname"
                          className="form-control"
                          value={contact?.lastname}
                          onChange={handlePersonalChange}
                        />
                        {contactErrors.lastname.length > 0 && (
                          <div className="text-danger">
                            {contactErrors.lastname}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row p-1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="form-label form-label ">
                          Salutation
                        </label>
                        <Select
                          name="salutation"
                          options={salutationOptions}
                          className="form-control"
                          isSearchable={true}
                          styles={customstyles}
                          value={salutationOptions.filter(
                            (record) => record.value === contact?.salutation
                          )}
                          onChange={(val) => {
                            handleDropDownChange(
                              {
                                target: {
                                  name: "salutation",
                                  value: val.value,
                                },
                              },
                              "contact"
                            );
                          }}
                        ></Select>
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="form-label form-label">
                          Main email<label className="text-danger">*</label>
                        </label>
                        <input
                          type="text"
                          name="email"
                          id="email"
                          className="form-control"
                          value={contact?.email}
                          onChange={(e) => {
                            setContainPersonalEmailError("");
                            handlePersonalChange(e);
                            handleMainEmail(e);
                          }}
                        />
                        {contactErrors.email.length > 0 && (
                          <div className="text-danger">
                            {contactErrors.email}
                          </div>
                        )}
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="form-label form-label">
                          Main phone
                        </label>
                        <PhoneInput
                          inputStyle={{ width: "100%" }}
                          country={"us"}
                          value={contact?.phone}
                          name="phone"
                          placeholder="(000-000-0000)"
                          id="phone"
                          onChange={handlecontactPhoneChange}
                        />
                      </div>
                    </div>
                    <div className="row p-1">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="form-label form-label">
                          Nickname
                        </label>
                        <input
                          type="text"
                          name="nickName"
                          id="nickName"
                          className="form-control"
                          value={contact?.nickName}
                          onChange={handlePersonalChange}
                        />
                      </div>

                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="form-label form-label">Gender</label>
                        <Select
                          isSearchable={true}
                          type="text"
                          name="gender"
                          id="gender"
                          styles={customstyles}
                          className="form-control"
                          onChange={(val) => {
                            handleDropDownChange(
                              {
                                target: {
                                  name: "gender",
                                  value: val.value,
                                },
                              },
                              "contact"
                            );
                          }}
                          value={genderOptions.filter(
                            (client) => client.value === contact?.gender
                          )}
                          options={genderOptions}
                        />
                      </div>
                    </div>

                    <div className="row p-1">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="form-label form-label">
                          Date of birth
                        </label>
                        <input
                          type="date"
                          name="dateOfBirth"
                          id="dateOfBirth"
                          className="form-control"
                          value={contact?.dateOfBirth}
                          onChange={handlePersonalChange}
                        />
                      </div>

                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="form-label form-label">
                          Description
                        </label>
                        <input
                          type="text"
                          name="description"
                          id="description"
                          className="form-control"
                          value={contact?.description}
                          onChange={handlePersonalChange}
                        />
                      </div>
                    </div>

                    <div className="row p-1">
                      <div
                        className="col-sm-12 col-md-6 col-lg-6"
                        hidden={true}
                      >
                        <label className="form-label form-label">
                          Contact role
                        </label>
                        <input
                          type="text"
                          name="contactRole"
                          id="contactRole"
                          className="form-control"
                          value={contact?.contactRole}
                          onChange={handlePersonalChange}
                        />
                      </div>
                    </div>

                    {/* personal email info */}

                    <div
                      className="row p-2 m-1"
                      style={{
                        "background-color": "#f2f2f2",
                      }}
                    >
                      {" "}
                      Email info
                    </div>

                    <div className="row p-1">
                      <div className="col-sm col-md-3 col-lg-12">
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-3 col-lg-4">
                            <label className="form-label form-label">
                              Email<label className="text-danger">*</label>
                            </label>
                            <input
                              type="text"
                              name="email"
                              id="email"
                              className="form-control"
                              value={personalEmail.email}
                              onChange={(e) => {
                                handleChangePersonalEmail(e);
                                setContainPersonalEmailError("");
                              }}
                            />
                            {personalEmailErrors.email.length > 0 && (
                              <small
                                className="form-check-label text-danger"
                                htmlFor="email"
                              >
                                {personalEmailErrors.email}
                              </small>
                            )}
                          </div>
                          <div className="col-sm-12 col-md-3 col-lg-3">
                            <label className="form-label form-label">
                              Label<label className="text-danger">*</label>
                            </label>
                            <input
                              type="text"
                              name="label"
                              id="label"
                              className="form-control"
                              value={personalEmail.label}
                              onChange={handleChangePersonalEmail}
                            />
                            {personalEmailErrors.label.length > 0 && (
                              <small
                                className="form-check-label text-danger"
                                htmlFor="label"
                              >
                                {personalEmailErrors.label}
                              </small>
                            )}
                          </div>
                          <div className="d-flex justify-content-center align-items-start col-sm-12 col-md-2 col-lg-2 mt-5">
                            <input
                              type="checkbox"
                              id="isMain"
                              name="isMain"
                              className="form-check-input ms-3"
                              defaultChecked={false}
                              onChange={(e) => {
                                //console.log("e", e.target);
                                setContainPersonalEmailError("");
                                setPersonalEmail({
                                  ...personalEmail,
                                  [e.target.name]: e.target.checked ? 1 : 0,
                                });
                              }}
                              checked={
                                personalEmail.isMain === 1 ? true : false
                              }
                            />
                            <label className="form-label form-label ms-1">
                              Ismain?
                            </label>
                          </div>
                          <div className="d-flex justify-content-end align-items-end  gap-2 col-sm-12 col-md-3 col-lg-3">
                            <Button
                              variant="primary"
                              id="personalEmailClear"
                              className="form-control mb-3 btn-sm"
                              onClick={() => {
                                setPersonalEmailErrors(
                                  initialpersonalEmailState
                                );
                                setContainPersonalEmailError("");
                                setPersonalEmail(initialpersonalEmailState);
                              }}
                            >
                              Clear
                            </Button>
                            <Button
                              variant="primary"
                              id="personalEmailAdd"
                              className="form-control mb-3 btn-sm"
                              onClick={addPersonalEmail}
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                        {containPersonalEmailError !== "" && (
                          <small
                            className="form-check-label text-danger"
                            htmlFor="label"
                          >
                            {containPersonalEmailError}
                          </small>
                        )}
                      </div>
                    </div>
                    {EmailArray.length > 0 && (
                      <div className="row p-1 mt-3">
                        <Table striped bordered hover responsive>
                          <thead>
                            <tr>
                              <th>Email</th>
                              <th>Label</th>
                              <th>Ismain</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {EmailArray?.map((row, index) => (
                              <tr key={index}>
                                <td> {row?.email}</td>
                                <td> {row?.label}</td>
                                <td>
                                  {" "}
                                  <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                    <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                    {row?.isMain === 1 ? "Yes" : "No"}{" "}
                                  </span>
                                </td>
                                <td>
                                  <Button
                                    className="btn btn-sm  me-2"
                                    onClick={() =>
                                      deletePersonalEmailRow(index)
                                    }
                                  >
                                    Delete
                                  </Button>
                                  <Button
                                    onClick={() =>
                                      SelectPersonalEmailRow(index)
                                    }
                                    className="btn btn-sm"
                                  >
                                    Edit
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    )}
                    {/* personal address info */}

                    <div
                      className="row p-2 m-1"
                      style={{
                        "background-color": "#f2f2f2",
                      }}
                    >
                      {" "}
                      Address info
                    </div>

                    <div className="row p-1">
                      <div className="col-sm col-md-12 col-lg-12 ">
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-4 col-lg-4">
                            <label className="form-label form-label">
                              Address label
                              <label className="text-danger">*</label>
                            </label>
                            <Select
                              isSearchable={true}
                              type="text"
                              name="addressLabel"
                              id="addressLabel"
                              className="form-control"
                              styles={customstyles}
                              value={addressOptions.filter(
                                (client) =>
                                  client.value === personalAddress.addressLabel
                              )}
                              onChange={(val) => {
                                handleDropDownChange(
                                  {
                                    target: {
                                      name: "addressLabel",
                                      value: val.value,
                                    },
                                  },
                                  "personaladdress"
                                );
                              }}
                              options={addressOptions}
                            />
                            {personalAddressErrors.addressLabel.length > 0 && (
                              <small
                                className="form-check-label text-danger"
                                htmlFor="addressLabel"
                              >
                                {personalAddressErrors.addressLabel}
                              </small>
                            )}
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-4">
                            <label className="form-label form-label">
                              Address line 1
                              <label className="text-danger">*</label>
                            </label>
                            <input
                              type="text"
                              name="addressLine1"
                              id="addressLine1"
                              className="form-control"
                              value={personalAddress.addressLine1}
                              onChange={handleChangePersonalAddress}
                            />
                            {personalAddressErrors.addressLine1.length > 0 && (
                              <small
                                className="form-check-label text-danger"
                                htmlFor="addressLine1"
                              >
                                {personalAddressErrors.addressLine1}
                              </small>
                            )}
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-4">
                            <label className="form-label form-label">
                              Address line 2
                            </label>
                            <input
                              type="text"
                              name="addressLine2"
                              id="addressLine2"
                              className="form-control"
                              value={personalAddress.addressLine2}
                              onChange={handleChangePersonalAddress}
                            />
                          </div>
                        </div>
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-4 col-lg-4">
                            <label className="form-label form-label">
                              City
                            </label>
                            <input
                              type="text"
                              name="city"
                              id="city"
                              className="form-control"
                              value={personalAddress.city}
                              onChange={handleChangePersonalAddress}
                            />
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-4">
                            <label className="form-label form-label">
                              Country
                            </label>
                            <input
                              type="text"
                              name="country"
                              id="country"
                              className="form-control"
                              value={personalAddress.country}
                              onChange={handleChangePersonalAddress}
                            />
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-4">
                            <label className="form-label form-label">
                              State
                            </label>

                            <input
                              type="text"
                              name="state"
                              id="state"
                              className="form-control"
                              value={personalAddress.state}
                              onChange={handleChangePersonalAddress}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-9 d-flex gap-2">
                            <div className="col-sm-12 col-md-4 col-lg-4">
                              <label className="form-label form-label">
                                Postal code
                              </label>
                              <input
                                type="number"
                                name="postalCode"
                                id="postalCode"
                                className="form-control"
                                value={personalAddress.postalCode}
                                onChange={handleChangePersonalAddress}
                              />
                            </div>
                            <div className="col-sm-12 col-md-2 col-lg-2 mt-5">
                              <input
                                type="checkbox"
                                id="isMain"
                                name="isMain"
                                className="form-check-input ms-3"
                                onChange={(e) => {
                                  setContainPersonalAddressError("");
                                  setPersonalAddress({
                                    ...personalAddress,
                                    [e.target.name]: e.target.checked ? 1 : 0,
                                  });
                                }}
                                checked={
                                  personalAddress.isMain === 1 ? true : false
                                }
                              />
                              <label className="form-label form-label ms-1">
                                Ismain?
                              </label>
                            </div>
                          </div>
                          <div className="d-flex justify-content-end align-items-end  gap-2 col-sm-12 col-md-3 col-lg-3">
                            <Button
                              variant="primary"
                              id="personalAddressClear"
                              className="form-control mb-btn-sm3"
                              onClick={() => {
                                setPersonalAddressErrors(
                                  initialpersonalAddressState
                                );
                                setContainPersonalAddressError("");
                                setPersonalAddress(initialpersonalAddressState);
                              }}
                            >
                              Clear
                            </Button>
                            <Button
                              variant="primary"
                              id="personalAddressAdd"
                              className="form-control btn-sm"
                              onClick={addPersonalAddress}
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </div>
                      {containPersonalAddressError !== "" && (
                        <small
                          className="form-check-label text-danger"
                          htmlFor="label"
                        >
                          {containPersonalAddressError}
                        </small>
                      )}
                    </div>
                    <div className="row p-1 mt-3">
                      <Table striped bordered hover responsive>
                        <thead>
                          <tr>
                            <th>Address label</th>
                            <th>Address line 1</th>
                            <th>Address line 2</th>
                            <th>City</th>
                            <th>Ismain</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {AddressArray?.map((row, index) => (
                            <tr key={index}>
                              {addressOptions.map((option) =>
                                option.value === row?.addressLabel ? (
                                  <td>{option.label}</td>
                                ) : (
                                  ""
                                )
                              )}
                              <td> {row?.addressLine1}</td>
                              <td> {row?.addressLine2}</td>
                              <td> {row?.city}</td>
                              <td>
                                {" "}
                                <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                  <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                  {row?.isMain === 1 ? "Yes" : "No"}
                                </span>
                              </td>
                              <td>
                                <Button
                                  className="btn-sm me-2"
                                  onClick={() =>
                                    deletePersonalAddressRow(index)
                                  }
                                >
                                  Delete
                                </Button>
                                <Button
                                  onClick={() =>
                                    SelectPersonalAddressRow(index)
                                  }
                                  className="btn-sm "
                                >
                                  Edit
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    {/* personal phone info */}

                    <div
                      className="row  p-2 m-1"
                      style={{
                        "background-color": "#f2f2f2",
                      }}
                    >
                      {" "}
                      Phone info
                    </div>
                    <div className="row p-1">
                      <div className="col-sm col-md-12 col-lg-12 ">
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-3 col-lg-3">
                            <label className="form-label form-label">
                              Phone type
                              <label className="text-danger">*</label>
                            </label>
                            <Select
                              isSearchable={true}
                              type="text"
                              name="type"
                              id="type"
                              styles={customstyles}
                              className="form-control"
                              options={phoneOptions}
                              value={phoneOptions.filter(
                                (client) => client.value === personalPhone.type
                              )}
                              onChange={(val) => {
                                handleDropDownChange(
                                  {
                                    target: {
                                      name: "type",
                                      value: val.value,
                                    },
                                  },
                                  "personalphone"
                                );
                              }}
                            />
                            {personalPhoneErrors.type.length > 0 && (
                              <small
                                className="form-check-label text-danger"
                                htmlFor="type"
                              >
                                {personalPhoneErrors.type}
                              </small>
                            )}
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-4">
                            <label className="form-label form-label">
                              Phone number{" "}
                              <label className="text-danger">*</label>
                            </label>

                            <PhoneInput
                              inputStyle={{ width: "250px" }}
                              defaultCountryCode={true}
                              country={"us"}
                              value={personalPhone.fullPhone}
                              name="fullPhone"
                              placeholder="(000-000-0000)"
                              id="fullPhone"
                              onChange={handleOnPhoneChange}
                            />

                            {personalPhoneErrors.phone.length > 0 && (
                              <small
                                className="form-check-label text-danger"
                                htmlFor="phone"
                              >
                                {personalPhoneErrors.phone}
                              </small>
                            )}
                          </div>
                          <div className="col-sm-12 col-md-1 col-lg-1">
                            <label className="form-label form-label">
                              Extn
                            </label>
                            <input
                              type="text"
                              name="extphone"
                              id="extphone"
                              className="form-control"
                              placeholder="Ext Phone..."
                              value={personalPhone.extphone}
                              onChange={handleChangePersonalPhone}
                            />
                          </div>
                          <div className="justify-content-center align-items-start col-sm-12 col-md-2 col-lg-2  mt-5">
                            <input
                              type="checkbox"
                              className="form-check-input ms-3"
                              id="isMain"
                              name="isMain"
                              onChange={(e) => {
                                setContainPersonalPhoneError("");
                                setPersonalPhone({
                                  ...personalPhone,
                                  [e.target.name]: e.target.checked ? 1 : 0,
                                });
                              }}
                              checked={
                                personalPhone.isMain === 1 ? true : false
                              }
                            />
                            <label className="form-label ms-1">Ismain?</label>
                          </div>
                          <div className="d-flex justify-content-end align-items-end  gap-2 col-sm-12 col-md-2 col-lg-2">
                            <Button
                              variant="primary"
                              id="personalPhoneAdd"
                              className="form-control btn-sm"
                              onClick={() => {
                                setPersonalPhoneErrors(
                                  initialpersonalPhoneState
                                );
                                setContainPersonalPhoneError("");
                                setPersonalPhone(initialpersonalPhoneState);
                              }}
                            >
                              Clear
                            </Button>
                            <Button
                              variant="primary"
                              id="personalPhoneAdd"
                              className="form-control btn-sm"
                              onClick={addPersonalPhone}
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </div>
                      {containPersonalPhoneError !== "" && (
                        <small
                          className="form-check-label text-danger"
                          htmlFor="label"
                        >
                          {containPersonalPhoneError}
                        </small>
                      )}
                    </div>
                    <div className="row p-1 mt-3">
                      <Table striped bordered hover responsive>
                        <thead>
                          <tr>
                            <th>Phone type</th>

                            <th>Phone number</th>
                            <th>Extn</th>
                            <th>Ismain</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {PhoneArray &&
                            PhoneArray?.map((row, index) => (
                              <tr key={index}>
                                {phoneOptions.map((option) =>
                                  option.value === row?.type ? (
                                    <td>{option.label}</td>
                                  ) : (
                                    ""
                                  )
                                )}

                                <td>
                                  <PhoneInput
                                    inputStyle={{ width: "250px" }}
                                    value={row?.fullPhone}
                                    name="fullPhone"
                                    id="fullPhone"
                                    placeholder="(000-000-0000)"
                                    disabled
                                  />
                                </td>
                                <td> {row?.extphone}</td>
                                <td>
                                  <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                    <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                    {row?.isMain === 1 ? "Yes" : "No"}
                                  </span>
                                </td>
                                <td width="25%">
                                  <Button
                                    className="btn-sm me-2"
                                    onClick={() =>
                                      deletePersonalPhoneRow(index)
                                    }
                                  >
                                    Delete
                                  </Button>
                                  <Button
                                    onClick={() =>
                                      SelectPersonalPhoneRow(index)
                                    }
                                    className="btn-sm "
                                  >
                                    Edit
                                  </Button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
              {contact?.contactAffiliationsArray &&
                contact?.contactAffiliationsArray?.map((affiliation, index) => {
                  return (
                    <div className="accordion-item mt-2">
                      <h2 className="accordion-header mt-0">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={"#panelsStayOpen-" + affiliation.id}
                          aria-expanded="false"
                          aria-controls={"panelsStayOpen-" + affiliation.id}
                          style={{
                            "background-color": "#f2f2f2",
                          }}
                        >
                          {affiliation.companyName === ""
                            ? "Add affiliation"
                            : affiliation.companyName +
                              " (" +
                              affiliation?.sourceSystem?.appname +
                              ")"}
                          <Button
                            style={{
                              position: "absolute",
                              right: "60px",
                            }}
                            onClick={() =>
                              handleAffiliations(
                                "e",
                                "inactiveaffiliation",
                                index,
                                ""
                              )
                            }
                          >
                            Delete affiliation
                          </Button>
                        </button>
                      </h2>
                      <div
                        id={"panelsStayOpen-" + affiliation.id}
                        className="accordion-collapse show"
                        aria-labelledby="panelsStayOpen-headingBusinessInfo"
                      >
                        <div className="accordion-body">
                          <div className="row">
                            <div className="row p-1">
                              <div className="col-sm-12 col-md-6 col-lg-6">
                                <label className="form-label form-label ">
                                  Company name
                                  {contact?.verified_status
                                    ?.contactAffiliationsArray[index]
                                    ?.companyName?.status === "verified" ? (
                                    <span class="ms-2  badge rounded-pill bg-success">
                                      {
                                        contact?.verified_status
                                          ?.contactAffiliationsArray[index]
                                          ?.companyName?.status
                                      }
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </label>
                                <input
                                  type="text"
                                  name={"companyName_" + index}
                                  id={"companyName_" + index}
                                  className="form-control"
                                  value={affiliation?.companyName}
                                  disabled={true}
                                  onChange={(evnt) =>
                                    handleBusinessChange(
                                      evnt,
                                      affiliation.id,
                                      "companyName"
                                    )
                                  }
                                />
                                {businessContactErrors.companyName.length >
                                  0 && (
                                  <div className="text-danger">
                                    {businessContactErrors.companyName}
                                  </div>
                                )}
                              </div>

                              <div className="col-sm-12 col-md-6 col-lg-6">
                                <label className="form-label form-label">
                                  Description
                                  {contact?.verified_status
                                    ?.contactAffiliationsArray[index]
                                    ?.description?.status === "verified" ? (
                                    <span class="ms-2  badge rounded-pill bg-success">
                                      {
                                        contact?.verified_status
                                          ?.contactAffiliationsArray[index]
                                          ?.description?.status
                                      }
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </label>
                                <input
                                  type="text"
                                  name={"description_" + index}
                                  id={"description_" + index}
                                  className="form-control"
                                  value={affiliation?.description}
                                  onChange={(evnt) =>
                                    handleBusinessChange(
                                      evnt,
                                      affiliation.id,
                                      "description"
                                    )
                                  }
                                />
                              </div>
                            </div>

                            <div className="row p-1">
                              <div className="col-sm-12 col-md-6 col-lg-6">
                                <label className="form-label form-label">
                                  Contact role{" "}
                                  {contact?.verified_status
                                    ?.contactAffiliationsArray[index]
                                    ?.contactRole?.status === "verified" ? (
                                    <span class="ms-2  badge rounded-pill bg-success">
                                      {
                                        contact?.verified_status
                                          ?.contactAffiliationsArray[index]
                                          ?.contactRole?.status
                                      }
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </label>
                                <input
                                  type="text"
                                  name={"contactRole_" + index}
                                  id={"contactRole_" + index}
                                  className="form-control"
                                  value={affiliation?.contactRole}
                                  disabled={true}
                                  onChange={(evnt) =>
                                    handleBusinessChange(
                                      evnt,
                                      affiliation.id,
                                      "contactRole",
                                      1
                                    )
                                  }
                                />
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-6">
                                <label className="form-label form-label">
                                  Title
                                  {contact?.verified_status
                                    ?.contactAffiliationsArray[index]?.title
                                    ?.status === "verified" ? (
                                    <span class="ms-2  badge rounded-pill bg-success">
                                      {
                                        contact?.verified_status
                                          ?.contactAffiliationsArray[index]
                                          ?.title?.status
                                      }
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </label>
                                <input
                                  type="text"
                                  name={"title_" + index}
                                  id={"title_" + index}
                                  className="form-control"
                                  value={affiliation?.title}
                                  onChange={(evnt) =>
                                    handleBusinessChange(
                                      evnt,
                                      affiliation.id,
                                      "title"
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="row p-1">
                              <div className="col-sm-12 col-md-6 col-lg-6">
                                <label className="form-label form-label">
                                  Department{" "}
                                  {contact?.verified_status
                                    ?.contactAffiliationsArray[index]
                                    ?.department?.status === "verified" ? (
                                    <span class="ms-2  badge rounded-pill bg-success">
                                      {
                                        contact?.verified_status
                                          ?.contactAffiliationsArray[index]
                                          ?.department?.status
                                      }
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </label>
                                <input
                                  type="text"
                                  name={"department_" + index}
                                  id={"department_" + index}
                                  className="form-control"
                                  value={affiliation?.department}
                                  onChange={(evnt) =>
                                    handleBusinessChange(
                                      evnt,
                                      affiliation.id,
                                      "department"
                                    )
                                  }
                                />
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-6">
                                <label className="form-label form-label">
                                  Claimed URL
                                  {contact?.verified_status
                                    ?.contactAffiliationsArray[index]
                                    ?.claimedUrl?.status === "verified" ? (
                                    <span class="ms-2  badge rounded-pill bg-success">
                                      {
                                        contact?.verified_status
                                          ?.contactAffiliationsArray[index]
                                          ?.claimedUrl?.status
                                      }
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </label>
                                <input
                                  type="text"
                                  name={"claimedUrl_" + index}
                                  id={"claimedUrl_" + index}
                                  className="form-control"
                                  value={affiliation?.claimedUrl}
                                  onChange={(evnt) =>
                                    handleBusinessChange(
                                      evnt,
                                      affiliation.id,
                                      "claimedUrl"
                                    )
                                  }
                                />
                              </div>
                            </div>

                            <div className="row p-1" hidden={true}>
                              <div
                                className="col-sm-12 col-md-6 col-lg-6"
                                hidden={true}
                              >
                                <label className="form-label form-label">
                                  Report to
                                  {contact?.verified_status
                                    ?.contactAffiliationsArray[index]?.reportTo
                                    ?.status === "verified" ? (
                                    <span class="ms-2  badge rounded-pill bg-success">
                                      {
                                        contact?.verified_status
                                          ?.contactAffiliationsArray[index]
                                          ?.reportTo?.status
                                      }
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </label>

                                <input
                                  type="text"
                                  name={"reportTo_" + index}
                                  id={"reportTo_" + index}
                                  className="form-control"
                                  value={affiliation?.reportTo}
                                  onChange={(evnt) =>
                                    handleBusinessChange(
                                      evnt,
                                      affiliation.id,
                                      "reportTo"
                                    )
                                  }
                                />
                              </div>
                              <div
                                hidden={true}
                                className="col-sm-12 col-md-6 col-lg-6"
                              >
                                <label className="form-label form-label">
                                  Contact record type
                                  {contact?.verified_status
                                    ?.contactAffiliationsArray[index]
                                    ?.contactRecordType?.status ===
                                  "verified" ? (
                                    <span class="ms-2  badge rounded-pill bg-success">
                                      {
                                        contact?.verified_status
                                          ?.contactAffiliationsArray[index]
                                          ?.contactRecordType?.status
                                      }
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </label>

                                <input
                                  type="text"
                                  name={"contactRecordType_" + index}
                                  id={"contactRecordType_" + index}
                                  className="form-control"
                                  value={affiliation?.contactRecordType}
                                  onChange={(evnt) =>
                                    handleBusinessChange(
                                      evnt,
                                      affiliation.id,
                                      "contactRecordType"
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* business email info */}
                        <div className="accordion-item p-0">
                          <div
                            className="accordion-header p-2 m-2"
                            style={{
                              "background-color": "#f2f2f2",
                            }}
                          >
                            {" "}
                            Email info
                          </div>

                          <div className="accordion-body">
                            <div className="row p-1">
                              <div className="col-sm col-md-12 col-lg-12 ">
                                <div className="row p-1">
                                  <div className="col-sm-12 col-md-4 col-lg-4">
                                    <label className="form-label form-label">
                                      Email{" "}
                                      <label className="text-danger">*</label>
                                    </label>
                                    <input
                                      type="text"
                                      name="email"
                                      id="email"
                                      className="form-control"
                                      value={businessEmail?.email}
                                      onChange={(evnt) => {
                                        setContainBusinessEmailError("");
                                        handleChangeBusinessEmail(
                                          evnt,
                                          index,
                                          "email"
                                        );
                                      }}
                                    />
                                    {businessEmailErrors.email.length > 0 && (
                                      <small
                                        className="form-check-label text-danger"
                                        htmlFor="email"
                                      >
                                        {businessEmailErrors.email}
                                      </small>
                                    )}
                                  </div>
                                  <div className="col-sm-12 col-md-3 col-lg-3">
                                    <label className="form-label form-label">
                                      Label
                                      <label className="text-danger">*</label>
                                    </label>
                                    <input
                                      type="text"
                                      name="label"
                                      id="label"
                                      className="form-control"
                                      value={businessEmail?.label}
                                      onChange={(evnt) => {
                                        handleChangeBusinessEmail(
                                          evnt,
                                          index,
                                          "label"
                                        );
                                      }}
                                    />
                                    {businessEmailErrors.label.length > 0 && (
                                      <small
                                        className="form-check-label text-danger"
                                        htmlFor="label"
                                      >
                                        {businessEmailErrors.label}
                                      </small>
                                    )}
                                  </div>
                                  <div className="d-flex justify-content-center align-items-start col-sm-12 col-md-2 col-lg-2 mt-5">
                                    <input
                                      type="checkbox"
                                      id="isMain"
                                      name="isMain"
                                      className="form-check-input ms-3"
                                      onChange={(evnt) => {
                                        setContainBusinessEmailError("");
                                        handleChangeBusinessEmail(
                                          evnt,
                                          index,
                                          "isMain"
                                        );
                                      }}
                                      checked={
                                        businessEmail?.isMain === 1
                                          ? true
                                          : false
                                      }
                                    />
                                    <label className="form-label form-label ms-1">
                                      Ismain?
                                    </label>
                                  </div>
                                  <div className="d-flex justify-content-end align-items-end  gap-2 col-sm-12 col-md-3 col-lg-3">
                                    <Button
                                      variant="primary"
                                      id="businessPhoneAdd"
                                      className="form-control btn-sm"
                                      onClick={() => {
                                        clearBusinessEmail();
                                      }}
                                    >
                                      Clear
                                    </Button>
                                    <Button
                                      variant="primary"
                                      id="businessEmailAdd"
                                      className="form-control btn-sm"
                                      onClick={(evnt) => {
                                        addBusinessEmail(evnt, index);
                                      }}
                                    >
                                      Submit
                                    </Button>
                                  </div>
                                </div>
                                {containBusinessEmailError !== "" && (
                                  <small
                                    className="form-check-label text-danger"
                                    htmlFor="label"
                                  >
                                    {containBusinessEmailError}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="row p-1 mt-3">
                              <Table striped bordered hover responsive>
                                <thead>
                                  <tr>
                                    <th>Email</th>
                                    <th>Label</th>
                                    <th>Ismain</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {affiliation.emailArray?.map(
                                    (row, rowindex) => (
                                      <tr key={rowindex}>
                                        <td width="30%"> {row?.email}</td>
                                        <td width="30%"> {row?.label}</td>
                                        <td>
                                          {" "}
                                          <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                            <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                            {row?.isMain === 1 ? "Yes" : "No"}
                                          </span>
                                        </td>
                                        <td>
                                          <button
                                            className="btn btn-sm me-2"
                                            onClick={() =>
                                              deleteBusinessEmailRow(
                                                index,
                                                rowindex
                                              )
                                            }
                                          >
                                            Delete
                                          </button>
                                          <button
                                            onClick={() =>
                                              SelectBusinessEmailRow(
                                                index,
                                                rowindex,
                                                row?.id
                                              )
                                            }
                                            className="btn btn-sm "
                                          >
                                            Edit
                                          </button>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                          {/* </div> */}
                        </div>
                        {/* business address info */}
                        <div className="accordion-item p-0">
                          <div
                            className="accordion-header p-2 m-2"
                            style={{
                              "background-color": "#f2f2f2",
                            }}
                          >
                            {" "}
                            Address info
                          </div>

                          <div className="accordion-body">
                            <div className="row p-1">
                              <div className="col-sm col-md-12 col-lg-12 ">
                                <div className="row p-1">
                                  <div className="col-sm-12 col-md-4 col-lg-4">
                                    <label className="form-label form-label">
                                      Address label
                                      <label className="text-danger">*</label>
                                    </label>
                                    <Select
                                      type="text"
                                      name="addressLabel"
                                      id="addressLabel"
                                      className="form-control"
                                      styles={customstyles}
                                      options={addressOptions}
                                      value={addressOptions.filter(
                                        (client) =>
                                          client.value ===
                                          businessAddress?.addressLabel
                                      )}
                                      onChange={(val) => {
                                        handleChangeBusinessAddress(
                                          {
                                            target: {
                                              name: "addressLabel",
                                              value: val.value,
                                            },
                                          },
                                          "addressLabel"
                                        );
                                      }}
                                    />

                                    {businessAddressErrors.addressLabel.length >
                                      0 && (
                                      <small
                                        className="form-check-label text-danger"
                                        htmlFor="addressLabel"
                                      >
                                        {businessAddressErrors.addressLabel}
                                      </small>
                                    )}
                                  </div>
                                  <div className="col-sm-12 col-md-4 col-lg-4">
                                    <label className="form-label form-label">
                                      Address line 1
                                      <label className="text-danger">*</label>
                                    </label>
                                    <input
                                      type="text"
                                      name="addressLine1"
                                      id="addressLine1"
                                      className="form-control"
                                      value={businessAddress?.addressLine1}
                                      onChange={(evnt) =>
                                        handleChangeBusinessAddress(
                                          evnt,
                                          "addressLine1"
                                        )
                                      }
                                    />
                                    {businessAddressErrors.addressLine1.length >
                                      0 && (
                                      <small
                                        className="form-check-label text-danger"
                                        htmlFor="addressLine1"
                                      >
                                        {businessAddressErrors.addressLine1}
                                      </small>
                                    )}
                                  </div>
                                  <div className="col-sm-12 col-md-4 col-lg-4">
                                    <label className="form-label form-label">
                                      Address line 2
                                    </label>
                                    <input
                                      type="text"
                                      name="addressLine2"
                                      id="addressLine2"
                                      className="form-control"
                                      value={businessAddress?.addressLine2}
                                      onChange={(evnt) =>
                                        handleChangeBusinessAddress(
                                          evnt,
                                          "addressLine2"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="row p-1">
                                  <div className="col-sm-12 col-md-4 col-lg-4">
                                    <label className="form-label form-label">
                                      City
                                    </label>
                                    <input
                                      type="text"
                                      name="city"
                                      id="city"
                                      className="form-control"
                                      value={businessAddress?.city}
                                      onChange={(evnt) =>
                                        handleChangeBusinessAddress(
                                          evnt,
                                          "city"
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="col-sm-12 col-md-4 col-lg-4">
                                    <label className="form-label form-label">
                                      State
                                    </label>
                                    <input
                                      type="text"
                                      name="state"
                                      id="state"
                                      className="form-control"
                                      value={businessAddress?.state}
                                      onChange={(evnt) =>
                                        handleChangeBusinessAddress(
                                          evnt,
                                          "state"
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="col-sm-12 col-md-4 col-lg-4">
                                    <label className="form-label form-label">
                                      Country
                                    </label>

                                    <input
                                      type="text"
                                      name="country"
                                      id="country"
                                      className="form-control"
                                      value={businessAddress?.country}
                                      onChange={(evnt) =>
                                        handleChangeBusinessAddress(
                                          evnt,
                                          "country"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-9 d-flex gap-2">
                                    <div className="col-sm-12 col-md-4 col-lg-4">
                                      <label className="form-label form-label">
                                        Postal code
                                      </label>
                                      <input
                                        type="text"
                                        name="postalCode"
                                        id="postalCode"
                                        className="form-control"
                                        value={businessAddress?.postalCode}
                                        onChange={(evnt) =>
                                          handleChangeBusinessAddress(
                                            evnt,
                                            "postalCode"
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="col-sm-12 col-md-2 col-lg-2 mt-5">
                                      <input
                                        type="checkbox"
                                        id="isMain"
                                        name="isMain"
                                        className="form-check-input ms-3"
                                        onChange={(evnt) => {
                                          setContainBusinessAddressError("");
                                          handleChangeBusinessAddress(
                                            evnt,
                                            "isMain"
                                          );
                                        }}
                                        checked={
                                          businessAddress?.isMain === 1
                                            ? true
                                            : false
                                        }
                                      />
                                      <label className="form-label form-label ms-1">
                                        Ismain?
                                      </label>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-end align-items-end  gap-2 col-sm-12 col-md-3 col-lg-3">
                                    <Button
                                      variant="primary"
                                      id="businessAddressClear"
                                      className="form-control btn-sm"
                                      onClick={() => {
                                        clearBusinessAddress();
                                      }}
                                    >
                                      Clear
                                    </Button>
                                    <Button
                                      variant="primary"
                                      id="businessAddressAdd"
                                      className="form-control  btn-sm"
                                      type="submit"
                                      onClick={(evnt) => {
                                        addBusinessAddress(evnt, index);
                                      }}
                                    >
                                      Submit
                                    </Button>
                                  </div>
                                </div>
                                {containBusinessAddressError !== "" && (
                                  <small
                                    className="form-check-label text-danger"
                                    htmlFor="label"
                                  >
                                    {containBusinessAddressError}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="row p-1 mt-3">
                              <Table striped bordered hover responsive>
                                <thead>
                                  <tr>
                                    <th>Address label</th>
                                    <th>Address line 1</th>
                                    <th>Address line 2</th>
                                    <th>City</th>
                                    <th>Ismain</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {" "}
                                  {affiliation.addressArray?.map(
                                    (row, rowindex) => (
                                      <tr key={rowindex}>
                                        <td>
                                          {addressOptions.map((option) =>
                                            option.value ===
                                            row?.addressLabel ? (
                                              <span>{option.label}</span>
                                            ) : (
                                              <span>&nbsp;</span>
                                            )
                                          )}
                                        </td>
                                        <td> {row?.addressLine1}</td>
                                        <td> {row?.addressLine2}</td>
                                        <td> {row?.city}</td>
                                        <td>
                                          {" "}
                                          <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                            <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                            {row?.isMain === 1 ? "Yes" : "No"}
                                          </span>
                                        </td>
                                        <td>
                                          <button
                                            className="btn btn-sm  me-2"
                                            onClick={() =>
                                              deleteBusinessAddressRow(
                                                index,
                                                rowindex
                                              )
                                            }
                                          >
                                            Delete
                                          </button>
                                          <button
                                            onClick={() =>
                                              SelectBusinessAddressRow(
                                                index,
                                                rowindex,
                                                row?.id
                                              )
                                            }
                                            className="btn btn-sm"
                                          >
                                            Edit
                                          </button>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                          {/* </div> */}
                        </div>
                        {/* business phone info */}
                        <div className="accordion-item p-0">
                          <div
                            className="accordion-header p-2 m-2"
                            style={{
                              "background-color": "#f2f2f2",
                            }}
                          >
                            {" "}
                            Alternate phone info
                          </div>
                          <div className="accordion-body">
                            <div className="row p-1">
                              <div className="col-sm col-md-12 col-lg-12 ">
                                <div className="row p-1">
                                  <div className="col-sm-12 col-md-3 col-lg-3">
                                    <label className="form-label form-label">
                                      Phone type
                                      <label className="text-danger">*</label>
                                    </label>
                                    <Select
                                      type="text"
                                      name="type"
                                      id="type"
                                      className="form-control"
                                      styles={customstyles}
                                      value={phoneOptions.filter(
                                        (client) =>
                                          client.value === businessPhone?.type
                                      )}
                                      onChange={(val) => {
                                        handleChangeBusinessPhone(
                                          {
                                            target: {
                                              name: "type",
                                              value: val.value,
                                            },
                                          },
                                          index,
                                          "type"
                                        );
                                      }}
                                      options={phoneOptions}
                                    />
                                    {businessPhoneErrors.type.length > 0 && (
                                      <small
                                        className="form-check-label text-danger"
                                        htmlFor="type"
                                      >
                                        {businessPhoneErrors.type}
                                      </small>
                                    )}
                                  </div>
                                  <div className="col-sm-12 col-md-4 col-lg-4">
                                    <label className="form-label form-label">
                                      Phone number
                                      <label className="text-danger">*</label>
                                    </label>
                                    <PhoneInput
                                      inputStyle={{ width: "250px" }}
                                      country={"us"}
                                      value={businessPhone?.fullPhone}
                                      placeholder="(000-000-0000)"
                                      name="fullPhone"
                                      id="fullPhone"
                                      onChange={handleOnBusinessPhoneChange}
                                    />
                                    {businessPhoneErrors.phone.length > 0 && (
                                      <small
                                        className="form-check-label text-danger"
                                        htmlFor="phone"
                                      >
                                        {businessPhoneErrors.phone}
                                      </small>
                                    )}
                                  </div>
                                  <div className="col-sm-12 col-md-1 col-lg-1">
                                    <label className="form-label form-label">
                                      Ext
                                    </label>
                                    <input
                                      type="text"
                                      name="extphone"
                                      id="extphone"
                                      className="form-control"
                                      value={businessPhone?.extphone}
                                      onChange={(evnt) => {
                                        setContainBusinessPhoneError("");
                                        handleChangeBusinessPhone(
                                          evnt,
                                          index,
                                          "extphone"
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="justify-content-center align-items-start col-sm-12 col-md-2 col-lg-2  mt-5">
                                    <input
                                      type="checkbox"
                                      id="isMain"
                                      name="isMain"
                                      className="form-check-input ms-3"
                                      onChange={(evnt) => {
                                        setContainBusinessPhoneError("");
                                        handleChangeBusinessPhone(
                                          evnt,
                                          index,
                                          "isMain"
                                        );
                                      }}
                                      checked={
                                        businessPhone.isMain === 1
                                          ? true
                                          : false
                                      }
                                    />
                                    <label className="form-label form-label ms-1">
                                      Ismain?
                                    </label>
                                  </div>
                                  <div className="d-flex justify-content-end align-items-end  gap-2 col-sm-12 col-md-2 col-lg-2">
                                    <Button
                                      variant="primary"
                                      id="businessPhoneClear"
                                      className="form-control btn-sm"
                                      onClick={() => {
                                        setBusinessPhoneErrors(
                                          initialbusinessPhoneState
                                        );
                                        setContainBusinessPhoneError("");
                                        setBusinessPhone(
                                          initialbusinessPhoneState
                                        );
                                      }}
                                    >
                                      Clear
                                    </Button>
                                    <Button
                                      variant="primary"
                                      id="businessPhoneAdd"
                                      className="form-control btn-sm"
                                      onClick={(evnt) => {
                                        addBusinessPhone(evnt, index);
                                      }}
                                    >
                                      Submit
                                    </Button>
                                  </div>
                                </div>
                                {containBusinessPhoneError !== "" && (
                                  <small
                                    className="form-check-label text-danger"
                                    htmlFor="label"
                                  >
                                    {containBusinessPhoneError}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="row p-1 mt-">
                              <Table striped bordered hover responsive>
                                <thead>
                                  <tr>
                                    <th>Phone type</th>
                                    <th>Phone number</th>
                                    <th>Extn</th>
                                    <th>Ismain</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {affiliation.phoneArray?.map(
                                    (row, rowindex) => (
                                      <tr key={index}>
                                        <td>
                                          {phoneOptions.map((option) =>
                                            option.value === row?.type ? (
                                              <span>{option.label}</span>
                                            ) : (
                                              ""
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {" "}
                                          <PhoneInput
                                            inputStyle={{ width: "250px" }}
                                            value={row?.fullPhone}
                                            name="fullPhone"
                                            placeholder="(000-000-0000)"
                                            id="fullPhone"
                                            disabled
                                          />
                                        </td>
                                        <td> {row?.extphone}</td>
                                        <td>
                                          {" "}
                                          <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                            <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                            {row?.isMain === 1 ? "Yes" : "No"}
                                          </span>
                                        </td>
                                        <td>
                                          <Button
                                            className="btn-sm me-2"
                                            onClick={() =>
                                              deleteBusinessPhoneRow(
                                                index,
                                                rowindex
                                              )
                                            }
                                          >
                                            Delete
                                          </Button>
                                          <Button
                                            onClick={() =>
                                              SelectBusinessPhoneRow(
                                                index,
                                                rowindex,
                                                row?.id
                                              )
                                            }
                                            className="btn-sm"
                                          >
                                            Edit
                                          </Button>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="row mt-3 mb-3 p-2">
              <div className="col-4 d-flex gap-2">
                <Button
                  className="btn btn-success "
                  onClick={submitAddCompany}
                  disabled={
                    contact.firstname === "" ||
                    contact.lastname === "" ||
                    contact.email === ""
                  }
                >
                  Add company affiliation
                </Button>
              </div>
              <div className="col d-flex justify-content-end gap-2">
                <Button className=" btn btn-success " onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  className="btn btn-success me-2"
                  onClick={submitHandler}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {isSuccess && (
          <SweetAlert
            success
            style={{ color: "black" }}
            title={appConstants.contact.successfullmsg.create}
            onConfirm={handleSucessSweetAlert}
          ></SweetAlert>
        )}
        {isAlreadyExist && (
          <SweetAlert
            warning
            style={{ color: "black" }}
            title={appConstants.contact.alreadyexistmsg.contact}
            onConfirm={handleSucessSweetAlert1}
          ></SweetAlert>
        )}
        {isCompany && (
          <Modal
            show={isCompany}
            onHide={() => {
              setIsCompany(false);
            }}
            size="xl"
            centered
          >
            <Modal.Body>
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src="/images/cm_logo.png"
                  className="headerLogo"
                  alt="Logo"
                />
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <label className="h5">
                  Select company for{" "}
                  <span className="fw-bold">
                    {contact?.firstname + " " + contact?.lastname}
                  </span>
                </label>
              </div>
              <hr></hr>
              <div className="d-flex justify-content-start align-items-center row">
                <div className="col-1 d-flex justify-content-end ">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </div>
                <div className="col-11">
                  <CreatableSelect
                    name="vendorsearch"
                    id="vendorsearch"
                    options={vendorListSearchLookup}
                    isSearchable={true}
                    //  styles={customstyles}
                    onChange={(val) => {
                      const containsvalue = !!appAffList.find((app) => {
                        return app?.vendorname === "";
                      });
                      var changeinfo = {
                        value: val,
                        name: "vendorsearch",
                        rowindex: "",
                      };
                      if (containsvalue) {
                        setchangeVendorRow(changeinfo);
                        handleDropDownChangeAddCompany(changeinfo);
                      } else {
                        setchangeVendorRow(changeinfo);
                        setIsVendorChange(!isVendorChange);
                      }
                    }}
                  ></CreatableSelect>
                </div>
              </div>

              <div>
                <hr></hr>
                <div className="row mt-3 d-flex justify-content-center align-items-center">
                  <div className="col-sm-12 col-md-2 col-lg-1">
                    {" "}
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="allSelectSource"
                      id="allSelectSource"
                      // allSelect selected when both length equal

                      defaultChecked={true}
                      onChange={(e) => handleChange_AppSelect(e, appAffList)}
                    />
                  </div>
                  <div className="col-sm-12 col-md-2 col-lg-3 fw-bold text-start">
                    <label>Connect apps</label>
                  </div>
                  <div className="col-sm-12 col-md-2 col-lg-4 fw-bold  text-start">
                    <label>Account display name</label>
                  </div>

                  <div className="col-sm-12 col-md-2 col-lg-4 fw-bold  text-start">
                    <label>Contact company</label>
                  </div>
                </div>
                <hr></hr>
                {appAffList?.map((row, index) => (
                  <div key={index}>
                    {row?.appname.toLowerCase() !== "quickbooks" && (
                      <div className="row pb-1 d-flex justify-content-center align-items-center">
                        <div className="col-sm-12 col-md-2 col-lg-1">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            name="SelectSource"
                            id="SelectSource"
                            checked={row?.SelectSource}
                            onChange={(e) =>
                              handleChange_AppSelect(e, row, index)
                            }
                          />
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-3">
                          <span className="d-flex">{row?.appname}</span>
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-4">
                          <span className="d-flex">
                            {row?.appuserinfo[0]?.appdisplayname}
                          </span>
                        </div>

                        <div className="col-sm-12 col-md-2 col-lg-4">
                          <CreatableSelect
                            name={"vendor_" + index}
                            id={"vendor_" + index}
                            options={GetVendorListByCompanyID(
                              row?.appaccountid,
                              row?.appname,
                              index
                            )}
                            isClearable={false}
                            isSearchable={true}
                            // styles={customexpandstyles}
                            placeholder={
                              <div className="d-flex">Select...</div>
                            }
                            value={vendorList.filter(
                              (vendorinfo) =>
                                vendorinfo.vendorname === row?.vendorname
                            )}
                            onChange={(val) => {
                              var changeinfo = {
                                value: val,
                                name: "vendor",
                                rowindex: index,
                              };
                              if (row?.vendorname === "") {
                                setchangeVendorRow(changeinfo);
                                handleDropDownChangeAddCompany(changeinfo);
                              } else {
                                setIsVendorChange(!isVendorChange);
                                setchangeVendorRow(changeinfo);
                              }
                            }}
                          ></CreatableSelect>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div>
                <hr></hr>
                <div className="row mt-3 d-flex justify-content-center align-items-center">
                  <div className="col-sm-12 col-md-2 col-lg-1"></div>
                  <div className="col-sm-12 col-md-2 col-lg-2 fw-bold text-start">
                    <label>Connect apps</label>
                  </div>
                  <div className="col-sm-12 col-md-2 col-lg-3 fw-bold  text-start">
                    <label>Account display name</label>
                  </div>
                  <div className="col-sm-12 col-md-2 col-lg-3 fw-bold  text-start">
                    <label>Contact role</label>
                  </div>
                  <div className="col-sm-12 col-md-2 col-lg-3 fw-bold  text-start">
                    <label>Contact company</label>
                  </div>
                </div>
                <hr></hr>
                {appAffList?.map((row, index) => (
                  <div key={index}>
                    {row?.appname.toLowerCase() === "quickbooks" && (
                      <div className="row pb-1 d-flex justify-content-center align-items-center">
                        <div className="col-sm-12 col-md-2 col-lg-1">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            name="SelectSource"
                            id="SelectSource"
                            checked={row?.SelectSource}
                            onChange={(e) =>
                              handleChange_AppSelect(e, row, index)
                            }
                          />
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-2">
                          <span className="d-flex">{row?.appname}</span>
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-3">
                          <span className="d-flex">
                            {row?.appuserinfo[0]?.appdisplayname}
                          </span>
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-3">
                          {row?.appname?.toLowerCase() === "quickbooks" && (
                            <Select
                              name={"contactrole_" + index}
                              id={"contactrole_" + index}
                              options={contactRoleList}
                              isClearable={false}
                              isSearchable={true}
                              // styles={customexpandstyles}
                              placeholder={
                                <div className="d-flex">Select...</div>
                              }
                              value={contactRoleList.filter(
                                (contactrole) =>
                                  contactrole.value === row?.contactrole
                              )}
                              onChange={(val) => {
                                var _appAffList = [...appAffList];

                                if (
                                  _appAffList[index].contactrole === "" &&
                                  _appAffList[index].vendorname !== ""
                                ) {
                                  _appAffList[index].contactrole = val?.value;
                                  setAppAffList(_appAffList);
                                } else {
                                  _appAffList[index].contactrole = val?.value;
                                  _appAffList[index].selectvalue = "";
                                  _appAffList[index].vendorid = "";
                                  _appAffList[index].vendorname = "";
                                  setAppAffList(_appAffList);
                                  GetVendorQuickbooksListByCompanyID(
                                    row?.appaccountid,
                                    row?.appname,
                                    val?.value,
                                    index
                                  );
                                }
                              }}
                            ></Select>
                          )}
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-3">
                          <CreatableSelect
                            name={"vendor_" + index}
                            id={"vendor_" + index}
                            options={quickBooksCompanyList}
                            isClearable={false}
                            isSearchable={true}
                            // styles={customexpandstyles}
                            placeholder={
                              <div className="d-flex">Select...</div>
                            }
                            value={vendorList.filter(
                              (vendorinfo) =>
                                vendorinfo.vendorname === row?.vendorname
                            )}
                            onChange={(val) => {
                              var changeinfo = {
                                value: val,
                                name: "vendor",
                                rowindex: index,
                              };
                              if (row?.vendorname === "") {
                                setchangeVendorRow(changeinfo);
                                handleDropDownChangeAddCompany(changeinfo);
                              } else {
                                setIsVendorChange(!isVendorChange);
                                setchangeVendorRow(changeinfo);
                              }
                            }}
                          ></CreatableSelect>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              {errormsg.length > 0 && (
                <div className="text-danger">{errormsg}</div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex justify-content-end gap-2">
                <Button
                  className="btn btn-primary "
                  onClick={() => {
                    setIsCompany(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="btn btn-primary me-2"
                  //  disabled={!isVaildData}
                  onClick={submitConfirm}
                >
                  Confirm
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        )}
        {isDeleteAffiliation && (
          <SweetAlert
            warning
            style={{ color: "black" }}
            title=""
            confirmBtnText="Yes"
            cancelBtnText="No"
            showCancel={true}
            onConfirm={(e) => handleConfirmAffiliation(e, "delete")}
            onCancel={() => setIsDeleteAffiliation(false)}
          >
            <div>{appConstants.contact.confirmationmsg.deleteaff}</div>
          </SweetAlert>
        )}
        {isVendorChange && (
          <SweetAlert
            warning
            style={{ color: "black" }}
            title=""
            confirmBtnText="Yes"
            cancelBtnText="No"
            showCancel={true}
            onConfirm={handleConfirmVendor}
            onCancel={handleCancelVendor}
          >
            <div>{appConstants.contact.confirmationmsg.company}</div>
          </SweetAlert>
        )}
      </div>
      <LoadingDialog
        isOpen={loadDialogOpen}
        onClose={() => setloadDialogOpen(false)}
      />
    </div>
  );
}
export default AddContact;
